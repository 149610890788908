<template>
  <div class="row m-0">
    <div class="col-md-10 col-xl-9 col-lg-9 order-md-1 order-lg-1 order-xl-1">
      <p class="fw-bold text-center">Supervisor TRIP REPORT</p>
      <div class="card card-body bg-dark border-3 rounded-3 border-secondary">
        <div class="table-responsive">
          <table class="table table-hover table-striped vehicle_table text-center m-0">
            <thead class="table_header">
              <tr>
                <th class="font_detail" colspan="2">Seat Ticket</th>
                <th class="font_detail" colspan="2">Deck Ticket</th>
                <th class="font_detail" colspan="2">Goods Ticket</th>
                <th v-if="printData.onlineTicket.totalSeat != 0" class="font_detail" colspan="2">Online Ticket</th>
                <th class="font_detail borderLeft" rowspan="2">Net Pay</th>
              </tr>
              <tr>
                <th class="font_detail">Total Seat</th>
                <th class="font_detail">Total Amount</th>
                <th class="font_detail">Total Seat</th>
                <th class="font_detail">Total Amount</th>
                <th class="font_detail">Total Seat</th>
                <th class="font_detail">Total Amount</th>
                <th v-if="printData.onlineTicket.totalSeat != 0" class="font_detail">Total Seat</th>
                <th v-if="printData.onlineTicket.totalSeat != 0" class="font_detail">Total Amount</th>
              </tr>
            </thead>
            <tbody class="table_body">
              <tr>
                <td class="font_detail">{{ printData.generalTicket.totalSeat }}</td>
                <td class="font_detail">{{ printData.generalTicket.totalAmount }}</td>
                <td class="font_detail">{{ printData.deckTicket.total }}</td>
                <td class="font_detail">{{ printData.deckTicket.totalAmount }}</td>
                <td class="font_detail">{{ printData.goodsTicket.total }}</td>
                <td class="font_detail">{{ printData.goodsTicket.totalAmount }}</td>
                <td v-if="printData.onlineTicket.totalSeat != 0" class="font_detail">{{ printData.onlineTicket.totalSeat }}</td>
                <td v-if="printData.onlineTicket.totalSeat != 0" class="font_detail">{{ printData.onlineTicket.totalAmount }}</td>
                <td class="font_detail">{{ printData.netPay }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-md-2 col-lg-3 col-xl-3 order-md-2 order-lg-2 order-xl-2">
      <p class="fw-bold text-center">ACTIONS</p>
      <div class="card card-body">
        <div class="row">
          <div class="col-md-12">
            <p class="text-center font_detail m-0">GO BACK</p>
            <button class="cancel_button w-100" @click="goBack">
              <i class="fas fa-arrow-left"></i>
            </button>
          </div>

          <div v-if="printStatus" class="col-md-12">
            <p class="text-center font_detail m-0">PRINT</p>
            <p style="display: none" id="fullReport">{{ printData }}</p>
            <p style="display: none" id="type">{{ supervisorTicketType }}</p>
            <p style="display: none" id="printCount">{{ supervisorReportPrintCount }}</p>
            <!-- <p style="display: none" id="once">{{ supervisorReportOnce }}</p> -->
            <button
              class="confirm_button w-100"
              onclick="PrintAndroid(document.getElementById('fullReport').innerHTML,document.getElementById('type').innerHTML, document.getElementById('printCount').innerHTML)"
              @click="submitPrintCountIncrease"
            >
              <i class="fas fa-print"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, inject } from "vue";
// import moment from "moment";
import store from "@/store";

export default {
  name: "TripReportPrintPreview",
  props: {
    printData: Object,
    ticketType: String,
    printCount: Number,
    reportOnce: Number,
    printCountStatus: Boolean,
    tripId: [Number, String],
    tripDateAndTime: String,
    selectedShip: String,
    routesName: String
  },
  setup(props, { emit }) {
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");

    let supervisorReportPrintData = ref(props.printData);
    let supervisorTicketType = ref(props.ticketType)
    let supervisorReportPrintCount = ref(props.printCount);
    let supervisorReportOnce = ref(props.reportOnce);
    let supervisorReportprintCountStatus = ref(props.printCountStatus)
    let supervisorTripId = ref(props.tripId)
    let tripDateTime = ref(props.tripDateAndTime)
    let supervisorShipName = ref(props.selectedShip)
    let supervisorRouteName = ref(props.routesName)
    let printStatus = ref(props.printCountStatus);
    // const dateFormat = (date) => {
    //   return moment(String(date))
    //     .utcOffset(0, false)
    //     .format("DD-MMM-yyyy hh:mm:ss A");
    // };

    // const dateFormat2 = (date) => {
    //   return moment(String(date))
    //     .utcOffset(0, false)
    //     .format("yyyy-MMM-DD hh:mm:ss A");
    // };

    const submitPrintCountIncrease = () => {
      showLoader();
      supervisorReportPrintCount.value += 1;
      store
        .dispatch("supervisorReportPrintCountIncrease", {
          reportType: "supervisorTripWiseReport",
          tripId: supervisorTripId.value,
          tripDateTime: tripDateTime.value,
          route: supervisorRouteName.value,
          ship: supervisorShipName.value
        })
        .then(() => {
          hideLoader();
          goBack();
        })
        .catch(() => {
          hideLoader();
          console.log("Something wrong.Please try again later.");
        });
    };

    const goBack = () => {
      emit("close");
    };

    return {
      supervisorReportPrintData,
      supervisorTicketType,
      supervisorReportPrintCount,
      supervisorReportOnce,
      supervisorReportprintCountStatus,
      supervisorTripId,
      tripDateTime,
      supervisorShipName,
      supervisorRouteName,
      submitPrintCountIncrease,
      printStatus,
      goBack,
    };
  },
};
</script>

<style>
.table_header {
  background-color: #3561f0;
  color: #fff;
}
.table_body {
  background-color: #fff;
  color: #212529;
}
.cancel_button {
  margin: 1px 2px;
  border: 2px solid #ff0000;
  border-radius: 4px;
  background-color: #ff0000;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.cancel_button:hover,
.cancel_button:focus {
  border: 2px solid #ff0000;
  background-color: #fff;
  color: #ff0000;
  transition: 0.4s;
  outline: 0;
}

.confirm_button {
  margin: 1px 2px;
  border: 2px solid #34a853;
  border-radius: 4px;
  background-color: #34a853;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.confirm_button:hover,
.confirm_button:focus {
  border: 2px solid #34a853;
  background-color: #fff;
  color: #34a853;
  transition: 0.4s;
  outline: 0;
}

.borderLeft{
  border-left: 1px solid white;
}

</style>