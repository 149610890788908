<template>
    <Modal @close="closeModal">
        <template v-slot:title>{{ title }}</template>
        <template v-slot:body>
            <form>
                <div class="form-group row my-1">
                    <ModalInput
                        v-model="bPlace.name"
                        :placeholder="'Enter Name'"
                        :label="'Name'"
                        :id="'name'"
                        :type="'text'"
                    >
                        <template v-slot:error>
                            <ModalError v-if="errors.name" v-bind:error="errors.name"/>
                        </template>
                    </ModalInput>
                    <br> <br>
                    <ModalInput
                        v-model="bPlace.position"
                        :placeholder="'Enter Position'"
                        :label="'Position'"
                        :id="'position'"
                        :type="'number'"
                    >
                    </ModalInput>
                </div>

                <div class="form-group row my-1">
                    <label class="col-sm-3 col-form-label"></label>
                    <div class="col-sm-9">
                        <input
                            v-if="!bpId"
                            type="submit"
                            value="Submit"
                            class="mt-3 py-2 submit_button font_detail float-end"
                            @click.prevent="addBoardingPlace"
                        />
                        <input
                            v-else
                            type="submit"
                            value="Submit"
                            class="mt-3 py-2 submit_button font_detail float-end"
                            @click.prevent="updateBoardingPlace"
                        />
                    </div>
                </div>
            </form>
        </template>
    </Modal>
</template>

<script>
import dropdownVariables from "@/modules/dropdownVariables";
import useInputValidation from "@/modules/useInputValidations";
import {inject, onBeforeMount, ref, watch} from "vue";
import {reactive} from "@vue/reactivity";

import store from "../../../../../../store";

export default {
    name: "BoardingPlaceAddUpdateModal",
    props: {
        boardingPlace: String,
        bpPosition: String,
        route: String,
        boardingPlaceName: String,
    },
    setup(props, {emit}) {
        let {requiredValidation, errors} = useInputValidation();
        let bPlace = reactive({
            routeId: "",
            name: "",
            previousName: "",
            newName: "",
            position: "",
            previousPosition: "",
            newPosition: "",
        });
        const bpId = ref(props.boardingPlace);
        const sId = ref(props.route);
        const bpName = ref(props.boardingPlaceName);
        const bpPosition = ref(props.bpPosition);
        const status = dropdownVariables.status;
        const title = ref("Add Boarding Place");
        const showLoader = inject("showLoader");
        const hideLoader = inject("hideLoader");

        watch(
            () => bPlace.name,
            () => {
                requiredValidation("name", bPlace.name);
            }
        );

        const validate = () => {
            Object.keys(errors).forEach(function (key) {
                errors[key] = "";
            });
            requiredValidation("name", bPlace.name);
            return !(
                Object.values(errors).length <= 0 ||
                !Object.values(errors).every((x) => x === null || x === "")
            );
        };
        const closeModal = () => {
            emit("update:modelValue", false);
        };
        const addBoardingPlace = () => {
            if (validate()) {
                showLoader();
                store
                    .dispatch("addOwnerServicesBoardingPlace", bPlace)
                    .then(() => {
                        hideLoader();
                        emit("getAllBoardingPlaces");
                        closeModal();
                    })
                    .catch((error) => {
                        hideLoader();
                        if (error.status === 401) {
                            addBoardingPlace();
                        }
                    });
            }
        };
        const updateBoardingPlace = () => {
            if (validate()) {
                showLoader();
                bPlace.newName = bPlace.name;
                bPlace.newPosition = bPlace.position;
                store
                    .dispatch("updateOwnerServicesBoardingPlace", bPlace)
                    .then(() => {
                        hideLoader();
                        emit("getAllBoardingPlaces");
                        closeModal();
                    })
                    .catch((error) => {
                        hideLoader();
                        if (error.status === 401) {
                            updateBoardingPlace();
                        }
                    });
            }
        };

        onBeforeMount(() => {
            Object.keys(errors).forEach(function (key) {
                delete errors[key];
            });

            bPlace.routeId = sId.value;

            if (bpId.value !== "") {
                title.value = "Update Boarding Place";
                bPlace.previousName = bpName.value;
                bPlace.name = bpName.value;
                bPlace.previousPosition = bpPosition.value;
                bPlace.position = bpPosition.value;
            }
        });

        return {
            bpId,
            bPlace,
            errors,
            status,
            title,
            addBoardingPlace,
            updateBoardingPlace,
            closeModal,
        };
    },
};
</script>

<style scoped>
</style>