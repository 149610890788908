<template>
  <Modal @close="closeModal" :size="'xl'">
    <template v-slot:title>{{ title }}</template>
    <template v-slot:body>
      <form>
        <div class="auth_form py-3">
          <div class="row my-2">
            <div class="col-md-6 col-lg-4 col-xl-3 mt-3">
              <Input
                v-model="cabinmanData.name"
                :placeholder="'Enter Cabinman Name'"
                :label="'Name'"
                :id="'name'"
                :type="'text'"
              />
              <Error v-if="errors.name" :error="errors.name" />
            </div>
            <div class="col-md-6 col-lg-4 col-xl-3 mt-3">
              <Input
                v-model="cabinmanData.phone"
                :placeholder="'Enter Phone Number'"
                :label="'Phone'"
                :id="'phone'"
                :type="'text'"
              />
              <Error v-if="errors.phone" :error="errors.phone" />
            </div>
            <div class="col-md-6 col-lg-4 col-xl-3 mt-3">
              <Input
                v-model="cabinmanData.password"
                :placeholder="'Enter Password'"
                autocomplete="off"
                :label="'Password'"
                :id="'password'"
                :type="fieldTypes.password"
              />
              <Error v-if="errors.password" :error="errors.password" />
            </div>
            <div class="col-md-6 col-lg-4 col-xl-3 mt-3">
              <Input
                v-model="cabinmanData.email"
                :placeholder="'Email Address'"
                autocomplete="off"
                :label="'Email'"
                :id="'email'"
                :type="fieldTypes.email"
              />
            </div>

            <div class="col-md-6 col-lg-4 col-xl-3 mt-3">
              <!-- <Dropdown
                  v-if="printerType"
                  v-model="cabinmanData.printerType"
                  :id="'printerType'"
                  :label="'Printer Type'"
                  :dropdown="printerType"
                  :default-option="'Select a Printer Type'"
                  :default-value="''"
              />
              <Error
                  v-if="errors.printerType"
                  :error="errors.printerType"
              /> -->
              <label for="printerType" class="label_font ">Printer Type</label>
              <input 
                type="text" 
                id="printerType" 
                :value="cabinmanData.printerType" 
                class="input_field form-control rounded py-2 px-3 w-100 mt-1 font_detail" 
                disabled
              />
            </div>
            <div class="col-md-6 col-lg-4 col-xl-3 mt-3">
              <Dropdown
                  v-if="status"
                  v-model="cabinmanData.printingType"
                  :id="'printingType'"
                  :label="'Ticket Printing'"
                  :dropdown="printingType"
              />
            </div>

            <div class="col-md-6 col-lg-4 col-xl-3 mt-3">
              <Input
                v-model="cabinmanData.reportPrintLimit"
                :placeholder="'Print Limit'"
                :label="'Report Print Limit'"
                :id="'reportPrintLimit'"
                :type="'number'"
              />
              <Error
                v-if="errors.reportPrintLimit"
                :error="errors.reportPrintLimit"
              />
            </div>
            <div class="col-md-6 col-lg-4 col-xl-3 mt-3">
              <Input
                v-model="cabinmanData.nid"
                :placeholder="'NID Number'"
                :label="'NID'"
                :id="'nid'"
                :type="'text'"
              />
            </div>
            <div class="col-md-6 col-lg-4 col-xl-3">
              <Dropdown
                v-if="status"
                v-model="cabinmanData.status"
                :id="'status'"
                :label="'Status'"
                :dropdown="status"
              />
            </div>
            <div class="col-md-6 col-lg-4 col-xl-3">
              <!--              <MultiSelectDropdown v-model="cabinmanData.shipId" :label="'Ships'" :id="'ships'"/>-->
              <label class="label_font">Ships</label>
              <Multiselect
                class="w-100 mt-1 mb-4"
                placeholder="Select ships"
                v-model="cabinmanData.shipId"
                :options="$store.getters.getOwnerServicesShipListForFilter"
                id="multiselectId"
                mode="single"
                :searchable="true"
                :createTag="true"
              />
              <Error v-if="errors.ship" :error="errors.ship" />
            </div>
            <div class="col-md-6">
              <label for="address" class="form-label all_permission_label">Address</label>
              <div>
                <textarea
                    class="form-control font_detail"
                    id="address"
                    v-model="cabinmanData.address"
                ></textarea>
              </div>
            </div>
            <div v-if="reservationStatus" class="col-md-6 col-lg-4 col-xl-3">
              <Dropdown
                v-if="status"
                v-model="cabinmanData.cabinmanReserveConfirm"
                :id="'status'"
                :label="'Reservation Confirm'"
                :dropdown="cabinmanReserveConfirmStatus"
              />
            </div>
          </div>


        </div>

        <div class="form-group row my-1">
          <label class="col-sm-3 col-form-label"></label>
          <div class="col-sm-9">
            <input
              v-if="!cabinmanIdForUpdate"
              type="submit"
              value="Submit"
              class="mt-3 py-2 submit_button font_detail float-end"
              @click.prevent="addCabinMan"
            />
            <input
              v-else
              type="submit"
              value="Submit"
              class="mt-3 py-2 submit_button font_detail float-end"
              @click.prevent="updateCabinMan"
            />
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
import { reactive } from "@vue/reactivity";
import dropdownVariables from "@/modules/dropdownVariables";
import useInputValidation from "@/modules/useInputValidations";
import { inject, onBeforeMount, ref, watch } from "vue";
import store from "@/store";
import Multiselect from "@vueform/multiselect";

export default {
  name: "DeckmanAddUpdateModal",
  props: {
    modalValue: Boolean,
    cabinman: String,
    company: String,
  },
  components: {
    Multiselect,
  },
  setup(props, { emit }) {
    let cabinmanData = reactive({
      id: "",
      name: "",
      designation: "",
      phone: "",
      password: "",
      email: "",
      nid: "",
      address: "",
      avatar: "",
      transactionType: "",
      balance: "",
      commission: "",
      userType: "cabinman",
      companyId: "",
      counterId: "",
      shipId: [],
      commissionType: "",
      roles: "",
      status: "1",
      cabinmanReserveConfirm: "0",
      api_token: "",
      device_token: "",
      reset_token: "",
      reportPrintLimit: 1,
      printingType: "Individual",
      printerType: "Bluetooth",
    });
    let reservationStatus = ref(false)
    let cabinmanIdForUpdate = ref(props.cabinman);
    let companyId = ref(props.company);
    let {
      requiredValidation,
      passwordValidation,
      phoneValidation,
      validNumberValidation,
      errors,
    } = useInputValidation();
    const status = dropdownVariables.status;
    const cabinmanReserveConfirmStatus = dropdownVariables.boolean;
    const printerType = dropdownVariables.countermanPrinterType
    const printingType = dropdownVariables.printingType;
    const title = ref("Add Cabinman");
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");

    let fieldTypes = reactive({
      email: "text",
      password: "text",
    });

    watch(
      () => cabinmanData.name,
      () => {
        requiredValidation("name", cabinmanData.name);
      }
    );
    watch(
      () => cabinmanData.phone,
      () => {
        phoneValidation("phone", cabinmanData.phone);
      }
    );
    watch(
      () => cabinmanData.password,
      () => {
        passwordValidation("password", cabinmanData.password);
      }
    );
    watch(
      () => cabinmanData.shipId,
      () => {
        requiredValidation("ship", cabinmanData.shipId);
      }
    );
    watch(
      () => cabinmanData.reportPrintLimit,
      () => {
        validNumberValidation("reportPrintLimit", cabinmanData.reportPrintLimit);
      }
    );
    watch(
      () => cabinmanData.password,
      () => {
        fieldTypes.password = "password";
      }
    );
    watch(
      () => cabinmanData.email,
      () => {
        fieldTypes.email = "email";
      }
    );

    const closeModal = () => {
      emit("update:modelValue", false);
    };

    const validate = () => {
      Object.keys(errors).forEach(function (key) {
        errors[key] = "";
      });
      requiredValidation("name", cabinmanData.name);
      phoneValidation("phone", cabinmanData.phone);
      requiredValidation("ship", cabinmanData.shipId);
      validNumberValidation("reportPrintLimit", cabinmanData.reportPrintLimit);
      if (cabinmanIdForUpdate.value === "") {
        passwordValidation("password", cabinmanData.password);
      } else {
        if (cabinmanData.password !== "") {
          passwordValidation("password", cabinmanData.password);
        }
      }
      return !(
        Object.values(errors).length <= 0 ||
        !Object.values(errors).every((x) => x === null || x === "")
      );
    };

    const addCabinMan = () => {
      if (validate()) {
        showLoader();
        store
          .dispatch("addOwnerServicesCabinMan", cabinmanData)
          .then(() => {
            hideLoader();
            emit("getAllCabinman");
            closeModal();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              addCabinMan();
            }
          });
      }
    };
    const updateCabinMan = () => {
      if (validate()) {
        showLoader();
        store
          .dispatch("updateOwnerServicesCabinMan", cabinmanData)
          .then(() => {
            emit("getAllCabinman");
            closeModal();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              updateCabinMan();
            }
          });
      }
    };

    onBeforeMount(() => {
      Object.keys(errors).forEach(function (key) {
        delete errors[key];
      });
      reservationStatus.value = store.getters.getReservationStatus;
      cabinmanData.companyId = companyId.value;
      if (cabinmanIdForUpdate.value !== "") {
        const deckMan = store.getters.getCabinMan(cabinmanIdForUpdate.value);
        title.value = "Update Cabinman";
        cabinmanData.id = deckMan[0]._id;
        cabinmanData.companyId = deckMan[0].companyId;
        cabinmanData.name = deckMan[0].name;
        cabinmanData.phone = deckMan[0].phone;
        cabinmanData.email = deckMan[0].email;
        cabinmanData.nid = deckMan[0].nid;
        cabinmanData.address = deckMan[0].address;
        cabinmanData.status = deckMan[0].status ? 1 : 0;
        cabinmanData.cabinmanReserveConfirm = deckMan[0].cabinmanReserveConfirm ? 1 : 0;
        deckMan[0].shipId.forEach((item) => {
          cabinmanData.shipId.push(item._id);
        });
        cabinmanData.reportPrintLimit = parseInt(deckMan[0].reportPrintLimit);
        // cabinmanData.printerType = deckMan[0].printerType;
        cabinmanData.printingType = deckMan[0].printingType;
      }
    });

    return {
      status,
      cabinmanReserveConfirmStatus,
      cabinmanData,
      errors,
      title,
      cabinmanIdForUpdate,
      updateCabinMan,
      addCabinMan,
      closeModal,
      fieldTypes,
      printingType,
      printerType,
      reservationStatus
    };
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>

<style scoped></style>
