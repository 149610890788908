<template>
  <div :class="[labelColorProp]">
    <input
      class="form-check-input"
      type="checkbox"
      v-model="checked"
      :id="inputIdProp"
    />
    <label class="form-check-label" :for="inputIdProp">
      {{ labelProps }}
    </label>
  </div>
</template>

<script>
import { ref, watch } from "vue";

export default {
  name: "CheckboxSingle",
  props: {
    modelValue: Boolean,
    label: String,
    labelColor: String,
    inputId: String,
  },
  setup(props, { emit }) {
    let checked = ref(props.modelValue);
    const labelProps = ref(props.label);
    let labelColorProp = ref(props.labelColor);
    const inputIdProp = ref(props.inputId)
    watch(
      () => checked.value,
      (value) => {
        emit("update:modelValue", value);
      }
    );
    watch(
      () => props.modelValue,
      (value) => {
        checked.value = value;
      }
    );

    return { checked, labelProps, labelColorProp, inputIdProp };
  },
};
</script>

<style scoped>
.form-check-input {
  width: 1.6em;
  height: 1.6em;
  margin-right: 3px;
}
label {
  vertical-align: sub;
}
</style>