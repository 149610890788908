<template>
  <label :for="inputId" class="col-sm-3 col-form-label font_detail"
    >{{ inputLabel }} :</label
  >
  <div class="col-sm-9">
    <v-date-picker
      class="inline-block h-full"
      mode="date"
      v-model="date"
      :masks="masks"
      :model-config="modelConfig"
      is-dark
      color="teal"
      :min-date="new Date()"
    >
      <template v-slot="{ inputValue, togglePopover }">
        <input
          class="form-control font_detail"
          :value="inputValue"
          @click="togglePopover()"
          readonly
        />
      </template>
    </v-date-picker>
    <slot name="error"></slot>
  </div>
</template>

<script>
import { ref, watch } from "vue";

export default {
  name: "DatePicker",
  props: {
    modelValue: String,
    label: String,
    id: String,
  },
  setup(props, { emit }) {
    let date = ref(props.modelValue);
    const inputLabel = ref(props.label);
    const inputId = ref(props.id);
    const modelConfig = ref({
      type: "string",
      mask: "YYYY-MM-DD",
    });
    const masks = ref({
      input: "DD-MMM-YYYY",
    });
    watch(
      () => date.value,
      () => {
        emit("update:modelValue", date.value);
      }
    );
    watch(
      () => props.modelValue,
      (value) => {
        date.value = value;
      }
    );
    return {
      inputLabel,
      inputId,
      date,
      modelConfig,
      masks,
    };
  },
};
</script>

<style scoped>
</style>