<template>
     <Table
          id="exportable_data"
          v-if="$store.getters.getPrintSuperVisorTripWiseCountList.length"
     >
     <template v-slot:header>
          <tr>
               <td class="font_detail">SL</td>
               <td class="font_detail">Supervisor Name</td>
               <td class="font_detail">Trip Date & Time</td>
               <td class="font_detail">Route</td>
               <td class="font_detail">Ship Name</td>
               <td class="font_detail">Print Count</td>
          </tr>
     </template>
     <template v-slot:body>
          <tr v-for="(item, index) in supervisprTripWisePrintList" :key="index">
               <td class="font_detail">{{ index + 1 }}</td>
               <td class="font_detail">
               {{ item.counterman[0] }}
               </td>
               <td class="font_detail">
               {{ dateFormat(item.tripDateTime) }}
               </td>
               <td class="font_detail">{{ item.route }}</td>
               <td class="font_detail">{{ item.ship }}</td>
               <td class="font_detail">
               {{ item.printCount }}
               </td>
          </tr>
          <!-- <tr class="bg-dark text-white"  >
               <td class="font_detail"></td>
               <td class="font_detail"></td>
               <td class="font_detail"></td>
               <td class="font_detail"></td>
               <td class="font_detail"></td>
               <td class="font_detail"></td>
          </tr> -->
          </template>
          <!-- <template v-slot:pagination>
          <Pagination
               v-model="shipWiseSeatReport.page"
               :records="$store.getters.getShipWiseSeatDetailsOwner.length"
               :per-page="shipWiseSeatReport.limit"
               @paginate="getSeatDetails"
               :options="options"
          />
          </template> -->
     </Table>
</template>
   
<script>
     import moment from "moment";
     import store from "@/store";
     import { computed } from "vue";
     export default {
          name: "tripWiseTicketPrintCountDetails",
          setup() {
               const dateFormat = (date) => {
               return moment(String(date))
                    .utcOffset(0, false)
                    .format("DD-MMM-yyyy hh:mm:ss A");
               };
               const supervisprTripWisePrintList = computed(() => {
                    return store.getters.getPrintSuperVisorTripWiseCountList.filter((item) => {
                         return item.printCount > 1;
                    });
               });
               return {
                    dateFormat,
                    supervisprTripWisePrintList,
               };
          },
   };
</script>

<style>
</style>