<template>
  <div v-if="reportActive">
    <FilterTabBetween>
      <template v-slot:body>
        <div class="col-sm-6 col-md-2">
          <DropdownFilter
            :id="'company'"
            :label="'Company'"
            :defaultOption="'--select company--'"
            v-model="cabinmanWiseReport.companyId"
            :defaultValue="''"
            :dropdown="$store.getters.getCompanyList"
          />
        </div>

        <div v-if="cabinmanWiseReport.companyId" class="col-sm-6 col-md-auto">
          <DropdownFilter
              :id="'ship'"
              :label="'Ship Name'"
              :defaultOption="'--select ship--'"
              v-model="cabinmanWiseReport.shipId"
              :defaultValue="''"
              :dropdown="$store.getters.getCompanyInfo.ships"
          />
        </div>

        <div v-if="cabinmanWiseReport.companyId" class="col-sm-6 col-md-4">
          <DateTimeRangePicker v-model="date.range" />
        </div>
        <div
          v-if="cabinmanWiseReport.companyId"
          class="col-sm-6 col-md-1 col-lg-1 col-xxl-1"
        >
          <FilterInput
            :id="'tripcode'"
            :label="'Trip Code'"
            v-model="cabinmanWiseReport.tripCode"
            :placeholder="'tripcode'"
          />
        </div>

        <div v-if="cabinmanWiseReport.companyId" class="col-sm-6 col-md-auto">
          <DropdownFilter
            :id="'direction'"
            :label="'Direction'"
            :defaultOption="'--select direction--'"
            v-model="cabinmanWiseReport.direction"
            :defaultValue="''"
            :dropdown="dropdownVariables.direction"
          />
        </div>
        <div v-if="cabinmanWiseReport.companyId" class="col-sm-2 col-md-auto">
          <DropdownFilter
            :id="'status'"
            :label="'Status'"
            v-model="cabinmanWiseReport.status"
            :dropdown="dropdownVariables.reportStatus"
          />
        </div>

        <div v-if="cabinmanWiseReport.companyId" class="col-sm-2 col-md-auto">
          <CheckboxSingle v-model="isDiscount" />
        </div>

      </template>

      <template v-slot:export>
        <div class="d-flex justify-content-between gap-2">
          <div
              v-if="cabinmanWiseReport.companyId"
              class="col-sm-2 col-md-auto mt-auto"
          >
            <button class="edit_table_data font_detail" @click="filterReport">
              <i class="fas fa-filter"></i><span class="mx-1 bold">Filter</span>
            </button>
          </div>

          <button
              class="edit_table_data font_detail"
              @click="
            exportExcel(
              'xlsx',
              'Admin_cabinmanWise_Report.',
              undefined,
              'exportable_data'
            )
          "
          >
            <i class="fas fa-download"></i><span class="mx-1 bold">Export</span>
          </button>
        </div>
      </template>

    </FilterTabBetween>
    <Table
      v-if="cabinmanWiseReport.companyId !== '' && filterActive"
      id="exportable_data"
    >
      <template v-slot:header>
        <tr>
          <td class="font_detail">SL</td>
          <td class="font_detail">Cabin Man</td>
          <td class="font_detail">Total Ticket</td>
          <td class="font_detail">Total Seat</td>
          <td class="font_detail">{{discountStatus ? 'Total Discount' : 'Total Amount'}}</td>
          <td class="font_detail">Details</td>
        </tr>
      </template>
      <template v-slot:body>
        <tr
          v-for="(item, index) in $store.getters.getCabinmanWiseReport"
          :key="item._id"
        >
          <td class="font_detail">{{ index + 1 }}</td>
          <td class="font_detail">{{ item.countermanName }}</td>
          <td class="font_detail">{{ item.totalTicket }}</td>
          <td class="font_detail">{{ item.totalSeat }}</td>
          <td class="font_detail">{{ item.totalAmount }} Taka</td>
          <td class="font_detail">
            <button
              class="btn btn-success m-1 btn-sm"
              @click="seatDetails(item.countermanId)"
            >
              Details
            </button>
          </td>
        </tr>
        <tr class="bg-dark text-white" v-if="summary">
          <td class="font_detail"></td>
          <td class="font_detail"></td>
          <td class="font_detail text-white">
            Total Ticket: {{ cabinTicketTotal }}
          </td>
          <td class="font_detail text-white">
            Total Seat: {{ cabinSeatTotal }}
          </td>
          <td class="font_detail text-white">
            {{discountStatus ? 'Total Discount' :'Total Amount'}}: {{ cabinAmountTotal }} Taka
          </td>
          <td class="font_detail"></td>
        </tr>
      </template>
    </Table>
  </div>
  <CabinmanReportDetails
    v-if="seatDetailsActive"
    :status="cabinmanWiseReport.status"
    :direction="cabinmanWiseReport.direction"
    :cabinManId="cabinManIdPass"
    :tripCode="cabinmanWiseReport.tripCode"
    :fromDate="date.range.start"
    :toDate="date.range.end"
    :discount="cabinmanWiseReport.discount"
    v-on:backToShipWiseReport="onChildClick"
  />

  <router-view></router-view>
</template>

<script>
import DateTimeRangePicker from "@/components/layouts/Filter/Inputs/DateTimeRangePicker";
import DropdownFilter from "@/components/layouts/Filter/Inputs/Dropdown";
import FilterInput from "@/components/layouts/Filter/Inputs/Input.vue";
import dropdownVariables from "@/modules/dropdownVariables";
import CheckboxSingle from "@/components/layouts/Inputs/CheckboxSingle.vue";
import { reactive } from "@vue/reactivity";
import moment from "moment";
import { watch, onBeforeMount, ref, inject } from "vue";
import store from "@/store";
import CabinmanReportDetails from "./CabinmanReportDetails.vue";

export default {
  name: "CabinmanReport",
  components: {
    DateTimeRangePicker,
    DropdownFilter,
    CabinmanReportDetails,
    FilterInput,
    CheckboxSingle,
  },

  setup() {
    let cabinDetailsActive = ref(false);
    let seatDetailsActive = ref(false);
    let reportActive = ref(true);
    let cabinManIdPass = ref("");
    let filterActive = ref(false);

    let cabinTicketTotal = ref(0);
    let cabinSeatTotal = ref(0);
    let cabinAmountTotal = ref(0);
    // let goodsTicketTotal = ref(0);
    // let goodsAmountTotal = ref(0);

    let isDiscount = ref(false);
    let discountStatus = ref(false);
    let summary = ref(false);
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const exportExcel = inject("exportExcel");

    const fromDate = moment(new Date()).format("yyyy-MM-DD" + " 00:00:00");
    const toDate = moment(new Date()).format("yyyy-MM-DD" + " 23:59:59");

    let date = reactive({
      range: {
        start: fromDate,
        end: toDate,
      },
    });

    let cabinmanWiseReport = reactive({
      companyId: "",
      shipId: "",
      fromDate: "",
      toDate: "",
      status: 1,
      tripCode: "",
      direction: "",
      discount: "",
      page: 1,
      limit: 10,
    });

    watch(
      () => isDiscount.value,
      () => {
        isDiscount.value
          ? (cabinmanWiseReport.discount = 1)
          : (cabinmanWiseReport.discount = 0);
      }
    );

    watch(
      () => cabinmanWiseReport.companyId,
      (value) => {
        cabinmanWiseReport.companyId = value;
      }
    );

    watch(
      () => cabinmanWiseReport.status,
      (value) => {
        cabinmanWiseReport.status = value;
      }
    );
    watch(
      () => date.range.start,
      (value) => {
        cabinmanWiseReport.fromDate = moment(value).format(
          "yyyy-MM-DD" + " 00:00:00"
        );
      }
    );
    watch(
      () => date.range.end,
      (value) => {
        cabinmanWiseReport.toDate = moment(value).format(
          "yyyy-MM-DD" + " 23:59:59"
        );
      }
    );
    watch(
      () => cabinmanWiseReport.direction,
      (value) => {
        cabinmanWiseReport.direction = value;
      }
    );
    watch(
      () => cabinmanWiseReport.discount,
      (value) => {
        cabinmanWiseReport.discount = value;
      }
    );

    watch(
        () => cabinmanWiseReport.shipId,
        (value) => {
          cabinmanWiseReport.shipId = value;
        }
    );

    watch(
        () => cabinmanWiseReport.companyId,
        () => {
          if (cabinmanWiseReport.companyId !== "") {
            getCompanyInformation();
          }
        }
    );

    const getCompanyInformation = () => {
      showLoader();
      store
          .dispatch("getCompanyInfo", {
            companyId: cabinmanWiseReport.companyId,
          })
          .then(() => hideLoader())
          .catch(() => hideLoader());
    };

    const getAllCompanyList = () => {
      showLoader();
      store
        .dispatch("getAllCompanyList")
        .then(() => hideLoader())
        .catch(() => hideLoader());
    };
    const getAllCabinmanList = () => {
      showLoader();

      store
        .dispatch("getCabinmanWiseReport", cabinmanWiseReport)
        .then((response) => {
          if(isDiscount.value == 1){
            discountStatus.value = true
          } else {
            discountStatus.value = false
          }
          if (response.length === 0) {
            summary.value = false;
          } else {
            summary.value = true;
            cabinTicketTotal.value = 0;
            cabinSeatTotal.value = 0;
            cabinAmountTotal.value = 0;

            sumListData();
          }
          hideLoader();
        })
        .catch(() => hideLoader());
    };
    const filterReport = () => {
      filterActive.value = true;
      getAllCabinmanList();
    };

    const sumListData = () => {
      store.getters.getCabinmanWiseReport.forEach((data) => {
        cabinTicketTotal.value += data.totalTicket;
        cabinSeatTotal.value += data.totalSeat;
        cabinAmountTotal.value += data.totalAmount;
      });
    };

    onBeforeMount(() => {
      getAllCompanyList();
      cabinmanWiseReport.fromDate = fromDate;
      cabinmanWiseReport.toDate = toDate;
    });

    const seatDetails = (counterManId) => {
      cabinManIdPass.value = counterManId;
      cabinDetailsActive.value = false;
      reportActive.value = false;
      seatDetailsActive.value = true;
    };

    const onChildClick = (value) => {
      reportActive.value = value;
      cabinDetailsActive.value = !value;
      seatDetailsActive.value = !value;
      filterReport();
    };

    return {
      dropdownVariables,
      date,
      cabinmanWiseReport,
      filterReport,
      cabinDetailsActive,
      seatDetailsActive,
      seatDetails,
      reportActive,
      cabinManIdPass,
      filterActive,
      onChildClick,
      cabinTicketTotal,
      cabinAmountTotal,
      exportExcel,
      summary,
      isDiscount,
      discountStatus,
      cabinSeatTotal,
    };
  },
};
</script>

<style scoped></style>
