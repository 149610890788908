<template>
  <div class="card card-body py-4" style="background-color: whitesmoke">
    <div class="row">
      <!-- <div v-if="purchaseStatus" class="row m-0">
        <ReservationTicketPrintPreview
          :ticket="purchasedTicket"
          :ticketType="'ticket'"
          :bookingNumber="lBlookingNumber"
          @close="close"
          :dashboardTripData="propsPaginationData"
        />
      </div> -->
      <div class="row m-0">
        <div
          class="col-md-6 col-lg-3 col-xl-3 order-md-1 order-lg-1 order-xl-1"
        >
          <span class="fw-bold">SUMMARY</span>
          <div
            class="card card-body bg-dark border-3 rounded-3 border-secondary"
          >
            <div class="row my-1">
              <div class="col-md-4">
                <span class="text-white fw-bold border-2 border-white"
                  >SEATS</span
                >
              </div>
              <div class="col-md-8">
                <span class="orange-bold-text fw-bold"
                  >#{{ seatInformation.seats.toString() }}</span
                >
              </div>
            </div>
            <div class="row my-1">
              <div class="col-md-4">
                <span class="text-white fw-bold">CLASS</span>
              </div>
              <div class="col-md-8">
                <span class="orange-bold-text fw-bold">{{
                  seatInformation.seatClassName
                }}</span>
              </div>
            </div>
            <div class="card bg-transparent border-1 border-info my-1 py-1">
              <div class="row my-1">
                <div class="col-md-4">
                  <span class="text-white fw-bold">AMOUNT</span>
                </div>
                <div class="col-md-8">
                  <span class="text-white fw-bold"
                    >{{ seatInformation.totalAmount }} Taka</span
                  >
                </div>
              </div>
              <div class="border-top border-1 border-white mx-3"></div>
              <div class="row my-1">
                <div class="col-md-4">
                  <span class="text-white fw-bold">PAYABLE</span>
                </div>
                <div class="col-md-8">
                  <span class="orange-bold-text fw-bold"
                    >{{ seatInformation.payable }} Taka</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-md-12 col-lg-6 col-xl-6 order-md-3 order-lg-2 order-xl-2"
        >
          <span class="fw-bold">RESERVATION INFORMATION</span>
          <div class="card card-body">
            <div class="mx-6">
              <form class="my-2">
                <div class="row">
                  <div class="col-md-6 col-lg-12 col-xl-6">
                    <div class="form-group row my-1">
                      <div class="col-sm-3">
                        <label for="address" class="col-form-label font_detail"
                          >Name</label
                        >
                      </div>
                      <div class="col-sm-9">
                        <input
                          class="form-control font_detail"
                          v-model="passengerDetails.passengerName"
                          placeholder="Passenger Name"
                          type="text">
                      </div>
                    </div>
                    <div class="form-group row my-1">
                      <TicketInputPhone
                        v-model="passengerDetails.passengerPhone"
                        :placeholder="'Passenger Mobile'"
                        :label="'Mobile'"
                        :id="'mobile'"
                        :type="'text'"
                        :min="'0'"
                        :max="'99999999999999'"
                        :maxlength="'14'"
                        :step="'1'"
                        :isDisableKeyBindingInput="true"
                      >
                        <template v-slot:error>
                          <TicketError v-if="errors.passengerPhone" :error="errors.passengerPhone"/>
                        </template>
                      </TicketInputPhone>
                    </div>
                    <div class="form-group row my-1">
                      <TicketDropdown
                        v-model="passengerDetails.passengerGender"
                        :id="'passengerGender'"
                        :label="'Gender'"
                        :dropdown="genderDropdown"
                      >
                        <template v-slot:error>
                          <TicketError
                            v-if="errors.gender"
                            :error="errors.gender"
                          />
                        </template>
                      </TicketDropdown>
                    </div>
                    <div class="form-group row my-1">
                      <TicketInput
                        v-model="passengerDetails.passengerNID"
                        :placeholder="'Passenger NID'"
                        :label="'NID'"
                        :id="'nid'"
                        :type="'text'"
                        :min="'1'"
                        :step="'1'"
                        :onkeypress ="'return event.charCode >= 48 && event.charCode <= 57'"
                      />
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-12 col-xl-6">
                    <div class="form-group row my-1">
                      <div class="col-sm-3">
                        <label for="address" class="col-form-label font_detail">Address</label>
                      </div>
                      <div class="col-sm-9">
                        <textarea
                          class="form-control font_detail"
                          id="address"
                          v-model="passengerDetails.passengerAddress"
                        ></textarea>
                      </div>
                    </div>
                    <div class="form-group row my-1">
                      <TicketDropdown
                        v-model="passengerDetails.boardingPlace"
                        :id="'boardingPlace'"
                        :label="'Boarding place'"
                        :dropdown="boardingPlaceDropDown"
                      >
                        <template v-slot:error>
                          <TicketError
                            v-if="errors.boardingPlace"
                            :error="errors.boardingPlace"
                          />
                        </template>
                      </TicketDropdown>
                    </div>
                    <div class="form-group row my-1">
                      <TicketDropdown
                        v-model="passengerDetails.droppingPoint"
                        :id="'droppingPoint'"
                        :label="'Dropping place'"
                        :dropdown="droppingPointDropDown"
                      >
                        <template v-slot:error>
                          <TicketError
                            v-if="errors.droppingPoint"
                            :error="errors.droppingPoint"
                          />
                        </template>
                      </TicketDropdown>
                    </div>
                  </div>
                </div>
                <div class="row border-top">
                  <div class="col-12 text-center my-2">
                    <span class="fw-bold">PAYMENT INFORMATION</span>
                  </div>
                  <div class="col-md-6 col-lg-12 col-xl-6">
                    <div class="form-group row my-1">
                      <div class="col-sm-3">
                        <label :for="inputId" class="font_detail ">Paid :</label>
                      </div>
                      <div class="col-sm-9">
                        <input 
                          v-model="passengerDetails.paid"
                          placeholder="seatInformation.payable.value"
                          type="Number"  
                          min="0" 
                          step="1" 
                          onkeypress='return event.charCode !== 46 || event.charCode >= 48 && event.charCode <= 57|| event.keyCode == 8 || event.keyCode == 46'
                          onkeydown="if(event.key==='.'){event.preventDefault();}"
                          onkeyup="if(event.key==='.'){event.preventDefault();}"
                          oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"
                          autocomplete="off"
                          class="input_field form-control rounded px-3 w-100 text-left" @click.right.prevent @keydown="keydown" @copy.prevent @paste.prevent
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-12 col-xl-6">
                    <div class="form-group my-1 row">
                      <div class="col-sm-3">
                        <p class="font_detail">Due(s)</p>
                      </div>
                      <div class="col-sm-9">
                        <div class="form-control font_detail available_other available span_font">
                          <span class="orange-bold-text">
                            {{ passengerDetails.dues }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div
          class="col-md-6 col-lg-3 col-xl-3 order-md-2 order-lg-3 order-xl-3"
        >
          <span class="fw-bold">ACTIONS</span>
          <div class="card card-body">
            <div class="row">
              <div class="col-md-12 col-lg-6 col-xl-6">
                <p class="text-center font_detail m-0">RESET</p>
                <button class="reset_button w-100" @click="reset">
                  <i class="fas fa-trash-alt"></i>
                </button>
              </div>
              <div class="col-md-12 col-lg-6 col-xl-6">
                <p class="text-center font_detail m-0">CANCEL</p>
                <button class="cancel_button w-100" @click="close">
                  <i class="fas fa-times"></i>
                </button>
              </div>
              <div class="col-md-12 col-lg-12 col-xl-12">
                <p class="text-center font_detail m-0">Reserve Ticket</p>
                <button class="reserve_button w-100" @click="reserveTicket">
                  <i class="fas fa-ticket-alt"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dropdownVariables from "@/modules/dropdownVariables";
import useInputValidation from "@/modules/useInputValidations";
import { onBeforeMount, ref, computed, watch, inject } from "vue";
import { reactive } from "@vue/reactivity";
import store from "@/store";
import moment from "moment";
export default {
  name: "TicketPurchase",
  props: {
    seatInfo: Object,
    passPagination: Object,
    bookingNumber: [String, Number],
  },
  setup(props, { emit }) {
    const propsPaginationData = ref(props.passPagination);
    const seatInformation = ref(props.seatInfo);
    let { requiredValidation, validPhoneValidation, errors } =
      useInputValidation();
    let passengerDetails = reactive({
      ticketType: "RESERVED",
      issuedBy: store.getters.userName,
      countermanId: store.getters.userId,
      passengerName: "",
      passengerPhone: "",
      passengerGender: "",
      passengerNID: "",
      passengerAddress: "",
      boardingPlace: "",
      droppingPoint: "",
      companyId: "",
      tripId: "",
      direction: "",
      shipId: "",
      shipName: "",
      seatClassId: "",
      numberOfTicketCopy: "",
      passengerCopy: "",
      officeCopy: "",
      seatClassName: "",
      seatNumbers: "",
      totalAmount: "0",
      discount: "0",
      payable: "0",
      paid: "0",
      dues: 0,
      refund: 0,
      ticketDateTime: "",
      bookingNumber: props.bookingNumber,
      floorId: "",
      floorName: "",
    });
    const title = ref("Print Booking");
    const purchaseStatus = ref(false);
    let purchasedTicket = reactive({
      bookingNumber: props.bookingNumber,
      seatNumbersForPrintArray: [],
      seatNumbersForPrint: "",
    });
    let reA = /[^a-zA-Z]/g;
    let reN = /[^0-9]/g;
    const status = dropdownVariables.status;
    const gender = ref(['Male', 'Female']);
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    let lBlookingNumber = ref(props.bookingNumber);
    let defaultBoardingPlace = ref(store.getters.getDefaultBoardingPlace);
    // let boardingPlaces = ref(
    //   store.getters.getTripForTicketing(seatInformation.value.tripId)[0].route
    //     .boardingPlace
    // );
    // let droppingPoints = ref(
    //   store.getters.getTripForTicketing(seatInformation.value.tripId)[0].route
    //     .droppingPoint
    // );
    watch(
      () => passengerDetails.paid,
      () => {
        if (passengerDetails.paid > passengerDetails.payable) {
          passengerDetails.paid = 0;
        }
        passengerDetails.paid = Math.round(passengerDetails.paid)
        passengerDetails.dues =
        parseInt(passengerDetails.payable) - passengerDetails.paid;
      }
    );
    watch(
      () => lBlookingNumber,
      () => {
        purchasedTicket.bookingNumber = lBlookingNumber;
      }
    );
    watch(
      () => passengerDetails.passengerGender,
      () => {
        requiredValidation("gender", passengerDetails.passengerGender);
      }
    );
    watch(
      () => passengerDetails.boardingPlace,
      () => {
        requiredValidation("boardingPlace", passengerDetails.boardingPlace);
      }
    );
    watch(
      () => passengerDetails.droppingPoint,
      () => {
        requiredValidation("droppingPoint", passengerDetails.droppingPoint);
      }
    );
    watch(
      () => passengerDetails.passengerPhone,
      () => {
        validPhoneValidation("passengerPhone", passengerDetails.passengerPhone);
      }
    );
    // const initDefaultBoardingPlace = () => {
    //   return boardingPlaces.value.filter(
    //     (boardingPlace) => boardingPlace == defaultBoardingPlace.value
    //   );
    // };
    const genderDropdown = computed(()=>{
      let genders = {};
      gender.value.forEach((item) => {
        genders[item] = item;
      });
      return genders;
    })
    const boardingPlaceDropDown = computed(() => {
      let boardingPlace = {};
      let boarding = store.getters.getTripForTicketing(
        seatInformation.value.tripId
      )[0].route.boardingPlace;
      if (
        store.getters.getTripForTicketing(seatInformation.value.tripId)[0]
          .direction === "DOWN"
      ) {
        boarding = store.getters.getTripForTicketing(
          seatInformation.value.tripId
        )[0].route.droppingPoint;
      } else {
        boarding = store.getters.getTripForTicketing(
          seatInformation.value.tripId
        )[0].route.boardingPlace;
      }
      boarding.forEach((item) => {
        boardingPlace[item] = item;
      });
      return boardingPlace;
    });
    const droppingPointDropDown = computed(() => {
      let droppingPoint = {};
      let boarding = store.getters.getTripForTicketing(
        seatInformation.value.tripId
      )[0].route.droppingPoint;
      if (
        store.getters.getTripForTicketing(seatInformation.value.tripId)[0]
          .direction === "DOWN"
      ) {
        boarding = store.getters.getTripForTicketing(
          seatInformation.value.tripId
        )[0].route.boardingPlace;
      } else {
        boarding = store.getters.getTripForTicketing(
          seatInformation.value.tripId
        )[0].route.droppingPoint;
      }
      boarding.forEach((item) => {
        droppingPoint[item] = item;
      });
      return droppingPoint;
    });
    const reset = () => {
      passengerDetails.passengerName = "";
      passengerDetails.passengerPhone = "";
      passengerDetails.passengerGender = "";
      passengerDetails.passengerNID = "";
      passengerDetails.passengerAddress = "";
      passengerDetails.boardingPlace = "";
      passengerDetails.droppingPoint = "";
    };
    const validate = () => {
      Object.keys(errors).forEach(function (key) {
        errors[key] = "";
      });
      requiredValidation("gender", passengerDetails.passengerGender);
      requiredValidation("boardingPlace", passengerDetails.boardingPlace);
      requiredValidation("droppingPoint", passengerDetails.droppingPoint);
      validPhoneValidation("passengerPhone", passengerDetails.passengerPhone);
      return !(
        Object.values(errors).length <= 0 ||
        !Object.values(errors).every((x) => x === null || x === "")
      );
    };
    const close = () => {
      emit("refreshPageGoBack");
    };
    const reserveTicket = () => {
      passengerDetails.ticketDateTime = moment(new Date())
        .utcOffset(0, true)
        .format("yyyy-MM-DD hh:mm:ss");
      if (validate()) {
        showLoader();
        store
          .dispatch("reservePassengerTicket", passengerDetails)
          .then((ticket) => {
            hideLoader();
            setSeatNumbersForPrint(ticket);
            purchaseStatus.value = true;
            close();
          })
          .catch((error) => {
            purchaseStatus.value = false;
            hideLoader();
            if (error.status === 401) {
              reserveTicket();
            }
          });
      }
    };
    const setSeatNumbersForPrint = (ticket) => {
      for (let index = 0; index < ticket.numberOfTicketCopy; index++) {
        purchasedTicket.seatNumbersForPrintArray = [
          ...purchasedTicket.seatNumbersForPrintArray,
          ...ticket.seatNumbers.split(","),
        ];
      }
      purchasedTicket.seatNumbersForPrint =
        purchasedTicket.seatNumbersForPrintArray.sort(sortAlphaNum).toString();
      delete purchasedTicket.seatNumbersForPrintArray;
      purchasedTicket = Object.assign(purchasedTicket, ticket);
    };
    const sortAlphaNum = (a, b) => {
      let aA = a.replace(reA, "");
      let bA = b.replace(reA, "");
      if (aA === bA) {
        let aN = parseInt(a.replace(reN, ""), 10);
        let bN = parseInt(b.replace(reN, ""), 10);
        return aN === bN ? 0 : aN > bN ? 1 : -1;
      } else {
        return aA > bA ? 1 : -1;
      }
    };
    onBeforeMount(() => {
      passengerDetails.companyId = store.getters.userCompany;
      passengerDetails.tripId = seatInformation.value.tripId;
      passengerDetails.direction = store.getters.getTripForTicketing(
        seatInformation.value.tripId
      )[0].direction;
      passengerDetails.shipId = store.getters.getTripForTicketing(
        seatInformation.value.tripId
      )[0].ship._id;
      passengerDetails.shipName = store.getters.getTripForTicketing(
        seatInformation.value.tripId
      )[0].ship.name;
      passengerDetails.seatClassId = seatInformation.value.seatClassId;
      passengerDetails.numberOfTicketCopy =
        seatInformation.value.numberOfTicketCopy;
      passengerDetails.passengerCopy = seatInformation.value.passengerCopy;
      passengerDetails.officeCopy = seatInformation.value.officeCopy;
      passengerDetails.seatClassName = seatInformation.value.seatClassName;
      passengerDetails.seatNumbers = seatInformation.value.seats.toString();
      passengerDetails.totalAmount = seatInformation.value.totalAmount;
      passengerDetails.discount = seatInformation.value.discount;
      passengerDetails.payable = seatInformation.value.payable;
      passengerDetails.paid = 0;
      passengerDetails.floorId = seatInformation.value.floorId;
      passengerDetails.floorName = seatInformation.value.floorName;
      passengerDetails.passengerGender = gender.value[0]
      if (
          store.getters.getTripForTicketing(seatInformation.value.tripId)[0]
              .direction === "DOWN"
      ) {
        passengerDetails.boardingPlace = defaultBoardingPlace.value && boardingPlaceDropDown.value[defaultBoardingPlace.value] ? defaultBoardingPlace.value :
            store.getters.getTripForTicketing(
                seatInformation.value.tripId
            )[0].route.droppingPoint[0];
        passengerDetails.droppingPoint = store.getters.getTripForTicketing(
            seatInformation.value.tripId
        )[0].route.boardingPlace[0];
      } else {
        passengerDetails.boardingPlace = defaultBoardingPlace.value && boardingPlaceDropDown.value[defaultBoardingPlace.value] ? defaultBoardingPlace.value :
            store.getters.getTripForTicketing(
                seatInformation.value.tripId
            )[0].route.boardingPlace[0];
        passengerDetails.droppingPoint = store.getters.getTripForTicketing(
            seatInformation.value.tripId
        )[0].route.droppingPoint[0];
      }
      // if (
      //   store.getters.getTripForTicketing(seatInformation.value.tripId)[0]
      //     .direction === "DOWN"
      // ) {
      //   passengerDetails.boardingPlace = droppingPoints.value[0];
      //   passengerDetails.droppingPoint =
      //     initDefaultBoardingPlace().length > 0
      //       ? initDefaultBoardingPlace()[0]
      //       : boardingPlaces.value[0];
      // } else {
      //   passengerDetails.boardingPlace =
      //     initDefaultBoardingPlace().length > 0
      //       ? initDefaultBoardingPlace()[0]
      //       : boardingPlaces.value[0];
      //   passengerDetails.droppingPoint = droppingPoints.value[0];
      // }
    });
    return {
      seatInformation,
      purchaseStatus,
      purchasedTicket,
      reserveTicket,
      status,
      title,
      passengerDetails,
      genderDropdown,
      boardingPlaceDropDown,
      droppingPointDropDown,
      dropdownVariables,
      propsPaginationData,
      errors,
      reset,
      close,
      lBlookingNumber,
      // initDefaultBoardingPlace,
      defaultBoardingPlace,
    };
  },
};
</script>

<style scoped>
.ticket-info {
  font-size: 12px !important;
}
.cancel_button {
  margin: 1px 2px;
  border: 2px solid #ff0000;
  border-radius: 4px;
  background-color: #ff0000;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}
.cancel_button:hover,
.cancel_button:focus {
  border: 2px solid #ff0000;
  background-color: #fff;
  color: #ff0000;
  transition: 0.4s;
  outline: 0;
}
.confirm_button {
  margin: 1px 2px;
  border: 2px solid #34a853;
  border-radius: 4px;
  background-color: #34a853;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}
.confirm_button:hover,
.confirm_button:focus {
  border: 2px solid #34a853;
  background-color: #fff;
  color: #34a853;
  transition: 0.4s;
  outline: 0;
}
.reserve_button {
  margin: 1px 2px;
  border: 1px solid #5c1916;
  border-radius: 4px;
  background-color: #5c1916;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}
.reserve_button:hover,
.reserve_button:focus {
  border: 1px solid #5c1916;
  background-color: #fff;
  color: #5c1916;
  transition: 0.4s;
  outline: 0;
}
.reset_button {
  margin: 1px 2px;
  border: 2px solid #ffc107;
  border-radius: 4px;
  background-color: #ffc107;
  padding: 2px 6px;
  color: #ffff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}
.reset_button:hover,
.reset_button:focus {
  border: 2px solid #ffc107;
  background-color: #fff;
  color: #ffc107;
  transition: 0.4s;
  outline: 0;
}
.available {
  color: #000000;
  padding: 5px 10px;
  border-radius: 4px;
  text-transform: uppercase;
}
.span_font {
  font-size: 14px;
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: 500;
}
</style>