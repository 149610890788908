<template>
  <div class="text-end">
    <FilterTab>
      <template v-slot:body>
        <div class="col-sm-6 col-md-2"></div>
      </template>
      <template v-slot:button2>
        <button class="edit_table_data font_detail" @click="goBack">
          <i class="fas fa-arrow-left"></i
          ><span class="mx-1 bold">Go Back</span>
        </button>
      </template>
      <template v-slot:button1>
        <button
          class="edit_table_data font_detail"
          @click="exportExcel('xlsx')"
        >
          <i class="fas fa-download"></i><span class="mx-1 bold">Export</span>
        </button>
      </template>
    </FilterTab>
  </div>
  <Table id="exportable_data">
    <template v-slot:header>
      <tr>
        <td class="font_detail">SL</td>
        <td class="font_detail">Ticket Date Time</td>
        <td class="font_detail">Ship Name</td>
        <td class="font_detail">Goods Type</td>
        <td class="font_detail">Amount</td>
        <td class="font_detail">Issued By</td>
      </tr>
    </template>
    <template v-slot:body>
      <tr
        v-for="(item, index) in $store.getters.getShipWiseGoodDetails"
        :key="index"
      >
        <td class="font_detail">{{ index + 1 }}</td>

        <td class="font_detail">{{ dateFormat(item.ticketDateTime) }}</td>
        <td class="font_detail">{{ item.shipName }}</td>
        <td class="font_detail">
          {{ item.goodsType }}
        </td>
        <td class="font_detail">{{ item.totalAmount }} Taka</td>
        <td class="font_detail">{{ item.issuedBy }}</td>
      </tr>
      <tr class="bg-dark text-white">
        <td class="font_detail"></td>
        <td class="font_detail"></td>
        <td class="font_detail"></td>
        <td class="font_detail"></td>
        <td class="font_detail text-white">
          Total Amount:
          {{ totalAmount }} Taka
        </td>
        <td class="font_detail"></td>
      </tr>
    </template>
    <!-- <template v-slot:pagination>
      <pagination
        v-model="shipWiseGoodReport.page"
        :records="$store.getters.getTotalShipWiseGoodDetails"
        :per-page="shipWiseGoodReport.limit"
        @paginate="getDeckDetails"
        :options="options"
      />
    </template> -->
  </Table>
</template>


<script>
import { reactive } from "@vue/reactivity";
import { ref, onBeforeMount, inject } from "vue";
import store from "@/store";
import XLSX from "xlsx";
import moment from "moment";

export default {
  name: "GoodDetails",
  components: {},
  props: {
    shipId: String,
    shipName: String,
    status: Number,
    discount: [Number, String],
    fromDate: String,
    toDate: String,
    direction: String,
  },
  emits: ["backToShipWiseReport"],
  setup(props, { emit }) {
    let shipId = ref(props.shipId);
    let shipNameProps = ref(props.shipName);
    let totalAmount = ref(0);
    let totalTicket = ref(0);

    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");

    let shipWiseGoodReport = reactive({
      ticketType: "GOODS",
      shipId: shipId.value,
      fromDate: props.fromDate,
      toDate: props.toDate,
      direction: props.direction,
      discount: props.discount,
      status: props.status,
      page: 1,
      limit: 10,
    });

    const options = {
      texts: {
        count: "",
      },
    };

    const dateFormat = (date) => {
      return moment(String(date))
        .utcOffset(0, false)
        .format("DD-MMM-yyyy hh:mm A");
    };

    const getDeckDetails = () => {
      showLoader();
      store
        .dispatch("getGoodDetails", shipWiseGoodReport)
        .then(() => {
          hideLoader();
          goodDetailsdata();
        })
        .catch(() => hideLoader());
    };

    const goodDetailsdata = () => {
      store.getters.getShipWiseGoodDetails.forEach((data) => {
        totalAmount.value += data.totalAmount;
        totalTicket.value += data.totalTicket;
      });
    };

    const goBack = () => {
      emit("backToShipWiseReport", true);
    };

    const exportExcel = (type, fn, dl) => {
      if (document.getElementById("exportable_data") !== null) {
        var elt = document.getElementById("exportable_data");
        var wb = XLSX.utils.table_to_book(elt, { sheet: "sheet Js" });
        return dl
          ? XLSX.write(wb, { bookType: type, bookSST: true, type: "base64" })
          : XLSX.writeFile(
              wb,
              fn || "Admin_ShipWise_Deck_Report_Goods." + (type || "xlsx")
            );
      }
    };

    onBeforeMount(() => {
      getDeckDetails();
    });

    return {
      shipWiseGoodReport,
      goBack,
      exportExcel,
      shipNameProps,
      getDeckDetails,
      options,
      dateFormat,
      totalAmount,
      totalTicket,
    };
  },
};
</script>
