<template>
  <div>
    <FilterTab v-if="!isInactiveFilter">
      <template v-slot:title><h6>Seat Plan ({{shipName}})</h6></template>
      <template v-slot:body>
        <div class="col-sm-6 col-md-2"></div>
      </template>
      <template v-slot:button1>
        <button class="edit_table_data font_detail" @click="goBack">
          <i class="fas fa-arrow-left"></i
          ><span class="mx-1 bold">Go Back</span>
        </button>
      </template>
    </FilterTab>
    <div
      v-if="hasPermission(Owner.ownerCompanySetupWrite)"
      class="card mx-5 new-class mt-2"
    >
      <div class="card-body">
        <h6 class="card-title bold">Add New Class</h6>
        <span v-if="error" class="text-danger err_msg">* {{ error }}</span>
        <div class="row align-items-center">
          <div class="col-md-2">
            <Input
              v-model="seatClassData.name"
              :placeholder="'Enter Class Name'"
              :id="'name'"
              :type="'text'"
            />
            <Error v-if="errors.name" v-bind:error="errors.name" />
          </div>
          <div class="col-md-1">
            <Input
              v-model="seatClassData.upFare"
              :placeholder="'Up Fare'"
              :id="'upFare'"
              :type="'number'"
            >
            </Input>
            <Error v-if="errors.upFare" v-bind:error="errors.upFare" />
          </div>
          <div class="col-md-1">
            <Input
              v-model="seatClassData.downFare"
              :placeholder="'Down Fare'"
              :id="'downFare'"
              :type="'number'"
            >
            </Input>
            <Error v-if="errors.downFare" v-bind:error="errors.downFare" />
          </div>
          <div class="col-md-2">
            <Input
              v-model="seatClassData.numberOfTicketCopy"
              :placeholder="'No of Ticket Copy'"
              :id="'numberOfTicketCopy'"
              :type="'number'"
            >
            </Input>
            <Error
              v-if="errors.numberOfTicketCopy"
              v-bind:error="errors.numberOfTicketCopy"
            />
          </div>
          <div class="col">
            <CheckboxInput
              v-model="seatClassData.passengerCopy"
              :label="'Passenger Copy'"
              :labelColor="'text-muted'"
              :input-id="passengerCopy"
            />
          </div>
          <div class="col">
            <CheckboxInput
              v-model="seatClassData.officeCopy"
              :label="'Office Copy'"
              :labelColor="'text-muted'"
              :input-id="officeCopy"
            />
          </div>
          <div class="col">
            <CheckboxInput
              v-model="seatClassData.isCabin"
              :label="'Cabin'"
              :labelColor="'text-muted'"
              :input-id="cabin"
            />
          </div>
          <div class="col-md-2">
            <input
              type="submit"
              class="submit_button mt-1 w-50"
              @click.prevent="addSeatClass"
            />
          </div>
        </div>
      </div>
    </div>
    <div id="accordion" v-if="$store.getters.getShipOwner(shipId)">
      <div
        v-for="seatPlan in $store.getters
          .getShipOwner(shipId)[0]
          .seatPlan.filter((item) => item.floorId === floorId)"
        :key="seatPlan._id"
      >
        <SeatPlanCard
          v-if="seatPlan"
          :company="companyId"
          :seatPlanId="seatPlan._id"
          :ship="shipId"
          :floor="floorId"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from "@vue/reactivity";
import Input from "@/components/layouts/Inputs/Input";
import Error from "@/components/layouts/Inputs/Error";
import useInputValidation from "@/modules/useInputValidations";
import dropdownVariables from "@/modules/dropdownVariables";
import { inject, onBeforeMount, ref } from "vue";
import SeatPlanCard from "./SeatPlanCard";
import store from "@/store";
import FilterTab from "@/components/layouts/Filter/FilterTab";
import permission from "@/modules/permission";
import roles from "@/modules/roles";
import CheckboxInput from "@/components/layouts/Inputs/CheckboxInput.vue";

const { hasPermission } = permission();
const { Owner } = roles();

export default {
  name: "SeatPlan",
  props: {
    ship: String,
    shipName: String,
    company: String,
    inactiveFilter: Boolean,
    floor: String,
  },
  components: { Input, Error, SeatPlanCard, FilterTab, CheckboxInput },
  setup(props, { emit }) {
    let seatClassData = reactive({
      id: "",
      companyId: "",
      shipId: "",
      name: "",
      upFare: "",
      downFare: "",
      numberOfTicketCopy: "",
      blockedSeat: "",
      status: 1,
      passengerCopy: false,
      officeCopy: false,
      isCabin: false,
      floorId: props.floor,
    });
    let { requiredValidation, validNumberValidation, errors } =
      useInputValidation();
    let shipId = ref(props.ship);
    let companyId = ref(props.company);
    const floorId = ref(props.floor);
    let isInactiveFilter = ref(props.inactiveFilter);
    let error = ref("");
    const status = dropdownVariables.status;
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");

    const clear = () => {
      seatClassData.id = "";
      seatClassData.name = "";
      seatClassData.upFare = "";
      seatClassData.downFare = "";
      seatClassData.numberOfTicketCopy = "";
      seatClassData.status = 1;
      seatClassData.passengerCopy = false;
      seatClassData.officeCopy = false;
      seatClassData.isCabin = false;
    };
    const goBack = () => {
      emit("toggle");
    };
    const validate = () => {
      Object.keys(errors).forEach(function (key) {
        errors[key] = "";
      });
      requiredValidation("name", seatClassData.name);
      validNumberValidation("upFare", seatClassData.upFare);
      validNumberValidation("downFare", seatClassData.downFare);
      validNumberValidation(
        "numberOfTicketCopy",
        seatClassData.numberOfTicketCopy
      );
      return !(
        Object.values(errors).length <= 0 ||
        !Object.values(errors).every((x) => x === null || x === "")
      );
    };
    const addSeatClass = () => {
      if (validate()) {
        showLoader();
        error.value = "";
        store
          .dispatch("addSeatClassOwner", seatClassData)
          .then(() => {
            hideLoader();
            clear();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              addSeatClass();
            } else {
              error.value = error;
            }
          });
      }
    };

    onBeforeMount(() => {
      seatClassData.shipId = shipId.value;
      seatClassData.companyId = companyId.value;
    });

    return {
      seatClassData,
      errors,
      status,
      shipId,
      companyId,
      error,
      Owner,
      hasPermission,
      addSeatClass,
      goBack,
      isInactiveFilter,
      floorId,
    };
  },
};
</script>

<style scoped>
.new-class {
  background: #f3f3f3;
}

.bold {
  font-weight: bold;
}
</style>
