import SecureLS from "secure-ls";
import Vuex from "vuex";
import { createStore } from "vuex-extensions";
import createPersistedState from "vuex-persistedstate";
import Admins from "./modules/Admin/admins";
import Company from "./modules/Admin/company";
import Dashboard from "./modules/Admin/dashboard";
import Report from "./modules/Admin/report";
import Agent from "./modules/Agent/agent";
import AgentDashboard from "./modules/Agent/agentDashboard";
import AgentReport from "./modules/Agent/agentReport";
import AgentShip from "./modules/Agent/agentShip";
import AgentTrip from "./modules/Agent/agentTrip";
import Auth from "./modules/auth";
import DigitalTicketing from "./modules/Admin/digitalTicketing";
import CountermanDashboard from "./modules/Counterman/countermanDashboard";
import CountermanReport from "./modules/Counterman/countermanReport";
import CountermanShip from "./modules/Counterman/countermanShip";
import CountermanTrip from "./modules/Counterman/countermanTrip";
import CountermanVerify from "./modules/Counterman/countermanVerify";
import OwnerDashBoard from "./modules/Owner/ownerDashboard";
import OwnerOwners from "./modules/Owner/ownerOwners";
import OwnerReport from "./modules/Owner/ownerReport";
import OwnerServices from "./modules/Owner/ownerServices";
import SupervisorDashboard from "./modules/Supervisor/SupervisorDashboard";
import SupervisorReport from "./modules/Supervisor/supervisorReport";
import SupervisorVerify from "./modules/Supervisor/supervisorVerify";

var ls = new SecureLS({ encodingType: "base64", isCompression: false });
const store = createStore(Vuex.Store, {
  modules: {
    auth: Auth,
    admin: Admins,
    company: Company,
    report: Report,
    digitalTicketing: DigitalTicketing,
    countermanDashboard: CountermanDashboard,
    countermanShip: CountermanShip,
    countermanTrip: CountermanTrip,
    countermanReport: CountermanReport,
    countermanVerify: CountermanVerify,
    supervisorDashboard: SupervisorDashboard,
    supervisorReport: SupervisorReport,
    supervisorVerify: SupervisorVerify,
    agentDashboard: AgentDashboard,
    agentShip: AgentShip,
    agentTrip: AgentTrip,
    agentReport: AgentReport,
    dashboard: Dashboard,
    agent: Agent,
    ownerDashBoard: OwnerDashBoard,
    ownerReport: OwnerReport,
    ownerOwners: OwnerOwners,
    ownerServices: OwnerServices,
  },
  plugins: [
    createPersistedState({
      key: "intercity",
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
});

export default store;
