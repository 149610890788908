<template>
  <Modal @close="closeModal">
    <template v-slot:title>Delete Local Route</template>
    <template v-slot:body>
      <form>
        <div class="form-group row my-1 px-3">
          Do you want to delete local route?
        </div>
        <div class="form-group row my-1">
          <label class="col-sm-3 col-form-label"></label>
          <div class="col-sm-9">
            <input
              type="submit"
              value="Delete"
              class="mt-3 py-2 submit_button font_detail float-end"
              @click.prevent="deleteLocalRoute"
            />
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
import { inject, ref } from "vue";

import store from "@/store";

export default {
  name: "LocalRouteDeleteModal",
  props: {
    routeIdValue: [String, Number],
    localRoutesIdValue : [String, Number]
  },
  setup(props, { emit }) {
    const localRouteID = ref(props.localRoutesIdValue);
    const rId = ref(props.routeIdValue);

    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const localRoute = {
      id: localRouteID.value,
      routeId: rId.value,
    };
    const closeModal = () => {
      emit("getAllLocalRoutes");
      emit("update:modelValue", false);
    };
    const deleteLocalRoute = () => {
      showLoader();

      store
        .dispatch("deleteOwnerServicesLocalRouteforRoute", { localRoute } )
        .then(() => {
          hideLoader();
          closeModal();
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            deleteLocalRoute();
          }
        });
    };

    return {
      deleteLocalRoute,
      closeModal,
    };
  },
};
</script>

<style scoped></style>
