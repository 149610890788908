<template>
  <div>
    <div v-if="addUpdate">
      <ShipsAddUpdateModal v-model="addUpdate" :ship="shipId" :modalType="actionType" :company="pagination.companyId"
                           @getAllShips="getAllShips"/>
    </div>
    <div v-if="seatPlan">
      <SeatPlan :ship="shipId" :shipName="shipName" :company="pagination.companyId" :inactiveFilter="filter" @close="close" @toggle="toggle"/>
    </div>
    <div v-else-if="floorPlan">
      <FloorPlan :ship="shipId" :shipName="shipName" :company="pagination.companyId" @close="close" @toggle="toggle"/>
    </div>
    <div v-else>
      <FilterTab>
        <template v-slot:title><h6>Ships</h6></template>
        <template v-slot:body>
          <div class="col-sm-6 col-md-2">
          </div>
        </template>
        <template v-if="hasPermission(Admin.companyDeployWrite)" v-slot:button1>
            <button class="edit_table_data font_detail" @click="toggle">
                <i class="fas fa-plus"></i><span class="mx-1 bold">Add New</span>
            </button>
        </template>
      </FilterTab>
      <Table v-if="$store.getters.getShips">
        <template v-slot:header>
          <tr>
            <td class="font_detail"> SL</td>
            <td class="font_detail"> NAME</td>
            <td class="font_detail"> SEAT PLAN</td>
            <td class="font_detail"> STATUS</td>
            <td v-if="hasPermission(Admin.companyDeployWrite)" class="font_detail"> ACTION</td>
          </tr>
        </template>
        <template v-slot:body>
          <tr v-for="(ship,index) in $store.getters.getShips" :key="ship._id">
            <td class="font_detail">{{ pagination.limit*(pagination.page-1) +index +1 }}</td>
            <td class="font_detail">{{ ship.name }}</td>
            <td class="font_detail">
              <button class="edit_table_data font_detail add_table_button px-2" @click="updateSeatPlan(ship)"><i class="fas fa-ship"></i><span class="mx-1">Create</span></button>
            </td>
            <td><span v-if="ship.status" class="badge bg-success">{{ dropdownVariables.status[1] }}</span>
              <span v-else class="badge bg-danger">{{ dropdownVariables.status[0] }}</span>
            </td>
            <td v-if="hasPermission(Admin.companyDeployWrite)">
              <button class="edit_table_data font_detail edit_table_button" @click="updateShips(ship._id, 'update')"><i
                  class="fas fa-edit"></i><span class="mx-1">Edit</span>
              </button>
              <button class="edit_table_data font_detail edit_table_button" @click="updateShips(ship._id, 'copy')"><i
                  class="fas fa-copy"></i><span class="mx-1">Copy</span>
              </button>
            </td>
          </tr>
        </template>
        <template v-slot:pagination>
          <pagination v-model="pagination.page" :records="$store.getters.getTotalShip" :per-page="pagination.limit"
                      @paginate="getAllShips" :options="options"/>
        </template>
      </Table>
    </div>
  </div>
</template>

<script>
import {reactive} from "@vue/reactivity";
import {inject, onBeforeMount, ref} from "vue";
import store from "@/store";
import dropdownVariables from "@/modules/dropdownVariables";
import {useRoute} from "vue-router";
import ShipsAddUpdateModal from "./ShipsAddUpdateModal";
import SeatPlan from "./SeatPlan/SeatPlan";
import FloorPlan from "./SeatPlan/FloorPlan";
import permission from "@/modules/permission";
import roles from "@/modules/roles";

const {hasPermission} = permission();
const {Admin} = roles();

export default {
  name: "Ships",
  components: {ShipsAddUpdateModal, SeatPlan, FloorPlan},
  setup() {
    let pagination = reactive({
      page: 1,
      limit: 20,
      companyId: ""
    });
    const addUpdate = ref(false);
    const seatClass = ref(false);
    const seatPlan = ref(false);
    const floorPlan = ref(false)
    const filter = ref(false)
    const showLoader = inject('showLoader');
    const hideLoader = inject('hideLoader');
    const shipId = ref("");
    const actionType = ref("");
    const shipName = ref("");
    const options = {
      texts: {
        count: ""
      }
    };
    const route = useRoute();

    const getAllShips = () => {
      showLoader()
      store.dispatch('getShipsByPage', pagination).then(() => {
        hideLoader()
        getAllRoutes();
      }).catch((error)=>{
        hideLoader()
        if(error.status===401){
          getAllShips()
        }
      });
    };
    const getAllRoutes = () =>{
      showLoader()
      store.dispatch("getRouteList", {companyId: pagination.companyId}).then(() => {hideLoader()}).catch((error)=>{
        hideLoader()
        if(error.status===401){
          getAllRoutes()
        }
      });
    }
    const close = () => {
      addUpdate.value = false;
      seatClass.value = false;
      seatPlan.value = false;
      floorPlan.value = false;
    };
    const toggle = () => {
      shipId.value = '';
      if (seatClass.value || seatPlan.value || floorPlan.value) {
        seatClass.value = false;
        seatPlan.value = false;
        floorPlan.value = false;
      } else {
        addUpdate.value = addUpdate.value === false;
      }
    };
    
    const updateShips = (id, type) => {
      shipId.value = id;
      actionType.value = type;
      addUpdate.value = addUpdate.value === false;
    };

    const updateSeatPlan = (ship) => {
      shipId.value = ship._id;
      shipName.value = ship.name;
      if (ship.isMultiFloor){
        floorPlan.value = true
      }else {
        seatPlan.value = true
      }
    };

    onBeforeMount(() => {
      if (route.query.id) {
        pagination.companyId = route.query.id;
        getAllShips();
      }
    });

    return {
      options, pagination, dropdownVariables, addUpdate, seatClass, shipId, actionType, shipName, seatPlan,Admin,
      hasPermission,
      getAllShips, toggle, updateShips, close, updateSeatPlan, floorPlan,filter,
    };
  }
};
</script>

<style scoped>
.bold{
  font-weight: bold;
}

</style>