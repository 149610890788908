<template>
  <v-date-picker
      class="inline-block h-full"
      v-model="range"
      mode="dateTime"
      :masks="masks"
      :model-config="modelConfig"
      color="teal"
      is-dark
      is-range
  >
    <template v-slot="{ inputValue, isDragging,togglePopover }">
      <div class="flex flex-col sm:flex-row justify-start items-center">
        <div class="row">
          <div class="col-md-6">
            <div class="relative flex-grow">
              <label for="dateTimeRangePicker1" class="text-white">From :</label>
              <input
                  id="dateTimeRangePicker1"
                  class="select_item form-select-sm font_detail w-100"
                  :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                  :value="inputValue.start"
                  @click="togglePopover()"
                  readonly
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="relative flex-grow">
              <label for="dateTimeRangePicker2" class="text-white">To :</label>
              <input
                  id="dateTimeRangePicker2"
                  class="select_item form-select-sm font_detail w-100"
                  :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                  :value="inputValue.end"
                  @click="togglePopover()"
                  readonly
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </v-date-picker>
</template>

<script>
import {ref, watch} from "vue";

export default {
  name: "DateTimeRangePicker",
  props:{
      modelValue: [Object],
  },
  setup(props,{emit}) {
    let range = ref(props.modelValue);
    const modelConfig = ref({
      type: 'string',
      mask: 'YYYY-MM-DD HH:mm',
    });
    const masks = ref({
      inputDateTime: 'DD-MMM-YYYY hh:mm A',
    })
    watch(() => range.value, () => {
      range.value.start+=":00";
      range.value.end+=":59";
      emit("update:modelValue", range.value);
    });
    watch(() => props.modelValue, (value) => {
      range.value = value;
    });

    return {
      range, masks,modelConfig
    }
  }
}
</script>

<style scoped>

</style>