<template>
  <ReserveTicketCancel
    v-if="modelValue"
    v-model="modelValue"
    :ticketIdStore="ticketIdStore"
    :ticketAmountPaid="ticketAmountPaid"
    :ticketAmountDues="ticketAmountDues"
    :ticketTotalSeats="ticketSeats"
    :pagination="pagination"
    @getAllTripsForTicketing="getAllTripsForTicketing"
  />
  <ReserveTicketConfirm
    v-if="confirmModalValue"
    v-model="confirmModalValue"
    :ticketIdStore="ticketIdStore"
    :countermanTicketId="countermanTicketId"
    :ticketTotalAmount="ticketTotalAmount"
    :ticketPayable="ticketPayable"
    :ticketAmountPaid="ticketAmountPaid"
    :ticketAmountDues="ticketAmountDues"
    :ticketTotalSeats="ticketSeats"
    :pagination="pagination"
    @ticketPrintPreview="ticketPrintPreview"
  />
  <div>
    <FilterTab>
      <template v-slot:title><h6>Reserved Tickets</h6></template>
      <template v-slot:body>
      </template>
      <template v-slot:searchTicket v-if="!purchaseStatus" class="w-100 d-flex justify-content-between">
        <div class="d-flex p-0 justify-content-start border border-2 border-secondary rounded-2">
          <DropdownFilter
            v-bind:id="'classWiseReport'"
            v-bind:defaultOption="'--select class--'"
            v-model="classNameForFilter"
            :style="'customFontSize'"
            v-bind:defaultValue="''"
            v-bind:dropdown="classNamesForFilter"
          />
          <button class="border-0 bg-secondary text-white px-2">
            <i class="fas fa-filter"></i>
          </button>
        </div>

        <div class="d-flex p-0 justify-content-start border border-2 border-secondary rounded-2 ">
          <input
            type="text"
            placeholder="Search Ticket"
            v-model="searchingValue"
            class="p-1 rounded-2 border-0 filterWidth"
          />
          <button @click="searchingTicket" class="border-0 bg-secondary text-white px-2">
            <i class="fa fa-search"></i>
          </button>
        </div>
      </template>
      
      <template v-slot:button1>
        <button class="edit_table_data font_detail" @click="goBack">
          <i class="fas fa-arrow-left"></i
          ><span class="mx-1 bold">Go Back</span>
        </button>
      </template>
    </FilterTab>
    <div v-if="purchaseStatus" class="row m-0">
      <ReservationTicketPrintPreview
        :ticket="ticket"
        :ticketPaymentData="ticketPaymentData"
        v-bind:ticketType="'ticket'"
        @getAllTripsForTicketing="getAllTripsForTicketing"
        :dashboardTripData="pagination"
      />
    </div>
    <div
      v-else-if="$store.getters.getReservedTicketsHistoryCounter"
      class="table-responsive"
    >
      <Table>
        <template v-slot:header>
          <tr>
            <td class="font_detail">SL</td>
            <td class="font_detail">PASSENGER NAME</td>
            <td class="font_detail">PHONE</td>
            <td class="font_detail">SEAT NUMBERS</td>
            <td class="font_detail">GENDER</td>
            <td class="font_detail">TOTAL SEATS</td>
            <td class="font_detail">FLOOR NAME</td>
            <td class="font_detail">SEAT CLASS</td>
            <td class="font_detail">RESERVED BY</td>
            <td class="font_detail">ADVANCED</td>
            <td class="font_detail">DUES</td>
            <td class="font_detail">ACTION</td>
          </tr>
        </template>
        <template v-slot:body>
          <tr v-for="(ticket, index) in computedSeat" :key="index">
            <td class="font_detail">{{ pagination.limit*(pagination.page-1) +index +1 }}</td>
            <td class="font_detail">{{ ticket.passengerName || "N/A" }}</td>
            <td class="font_detail">{{ ticket.passengerPhone || "N/A" }}</td>
            <td class="font_detail">
              {{ ticket.seatNumbers.replaceAll(",", ", ") }}
              <!--  -->
            </td>
            <td class="font_detail">{{ ticket.passengerGender ? ticket.passengerGender : 'Male' }}</td>
            <td class="font_detail">{{ ticket.totalSeat }}</td>
            <td class="font_detail">{{ ticket.floorName || "N/A" }}</td>
            <td class="font_detail">{{ ticket.seatClassName }}</td>
            <td class="font_detail">{{ ticket.issuedBy }}</td>
            <td class="font_detail">{{ ticket.paid }}</td>
            <td class="font_detail">{{ ticket.dues }}</td>
            <td class="font_detail d-flex gap-3 justify-content-center flex-wrap px-0">
              <button class="font_detail border-0 outline-0 cancelButton" :class="cancelTicketButtonHandler(ticket.countermanId) ? 'd-block' : 'd-none'" @click="cancelTicket(ticket._id, ticket.paid, ticket.dues)">
                <span class="mx-1"><i class="fas fa-times"></i></span>
              </button>

              <button class="font_detail border-0 outline-0 confirmButton" @click="confirmTicket(ticket)">
                <span class="mx-1"><i class="fas fa-check"></i></span>
              </button>

              <button class="font_detail border-0 outline-0" :class=" ticket.keyStatus ? 'keyBtn' : 'keyBtnActive'" @click="keySubmit(ticket)">
                <span class="mx-1"><i class="fas fa-key"></i></span>
              </button>
            </td>
          </tr>
        </template>
        <template v-slot:pagination>
          <pagination
            v-model="pagination.page"
            :records="$store.getters.getTotalReservedTicketsHistoyCounter"
            :per-page="pagination.limit"
            @paginate="getTicketsHistory"
            :options="options"
          />
        </template>
      </Table>
    </div>
  </div>
</template>

<script>
import { inject,onBeforeUpdate, onBeforeMount, ref, watch } from "vue";
import store from "@/store";
import { reactive } from "@vue/reactivity";
import permission from "@/modules/permission";
import roles from "@/modules/roles";
import ReserveTicketCancel from "./ReserveTicketCancel.vue";
import ReserveTicketConfirm from "./ReserveTicketConfirm.vue";
const { hasPermission } = permission();
const { Counterman } = roles();
export default {
  name: "ReservedTicketsDetails",
  components: {
    ReserveTicketCancel,
    ReserveTicketConfirm,
  },
  props: {
    tripIdPass: {
      type: String,
    },
    seatClassName :{
      type: String,
    },
    seatNumbers :{
      type: String,
    },
    paginationData: {
      type: Object,
    },
  },
  emits: ["close"],
  setup(props, { emit }) {
    let searchingValue = ref("");
    // let seatClasses = ref([]);
    let computedSeat = ref([]);
    let modelValue = ref(false);
    let confirmModalValue = ref(false);
    let ticket = ref({});
    let ticketPaymentData = ref({});
    let ticketIdStore = ref("");
    let countermanTicketId = ref("");
    let classNameForFilter = ref("");
    let thisCountermanId = ref();
    let classNamesForFilter = ref({});
    let filterClassWiseReport = ref([]);
    let ticketTotalAmount = ref("");
    let ticketPayable = ref("");
    let ticketAmountPaid = ref("");
    let ticketAmountDues = ref("");
    let tripId = ref(props.tripIdPass);
    let nameofSeatClass = ref("");
    let ticketSeats = ref([]);
    let pagination = reactive({
      page: 1, /// limit * (page - 1) + 1
      limit: 25,
      tripId: tripId.value,
      seatClassName: "",
      seatNumbers: props.seatNumbers,
    });

    let keySubmitData = reactive({
      ticketId: ''
    });
    const purchaseStatus = ref(false);
    const options = {
      texts: {
        count: "",
      },
    };
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const goBack = () => {
      emit("close");
    };
    const getTicketsHistory = () => {
      showLoader();
      store
        .dispatch("getReservedTicketsHistory", pagination)
        .then(() => {
          hideLoader();
          initSeatClassNamesforFilter();
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            getTicketsHistory();
          }
        });
    };
    watch(
      () => classNameForFilter.value,
      (value) => {
        if(value){
          pagination.seatClassName = classNamesForFilter.value[value];
        } else {
          pagination.seatClassName = ""
        }
        resetPagination();
        getTicketsHistory();
        searchingValue.value = ""
      }
    );
    watch(
      () => searchingValue.value,
      (value) => {
        pagination.seatNumbers = value;
        resetPagination();
        getTicketsHistory();
      }
    );

    const resetPagination =()=>{
      pagination.page = 1;
    };

    const ticketPrintPreview = (paymentData) => {
      ticketPaymentData.value = paymentData;
      purchaseStatus.value = true;  
    };
    const getAllTripsForTicketing = () => {
      showLoader();
      purchaseStatus.value = false;
      store
        .dispatch("getActiveTripsForTicketing", props.paginationData)
        .then(() => {
          hideLoader();
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            getAllTripsForTicketing();
          }
        });
    };
    const submitCabinKey = () => {
      showLoader();
      purchaseStatus.value = false;
      store
        .dispatch("getReservationTicketKey", keySubmitData)
        .then(() => {
          hideLoader();
          getTicketsHistory();
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            submitCabinKey();
          }
        });
    };
    const cancelTicketButtonHandler = (ticketCountermanId) => {
      let buttonViewStatus = ref(false);
      if ((hasPermission(Counterman.counterCancelReservation) && hasPermission(Counterman.counterCancelOtherReservation))
          || (ticketCountermanId == thisCountermanId.value && hasPermission(Counterman.counterCancelReservation))
          || (ticketCountermanId != thisCountermanId.value && hasPermission(Counterman.counterCancelOtherReservation))){
        return buttonViewStatus.value = true;
      }
      else if ((!hasPermission(Counterman.counterCancelReservation) && !hasPermission(Counterman.counterCancelOtherReservation))
          || (ticketCountermanId == thisCountermanId.value && !hasPermission(Counterman.counterCancelReservation))
          || (ticketCountermanId != thisCountermanId.value && !hasPermission(Counterman.counterCancelOtherReservation))){
        return buttonViewStatus.value = false;
      }
    };
    const confirmTicket = (ticketValue) => {
      ticket.value = ticketValue;
      ticketIdStore.value = ticketValue._id;
      countermanTicketId.value = ticketValue.countermanId;
      ticketTotalAmount.value = ticketValue.totalAmount;
      ticketPayable.value = ticketValue.payable;
      ticketAmountPaid.value = ticketValue.paid;
      ticketAmountDues.value = ticketValue.dues;
      confirmModalValue.value = true;
    };
    const cancelTicket = (ticketId, paid, dues) => {
      ticketIdStore.value = ticketId;
      ticketAmountPaid.value = paid;
      ticketAmountDues.value = dues;
      modelValue.value = true;
    };
    const keySubmit = (ticketData) =>{
      keySubmitData.ticketId = ticketData._id;
      submitCabinKey();
    }

    const initSeatClassNamesforFilter = ()=>{
      classNamesForFilter.value = {}
      store.getters.getReservedTicketsSeatclassName.forEach((seatClass, index)=>{
        classNamesForFilter.value[index] = seatClass;
      })
    }
    
    onBeforeUpdate(()=>{
      computedSeat.value = store.getters.getReservedTicketsHistoryCounter;
    })
    onBeforeMount(() => {
      thisCountermanId.value = store.getters.getUser._id;
      getTicketsHistory();
      cancelTicketButtonHandler();
    });
    return {
      options,
      pagination,
      Counterman,
      modelValue,
      confirmModalValue,
      ticket,
      ticketPaymentData,
      ticketIdStore,
      countermanTicketId,
      ticketTotalAmount,
      ticketPayable,
      ticketAmountPaid,
      ticketAmountDues,
      hasPermission,
      cancelTicket,
      confirmTicket,
      getTicketsHistory,
      goBack,
      ticketSeats,
      ticketPrintPreview,
      getAllTripsForTicketing,
      purchaseStatus,
      searchingValue,
      computedSeat,
      classNameForFilter,
      thisCountermanId,
      classNamesForFilter,
      filterClassWiseReport,
      keySubmit,
      submitCabinKey,
      nameofSeatClass,
      cancelTicketButtonHandler
    };
  },
};
</script>

<style scoped>
.filterWidth{
  width: 150px;
}
.keyBtn{
  background: #0B5ED7;
  color: #fff;
  width: 40px;
  height: 30px;
  border-radius: 5px;
  cursor: not-allowed;
  pointer-events: none;
}
.keyBtnActive{
  background: #999;
  color: #fff;
  width: 40px;
  height: 30px;
  border-radius: 5px;
}
.confirmButton{
  background: #157347;
  color: #fff;
  width: 40px;
  height: 30px;
  border-radius: 5px;
}
.cancelButton{
  background: #DC3545;
  color: #fff;
  width: 40px;
  height: 30px;
  border-radius: 5px;
}
</style>