import SupervisorDashboard from "@/views/Supervisor/Dashboard/Dashboard";
import SupervisorReportList from "@/views/Supervisor/Report/ReportList";
import Supervisor from "@/views/Supervisor/Supervisor";
import SupervisorVerify from "@/views/Supervisor/Verify/Verify.vue";
import {createRouter, createWebHistory} from "vue-router";
import store from "../store";
import web from "../urls/web";
import Admin from "../views/Admin/Admin";
import AdminAdmins from "../views/Admin/Admins/Admins";
import AdminCompanyDeploy from "../views/Admin/CompanyDeploy/CompanyDeploy";
import CompanyManage from "../views/Admin/CompanyDeploy/CompanyManage";
import AdminDashboard from "../views/Admin/Dashboard/Dashboard";
import AdminReport from "../views/Admin/Report/Report";
import Agent from "../views/Agent/Agent";
import AgentDashboard from "../views/Agent/Dashboard/Dashboard";
import AgentReport from "../views/Agent/Report/Report";
import AgentShip from "../views/Agent/Ship/Ship";
import AgentTrip from "../views/Agent/Trips/Trips";
import Login from "../views/Authentication/Login";
import Counterman from "../views/Counterman/Counterman";
import CountermanDashboard from "../views/Counterman/Dashboard/Dashboard";
import CountermanReport from "../views/Counterman/Report/Report";
import CountermanShip from "../views/Counterman/Ship/Ship";
import CountermanTrip from "../views/Counterman/Trips/Trips";
import CountermanVerify from "../views/Counterman/Verify/Verify";
import Error from "../views/Default/Error";
import Profile from "../views/Default/Profile";
import OwnerDashboard from "../views/Owner/Dashboard/Dashboard";
import Owner from "../views/Owner/Owner";
import OwnersList from "../views/Owner/Owners/OwnersList";
import OwnerReport from "../views/Owner/Report/Report";
import OwnerServices from "../views/Owner/Services/CompanyManage";
import DigitalTicketing from "@/views/Admin/DigitalTecketing/DigitalTicketing";
import Stoppage from "@/views/Admin/DigitalTecketing/children/Stoppage/Stoppage";
import middlewarePipeline from "./middlewarePipeline";
import admin from "./middlewares/admin";
import agent from "./middlewares/agent";
import auth from "./middlewares/auth";
import counterman from "./middlewares/counterman";
import guest from "./middlewares/guest";
import hasAccess from "./middlewares/hasAccess";
import owner from "./middlewares/owner";
import supervisor from "./middlewares/supervisor";
import TicketDetails from "@/views/Counterman/Ticket/TicketDetails";

const routes = [
    {
        path: web.Default.LOGIN_URL.path,
        name: web.Default.LOGIN_URL.name,
        component: Login,
        meta: {
            title: web.Default.LOGIN_URL.title,
            middleware: [guest],
        },
    },
    {
        path: web.Admin.path,
        name: web.Admin.name,
        component: Admin,
        meta: {
            middleware: [auth, admin, hasAccess],
        },
        children: [
            {
                path: web.Admin.DASHBOARD_URL.path,
                name: web.Admin.DASHBOARD_URL.name,
                component: AdminDashboard,
                meta: {
                    title: web.Admin.DASHBOARD_URL.title,
                    middleware: [auth, admin, hasAccess],
                },
            },
            {
                path: web.Admin.REPORT_URL.path,
                name: web.Admin.REPORT_URL.name,
                component: AdminReport,
                meta: {
                    title: web.Admin.REPORT_URL.title,
                    middleware: [auth, admin, hasAccess],
                },
            },
            {
                path: web.Admin.ADMINS_URL.path,
                name: web.Admin.ADMINS_URL.name,
                component: AdminAdmins,
                meta: {
                    title: web.Admin.ADMINS_URL.title,
                    middleware: [auth, admin, hasAccess],
                },
            },
            {
                path: web.Admin.DIGITAL_TICKETING_URL.path,
                name: web.Admin.DIGITAL_TICKETING_URL.name,
                component: AdminAdmins,
                meta: {
                    title: web.Admin.DIGITAL_TICKETING_URL.title,
                    middleware: [auth, admin, hasAccess],
                },
            },
            {
                path: web.Admin.DIGITAL_TICKETING_URL.path,
                name: web.Admin.DIGITAL_TICKETING_URL.name,
                component: DigitalTicketing,
                meta: {
                    title: web.Admin.DIGITAL_TICKETING_URL.title,
                    middleware: [
                        auth,
                        admin,
                        hasAccess
                    ]
                },
                children: [
                    {
                        path: web.Admin.DIGITAL_TICKETING_URL.children.STOPPAGE_URL.path,
                        name: web.Admin.DIGITAL_TICKETING_URL.children.STOPPAGE_URL.name,
                        component: Stoppage,
                        props: true,
                        meta: {
                            middleware: [
                                auth,
                                admin,
                                hasAccess
                            ]
                        }
                    }
                ]
            },
            {
                path: web.Admin.COMPANY_DEPLOY_URL.path,
                name: web.Admin.COMPANY_DEPLOY_URL.name,
                component: AdminCompanyDeploy,
                meta: {
                    title: web.Admin.COMPANY_DEPLOY_URL.title,
                    middleware: [auth, admin, hasAccess],
                },
                children: [
                    {
                        path: web.Admin.COMPANY_DEPLOY_URL.children.MANAGE_URL.path,
                        name: web.Admin.COMPANY_DEPLOY_URL.children.MANAGE_URL.name,
                        component: CompanyManage,
                        props: true,
                        meta: {
                            middleware: [auth, admin],
                        },
                    },
                ],
            },
            {
                path: web.Admin.PROFILE_URL.path,
                name: web.Admin.PROFILE_URL.name,
                component: Profile,
                meta: {
                    title: web.Admin.PROFILE_URL.title,
                    middleware: [auth, admin],
                },
            },
        ],
    },
    {
        path: web.Owner.path,
        component: Owner,
        // meta:{
        //     middleware: [
        //         auth
        //     ]
        // },
        children: [
            {
                path: web.Owner.DASHBOARD_URL.path,
                name: web.Owner.DASHBOARD_URL.name,
                component: OwnerDashboard,
                meta: {
                    title: web.Owner.DASHBOARD_URL.title,
                    middleware: [auth, owner, hasAccess],
                },
            },
            {
                path: web.Owner.REPORT_URL.path,
                name: web.Owner.REPORT_URL.name,
                component: OwnerReport,
                meta: {
                    title: web.Owner.REPORT_URL.title,
                    middleware: [auth, owner, hasAccess],
                },
            },
            {
                path: web.Owner.OWNERS_URL.path,
                name: web.Owner.OWNERS_URL.name,
                component: OwnersList,
                meta: {
                    title: web.Owner.OWNERS_URL.title,
                    middleware: [auth, owner, hasAccess],
                },
            },
            {
                path: web.Owner.SERVICES_URL.path,
                name: web.Owner.SERVICES_URL.name,
                component: OwnerServices,
                meta: {
                    title: web.Owner.SERVICES_URL.title,
                    middleware: [auth, owner, hasAccess],
                },
            },
            {
                path: web.Owner.PROFILE_URL.path,
                name: web.Owner.PROFILE_URL.name,
                component: Profile,
                meta: {
                    middleware: [auth, owner],
                },
            },
        ],
    },
    {
        path: web.Counterman.path,
        component: Counterman,
        meta: {
            middleware: [auth],
        },
        children: [
            {
                path: web.Counterman.DASHBOARD_URL.path,
                name: web.Counterman.DASHBOARD_URL.name,
                component: CountermanDashboard,
                meta: {
                    title: web.Counterman.DASHBOARD_URL.title,
                    middleware: [auth, counterman, hasAccess],
                },
            },
            {
                path: web.Counterman.REPORT_URL.path,
                name: web.Counterman.REPORT_URL.name,
                component: CountermanReport,
                meta: {
                    title: web.Counterman.REPORT_URL.title,
                    middleware: [auth, counterman, hasAccess],
                },
            },
            {
                path: web.Counterman.SHIPS_URL.path,
                name: web.Counterman.SHIPS_URL.name,
                component: CountermanShip,
                meta: {
                    title: web.Counterman.SHIPS_URL.title,
                    middleware: [auth, counterman, hasAccess],
                },
            },
            {
                path: web.Counterman.TRIPS_URL.path,
                name: web.Counterman.TRIPS_URL.name,
                component: CountermanTrip,
                meta: {
                    title: web.Counterman.TRIPS_URL.title,
                    middleware: [auth, counterman, hasAccess],
                },
            },
            {
                path: web.Counterman.PROFILE_URL.path,
                name: web.Counterman.PROFILE_URL.name,
                component: Profile,
                meta: {
                    title: web.Counterman.PROFILE_URL.title,
                    middleware: [auth, counterman],
                },
            },
            {
                path: web.Counterman.VERIFY_URL.path,
                name: web.Counterman.VERIFY_URL.name,
                component: CountermanVerify,
                meta: {
                    title: web.Counterman.VERIFY_URL.title,
                    middleware: [auth, counterman],
                },
            },
            {
                path: web.Counterman.TICKET_URL.path,
                name: web.Counterman.TICKET_URL.name,
                component: TicketDetails,
                meta: {
                    title: web.Counterman.TICKET_URL.title,
                    middleware: [auth, counterman],
                },
            },
        ],
    },
    {
        path: web.Supervisor.path,
        component: Supervisor,
        meta: {
            middleware: [auth],
        },
        children: [
            {
                path: web.Supervisor.DASHBOARD_URL.path,
                name: web.Supervisor.DASHBOARD_URL.name,
                component: SupervisorDashboard,
                meta: {
                    title: web.Supervisor.DASHBOARD_URL.title,
                    middleware: [auth, supervisor],
                },
            },
            {
                path: web.Supervisor.REPORT_URL.path,
                name: web.Supervisor.REPORT_URL.name,
                component: SupervisorReportList,
                meta: {
                    title: web.Supervisor.REPORT_URL.title,
                    middleware: [auth, supervisor],
                },
            },
            {
                path: web.Supervisor.VERIFY_URL.path,
                name: web.Supervisor.VERIFY_URL.name,
                component: SupervisorVerify,
                meta: {
                    title: web.Supervisor.VERIFY_URL.title,
                    middleware: [auth, supervisor],
                },
            },
        ],
    },
    {
        path: web.Agent.path,
        component: Agent,
        meta: {
            middleware: [auth],
        },
        children: [
            {
                path: web.Agent.DASHBOARD_URL.path,
                name: web.Agent.DASHBOARD_URL.name,
                component: AgentDashboard,
                meta: {
                    title: web.Agent.DASHBOARD_URL.title,
                    middleware: [auth, agent, hasAccess],
                },
            },
            {
                path: web.Agent.REPORT_URL.path,
                name: web.Agent.REPORT_URL.name,
                component: AgentReport,
                meta: {
                    title: web.Agent.REPORT_URL.title,
                    middleware: [auth, agent, hasAccess],
                },
            },
            {
                path: web.Agent.SHIPS_URL.path,
                name: web.Agent.SHIPS_URL.name,
                component: AgentShip,
                meta: {
                    title: web.Agent.SHIPS_URL.title,
                    middleware: [auth, agent, hasAccess],
                },
            },
            {
                path: web.Agent.TRIPS_URL.path,
                name: web.Agent.TRIPS_URL.name,
                component: AgentTrip,
                meta: {
                    title: web.Agent.TRIPS_URL.title,
                    middleware: [auth, agent, hasAccess],
                },
            },
            {
                path: web.Agent.PROFILE_URL.path,
                name: web.Agent.PROFILE_URL.name,
                component: Profile,
                meta: {
                    title: web.Agent.PROFILE_URL.title,
                    middleware: [auth, agent],
                },
            },
        ],
    },
    {
        path: web.Default.ERROR_URL.path,
        name: web.Default.ERROR_URL.name,
        component: Error,
        // meta:{
        //     middleware: [
        //         guest
        //     ]
        // }
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    linkActiveClass: "active",
    linkExactActiveClass: "exact-active",
});

router.beforeEach((to, from, next) => {
    document.title = `${process.env.VUE_APP_TITLE} - ${to.meta.title}`;
    if (!to.meta.middleware) {
        return next();
    }
    const middleware = to.meta.middleware;
    const context = {
        to,
        from,
        next,
        store,
    };

    return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1),
    });
});
export default router;
