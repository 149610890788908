<template>
  <div class="row m-0">
    <div class="col-md-10 col-xl-9 col-lg-9 order-md-1 order-lg-1 order-xl-1">
      <span class="fw-bold">TICKET INFORMATION</span>
      <div class="card card-body bg-dark border-3 rounded-3 border-secondary">
        <div class="row my-1">
          <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-4">
            <div class="row">
              <div
                class="col-sm-4 col-md-4 col-lg-3 border-1 border_end text-end"
              >
                <span class="text-info ticket-info">PNR</span>
              </div>
              <div class="col-sm-8 col-md-8 col-lg-9 text-start">
                <span class="text-white ticket-info">{{
                  purchasedTicket.pnrCode
                }}</span>
              </div>
            </div>
            <div class="row mt-1">
              <div
                class="col-sm-4 col-md-4 col-lg-3 border-1 border_end text-end"
              >
                <span class="text-info ticket-info">Trip Date</span>
              </div>
              <div class="col-sm-8 col-md-8 col-lg-9 text-start">
                <span class="orange-bold-text ticket-info">{{
                  dateFormat(purchasedTicket.tripDateTime, 0)
                }}</span>
              </div>
            </div>
            <div class="row mt-1">
              <div
                class="col-sm-4 col-md-4 col-lg-3 border-1 border_end text-end"
              >
                <span class="text-info ticket-info">Boarding Point</span>
              </div>
              <div class="col-sm-8 col-md-8 col-lg-9 text-start">
                <span class="orange-bold-text ticket-info">{{
                  purchasedTicket.boardingPlace
                }}</span>
              </div>
            </div>
            <div class="row mt-1">
              <div
                class="col-sm-4 col-md-4 col-lg-3 border-1 border_end text-end"
              >
                <span class="text-info ticket-info">Dropping Point</span>
              </div>
              <div class="col-sm-8 col-md-8 col-lg-9 text-start">
                <span class="orange-bold-text ticket-info">{{
                  purchasedTicket.droppingPoint
                }}</span>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-4">
            <div class="row">
              <div
                class="col-sm-4 col-md-4 col-lg-3 border-1 border_end text-end"
              >
                <span class="text-info ticket-info">Seat Class</span>
              </div>
              <div class="col-sm-8 col-md-8 col-lg-9 text-start">
                <span class="orange-bold-text ticket-info">{{
                  purchasedTicket.seatClassName
                }}</span>
              </div>
            </div>
            <div class="row mt-1">
              <div
                class="col-sm-4 col-md-4 col-lg-3 border-1 border_end text-end"
              >
                <span class="text-info ticket-info">Seat</span>
              </div>
              <div class="col-sm-8 col-md-8 col-lg-9 text-start">
                <span class="orange-bold-text ticket-info"
                  >#{{ purchasedTicket.seatNumbers }}</span
                >
              </div>
            </div>
            <div class="row mt-1">
              <div
                class="col-sm-4 col-md-4 col-lg-3 border-1 border_end text-end"
              >
                <span class="text-info ticket-info">Amount</span>
              </div>
              <div class="col-sm-8 col-md-8 col-lg-9 text-start">
                <span class="text-white ticket-info">{{
                  purchasedTicket.totalAmount
                }}</span>
              </div>
            </div>
            <div class="row mt-1">
              <div
                class="col-sm-4 col-md-4 col-lg-3 border-1 border_end text-end"
              >
                <span class="text-info ticket-info">Discount</span>
              </div>
              <div class="col-sm-8 col-md-8 col-lg-9 text-start">
                <span class="text-white ticket-info">{{
                  purchasedTicket.discount
                }}</span>
              </div>
            </div>
            <div class="row mt-1">
              <div
                class="col-sm-4 col-md-4 col-lg-3 border-1 border_end text-end"
              >
                <span class="text-info ticket-info">Payable</span>
              </div>
              <div class="col-sm-8 col-md-8 col-lg-9 text-start">
                <span class="orange-bold-text ticket-info">{{
                  purchasedTicket.payable
                }}</span>
              </div>
            </div>
            <div class="row mt-1">
              <div
                class="col-sm-4 col-md-4 col-lg-3 border-1 border_end text-end"
              >
                <span class="text-info ticket-info">Issued By</span>
              </div>
              <div class="col-sm-8 col-md-8 col-lg-9 text-start">
                <span class="text-white ticket-info">{{
                  purchasedTicket.issuedBy
                }}</span>
              </div>
            </div>
            <div class="row mt-1">
              <div
                class="col-sm-4 col-md-4 col-lg-3 border-1 border_end text-end"
              >
                <span class="text-info ticket-info">Ticket Date</span>
              </div>
              <div class="col-sm-8 col-md-8 col-lg-9 text-start">
                <span class="text-white ticket-info">{{
                  dateFormat(purchasedTicket.ticketDateTime, 6)
                }}</span>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-4">
            <div class="row">
              <div
                class="col-sm-4 col-md-4 col-lg-3 border-1 border_end text-end"
              >
                <span class="text-info ticket-info">Name</span>
              </div>
              <div class="col-sm-8 col-md-8 col-lg-9 text-start">
                <span class="text-white ticket-info">{{
                  purchasedTicket.passengerName
                }}</span>
              </div>
            </div>
            <div class="row mt-1">
              <div
                class="col-sm-4 col-md-4 col-lg-3 border-1 border_end text-end"
              >
                <span class="text-info ticket-info">Phone</span>
              </div>
              <div class="col-sm-8 col-md-8 col-lg-9 text-start">
                <span class="text-white ticket-info">{{
                  purchasedTicket.passengerPhone
                }}</span>
              </div>
            </div>
            <div class="row mt-1">
              <div
                class="col-sm-4 col-md-4 col-lg-3 border-1 border_end text-end"
              >
                <span class="text-info ticket-info">Gender</span>
              </div>
              <div class="col-sm-8 col-md-8 col-lg-9 text-start">
                <span class="text-white ticket-info">{{
                  purchasedTicket.passengerGender
                }}</span>
              </div>
            </div>
            <div class="row mt-1">
              <div
                class="col-sm-4 col-md-4 col-lg-3 border-1 border_end text-end"
              >
                <span class="text-info ticket-info">NID</span>
              </div>
              <div class="col-sm-8 col-md-8 col-lg-9 text-start">
                <span class="text-white ticket-info">{{
                  purchasedTicket.passengerNID
                }}</span>
              </div>
            </div>
            <div class="row mt-1">
              <div
                class="col-sm-4 col-md-4 col-lg-3 border-1 border_end text-end"
              >
                <span class="text-info ticket-info">Address</span>
              </div>
              <div class="col-sm-8 col-md-8 col-lg-9 text-start">
                <span class="text-white ticket-info">{{
                  purchasedTicket.passengerAddress
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-2 col-lg-3 col-xl-3 order-md-2 order-lg-2 order-xl-2">
      <span class="fw-bold">ACTIONS</span>
      <div class="card card-body">
        <div class="row">
          <div class="col-md-12 col-lg-6 col-xl-6 mb-5 mb-lg-0">
            <p class="text-center font_detail m-0">GO BACK</p>
            <button class="cancel_button w-100" @click="goBack">
              <i class="fas fa-arrow-left"></i>
            </button>
          </div>
          <div class="col-md-12 col-lg-6 col-xl-6">
            <p class="text-center font_detail m-0">PRINT</p>
            <p style="display: none" id="purchasedTicket">
              {{ purchasedTicket }}
            </p>
            <p style="display: none" id="type">{{ type }}</p>
            <p style="display: none" id="printCount">{{ printCount }}</p>
            <p style="display: none" id="once">{{ once }}</p>
            <button
              class="confirm_button w-100"
              onclick="PrintAndroid(document.getElementById('purchasedTicket').innerHTML,document.getElementById('type').innerHTML,document.getElementById('printCount').innerHTML,document.getElementById('once').innerHTML)"
              @click="submitPrintCount"
            >
              <i class="fas fa-print"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, inject, onBeforeMount } from "vue";
import moment from "moment";
import store from "@/store";

export default {
  name: "TicketPrintPreview",
  props: {
    ticket: Object,
    ticketType: String,
    dashboardTripData: Object,
  },
  setup(props, { emit }) {
    const printCount = ref("");
    const once = ref(1);
    let purchasedTicket = ref(props.ticket);
    let type = ref(props.ticketType);
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const dateFormat = (date, offset) => {
      return moment(String(date))
        .utcOffset(offset, false)
        .format("DD-MMM-yyyy hh:mm:ss A");
    };

    const submitPrintCount = () => {
      showLoader();
      once.value += 1;
      if (type.value === "lastTicket") {
        store
          .dispatch("ticketPrintCount", { ticketId: props.ticket._id })
          .then(() => {
            goBack();
            hideLoader();
          })
          .catch(() => {
            hideLoader();
          });
      } else {
        goBack();
        hideLoader();
      }
    };
    const goBack = () => {
      emit("close");
    };
    onBeforeMount(() => {});

    return {
      purchasedTicket,
      type,
      dateFormat,
      submitPrintCount,
      printCount,
      once,
      goBack,
    };
  },
};
</script>

<style scoped>
.cancel_button {
  margin: 1px 2px;
  border: 2px solid #ff0000;
  border-radius: 4px;
  background-color: #ff0000;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.cancel_button:hover,
.cancel_button:focus {
  border: 2px solid #ff0000;
  background-color: #fff;
  color: #ff0000;
  transition: 0.4s;
  outline: 0;
}

.confirm_button {
  margin: 1px 2px;
  border: 2px solid #34a853;
  border-radius: 4px;
  background-color: #34a853;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.confirm_button:hover,
.confirm_button:focus {
  border: 2px solid #34a853;
  background-color: #fff;
  color: #34a853;
  transition: 0.4s;
  outline: 0;
}
</style>
