<template>
  <Modal @close="closeModal">
    <template v-slot:title>{{ title }}</template>
    <template v-slot:body>
      <form>
        <div class="form-group row my-1">
          <ModalDropdown
              v-if="routes"
              :id="'route'"
              v-model="tripData.route"
              :defaultOption="'---select a route---'"
              :defaultValue="''"
              :dropdown="routes"
              :label="'Routes'"
          >
            <template v-slot:error>
              <ModalError v-if="errors.route" :error="errors.route"/>
            </template>
          </ModalDropdown>
        </div>

        <div v-if="showShipDropdown" class="form-group row my-1">
          <ModalDropdown
              v-if="ships"
              :id="'ship'"
              v-model="tripData.ship"
              :defaultOption="'---select a ship---'"
              :defaultValue="''"
              :dropdown="ships"
              :label="'Ship'"
          >
            <template v-slot:error>
              <ModalError v-if="errors.ship" :error="errors.ship"/>
            </template>
          </ModalDropdown>
        </div>

        <div class="form-group row my-1">
          <ModalDropdown
              v-if="direction"
              :id="'direction'"
              v-model="tripData.direction"
              :defaultOption="'---select a direction---'"
              :defaultValue="''"
              :dropdown="direction"
              :label="'Direction'"
          >
            <template v-slot:error>
              <ModalError
                  v-if="errors.direction"
                  :error="errors.direction"
              />
            </template>
          </ModalDropdown>
        </div>

        <div v-if="tripId" class="form-group row my-1">
          <DatePicker
              :id="'tripDate'"
              v-model="tripData.tripDate"
              :label="'Trips Date'"
          >
            <template v-slot:error>
              <ModalError
                  v-if="errors.tripDate"
                  :error="errors.tripDate"
              />
            </template>
          </DatePicker>
        </div>

        <div v-else>
          <div class="form-group row my-1">
            <DatePicker
                :id="'fromDate'"
                v-model="tripData.fromDate"
                :label="'From'"
            >
              <template v-slot:error>
                <ModalError
                    v-if="errors.fromDate"
                    :error="errors.fromDate"
                />
              </template>
            </DatePicker>
          </div>

          <div class="form-group row my-1">
            <DatePicker
                :id="'toDate'"
                v-model="tripData.toDate"
                :label="'To'"
            >
              <template v-slot:error>
                <ModalError v-if="errors.toDate" :error="errors.toDate"/>
              </template>
            </DatePicker>
          </div>
        </div>

        <div class="form-group row my-1">
          <TimePicker
              :id="'tripTime'"
              v-model="tripData.tripTime"
              :label="'Time'"
          >
            <template v-slot:error>
              <ModalError
                  v-if="errors.tripTime"
                  :error="errors.tripTime"
              />
            </template>
          </TimePicker>
        </div>

        <div class="form-group row my-1">
          <ModalCheckSwitch
              :id="'deck-available'"
              v-model="tripData.deckAvailability"
              :disability="!shipDeckAvailability"
              :label="'Deck Available'"
          >
            <template v-slot:error>
              <Error
                  v-if="errors.deckAvailability"
                  :error="errors.deckAvailability"
              />
            </template>
          </ModalCheckSwitch>
        </div>

        <div class="form-group row my-1">
          <ModalCheckSwitch
              :id="'dtAvailable'"
              v-model="tripData.isDTAvailable"
              :label="'Available for DT'"
          >
            <template v-slot:error>
              <Error
                  v-if="errors.isDTAvailable"
                  :error="errors.isDTAvailable"
              />
            </template>
          </ModalCheckSwitch>
        </div>

        <div v-if="tripData.deckAvailability" class="form-group row my-1">
          <ModalInput
              :id="'deck-fare'"
              v-model="tripData.deckFare"
              :label="'Deck Fare'"
              :placeholder="'Enter Deck Fare'"
              :type="'number'"
          >
            <template v-slot:error>
              <ModalError
                  v-if="errors.deckFare"
                  :error="errors.deckFare"
              />
            </template>
          </ModalInput>
        </div>
        <div class="form-group row my-1">
          <ModalDropdown
              v-if="status"
              :id="'status'"
              v-model="tripData.status"
              :dropdown="status"
              :label="'Status'"
          ></ModalDropdown>
        </div>

        <div class="form-group row my-1">
          <label class="col-sm-3 col-form-label"></label>
          <div class="col-sm-9">
            <input
                v-if="!tripId"
                class="mt-3 py-2 submit_button font_detail float-end"
                type="submit"
                @click.prevent="addTrip"
            />
            <input
                v-else
                class="mt-3 py-2 submit_button font_detail float-end"
                type="submit"
                @click.prevent="updateTrip"
            />
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
import dropdownVariables from "@/modules/dropdownVariables";
import useInputValidation from "@/modules/useInputValidations";
import {computed, inject, onBeforeMount, ref, watch} from "vue";
import {reactive} from "@vue/reactivity";
import store from "@/store";
import moment from "moment";

export default {
  name: "TripsAddUpdateModal",
  props: {
    trip: String,
    company: String,
    routeList: Object,
  },
  setup(props, {emit}) {
    let {requiredValidation, errors} = useInputValidation();
    // let range = reactive({
    //   fromDate: moment(new Date()).format('yyyy-MM-DD'),
    //   toDate: moment(new Date()).format('yyyy-MM-DD'),
    //   tripDate: moment(new Date()).format('yyyy-MM-DD'),
    //   tripTime: moment(new Date()).format('HH:mm:00'),
    // })
    let tripData = reactive({
      id: "",
      companyId: "",
      ship: "",
      route: "",
      direction: "",
      fromDate: "",
      toDate: "",
      deckAvailability: false,
      ...(store.getters.getCompanyTag && {
            isDTAvailable: false,
          }
      ),
      deckFare: 0,
      tripDate: "",
      tripTime: "",
      status: "ACTIVE",
    });
    const tripId = ref(props.trip);
    const companyId = ref(props.company);
    const showShipDropdown = ref(false);
    const updateModal = ref(false)
    const routes = ref(props.routeList);
    const ships = ref({});
    const direction = dropdownVariables.direction;
    const title = ref("Add Trips");
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    let shipDeckAvailability = ref(true);

    const status = computed(() => {
      let allTripStatus = {};
      for (let [key, value] of Object.entries(dropdownVariables.tripStatus)) {
        allTripStatus[key] = value.value;
      }
      return allTripStatus;
    });

    watch(
        () => tripData.route,
        () => {
          if (!updateModal.value) {
            tripData.ship = "";
          }
          requiredValidation("route", tripData.route);
          showLoader();
          ships.value = {};
          store
              .dispatch("getShipsByRoute", {routeId: tripData.route})
              .then((response) => {
                response.forEach((ship) => {
                  ships.value[ship._id] = ship.name;
                });
                showShipDropdown.value = true;
                hideLoader();
              })
              .catch(() => {
                hideLoader();
              });
        }
    );
    watch(
        () => tripData.ship,
        () => {
          if (tripData.route) {
            requiredValidation("ship", tripData.ship);
          }
          if (tripData.ship) {
            showLoader();
            store
                .dispatch("getShipsInfo", {shipId: tripData.ship})
                .then((response) => {
                  shipDeckAvailability.value = response.deckAvailability;
                  if (tripId.value) {
                    if (!response.deckAvailability) {
                      tripData.deckAvailability = false;
                      tripData.deckFare = 0;
                    }
                  } else {
                    tripData.deckAvailability = response.deckAvailability;
                    tripData.deckFare = response.deckFare;
                  }
                  hideLoader();
                })
                .catch(() => {
                  hideLoader();
                });
          }
        }, {immediate: true}
    );
    watch(
        () => tripData.tripTime,
        () => {
          requiredValidation("tripTime", tripData.tripTime);
        }
    );
    watch(
        () => tripData.direction,
        () => {
          requiredValidation("direction", tripData.direction);
        }
    );
    watch(
        () => tripData.fromDate,
        () => {
          tripData.toDate = tripData.fromDate;
        }
    );
    watch(
        () => tripData.toDate,
        () => {
          if (tripData.toDate < tripData.fromDate) {
            tripData.fromDate = tripData.toDate;
          }
        }
    );

    // const dateFormat = (date) => {
    //   return moment(date.toLocaleString('en-US')).utcOffset(0, true).format('yyyy-MM-DD');
    // };
    // const timeFormat = (time) => {
    //   return moment(time.toLocaleString('en-US')).utcOffset(0, true).format('HH:mm:00');
    // };
    const validate = () => {
      Object.keys(errors).forEach(function (key) {
        errors[key] = "";
      });
      requiredValidation("route", tripData.route);
      requiredValidation("tripTime", tripData.tripTime);
      requiredValidation("direction", tripData.direction);
      if (tripId.value !== "") {
        requiredValidation("tripDate", tripData.tripDate);
      } else {
        requiredValidation("fromDate", tripData.fromDate);
        requiredValidation("toDate", tripData.toDate);
      }
      if (tripData.route) {
        requiredValidation("ship", tripData.ship);
      }
      return !(
          Object.values(errors).length <= 0 ||
          !Object.values(errors).every((x) => x === null || x === "")
      );
    };
    const closeModal = () => {
      emit("update:modelValue", false);
    };
    const addTrip = () => {
      if (validate()) {
        showLoader();
        store
            .dispatch("addTrip", tripData)
            .then(() => {
              hideLoader();
              emit("getAllTrips");
              emit("close");
            })
            .catch((error) => {
              hideLoader();
              if (error.status === 401) {
                addTrip();
              }
            });
      }
    };
    const updateTrip = () => {
      if (validate()) {
        showLoader();
        store
            .dispatch("updateTrip", tripData)
            .then(() => {
              hideLoader();
              emit("getAllTrips");
              emit("close");
            })
            .catch((error) => {
              hideLoader();
              if (error.status === 401) {
                updateTrip();
              }
            });
      }
    };

    onBeforeMount(() => {
      Object.keys(errors).forEach(function (key) {
        delete errors[key];
      });
      // Object.entries(props.routeList).forEach(el => {
      //   routes[el[0]] = el[1];
      // });
      if (tripId.value !== "") {
        updateModal.value = true
        const tripDetails = store.getters.getTrip(tripId.value);
        tripData.route = tripDetails[0].route._id;
        title.value = "Update Trip";
        tripData.id = tripDetails[0]._id;
        tripData.ship = tripDetails[0].ship._id;
        tripData.direction = tripDetails[0].direction;
        tripData.deckAvailability = tripDetails[0].deckAvailability;

        tripData.isDTAvailable = tripDetails[0].isDTAvailable;

        tripData.deckFare = tripDetails[0].deckFare;
        tripData.tripDate = moment(tripDetails[0].tripDate)
            .utcOffset(0, false)
            .format("yyyy-MM-DD");
        tripData.tripTime = moment(tripDetails[0].tripDate)
            .utcOffset(0, false)
            .format("HH:mm:00");
        tripData.status = tripDetails[0].status;
      } else {
        tripData.fromDate = moment(new Date()).format("yyyy-MM-DD");
        tripData.toDate = moment(new Date()).format("yyyy-MM-DD");
        tripData.tripTime = moment(new Date()).format("HH:mm:00");
      }
      tripData.companyId = companyId.value;
    });

    return {
      tripId,
      errors,
      status,
      title,
      routes,
      showShipDropdown,
      ships,
      direction,
      tripData,
      addTrip,
      updateTrip,
      closeModal,
      shipDeckAvailability,
    };
  },
};
</script>

<style scoped></style>
