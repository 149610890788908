<template>
    <div class="row m-0">
        <div class="card col-md-10 col-xl-9 col-lg-9 order-md-1 order-lg-1 order-xl-1">
            <div id="print_div">
                <table width="100%" cellspacing="0" cellpadding="0"
                       style="border-collapse: collapse; border-bottom: 1px solid #ddd;">
                    <tr>
                        <th style="text-align: left; line-height: 7px;" colspan="3">
                            <h2>{{ tripTicket.companyName }}</h2>
                            <h4 style="">Ship Wise Report</h4>
                        </th>
                    </tr>
                    <tr>
                        <td style="text-align: left;font-family: Arial;font-size:15px;font-weight:bold;" colspan="2">
                            <p>Ship Name: <span>{{ tripTicket.shipName }}</span></p>
                        </td>
                    </tr>
                    <tr>
                        <td style="text-align: left;font-family: Arial;font-size:15px;" colspan="6">
                            <p>Trip Code: <span>{{ tripTicket.tripCode }}</span></p>
                        </td>
                    </tr>
                    <tr>
                        <td style="text-align: left;font-family: Arial;font-size:15px;font-weight:bold;" colspan="6">
                            <p>Journey Date &amp; time: <span>{{ tripTicket.journeyDateTime }}</span></p>
                        </td>
                    </tr>
                    <tr>
                        <td style="text-align: left;font-family: Arial;font-size:18px;font-weight:bold;" colspan="6">
                            <p>Total Ticket: <span>{{ totalTickets }}</span></p>
                        </td>
                    </tr>
                    <tr v-for="seatClass in tripReportInfo" :key="seatClass.seatClass">
                        <td style="text-align: left;" colspan="6">
                            <p>{{ seatClass.seatClass }}: <span>{{ seatClass.seatTicket }}</span></p>
                        </td>
                    </tr>
                    <tr>
                        <td style="text-align:left;font-family: Arial;font-size:18px;font-weight:bold;" colspan="3">
                            <p>Total Fare: <span>{{ totalFare }}</span></p>
                        </td>
                    </tr>
                    <tr>
                        <td style="text-align:left;font-family: Arial;font-size:18px;font-weight:bold;" colspan="3">
                            <p>Net Pay: <span>{{ totalFare }}</span></p>
                        </td>
                    </tr>
                    <tr>
                        <td style="text-align:left;font-family: Arial;font-size:18px;font-weight:bold;" colspan="3">
                            <p>Cancel Ticket: <span>{{ tripTicket.cancelledTicket }}</span></p>
                        </td>
                    </tr>
                    <tr>
                        <td style="text-align: left;font-family: Arial;font-size:18px;font-weight:bold;" colspan="3">
                            <p>Counterman Name: <span>{{ tripTicket.counntermanName }}</span></p>
                        </td>
                    </tr>
                    <tr>
                        <td style="text-align: left;font-family: Arial;font-size:18px;font-weight:bold;" colspan="3">
                            <p>Print Date time: <span>{{ dateFormat(new Date()) }}</span></p>
                        </td>
                    </tr>
                </table>
            </div>

        </div>
        <div class="col-md-2 col-lg-3 col-xl-3 order-md-2 order-lg-2 order-xl-2">
            <span class="fw-bold">ACTIONS</span>
            <div class="card card-body">
                <div class="row">
                    <div class="col-md-12 col-lg-6 col-xl-6">
                        <p class="text-center font_detail  m-0">GO BACK</p>
                        <button class="cancel_button w-100" @click="close"><i class="fas fa-arrow-left"></i
                        ></button>
                    </div>
                    <div class="col-md-12 col-lg-6 col-xl-6">
                        <p class="text-center font_detail m-0">PRINT</p>
                        <p style="display: none" id="tripTicket">{{ tripTicket }}</p>
                        <p style="display: none" id="type">{{ type }}</p>
                        <button class="confirm_button w-100"
                                @click="PrintDiv">
                            <i class="fas fa-print"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<!--onclick="PrintAndroid(document.getElementById('tripTicket').innerHTML,document.getElementById('type').innerHTML)"-->
<script>
import {ref} from "vue";
import moment from "moment";

export default {
    name: "TripReportPrintPreviwForAgent",
    props: {
        trip: Object,
        ticketType: String,
    },
    setup(props, {emit}) {
        let tripTicket = ref(props.trip.reportInfo)
        let tripReportInfo = ref(props.trip.ticket)
        let totalTickets = ref(0)
        let totalFare = ref(0)
        let type = ref(props.ticketType)

        tripReportInfo.value.forEach((seat) => {
            totalTickets.value += seat.seatTicket
            totalFare.value += seat.seatAmount
        })
        const dateFormat = (date) => {
            return moment(String(date)).utcOffset(0, false).format('DD-MMM-yyyy hh:mm:ss A');
        };

        const close = () => {
            emit("close");
        }

        const PrintDiv = () => {
            let divContents = window.document.getElementById("print_div").innerHTML;

            let printWindow = window.open();
            printWindow.document.write('<html>');
            printWindow.document.write('<body>');
            printWindow.document.write(divContents);
            printWindow.document.write('</body></html>');
            printWindow.document.close();
            setTimeout(function () {
                printWindow.print();
                printWindow.close();
                close()
            }, 200);
        }

        return {
            tripTicket, type, tripReportInfo, totalTickets, totalFare,
            dateFormat, close, PrintDiv
        }
    }
}
</script>

<style scoped>
.table_header {
    background-color: #3561f0;
    color: #fff;
}

.table_body {
    background-color: #fff;
    color: #212529;
}

.cancel_button {
    margin: 1px 2px;
    border: 2px solid #ff0000;
    border-radius: 4px;
    background-color: #ff0000;
    padding: 2px 6px;
    color: #fff;
    position: relative;
    font-size: 14px;
    font-family: Helvetica Neue;
}

.cancel_button:hover,
.cancel_button:focus {
    border: 2px solid #ff0000;
    background-color: #fff;
    color: #ff0000;
    transition: 0.4s;
    outline: 0;
}

.confirm_button {
    margin: 1px 2px;
    border: 2px solid #34a853;
    border-radius: 4px;
    background-color: #34a853;
    padding: 2px 6px;
    color: #fff;
    position: relative;
    font-size: 14px;
    font-family: Helvetica Neue;
}

.confirm_button:hover,
.confirm_button:focus {
    border: 2px solid #34a853;
    background-color: #fff;
    color: #34a853;
    transition: 0.4s;
    outline: 0;
}

</style>