<template>
  <Modal @close="closeModal">
    <template v-slot:title>Delete Boarding Place</template>
    <template v-slot:body>
      <form>
        <div class="form-group row my-1 px-3">
          Do you want to delete boarding place?
          <!-- <ModalInput
            v-model="bPlaceName"
            v-bind:placeholder="'Enter Name'"
            v-bind:label="'Name'"
            v-bind:id="'name'"
            v-bind:type="'text'"
          >
          </ModalInput> -->
        </div>
        <div class="form-group row my-1">
          <label class="col-sm-3 col-form-label"></label>
          <div class="col-sm-9">
            <input
              type="submit"
              value="Delete"
              class="mt-3 py-2 submit_button font_detail float-end"
              @click.prevent="deleteBoardingPlace"
            />
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
import { onBeforeMount, inject, ref } from "vue";

import store from "@/store";

export default {
  name: "BoardingPlaceDeleteModal",
  props: {
    routeId: [String, Number],
    boardingPlaceName: String,
  },
  setup(props, { emit }) {
    const bPlaceName = ref(props.boardingPlaceName);
    const rId = ref(props.routeId);
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const bPlace = {
      routeId: rId.value,
      name: bPlaceName.value,
    };
    const closeModal = () => {
      emit("getAllBoardingPlaces");
      emit("update:modelValue", false);
    };
    const deleteBoardingPlace = () => {
      showLoader();
      store
        .dispatch("deleteOwnerServicesBoardingPlaceForRoute", { bPlace })
        .then(() => {
          hideLoader();
          closeModal();
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            deleteBoardingPlace();
          }
        });
    };

    onBeforeMount(() => {});

    return {
      deleteBoardingPlace,
      closeModal,
      bPlaceName,
    };
  },
};
</script>

<style scoped></style>
