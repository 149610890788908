<template>
  <div>
    <FilterTab>
      <template v-slot:body>
        <div class="col-sm-6 col-md-2"></div>
      </template>
      <template v-slot:button2>
        <button class="edit_table_data font_detail" @click="goBack">
          <i class="fas fa-arrow-left"></i
          ><span class="mx-1 bold">Go Back</span>
        </button>
      </template>
      <template v-slot:button1>
        <button
          class="edit_table_data font_detail"
          @click="exportTable('xlsx')"
        >
          <i class="fas fa-download"></i><span class="mx-1 bold">Export</span>
        </button>
      </template>
    </FilterTab>
  </div>
  <div id="exportable_data">
    <div
      class="card h-100 mt-4"
      v-for="(itemValue, index) in classWiseReport"
      :key="index"
    >
      <table>
        <tr colspan="100">
          <th><h5 class="mb-0 py-2 text-uppercase fw-bold text-center text-orange">{{ itemValue.seatClassName }}</h5></th>
        </tr>
      </table>
      <div class="card-body mt-0 py-0">
        <table-simple :id="index">
          <template v-slot:header>
            <tr>
              <td class="font_detail">SL</td>
              <td class="font_detail">Ticket Date Time</td>
              <td class="font_detail">Trip Date Time</td>
              <td class="font_detail">Ship Name</td>
              <td class="font_detail">Passenger Name</td>
              <td class="font_detail">Passenger Phone</td>
              <td class="font_detail">Boarding Place</td>
              <td class="font_detail">Dropping Place</td>
              <td class="font_detail">Floor Name</td>
              <td class="font_detail">Seat Numbers</td>
              <td class="font_detail">Total Seat</td>
              <td class="font_detail">Seat Fare</td>
              <td class="font_detail">Partial Amount</td>
              <td class="font_detail">Discount</td>
              <td class="font_detail">Receivable Amount</td>
              <td class="font_detail">Reserved By</td>
              <td class="font_detail" v-if="status === ''">Status</td>
              <td class="font_detail" v-if="status.toUpperCase() === 'CANCELED' || status.toUpperCase() === dropdownVariables.reservationReportStatus.confirmed || status === ''">Confirmed By</td>
              <td class="font_detail" v-if="status.toUpperCase() === 'CANCELED' || status === ''">Cancelled By</td>
              <td class="font_detail" v-if="status.toUpperCase() === 'CANCELED' || status === ''">Cancellation Cause</td>
            </tr>
          </template>
          <template v-slot:body>
            <tr v-for="(item, index) in itemValue.tickets" :key="index">
              <td class="font_detail" :class="item.ticketId.userType === 'cabinman' || item.ticketId.isConfirmedByCabinMan ? 'cabinmanTicket' : ''">{{ index + 1 }}</td>
              <td class="font_detail" :class="item.ticketId.userType === 'cabinman' || item.ticketId.isConfirmedByCabinMan ? 'cabinmanTicket' : ''">{{ dateFormat(item.ticketId.ticketDateTime) }}</td>
              <td class="font_detail" :class="item.ticketId.userType === 'cabinman' || item.ticketId.isConfirmedByCabinMan ? 'cabinmanTicket' : ''">{{ dateFormat2(item.ticketId.tripDateTime) }}</td>
              <td class="font_detail" :class="item.ticketId.userType === 'cabinman' || item.ticketId.isConfirmedByCabinMan ? 'cabinmanTicket' : ''">{{ item.ticketId.shipName }}</td>
              <td class="font_detail" :class="item.ticketId.userType === 'cabinman' || item.ticketId.isConfirmedByCabinMan ? 'cabinmanTicket' : ''">{{ item.ticketId.passengerName || "N/A" }} <span></span></td>
              <td class="font_detail" :class="item.ticketId.userType === 'cabinman' || item.ticketId.isConfirmedByCabinMan ? 'cabinmanTicket' : ''">{{ item.ticketId.passengerPhone || "N/A" }}</td>
              <td class="font_detail" :class="item.ticketId.userType === 'cabinman' || item.ticketId.isConfirmedByCabinMan ? 'cabinmanTicket' : ''">{{ item.ticketId.boardingPlace }}</td>
              <td class="font_detail" :class="item.ticketId.userType === 'cabinman' || item.ticketId.isConfirmedByCabinMan ? 'cabinmanTicket' : ''">{{ item.ticketId.droppingPoint }}</td>
              <td class="font_detail" :class="item.ticketId.userType === 'cabinman' || item.ticketId.isConfirmedByCabinMan ? 'cabinmanTicket' : ''">{{item.ticketId.floorName }}</td>
              <td class="font_detail" :class="item.ticketId.userType === 'cabinman' || item.ticketId.isConfirmedByCabinMan ? 'cabinmanTicket' : ''">{{item.ticketId.seatNumbers }}</td>
              <td class="font_detail" :class="item.ticketId.userType === 'cabinman' || item.ticketId.isConfirmedByCabinMan ? 'cabinmanTicket' : ''">{{ item.totalSeat }}</td>
              <td class="font_detail" :class="item.ticketId.userType === 'cabinman' || item.ticketId.isConfirmedByCabinMan ? 'cabinmanTicket' : ''">{{ item.totalAmount }}</td>
              <td class="font_detail" :class="item.ticketId.userType === 'cabinman' || item.ticketId.isConfirmedByCabinMan ? 'cabinmanTicket' : ''">{{ item.partialAmount }}</td>
              <td class="font_detail" :class="item.ticketId.userType === 'cabinman' || item.ticketId.isConfirmedByCabinMan ? 'cabinmanTicket' : ''">{{ item.ticketId.discount || 0 }}</td>
              <td class="font_detail" :class="item.ticketId.userType === 'cabinman' || item.ticketId.isConfirmedByCabinMan ? 'cabinmanTicket' : ''">{{ item.ticketId.payable }}</td>
              <td class="font_detail" :class="item.ticketId.userType === 'cabinman' || item.ticketId.isConfirmedByCabinMan ? 'cabinmanTicket' : ''">{{ item.reservedBy }}</td>
              <td class="font_detail" :class="item.ticketId.userType === 'cabinman' || item.ticketId.isConfirmedByCabinMan ? 'cabinmanTicket' : ''" v-if="status === ''">{{ item.reservedStatus === 'PENDING'?'RESERVED': item.reservedStatus }}</td>
              <td class="font_detail" :class="item.ticketId.userType === 'cabinman' || item.ticketId.isConfirmedByCabinMan ? 'cabinmanTicket' : ''" v-if="status.toUpperCase() === 'CANCELED' || status.toUpperCase() === dropdownVariables.reservationReportStatus.confirmed || status === ''">{{ item.confirmedBy || "N/A" }}</td>
              <td class="font_detail" :class="item.ticketId.userType === 'cabinman' || item.ticketId.isConfirmedByCabinMan ? 'cabinmanTicket' : ''" v-if="status.toUpperCase() === 'CANCELED' || status === ''">{{ item.cancelledBy || "N/A" }}</td>
              <td class="font_detail" :class="item.ticketId.userType === 'cabinman' || item.ticketId.isConfirmedByCabinMan ? 'cabinmanTicket' : ''" v-if="status.toUpperCase() === 'CANCELED' || status === ''">{{ item.ticketId.cancellationCause || "N/A" }}</td>
            </tr>
            <tr class="bg-dark text-white">
              <td class="font_detail text-white"></td>
              <td class="font_detail text-white"></td>
              <td class="font_detail text-white"></td>
              <td class="font_detail text-white"></td>
              <td class="font_detail text-white"></td>
              <td class="font_detail text-white"></td>
              <td class="font_detail text-white"></td>
              <td class="font_detail text-white"></td>
              <td class="font_detail text-white"></td>
              <td class="font_detail text-white"></td>
              <td class="font_detail text-white">Total Seat: {{ itemValue.totalSeat }}</td>
              <td class="font_detail text-white"></td>
              <td class="font_detail text-white">Total Partial: {{ itemValue.totalPartial }} Taka</td>
              <td class="font_detail text-white">Total Discount: {{ itemValue.totalDiscount }} Taka</td>
              <td class="font_detail text-white">Total Amount: {{ itemValue.totalAmount }} Taka</td>
              <td class="font_detail text-white"></td>
              <td class="font_detail text-white" v-if="status === ''"></td>
              <td class="font_detail text-white" v-if="status.toUpperCase() === 'CANCELED' || status.toUpperCase() === dropdownVariables.reservationReportStatus.confirmed || status === ''"></td>
              <td class="font_detail text-white" v-if="status.toUpperCase() === 'CANCELED' || status === ''"></td>
              <td class="font_detail text-white" v-if="status.toUpperCase() === 'CANCELED' || status === ''"></td>
            </tr>
          </template>
        </table-simple>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from "@vue/reactivity";
import { ref, onBeforeMount, inject } from "vue";
import store from "@/store";
import moment from "moment";
import dropdownVariables from "@/modules/dropdownVariables";
import XLSX from "xlsx";

export default {
  name: "ReservationDetails",
  components: {},
  props: {
    counterManId: String,
    shipName: String,
    fromDate: String,
    toDate: String,
    status: String,
  },
  emits: ["backToShipWiseReport"],
  setup(props, { emit }) {
    let reservedById = ref(props.counterManId);
    let fromDate = ref(props.fromDate);
    let toDate = ref(props.toDate);
    let status = ref(props.status);
    let shipId = ref(props.shipName);
    let totalAmountSeat = ref(0);
    let totalSeat = ref(0);
    let totalDiscount = ref(0);

    let cancelledBy = ref(props.status);
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");

    // const date = moment(new Date()).format("yyyy-MM-DD");

    let reservationWiseReport = reactive({
      reservedById: reservedById.value,
      status: status.value,
      shipId: shipId.value,
      fromDate: fromDate.value,
      toDate: toDate.value,
      // page: 1,
      // limit: 10,
    });

    let classWiseReport = ref({});

    const dateFormat = (date) => {
      return moment(String(date))
        .utcOffset(6, false)
        .format("DD-MMM-yyyy hh:mm A");
    };
    const dateFormat2 = (date) => {
      return moment(String(date))
        .utcOffset(0, false)
        .format("DD-MMM-yyyy hh:mm A");
    };

    const getSeatDetails = () => {
      showLoader();
      store
        .dispatch("getReservationWiseReportDetailsOwner", reservationWiseReport)
        .then((response) => {
          if (response.length !== 0) {
            classWiseReport.value = response
          }
          hideLoader();
        })
        .catch(() => hideLoader());
    };
    
    const goBack = () => {
      emit("backToShipWiseReport", true);
    };

    const exportTable = (type, fn, dl) => {
      if (document.getElementById("exportable_data") !== null) {
        var elt = document.getElementById("exportable_data");
        var wb = XLSX.utils.table_to_book(elt, {sheet: "sheet Js"});
        return dl
            ? XLSX.write(wb, {bookType: type, bookSST: true, type: "base64"})
            : XLSX.writeFile(
                wb,
                fn || "Owner_ReservationWise_Details_Report." + (type || "xlsx")
            );
      }
    };

    onBeforeMount(() => {
      getSeatDetails();
    });

    return {
      reservationWiseReport,
      totalAmountSeat,
      goBack,
      exportTable,
      totalSeat,
      cancelledBy,
      totalDiscount,
      dateFormat,
      dateFormat2,
      classWiseReport,
      dropdownVariables,
      shipId
    };
  },
};
</script>

<style scoped>
h3,
h5 {
  font-size: 36px;
  color: #f04935;
}
h5 {
  font-size: 18px;
}
.cabinmanTicket{
    background-color: #bdcbff;
  }
</style>
