<template>
  <div v-if="!reportPrint">
    <Filter>
      <template v-slot:body>
        <div class="col-sm-6 col-md-4">
          <DateTimeRangePicker v-model="date.range" />
        </div>
        <div class="col-md-2 col-sm-6 mt-auto">
          <button class="edit_table_data font_detail" @click="getAgentReport">
            <i class="fas fa-filter"></i><span class="mx-1 bold">Filter</span>
          </button>
        </div>
      </template>

      <template v-slot:button2>
        <button class="edit_table_data font_detail" @click="printUserReport">
          <i class="fas fa-print"></i><span class="mx-1 bold">Print</span>
        </button>
      </template>
      <template v-slot:button1>
        <button
          class="edit_table_data font_detail"
          @click="exportExcel('xlsx')"
        >
          <i class="fas fa-download"></i><span class="mx-1 bold">Export</span>
        </button>
      </template>
    </Filter>
    <Table id="exportable_data">
      <template v-slot:header>
        <tr>
          <td class="font_detail">SL</td>
          <td class="font_detail">Seat Class</td>
          <td class="font_detail">Seat Ticket</td>
          <td class="font_detail">Seat Amount</td>
        </tr>
      </template>
      <template v-slot:body>
        <tr
          v-for="(report, index) in $store.getters.getAgentReport.ticket"
          :key="report.seatClassId"
        >
          <td class="font_detail">{{ index + 1 }}</td>
          <td class="font_detail">{{ report.seatClass }}</td>
          <td class="font_detail">{{ report.seatTicket }}</td>
          <td class="font_detail">{{ report.seatAmount }}</td>
        </tr>
        <tr class="bg-dark text-white">
          <td class="font_detail"></td>
          <td class="font_detail"></td>
          <!-- <td class="font_detail"> Deck Total Print: {{deckTicketTotal}}</td> -->
          <td class="font_detail">Total Ticket: {{ seatTicketTotal }}</td>
          <td class="font_detail">Total Amount: {{ seatAmountTotal }} Taka</td>
        </tr>
      </template>
      <!-- <template v-slot:pagination>
              <Pagination v-model="pagination.page" :records="$store.getters.getTotalShipWiseReport" :per-page="pagination.limit"
                          @paginate="getAllCompanyList" :options="options"/>
           </template> -->
    </Table>
  </div>
  <div class="card card-body" v-else>
    <div v-if="$store.getters.userType !== 'Agent'">
      <UserReportPrintPreview
        v-bind:report="$store.getters.getAgentReport"
        v-bind:ticketType="'userReport'"
        @close="close"
      />
    </div>
    <div v-else>
      <UserReportPrintPreviewForAgent
        v-bind:report="$store.getters.getAgentReport"
        v-bind:ticketType="'userReport'"
        @close="close"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { reactive } from "@vue/reactivity";
import { onBeforeMount, ref, watch } from "vue";
import store from "@/store";
import XLSX from "xlsx";

export default {
  name: "Report",
  setup() {
    let pagination = reactive({
      page: 1,
      limit: 10,
      shipId: "",
      fromDate: "",
      toDate: "",
    });
    const options = {
      texts: {
        count: "",
      },
    };
    const fromDate = moment(new Date()).format("yyyy-MM-DD" + " 00:00:00");
    const toDate = moment(new Date()).format("yyyy-MM-DD" + " 23:59:59");
    let seatTicketTotal = ref(0);
    let seatAmountTotal = ref(0);
    let reportPrint = ref(false);

    let date = reactive({
      range: {
        start: fromDate,
        end: toDate,
      },
    });

    watch(
      () => date.range.start,
      (value) => {
        pagination.fromDate = moment(value).format("yyyy-MM-DD" + " 00:00:00");
      }
    );
    watch(
      () => date.range.end,
      (value) => {
        pagination.toDate = moment(value).format("yyyy-MM-DD" + " 23:59:59");
      }
    );

    const printUserReport = () => {
      reportPrint.value = true;
    };
    const close = () => {
      reportPrint.value = false;
    };
    const sumListData = () => {
      store.getters.getAgentReport.ticket.forEach((data) => {
        seatTicketTotal.value += data.seatTicket;
        seatAmountTotal.value += data.seatAmount;
      });
    };
    const exportExcel = (type, fn, dl) => {
      let elt = document.getElementById("exportable_data");
      let wb = XLSX.utils.table_to_book(elt, { sheet: "sheet Js" });
      return dl
        ? XLSX.write(wb, { bookType: type, bookSST: true, type: "base64" })
        : XLSX.writeFile(wb, fn || "Agent_Report." + (type || "xlsx"));
    };
    const getAgentReport = () => {
      store.dispatch("getAllAgentReport", pagination).then(() => {
        seatTicketTotal.value = 0;
        seatAmountTotal.value = 0;
        sumListData();
      });
    };
    onBeforeMount(() => {
      pagination.fromDate = fromDate;
      pagination.toDate = toDate;
      getAgentReport();
    });
    return {
      options,
      pagination,
      date,
      seatTicketTotal,
      seatAmountTotal,
      reportPrint,
      exportExcel,
      getAgentReport,
      printUserReport,
      close,
    };
  },
};
</script>

<style scoped>
</style>