// import tokenCookie from "@/modules/tokenCookie";
import web from "@/urls/web";

// const {hasValidToken} = tokenCookie();

export default function supervisor({ next, store }) {
  if (store.getters.userType !== "Supervisor") {
    return next({
      name: web.Default.LOGIN_URL.name,
    });
  }
  return next();
}
