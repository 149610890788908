<template>
  <li class="list-group-item">
    <span v-if="error" class="text-danger err_msg">* {{ error }}</span>
    <div class="row my-2">
      <div class="col-7 col-xl-5 my-2 my-xl-0">
        <div class="row">
          <div class="col">
            <Input :id="'prefix'+spId"
                   v-model="seatDesign.prefix" :placeholder="'Prefix'" :type="'text'"/>
            <!--      <Error v-if="errors.prefix" :error="errors.prefix"/>-->
          </div>
          <div class="col">
            <Input :id="'from'+spId"
                   v-model="seatDesign.from" :placeholder="'From'" :type="'text'"/>
            <div v-if="errorId === spId">
              <Error v-if="errors.from" :error="errors.from"/>
            </div>
          </div>
          <div class="col">
            <Input :id="'to'+spId"
                   v-model="seatDesign.to" :placeholder="'To'" :type="'text'"/>
            <div v-if="errorId === spId">
              <Error v-if="errors.to" :error="errors.to"/>
            </div>
          </div>
          <div class="col">
            <Input :id="'suffix'+spId"
                   v-model="seatDesign.suffix" :placeholder="'Suffix'" :type="'text'"/>
            <!--      <Error v-if="errors.suffix" :error="errors.suffix"/>-->
          </div>
          <div class="col">
            <button :id="'add'+spId" class="add_button mt-1" @click="addSeatPlan()"><i class="fas fa-plus"></i></button>
          </div>
        </div>
      </div>
      <div class="col-5 col-xl-3 my-2 my-xl-0">
        <div class="row">
          <div class="col-md-10">
            <Input :id="'custom'+spId"
                   v-model="seatDesign.customSeats" :placeholder="'Enter Seats(Ex: 101,102,106)'" :type="'text'"/>
            <div v-if="errorId === spId">
              <Error v-if="errors.seats" :error="errors.seats"/>
            </div>
          </div>
          <div class="col-md-2">
            <button :id="'addCustom'+spId" class="add_button mt-1" @click="addCustomSeatPlan()"><i
                class="fas fa-plus"></i></button>
          </div>
        </div>
      </div>
      <div class="col-4 my-2 my-xl-0">
        <div class="row">
          <div class="col-md-10">
            <div class="card p-1 h-100">

              <div class="row">
                <div class="text-muted small">Block seats</div>
                <div class="col-auto">
                           <span v-for="seat in blockedSeats" :key="seat+spId"
                                 class="badge badge-pill allBlockSeat m-1 p-2">
                              {{ seat }}
                           </span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <button :id="'block'+spId" class="block_button mt-1" @click="blockSeats()"><i class="fas fa-ban"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="row">
          <div class="col-4">
            <CheckboxInput
                v-model="operationName.block"
                :input-id="block"
                :label="'Block'"
                :labelColor="'text-muted'"
                @click="getOperationName('block')"
            />
          </div>
          <div class="col-4">
            <CheckboxInput
                v-model="operationName.quotaUp"
                :input-id="dtquotaup"
                :label="'DT Quota Up'"
                :labelColor="'text-muted'"
                @click="getOperationName('quotaUp')"

            />
          </div>
          <div class="col-4">
            <CheckboxInput
                v-model="operationName.quotaDown"
                :input-id="dtquotadown"
                :label="'DT Quota Down'"
                :labelColor="'text-muted'"
                @click="getOperationName('quotaDown')"

            />
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="row">
          <div class="col-md-10">
            <div class="card p-1 h-100">
              <div class="row">
                <div class="text-muted small">DT Quota(Up Trip)</div>

                <div class="col-auto">
                           <span v-for="seat in digitalTicketingUpSeats" :key="seat+spId"
                                 class="badge badge-pill allDtUpSeat m-1 p-2">
                              {{ seat }}
                           </span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <button :id="'block'+spId" class="quota_button mt-1" @click="addDtUpSeat()"><i class="fas fa-lock"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="row">
          <div class="col-md-10">
            <div class="card p-1 h-100">
              <div class="row">
                <div class="text-muted small">DT Quota(Down Trip)</div>
                <div class="col-auto">
                           <span v-for="seat in digitalTicketingDownSeats" :key="seat+spId"
                                 class="badge badge-pill allDtDownSeat m-1 p-2">
                              {{ seat }}
                           </span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <button :id="'block'+spId" class="quota_button mt-1" @click="addDtDownSeat()"><i class="fas fa-lock"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import {reactive} from "@vue/reactivity";
import Input from "@/components/layouts/Inputs/Input";
import Error from "@/components/layouts/Inputs/Error";
import useInputValidation from "@/modules/useInputValidations";
import {inject, ref, watch,} from "vue";
import store from "@/store";
import CheckboxInput from "@/components/layouts/Inputs/CheckboxInput.vue";


export default {
  name: "AddSeatRow",
  props: {
    blocked: Object,
    dtUpSeats: Object,
    dtDownSeats: Object,
    seatPlanId: String,
    ship: String,
    company: String
  },
  emits: ["getSeatPlan", "getOperationStatus"],
  components: {Input, Error, CheckboxInput},
  setup(props, {emit}) {
    let seatDesign = reactive({
      prefix: "",
      from: "",
      to: "",
      suffix: "",
      customSeats: "",
    });
    let row = reactive({
      shipId: "",
      seatClassId: "",
      seatRow: ""
    });
    let block = reactive({
      shipId: "",
      seatClassId: "",
      seatNumbers: "",
    });

    let operationName = reactive({
      block: true,
      quotaUp: false,
      quotaDown: false,
    })

    let spId = ref(props.seatPlanId);
    let blockedSeats = ref(props.blocked);
    let digitalTicketingUpSeats = ref(props.dtUpSeats);
    let digitalTicketingDownSeats = ref(props.dtDownSeats);
    let companyId = ref(props.company);
    let shipId = ref(props.ship);
    let errorId = ref("");
    let error = ref("");
    let {requiredValidation, errors} = useInputValidation();
    const showLoader = inject('showLoader');
    const hideLoader = inject('hideLoader');

    watch(() => [...props.blocked], () => {
      blockedSeats.value = props.blocked;
    });

    watch(() => [...props.dtUpSeats], () => {
      digitalTicketingUpSeats.value = props.dtUpSeats;
    });

    watch(() => [...props.dtDownSeats], () => {
      digitalTicketingDownSeats.value = props.dtDownSeats;
    });

    const getOperationName = (name) => {
      if (name === 'block') {
        operationName.quotaUp = false;
        operationName.quotaDown = false;
        if (!operationName.block) {
          emitOperation(name)
        } else {
          emitOperation('')
        }
      } else if (name === 'quotaUp') {
        operationName.block = false;
        operationName.quotaDown = false;
        if (!operationName.quotaUp) {
          emitOperation(name)
        } else {
          emitOperation('')
        }
      } else if (name === 'quotaDown') {
        operationName.block = false;
        operationName.quotaUp = false;
        if (!operationName.quotaDown) {
          emitOperation(name)
        } else {
          emitOperation('')
        }
      }
    }

    const emitOperation = (name) => {
      emit('getOperationStatus', name);
    }

    const clear = () => {
      row.shipId = "";
      row.seatClassId = "";
      row.seatRow = "";
      seatDesign.prefix = "";
      seatDesign.from = "";
      seatDesign.to = "";
      seatDesign.suffix = "";
      seatDesign.customSeats = "";
      block.seatClassId = "";
      block.shipId = "";
      block.seatNumbers = "";
    };
    const validate = () => {
      error.value = "";
      Object.keys(errors).forEach(function (key) {
        errors[key] = ""
      });
      requiredValidation("from", seatDesign.from);
      requiredValidation("to", seatDesign.to);
      if (Object.values(errors).length <= 0 || !Object.values(errors).every(x => x === null || x === '')) {
        return false;
      } else {
        if (parseInt(seatDesign.from) && parseInt(seatDesign.to) && parseInt(seatDesign.from) <= parseInt(seatDesign.to)) {
          return true;
        } else if (typeof seatDesign.from === typeof seatDesign.to && seatDesign.from.length === 1 && seatDesign.to.length === 1) {
          if (/[a-z]/.test(seatDesign.from) && /[a-z]/.test(seatDesign.to) && seatDesign.from.charCodeAt(0) < seatDesign.to.charCodeAt(0)) {
            return true;
          } else if (/[A-Z]/.test(seatDesign.from) && /[A-Z]/.test(seatDesign.to) && seatDesign.from.charCodeAt(0) < seatDesign.to.charCodeAt(0)) {
            return true;
          } else {
            error.value = "Supported format for 'From' and 'To' fields: A-Z/a-z/1-'<'any number'>'";
            return false;
          }
        } else {
          error.value = "Supported format: A-Z/a-z/1-'<'any number'>'";
          return false;
        }
      }
    };
    const validateCustomSeatPlan = () => {
      error.value = "";
      Object.keys(errors).forEach(function (key) {
        errors[key] = ""
      });
      // requiredValidation("seats", seatDesign.customSeats.trim());

      if (seatDesign.customSeats.trim() === '') {
        return true
      } else {
        if (/\s/g.test(seatDesign.customSeats.trim())) {
          error.value = "Space not allowed";
          return false
        } else if (!/^([A-Za-z0-9-]+,?)+$/.test(seatDesign.customSeats.trim())) {
          error.value = "Invalid seats";
          return false
        } else {
          return true
        }
      }

    };
    const seats = () => {
      let seatList = [];
      if (parseInt(seatDesign.from) && parseInt(seatDesign.to)) {
        for (let i = parseInt(seatDesign.from); i <= parseInt(seatDesign.to); i++) {
          seatList.push(seatDesign.prefix + i + seatDesign.suffix);
        }
      } else {
        for (let i = seatDesign.from.charCodeAt(0); i <= seatDesign.to.charCodeAt(0); i++) {
          seatList.push(seatDesign.prefix + String.fromCharCode(i) + seatDesign.suffix);
        }
      }
      return seatList.join();
    };
    const getSeatPlan = () => {
      emit("getSeatPlan");
    };
    const blockSeats = () => {
      showLoader()
      block.shipId = shipId.value;
      block.seatClassId = spId.value;
      block.seatNumbers = blockedSeats.value.toString();
      store.dispatch("blockSeats", block).then(() => {
        hideLoader()
        clear();
        getSeatPlan();
      }).catch((error) => {
        hideLoader()
        if (error.status === 401) {
          blockSeats()
        }
      });
    };
    const addDtUpSeat = () => {
      showLoader()
      block.shipId = shipId.value;
      block.seatClassId = spId.value;
      block.seatNumbers = digitalTicketingUpSeats.value.toString();
      store.dispatch("addDtUpSeat", block).then(() => {
        hideLoader()
        clear();
        getSeatPlan();
      }).catch((error) => {
        hideLoader()
        if (error.status === 401) {
          blockSeats()
        }
      });
    };

    const addDtDownSeat = () => {
      showLoader()
      block.shipId = shipId.value;
      block.seatClassId = spId.value;
      block.seatNumbers = digitalTicketingDownSeats.value.toString();
      store.dispatch("addDtDownSeat", block).then(() => {
        hideLoader()
        clear();
        getSeatPlan();
      }).catch((error) => {
        hideLoader()
        if (error.status === 401) {
          blockSeats()
        }
      });
    };

    const addSeatPlan = () => {
      if (validate()) {
        showLoader()
        row.shipId = shipId.value;
        row.seatClassId = spId.value;
        row.seatRow = seats();
        store.dispatch("addSeatRow", row).then(() => {
          hideLoader()
          clear();
          getSeatPlan();
        }).catch((error) => {
          hideLoader()
          if (error.status === 401) {
            addSeatPlan()
          }
        });
      } else {
        errorId.value = spId.value;
      }
    };
    const addCustomSeatPlan = () => {
      if (validateCustomSeatPlan()) {
        showLoader()
        row.shipId = shipId.value;
        row.seatClassId = spId.value;
        row.seatRow = seatDesign.customSeats.trim();
        store.dispatch("addCustomSeatRow", row).then(() => {
          hideLoader()
          clear();
          getSeatPlan();
        }).catch((error) => {
          hideLoader()
          if (error.status === 401) {
            addCustomSeatPlan()
          }
        });
      } else {
        errorId.value = spId.value;
      }
    };

    return {
      seatDesign,
      companyId,
      shipId,
      spId,
      errors,
      errorId,
      error,
      blockedSeats,
      addSeatPlan,
      blockSeats,
      addCustomSeatPlan,
      addDtUpSeat,
      addDtDownSeat,
      operationName,
      getOperationName,
      digitalTicketingUpSeats,
      digitalTicketingDownSeats,
    };
  }
};
</script>

<style scoped>
.add_button {
  border: 2px solid #17A2B8;
  border-radius: 20px;
  background-color: #17A2B8;
  padding: 5px 10px;
  color: #ffffff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.add_button:hover,
.add_button:focus {
  border: 2px solid #17A2B8;
  background-color: #fff;
  color: #17A2B8;
  transition: 0.4s;
  outline: 0;
}

.quota_button {
  border: 2px solid #17A2B8;
  border-radius: 20px;
  background-color: #17A2B8;
  padding: 5px 10px;
  color: #ffffff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.quota_button:hover,
.quota_button:focus {
  border: 2px solid #17A2B8;
  background-color: #fff;
  color: #17A2B8;
  transition: 0.4s;
  outline: 0;
}


.block_button {
  border: 2px solid #ff0000;
  border-radius: 20px;
  background-color: #ff0000;
  padding: 5px 10px;
  color: #ffffff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.allBlockSeat {
  background-color: #000 !important;
}

.allDtUpSeat {
  background-color: #c96c89 !important;
}

.allDtDownSeat {
  background-color: #9997dc !important;
}

.block_button:hover,
.block_button:focus {
  border: 2px solid #ff0000;
  background-color: #fff;
  color: #ff0000;
  transition: 0.4s;
  outline: 0;
}
</style>