import apiResponse from "@/modules/apiResponse";
import toast from "@/modules/Toast";
import tokenCookie from "@/modules/tokenCookie";
import Apis from "@/urls/apis";
import axios from "axios";

const { infoToast, successToast } = toast();
const { hasValidToken, getToken } = tokenCookie();
const { errorResponse } = apiResponse();

const state = {
  filter: {
    company: {},
  },
  shipWiseReportList: [],
  userWiseReportList: [],
  agentWiseReportList: [],
  deckmanWiseReportList: [],
  shipWiseSeatDetails: [],
  shipWiseDeckDetails: [],
  shipWiseGoodDetails: [],
  counterWiseReportList: [],
  counterWiseSeatDetails: [],
  counterWiseReportData: [],
  reservationWiseReportList: [],
  reservationWiseReportDetails: [],
  agentWiseSeatDetails: [],
  deckmanWiseReportDetails: [],
  cabinmanWiseReportList: [],
  cabinmanWiseReportDetails: [],

  printTicketCountList: [],
  printTripWiseCountList: [],
  printDailySummaryWiseCountList: [],
  printSuperVisorTripWiseCountList: [],
};
const getters = {
  getShipWiseDeckDetails: (state) => state.shipWiseDeckDetails,
  // getTotalShipWiseDeckDetails: (state) => state.shipWiseDeckDetails.totalDocs,
  // getTotalAmountShipWiseDeckDetails: (state) =>
  //   state.shipWiseDeckDetails.totalAmount,
  getShipWiseGoodDetails: (state) => state.shipWiseGoodDetails,
  // getTotalShipWiseGoodDetails: (state) => state.shipWiseGoodDetails.totalDocs,
  // getTotalAmountShipWiseGoodDetails: (state) =>
  //   state.shipWiseGoodDetails.totalAmount,
  getShipWiseSeatDetails: (state) => state.shipWiseSeatDetails,
  // getTotalShipWiseSeatDetails: (state) => state.shipWiseSeatDetails.totalDocs,
  // getTotalAmountShipWiseSeatDetails: (state) =>
  //   state.shipWiseSeatDetails.totalAmount,
  getTotalTicketShipWiseSeatDetails: (state) =>
    state.shipWiseSeatDetails.totalTicket,
  getShipWiseReport: (state) => state.shipWiseReportList,
  getUserWiseReport: (state) => state.userWiseReportList,
  getAgentWiseReport: (state) => state.agentWiseReportList,
  getDeckmanWiseReport: (state) => state.deckmanWiseReportList,
  getCompanyList: (state) => state.filter.company,
  getCounterWiseReport: (state) => state.counterWiseReportList,
  getcounterWiseSeatDetails: (state) => state.counterWiseSeatDetails,
  getcounterWiseReportData: (state) => state.counterWiseReportData,
  getReservationWiseReport: (state) => state.reservationWiseReportList,
  getReservationWiseReportDetails: (state) => state.reservationWiseReportDetails,
  getAgentWiseSeatDetails: (state) => state.agentWiseSeatDetails,
  getDeckmanWiseReportDetails: (state) => state.deckmanWiseReportDetails,
  getCabinmanWiseReport: (state) => state.cabinmanWiseReportList,
  getCabinmanWiseReportDetails: (state) => state.cabinmanWiseReportDetails,

  // getTotalDeckmanWiseReportDetails: (state) =>
  //   state.deckmanWiseReportDetails.totalDocs,
  // getTotalAmountDeckmanWiseReportDetails: (state) =>
  //   state.deckmanWiseReportDetails.totalAmount,

  getPrintTicketCountList: (state) => state.printTicketCountList,
  getPrintTripWiseCountList: (state) => state.printTripWiseCountList,
  getPrintSuperVisorTripWiseCountList: (state) => state.printSuperVisorTripWiseCountList,
  getPrintDailySummaryWiseCountList: (state) => state.printDailySummaryWiseCountList,
};
const actions = {
  async getAllCompanyList({ commit }) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };
      return new Promise((resolve, reject) => {
        axios
          .get(Apis.COMPANY_LIST, {
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              let companyList = {};
              data.company.forEach((company) => {
                companyList[company._id] = company.name;
              });
              commit("setCompanyList", companyList);
              resolve(data.company);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getAllShipReport({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.SHIP_WISE_REPORT, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (data.ticket.length === 0) {
                infoToast("No Data Available")
            }
            if (status === 200) {
              commit("setShipWiseList", data.ticket);
              resolve(data.ticket);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getAllAgentList({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.AGENT_WISE_REPORT, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (data.ticket.length === 0) {
              infoToast("No Data Available");
            }
            if (status === 200) {
              commit("setAgentWiseList", data.ticket);
              resolve(data.ticket);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getDeckmanWiseReport({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.DECKMAN_WISE_REPORT, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (data.ticket.length === 0) {
              infoToast("No Data Available");
            }
            if (status === 200) {
              commit("setDeckmanWiseReport", data.ticket);
              resolve(data.ticket);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getCabinmanWiseReport({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.CABINMAN_WISE_REPORT, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (data.report.length === 0) {
              infoToast("No Data Available");
            }
            if (status === 200) {
              commit("setCabinmanWiseReport", data.report);
              resolve(data.report);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getCabinmanWiseReportDetails({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.CABINMAN_WISE_REPORT_DETAILS, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (data.report.length === 0) {

              infoToast("No Data Available");
            }
            if (status === 200) {
              commit("setCabinmanWiseReportDetails", data.report);
            }
            resolve(data.report);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },


  async getAllCounterList({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.COUNTER_WISE_REPORT_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              commit("setAllCounterListOwner", data.ticket);
              resolve(data.ticket);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async countermanTicketCancel(_, payload) {
    if (await hasValidToken()) {
        const header = {
            'Authorization': 'Bearer ' + getToken()
        };
        return new Promise((resolve, reject) => {
            axios.post(Apis.CANCEL_COUNTERMAN_TICKET, payload, { headers: header })
                .then((response) => {
                    successToast('Ticket Cancelled Successfully')
                    resolve(response)
                })
                .catch(error => {
                    reject(errorResponse(error))
                });
        });
    }
  },

  async userTicketCancel(_, payload) {
    if (await hasValidToken()) {
      const header = {
        'Authorization': 'Bearer ' + getToken()
      };
      return new Promise((resolve, reject) => {
        axios.post(Apis.CANCEL_USER_TICKET, payload, { headers: header })
            .then((response) => {
              successToast('Ticket Cancelled Successfully')
              resolve(response)
            })
            .catch(error => {
              reject(errorResponse(error))
            });
      });
    }
  },

  async getfilterAgentWiseReport({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };
      return new Promise((resolve, reject) => {
        axios
          .get(Apis.AGENT_WISE_REPORT, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (data.ticket.length === 0) {
              infoToast("No Data Available");
            }
            if (status === 200) {
              commit("setFilterAgentWiseReport", data.ticket);
            }
            resolve(data.ticket);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getAgentWiseSeatDetails({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.AGENT_WISE_SEAT_REPORT_DETAILS, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (data.ticket.length === 0) {
              infoToast("No Data Available");
            }
            if (status === 200) {
              commit("setAgentWiseSeatDetails", data.ticket);
            }
            resolve(data.ticket);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getDeckmanWiseReportDetails({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.DECKMAN_WISE_REPORT_DETAILS, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (data.ticket.length === 0) {
              infoToast("No Data Available");
            }
            if (status === 200) {
              commit("setDeckmanWiseReportDetails", data.ticket);
            }
            resolve(data.ticket);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async cancelDeckmanWiseReportDetails(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.CANCEL_DECKMAN_WISE_REPORT_DETAILS, payload, {
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              successToast(data.message);
              // commit("setDeckmanWiseReportDetails", data.ticket);
            }
            resolve(data.message);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async cancelCabinmanReportDetails(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.CANCEL_CABINMAN_REPORT_DETAILS, payload, {
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              successToast(data.message);
              // commit("setDeckmanWiseReportDetails", data.ticket);
            }
            resolve(data.message);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getDeckDetails({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.SHIP_WISE_DECK_REPORT_DETAILS, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (data.ticket.length === 0) {
              infoToast("No Data Available");
            }
            if (status === 200) {
              commit("setShipWiseDeckDetails", data.ticket);
            }
            resolve(data.ticket);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getGoodDetails({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.SHIP_WISE_GOOD_REPORT_DETAILS, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (data.ticket.length === 0) {
              infoToast("No Data Available");
            }
            if (status === 200) {
              commit("setShipWiseGoodDetails", data.ticket);
            }
            resolve(data.ticket);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getSeatDetails({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setShipWiseSeatDetails", []);

      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.SHIP_WISE_DECK_REPORT_DETAILS, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (data.ticket.length === 0) {
              infoToast("No Data Available");
            }
            if (status === 200) {
              commit("setShipWiseSeatDetails", data.ticket);
            }
            resolve(data.ticket);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getFilterShipWiseReport({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };
      return new Promise((resolve, reject) => {
        axios
          .get(Apis.SHIP_WISE_REPORT, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (data.ticket.length === 0) {
              infoToast("No Data Available");
            }
            if (status === 200) {
              commit("setFilterShipWiseReport", data.ticket);
            }
            resolve(data.ticket);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getfilterCounterWiseReport({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };
      return new Promise((resolve, reject) => {
        axios
          .get(Apis.COUNTER_WISE_REPORT, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (data.ticket.length === 0) {
              infoToast("No Data Available");
            }
            if (status === 200) {
              commit("setFilterCounterWiseReport", data.ticket);
            }
            resolve(data.ticket);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getCounterWiseSeatDetails({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.COUNTER_WISE_REPORT_DETAILS, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (data.ticket.length == 0 && (data?.report?.totalReservationCount == 0 && data?.report?.netPay == 0)) {
              infoToast("No Data Available");
            }
            if (status === 200) {
              commit("setCounterWiseReportData", data.report);
              commit("setCounterWiseSeatDetails", data.ticket);
            }
            resolve(data.ticket);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getfilterReservationWiseReport({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };
      return new Promise((resolve, reject) => {
        axios
          .get(Apis.RESERVATION_WISE_REPORT, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (data.report.length === 0) {
              infoToast("No Data Available");
            }
            if (status === 200) {
              commit("setfilterReservationWiseReport", data.report);
            }
            resolve(data.report);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getReservationWiseReportDetails({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.RESERVATION_WISE_REPORT_DETAILS, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (data.report.length === 0) {
              infoToast("No Data Available");
            }
            if (status === 200) {
              commit("setReservationWiseReportDetails", data.report);
            }
            resolve(data.report);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getPrintCountList({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.PRINT_COUNT, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {

            if(data.report && data.report.length === 0){
              infoToast("No Data Available");
            }
            if (data.ticket && data.ticket.length === 0) {
              infoToast("No Data Available");
            }
            if (status === 200) {
              commit("setCountReport", { data, payload });
              resolve();
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getAllUserWiseReport({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.USER_WISE_REPORT, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (data.ticket.length === 0) {
                infoToast("No Data Available")
            }
            if (status === 200) {
              commit("setUserWiseList", data.ticket);
              resolve(data.ticket);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
};
const mutations = {
  setFilterShipWiseReport: (state, updateList) =>
    (state.shipWiseReportList = updateList),

  setFilterCounterWiseReport: (state, updateList) =>
    (state.counterWiseReportList = updateList),

  setfilterReservationWiseReport: (state, updateList) =>
    (state.reservationWiseReportList = updateList),

  setReservationWiseReportDetails: (state, seatDetails) =>
    (state.reservationWiseReportDetails = seatDetails),

  setShipWiseDeckDetails: (state, deckDetails) =>
    (state.shipWiseDeckDetails = deckDetails),

  setShipWiseGoodDetails: (state, goodDetails) =>
    (state.shipWiseGoodDetails = goodDetails),

  setShipWiseSeatDetails: (state, seatDetails) =>
    (state.shipWiseSeatDetails = seatDetails),

  setCounterWiseSeatDetails: (state, seatDetails) =>
    (state.counterWiseSeatDetails = seatDetails),

  setCounterWiseReportData: (state, reportData) =>
    (state.counterWiseReportData = reportData),

  setShipWiseList: (state, shipWiseReport) =>
    (state.shipWiseReportList = shipWiseReport),

  setAgentWiseList: (state, agentWiseReport) =>
    (state.agentWiseReportList = agentWiseReport),

  setDeckmanWiseReport: (state, deckmanWiseReport) =>
    (state.deckmanWiseReportList = deckmanWiseReport),

  setFilterAgentWiseReport: (state, updateList) =>
    (state.agentWiseReportList = updateList),

  setAgentWiseSeatDetails: (state, seatDetails) =>
    (state.agentWiseSeatDetails = seatDetails),

  setDeckmanWiseReportDetails: (state, report) =>
    (state.deckmanWiseReportDetails = report),

  setCompanyList: (state, companyList) => (state.filter.company = companyList),

  setUserWiseList: (state, userWiseReport) =>
    (state.userWiseReportList = userWiseReport),

  setCabinmanWiseReport: (state, cabinmanWiseReport) => (state.cabinmanWiseReportList = cabinmanWiseReport),

  setCabinmanWiseReportDetails: (state, cabinmanWiseReportDetails) => (state.cabinmanWiseReportDetails = cabinmanWiseReportDetails),

  setCountReport: (state, value) => {
    state.printTicketCountList = [];
    state.printTripWiseCountList = [];
    state.printDailySummaryWiseCountList = [];
    state.printSuperVisorTripWiseCountList = [];
    if (value.payload.reportType === "ticket") {
      state.printTicketCountList = value.data.ticket;
    } else if (value.payload.reportType === "tripWiseReport") {
      state.printTripWiseCountList = value.data.report;
    } else if (value.payload.reportType === "dailySummaryReport") {
      state.printDailySummaryWiseCountList = value.data.report;
    } else if (value.payload.reportType === "supervisorTripWiseReport") {
      state.printSuperVisorTripWiseCountList = value.data.report;
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
