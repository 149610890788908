<template>
  <div v-if="reportActive">
    <FilterTab>
      <template v-slot:body>
        <div v-if="agentWiseReport.companyId" class="col-sm-6 col-md-4">
          <DateTimeRangePicker v-model="date.range" />
        </div>

        <div v-if="agentWiseReport.companyId" class="col-sm-6 col-md-2">
          <DropdownFilter
            v-bind:id="'status'"
            v-bind:label="'Status'"
            v-bind:defaultOption="'--select status--'"
            v-model="agentWiseReport.status"
            v-bind:defaultValue="''"
            v-bind:dropdown="dropdownVariables.reportStatus"
          />
        </div>
        <div v-if="agentWiseReport.companyId" class="col-sm-6 col-md-auto">
          <CheckboxSingle v-model="isDiscount" />
        </div>
        <div class="col-sm-2 col-md-auto mt-auto">
          <button class="edit_table_data font_detail" @click="filterReport">
            <i class="fas fa-filter"></i><span class="mx-1 bold">Filter</span>
          </button>
        </div>
      </template>
      <!-- <template v-slot:button2>
        <button class="edit_table_data font_detail" @click="filterReport">
          <i class="fas fa-filter"></i><span class="mx-1 bold">Filter</span>
        </button>
      </template> -->
      <template v-slot:button1>
        <button
          class="edit_table_data font_detail"
          @click="
            exportExcel(
              'xlsx',
              'Owner_AgentWise_Report.',
              undefined,
              'exportable_data'
            )
          "
        >
          <i class="fas fa-download"></i><span class="mx-1 bold">Export</span>
        </button>
      </template>
    </FilterTab>
    <Table
      v-if="agentWiseReport.companyId !== '' && filterActive"
      id="exportable_data"
    >
      <template v-slot:header>
        <tr>
          <td class="font_detail">SL</td>
          <td class="font_detail">Agent</td>
          <td class="font_detail">Total Ticket</td>
          <td class="font_detail">Total Amount</td>
          <td class="font_detail">Details</td>
        </tr>
      </template>
      <template v-slot:body>
        <tr
          v-for="(item, index) in $store.getters.getAgentWiseReportOwner"
          :key="item._id"
        >
          <td class="font_detail">{{ index + 1 }}</td>
          <td class="font_detail">{{ item.countermanName[0] }}</td>
          <td class="font_detail">{{ item.totalTicket }}</td>
          <td class="font_detail">{{ item.totalAmount }} Taka</td>
          <td class="font_detail">
            <button
              class="btn btn-success m-1 btn-sm"
              @click="seatDetails(item.countermanId)"
            >
              Details
            </button>
          </td>
        </tr>
        <tr class="bg-dark text-white" v-if="summary">
          <td class="font_detail text-white"></td>
          <td class="font_detail text-white"></td>
          <td class="font_detail text-white">Total Ticket: {{ ticketTotal }}</td>
          <td class="font_detail text-white">Total Amount: {{ amountTotal }} Taka</td>
          <td class="font_detail text-white"></td>
        </tr>
      </template>
    </Table>
  </div>
  <AgentDetails
    v-if="seatDetailsActive"
    :counterManId="counterManIdPass"
    :fromDate="date.range.start"
    :toDate="date.range.end"
    :status="parseInt(agentWiseReport.status)"
    :discount="agentWiseReport.discount"
    v-on:backToShipWiseReport="onChildClick"
  />

  <router-view></router-view>
</template>

<script>
import DateTimeRangePicker from "@/components/layouts/Filter/Inputs/DateTimeRangePicker";
import DropdownFilter from "@/components/layouts/Filter/Inputs/Dropdown";
import dropdownVariables from "@/modules/dropdownVariables";
import { reactive } from "@vue/reactivity";
import moment from "moment";
import { watch, onBeforeMount, ref, inject } from "vue";
import store from "@/store";
import AgentDetails from "./AgentDetails.vue";
import CheckboxSingle from "@/components/layouts/Inputs/CheckboxSingle.vue";

export default {
  name: "CounterReport",
  components: {
    DateTimeRangePicker,
    DropdownFilter,
    AgentDetails,
    CheckboxSingle,
  },

  setup() {
    let deckDetailsActive = ref(false);
    let seatDetailsActive = ref(false);
    let reportActive = ref(true);
    let counterManIdPass = ref("");
    let filterActive = ref(false);

    let ticketTotal = ref(0);
    let amountTotal = ref(0);
    let seatTicketTotal = ref(0);
    let seatAmountTotal = ref(0);
    let isDiscount = ref(false);
    let summary = ref(false);
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const exportExcel = inject("exportExcel");

    const fromDate = moment(new Date()).format("yyyy-MM-DD" + " 00:00:00");
    const toDate = moment(new Date()).format("yyyy-MM-DD" + " 23:59:59");

    let date = reactive({
      range: {
        start: fromDate,
        end: toDate,
      },
    });

    let agentWiseReport = reactive({
      companyId: "",
      fromDate: "",
      toDate: "",
      status: 1,
      discount: "",
      page: 1,
      limit: 10,
    });

    watch(
      () => isDiscount.value,
      () => {
        isDiscount.value
          ? (agentWiseReport.discount = 1)
          : (agentWiseReport.discount = "");
      }
    );

    watch(
      () => agentWiseReport.status,
      (value) => {
        agentWiseReport.status = value;
      }
    );
    watch(
      () => date.range.start,
      (value) => {
        agentWiseReport.fromDate = moment(value).format(
          "yyyy-MM-DD" + " 00:00:00"
        );
      }
    );
    watch(
      () => date.range.end,
      (value) => {
        agentWiseReport.toDate = moment(value).format(
          "yyyy-MM-DD" + " 23:59:59"
        );
      }
    );

    const getAllAgentList = () => {
      showLoader();
      store
        .dispatch("getAllAgentListOwner", agentWiseReport)
        .then((response) => {
          if (response.length === 0) {
            summary.value = false;
          } else {
            summary.value = true;
            ticketTotal.value = 0;
            amountTotal.value = 0;
            sumListData();
          }
          hideLoader();
        })
        .catch(() => hideLoader());
    };
    const filterReport = () => {
      filterActive.value = true;
      getAllAgentList();
    };

    const sumListData = () => {
      store.getters.getAgentWiseReportOwner.forEach((data) => {
        ticketTotal.value += data.totalTicket;
        amountTotal.value += data.totalAmount;
      });
    };

    onBeforeMount(() => {
      agentWiseReport.companyId = store.getters.userCompany;
      agentWiseReport.fromDate = fromDate;
      agentWiseReport.toDate = toDate;
    });

    const seatDetails = (counterManId) => {
      counterManIdPass.value = counterManId;
      deckDetailsActive.value = false;
      reportActive.value = false;
      seatDetailsActive.value = true;
    };

    const onChildClick = (value) => {
      reportActive.value = value;
      deckDetailsActive.value = !value;
      seatDetailsActive.value = !value;
    };

    return {
      dropdownVariables,
      date,
      agentWiseReport,
      filterReport,
      deckDetailsActive,
      seatDetailsActive,
      seatDetails,
      reportActive,
      counterManIdPass,
      filterActive,
      onChildClick,
      ticketTotal,
      amountTotal,
      seatTicketTotal,
      seatAmountTotal,
      exportExcel,
      summary,
      isDiscount,
    };
  },
};
</script>

<style scoped>
</style>