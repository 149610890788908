<template>
 <div>
     <!--      <Filter>-->
     <!--        <template v-slot:body>-->
     <!--          <div class="col-sm-6 col-md-2">-->
     <!--          </div>-->
     <!--        </template>-->
     <!--      </Filter>-->
     <Table v-if="$store.getters.getAllShipsCounter">
         <template v-slot:header>
             <tr>
                 <td class="font_detail"> SL</td>
                 <td class="font_detail"> NAME</td>
                 <td class="font_detail"> STATUS</td>
             </tr>
         </template>
         <template v-slot:body>
             <tr v-for="(ship,index) in $store.getters.getAllShipsCounter" :key="index">
                 <td class="font_detail">{{ index + 1 }}</td>
            <td class="font_detail">{{ ship.name }}</td>
   
            <td><span v-if="ship.status" class="badge bg-success">{{ dropdownVariables.status[1] }}</span>
              <span v-else class="badge bg-danger">{{ dropdownVariables.status[0] }}</span>
            </td>
          </tr>
        </template>
        <template v-slot:pagination>
          <pagination v-model="pagination.page" :records="$store.getters.getTotalShipsCounter" :per-page="pagination.limit"
                      @paginate="getAllShips" :options="options"/>
        </template>
      </Table>
    </div>


</template>

<script>
import {onBeforeMount,inject} from "vue";
import store from "@/store";
import {reactive} from "@vue/reactivity";
import dropdownVariables from "@/modules/dropdownVariables";
export default {
  name: "Ship",

  setup() {

    const showLoader = inject('showLoader');
    const hideLoader = inject('hideLoader');

    let pagination = reactive({
      page: 1,
      limit: 10,
    });

    const options = {
      texts: {
        count: ""
      }
    };

    const getAllShips = () => {
      showLoader()
      store.dispatch('getAllShipsCounter', pagination).then(() => {
        hideLoader()
      }).catch((error)=>{
        hideLoader()
        if(error.status===401){
          getAllShips()
        }
      });
    };

    onBeforeMount(() => {
        getAllShips();
    })

    return {
        pagination,dropdownVariables, options,getAllShips
    }
    
   

  }
};
</script>

