<template>
  <div class="table-responsive">
    <table class="table table-hover table-striped text-center vehicle_table">
      <thead class="font_detail bg_corporate">
      <slot name="header"></slot>
      </thead>
      <tbody>
      <slot name="body"></slot>
      </tbody>
    </table>
    <div v-if="paginationStatus" class="row mx-5">
      <nav aria-label="Page navigation example">
        <ul class="pagination justify-content-center justify-content-sm-end">
          <slot name="pagination"></slot>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity';
export default {
  name: "Table",
  props: ["pagination"],
 setup(props){
  const paginationStatus = ref(props?.pagination ?? true);


  return{
    paginationStatus,
  }
}
}
</script>

<style scoped>

</style>