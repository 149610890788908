import {dateTimeFormat} from "@/helpers/dateTimeFormat";
import moment from "moment/moment";

let dtSetTimeout;

const checkDTValidity = (singleTripDetails, refreshPageGoBack) => {
    const dtCancelTime = singleTripDetails.countermanDtExpireTime
    const dtStatus = singleTripDetails.isDTAvailable
    const currentTime = dateTimeFormat(new Date(), 6);
    const tripTimeDifference = moment(dateTimeFormat(dtCancelTime, 0)).diff(moment(currentTime))
    console.log(tripTimeDifference)
    console.log(dtStatus)
    if (dtStatus && tripTimeDifference >= 0) {
        dtSetTimeout = setTimeout(() => {
            refreshPageGoBack()
        }, tripTimeDifference)
    }
}

export {
    checkDTValidity, dtSetTimeout,
}
