<template>
    <div>
      <div v-if="digitalTicketingTabs">
        <ul
          class="nav nav-tabs justify-content-center nav-style"
          id="myTab"
          role="tablist"
        >
          <li
            v-for="(tab, index) in digitalTicketingTabs"
            :key="index"
            class="nav-item"
            role="presentation"
          >
            <button
              class="nav-link"
              :id="tab.name + '-tab'"
              data-bs-toggle="tab"
              v-bind:class="
                tab.name === activeTab
                  ? 'active active-button'
                  : 'inactive-button'
              "
              type="button"
              @click="setActive(tab.name)"
              role="tab"
              :aria-controls="tab.path"
              aria-selected="true"
            >
              {{ tab.title }}
            </button>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div
            v-for="(tab, index) in digitalTicketingTabs"
            :key="index"
            class="tab-pane fade"
            v-bind:class="tab.name === activeTab ? 'show active' : ''"
            :id="tab.path"
            role="tabpanel"
            :aria-labelledby="tab.name + '-tab'"
          ></div>
          <Stoppage v-if="activeTab === digitalTicketingTabs.STOPPAGE_URL.name" />
        </div>
      </div>
    </div>
</template>

<script>
import permission from "@/modules/permission";
import roles from "@/modules/roles";
import Stoppage from "@/views/Admin/DigitalTecketing/children/Stoppage/Stoppage";
import { onBeforeMount, onBeforeUnmount, ref } from "vue";
import web from "@/urls/web";
import store from "@/store";

const { hasPermission } = permission();
const { Admin } = roles();
export default {
  name: "DigitalTicketing",
  components: { Stoppage },
  setup() {
    let activeTab = ref("");
    const digitalTicketingTabs = web.Admin.DIGITAL_TICKETING_URL.children;
    const setActive = (index) => {
      activeTab.value = index;
      store.commit("setActiveTab", activeTab.value);
    };

    onBeforeMount(() => {
      activeTab.value = store.getters.getActiveTab
        ? store.getters.getActiveTab
        : digitalTicketingTabs.STOPPAGE_URL.name;
    });
    onBeforeUnmount(() => {
      if (store.getters.isLoggedIn) {
        store.commit("setActiveTab", "");
      }
    });
    return {
      Admin,
      activeTab,
      digitalTicketingTabs,
      setActive,
      hasPermission,
    };
  },
};
</script>

<style scoped>
.nav-style {
  background: #3b3b3b;
  border-top: 1px;
}

.active-button {
  background: #242424 !important;
  border: 1px solid #242424 !important;
  color: #fff !important;
}

.inactive-button {
  color: #fff !important;
}
</style>
