<template>
    <div>
        <div v-if="addUpdate">
            <!--            <TicketPurchaseModal v-model="addUpdate" v-bind:seatInfo="seatsDetails" @refreshPage="refreshPage"/>-->
            <TicketPurchase v-model="addUpdate" v-bind:seatInfo="seatsDetails" @refreshPage="refreshPage"/>
        </div>
        <div v-else-if="printPreview">
            <div v-if="$store.getters.userType !== 'Agent'">
                <TicketPrintPreview v-if="ticketTypeForPrint === 'lastTicket'" v-bind:ticket="lastTicketDetails"
                                    v-bind:ticketType="ticketTypeForPrint" @close="closePrintPreview"/>
                <TripReportPrintPreview v-else v-bind:trip="userTripReport" v-bind:ticketType="ticketTypeForPrint"
                                        @close="closePrintPreview"/>
            </div>
            <div v-else>
                <TicketPrintPreviewForAgent v-if="ticketTypeForPrint === 'lastTicket'" v-bind:ticket="lastTicketDetails"
                                            v-bind:ticketType="ticketTypeForPrint" @close="closePrintPreview"/>
                <TripReportPrintPreviewForAgent v-else v-bind:trip="userTripReport"
                                                v-bind:ticketType="ticketTypeForPrint"
                                                @close="closePrintPreview"/>
            </div>
        </div>
        <div v-else class="card card-body py-4" style="background-color: whitesmoke">
            <div class="row">
                <div class="row m-0">
                    <div class="col-md-4 col-lg-4 col-xl-2 order-md-1 order-xl-1">
                        <span class="fw-bold">TRIP INFORMATION</span>
                        <div class="card card-body">
                            <div class="row">
                                <div class="col-sm-4 col-md-4">
                                    <p class="text-center font_detail m-0">Sold</p>
                                    <div>
                                        <div>
                                            <div class="rounded-end sold_seats common_btn span_font w-100">
                                                <span>{{ totalBookedSeatCount }}</span>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4">
                                    <p class="text-center font_detail m-0">Blocked</p>
                                    <div
                                            class="rounded-start disabled_seat common_btn span_font w-100">
                                        <span>{{ totalBlockedSeatCount }}</span>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4">
                                    <p class="text-center font_detail m-0">Available</p>
                                    <div
                                            class="rounded-end available_other available span_font w-100">
                                        {{ totalAvailableSeatCount }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-12 col-xl-6 order-md-4 order-xl-2">
                        <span class="fw-bold">SEAT INFORMATION</span>
                        <div class="card card-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <p class="text-center font_detail m-0">SEATS</p>
                                    <div class="rounded-end available_other available span_font w-100">
                                        <span class="orange-bold-text">#{{ seatsDetails.seats.toString() }}</span>
                                    </div>
                                    <p class="text-center font_detail m-0">Total Seats:
                                        {{ seatsDetails.seats.length }}</p>
                                </div>
                                <div class="col-sm-4 col-md-4">
                                    <p class="text-center font_detail m-0">FARE(TK)</p>
                                    <div
                                            class="rounded-end available_other available span_font w-100">
                                        <span class="orange-bold-text">{{ calculateFare }}</span>
                                    </div>
                                </div>
                                <div v-if="hasPermission(Agent.agentFareDiscount)" class="col-sm-4 col-md-4">
                                    <p class="text-center font_detail m-0">DISCOUNT</p>
                                    <input v-model="seatsDetails.discount" type="Number"
                                           class="input_field form-control rounded px-3 w-100 text-center">
                                    <p class="text-danger" v-if="discountErrorMsg">Discount amount can't be greater than
                                        net fare</p>
                                </div>
                                <div class="col-sm-4 col-md-4">
                                    <p class="text-center font_detail m-0">PAYABLE(TK)</p>
                                    <div
                                            class="rounded-end available_other available span_font w-100">
                                        <span class="orange-bold-text">{{ discountedFare }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-lg-4 col-xl-2 order-md-2 order-xl-3">
                        <span class="fw-bold">PRINT</span>
                        <div class="card card-body">
                            <div class="row">
                                <div class="col-sm-6 col-md-6">
                                    <p class="text-center font_detail m-0">TRIP REPORT</p>
                                    <button class="trip_button w-100" @click="tripReport(trip)"><i
                                            class="fas fa-print"></i></button>
                                    <!-- <p class="text-center font_detail m-0">SHIP NAME</p> -->
                                </div>
                                <div class="col-sm-6 col-md-6">
                                    <p class="text-center font_detail m-0">LAST TICKET</p>
                                    <button class="last_ticket  w-100" @click="lastTicket(trip)"><i
                                            class="fas fa-print"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-lg-4 col-xl-2 order-md-3 order-xl-4">
                        <span class="fw-bold">ACTIONS</span>
                        <div class="card card-body">
                            <div class="row">
                                <div class="col-sm-6 col-md-6">
                                    <p class="text-center font_detail m-0">RESET</p>
                                    <button class="remove_button w-100" @click="reset"><i
                                            class="fas fa-trash-alt"></i></button>
                                </div>
                                <div class="col-sm-6 col-md-6">
                                    <p class="text-center font_detail  m-0">REFRESH</p>
                                    <button class="refresh_button w-100" @click="refreshPage()"><i
                                            class="fas fa-sync-alt"></i></button>
                                </div>
                                <div v-if="hasPermission(Agent.agentTicketBooking)" class="col-sm-12 col-md-12">
                                    <p class="text-center font_detail m-0">CONFIRM</p>
                                    <button class="confirm_button w-100" @click="confirmSeats()"><i
                                            class="fas fa-check"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="seatPlans.length!==0" style="margin: 5px 0">
                    <ul class="nav nav-tabs justify-content-center nav-style border-0" id="myTab"
                        role="tablist">
                        <li v-for="(tab,index) in seatPlans" :key="index" class="nav-item" role="presentation">
                            <button class="nav-link py-2 px-4" :id="tab.name + '-tab'" data-bs-toggle="tab"
                                    v-bind:class="tab._id === seatPlan._id ? 'active active-button' : 'inactive-button'"
                                    type="button" @click="setSeatPlan(tab)" role="tab" :aria-controls="tab._id"
                                    aria-selected="true">
                                {{ tab.name }}
                            </button>
                        </li>
                    </ul>
                    <div class="card card-body p-0 tab-content" id="myTabContent">
                        <div v-for="(tab,index) in seatPlans" :key="index" class="tab-pane fade"
                             v-bind:class="tab._id === seatPlan._id ? 'show active' : ''" :id="tab._id"
                             role="tabpanel" :aria-labelledby="tab.name + '-tab'">
                        </div>
                        <SeatList v-model="seatsDetails.seats" :seatPlan="seatPlan"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {computed, inject, onBeforeMount, ref, watch} from "vue";
import SeatList from "./SeatList";
// import TicketPurchaseModal from "./TicketPurchaseModal";
import TicketPurchase from "./TicketPurchase";
import {reactive} from "@vue/reactivity";
import fareModality from "@/modules/fareModality";
import store from "@/store";
import dropdownVariables from '../../../../modules/dropdownVariables';
import toast from "@/modules/Toast";
import permission from "@/modules/permission";
import roles from "@/modules/roles";

const {errorToast} = toast();
const {hasPermission} = permission();
const {Agent} = roles();

const {getNetFare} = fareModality();
import moment from "moment";

export default {
    name: "TicketBooking",
    components: {SeatList, TicketPurchase},
    props: {
        tripId: String,
        tripDetails: Object
    },
    setup(props) {
        const trip = ref(props.tripId);
        const addUpdate = ref(false);
        const printPreview = ref(false);
        const tripIdForPrint = ref('');
        const ticketTypeForPrint = ref('');
        const userTripReport = ref([]);
        const lastTicketDetails = ref({});
        let discountErrorMsg = ref('');
        const propTripDetails = ref(props.tripDetails);
        let totalBookedSeatCount = ref(0);
        let totalBlockedSeatCount = ref(0);
        let totalAvailableSeatCount = ref(0);
        const title = ref("Print Booking");
        const showLoader = inject('showLoader');
        const hideLoader = inject('hideLoader');

        let seatsDetails = reactive({
            tripId: trip.value,
            seats: [],
            seatClassId: "",
            discount: 0,
            totalAmount: 0,
            payable: 0
        });

        let pagination = reactive({
            page: 1,
            limit: 10,
            companyId: store.getters.userCompany,
            from: "",
            to: "",
            ship: "",
            date: moment(new Date()).format('yyyy-MM-DD'),
            status: "ACTIVE"
        });
        const seatPlans = [];
        let seatPlan = ref();

        watch(() => seatsDetails.discount, () => {
            if (seatsDetails.discount > calculateFare.value) {
                discountErrorMsg.value = "Discount amount can't be greater than net fare";
                seatsDetails.discount = 0;
            }
        });

        const close = () => {
            addUpdate.value = false;
        };
        const calculateFare = computed(() => {
            let netFare = 0;
            seatPlans.forEach((plan) => {
                if (plan._id === seatsDetails.seatClassId) {
                    netFare = getNetFare(seatsDetails.seats, plan.upFare, plan.downFare, plan.direction);
                }
            });
            return netFare;
        });

        const reset = () => {
            seatsDetails.seats = [];
            seatsDetails.discount = 0;
        };

        const discountedFare = computed(() => {
            return calculateFare.value - seatsDetails.discount;
        });

        const setSeatPlan = (plan) => {
            seatsDetails.discount = 0;
            seatsDetails.seatClassName = plan.name;
            seatPlan.value = plan;
            seatsDetails.seatClassId = seatPlan.value._id;
            seatsDetails.seats = [];
        };

        const refreshPage = () => {
            showLoader()
            store.dispatch('getActiveTripsForTicketingOfAgent', pagination).then(() => {
                hideLoader()
            }).catch(() => {
                hideLoader()
            });
        };
        const tripReport = (tripId) => {
            tripIdForPrint.value = tripId;
            showLoader()
            store.dispatch('getAgentTripReport', {tripId: tripIdForPrint.value}).then((tripReport) => {
                hideLoader()
                if (tripReport) {
                    userTripReport.value = tripReport
                    ticketTypeForPrint.value = 'tripReport'
                    printPreview.value = true
                }
            }).catch(() => {
                hideLoader()
                errorToast("Something wrong.Please try again later.")
            })
        };
        const lastTicket = (tripId) => {
            tripIdForPrint.value = tripId;
            showLoader()
            store.dispatch('getCountermanLastTicket', {tripId: tripIdForPrint.value}).then((lastTicket) => {
                hideLoader()
                if (lastTicket.length > 0) {
                    lastTicketDetails.value = lastTicket[0]
                    ticketTypeForPrint.value = 'lastTicket'
                    printPreview.value = true
                }
            }).catch(() => {
                hideLoader()
                errorToast("Something wrong.Please try again later.")
            })
        };
        const closePrintPreview = () => {
            printPreview.value = false
            tripIdForPrint.value = '';
            ticketTypeForPrint.value = ''
        }

        const tripInfo = () => {
            const tripSingle = store.getters.getTripForTicketingByAgent(trip.value)[0];
            const bookedSeats = tripSingle.bookedSeats;
            const seatPlan = tripSingle.ship.seatPlan;

            // if (bookedSeats.length === 0) {
            //
            //    totalBookedSeat.value = [];
            //
            // } else {
            //    bookedSeats.forEach(bookedSeat => {
            //       totalBookedSeatCount.value += bookedSeat.seat_no.split(',').length
            //    });
            // }
            if (bookedSeats.length > 0) {
                bookedSeats.forEach(bookedSeat => {
                    totalBookedSeatCount.value += bookedSeat.seat_no.split(',').length
                });
            }
            // if (seatPlan.length !== 0) {
            //
            //    seatPlan.forEach(availableSeat => {
            //       // totalAvailableSeat.value;
            //       availableSeat.seatList.forEach(seat => {
            //          totalAvailableSeat.value.push(seat.split(','));
            //       });
            //    });
            //
            //    totalAvailableSeat.value.forEach(item => {
            //       totalAvailableSeatCount.value += item.length;
            //    });
            //    totalAvailableSeatCount.value -= totalBookedSeatCount.value;
            // }

            seatPlan.forEach(item => {
                // totalBlockedSeatCount
                let flag = false;
                item.seatList.forEach(seat => {
                    totalAvailableSeatCount.value += seat.split(',').length
                });
                if (item.blockedSeat) {
                    totalBlockedSeatCount.value += item.blockedSeat.split(',').length
                }
                bookedSeats.forEach(seat => {
                    if (item._id === seat.seat_class_id) {
                        const object = {
                            bookedList: seat.seat_no,
                            blockedSeats: item.blockedSeat,
                            seatList: item.seatList,
                            _id: item._id,
                            name: item.name,
                            upFare: item.upFare,
                            downFare: item.downFare,
                            direction: store.getters.getTripForTicketingByAgent(trip.value)[0].direction
                        };
                        seatPlans.push(object);
                        flag = true;
                    }

                });
                if (!flag) {
                    const object = {
                        bookedList: "",
                        blockedSeats: item.blockedSeat,
                        seatList: item.seatList,
                        _id: item._id,
                        name: item.name,
                        upFare: item.upFare,
                        downFare: item.downFare,
                        direction: store.getters.getTripForTicketingByAgent(trip.value)[0].direction
                    };
                    seatPlans.push(object);
                    flag = false;
                }
            });
            totalAvailableSeatCount.value -= (totalBookedSeatCount.value + totalBlockedSeatCount.value)
        };

        const confirmSeats = () => {
            if (seatsDetails.seats.length > 0) {
                seatsDetails.totalAmount = calculateFare.value;
                seatsDetails.payable = discountedFare.value;
                addUpdate.value = true;
            } else {
                errorToast('Please select any seat');
            }

        };

        onBeforeMount(() => {
            tripInfo();
            if (seatPlans.length > 0) {
                setSeatPlan(seatPlans[0]);
            }
        });
        return {
            discountErrorMsg,
            ticketTypeForPrint,
            printPreview,
            Agent,
            addUpdate,
            seatsDetails,
            userTripReport,
            lastTicketDetails,
            totalAvailableSeatCount,
            totalBookedSeatCount,
            dropdownVariables,
            seatPlans,
            title,
            trip,
            seatPlan,
            calculateFare,
            propTripDetails,
            discountedFare,
            totalBlockedSeatCount,
            hasPermission,
            tripReport,
            reset,
            close,
            lastTicket,
            confirmSeats,
            closePrintPreview,
            setSeatPlan,
            refreshPage,
        };
    }
};
</script>

<style scoped>
.nav-style {
    background: whitesmoke;
    border-top: 1px
}

.active-button {
    background: white !important;
    /*border: 1px solid #242424 !important;*/
    color: #000 !important;
}

.inactive-button {
    color: #888 !important;
}

.available {
    color: #000000;
    padding: 5px 10px;
    border-radius: 4px;
    text-transform: uppercase;
}

.span_font {
    font-size: 14px;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: 500;
}

.trip_button {
    margin: 1px 2px;
    border: 2px solid #9400D3;
    border-radius: 4px;
    background-color: #9400D3;
    padding: 2px 6px;
    color: #fff;
    position: relative;
    font-size: 14px;
    font-family: Helvetica Neue;
}

.trip_button:hover,
.trip_button:focus {
    border: 2px solid #9400D3;
    background-color: #fff;
    color: #9400D3;
    transition: 0.4s;
    outline: 0;
}

.last_ticket {
    margin: 1px 2px;
    border: 2px solid #00BFFF;
    border-radius: 4px;
    background-color: #00BFFF;
    padding: 2px 6px;
    color: #fff;
    position: relative;
    font-size: 14px;
    font-family: Helvetica Neue;
}

.last_ticket:hover,
.last_ticket:focus {
    border: 2px solid #00BFFF;
    background-color: #fff;
    color: #00BFFF;
    transition: 0.4s;
    outline: 0;
}

.remove_button {
    margin: 1px 2px;
    border: 2px solid #ff0000;
    border-radius: 4px;
    background-color: #ff0000;
    padding: 2px 6px;
    color: #fff;
    position: relative;
    font-size: 14px;
    font-family: Helvetica Neue;
}

.remove_button:hover,
.remove_button:focus {
    border: 2px solid #ff0000;
    background-color: #fff;
    color: #ff0000;
    transition: 0.4s;
    outline: 0;
}

.confirm_button {
    margin: 1px 2px;
    border: 2px solid #34a853;
    border-radius: 4px;
    background-color: #34a853;
    padding: 2px 6px;
    color: #fff;
    position: relative;
    font-size: 14px;
    font-family: Helvetica Neue;
}

.confirm_button:hover,
.confirm_button:focus {
    border: 2px solid #34a853;
    background-color: #fff;
    color: #34a853;
    transition: 0.4s;
    outline: 0;
}

.refresh_button {
    margin: 1px 2px;
    border: 2px solid #FFC107;
    border-radius: 4px;
    background-color: #FFC107;
    padding: 2px 6px;
    color: #ffff;
    position: relative;
    font-size: 14px;
    font-family: Helvetica Neue;
}

.refresh_button:hover,
.refresh_button:focus {
    border: 2px solid #FFC107;
    background-color: #fff;
    color: #FFC107;
    transition: 0.4s;
    outline: 0;
}
</style>