<template>
  <div v-if="hasPermission(Owner.ownerReports)">
    <div>
      <div v-if="reportTabs">
        <ul
          class="nav nav-tabs justify-content-center nav-style"
          id="myTab"
          role="tablist"
        >
          <li
            v-for="(tab, index) in reportTabs"
            :key="index"
            class="nav-item"
            role="presentation"
          >
            <button
              class="nav-link"
              :id="tab.name + '-tab'"
              data-bs-toggle="tab"
              v-bind:class="
                tab.name === activeTab
                  ? 'active active-button'
                  : 'inactive-button'
              "
              type="button"
              @click="setActive(tab.name)"
              role="tab"
              :aria-controls="tab.path"
              aria-selected="true"
            >
              {{ tab.title }}
            </button>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div
            v-for="(tab, index) in reportTabs"
            :key="index"
            class="tab-pane fade"
            v-bind:class="tab.name === activeTab ? 'show active' : ''"
            :id="tab.path"
            role="tabpanel"
            :aria-labelledby="tab.name + '-tab'"
          ></div>
          <ShipReport v-if="activeTab === reportTabs.SHIP_REPORT_URL.name" />
          <CounterReport
            v-if="activeTab === reportTabs.COUNTER_REPORT_URL.name"
          />
          <DeckmanReport
            v-if="activeTab === reportTabs.DECKMAN_REPORT_URL.name"
          />
          <CabinmanReport
            v-if="activeTab === reportTabs.CABINMAN_REPORT_URL.name"
          />
          <AgentReport v-if="activeTab === reportTabs.AGENT_REPORT_URL.name" />
          <UserReport v-if="activeTab === reportTabs.USER_REPORT_URL.name" />

          <PrintCountReport
            v-if="activeTab === reportTabs.PRINT_COUNT_REPORT_URL.name"
          />
          <ReservationReport
            v-if="activeTab === reportTabs.RESERVATION_REPORT_URL.name"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import permission from "@/modules/permission";
import roles from "@/modules/roles";
import CounterReport from "@/views/Owner/Report/Children/Counter/CounterReport.vue";
import AgentReport from "@/views/Owner/Report/Children/Agent/AgentReport.vue";
import ShipReport from "@/views/Owner/Report/Children/ShipReport";
import DeckmanReport from "@/views/Owner/Report/Children/Deckman/DeckmanReport.vue";
import CabinmanReport from "@/views/Owner/Report/Children/Cabinman/CabinmanReport.vue";
import PrintCountReport from "@/views/Owner/Report/Children/PrintCount/PrintCountReport.vue";
import UserReport from "@/views/Owner/Report/Children/User/UserReport.vue";
import ReservationReport from "@/views/Owner/Report/Children/Reservation/ReservationReport.vue";

import { onBeforeMount, onBeforeUnmount, ref } from "vue";
import web from "@/urls/web";
import store from "@/store";

const { hasPermission } = permission();
const { Owner } = roles();
export default {
  name: "OwnerReport",
  components: {
    ShipReport,
    CounterReport,
    AgentReport,
    PrintCountReport,
    DeckmanReport,
    UserReport,
    ReservationReport,
    CabinmanReport
  },
  setup() {
    let activeTab = ref("");
    const reportTabs = web.Owner.REPORT_URL.children;

    const setActive = (index) => {
      activeTab.value = index;
      store.commit("setActiveTab", activeTab.value);
    };

    onBeforeMount(() => {
      activeTab.value = store.getters.getActiveTab
        ? store.getters.getActiveTab
        : reportTabs.SHIP_REPORT_URL.name;
    });
    onBeforeUnmount(() => {
      if (store.getters.isLoggedIn) {
        store.commit("setActiveTab", "");
      }
    });
    return {
      Owner,
      activeTab,
      reportTabs,
      setActive,
      hasPermission,
    };
  },
};
</script>

<style scoped>
.nav-style {
  background: #3b3b3b;
  border-top: 1px;
}

.active-button {
  background: #242424 !important;
  border: 1px solid #242424 !important;
  color: #fff !important;
}

.inactive-button {
  color: #fff !important;
}
</style>
