<template>
  <cancellationCause
    v-if="modelValue"
    v-model="modelValue"
    :ticketIdStore="ticketIdStore"
    :ticketTotalSeats="ticketSeats"
    :counterWiseSeatReport="counterWiseSeatReport"
    @getSeatDetails="getSeatDetails"
  />
  <div>
    <FilterTab>
      <template v-slot:body>
         <!-- <div class="col-sm-6 col-md-2">
            <DropdownFilter
                v-bind:id="'shipName'"
                v-bind:label="'Ship Name'"
                v-bind:defaultOption="'--select ship name--'"
                v-model="shipForFilter"
                v-bind:defaultValue="''"
                v-bind:dropdown="shipsForFilter"
            /> 
        </div>-->
      </template>
      <template v-slot:button2>
        <button class="edit_table_data font_detail" @click="goBack">
          <i class="fas fa-arrow-left"></i
          ><span class="mx-1 bold">Go Back</span>
        </button>
      </template>
      <template v-slot:button1>
        <button
          class="edit_table_data font_detail"
          @click="exportTable('xlsx')"
        >
          <i class="fas fa-download"></i><span class="mx-1 bold">Export</span>
        </button>
      </template>
    </FilterTab>
  </div>

  <div id="exportable_data">
    <div class="card-body mt-0 p-4 table-responsive">
      <table width="100%" class="table table-hover table-striped text-center vehicle_table border">
          <thead>
            <tr class="border">
              <th colspan="10" class="card-header text-center bg-dark">
                <h5 class="mb-0 text-uppercase text-white">Summary</h5>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="bg_corporate">
              <td colspan="2" width="35%" class="text-white">Confirm Ticket</td>
              <td colspan="2" width="35%" class="text-white">Reserved Ticket</td>
              <td width="15%" class="font_detail text-white">Total Due Amount</td>
              <td width="15%" class="font_detail text-white">Net Pay</td>
            </tr>
            <tr class="">
              <td class="font_detail">Total Ticket</td>
              <td class="font_detail">Total Amount</td>
              <td class="font_detail">Total Reserved Ticket</td>
              <td class="font_detail">Total Reserved Amount</td>
              <td rowspan="2" class="font_detail text-black bgGray">{{ counterWiseReportData.dueAmount }}</td>
              <td rowspan="2" class="font_detail text-black bgGray">{{ counterWiseReportData.netPay }}</td>
            </tr>
            <tr class="">
              <td class="font_detail text-black">{{ counterWiseReportData.totalTicket }}</td>
              <td class="font_detail text-black">{{ counterWiseReportData.totalAmount }}</td>
              <td class="font_detail text-black">{{ counterWiseReportData.totalReservationCount }}</td>
              <td class="font_detail text-black border">{{ counterWiseReportData.totalReservationAmount }}</td>
            </tr>
          </tbody>
      </table>
    </div>
    <div v-if="reportStatus != 'reserved'">
      <div
        class="card h-100 mt-4"
        v-for="(itemValue, itemKey) in classWiseReport"
        :key="itemKey"
      >

        <table>
          <tr colspan="100">
            <th><h5 class="mb-0 py-2 text-uppercase fw-bold text-center text-orange">{{ itemKey }}</h5></th>
          </tr>
        </table>
        <div class="card-body mt-0 py-0">
          <table-simple :id="itemKey">
            <template v-slot:header>
              <tr>
                <td class="font_detail">SL</td>
                <td class="font_detail">Ticket Date Time</td>
                <td class="font_detail" v-if="counterWiseSeatReport.ticketDateTime">Trip Date Time</td>
                <td class="font_detail">Ship Name</td>
                <td class="font_detail">Passenger Name</td>
                <td class="font_detail">Passenger Phone</td>
                <td class="font_detail">Boarding Place</td>
                <td class="font_detail">Dropping Place</td>
                <td class="font_detail">Floor Name</td>
                <td class="font_detail">Seat Numbers</td>
                <td class="font_detail">Total Ticket</td>
                <td class="font_detail">Receivable Amount</td>
                <td class="font_detail" v-if="counterWiseSeatReport.discount">Discount</td>
                <td class="font_detail">Issued By</td>
                <td class="font_detail">Status</td>
                <td class="font_detail" v-if="reportStatus === 'canceled'">Cancelled By</td>
                <td class="font_detail" v-if="reportStatus === 'canceled'">Cancellation Cause</td>
                <td class="font_detail" v-if="reportStatus === 'canceled'">Cancelled At</td>
                <td class="font_detail" v-if="hasPermission(Admin.cancelCountermanTicket) && reportStatus != 'canceled'">Cancel</td>
              </tr>
            </template>
            <template v-slot:body>
              <tr v-for="(item, index) in itemValue.totalReport" :key="index">
                <td class="font_detail">{{ index + 1 }}</td>
                <td class="font_detail">{{ dateFormat(item.ticketDateTime) }}</td>
                <td class="font_detail" v-if="counterWiseSeatReport.ticketDateTime">{{ dateFormat2(item.tripDateTime) }}</td>
                <td class="font_detail">{{ item.shipName }}</td>
                <td class="font_detail">{{ item.passengerName || 'N/A' }}</td>
                <td class="font_detail">{{ item.passengerPhone || 'N/A' }}</td>
                <td class="font_detail">{{ item.boardingPlace }}</td>
                <td class="font_detail">{{ item.droppingPoint }}</td>
                <td class="font_detail">{{ item.floorName || 'N/A' }}</td>
                <td class="font_detail">{{ item.seatNumbers.replaceAll(",", ", ") }}</td>
                <td class="font_detail">{{ item.totalSeat }}</td>
                <td class="font_detail">{{ item.payable}}</td>
                <td class="font_detail" v-if="counterWiseSeatReport.discount">{{ item.discount }}</td>
                <td class="font_detail">{{ item.issuedBy }}</td>
                <td class="font_detail">{{ item.ticketType === 'RESERVED' ? 'Reserved' : (item.status ? "Confirmed" : "Cancelled") }}</td>
                <td class="font_detail" v-if="reportStatus === 'canceled'">{{ item.cancelledBy }}</td>
                <td class="font_detail" v-if="reportStatus === 'canceled'">{{ item.cancellationCause }}</td>
                <td class="font_detail" v-if="reportStatus === 'canceled'">{{ item.cancelledAt ? dateFormat(item.cancelledAt) : 'N/A' }}</td>
                <td class="font_detail" v-if="hasPermission(Admin.cancelCountermanTicket) && reportStatus != 'canceled'">
                  <button class="btn btn-danger m-1 btn-sm" @click="cancelTicket(item._id, item.seatNumbers)">
                    <i></i><span class="mx-1">CANCEL</span>
                  </button>
                </td>
              </tr>
              <tr class="bg-dark text-white">
                <td class="font_detail"></td>
                <td class="font_detail"></td>
                <td class="font_detail" v-if="counterWiseSeatReport.ticketDateTime"></td>
                <td class="font_detail"></td>
                <td class="font_detail"></td>
                <td class="font_detail"></td>
                <td class="font_detail"></td>
                <td class="font_detail"></td>
                <td class="font_detail"></td>
                <td class="font_detail"></td>
                <td class="font_detail text-white">Total Ticket: {{ itemValue.totalSeat }}</td>
                <td class="font_detail text-white">Total Amount: {{ itemValue.payable }} Taka</td>
                <td class="font_detail text-white" v-if="counterWiseSeatReport.discount">Total Discount: {{ itemValue.totalDiscount }} Taka</td>
                <td class="font_detail"></td>
                <td class="font_detail"></td>
                <td class="font_detail text-white" v-if="reportStatus === 'canceled'"></td>
                <td class="font_detail text-white" v-if="reportStatus === 'canceled'"></td>
                <td class="font_detail text-white" v-if="reportStatus === 'canceled'"></td>
                <td class="font_detail text-white" v-if="hasPermission(Admin.cancelCountermanTicket) && reportStatus != 'canceled'"></td>
              </tr>
            </template>
          </table-simple>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { reactive } from "@vue/reactivity";
import { ref, onBeforeMount, inject, watch, computed } from "vue";
import store from "@/store";
import moment from "moment";
import XLSX from "xlsx";
import cancellationCause from "./CancellationCause.vue";
import permission from "@/modules/permission";
import roles from "@/modules/roles";
const { hasPermission } = permission();
const { Admin } = roles();

export default {
  name: "CounterSeatDetails",
  components: {
    cancellationCause,
  },
  props: {
    counterManId: String,
    counterName: String,
    shipName: String,
    fromDate: String,
    toDate: String,
    status: String,
    direction: String,
    discount: [Number, String],
    ticketDateTime: [Number, String],
  },
  emits: ["backToShipWiseReport"],
  setup(props, { emit }) {
    let counterManId = ref(props.counterManId);
    let fromDate = ref(props.fromDate);
    let toDate = ref(props.toDate);
    let counterId = ref(props.counterName);
    let shipId = ref(props.shipName);
    let totalAmountSeat = ref(0);
    let totalSeat = ref(0);
    let totalDiscount = ref(0);
    const shipForFilter = ref("")
    const shipsForFilter = reactive({})
    let filteredSeatDetails = ref([])
    const counterWiseReportData = ref([])
    let summary = ref(false);
    const reportStatus = ref(props.status);
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    let modelValue = ref(false);
    let ticketIdStore = ref("");
    let ticketSeats = ref([]);
    let counterWiseSeatReport = reactive({
      ticketType: "SEAT",
      countermanId: counterManId.value,
      counterId: counterId.value,
      shipId: shipId.value,
      fromDate: fromDate.value,
      toDate: toDate.value,
      direction: props.direction,
      status: props.status,
      discount: props.discount,
      ticketDateTime: props.ticketDateTime,
      page: 1,
      limit: 10,
    });

    // let classWiseReport = reactive({});

    const dateFormat = (date) => {
      return moment(String(date))
        .utcOffset(6, false)
        .format("DD-MMM-yyyy hh:mm A");
    };

    const dateFormat2 = (date) => {
      return moment(String(date))
      .utcOffset(0, false)
      .format("DD-MMM-yyyy hh:mm A");
    };

    watch([shipForFilter], ([ship]) => {
      if (ship) {
        filteredSeatDetails.value = store.getters.getcounterWiseSeatDetails.filter(
            item => item.shipName === ship
        )
      }
      else {
        filteredSeatDetails.value = store.getters.getcounterWiseSeatDetails
      }
    })

    const getSeatDetails = () => {
      showLoader();
      store
        .dispatch("getCounterWiseSeatDetails", counterWiseSeatReport)
        .then(() => {
          hideLoader();
          seatDetailsdata()
        })
        .catch(() => {
          hideLoader()
        });
    };

    const seatDetailsdata = () => {
      filteredSeatDetails.value = store.getters.getcounterWiseSeatDetails
      counterWiseReportData.value = store.getters.getcounterWiseReportData
      filteredSeatDetails.value.forEach((data) => {
        if (data.shipName){
          shipsForFilter[data.shipName] = data.shipName
        }
      });
    };

    const cancelTicket = (ticketId, seats) => {
      ticketSeats.value = seats.split(",");
      modelValue.value = true;
      ticketIdStore.value = ticketId;
    };

    const classWiseReport = computed(() => {
      const data = {}
      const payable = []
      const totalSeat = []
      const totalDiscount = []
      filteredSeatDetails.value.forEach((report) => {
        report.seatClassName = report.seatClassName.replace(/[\s]+/g, ' ').trim().toUpperCase()
        if (report.seatClassName in data) {
          data[report.seatClassName].totalReport.push(report);
          payable[report.seatClassName] = 
            payable[report.seatClassName] == undefined ? report.payable : payable[report.seatClassName] + report.payable;
          totalSeat[report.seatClassName] = 
            totalSeat[report.seatClassName] == undefined ? report.totalSeat : totalSeat[report.seatClassName] + report.totalSeat;
          totalDiscount[report.seatClassName] = 
            totalDiscount[report.seatClassName] == undefined ? report.discount : totalDiscount[report.seatClassName] + report.discount;
          data[report.seatClassName].payable = payable[report.seatClassName];
          data[report.seatClassName].totalSeat = totalSeat[report.seatClassName];
          data[report.seatClassName].totalDiscount = totalDiscount[report.seatClassName];
        } 
        else {
          data[report.seatClassName] = {
            totalReport: [],
          };
          data[report.seatClassName].totalReport.push(report);
          payable[report.seatClassName] = 
            payable[report.seatClassName] == undefined ? report.payable : payable[report.seatClassName] + report.payable;
          totalSeat[report.seatClassName] = 
            totalSeat[report.seatClassName] == undefined ? report.totalSeat : totalSeat[report.seatClassName] + report.totalSeat;
          totalDiscount[report.seatClassName] = 
            totalDiscount[report.seatClassName] == undefined ? report.discount : totalDiscount[report.seatClassName] + report.discount;
          data[report.seatClassName].payable = payable[report.seatClassName];
          data[report.seatClassName].totalSeat = totalSeat[report.seatClassName];
          data[report.seatClassName].totalDiscount = totalDiscount[report.seatClassName];
        }
      });
    summary = true;
    return data;
    });

    const goBack = () => {
      emit("backToShipWiseReport", true);
    };
    const exportTable = (type, fn, dl) => {
      if (document.getElementById("exportable_data") !== null) {
        var elt = document.getElementById("exportable_data");
        var wb = XLSX.utils.table_to_book(elt, {sheet: "sheet Js"});
        return dl
            ? XLSX.write(wb, {bookType: type, bookSST: true, type: "base64"})
            : XLSX.writeFile(
                wb,
                fn || "Admin_CounterWise_Details_Report." + (type || "xlsx")
            );
      }
    };

    onBeforeMount(() => {
      getSeatDetails();
    });

    return {
      counterWiseSeatReport,
      totalAmountSeat,
      goBack,
      exportTable,
      totalSeat,
      summary,
      reportStatus,
      totalDiscount,
      dateFormat,
      dateFormat2,
      classWiseReport,
      shipForFilter,
      shipsForFilter,
      filteredSeatDetails,
      counterWiseReportData,
      counterId,
      shipId,
      hasPermission,
      Admin,
      cancelTicket,
      ticketIdStore,
      ticketSeats,
      modelValue,
      getSeatDetails
    };
  },
};
</script>

<style scoped>
h3,
h5 {
  font-size: 36px;
  color: #f04935;
}
h5 {
  font-size: 18px;
}
.bgGray{
  background-color: #eaeaea;
}
</style>