<template>
  <Modal @close="closeModal">
    <template v-slot:title>Cancel Ticket</template>
    <template v-slot:body>
      Are you sure you want to cancel this ticket?
      <div class="form-group row my-1">
        <label class="col-sm-3 col-form-label"></label>
        <div class="col-sm-9">
          <input
            type="submit"
            class="mt-3 py-2 submit_button float-end font_detail"
            value="Cancel"
            @click.prevent="cancelTicket"
          />
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/layouts/Modals/Modal";
import { inject } from "vue";
import store from "@/store";
import { onBeforeMount } from "vue";
export default {
  name: "CancelTicketModal",
  components: { Modal },
  props: {
    tecketId: [String, Number],
  },
  setup(props, { emit }) {
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const closeModal = () => {
      emit("update:modelValue", false);
    };
    const cancelTicket = () => {
      showLoader();
      store
        .dispatch("cancelDeckmanWiseReportDetails", {
          ticketId: props.tecketId,
        })
        .then(() => {
          hideLoader();
          closeModal();
          emit("getAllTicketReport");
        })
        .catch(() => hideLoader());
    };
    onBeforeMount(() => {});
    return {
      cancelTicket,
      closeModal,
    };
  },
};
</script>

<style scoped></style>
