import apiResponse from "@/modules/apiResponse";
import tokenCookie from "@/modules/tokenCookie";
import Apis from "@/urls/apis";
import axios from "axios";


const {hasValidToken,getToken} = tokenCookie();
const {errorResponse} = apiResponse();

const state = {
    dashboardTickets: [],
};
const getters = {
    getDashboardTickets: (state) => state.dashboardTickets, 
};
const actions = {
    async getDashboardTickets({commit}, payload) {
        if (await hasValidToken()) {
            commit("setDashboardTickets", []);
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.get(Apis.GET_ALL_DASHBOARD_TICKETS, {
                    params: payload,
                    headers: header
                })
                    .then(({data, status}) => {
                        if (status === 200) {
                            commit("setDashboardTickets", data.ticket);
                            resolve({'success': 'success'});
                        }
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

};
const mutations = {
    setDashboardTickets: (state, tickets) => (state.dashboardTickets = tickets),
};

export default {
    state,
    getters,
    actions,
    mutations
};