<template>
  <label :for="inputId" class="col-sm-3 col-form-label font_detail">
    {{ inputLabel }}
  </label>
  <div class="col-sm-9 switch-box">
    <div class="form-check form-switch switch">
      <input
        v-model="input"
        class="form-check-input form-control"
        type="checkbox"
        :id="inputId"
        :disabled="disability"
      />
      <slot name="error"></slot>
    </div>
    <!--    <input :id="inputId" v-model="input" :type="inputType"-->
    <!--           class="form-control font_detail">-->
    <!--          <label class="form-check-label" for="flexSwitchCheckDefault">Yes</label>-->
  </div>
</template>

<script>
import { ref, watch } from "vue";

export default {
  name: "CheckSwitch",
  props: {
    modelValue: Boolean,
    label: String,
    id: String,
    disability: Boolean,
  },
  setup(props, { emit }) {
    const inputLabel = ref(props.label);
    const inputId = ref(props.id);
    const input = ref(props.modelValue);
    watch(
      () => input.value,
      () => {
        emit("update:modelValue", input.value);
      }
    );
    watch(
      () => props.modelValue,
      (value) => {
        input.value = value;
      }
    );
    return {
      inputLabel,
      inputId,
      input,
    };
  },
};
</script>

<style scoped>
.switch {
  margin-bottom: 0 !important;
}

.switch-box {
  padding: 5px 12px;
}
</style>