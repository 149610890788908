<template>
  <Modal @close="closeModal">
    <template v-slot:title>{{ title }}</template>
    <template v-slot:body>
      <form>
        <div class="form-group row my-1">
          <ModalInput 
            v-model="routeData.name"
            :placeholder="'Enter Name'"
            :label="'Name'" 
            :id="'name'" 
            :type="'text'"
          >
            <template v-slot:error>
              <ModalError v-if="errors.name" v-bind:error="errors.name"/>
            </template>
          </ModalInput>
        </div>
        <div class="form-group row my-1">
          <ModalDropdown 
            v-if="$store.getters.getStoppageListForFilter" 
            v-model="routeData.fromStoppageId" 
            :id="'fromStoppageId'"
            :label="'From Stoppage'"
            :dropdown="$store.getters.getStoppageListForFilter" 
            :defaultOption="'--select stoppage--'" 
            :defaultValue="''"
          >
            <template v-slot:error>
              <ModalError v-if="errors.fromStoppageId" v-bind:error="errors.fromStoppageId"/>
            </template>
          </ModalDropdown>
        </div>
        <div class="form-group row my-1">
          <ModalDropdown 
            v-if="$store.getters.getStoppageListForFilter" 
            v-model="routeData.toStoppageId" 
            :id="'toStoppageId'"
            :label="'To Stoppage'"
            :dropdown="$store.getters.getStoppageListForFilter" 
            :defaultOption="'--select stoppage--'"
            :defaultValue="''"
          >
            <template v-slot:error>
              <ModalError v-if="errors.toStoppageId" v-bind:error="errors.toStoppageId"/>
            </template>
          </ModalDropdown>
        </div>
        <div class="form-group row my-1">
          <ModalDropdown 
            v-if="status" 
            v-model="routeData.status" 
            :id="'status'" 
            :label="'Status'"
            :dropdown="status"
          >
          </ModalDropdown>
        </div>

        <div class="form-group row my-1">
          <ModalDropdown 
            v-if="localRouteStatus" 
            v-model="routeData.localRouteStatus" 
            :id="'localRouteStatus'" 
            :label="'Local Route'"
            :dropdown="localRouteStatus"
          >
          </ModalDropdown>
        </div>

        <div class="form-group row my-1">
          <label class="col-sm-3 col-form-label"></label>
          <div class="col-sm-9">
            <input v-if="!routesId" type="submit" value="Submit" class="mt-3 py-2 submit_button font_detail float-end"
              @click.prevent="addRoute"/>
            <input v-else type="submit" value="Submit" class="mt-3 py-2 submit_button font_detail float-end"
              @click.prevent="updateRoute"/>
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
import dropdownVariables from "@/modules/dropdownVariables";
import useInputValidation from "@/modules/useInputValidations";
import {inject, onBeforeMount, ref, watch} from "vue";
import {reactive} from "@vue/reactivity";
import store from "@/store";

export default {
  name: "RoutesAddUpdateModal",
  props: {
    route: String,
    company: String,
  },
  setup(props, {emit}) {
    let {requiredValidation, errors} = useInputValidation();
    let routeData = reactive({
      id: "",
      companyId: "",
      name: "",
      fromStoppageId: "",
      toStoppageId: "",
      status: 1,
      localRouteStatus: 1,
    });
    const routesId = ref(props.route);
    const companyId = ref(props.company);
    const title = ref("Add Route");
    const status = dropdownVariables.status;
    const localRouteStatus = dropdownVariables.status;
    const showLoader = inject('showLoader');
    const hideLoader = inject('hideLoader');

    watch(() => routeData.name, () => {
      requiredValidation("name", routeData.name);
    });
    watch(() => routeData.fromStoppageId, () => {
      requiredValidation("fromStoppageId", routeData.fromStoppageId);
    });
    watch(() => routeData.toStoppageId, () => {
      requiredValidation("toStoppageId", routeData.toStoppageId);
    });

    const validate = () => {
      Object.keys(errors).forEach(function(key) {
        errors[key] = ""
      });
      requiredValidation("name", routeData.name);
      requiredValidation("fromStoppageId", routeData.fromStoppageId);
      requiredValidation("toStoppageId", routeData.toStoppageId);
      return !(Object.values(errors).length <= 0 || !Object.values(errors).every(x => x === null || x === ''));
    };
    const closeModal = () => {
      emit("update:modelValue", false);
    };
    const addRoute = () => {
      if (validate()) {
        showLoader()
        store.dispatch("addRoute", routeData).then(() => {
          hideLoader()
          emit("getAllRoutes");
          closeModal();
        }).catch((error)=>{
          hideLoader()
          if(error.status===401){
            addRoute()
          }
        });
      }
    };
    const updateRoute = () => {
      if (validate()) {
        showLoader()
        store.dispatch("updateRoute", routeData).then(() => {
          hideLoader()
          emit("getAllRoutes");
          closeModal();
        }).catch((error)=>{
          hideLoader()
          if(error.status===401){
            updateRoute()
          }
        });
      }
    };

    onBeforeMount(() => {
      Object.keys(errors).forEach(function (key) {
        delete errors[key];
      });
      // Object.entries(props.stoppageList).forEach(el => {
      //   stoppages[el[0]] = el[1];
      // });
      if (routesId.value !== "") {
        const routeDetails = store.getters.getRoute(routesId.value);
        title.value = "Update Route";
        routeData.id = routeDetails[0]._id;
        routeData.name = routeDetails[0].name;
        routeData.fromStoppageId = routeDetails[0].fromStoppageId;
        routeData.toStoppageId = routeDetails[0].toStoppageId;
        routeData.status = routeDetails[0].status ? 1 : 0;
        routeData.localRouteStatus = routeDetails[0].localRouteStatus ? 1 : 0;
      }
      routeData.companyId = companyId.value;
    });

    return {
      routesId, routeData, errors, status, title,
      addRoute, updateRoute, closeModal, localRouteStatus
    };
  }
};
</script>

<style scoped>

</style>