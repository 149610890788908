<template>
  <div>
    <FilterTab>
      <template v-slot:body>
        <div class="col-sm-6 col-md-2">
          <DropdownFilter
            v-bind:id="'from'"
            v-bind:label="'From Stoppage'"
            v-bind:defaultOption="'--select stoppage--'"
            v-model="pagination.from"
            v-bind:defaultValue="''"
            v-bind:dropdown="$store.getters.getSupervisorStoppageListForFilter"
          />
        </div>
        <div class="col-sm-6 col-md-2">
          <DropdownFilter
            v-bind:id="'to'"
            v-bind:label="'To Stoppage'"
            v-bind:defaultOption="'--select stoppage--'"
            v-model="pagination.to"
            v-bind:defaultValue="''"
            v-bind:dropdown="$store.getters.getSupervisorStoppageListForFilter"
          />
        </div>
        <div class="col-sm-6 col-md-2">
          <DropdownFilter
            v-bind:id="'ship'"
            v-bind:label="'Ship'"
            v-bind:defaultOption="'--select ship--'"
            v-model="pagination.ship"
            v-bind:defaultValue="''"
            v-bind:dropdown="$store.getters.getSupervisorShipListForFilter"
          />
        </div>
        <div class="col-sm-6 col-md-2">
          <DatePickerFilter
            v-bind:id="'datePickerDate'"
            v-bind:label="'Date'"
            v-model="pagination.date"
          />
        </div>
        <div class="col-sm-6 col-md-2">
          <DropdownFilter
            v-bind:id="'status'"
            v-bind:label="'Status'"
            v-bind:defaultOption="'--select status--'"
            v-model="pagination.status"
            v-bind:defaultValue="''"
            v-bind:dropdown="status"
          />
        </div>
      </template>
      <template v-slot:button1>
        <button class="edit_table_data font_detail" @click="addModal">
          <i class="fas fa-plus"></i><span class="mx-1 bold">Add New</span>
        </button>
      </template>
    </FilterTab>
    <div v-if="addUpdate">
      <TripsAddUpdateModal
        :trip="tripId"
        :routeList="$store.getters.getSupervisorRouteListForFilter"
        @close="close"
        @getAllTrips="getAllTrips"
      />
    </div>
    <div>
      <Table v-if="$store.getters.getSupervisorTrips">
        <template v-slot:header>
          <tr>
            <td class="font_detail">SL</td>
            <td class="font_detail">TRIP TIME & DATE</td>
            <td class="font_detail">TRIP CODE</td>
            <td class="font_detail">SHIP</td>
            <td class="font_detail">ROUTE</td>
            <td class="font_detail">DIRECTION</td>
            <td class="font_detail">DECK FARE</td>
            <td class="font_detail">STATUS</td>
            <td class="font_detail">ACTION</td>
          </tr>
        </template>
        <template v-slot:body>
          <tr
            v-for="(trip, index) in $store.getters.getSupervisorTrips"
            :key="trip._id"
          >
            <td class="font_detail">{{ pagination.limit*(pagination.page-1) + index + 1 }}</td>
            <td class="font_detail bold">{{ dateFormat(trip.tripDate) }}</td>
            <td class="font_detail">{{ trip.tripCode }}</td>
            <td class="font_detail bold">{{ trip.ship.name }}</td>
            <td class="font_detail">{{ trip.route.name }}</td>
            <td class="font_detail bold">
              {{ trip.direction }}<br />({{ trip.fromStoppage.name }} -
              {{ trip.toStoppage.name }})
            </td>
            <td class="font_detail">{{ trip.deckFare }}</td>
            <td>
              <span
                class="badge"
                :class="'bg-' + dropdownVariables.tripStatus[trip.status].badge"
                >{{ dropdownVariables.tripStatus[trip.status].value }}</span
              >
            </td>
            <td>
              <button
                class="edit_table_data font_detail edit_table_button"
                @click="updateModal(trip._id)"
              >
                <i class="fas fa-edit"></i><span class="mx-1">Edit</span>
              </button>
            </td>
          </tr>
        </template>
        <template v-slot:pagination>
          <pagination
            v-model="pagination.page"
            :records="$store.getters.getSupervisorTotalTrips"
            :per-page="pagination.limit"
            @paginate="getAllTrips"
            :options="options"
          />
        </template>
      </Table>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { reactive } from "@vue/reactivity";
import { inject, onBeforeMount, ref, watch, computed } from "vue";
import store from "@/store";
import dropdownVariables from "@/modules/dropdownVariables";
import TripsAddUpdateModal from "@/views/Supervisor/Dashboard/Trips/TripsAddUpdateModal.vue";
import permission from "@/modules/permission";
import roles from "@/modules/roles";

const { hasPermission } = permission();
const { Admin } = roles();

export default {
  name: "Trips",
  components: { TripsAddUpdateModal },
  setup() {
    let pagination = reactive({
      page: 1,
      limit: 10,
      companyId: "",
      from: "",
      to: "",
      ship: "",
      date: moment(new Date()).format("yyyy-MM-DD"),
      status: "",
    });
    const addUpdate = ref(false);
    const tripId = ref("");
    const options = {
      texts: {
        count: "",
      },
    };
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    // const routeList = reactive({});
    // let stoppageList = ref({});
    // const shipList = reactive({});

    watch(
      () => pagination.status,
      () => {
        resetPagination();
        getAllTrips();
      }
    );
    watch(
      () => pagination.from,
      () => {
        resetPagination();
        getAllTrips();
      }
    );
    watch(
      () => pagination.to,
      () => {
        resetPagination();
        getAllTrips();
      }
    );
    watch(
      () => pagination.date,
      () => {
        resetPagination();
        getAllTrips();
      }
    );
    watch(
      () => pagination.ship,
      () => {
        resetPagination();
        getAllTrips();
      }
    );

    const resetPagination =()=>{
      pagination.page = 1;
    };

    const status = computed(() => {
      let allTripStatus = {};
      for (let [key, value] of Object.entries(dropdownVariables.tripStatus)) {
        allTripStatus[key] = value.value;
      }
      return allTripStatus;
    });

    const close = () => {
      addUpdate.value = false;
    };
    const addModal = () => {
      tripId.value = "";
      addUpdate.value = true;
    };
    const updateModal = (id) => {
      tripId.value = id;
      addUpdate.value = true;
    };
    const dateFormat = (date) => {
      return moment(String(date))
        .utcOffset(0, false)
        .format("DD-MMM-yyyy hh:mm:ss A");
    };
    const getAllTrips = () => {
      showLoader();
      store
        .dispatch("getSupervisorTripsByPage", pagination)
        .then(() => {
          hideLoader();
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            getAllTrips();
          }
        });
    };
    const getAllRoutes = () => {
      store
        .dispatch("getRouteListForSupervisor", {
          companyId: pagination.companyId,
        })
        .then(() => {})
        .catch((error) => {
          if (error.status === 401) {
            getAllRoutes();
          }
        });
    };
    const getAllStoppages = () => {
      store
        .dispatch("getStoppageListForSupervisor", {
          companyId: pagination.companyId,
        })
        .then(() => {})
        .catch((error) => {
          if (error.status === 401) {
            getAllStoppages();
          }
        });
    };
    const getAllShips = () => {
      store
        .dispatch("getShipListForSupervisor", {
          companyId: pagination.companyId,
        })
        .then(() => {})
        .catch((error) => {
          if (error.status === 401) {
            getAllShips();
          }
        });
    };

    onBeforeMount(() => {
      getAllTrips();
      getAllStoppages();
      getAllShips();
      getAllRoutes();
    });

    return {
      options,
      pagination,
      dropdownVariables,
      addUpdate,
      tripId,
      Admin,
      hasPermission,
      getAllTrips,
      addModal,
      updateModal,
      close,
      dateFormat,
      status,
    };
  },
};
</script>

<style scoped>
.bold {
  font-weight: bold;
}
</style>