<template>
  <div>
    <div v-if="addUpdate">
      <RoutesAddUpdateModal 
        v-model="addUpdate" 
        :route="routeId" 
        :company="pagination.companyId"
        @getAllRoutes="getAllRoutes"/>
    </div>
    <div v-if="boardingPlace">
      <BoardingPlace :routeId="routeId" @close="close" @toggle="toggle"/>
    </div>
    <div v-else-if="droppingPlace">
      <DroppingPlace :routeId="routeId" @close="close" @toggle="toggle"/>
    </div>
    <div v-else-if="localRoute">
      <LocalRoute :localRouteData="localRouteData" @close="close" @toggle="toggle"/>
    </div>
    <div v-else>
      <FilterTab>
        <template v-slot:title><h6>Route</h6></template>
        <template v-slot:body>
          <div class="col-sm-6 col-md-2">
          </div>
        </template>
        <template v-if="hasPermission(Admin.companyDeployWrite)" v-slot:button1>
            <button class="edit_table_data font_detail" @click="toggle">
                <i class="fas fa-plus"></i><span class="mx-1 bold">Add New</span>
            </button>
        </template>
      </FilterTab>
      <Table v-if="$store.getters.getRoutes">
        <template v-slot:header>
          <tr>
            <td class="font_detail"> SL</td>
            <td class="font_detail"> NAME</td>
            <td class="font_detail"> BOARDING PLACES</td>
            <td class="font_detail"> DROPPING PLACES</td>
            <td class="font_detail"> LOCAL ROUTE</td>
            <td class="font_detail"> STATUS</td>
            <td v-if="hasPermission(Admin.companyDeployWrite)" class="font_detail"> ACTION</td>
          </tr>
        </template>
        <template v-slot:body>
          <tr v-for="(routes,index) in $store.getters.getRoutes" :key="routes._id">
            <td class="font_detail">{{ pagination.limit*(pagination.page-1) +index +1 }}</td>
            <td class="font_detail">{{ routes.name }}</td>
            <td class="font_detail">
              <button class="edit_table_data font_detail add_table_button px-2"
                      @click="updateBoardingPlace(routes._id)"><i
                  class="fas fa-map-marker-alt"></i><span class="mx-1">Create</span></button>
            </td>
            <td class="font_detail">
              <button class="edit_table_data font_detail add_table_button px-2"
                      @click="updateDroppingPlace(routes._id)"><i
                  class="fas fa-map-marker-alt"></i><span class="mx-1">Create</span></button>
            </td>
            <td class="font_detail">
              <button class="edit_table_data font_detail add_table_button px-2" :class="routes.localRouteStatus ? 'btnEnable' : 'btnDisable'" :disabled="!routes.localRouteStatus"
                @click="updateLocalRoute(routes._id)">
                <i class="fas fa-route"></i><span class="mx-1">Create</span>
              </button>
            </td>
            <td><span v-if="routes.status" class="badge bg-success">{{ dropdownVariables.status[1] }}</span>
              <span v-else class="badge bg-danger">{{ dropdownVariables.status[0] }}</span>
            </td>
            <td v-if="hasPermission(Admin.companyDeployWrite)">
              <button class="edit_table_data font_detail edit_table_button" @click="updateRoutes(routes._id)"><i
                  class="fas fa-edit"></i><span class="mx-1">Edit</span></button>
            </td>
          </tr>
        </template>
        <template v-slot:pagination>
          <pagination v-model="pagination.page" :records="$store.getters.getTotalRoutes" :per-page="pagination.limit"
                      @paginate="getAllRoutes" :options="options"/>
        </template>
      </Table>
    </div>
  </div>
</template>

<script>
import {reactive} from "@vue/reactivity";
import {inject, onBeforeMount, ref} from "vue";
import store from "@/store";
import dropdownVariables from "@/modules/dropdownVariables";
import RoutesAddUpdateModal from "./RoutesAddUpdateModal";
import {useRoute} from "vue-router";
import BoardingPlace from "./BoardingPlace/BoardingPlace";
import DroppingPlace from "./DroppingPlace/DroppingPlace";
import LocalRoute from "./LocalRoute/LocalRoute";
import permission from "@/modules/permission";
import roles from "@/modules/roles";

const {hasPermission} = permission();
const {Admin} = roles();

export default {
  name: "Route",
  components: {RoutesAddUpdateModal, BoardingPlace, DroppingPlace, LocalRoute},
  setup() {
    let pagination = reactive({
      page: 1,
      limit: 10,
      companyId: ""
    });
    const addUpdate = ref(false);
    const boardingPlace = ref(false);
    const droppingPlace = ref(false);
    const localRoute = ref(false);
    const localRouteData = reactive({
      routeId : "",
      places : "",
    })
    const routeId = ref("");
    const options = {
      texts: {
        count: ""
      }
    };
    const route = useRoute();
    const showLoader = inject('showLoader');
    const hideLoader = inject('hideLoader');

    const getAllRoutes = () => {
      showLoader();
      store.dispatch('getRoutesByPage', pagination).then(() => {
        getStoppageList();
      }).catch((error) => {
        hideLoader()
        if (error.status === 401) {
          getAllRoutes();
        }
      });
    };
    const getStoppageList = () => {
      store.dispatch("getStoppageList", {companyId: pagination.companyId}).then(() => {
        hideLoader()
      }).catch((error) => {
        hideLoader()
        if (error.status === 401) {
          getStoppageList();
        }
      });
    };
    const close = () => {
      addUpdate.value = false;
      boardingPlace.value = false;
      droppingPlace.value = false;
      localRoute.value = false;
    };
    const toggle = () => {
      routeId.value = '';
      if (boardingPlace.value || droppingPlace.value || localRoute.value) {
        boardingPlace.value = false;
        droppingPlace.value = false;
        localRoute.value = false;
      } else {
        addUpdate.value = !addUpdate.value;
      }
    };
    const updateRoutes = (id) => {
      routeId.value = id;
      addUpdate.value = !addUpdate.value;
    };
    const updateBoardingPlace = (id) => {
      routeId.value = id;
      boardingPlace.value = true;
    };
    const updateDroppingPlace = (id) => {
      routeId.value = id;
      droppingPlace.value = true;
    };
    const updateLocalRoute = (id) => {
      localRouteData.routeId = id;
      localRoute.value = true;
    };

    onBeforeMount(() => {
      if (route.query.id) {
        pagination.companyId = route.query.id;
        getAllRoutes();
      }
    });

    return {
      options, pagination, dropdownVariables, addUpdate, boardingPlace, droppingPlace, routeId, Admin,
      hasPermission,
      getAllRoutes, toggle, updateRoutes, close, updateBoardingPlace, updateDroppingPlace, updateLocalRoute, localRoute, localRouteData
    };
  }
};
</script>

<style scoped>
.bold {
  font-weight: bold;
}
.btnEnable {
  cursor: pointer;
}
.btnDisable {
  cursor: not-allowed;
  background: #666666;
  border:2px solid #666666;
  color: #ffffff;
}
</style>