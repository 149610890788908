export default function roles() {
  const Admin = {
    dashboard: 1,
    reports: 2,
    adminRead: 3,
    adminWrite: 4,
    companyDeployRead: 5,
    companyDeployWrite: 6,
    deckmanLogout: 7,
    cancelDeckTicket: 8,
    cancelCabinmanTicket: 9,
    cancelCountermanTicket:10,
    cancelUserTicket: 11,
  };
  const Owner = {
    ownerDashboard: 50,
    ownerReports: 51,
    ownerCompanySetupRead: 52,
    ownerCompanySetupWrite: 53,
    ownerTripRead: 54,
    ownerTripWrite: 55,
    ownerRead: 56,
    ownerWrite: 57,
    agentBalanceRecharge: 58,
    cancelUserTicket: 59,
  };
  const Counterman = {
    countermanTicketBooking: 100,
    countermanFareDiscount: 101,
    countermanReports: 102,
    countermanTrips: 103,
    countermanShips: 104,
    countermanFares: 105,
    countermanTicketCancel: 106,
    countermanCancelOthersTicket: 107,
    counterReservation: 108,
    counterCancelReservation: 109,
    counterCancelOtherReservation: 110,
    countermanUserReports: 111,
  };

  const Agent = {
    agentTicketBooking: 150,
    agentFareDiscount: 151,
    agentReports: 152,
    agentTrips: 153,
    agentShips: 154,
    agentFares: 155,
    // "agentTicketCancel": 156
  };
  const Supervisor = {
    supervisorTicketVerify: 200,
    supervisorReports: 201
  };
  return {
    Admin,
    Owner,
    Counterman,
    Supervisor,
    Agent,
  };
}
//1-49 for Admin
//50-99 for owner
//100-149 for counterman
