<template>
  <div>
    <div class="card mx-3 mx-xxl-4 my-2">
      <div
          :id="'heading' + seatPlan._id"
          :aria-controls="'collapse' + seatPlan._id"
          :data-target="'#collapse' + seatPlan._id"
          aria-expanded="true"
          class="card-header"
          data-toggle="collapse"
      >
        <div class="row align-items-center my-1 px-xxl-0">
          <div class="col-6 col-xxl-1 order-1 bold">
            {{ seatPlan.name }}
          </div>
          <div class="col-12 col-xxl-9 order-3 order-xxl-2 my-1 my-xxl-0">
            <div class="row">
              <div class="col text-xxl-center">
                Up: <span class="bold">{{ seatPlan.upFare }}</span>
              </div>
              <div class="col text-xxl-center">
                Down: <span class="bold">{{ seatPlan.downFare }}</span>
              </div>
              <div class="col text-xxl-center">
                No of Ticket Copy:
                <span class="bold">{{ seatPlan.numberOfTicketCopy }}</span>
              </div>
              <div class="col text-xxl-center">
                Passenger Copy:
                <span class="bold">{{
                    seatPlan.passengerCopy ? "Yes" : "No"
                  }}</span>
              </div>
              <div class="col text-xxl-center">
                Office Copy:
                <span class="bold">{{
                    seatPlan.officeCopy ? "Yes" : "No"
                  }}</span>
              </div>
              <div class="col text-xxl-center">
                Cabin:
                <span class="bold">{{
                    seatPlan.isCabin ? "Yes" : "No"
                  }}</span>
              </div>

              <div class="col text-xxl-center">
                DT:
                <span class="bold">{{
                    seatPlan.dtAvailable ? "Yes" : "No"
                  }}</span>
              </div>

              <div class="col text-xxl-center">
                Status:
                <span :class="seatPlan.status ? 'text-success' : 'text-danger' " class="bold">{{
                    seatPlan.status ? "Active" : "Inactive"
                  }}</span>
              </div>
            </div>
          </div>
          <div class="col-6 col-xxl-2 order-2 order-xxl-3 px-xxl-0">
            <button
                v-if="collapse"
                class="header_show_button font_detail float-end"
                @click="collapse = false"
            >
              <i class="fas fa-chevron-down"></i><span class="mx-1">Show</span>
            </button>
            <button
                v-else
                class="header_show_button font_detail float-end"
                @click="collapse = true"
            >
              <i class="fas fa-chevron-up"></i><span class="mx-1">Hide</span>
            </button>
            <button
                v-if="hasPermission(Admin.companyDeployWrite)"
                class="header_edit_button font_detail float-end"
                @click="updateSeatClass()"
            >
              <i class="fas fa-pencil-alt"></i><span class="mx-1">Edit</span>
            </button>
            <button
                v-if="hasPermission(Admin.companyDeployWrite)"
                class="header_delete_button font_detail float-end"
                @click="deleteSeatClass()"
            >
              <i class="fas fa-trash"></i><span class="mx-1">Delete</span>
            </button>
          </div>
        </div>
      </div>
      <ul
          v-if="seatPlan"
          :id="'collapse' + seatPlan._id"
          :aria-labelledby="'heading' + seatPlan._id"
          :class="!collapse ? 'show' : ''"
          class="collapse list-group list-group-flush"
          data-parent="#accordion"
      >
        <AddSeatRow
            v-if="hasPermission(Admin.companyDeployWrite)"
            v-bind:blocked="blockedSeats"
            v-bind:dtUpSeats="dtUpSeats"
            v-bind:dtDownSeats="dtDownSeats"
            v-bind:company="companyId"
            v-bind:seatPlanId="seatPlan._id"
            v-bind:ship="shipId"
            @getOperationStatus="getOperationStatus"
            @getSeatPlan="getSeatPlan"
        />
        <SeatList
            v-bind:blocked="blockedSeats"
            v-bind:dtUpSeats="dtUpSeats"
            v-bind:dtDownSeats="dtDownSeats"
            v-bind:seatList="seatPlan.seatList"
            v-bind:seatPlan="seatPlan._id"
            v-bind:ship="shipId"
            @getSeatPlan="getSeatPlan"
            @selectedForBlocked="selectedForBlocked"
        />
      </ul>
    </div>
    <div v-if="updateModal">
      <SeatPlanUpdateModal
          v-model="updateModal"
          :floor="floorId"
          v-bind:company="companyId"
          v-bind:seatClass="spId"
          v-bind:ship="shipId"
      />
    </div>
    <div v-if="deleteModal">
      <SeatPlanDeleteModal
          v-model="deleteModal"
          v-bind:company="companyId"
          v-bind:seatClass="spId"
          v-bind:ship="shipId"
      />
    </div>
  </div>
</template>

<script>
import dropdownVariables from "@/modules/dropdownVariables";
import {onBeforeMount, ref, watch} from "vue";
import store from "@/store";
import SeatPlanUpdateModal from "./SeatPlanUpdateModal";
import SeatPlanDeleteModal from "./SeatPlanDeleteModal";
import AddSeatRow from "./AddSeatRow";
import SeatList from "./SeatList";
import permission from "@/modules/permission";
import roles from "@/modules/roles";

const {hasPermission} = permission();
const {Admin} = roles();

export default {
  name: "SeatClassCard",
  props: {
    seatPlanId: String,
    ship: String,
    company: String,
    floor: String,
  },
  components: {
    SeatPlanUpdateModal,
    SeatPlanDeleteModal,
    AddSeatRow,
    SeatList,
  },
  setup(props) {
    let spId = ref(props.seatPlanId);
    let companyId = ref(props.company);
    let shipId = ref(props.ship);
    let floorId = ref(props.floor);
    let updateModal = ref(false);
    let deleteModal = ref(false);
    let collapse = ref(true);
    let seatPlan = ref({});
    const status = dropdownVariables.status;
    let blockedSeats = ref([]);
    let dtUpSeats = ref([]);
    let dtDownSeats = ref([]);
    let operationActive = ref('');

    watch(
        () => updateModal.value,
        () => {
          if (updateModal.value === false) {
            getSeatPlan();
          }
        }
    );
    watch(
        () => seatPlan.value,
        () => {
          getSeatPlan();
        }
    );

    const getOperationStatus = (operationStatus) => {
      getSeatPlan();
      operationActive.value = operationStatus;
    }

    const getSeatPlan = () => {
      seatPlan.value = store.getters.getSeatPlan(spId.value, shipId.value);
      if (seatPlan.value.blockedSeat) {
        blockedSeats.value = seatPlan.value.blockedSeat.split(",");
      } else {
        blockedSeats.value = []
      }
      if (seatPlan.value.dtUpSeat) {
        dtUpSeats.value = seatPlan.value.dtUpSeat.split(",");
      } else {
        dtUpSeats.value = []
      }
      if (seatPlan.value.dtDownSeat) {
        dtDownSeats.value = seatPlan.value.dtDownSeat.split(",");
      } else {
        dtDownSeats.value = []
      }
    };
    const updateSeatClass = () => {
      updateModal.value = true;
    };
    const deleteSeatClass = () => {
      deleteModal.value = true;
    };
    const selectedForBlocked = (seat) => {
      if(operationActive.value === 'quotaUp'){
        if (dtUpSeats.value.includes(seat)) {
          dtUpSeats.value = dtUpSeats.value.filter(
              (alreadySelected) => alreadySelected !== seat
          );
        } else if(!blockedSeats.value.includes(seat)) {
          dtUpSeats.value.push(seat);
        }
      }else if(operationActive.value === 'quotaDown'){
        if (dtDownSeats.value.includes(seat)) {
          dtDownSeats.value = dtDownSeats.value.filter(
              (alreadySelected) => alreadySelected !== seat
          );
        } else if(!blockedSeats.value.includes(seat)) {
          dtDownSeats.value.push(seat);
        }
      }else{
        if (blockedSeats.value.includes(seat)) {
          blockedSeats.value = blockedSeats.value.filter(
              (alreadySelected) => alreadySelected !== seat
          );
        } else if(!dtUpSeats.value.includes(seat) && !dtDownSeats.value.includes(seat)) {
          blockedSeats.value.push(seat);
        }
      }

    };

    onBeforeMount(() => {
      getSeatPlan();
    });

    return {
      status,
      seatPlan,
      spId,
      shipId,
      updateModal,
      companyId,
      deleteModal,
      collapse,
      Admin,
      blockedSeats,
      hasPermission,
      updateSeatClass,
      deleteSeatClass,
      getSeatPlan,
      selectedForBlocked,
      floorId,
      getOperationStatus,
      operationActive,
      dtUpSeats,
      dtDownSeats,
    };
  },
};
</script>

<style scoped>
.header_edit_button {
  margin: 0 2px;
  border: 2px solid #ffc107;
  border-radius: 4px;
  background-color: #ffc107;
  padding: 2px 6px;
  color: #000;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.header_edit_button:hover,
.header_edit_button:focus {
  border: 2px solid #ffc107;
  background-color: #fff;
  color: #ffc107;
  transition: 0.4s;
  outline: 0;
}

.header_delete_button {
  margin: 0 2px;
  border: 2px solid #ff072c;
  border-radius: 4px;
  background-color: #ff072c;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.header_delete_button:hover,
.header_delete_button:focus {
  border: 2px solid #ff072c;
  background-color: #fff;
  color: #ff072c;
  transition: 0.4s;
  outline: 0;
}

.header_show_button {
  margin: 0 2px;
  border: 2px solid #15aabf;
  border-radius: 4px;
  background-color: #15aabf;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.header_show_button:hover,
.header_show_button:focus {
  border: 2px solid #15aabf;
  background-color: #fff;
  color: #15aabf;
  transition: 0.4s;
  outline: 0;
}

.bold {
  font-weight: bold;
}
</style>
