import axios from "axios";
import Apis from "@/urls/apis";
import tokenCookie from "@/modules/tokenCookie";
// import validationMessage from "@/modules/ValidationMessage"
import apiResponse from "@/modules/apiResponse";
import toast from "@/modules/Toast";

const {successToast,errorToast} = toast();
const {hasValidToken, getToken} = tokenCookie();
// const {messageExtractor} = validationMessage();
const {errorResponse} = apiResponse();

const state = {
    filter: {
        agentsList:{}
    },

};
const getters = {
    getAllAgentList: (state) => state.filter.agentsList.docs,
    getTotalAllAgentList: (state) => state.filter.agentsList.totalDocs,
    getAgent: (state) => (id) => state.filter.agentsList.docs.filter(function (agent) {
        return agent._id === id;
    }),

};
const actions = {

    async addAgent(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.post(Apis.AGENT_ADD, payload, {headers: header})
                    .then((response) => {
                        successToast('Agent Added Successfully')
                        resolve(response)
                    })
                    .catch(error => {
                        errorToast('Agent Add Failed')
                        reject(errorResponse(error))
                    });
            });
        }
    },
    async updateAgent(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.put(Apis.AGENT_ADD, payload, {headers: header})
                    .then((response) => {
                        successToast('Agent updated Successfully')
                        resolve(response)
                    })
                    .catch(error => {
                        errorToast('Agent Update Failed')
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async getAgentList({commit}, payload) {
        if (await hasValidToken()) {
            commit("setDeckMans", []);
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };

            return new Promise((resolve, reject) => {
                axios.get(Apis.AGENT_LIST, {
                    params: payload,
                    headers: header
                })
                    .then(({data, status}) => {
                        if (status === 200) {
                            commit("setAgentList", data.agent);
                            resolve({'success': 'success'});
                        }
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },
};
const mutations = {
    setAgentList:(state, agents) => (state.filter.agentsList = agents),

};

export default {
    state,
    getters,
    actions,
    mutations
};