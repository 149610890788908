<template>
  <header id="topHeader">
    <nav class="navbar navbar-expand-lg navbar-light navbar_bg">
      <div class="container-fluid">
        <router-link
          v-if="hasPermission(Admin.dashboard)"
          v-bind:to="{ name: web.Admin.DASHBOARD_URL.name }"
          class="navbar-brand brand_name menu_font_detail home-logout"
        >
          {{ web.Admin.DASHBOARD_URL.title }}
        </router-link>
        <button
          class="navbar-toggler mobile_nav_toggle"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarTogglerDemo03"
          aria-controls="navbarTogglerDemo03"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarTogglerDemo03">
          <ul
            class="
              navbar-nav
              dashboard_nav
              me-auto
              mb-2 mb-lg-0
              menu_font_detail
            "
          >
            <li class="nav-item">
              <router-link
                v-if="hasPermission(Admin.reports)"
                v-bind:to="{ name: web.Admin.REPORT_URL.name }"
                class="nav-link menu_font_detail"
                >{{ web.Admin.REPORT_URL.title }}
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                v-if="hasPermission(Admin.adminRead)"
                v-bind:to="{ name: web.Admin.ADMINS_URL.name }"
                class="nav-link menu_font_detail"
                v-bind:class="
                  currentPath.search(
                    web.Admin.path + '/' + web.Admin.ADMINS_URL.path
                  ) > -1
                    ? 'nav-active'
                    : ''
                "
              >
                {{ web.Admin.ADMINS_URL.title }}
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                  v-if="hasPermission(Admin.companyDeployRead)"
                  v-bind:to="{ name: web.Admin.DIGITAL_TICKETING_URL.name }"
                  class="nav-link menu_font_detail"
                  v-bind:class="
                  currentPath.search(
                    web.Admin.path + '/' + web.Admin.DIGITAL_TICKETING_URL.path
                  ) > -1
                    ? 'nav-active'
                    : ''
                "
              >
                {{ web.Admin.DIGITAL_TICKETING_URL.title }}
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                v-if="hasPermission(Admin.companyDeployRead)"
                v-bind:to="{ name: web.Admin.COMPANY_DEPLOY_URL.name }"
                class="nav-link menu_font_detail"
                v-bind:class="
                  currentPath.search(
                    web.Admin.path + '/' + web.Admin.COMPANY_DEPLOY_URL.path
                  ) > -1
                    ? 'nav-active'
                    : ''
                "
              >
                {{ web.Admin.COMPANY_DEPLOY_URL.title }}
              </router-link>
            </li>
          </ul>
          <ul class="navbar-nav dashboard_nav d-flex menu_font_detail">
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle menu_font_detail"
                href="#"
                id="navbarScrollingDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span
                  v-bind:class="
                    currentPath ===
                    web.Admin.path + '/' + web.Admin.PROFILE_URL.path
                      ? 'nav-active'
                      : ''
                  "
                  >{{ $store.getters.userName }}</span
                >
              </a>
              <ul
                class="dropdown-menu intercity_login_dropdown"
                aria-labelledby="navbarScrollingDropdown"
              >
                <!-- <li>
                  <router-link :to={name:web.Admin.PROFILE_URL.name} class="dropdown-item text-dark menu_font_detail">
                    {{ web.Admin.PROFILE_URL.title }}
                  </router-link>

                </li> -->
                <!-- <li>
                  <hr class="dropdown-divider">
                </li> -->
                <li>
                  <p style="display: none" id="logoutMessage">
                    {{ logoutMessage }}
                  </p>

                  <button
                    onclick="logoutMessageAndroid(document.getElementById('logoutMessage').innerHTML)"
                    @click="logout"
                    class="dropdown-item text-dark menu_font_detail"
                  >
                    {{ web.Default.LOGOUT_URL.title }}
                  </button>
                </li>
                <li></li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import { computed } from "vue";
import store from "@/store";
import web from "@/urls/web";
import permission from "@/modules/permission";
import roles from "@/modules/roles";

const { hasPermission } = permission();
const { Admin } = roles();

export default {
  name: "AdminNav",
  setup() {
    const route = useRoute();
    const router = useRouter();
    const currentPath = computed(() => route.path);
    const logoutMessage = "logout sucessfully";

    const logout = () => {
      store
        .dispatch("logout")
        .then(() => {
          router
            .push(web.Default.LOGIN_URL.path)
            .catch((error) => console.log(error));
        })
        .catch(() => {
        });
    };

    return {
      currentPath,
      web,
      Admin,
      hasPermission,
      logout,
      logoutMessage,
    };
  },
};
</script>

<style scoped>
nav .active,
nav .exact-active,
.nav-active {
  color: #ff6347 !important;
  outline: none;
}

.dashboard_nav li a:hover {
  color: #ff6347 !important;
}
</style>
