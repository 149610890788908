<template>

   <li v-for="(plan,index) in seatLists" :key="spId+'plan'+index" class="list-group-item">
      <div class="row my-2">
         <div class="col-md-11 right_border">
            <div class="row">
               <div v-for="(seat,seatId) in plan.split(',')" :key="'seat'+seatId+index" class="col-auto" >
                 <div v-if="seat === 'AAA'" class="seat-empty my-1 px-1 p-3 rounded text-center   ">
                   <span> </span>
                 </div>
                 <div v-else-if="seat === 'BBB'" class="seat my-1 px-1 p-3 rounded text-center bg-dark ">
                   <span> </span>
                 </div>
                 <div v-else-if="seat === 'RRR'" class=" ms-auto" style="width: 114px">
                   <div  class="row">
                     <div class="seat-empty my-1 px-1 p-3 rounded text-center   ms-auto me-auto">
                       <span> </span>
                     </div>
                     <div class="seat-empty my-1 px-1 p-3 rounded text-center   ms-auto me-auto">
                       <span> </span>
                     </div>
                   </div>
                 </div>
                 <div v-else-if="seat === ''" class="d-none ">
                   <span> </span>
                 </div>
                  <div
                      v-else
                      :class="blockedSeat.includes(seat) ? 'seat my-1 px-1 py-1 rounded text-center allBlockedSeats col-auto' : ownPermittedSeat.includes(seat) ? 'seat my-1 px-1 py-1 rounded text-center ownPermittedSeat col-auto' : allreadyPermittedSeatsByOther.includes(seat) ? 'seat-notallowed bg-secondary my-1 my-1 px-1 py-1 rounded text-center col-auto':'seat my-1 px-1 py-1 rounded text-center col-auto'"
                      @click="selectedForBlocked(seat)">
                     <span>{{ seat }}</span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </li>
</template>

<script>
import { ref, watch, computed} from "vue";
import permission from "@/modules/permission";
const {hasPermission} = permission();


export default {
   name: "SeatList",
   props: {
      blockSeat: Object,
      ownPermitted: Object,
      allreadyPermittedSeats: Object,
      seatList: [Object, Array],
      seatPlan: String,
      ship: String,
      cabinman: String,
   },
   components: {
   },
   emits: ["getSeatPlan", "selectedForBlocked"],
   setup(props, {emit}) {
      const cabinManId = ref(props.cabinman)
      const spId = ref(props.seatPlan)
      const blockedSeat = computed(()=> props.blockSeat)
      const ownPermittedSeat = computed(()=> props.ownPermitted)
      const allreadyPermittedSeatsByOther = computed(()=> props.allreadyPermittedSeats)
      const seatLists = ref(props.seatList)
      const seatClassID = ref(props.seatPlan)
      const shipId = ref(props.ship)
      let editModal = ref(false)
      let listIndex = ref(-1)


      watch(() => [...props.blockSeat], () => {
         blockedSeat.value = props.blockSeat;
      });
      watch(() => [...props.ownPermitted], () => {
         ownPermittedSeat.value = props.ownPermitted;
      });
      watch(() => [...props.allreadyPermittedSeats], () => {
         allreadyPermittedSeatsByOther.value = props.allreadyPermittedSeats;
      });
      watch(() => [...props.seatList], () => {
         seatLists.value = props.seatList;
      });

      const getSeatPlan = () => {
         emit("getSeatPlan");
      }
      const selectedForBlocked = (seat) => {
         emit("selectedForBlocked", seat);
      }

      return {
         spId, seatLists, shipId, cabinManId, blockedSeat, ownPermittedSeat, editModal, listIndex,
         hasPermission,
         selectedForBlocked, getSeatPlan, allreadyPermittedSeatsByOther, seatClassID
      }
   }
};
</script>

<style scoped>

.right_border {
   border-right: 1px solid #ccc;
}

.seat {
   cursor: pointer;
   background-color: #FF6347;
   width: 50px;
   font-size: 14px;
   color: #FFF;
}
.seat-empty {
  cursor: crosshair;
  background-color: #ded2d0;
  width: 50px;
  font-size: 14px;
  color: #FFF;
}

.ownPermittedSeat{
   background-color: #157347 !important;
}

.allBlockedSeats{
   cursor: not-allowed;
   pointer-events: none;
   background-color: #000;
   width: 50px;
   font-size: 14px;
   color: #FFF;
}

.seat-notallowed{
   cursor: not-allowed;
   pointer-events: none;
   background-color: #6C757D;
   width: 50px;
   font-size: 14px;
   color: #FFF;
}
</style>