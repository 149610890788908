import axios from "axios";
import Apis from "@/urls/apis";
import tokenCookie from "@/modules/tokenCookie";
import apiResponse from "@/modules/apiResponse";


const {hasValidToken,getToken} = tokenCookie();
const {successResponse,errorResponse} = apiResponse();

const state = {
    admins: [],
    companyName: "",
    companyTag: "",
};
const getters = {
    getAdmins: (state) => state.admins.docs,
    getTotalRecords: (state) => state.admins.totalDocs,
    getAdmin: (state) => (id) => state.admins.docs.filter(function (user) {
        return user._id === id;
    }),
    getCompanyName: (state) => state.companyName,
    getCompanyTag: (state) => state.companyTag
};
const actions = {
    async getAdminsByPage({commit}, payload) {
        if (await hasValidToken()) {
            commit("setAdmins", []);
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.get(Apis.GET_ALL_USERS, {
                    params: payload,
                    headers: header
                })
                    .then(({data, status}) => {
                        if (status === 200) {
                            commit("setAdmins", data.admin);
                            resolve({'success': 'success'});
                        }
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async addAdmin(_,payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.post(Apis.ADD_USER, payload, {headers: header})
                    .then((success) => {
                        resolve(successResponse(success))
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async updateAdmin(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.put(Apis.UPDATE_USER, payload, {headers: header})
                    .then((success) => {
                        resolve(successResponse(success))
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async deleteAdmin({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.delete(Apis.DELETE_USER, {data: payload, headers: header})
                    .then((success) => {
                        if (success.status === 200) {
                            commit('deleteAdmin', payload);
                            resolve(successResponse(success))
                        }
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    }


};
const mutations = {
    setAdmins: (state, admins) => (state.admins = admins),
    deleteAdmin: (state, payload) => (state.admins.docs = state.admins.docs.filter((user) => user._id !== payload.id)),
    setCompanyName: (state, payload) => (state.companyName = payload),
    setComapanyTag: (state, payload) => (state.companyTag = payload)
};

export default {
    state,
    getters,
    actions,
    mutations
};