import apiResponse from "@/modules/apiResponse";
import tokenCookie from "@/modules/tokenCookie";
import validationMessage from "@/modules/ValidationMessage";
import Apis from "@/urls/apis";
import axios from "axios";

const { hasValidToken, getToken } = tokenCookie();
const { messageExtractor } = validationMessage();
const { successResponse, errorResponse } = apiResponse();

const state = {
  filter: {
    stoppage: {},
    ship: {},
    route: {},
    counter: {},
  },
  companyInfo: {
    ships: {},
    counters: {},
  },
  companies: [],
  owners: [],
  stoppages: [],
  routes: [],
  boardingPlaces: [],
  droppingPlaces: [],
  localRoutes: [],
  places: [],
  counters: [],
  counterMans: {},
  supervisors: {},
  ships: [],
  trips: [],
  deckMans: [],
  cabinMans: [],
  cabinSeatList: [],
  permittedCabinSeatList: [],
  permittedCabinSeatListByOther: [],
  blockedSeatList:[]
};
const getters = {
  getStoppageListForFilter: (state) => state.filter.stoppage,
  getShipListForFilter: (state) => state.filter.ship,

  getRouteListForFilter: (state) => state.filter.route,
  getCompanies: (state) => state.companies.docs,
  getTotalCompanies: (state) => state.companies.totalDocs,
  getCompany: (state) => (id) =>
    state.companies.docs.filter(function (company) {
      return company._id === id;
    }),

  getOwners: (state) => state.owners.docs,
  getTotalOwners: (state) => state.owners.totalDocs,
  getOwner: (state) => (id) =>
    state.owners.docs.filter(function (owner) {
      return owner._id === id;
    }),

  getStoppages: (state) => state.stoppages.docs,
  getTotalStoppages: (state) => state.stoppages.totalDocs,
  getStoppage: (state) => (id) =>
    state.stoppages.docs.filter(function (stoppage) {
      return stoppage._id === id;
    }),

  getRoutes: (state) => state.routes.docs,
  getTotalRoutes: (state) => state.routes.totalDocs,
  getRoute: (state) => (id) =>
    state.routes.docs.filter(function (route) {
      return route._id === id;
    }),

  getBoardingPlaces: (state) => state.boardingPlaces,
  // getTotalBoardingPlace: (state) => state.boardingPlaces.totalDocs,
  // getBoardingPlace: (state) => (id) => state.boardingPlaces.filter(function (boardingPlace) {
  //     return boardingPlace._id === id;
  // }),

  getDroppingPlaces: (state) => state.droppingPlaces,
  // getTotalDroppingPlace: (state) => state.droppingPlaces.totalDocs,
  // getDroppingPlace: (state) => (id) => state.droppingPlaces.docs.filter(function (droppingPlace) {
  //     return droppingPlace._id === id;
  // }),

  getCompanyInfo: (state) => state.companyInfo,

    //     return droppingPlace._id === id;
    // }),
  getLocalRoutes: (state) => state.localRoutes,
  getPlaces: (state) => state.places,
    
  getCounters: (state) => state.counters.docs,
  getTotalCounter: (state) => state.counters.totalDocs,
  getCounter: (state) => (id) =>
    state.counters.docs.filter(function (counter) {
      return counter._id === id;
    }),
  getCounterListForFilter: (state) => state.filter.counter,

  getCounterMans: (state) => state.counterMans.docs,
  getTotalCounterMan: (state) => state.counterMans.totalDocs,
  getCounterMan: (state) => (id) =>
    state.counterMans.docs.filter(function (counterMan) {
      return counterMan._id === id;
    }),

  getSupervisors: (state) => state.supervisors.docs,
  getTotalSupervisor: (state) => state.supervisors.totalDocs,
  getSupervisor: (state) => (id) =>
    state.supervisors.docs.filter(function (supervisor) {
      return supervisor._id === id;
    }),

  getDeckMans: (state) => state.deckMans.docs,
  getTotalDeckMan: (state) => state.deckMans.totalDocs,
  getDeckMan: (state) => (id) =>
    state.deckMans.docs.filter(function (deckMan) {
      return deckMan._id === id;
    }),


  getShips: (state) => state.ships.docs,
  getTotalShip: (state) => state.ships.totalDocs,
  getShip: (state) => (id) =>
    state.ships.docs.filter(function (ship) {
      return ship._id === id;
    }),

  getSeatPlan: (state) => (id, shipId) => {
    return state.ships.docs
      .find((ship) => ship._id === shipId)
      .seatPlan.find((plan) => plan._id === id);
  },

  getFloorPlan: (state) => (id, shipId) => {
    return state.ships.docs
      .find((ship) => ship._id === shipId)
      .floors.find((plan) => plan._id === id);
  },

  getTrips: (state) => state.trips.docs,
  getTotalTrips: (state) => state.trips.totalDocs,
  getTrip: (state) => (id) =>
    state.trips.docs.filter(function (trip) {
      return trip._id === id;
    }),

  getCabinMans: (state) => state.cabinMans.docs,
  getTotalCabinMan: (state) => state.cabinMans.totalDocs,
  getCabinMan: (state) => (id) =>
    state.cabinMans.docs.filter(function (cabinMan) {
      return cabinMan._id === id;
    }),

  getCabinSeatList: (state) => state.cabinSeatList,
  getCabinSeatListBySeatClassId: (state) => (id) =>
    state.cabinSeatList.filter(function (cabinSeatClass) {
      return cabinSeatClass.seatClassId === id;
    }),

  getPermittedCabinSeatList: (state) => state.permittedCabinSeatList,
  getPermittedCabinSeatListBySeatClassId: (state) => (id) =>
    state.permittedCabinSeatList.filter(function (cabinSeatClass) {
      return cabinSeatClass.seatClassId === id;
    }),
  getPermittedCabinSeatListByOther: (state) => state.permittedCabinSeatListByOther,
  getPermittedCabinSeatListByOtherBySeatClassId: (state) => (id) =>
    state.permittedCabinSeatListByOther.filter(function (cabinSeatClass) {
      return cabinSeatClass.seatClassId === id;
    }),
  getBlockedSeatList: (state) => state.blockedSeatList,
  getBlockedSeatListBySeatClassId: (state) => (id) =>
    state.blockedSeatList.filter(function (cabinSeatClass) {
      return cabinSeatClass.seatClassId === id;
    }),
};
const actions = {
  async deleteBoardingPlaceForRoute(_, { bPlace }) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .delete(Apis.DELETE_BOARDING_PLACE_FOR_ROUTE, {
            data: bPlace,
            headers: header,
          })
          .then((response) => {
            resolve(successResponse(response));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
  async deleteDroppingPointForRoute(_, { dPlace }) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .delete(Apis.DELETE_DROPPING_POINT_FOR_ROUTE, {
            data: dPlace,
            headers: header,
          })
          .then((response) => {
            resolve(successResponse(response));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
  async getCompaniesByPage({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setCompanies", []);
      const header = {
        Authorization: "Bearer " + getToken(),
      };
      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_COMPANIES, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              commit("setCompanies", data.company);
              resolve({ success: "success" });
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async addCompany(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.ADD_COMPANY, payload, { headers: header })
          .then((success) => {
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async updateCompany(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .put(Apis.UPDATE_COMPANY, payload, { headers: header })
          .then((success) => {
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getOwnersByPage({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setOwners", []);
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_OWNERS, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              commit("setOwners", data.owner);
              resolve({ success: "success" });
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async addOwner(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.ADD_OWNER, payload, { headers: header })
          .then((success) => {
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async updateOwner(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .put(Apis.UPDATE_OWNER, payload, { headers: header })
          .then((success) => {
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getStoppagesByPage({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setStoppages", []);
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_STOPPAGES, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              commit("setStoppages", data.stoppage);
              resolve({ success: "success" });
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getStoppageList({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setStoppageListInFilter", {});
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_STOPPAGES, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              let stoppageList = {};
              data.stoppage.forEach((stoppage) => {
                stoppageList[stoppage._id] = stoppage.name;
              });
              commit("setStoppageListInFilter", stoppageList);
              resolve(data.stoppage);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async addStoppage(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.ADD_STOPPAGE, payload, { headers: header })
          .then((success) => {
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async updateStoppage(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .put(Apis.UPDATE_STOPPAGE, payload, { headers: header })
          .then((success) => {
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getRoutesByPage({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setRoutes", []);
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_ROUTES, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              commit("setRoutes", data.route);
              resolve({ success: "success" });
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async addRoute(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.ADD_ROUTE, payload, { headers: header })
          .then((success) => {
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async updateRoute(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .put(Apis.UPDATE_ROUTE, payload, { headers: header })
          .then((success) => {
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getRouteList({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setRouteListInFilter", {});
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_ROUTES, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              let routeList = {};
              data.route.forEach((route) => {
                if (route.status) {
                  routeList[route._id] = route.name;
                }
              });
              commit("setRouteListInFilter", routeList);
              resolve(data.route);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getBoardingPlacesByPage({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setBoardingPlaces", []);
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_BOARDING_PLACES, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              commit("setBoardingPlaces", data.boardingPlace);
              resolve({ success: "success" });
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async addBoardingPlace(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.ADD_BOARDING_PLACE, payload, { headers: header })
          .then((success) => {
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async updateBoardingPlace(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .put(Apis.UPDATE_BOARDING_PLACE, payload, { headers: header })
          .then((success) => {
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getDroppingPlacesByPage({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setDroppingPlaces", []);
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_DROPPING_PLACES, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              commit("setDroppingPlaces", data.droppingPoint);
              resolve({ success: "success" });
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async addDroppingPlace(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.ADD_DROPPING_PLACE, payload, { headers: header })
          .then((success) => {
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async updateDroppingPlace(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .put(Apis.UPDATE_DROPPING_PLACE, payload, { headers: header })
          .then((success) => {
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
  

  async getLocalRoutesByPage({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setLocalRoutes", []);
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_LOCAL_ROUTES, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              commit("setLocalRoutes", data.localRoutes);
              commit("setPlaces", data.places)
              resolve({ success: "success" });
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async addLocalRoute(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.ADD_LOCAL_ROUTE, payload, { headers: header })
          .then((success) => {
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
  
  async updateLocalRoute(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .put(Apis.UPDATE_LOCAL_ROUTE, payload, { headers: header })
          .then((success) => {
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async deleteLocalRouteforRoute(_, { localRoute }) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .delete(Apis.DELETE_LOCAL_ROUTE, { params: localRoute, headers: header })
          .then((response) => {
            console.log(response);
            // if (status === 404) {
            //   commit("setLocalRoutes", data.localRoutes);
            //   resolve({ success: "success" });
            // }
            resolve(successResponse(response));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getCountersByPage({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setCounters", []);
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_COUNTERS, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              commit("setCounters", data.counter);
              resolve({ success: "success" });
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async addCounter(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.ADD_COUNTER, payload, { headers: header })
          .then((success) => {
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async updateCounter(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .put(Apis.UPDATE_COUNTER, payload, { headers: header })
          .then((success) => {
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getCompanyInfo({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setCompanyShipList", {})
      commit("setCompanyCounterList", {})
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_COMPANY_INFO, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              let counterList = {};
              let shipList = {};
              data.company.counters.forEach((counter) => {
                counterList[counter._id] = counter.name;
              });
              data.company.ships.forEach((ship) => {
                shipList[ship._id] = ship.name;
              });
              commit("setCompanyShipList", shipList)
              commit("setCompanyCounterList", counterList)
              resolve(data);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getCounterList({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setCounterListInFilter", {});
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_COUNTERS, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              let counterList = {};
              data.counter.forEach((counter) => {
                counterList[counter._id] = counter.name;
              });
              commit("setCounterListInFilter", counterList);
              resolve(data.counter);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getCounterMansByPage({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setCounterMans", []);
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_COUNTERMANS, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              commit("setCounterMans", data.counterMan);
              resolve({ success: "success" });
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async addCounterMan(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.ADD_COUNTERMAN, payload, { headers: header })
          .then((success) => {
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async updateCounterMan(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .put(Apis.UPDATE_COUNTERMAN, payload, { headers: header })
          .then((success) => {
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getSupervisorsByPage({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setSupervisors", []);
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_SUPERVISORS, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              commit("setSupervisors", data.supervisor);
              resolve({ success: "success" });
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async addSupervisor(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.ADD_SUPERVISOR, payload, { headers: header })
          .then((success) => {
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async updateSupervisor(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .put(Apis.UPDATE_SUPERVISOR, payload, { headers: header })
          .then((success) => {
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getShipsByPage({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setShips", []);
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_SHIPS, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              commit("setShips", data.ship);
              resolve({ success: "success" });
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getShipsByRoute(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_SHIPS_BY_ROUTE, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              resolve(data.ship);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getShipsByDeck({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_SHIPS_BY_DECK, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              let shipList = {};
              data.ship.forEach((ship) => {
                shipList[ship._id] = ship.name;
              });
              commit("setShipListInFilter", shipList);
              resolve(data.ship);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getShipsInfo(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_SHIP_INFO, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              resolve(data.ship);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async addShip(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.ADD_SHIP, payload, { headers: header })
          .then((success) => {
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async updateShip(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .put(Apis.UPDATE_SHIP, payload, { headers: header })
          .then((success) => {
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async copyShip(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.COPY_SHIP, payload, { headers: header })
          .then((success) => {
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async addFloor({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.ADD_FLOOR, payload, { headers: header })
          .then(({ data, status }) => {
            if (status === 200) {
              messageExtractor(data, status);
              commit("updateShip", data.ship);
              resolve(data.message);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async deleteFloor({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .delete(Apis.DELETE_FLOOR, { data: payload, headers: header })
          .then(({ data, status }) => {
            if (status === 200) {
              messageExtractor(data, status);
              // commit("removeSeatClass", payload);
              commit("updateShip", data.ship);
              resolve(data.message);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async updateFloor({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .put(Apis.UPDATE_FLOOR, payload, { headers: header })
          .then(({ data, status }) => {
            if (status === 200) {
              messageExtractor(data, status);
              commit("updateShip", data.ship);
              resolve(data.message);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async addSeatClass({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.ADD_SEAT_CLASS, payload, { headers: header })
          .then(({ data, status }) => {
            if (status === 200) {
              messageExtractor(data, status);
              commit("updateShip", data.ship);
              resolve(data.message);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async deleteSeatClass({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .delete(Apis.DELETE_SEAT_CLASS, { data: payload, headers: header })
          .then(({ data, status }) => {
            if (status === 200) {
              messageExtractor(data, status);
              // commit("removeSeatClass", payload);
              commit("updateShip", data.ship);
              resolve(data.message);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async updateSeatClass({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .put(Apis.UPDATE_SEAT_CLASS, payload, { headers: header })
          .then(({ data, status }) => {
            if (status === 200) {
              messageExtractor(data, status);
              commit("updateShip", data.ship);
              resolve(data.message);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async addSeatRow({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.ADD_SEAT_ROW, payload, { headers: header })
          .then(({ data, status }) => {
            if (status === 200) {
              messageExtractor(data, status);
              commit("updateShip", data.ship);
              resolve(data.message);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async addCustomSeatRow({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.ADD_CUSTOM_SEAT_ROW, payload, { headers: header })
          .then(({ data, status }) => {
            if (status === 200) {
              messageExtractor(data, status);
              commit("updateShip", data.ship);
              resolve(data.message);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async editCustomSeatRow({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.EDIT_CUSTOM_SEAT_ROW, payload, { headers: header })
          .then(({ data, status }) => {
            if (status === 200) {
              messageExtractor(data, status);
              commit("updateShip", data.ship);
              resolve(data.message);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async deleteSeatRow({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .delete(Apis.DELETE_SEAT_ROW, { data: payload, headers: header })
          .then(({ data, status }) => {
            if (status === 200) {
              messageExtractor(data, status);
              commit("updateShip", data.ship);
              resolve(data.message);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getTripsByPage({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setTrips", []);
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_TRIPS, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              commit("setTrips", data.trip);
              resolve({ success: "success" });
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async blockSeats({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.SEAT_BLOCK, payload, { headers: header })
          .then(({ data, status }) => {
            if (status === 200) {
              messageExtractor(data, status);
              commit("updateShip", data.ship);
              resolve(data.message);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async addDtUpSeat({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
            .post(Apis.ADD_DT_UP_SEAT, payload, { headers: header })
            .then(({ data, status }) => {
              if (status === 200) {
                messageExtractor(data, status);
                commit("updateShip", data.ship);
                resolve(data.message);
              }
            })
            .catch((error) => {
              reject(errorResponse(error));
            });
      });
    }
  },

  async addDtDownSeat({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
            .post(Apis.ADD_DT_DOWN_SEAT, payload, { headers: header })
            .then(({ data, status }) => {
              if (status === 200) {
                messageExtractor(data, status);
                commit("updateShip", data.ship);
                resolve(data.message);
              }
            })
            .catch((error) => {
              reject(errorResponse(error));
            });
      });
    }
  },

  async addTrip(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.ADD_TRIP, payload, { headers: header })
          .then((success) => {
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async updateTrip(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .put(Apis.UPDATE_TRIP, payload, { headers: header })
          .then((success) => {
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getDackmanByPage({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setDeckMans", []);
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_DECKMANS, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              commit("setDeckMans", data.deckMan);
              resolve({ success: "success" });
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async addDeckMan(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.ADD_DECKMAN, payload, { headers: header })
          .then((success) => {
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async updateDeckMan(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .put(Apis.UPDATE_DECKMAN, payload, { headers: header })
          .then((success) => {
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async logoutDeckMan(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.LOGOUT_DECKMAN, payload, { headers: header })
          .then((success) => {
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  //cabinman start
  async getCabinManByPage({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setCabinMans", []);
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_CABINMANS, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              commit("setCabinMans", data.cabinMan);
              resolve({ success: "success" });
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async addCabinMan(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.ADD_CABINMAN, payload, { headers: header })
          .then((success) => {
            console.log(success);
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async updateCabinMan(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .put(Apis.UPDATE_CABINMAN, payload, { headers: header })
          .then((success) => {
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async logoutCabinMan(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.LOGOUT_CABINMAN, payload, { headers: header })
          .then((success) => {
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getCabinSeatList({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.GET_CABIN_SEATLIST, payload, { headers: header })
          .then((res) => {
            commit('setCabinSeatList', res.data)
            resolve(res.data);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getPermittedCabinSeatList({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.GET_PERMITTED_CABIN_SEATLIST, payload, { headers: header })
          .then((res) => {
            if (res.data !== null) {
              commit('setPermittedCabinSeatList', res.data.permittedSeats)
              commit('setPermittedCabinSeatListByOther', res.data.permittedSeatListByOthers)
              commit('setBlockedSeatList', res.data.blockedSeatList)
              resolve(res.data.permittedSeats);
            }
            resolve(res.data)
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async addSeatPermission(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.SEAT_PERMISSION_CABINMAN, payload, { headers: header })
          .then((success) => {
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  //cabinman end

  async getShipList({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_SHIPS, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              let shipList = {};
              data.ship.forEach((ship) => {
                shipList[ship._id] = ship.name;
              });
              commit("setShipListInFilter", shipList);
              resolve(data.ship);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
};
const mutations = {
  setStoppageListInFilter: (state, stoppageList) =>
    (state.filter.stoppage = stoppageList),
  setShipListInFilter: (state, shipList) => (state.filter.ship = shipList),
  setRouteListInFilter: (state, routeList) => (state.filter.route = routeList),
  setCounterListInFilter: (state, counterList) =>
    (state.filter.counter = counterList),
  setCompanyShipList: (state, ships)=> (state.companyInfo.ships = ships),
  setCompanyCounterList: (state, counters)=> (state.companyInfo.counters = counters),
  setCompanies: (state, companies) => (state.companies = companies),
  setOwners: (state, owners) => (state.owners = owners),
  setStoppages: (state, stoppages) => (state.stoppages = stoppages),
  setRoutes: (state, routes) => (state.routes = routes),
  setBoardingPlaces: (state, boardingPlaces) => (state.boardingPlaces = boardingPlaces),
  setDroppingPlaces: (state, droppingPlaces) => (state.droppingPlaces = droppingPlaces),
  setLocalRoutes: (state, localRoutes) => (state.localRoutes = localRoutes),
  setPlaces: (state, places) => (state.places = places),
  setCounters: (state, counters) => (state.counters = counters),
  setCounterMans: (state, counterMans) => (state.counterMans = counterMans),
  setSupervisors: (state, supervisors) => (state.supervisors = supervisors),
  setShips: (state, ships) => (state.ships = ships),
  updateShip: (state, updatedShip) => {
    const index = state.ships.docs.findIndex(
      (ship) => ship._id === updatedShip._id
    );
    if (index !== -1) {
      state.ships.docs.splice(index, 1, updatedShip);
    }
  },
  setTrips: (state, trips) => (state.trips = trips),
  setDeckMans: (state, deckMans) => (state.deckMans = deckMans),
  setCabinMans: (state, cabinMans) => (state.cabinMans = cabinMans),
  setCabinSeatList: (state, seatList) => (state.cabinSeatList = seatList),
  setPermittedCabinSeatList: (state, seatList) => (state.permittedCabinSeatList = seatList),
  setPermittedCabinSeatListByOther: (state, seatList) => (state.permittedCabinSeatListByOther = seatList),
  setBlockedSeatList: (state, blockedSeatList) => (state.blockedSeatList = blockedSeatList),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
