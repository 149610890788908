<template>
  <div>
    <FilterTab>
      <template v-slot:title><h6>Floor Plan ({{shipName}})</h6></template>
      <template v-slot:body>
        <div class="col-sm-6 col-md-2"></div>
      </template>
      <template v-slot:button1>
        <button class="edit_table_data font_detail" @click="goBack">
          <i class="fas fa-arrow-left"></i
          ><span class="mx-1 bold">Go Back</span>
        </button>
      </template>
    </FilterTab>
    <div
        v-if="hasPermission(Admin.companyDeployWrite)"
        class="card container new-class mt-2"
    >
      <div class="card-body">
        <h6 class="card-title bold">Add New floor</h6>
        <span v-if="error" class="text-danger err_msg">* {{ error }}</span>
        <div class="row">
          <div class="col-4 col-xl-2">
            <Input
                v-model="floorPayload.name"
                v-bind:placeholder="'Enter Floor Name'"
                v-bind:id="'floorname'"
                v-bind:type="'text'"
            />
            <Error v-if="errors.name" v-bind:error="errors.name" />
          </div>
          <div class="col-4 col-xl-2">
            <input
                type="submit"
                value="Submit"
                class="submit_button mt-1 w-50"
                @click.prevent="addFloor"
            />
          </div>
        </div>
      </div>
    </div>

    <div id="accordion" v-if="$store.getters.getShip(shipId)">
      <div
          v-for="floor in $store.getters.getShip(shipId)[0].floors"
          :key="floor._id"
      >
        <FloorPlanCard
            v-if="floor"
            v-bind:company="companyId"
            v-bind:floorId="floor._id"
            v-bind:ship="shipId"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from "@vue/reactivity";
import Input from "@/components/layouts/Inputs/Input";
import Error from "@/components/layouts/Inputs/Error";
import useInputValidation from "@/modules/useInputValidations";
import dropdownVariables from "@/modules/dropdownVariables";
import { inject, onBeforeMount, ref } from "vue";
import FloorPlanCard from "./FloorPlanCard";
import store from "@/store";
import FilterTab from "@/components/layouts/Filter/FilterTab";
import permission from "@/modules/permission";
import roles from "@/modules/roles";

const { hasPermission } = permission();
const { Admin } = roles();

export default {
  name: "FloorPlan",
  props: {
    ship: String,
    shipName: String,
    company: String,
  },
  components: { Input, Error, FilterTab,FloorPlanCard, },
  setup(props, { emit }) {
    let floorPayload = reactive({
      id: "",
      companyId: "",
      shipId: "",
      name: "",
      upFare: "",
      downFare: "",
      numberOfTicketCopy: "",
      blockedSeat: "",
      status: 1,
      passengerCopy: false,
    });
    let { requiredValidation,  errors } =
        useInputValidation();
    let shipId = ref(props.ship);
    let companyId = ref(props.company);
    let error = ref("");
    const status = dropdownVariables.status;
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");

    const clear = () => {
       floorPayload.name = ''
    };
    const goBack = () => {
      emit("toggle");
    };
    const validate = () => {
      Object.keys(errors).forEach(function (key) {
        errors[key] = "";
      });
      requiredValidation("name", floorPayload.name);

      return !(
          Object.values(errors).length <= 0 ||
          !Object.values(errors).every((x) => x === null || x === "")
      );
    };
    const addFloor = () => {
      if (validate()) {
        showLoader();
        error.value = "";
        store
            .dispatch("addFloor", floorPayload)
            .then(() => {
              hideLoader();
              clear();
            })
            .catch((error) => {
              hideLoader();
              if (error.status === 401) {
                addFloor();
              } else {
                error.value = error;
              }
            });
      }
    };

    onBeforeMount(() => {
      floorPayload.shipId = shipId.value;
      floorPayload.companyId = companyId.value;
    });

    return {
      floorPayload,
      errors,
      status,
      shipId,
      companyId,
      error,
      Admin,
      hasPermission,
      addFloor,
      goBack,
    };
  },
};
</script>

<style scoped>
.new-class {
  background: #f3f3f3;
}

.bold {
  font-weight: bold;
}
</style>