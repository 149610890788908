import apiResponse from "@/modules/apiResponse";
import toast from "@/modules/Toast";
import tokenCookie from "@/modules/tokenCookie";
import Apis from "@/urls/apis";
import axios from "axios";

const { infoToast, successToast } = toast();
const { hasValidToken, getToken } = tokenCookie();
const { errorResponse } = apiResponse();

const state = {
  filter: {
    shipOwner: {},
    counterOwner: {},
  },
  companyInfoOwner: {
    ships: {},
    counters: {},
  },
  shipWiseReportListOwner: [],
  agentWiseReportListOwner: [],
  shipWiseDeckDetailsOwner: [],
  shipWiseGoodDetailsOwner: [],
  shipWiseSeatDetailsOwner: [],
  counterWiseSeatDetailsOwner: [],
  counterWiseReportDataOwner: [],
  agentWiseSeatDetailsOwner: [],
  counterWiseReportListOwner: [],
  deckmanWiseReportList: [],
  deckmanWiseReportDetails: [],
  reservationWiseReportList: [],
  reservationWiseReportDetails: [],
  printTicketCountListOwner: [],
  printTripWiseCountListOwner: [],
  printSupervisorTripWiseCountListOwner: [],
  printDailySummaryWiseCountListOwner: [],
  cabinmanWiseReportListForOwner: [],
  cabinmanWiseReportDetailsForOwner: [],
  companyTag: ""
};
const getters = {
  getOwnerCompanyTag: (state) => state.companyTag,
  getShipListForFilterOwner: (state) => state.filter.shipOwner,
  getCounterListForFilterOwner: (state) => state.filter.counterOwner,
  getCompanyInfoOwner: (state) => state.companyInfoOwner,
  getShipWiseReportOwner: (state) => state.shipWiseReportListOwner,
  getAgentWiseReportOwner: (state) => state.agentWiseReportListOwner,
  getcounterWiseSeatDetailsReportOwner: (state) => state.counterWiseSeatDetailsOwner,
  getcounterWiseReportDataOwner: (state) => state.counterWiseReportDataOwner,
  getAgentWiseSeatDetailsOwner: (state) => state.agentWiseSeatDetailsOwner,
  getCounterWiseReportListOwner: (state) => state.counterWiseReportListOwner,
  getShipWiseDeckDetailsOwner: (state) => state.shipWiseDeckDetailsOwner,
  // getTotalShipWiseDeckDetailsOwner: (state) =>
  //   state.shipWiseDeckDetailsOwner.totalDocs,
  // getTotalAmountShipWiseDeckDetailsOwner: (state) =>
  //   state.shipWiseDeckDetailsOwner.totalAmount,
  getShipWiseGoodDetailsOwner: (state) => state.shipWiseGoodDetailsOwner,

  // getTotalShipWiseGoodDetailsOwner: (state) =>
  //   state.shipWiseGoodDetailsOwner.totalDocs,
  // getTotalAmountShipWiseGoodDetailsOwner: (state) =>
  //   state.shipWiseGoodDetailsOwner.totalAmount,

  getShipWiseSeatDetailsOwner: (state) => state.shipWiseSeatDetailsOwner,
  // getTotalShipWiseSeatDetailsOwner: (state) =>
  //   state.shipWiseSeatDetailsOwner.totalDocs,
  // getTotalAmountShipWiseSeatDetailsOwner: (state) =>
  //   state.shipWiseSeatDetailsOwner.totalAmount,

  getTotalTicketShipWiseSeatDetailsOwner: (state) =>
    state.shipWiseSeatDetailsOwner.totalTicket,

  getDeckmanWiseReportOwner: (state) => state.deckmanWiseReportList,
  getDeckmanWiseReportDetailsOwner: (state) =>
    state.deckmanWiseReportDetails,
  // getTotalDeckmanWiseReportDetailsOwner: (state) =>
  //   state.deckmanWiseReportDetails.totalDocs,
  // getTotalAmountDeckmanWiseReportDetailsOwner: (state) =>
  //   state.deckmanWiseReportDetails.totalAmount,
  getReservationWiseReportOwner: (state) => state.reservationWiseReportList,
  getReservationWiseReportDetailsOwner: (state) => state.reservationWiseReportDetails,
  getPrintTicketCountListOwner: (state) => state.printTicketCountListOwner,
  getPrintTripWiseCountListOwner: (state) => state.printTripWiseCountListOwner,
  getPrintSupervisorTripWiseCountListOwner: (state) => state.printSupervisorTripWiseCountListOwner,
  getPrintDailySummaryWiseCountListOwner: (state) =>
    state.printDailySummaryWiseCountListOwner,
    getCabinmanWiseReportForOwner: (state) => state.cabinmanWiseReportListForOwner,
  getCabinmanWiseReportDetailsForOwner: (state) => state.cabinmanWiseReportDetailsForOwner,
};
const actions = {
  async userTicketCancelOwner(_, payload) {
    if (await hasValidToken()) {
      const header = {
        'Authorization': 'Bearer ' + getToken()
      };
      return new Promise((resolve, reject) => {
        axios.post(Apis.CANCEL_USER_TICKET_OWNER, payload, { headers: header })
            .then((response) => {
              successToast('Ticket Cancelled Successfully')
              resolve(response)
            })
            .catch(error => {
              reject(errorResponse(error))
            });
      });
    }
  },
  async getShipListOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_SHIPS_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              let shipList = {};
              data.ship.forEach((ship) => {
                shipList[ship._id] = ship.name;
              });
              commit("setShipListInFilterOwner", shipList);
              resolve(data.ship);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getCounterListOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_COUNTER_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              let counterList = {};
              data.counter.forEach((counter) => {
                counterList[counter._id] = counter.name;
              });
              commit("setCounterListInFilterOwner", counterList);
              resolve(counterList);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getCompanyInfoOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };
      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_COMPANY_INFO, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              let counterList = {};
              let shipList = {};
              data.company.counters.forEach((counter) => {
                counterList[counter._id] = counter.name;
              });
              data.company.ships.forEach((ship) => {
                shipList[ship._id] = ship.name;
              });
              commit("setCompanyShipListOwner", shipList)
              commit("setCompanyCounterListOwner", counterList)
              resolve(data);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getCounterWiseSeatDetailsOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.COUNTER_WISE_DETAILS_REPORT_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (data.ticket.length == 0 && (data.report.totalReservationCount == 0 && data.report.netPay == 0)) {
              infoToast("No Data Available");
            }
            if (status === 200) {
              commit("setCounterWiseSeatDetailsOwner", data.ticket);
              commit("setCounterWiseReportDataOwner", data.report);
            }
            resolve(data.ticket);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getSeatDetailsOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.SHIP_WISE_SEAT_REPORT_DETAILS_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (data.ticket.length === 0) {
              infoToast("No Data Available");
            }
            if (status === 200) {
              commit("setShipWiseSeatDetailsOwner", data.ticket);
            }
            resolve(data.ticket);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getfilterReservationWiseReportOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };
      return new Promise((resolve, reject) => {
        axios
          .get(Apis.RESERVATION_WISE_REPORT_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (data.report.length === 0) {
              infoToast("No Data Available");
            }
            if (status === 200) {
              commit("setfilterReservationWiseReport", data.report);
            }
            resolve(data.report);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getReservationWiseReportDetailsOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.RESERVATION_WISE_REPORT_DETAILS_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (data.report.length === 0) {
              infoToast("No Data Available");
            }
            if (status === 200) {
              commit("setReservationWiseReportDetails", data.report);
            }
            resolve(data.report);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getDeckDetailsOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.SHIP_WISE_DECK_REPORT_DETAILS_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (data.ticket.length === 0) {
              infoToast("No Data Available");
            }
            if (status === 200) {
              commit("setShipWiseDeckDetailsOwner", data.ticket);
            }
            resolve(data.ticket);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getGoodDetailsOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.SHIP_WISE_GOOD_REPORT_DETAILS_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (data.ticket.length === 0) {
              infoToast("No Data Available");
            }
            if (status === 200) {
              commit("setShipWiseGoodDetailsOwner", data.ticket);
            }
            resolve(data.ticket);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getAgentWiseSeatDetailsOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.AGENT_WISE_REPORT_DETAILS_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (data.ticket.length === 0) {
              infoToast("No Data Available");
            }
            if (status === 200) {
              commit("setAgentWiseSeatDetailsOwner", data.ticket);
            }
            resolve(data.ticket);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getCabinmanWiseReportForOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.CABINMAN_WISE_REPORT_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (data.report.length === 0 ) {
              infoToast("No Data Available");
            }
            if (status === 200) {
              
              commit("setCabinmanWiseReportForOwner", data.report);
              resolve(data.report);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getCabinmanWiseReportDetailsForOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.CABINMAN_WISE_REPORT_DETAILS_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (data.report.length === 0) {
             
              infoToast("No Data Available");
            }
            if (status === 200) {
              commit("setCabinmanWiseReportDetailsForOwner", data.report);
            }
            resolve(data.report);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getfilterCounterWiseReportOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };
      return new Promise((resolve, reject) => {
        axios
          .get(Apis.COUNTER_WISE_REPORT_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (data.ticket.length === 0) {
              infoToast("No Data Available");
            }
            if (status === 200) {
              commit("setFilterCounterWiseReportOwner", data.ticket);
            }
            resolve(data.ticket);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getfilterAgentWiseReportOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };
      return new Promise((resolve, reject) => {
        axios
          .get(Apis.AGENT_WISE_REPORT_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (data.ticket.length === 0) {
              infoToast("No Data Available");
            }
            if (status === 200) {
              commit("setFilterAgentWiseReportOwner", data.ticket);
            }
            resolve(data.ticket);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getAllAgentListOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.AGENT_WISE_REPORT_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (data.ticket.length === 0) {
              infoToast("No Data Available");
            }
            if (status === 200) {
              commit("setAgentWiseListOwner", data.ticket);
              resolve(data.ticket);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getFilterShipWiseReportOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.SHIP_WISE_REPORT_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              commit("setFilterShipWiseListOwner", data.ticket);
              resolve(data.ticket);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getAllShipReportOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.SHIP_WISE_REPORT_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (data.ticket.length === 0) {
              infoToast("No Data Available");
            }
            if (status === 200) {
              commit("setShipWiseListOwner", data.ticket);
              resolve(data.ticket);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
  async getPrintCountListOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.PRINT_COUNT_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if(data.report && data.report.length === 0){
              infoToast("No Data Available");
            }
            if (data.ticket && data.ticket.length === 0) {
              infoToast("No Data Available");
            }
            if (status === 200) {
              commit("setCountReportOwner", { data, payload });
              resolve(data);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
  async getDeckmanWiseReportOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.DECKMAN_WISE_REPORT_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (data.ticket.length === 0) {
              infoToast("No Data Available");
            }
            if (status === 200) {
              commit("setDeckmanWiseReportOwner", data.ticket);
              resolve(data.ticket);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
  async getDeckmanWiseReportDetailsOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.DECKMAN_WISE_REPORT_DETAILS_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (data.ticket.length === 0) {
              infoToast("No Data Available");
            }
            if (status === 200) {
              commit("setDeckmanWiseReportDetailsOwner", data.ticket);
            }
            resolve(data.ticket);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
  async getOwnerServicesAllUserWiseReport(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.USER_WISE_REPORT_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (data.ticket.length === 0) {
                infoToast("No Data Available")
            }
            if (status === 200) {
              resolve(data.ticket);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
};
const mutations = {
  setShipListInFilterOwner: (state, shipList) =>
    (state.filter.shipOwner = shipList),
  setCounterListInFilterOwner: (state, counterList) =>
    (state.filter.counterOwner = counterList),

    setCompanyShipListOwner: (state, ships)=> (state.companyInfoOwner.ships = ships),
    setCompanyCounterListOwner: (state, counters)=> (state.companyInfoOwner.counters = counters),

  setfilterReservationWiseReport: (state, updateList) =>
    (state.reservationWiseReportList = updateList),
  setReservationWiseReportDetails: (state, seatDetails) =>
    (state.reservationWiseReportDetails = seatDetails),
  setAgentWiseSeatDetailsOwner: (state, seatDetails) =>
    (state.agentWiseSeatDetailsOwner = seatDetails),
  setFilterAgentWiseReportOwner: (state, updateList) =>
    (state.agentWiseReportListOwner = updateList),
  setFilterCounterWiseReportOwner: (state, updateList) =>
    (state.counterWiseReportListOwner = updateList),
  setAllCounterListOwner: (state, counterWiseReport) =>
    (state.counterWiseReportListOwner = counterWiseReport),
  setAgentWiseListOwner: (state, agentWiseReport) =>
    (state.agentWiseReportListOwner = agentWiseReport),
  setShipWiseListOwner: (state, shipWiseReport) =>
    (state.shipWiseReportListOwner = shipWiseReport),
  setFilterShipWiseListOwner: (state, shipWiseReport) =>
    (state.shipWiseReportListOwner = shipWiseReport),
  setCounterWiseSeatDetailsOwner: (state, seatDetails) =>
    (state.counterWiseSeatDetailsOwner = seatDetails),
  setCounterWiseReportDataOwner: (state, reportData) =>
    (state.counterWiseReportDataOwner = reportData),
  setShipWiseDeckDetailsOwner: (state, deckDetails) =>
    (state.shipWiseDeckDetailsOwner = deckDetails),
  setShipWiseGoodDetailsOwner: (state, goodDetails) =>
    (state.shipWiseGoodDetailsOwner = goodDetails),
  setShipWiseSeatDetailsOwner: (state, seatDetails) =>
    (state.shipWiseSeatDetailsOwner = seatDetails),
  setDeckmanWiseReportOwner: (state, deckmanWiseReport) =>
    (state.deckmanWiseReportList = deckmanWiseReport),
  setDeckmanWiseReportDetailsOwner: (state, report) =>
    (state.deckmanWiseReportDetails = report),
  setOwnerCompanyTag: (state, payload) => (state.companyTag = payload),

    setCabinmanWiseReportForOwner: (state, cabinmanWiseReport) => (state.cabinmanWiseReportListForOwner = cabinmanWiseReport),
    setCabinmanWiseReportDetailsForOwner: (state, cabinmanWiseReportDetails) => (state.cabinmanWiseReportDetailsForOwner = cabinmanWiseReportDetails),

  setCountReportOwner: (state, value) => {
    state.printTicketCountListOwner = [];
    state.printTripWiseCountListOwner = [];
    state.printDailySummaryWiseCountListOwner = [];
    state.printSupervisorTripWiseCountListOwner = [];
    if (value.payload.reportType === "ticket") {
      state.printTicketCountListOwner = value.data.ticket;
    } else if (value.payload.reportType === "tripWiseReport") {
      state.printTripWiseCountListOwner = value.data.report;
    } else if (value.payload.reportType === "dailySummaryReport") {
      state.printDailySummaryWiseCountListOwner = value.data.report;
    } else if (value.payload.reportType === "supervisorTripWiseReport") {
      state.printSupervisorTripWiseCountListOwner = value.data.report;
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
