<template>
  <Modal @close="closeModal">
    <template v-slot:title>{{ title }}</template>
    <template v-slot:body>
      <form>
        <div class="form-group row my-1">
          <ModalInput v-model="stoppageData.name"
                      v-bind:placeholder="'Enter Name'"
                      v-bind:label="'Name'" v-bind:id="'name'" v-bind:type="'text'">
            <template v-slot:error>
              <ModalError v-if="errors.name" v-bind:error="errors.name"/>
            </template>
          </ModalInput>
        </div>
        <div class="form-group row my-1">
          <ModalDropdown v-if="boolean" v-model="stoppageData.digitalTicketingFromStoppage"
                         v-bind:id="'digitalTicketingFromStoppage'" v-bind:label="'Digital T.(From)'"
                         v-bind:dropdown="boolean"></ModalDropdown>
        </div>
        <div class="form-group row my-1">
          <ModalDropdown v-if="boolean" v-model="stoppageData.digitalTicketingToStoppage"
                         v-bind:id="'digitalTicketingToStoppage'" v-bind:label="'Digital T.(To)'"
                         v-bind:dropdown="boolean"></ModalDropdown>
        </div>

        <div class="form-group row my-1">
          <ModalDropdown v-if="status" v-model="stoppageData.status" v-bind:id="'status'" v-bind:label="'Status'"
                         v-bind:dropdown="status"></ModalDropdown>
        </div>
        <div class="form-group row my-1">
          <ModalDropdown
              v-model="stoppageData.cityId"
              v-bind:id="'dtcity'"
              v-bind:label="'City'"
              v-bind:defaultOption="'---select city---'"
              v-bind:defaultValue="''"
              v-bind:dropdown="$store.getters.getDigitalTicketingAllStoppages"
          >
            <!--            <template v-slot:error>-->
            <!--              <ModalError v-if="errors.dtCity" v-bind:error="errors.dtCity" />-->
            <!--            </template>-->
          </ModalDropdown>
        </div>

        <div class="form-group row my-1">
          <label class="col-sm-3 col-form-label"></label>
          <div class="col-sm-9">
            <input v-if="!stoppageId" type="submit" class="mt-3 py-2 submit_button font_detail float-end"
                   @click.prevent="addStoppage"/>
            <input v-else type="submit" class="mt-3 py-2 submit_button font_detail float-end"
                   @click.prevent="updateStoppage"/>
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
import dropdownVariables from "@/modules/dropdownVariables";
import useInputValidation from "@/modules/useInputValidations";
import {inject, onBeforeMount, ref, watch} from "vue";
import {reactive} from "@vue/reactivity";
import store from "@/store";

export default {
  name: "StoppageAddUpdateModal",
  props: {
    stoppage: String,
    company: String
  },
  setup(props, {emit}) {
    let {requiredValidation, errors} = useInputValidation();
    let stoppageData = reactive({
      id: "",
      companyId: "",
      name: "",
      digitalTicketingFromStoppage: 0,
      digitalTicketingToStoppage: 0,
      status: 1,
      cityId: "",
    });
    const stoppageId = ref(props.stoppage);
    const companyId = ref(props.company);
    const status = dropdownVariables.status;
    const boolean = dropdownVariables.boolean;
    const title = ref("Add Stoppage");
    const showLoader = inject('showLoader');
    const hideLoader = inject('hideLoader');

    watch(() => stoppageData.name, () => {
      requiredValidation("name", stoppageData.name);
    });

    const validate = () => {
      Object.keys(errors).forEach(function (key) {
        errors[key] = "";
      });
      requiredValidation("name", stoppageData.name);
      return !(Object.values(errors).length <= 0 || !Object.values(errors).every(x => x === null || x === ''));
    };
    const closeModal = () => {
      emit("update:modelValue", false);
    };
    const addStoppage = () => {
      if (validate()) {
        showLoader();
        store.dispatch("addStoppage", stoppageData).then(() => {
          hideLoader();
          emit('getAllStoppages');
          emit('close');
        }).catch((error) => {
          hideLoader();
          if (error.status === 401) {
            addStoppage();
          }
        });
      }
    };
    const updateStoppage = () => {
      if (validate()) {
        showLoader();
        store.dispatch("updateStoppage", stoppageData).then(() => {
          hideLoader();
          emit('getAllStoppages');
          emit('close');
        }).catch((error) => {
          hideLoader();
          if (error.status === 401) {
            updateStoppage();
          }
        });

      }
    };

    onBeforeMount(() => {
      Object.keys(errors).forEach(function (key) {
        delete errors[key];
      });
      if (stoppageId.value !== "") {
        const stoppageDetails = store.getters.getStoppage(stoppageId.value);
        title.value = "Update Stoppage";
        stoppageData.id = stoppageDetails[0]._id;
        stoppageData.name = stoppageDetails[0].name;
        stoppageData.digitalTicketingFromStoppage = stoppageDetails[0].digitalTicketingFromStoppage ? 1 : 0;
        stoppageData.digitalTicketingToStoppage = stoppageDetails[0].digitalTicketingToStoppage ? 1 : 0;
        stoppageData.status = stoppageDetails[0].status ? 1 : 0;
        stoppageData.cityId = stoppageDetails[0].cityId;
      }
      stoppageData.companyId = companyId.value;
    });

    return {
      stoppageId, stoppageData, errors, status, title, boolean,
      addStoppage, updateStoppage, closeModal
    };
  }
};
</script>

<style scoped>

</style>