<template>
  <CancellationCause
      v-if="modelValue"
      v-model="modelValue"
      :ticketIdStore="ticketIdStore"
      :ticketTotalSeats="ticketSeats"
      @getAllUserReport="getAllUserReport"
  />
  <div v-if="reportActive">
    <FilterTabBetween>
      <template v-slot:body>
        <div class="col-sm-6 col-md-2">
          <DropdownFilter
            v-bind:id="'company'"
            v-bind:label="'Company'"
            v-bind:defaultOption="'--select company--'"
            v-model="userWiseReport.companyId"
            v-bind:defaultValue="''"
            v-bind:dropdown="$store.getters.getCompanyList"
          />
        </div>
        <div v-if="userWiseReport.companyId" class="col-sm-6 col-md-2">
          <DropdownFilter
            v-bind:id="'ship'"
            v-bind:label="'Ship'"
            v-bind:defaultOption="'--select ship--'"
            v-model="userWiseReport.shipId"
            v-bind:defaultValue="''"
            v-bind:dropdown="$store.getters.getShipListForFilter"
          />
        </div>
        <div v-if="userWiseReport.companyId" class="col-sm-6 col-md-4">
          <DateTimeRangePicker v-model="date.range" />
        </div>

        <div v-if="userWiseReport.companyId" class="col-sm-3 col-md-1">
          <DropdownFilter
            v-bind:id="'direction'"
            v-bind:label="'Direction'"
            v-bind:defaultOption="'--select direction--'"
            v-model="userWiseReport.direction"
            v-bind:defaultValue="''"
            v-bind:dropdown="dropdownVariables.direction"
          />
        </div>

        <div v-if="userWiseReport.companyId" class="col-sm-3 col-md-1">
          <DropdownFilter
            v-bind:id="'platform'"
            v-bind:label="'Platform'"
            v-bind:defaultOption="'--select platform--'"
            v-model="userWiseReport.platform"
            v-bind:defaultValue="''"
            v-bind:dropdown="dropdownVariables.platformOptions"
          />
        </div>

        <div
          v-if="userWiseReport.companyId"
          class="col-sm-3 col-md-1 col-xxl-1"
        >
          <DropdownFilter
            v-bind:id="'status'"
            v-bind:label="'Status'"
            v-model="userWiseReport.status"
            v-bind:dropdown="dropdownVariables.reportStatus"
          />
        </div>
      </template>
      <template v-if="userWiseReport.companyId" v-slot:filter>
        <button class="edit_table_data font_detail" @click="filterReport">
          <i class="fas fa-filter"></i><span class="mx-1 bold">Filter</span>
        </button>
      </template>
      <template v-slot:export>
        <button
          class="edit_table_data font_detail"
          @click="exportTable('xlsx')"
        >
          <i class="fas fa-download"></i><span class="mx-1 bold">Export</span>
        </button>
      </template>
    </FilterTabBetween>
    <div
      class="card h-100 mt-4"
      v-for="(itemValue, itemKey) in classWiseReport.data"
      :key="itemKey"
    >
      <div class="card-header text-center bg-white">
        <h5 class="mb-0 text-uppercase">{{ itemKey }}</h5>
      </div>
      <div class="card-body mt-0 py-0">
        <table-simple :id="itemKey">
          <template v-slot:header>
            <tr>
              <td class="font_detail">SL</td>
              <td class="font_detail">Ticket Date Time</td>
              <td class="font_detail">Trip Date Time</td>

              <td class="font_detail">Ship Name</td>
              <td class="font_detail">Trip Code</td>
              <td class="font_detail">Passenger Name</td>
              <td class="font_detail">Passenger Phone</td>
              <td class="font_detail">Boarding Place</td>
              <td class="font_detail">Dropping Place</td>
              <td class="font_detail">Seat Numbers</td>
              <td class="font_detail">Total Ticket</td>
              <td class="font_detail">Seat Fare</td>
              <td class="font_detail">Online Ticket Charge </td>
              <td class="font_detail">Payment gateway Fee</td>
              <td class="font_detail">Paid</td>

              <td class="font_detail">Issued By</td>
              <td class="font_detail">Verified By</td>
              <td class="font_detail">Platform</td>
              <td class="font_detail" v-if="cancelledBy === 0">Cancelled By</td>
              <td class="font_detail" v-if="cancelledBy === 0">
                Cancellation Cause
              </td>
              <td v-if="hasPermission(Admin.cancelUserTicket)" class="font_detail">Cancel</td>

            </tr>
          </template>
          <template v-slot:body>
            <tr v-for="(item, index) in itemValue.totalReport" :key="index">
              <td class="font_detail">{{ index + 1 }}</td>
              <td class="font_detail">{{ dateFormat(item.ticketDateTime) }}</td>
              <td class="font_detail">{{ dateFormat2(item.tripDateTime) }}</td>

              <td class="font_detail">{{ item.shipName }}</td>
              <td class="font_detail">{{ item.tripCode }}</td>
              <td class="font_detail">{{ item.passengerName }}</td>
              <td class="font_detail">{{ item.passengerPhone }}</td>
              <td class="font_detail">{{ item.boardingPlace }}</td>
              <td class="font_detail">{{ item.droppingPoint }}</td>
              <td class="font_detail">
                {{ item.seatNumbers.replaceAll(",", ", ") }}
              </td>
              <td class="font_detail">{{ item.totalSeat }}</td>
              <td class="font_detail">
                {{ item.totalAmount }}
              </td>
              <td class="font_detail">{{ item.ticketCommission }}</td>
              <td class="font_detail">{{ item.paymentGatewayCommission }}</td>
              <td class="font_detail">{{ item.paid }}</td>

              <td class="font_detail">{{ item.issuedBy }}</td>
              <td class="font_detail">{{ item.isVerified ? item.countermanId.name  : 'N/A' }}</td>
              <td class="font_detail">{{ item.origin }}</td>
              <td class="font_detail" v-if="cancelledBy === 0">
                {{ item.cancelledBy }}
              </td>
              <td class="font_detail" v-if="cancelledBy === 0">
                {{ item.cancellationCause }}
              </td>
              <td v-if="hasPermission(Admin.cancelUserTicket)" class="font_detail">

                <button
                    class="delete_table_data font_detail delete_table_button"
                    @click="cancelTicket(item._id, item.seatNumbers)"
                >
                  <i></i><span class="mx-1">Cancel</span>
                </button>
              </td>

            </tr>
            <tr class="bg-dark text-white" v-if="summary">
              <td class="font_detail text-white"></td>
              <td class="font_detail text-white"></td>
              <td class="font_detail text-white"></td>
              <td class="font_detail text-white"></td>
              <td class="font_detail text-white"></td>
              <td class="font_detail text-white"></td>
              <td class="font_detail text-white"></td>
              <td class="font_detail text-white"></td>
              <td class="font_detail text-white"></td>
              <td class="font_detail text-white"></td>
              <td class="font_detail text-white">
                Total Ticket: {{ itemValue.totalTicket }}
              </td>
              <td class="font_detail text-white">
                Total Amount: {{ itemValue.totalAmount }} Taka
              </td>

              <td class="font_detail text-white">
                Total Amount: {{ itemValue.totalCommission }} Taka
              </td>
              <td class="font_detail text-white">
                Total Amount: {{ itemValue.totalPaymentGatewayCommission }} Taka
              </td>
              <td class="font_detail text-white">
                Total Amount: {{ itemValue.totalPayable }} Taka
              </td>

              <td class="font_detail text-white"></td>
              <td class="font_detail text-white"></td>
              <td class="font_detail text-white"></td>
              <td class="font_detail text-white" v-if="cancelledBy === 0"></td>
              <td class="font_detail text-white" v-if="cancelledBy === 0"></td>
              <td v-if="hasPermission(Admin.cancelUserTicket)" class="font_detail text-white"></td>

            </tr>
          </template>
        </table-simple>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from "@vue/reactivity";
import { ref, onBeforeMount, inject, watch } from "vue";
import store from "@/store";
import moment from "moment";
import dropdownVariables from "@/modules/dropdownVariables";
import CancellationCause from "./CancellationCause.vue";
import permission from "@/modules/permission";
import roles from "@/modules/roles";


export default {
  name: "AgentDetails",
  components: {
    CancellationCause,
  },
  props: {},
  setup() {

    const {hasPermission} = permission();
    const {Admin} = roles();

    let totalAmountSeat = ref(0);
    let totalSeat = ref(0);
    let totalDiscount = ref(0);
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const exportExcel = inject("exportExcel");
    let cancelledBy = "";
    let reportActive = ref(true);
    let ticketSeats = ref([]);
    let modelValue = ref(false);
    let ticketIdStore = ref("");

    let summary = ref(false);

    const fromDate = moment(new Date()).format("yyyy-MM-DD" + " 00:00:00");
    const toDate = moment(new Date()).format("yyyy-MM-DD" + " 23:59:59");

    let date = reactive({
      range: {
        start: fromDate,
        end: toDate,
      },
    });

    let userWiseReport = reactive({
      companyId: "",
      shipId: "",
      fromDate: fromDate.value,
      toDate: toDate.value,
      direction: "",
      status: 1,
      platform: "",
    });
    let classWiseReport = reactive({
      data: {},
    });

    const dateFormat = (date) => {
      return moment(String(date))
        .utcOffset(6, false)
        .format("DD-MMM-yyyy hh:mm A");
    };
    const dateFormat2 = (date) => {
      return moment(String(date))
        .utcOffset(0, false)
        .format("DD-MMM-yyyy hh:mm A");
    };
    watch(
      () => userWiseReport.companyId,
      (value) => {
        userWiseReport.companyId = value;
      }
    );
    watch(
      () => userWiseReport.shipId,
      (value) => {
        userWiseReport.shipId = value;
      }
    );
    watch(
      () => userWiseReport.direction,
      (value) => {
        userWiseReport.direction = value;
      }
    );
    watch(
      () => userWiseReport.status,
      (value) => {
        userWiseReport.status = value;
      }
    );
    watch(
      () => date.range.start,
      (value) => {
        userWiseReport.fromDate = moment(value).format(
          "yyyy-MM-DD" + " 00:00:00"
        );
      }
    );
    watch(
      () => date.range.end,
      (value) => {
        userWiseReport.toDate = moment(value).format(
          "yyyy-MM-DD" + " 23:59:59"
        );
      }
    );
    watch(
      () => userWiseReport.companyId,
      () => {
        if (userWiseReport.companyId !== "") {
          getAllShips();
        }
      }
    );

    const cancelTicket = (ticketId, seats) => {
      ticketSeats.value = seats.split(",");
      modelValue.value = true;
      ticketIdStore.value = ticketId;
    };

    const getAllShips = () => {
      store.dispatch("getShipList", { companyId: userWiseReport.companyId });
    };

    const getAllCompanyList = () => {
      store.dispatch("getAllCompanyList");
    };
    const getAllUserReport = () => {
      showLoader();
      store
        .dispatch("getAllUserWiseReport", userWiseReport)
        .then((response) => {
          classifyByClassName(response);

          // if (response.length !== 0) {
          // } else {
          //   summary.value = false;
          // }
          hideLoader();
        })
        .catch(() => hideLoader());
    };
    const filterReport = () => {
      getAllUserReport();
    };

    const classifyByClassName = (reports) => {
      classWiseReport.data = {};
      reports.forEach((report) => {
        if (report.seatClassName in classWiseReport.data) {
          classWiseReport.data[report.seatClassName].totalReport.push(report);
        } else {
          classWiseReport.data[report.seatClassName] = {
            totalReport: [],
            totalTicket: 0,
            totalAmount: 0,
            totalCommission: 0,
            totalPaymentGatewayCommission: 0,
            totalPayable: 0,
          };
          classWiseReport.data[report.seatClassName].totalReport.push(report);
        }
      });
      seatDetailsdata();
    };

    const seatDetailsdata = () => {
      for (const key in classWiseReport.data) {
        classWiseReport.data[key].totalReport.forEach((data) => {
          classWiseReport.data[key].totalAmount += data.totalAmount; //or data.payable;
          classWiseReport.data[key].totalTicket += data.totalSeat;
          classWiseReport.data[key].totalCommission += data.ticketCommission;
          classWiseReport.data[key].totalPaymentGatewayCommission += data.paymentGatewayCommission;
          classWiseReport.data[key].totalPayable += data.paid;
        });
      }
      summary.value = true;
    };

    const exportTable = (type) => {
      for (const key in classWiseReport.data) {
        exportExcel(
          type,
          key + "_Admin_UserWise_Details_Report.",
          undefined,
          key
        );
      }
    };

    onBeforeMount(() => {
      getAllCompanyList();
      userWiseReport.fromDate = fromDate;
      userWiseReport.toDate = toDate;
    });

    return {
      userWiseReport,
      totalAmountSeat,
      exportTable,
      totalSeat,
      summary,
      cancelledBy,
      totalDiscount,
      dateFormat,
      dateFormat2,
      classWiseReport,
      date,
      filterReport,
      reportActive,
      dropdownVariables,
      ticketSeats,
      modelValue,
      ticketIdStore,
      getAllUserReport,
      cancelTicket,
      hasPermission,
      Admin,

    };
  },
};
</script>

<style scoped>
</style>