<template>
  <div>
    <label v-if="inputLabel" :for="inputId" class="label_font">{{
      inputLabel
    }}</label>
    <input
      :disabled="isDisabled"
      :id="inputId"
      class="input_field form-control rounded py-2 px-3 w-100 mt-1"
      v-model="input"
      :type="inputType"
      autocomplete="off"
      :placeholder="inputPlaceholder"
    />
  </div>
</template>

<script>
import { ref, watch } from "vue";

export default {
  name: "Input",
  props: {
    modelValue: [String, Number],
    placeholder: String,
    label: String,
    id: String,
    type: String,
    disabled: Boolean,
  },
  setup(props, { emit }) {
    const inputPlaceholder = ref(props.placeholder);
    const inputLabel = ref(props.label);
    const inputId = ref(props.id);
    const inputType = ref(props.type);
    const input = ref(props.modelValue);
    const isDisabled = ref(props.disabled);
    watch(
      () => input.value,
      () => {
        emit("update:modelValue", input.value);
      }
    );
    watch(
      () => props.modelValue,
      (value) => {
        input.value = value;
      }
    );
    watch(
      () => props.type,
      () => {
        if (props.type === "password") {
          inputType.value = "password";
        }
        if (props.type === "email") {
          inputType.value = "email";
        }
        if (props.type === "text") {
          inputType.value = "text";
        }
      }
    );
    watch(
      () => props.disabled,
      () => {
        isDisabled.value = props.disabled;
      }
    );
    return {
      inputPlaceholder,
      inputLabel,
      inputId,
      inputType,
      input,
      isDisabled,
    };
  },
};
</script>

<style scoped>
</style>
