<template>
  <div>
    <div v-if="addUpdate">
      <!--            <TicketPurchaseModal v-model="addUpdate" v-bind:seatInfo="seatsDetails" @refreshPage="refreshPage"/>-->
      <TicketPurchase
          v-model="addUpdate"
          :bookingNumber="propTripDetails.bookingNumber"
          :passPagination="propsPaginationData"
          v-bind:seatInfo="seatsDetails"
          @refreshPageGoBack="refreshPageGoBack"
      />
    </div>
    <div v-else-if="addReserve">
      <!--            <TicketPurchaseModal v-model="addReserve" v-bind:seatInfo="seatsDetails" @refreshPage="refreshPage"/>-->
      <TicketReserve
          v-model="addReserve"
          :bookingNumber="propTripDetails.bookingNumber"
          :passPagination="propsPaginationData"
          :seatInfo="seatsDetails"
          @refreshPageGoBack="refreshPageGoBack"
      />
    </div>
    <div v-else-if="printPreview">
      <TicketPrintPreview
          v-if="ticketTypeForPrint === 'lastTicket'"
          v-bind:ticket="lastTicketDetails"
          v-bind:ticketType="ticketTypeForPrint"
          @close="closePrintPreview"
      />
      <TripReportPrintPreview
          v-else
          :detailsTrip="propTripDetails"
          :printCount="printCount"
          :printCountStatus="printCountStatus"
          :tripId="trip"
          v-bind:ticketType="ticketTypeForPrint"
          v-bind:trip="userTripReport"
          @close="closePrintPreview"
      />
    </div>
    <div
        v-else
        class="card card-body py-4"
        style="background-color: whitesmoke"
    >
      <div class="row">
        <div class="row m-0">
          <div class="col-md-4 col-lg-4 col-xl-2 order-md-1 order-xl-1">
            <span class="fw-bold">TRIP INFORMATION</span>
            <div class="card card-body">
              <div class="row">
                <div class="col-sm-4 col-md-6 col-xl-12 col-xxl-6">
                  <p class="text-center font_detail m-0">Sold</p>
                  <div>
                    <div>
                      <div
                          class="rounded-end sold_seats common_btn span_font w-100"
                      >
                        <span>{{ totalBookedSeatCount }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4 col-md-6 col-xl-12 col-xxl-6">
                  <p class="text-center font_detail m-0">Blocked</p>
                  <div
                      class="rounded-start disabled_seat common_btn span_font w-100"
                  >
                    <span>{{ totalBlockedSeatCount }}</span>
                  </div>
                </div>
                <div
                    class="col-sm-4 col-md-12 col-xl-12 mt-md-2 mt-xl-0 mt-xxl-2"
                >
                  <p class="text-center font_detail m-0">Available</p>
                  <div
                      class="rounded-end available_other available span_font w-100"
                  >
                    {{ totalAvailableSeatCount }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-lg-12 col-xl-6 order-md-4 order-xl-2">
            <span class="fw-bold">SEAT INFORMATION</span>
            <div class="card card-body">
              <div class="row">
                <div class="col-md-12">
                  <p class="text-center font_detail m-0">SEATS</p>
                  <div
                      class="rounded-end available_other available span_font w-100"
                  >
                    <span class="orange-bold-text"
                    >#{{ seatsDetails.seats.toString() }}</span
                    >
                  </div>
                  <p class="text-center font_detail m-0">
                    Total Seats: {{ seatsDetails.seats.length }}
                  </p>
                </div>
                <div class="col-sm-4 col-md-4">
                  <p class="text-center font_detail m-0">FARE(TK)</p>
                  <div
                      class="rounded-end available_other available span_font w-100"
                  >
                    <span class="orange-bold-text">{{ calculateFare }}</span>
                  </div>
                </div>
                <div
                    v-if="hasPermission(Counterman.countermanFareDiscount)"
                    class="col-sm-4 col-md-4"
                >
                  <p class="text-center font_detail m-0">DISCOUNT</p>
                  <input
                      v-model="seatsDetails.discount"
                      autocomplete="off"
                      class="input_field form-control rounded px-3 w-100 text-center"
                      min="0"
                      oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"
                      onkeydown="if(event.key==='.'){event.preventDefault();}"
                      onkeypress='return event.charCode !== 46 || event.charCode >= 48 && event.charCode <= 57|| event.keyCode == 8 || event.keyCode == 46'
                      onkeyup="if(event.key==='.'){event.preventDefault();}"
                      step="1"
                      type="Number" @keydown="keydown"
                      @click.right.prevent @copy.prevent @paste.prevent>
                  <p v-if="discountErrorMsg" class="text-danger">
                    {{ discountErrorMsg }}
                  </p>
                </div>
                <div class="col-sm-4 col-md-4">
                  <p class="text-center font_detail m-0">PAYABLE(TK)</p>
                  <div
                      class="rounded-end available_other available span_font w-100"
                  >
                    <span class="orange-bold-text">{{ discountedFare }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-lg-4 col-xl-2 order-md-2 order-xl-3">
            <span class="fw-bold">PRINT</span>
            <div class="card card-body">
              <div class="row">
                <div class="col-sm-6 col-md-6">
                  <p class="text-center font_detail m-0">TRIP REPORT</p>
                  <button class="trip_button w-100" @click="tripReport(trip)">
                    <i class="fas fa-print"></i>
                  </button>
                  <!-- <p class="text-center font_detail m-0">SHIP NAME</p> -->
                </div>
                <div class="col-sm-6 col-md-6">
                  <p class="text-center font_detail m-0">LAST TICKET</p>
                  <button class="last_ticket w-100" @click="lastTicket(trip)">
                    <i class="fas fa-print"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-lg-4 col-xl-2 order-md-3 order-xl-4">
            <span class="fw-bold">ACTIONS</span>
            <div class="card card-body">
              <div class="row">
                <div class="col-sm-6 col-md-6 p-1">
                  <p class="text-center font_detail m-0">RESET</p>
                  <button class="remove_button w-100" @click="reset">
                    <i class="fas fa-trash-alt"></i>
                  </button>
                </div>
                <div class="col-sm-6 col-md-6 p-1">
                  <p class="text-center font_detail m-0">REFRESH</p>
                  <button class="refresh_button w-100" @click="refreshPageGoBack()">
                    <i class="fas fa-sync-alt"></i>
                  </button>
                </div>
                <div v-if="hasPermission(Counterman.counterReservation) && reservationStatus"
                     class="col-sm-6 col-md-6 p-1 mt-2">
                  <p class="text-center font_detail m-0">RESERVATION</p>
                  <button class="reserve_button w-100" @click="reserveSeats()">
                    <i class="fas fa-ticket-alt"></i>
                  </button>
                </div>

                <div
                    v-if="hasPermission(Counterman.countermanTicketBooking)"
                    class="col-sm-6 col-md-6 p-1 mt-2"
                >
                  <p class="text-center font_detail m-0">CONFIRM</p>
                  <button class="confirm_button w-100" @click="confirmSeats()">
                    <i class="fas fa-check"></i>
                  </button>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div v-if="floors.length !== 0 && isMultiFoor" style="margin: 5px 0">
          <ul
              id="myTab"
              class="nav nav-tabs justify-content-center nav-style border-0"
              role="tablist"
          >
            <li
                v-for="(floorTab, index) in floors"
                :key="index"
                class="nav-item"
                role="presentation"
            >
              <button
                  :id="floorTab.name + '-tab'"
                  :aria-controls="floorTab._id"
                  :class="
                  floorTab._id === floorPlan._id
                    ? 'active active-button'
                    : 'inactive-button'
                "
                  aria-selected="true"
                  class="nav-link py-2 px-4"
                  data-bs-toggle="tab"
                  role="tab"
                  type="button"
                  @click="setFloorPlan(floorTab, index)"
              >
                {{ floorTab.name }}
              </button>
            </li>
          </ul>
          <div id="myTabContent" class="card card-body p-0 tab-content">
            <div
                v-for="(floorTab, index) in floors"
                :id="floorTab._id"
                :key="index"
                :aria-labelledby="floorTab.name + '-tab'"
                class="tab-pane fade"
                role="tabpanel"
                v-bind:class="floorTab._id === floorPlan._id ? 'show active' : ''"
            ></div>
            <div>
              <div v-if="seatPlans.length !== 0" style="margin: 5px 0">
                <ul
                    id="myTab"
                    class="nav nav-tabs justify-content-center nav-style border-0"
                    role="tablist"
                >
                  <li
                      v-for="(tab, index) in seatPlans"
                      :key="index"
                      class="nav-item"
                      role="presentation"
                  >
                    <button
                        :id="tab.name + '-tab'"
                        :aria-controls="tab._id"
                        aria-selected="true"
                        class="nav-link py-2 px-4"
                        data-bs-toggle="tab"
                        role="tab"
                        type="button"
                        v-bind:class="
                        tab._id === seatPlanSingle._id
                          ? 'active active-button'
                          : 'inactive-button'
                      "
                        @click="setSeatPlan(tab, index)"
                    >
                      {{ tab.name }}
                    </button>
                  </li>
                </ul>
                <div
                    id="myTabContent"
                    class="card seat-card card-body p-0 tab-content"
                >
                  <div
                      v-for="(tab, index) in seatPlans"
                      :id="tab._id"
                      :key="index"
                      :aria-labelledby="tab.name + '-tab'"
                      class="tab-pane fade"
                      role="tabpanel"
                      v-bind:class="
                      tab._id === seatPlanSingle._id ? 'show active' : ''
                    "
                  ></div>
                  <SeatList
                      v-model="seatsDetails.seats"
                      :direction="propTripDetails.direction"
                      :maxSeatPerTicket="propTripDetails.maxSeatPerTicket"
                      :seatPlan="seatPlanSingle"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
            v-if="seatPlans.length !== 0 && !isMultiFoor"
            style="margin: 5px 0"
        >
          <ul
              id="myTab"
              class="nav nav-tabs justify-content-center nav-style border-0"
              role="tablist"
          >
            <li
                v-for="(tab, index) in seatPlans"
                :key="index"
                class="nav-item"
                role="presentation"
            >
              <button
                  :id="tab.name + '-tab'"
                  :aria-controls="tab._id"
                  aria-selected="true"
                  class="nav-link py-2 px-4"
                  data-bs-toggle="tab"
                  role="tab"
                  type="button"
                  v-bind:class="
                  tab._id === seatPlanSingle._id
                    ? 'active active-button'
                    : 'inactive-button'
                "
                  @click="setSeatPlan(tab, index)"
              >
                {{ tab.name }}
              </button>
            </li>
          </ul>
          <div
              id="myTabContent"
              class="card seat-card card-body p-0 tab-content"
          >
            <div
                v-for="(tab, index) in seatPlans"
                :id="tab._id"
                :key="index"
                :aria-labelledby="tab.name + '-tab'"
                class="tab-pane fade"
                role="tabpanel"
                v-bind:class="tab._id === seatPlanSingle._id ? 'show active' : ''"
            ></div>
            <SeatList
                v-model="seatsDetails.seats"
                :direction="propTripDetails.direction"
                :maxSeatPerTicket="propTripDetails.maxSeatPerTicket"
                :seatPlan="seatPlanSingle"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, inject, onBeforeMount, onBeforeUnmount, ref, watch} from "vue";
import SeatList from "./SeatList";
// import TicketPurchaseModal from "./TicketPurchaseModal";
import TicketPurchase from "./TicketPurchase";
import TicketReserve from "./TicketReserve";
import {reactive} from "@vue/reactivity";
import fareModality from "@/modules/fareModality";
import store from "@/store";
import dropdownVariables from "../../../../modules/dropdownVariables";
import toast from "@/modules/Toast";
import permission from "@/modules/permission";
import roles from "@/modules/roles";
import moment from "moment";
import {checkDTValidity, dtSetTimeout} from "@/composables/digitalTicketing";


const {errorToast} = toast();
const {hasPermission} = permission();
const {Counterman} = roles();

const {getNetFare} = fareModality();

export default {
  name: "TicketBooking",
  components: {SeatList, TicketPurchase, TicketReserve},
  props: {
    tripId: String,
    tripDetails: Object,
    paginationData: Object,
    selectedSeatPlanTab: Object,
    selectedFloorPlanTab: Object,
    selectedSeatPlanTabIndex: Number,
    selectedFloorPlanTabIndex: Number
  },
  setup(props, {emit}) {
    const propsPaginationData = ref(props.paginationData);
    const trip = ref(props.tripId);
    const addUpdate = ref(false);
    const addReserve = ref(false);
    const printPreview = ref(false);
    const tripIdForPrint = ref("");
    const ticketTypeForPrint = ref("");
    const userTripReport = ref([]);
    const lastTicketDetails = ref({});
    let discountErrorMsg = ref("");
    const propTripDetails = ref(props.tripDetails);
    let totalBookedSeatCount = ref(0);
    let totalActiveBookedSeatCount = ref(0);
    let totalBlockedSeatCount = ref(0);
    let totalAvailableSeatCount = ref(0);
    let printCount = ref(0);
    let printCountStatus = ref(false);
    let reservationStatus = ref(false);
    let reA = /[^a-zA-Z]/g;
    let reN = /[^0-9]/g;
    const title = ref("Print Booking");
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const floorId = ref("");
    const floorName = ref("");
    const activeSeatPlanTab = ref(props.selectedSeatPlanTab);
    const activeFloorPlanTab = ref(props.selectedFloorPlanTab);
    const activeSeatPlanTabIndex = ref(props.selectedSeatPlanTabIndex);
    // const activeFloorPlanTabIndex = ref(props.selectedFloorPlanTabIndex);

    let seatsDetails = reactive({
      tripId: trip.value,
      seats: [],
      seatClassId: "",
      numberOfTicketCopy: "",
      passengerCopy: "",
      officeCopy: "",
      discount: 0,
      totalAmount: 0,
      payable: 0,
      floorId: floorId,
      floorName: floorName,
    });

    let pagination = reactive({
      page: 1,
      limit: 10,
      companyId: store.getters.userCompany,
      from: "",
      to: "",
      ship: "",
      date: moment(new Date()).format("yyyy-MM-DD"),
      status: "ACTIVE",
    });
    const seatPlans = ref([]);
    let seatPlanSingle = ref();
    let floorPlan = ref();
    const isMultiFoor = ref(false);
    let lastTicketClone = {
      seatNumbersForPrintArray: [],
      seatNumbersForPrint: "",
    };
    const floors = ref([]);

    watch(
        () => seatsDetails.discount,
        () => {
          if (seatsDetails.discount % 1 !== 0) {
            seatsDetails.discount = Math.round(seatsDetails.discount)
          }
          if (seatsDetails.discount > calculateFare.value) {
            discountErrorMsg.value =
                "Discount amount can't be greater than net fare";
            seatsDetails.discount = 0;
          }
        }
    );

    watch(() => props.tripDetails, (value) => {
      checkDTValidity(value, refreshPageGoBack);
    })


    const close = () => {
      addUpdate.value = false;
      addReserve.value = false;
    };
    const calculateFare = computed(() => {
      let netFare = 0;
      seatPlans.value.forEach((plan) => {
        if (plan._id === seatsDetails.seatClassId) {
          netFare = getNetFare(
              seatsDetails.seats,
              plan.upFare,
              plan.downFare,
              plan.direction
          );
        }
      });
      return netFare;
    });

    const reset = () => {
      seatsDetails.seats = [];
      seatsDetails.discount = 0;
    };

    const discountedFare = computed(() => {
      return calculateFare.value - seatsDetails.discount;
    });

    const setFloorPlan = (plan, index) => {
      emit("floorPlanTab", plan, index);
      floorPlan.value = plan;
      floorId.value = plan._id;
      floorName.value = plan.name;
      tripInfoForFloor();
      // setSeatPlan()
    };

    const setSeatPlan = (plan, index) => {
      if (index === undefined) {
        index = 0;
      }
      emit("seatPlanTab", plan, index);
      seatsDetails.discount = 0;
      seatsDetails.seatClassName = plan.name;
      seatPlanSingle.value = plan;
      seatsDetails.seatClassId = seatPlanSingle.value._id;
      seatsDetails.numberOfTicketCopy = seatPlanSingle.value?.numberOfTicketCopy;
      seatsDetails.passengerCopy = seatPlanSingle.value?.passengerCopy;
      seatsDetails.officeCopy = seatPlanSingle.value?.officeCopy;
      seatsDetails.seats = [];
    };

    const refreshPage = () => {
      showLoader();
      store
          .dispatch("getActiveTripsForTicketing", pagination)
          .then(() => {
            hideLoader();
          })
          .catch(() => {
            hideLoader();
          });
    };

    const refreshPageGoBack = () => {
      showLoader();
      store
          .dispatch("getActiveTripsForTicketing", propsPaginationData.value)
          .then(() => {
            hideLoader();
          })
          .catch(() => {
            hideLoader();
          });
    };

    const tripReport = (tripId) => {
      tripIdForPrint.value = tripId;
      showLoader();
      store
          .dispatch("getCountermanTripReport", {tripId: tripIdForPrint.value})
          .then((tripReport) => {
            hideLoader();
            if (tripReport) {
              userTripReport.value = tripReport;
              ticketTypeForPrint.value = "tripReport";
            }
            reportPrintCount();
          })
          .catch(() => {
            hideLoader();
            errorToast("Something wrong.Please try again later.");
          });
    };
    const reportPrintCount = () => {
      showLoader();

      store
          .dispatch("reportPrintCount", {
            reportType: "tripWiseReport",
            tripId: trip.value,
          })
          .then((data) => {
            hideLoader();
            printCount.value = data.printCount + 1;
            printCountStatus.value = data.status;
            printPreview.value = true;
          })
          .catch(() => {
            hideLoader();
            errorToast("Something wrong.Please try again later.");
          });
    };
    const lastTicket = (tripId) => {
      tripIdForPrint.value = tripId;
      showLoader();
      store
          .dispatch("getCountermanLastTicket", {tripId: tripIdForPrint.value})
          .then((lastTicket) => {
            hideLoader();
            if (lastTicket.length > 0) {
              lastTicketDetails.value = lastTicket[0];

              setSeatNumbersForPrint();

              ticketTypeForPrint.value = "lastTicket";
              printPreview.value = true;
            }
          })
          .catch(() => {
            hideLoader();
            errorToast("Something wrong.Please try again later.");
          });
    };
    const setSeatNumbersForPrint = () => {
      for (
          let index = 0;
          index < lastTicketDetails.value.numberOfTicketCopy;
          index++
      ) {
        lastTicketClone.seatNumbersForPrintArray = [
          ...lastTicketClone.seatNumbersForPrintArray,
          ...lastTicketDetails.value.seatNumbers.split(","),
        ];
      }

      lastTicketClone.seatNumbersForPrint =
          lastTicketClone.seatNumbersForPrintArray.sort(sortAlphaNum).toString();
      delete lastTicketClone.seatNumbersForPrintArray;

      lastTicketDetails.value["seatNumbersForPrint"] =
          lastTicketClone.seatNumbersForPrint;
    };

    const sortAlphaNum = (a, b) => {
      let aA = a.replace(reA, "");
      let bA = b.replace(reA, "");
      if (aA === bA) {
        let aN = parseInt(a.replace(reN, ""), 10);
        let bN = parseInt(b.replace(reN, ""), 10);
        return aN === bN ? 0 : aN > bN ? 1 : -1;
      } else {
        return aA > bA ? 1 : -1;
      }
    };
    const closePrintPreview = () => {
      printPreview.value = false;
      refreshPageGoBack();
      tripIdForPrint.value = "";
      ticketTypeForPrint.value = "";
    };

    const tripInfoForFloor = () => {
      seatPlans.value = [];
      const tripSingle = store.getters.getTripForTicketing(trip.value)[0];
      const seatPlan = tripSingle.ship.seatPlan.filter(
          (item) => item.floorId === floorPlan.value._id
      );
      seatPlanSingle.value = seatPlan[0];
      const bookedSeats = tripSingle.bookedSeats;
      seatPlan.forEach((item) => {
        let flag = false;
        bookedSeats.forEach((seat) => {
          if (item._id === seat.seat_class_id) {
            const object = {
              bookedList: seat.seat_no,
              onlineSeats: seat.online_seat,
              reservedSeats: seat.reserved_seat,
              bookedByFemaleList: seat.female_seat_no,
              bookedByMaleList: seat.male_seat_no,
              blockedSeats: item.blockedSeat,
              dtUpSeat: item.dtUpSeat,
              dtDownSeat: item.dtDownSeat,
              dtAvailable: item.dtAvailable,
              seatList: item.seatList,
              _id: item._id,
              floorId: item.floorId,
              name: item.name,
              upFare: item.upFare,
              downFare: item.downFare,
              numberOfTicketCopy: item.numberOfTicketCopy,
              passengerCopy: item.passengerCopy,
              officeCopy: item.officeCopy,
              direction: store.getters.getTripForTicketing(trip.value)[0]
                  .direction,
            };
            seatPlans.value.push(object);
            flag = true;
          }
        });
        if (!flag) {
          const object = {
            bookedList: "",
            onlineSeats: "",
            reservedSeats: "",
            bookedByFemaleList: "",
            bookedByMaleList: "",
            blockedSeats: item.blockedSeat,
            dtUpSeat: item.dtUpSeat,
            dtDownSeat: item.dtDownSeat,
            dtAvailable: item.dtAvailable,
            seatList: item.seatList,
            _id: item._id,
            floorId: item.floorId,
            name: item.name,
            upFare: item.upFare,
            downFare: item.downFare,
            numberOfTicketCopy: item.numberOfTicketCopy,
            passengerCopy: item.passengerCopy,
            officeCopy: item.officeCopy,

            direction: store.getters.getTripForTicketing(trip.value)[0]
                .direction,
          };
          seatPlans.value.push(object);
          flag = false;
        }
      });
      setSeatPlan(seatPlans.value[activeSeatPlanTabIndex.value ?? 0] ?? seatPlans.value[0], 0)
      activeSeatPlanTabIndex.value = 0;
    };

    const calculateSeatCounter = (trip) => {
      const bookedSeatsLocal = trip.bookedSeats;
      const seatCapacityLocal = trip.ship.isMultiFloor ? trip.ship.floorWiseSeatCapacity : trip.ship.seatCapacity;
      const seatPlansLocal = trip.ship.seatPlan;

      if (bookedSeatsLocal.length > 0) {
        bookedSeatsLocal.forEach((bookedSeat) => {
          let seatCount = 0;
          let onlineSeatCount = 0;
          if (bookedSeat.seat_no !== "" && bookedSeat.seat_no !== undefined) {
            seatCount = bookedSeat.seat_no?.split(",").length
          }
          if (bookedSeat.online_seat !== "" && bookedSeat.online_seat !== undefined) {
            onlineSeatCount = bookedSeat.online_seat?.split(",").length
          }
          totalBookedSeatCount.value += seatCount + onlineSeatCount;
          if (trip.ship.seatPlan.find((item) => item._id === bookedSeat.seat_class_id) !== undefined) {
            totalActiveBookedSeatCount.value += seatCount + onlineSeatCount
          }
        })
      }

      seatPlansLocal.forEach((item) => {
        if (trip.ship.isMultiFloor) {
          if (item.floorId) {
            totalBlockedSeatCount.value += item.blockedSeat ? item.blockedSeat.split(",").length : 0;
          }
        } else {
          if (!item.floorId) {
            totalBlockedSeatCount.value += item.blockedSeat ? item.blockedSeat.split(",").length : 0;
          }
        }
        /* if (item.blockedSeat) {
          totalBlockedSeatCount.value += item.blockedSeat.split(",").length;
        }*/
      });

      totalAvailableSeatCount.value =
          seatCapacityLocal -
          (totalBlockedSeatCount.value + totalActiveBookedSeatCount.value);
    };

    const tripInfo = () => {
      const tripSingle = store.getters.getTripForTicketing(trip.value)[0];
      isMultiFoor.value = tripSingle.ship.isMultiFloor;
      let seatPlan = [];
      if (isMultiFoor.value) {
        floors.value = tripSingle.ship.floors;
        floorPlan.value = floors.value[0];
        floorId.value = floors.value[0]._id;
        floorName.value = floors.value[0].name;
        seatPlan = tripSingle.ship.seatPlan.filter(
            (item) => item.floorId === floorPlan.value?._id
        );
      } else {
        seatPlan = tripSingle.ship.seatPlan.filter(
            (item) => item.floorId === undefined
        );
      }
      const bookedSeats = tripSingle.bookedSeats;
      seatPlan.forEach((item) => {
        let flag = false;

        bookedSeats.forEach((seat) => {
          if (item._id === seat.seat_class_id) {
            const object = {
              bookedList: seat.seat_no,
              onlineSeats: seat.online_seat,
              reservedSeats: seat.reserved_seat,
              bookedByFemaleList: seat.female_seat_no,
              bookedByMaleList: seat.male_seat_no,
              blockedSeats: item.blockedSeat,
              dtUpSeat: item.dtUpSeat,
              dtDownSeat: item.dtDownSeat,
              dtAvailable: item.dtAvailable,
              seatList: item.seatList,
              _id: item._id,
              name: item.name,
              upFare: item.upFare,
              downFare: item.downFare,
              numberOfTicketCopy: item.numberOfTicketCopy,
              passengerCopy: item.passengerCopy,
              officeCopy: item.officeCopy,
              direction: store.getters.getTripForTicketing(trip.value)[0]
                  .direction,
            };
            seatPlans.value.push(object);
            flag = true;
          }
        });
        if (!flag) {
          const object = {
            bookedList: "",
            onlineSeats: "",
            reservedSeats: "",
            bookedByFemaleList: "",
            bookedByMaleList: "",
            blockedSeats: item.blockedSeat,
            dtUpSeat: item.dtUpSeat,
            dtDownSeat: item.dtDownSeat,
            dtAvailable: item.dtAvailable,
            seatList: item.seatList,
            _id: item._id,
            name: item.name,
            upFare: item.upFare,
            downFare: item.downFare,
            numberOfTicketCopy: item.numberOfTicketCopy,
            passengerCopy: item.passengerCopy,
            officeCopy: item.officeCopy,

            direction: store.getters.getTripForTicketing(trip.value)[0]
                .direction,
          };
          seatPlans.value.push(object);
          flag = false;
        }
      });
      calculateSeatCounter(tripSingle);
    };

    const confirmSeats = () => {
      if (seatsDetails.seats.length > 0) {
        seatsDetails.totalAmount = calculateFare.value;
        seatsDetails.payable = discountedFare.value;
        addUpdate.value = true;
      } else {
        errorToast("Please select any seat");
      }
    };

    const reserveSeats = () => {
      if (seatsDetails.seats.length > 0) {

        if (seatsDetails.discount > 0) {
          discountErrorMsg.value =
              "Discount isn't allowed for Reservation";
          // seatsDetails.discount = 0;
          addReserve.value = false;
        } else {
          seatsDetails.totalAmount = calculateFare.value;
          seatsDetails.payable = calculateFare.value;
          addReserve.value = true;
        }
      } else {
        errorToast("Please select any seat");
      }
    };
    const activeCurrentTab = () => {
      const availableFloor = store.getters.getTripForTicketing(trip.value)[0].ship.isMultiFloor;
      if (!availableFloor) {
        if (Object.keys(activeSeatPlanTab.value).length > 0) {
          const currentPlan = seatPlans.value.findIndex(plan => plan._id === activeSeatPlanTab.value._id);
          setSeatPlan(seatPlans.value[currentPlan < 0 ? 0 : currentPlan]);
        } else if (seatPlans.value.length > 0) {
          setSeatPlan(seatPlans.value[0]);
        }
      } else {
        if (Object.keys(activeFloorPlanTab.value).length > 0 && Object.keys(activeSeatPlanTab.value).length > 0) {
          const currentFloor = floors.value.findIndex(floor => floor._id === activeFloorPlanTab.value._id);
          setFloorPlan(floors.value[currentFloor < 0 ? 0 : currentFloor]);
          const currentPlan = seatPlans.value.findIndex(plan => plan._id === activeSeatPlanTab.value._id);
          setSeatPlan(seatPlans.value[currentPlan < 0 ? 0 : currentPlan]);
        } else if (floors.value.length > 0 && seatPlans.value.length > 0) {
          setFloorPlan(floors.value[0]);
          setSeatPlan(seatPlans.value[0]);
        }
      }
    };

    checkDTValidity(propTripDetails.value, refreshPageGoBack);

    onBeforeUnmount(() => {
      clearTimeout(dtSetTimeout)
    })


    onBeforeMount(() => {
      totalBlockedSeatCount.value = 0;
      totalAvailableSeatCount.value = 0;
      totalBookedSeatCount.value = 0;
      reservationStatus.value =
          store.getters.getTripsForTicketing[0].companyId.reservation;
      tripInfo();
      activeCurrentTab();
    });
    return {
      discountErrorMsg,
      ticketTypeForPrint,
      printPreview,
      Counterman,
      addUpdate,
      addReserve,
      seatsDetails,
      userTripReport,
      lastTicketDetails,
      totalAvailableSeatCount,
      totalBookedSeatCount,
      totalActiveBookedSeatCount,
      dropdownVariables,
      seatPlans,
      title,
      trip,
      seatPlanSingle,
      calculateFare,
      propTripDetails,
      discountedFare,
      totalBlockedSeatCount,
      propsPaginationData,
      hasPermission,
      tripReport,
      reset,
      close,
      lastTicket,
      confirmSeats,
      reserveSeats,
      reservationStatus,
      closePrintPreview,
      setSeatPlan,
      refreshPage,
      refreshPageGoBack,
      printCount,
      printCountStatus,
      floors,
      setFloorPlan,
      floorPlan,
      isMultiFoor,
      floorId,
      floorName,
      activeSeatPlanTab
    };
  },
};
</script>

<style scoped>
.seat-card {
  margin-right: auto;
  width: fit-content;
  margin-left: auto;
  background-color: #eaeaea;
}

.nav-style {
  background: whitesmoke;
  border-top: 1px;
}

.active-button {
  background: #3B3B3B !important;
  /*border: 1px solid #242424 !important;*/
  color: #FFF !important;
}

.inactive-button {
  color: #888 !important;
}

.available {
  color: #000000;
  padding: 5px 10px;
  border-radius: 4px;
  text-transform: uppercase;
}

.span_font {
  font-size: 14px;
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: 500;
}

.trip_button {
  margin: 1px 2px;
  border: 2px solid #9400d3;
  border-radius: 4px;
  background-color: #9400d3;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.trip_button:hover,
.trip_button:focus {
  border: 2px solid #9400d3;
  background-color: #fff;
  color: #9400d3;
  transition: 0.4s;
  outline: 0;
}

.last_ticket {
  margin: 1px 2px;
  border: 2px solid #00bfff;
  border-radius: 4px;
  background-color: #00bfff;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.last_ticket:hover,
.last_ticket:focus {
  border: 2px solid #00bfff;
  background-color: #fff;
  color: #00bfff;
  transition: 0.4s;
  outline: 0;
}

.remove_button {
  margin: 1px 2px;
  border: 2px solid #ff0000;
  border-radius: 4px;
  background-color: #ff0000;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.remove_button:hover,
.remove_button:focus {
  border: 2px solid #ff0000;
  background-color: #fff;
  color: #ff0000;
  transition: 0.4s;
  outline: 0;
}

.confirm_button {
  margin: 1px 2px;
  border: 2px solid #34a853;
  border-radius: 4px;
  background-color: #34a853;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.confirm_button:hover,
.confirm_button:focus {
  border: 2px solid #34a853;
  background-color: #fff;
  color: #34a853;
  transition: 0.4s;
  outline: 0;
}

.reserve_button {
  margin: 1px 2px;
  border: 1px solid #5c1916;
  border-radius: 4px;
  background-color: #5c1916;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.reserve_button:hover,
.reserve_button:focus {
  border: 1px solid #5c1916;
  background-color: #fff;
  color: #5c1916;
  transition: 0.4s;
  outline: 0;
}

.refresh_button {
  margin: 1px 2px;
  border: 2px solid #ffc107;
  border-radius: 4px;
  background-color: #ffc107;
  padding: 2px 6px;
  color: #ffff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.refresh_button:hover,
.refresh_button:focus {
  border: 2px solid #ffc107;
  background-color: #fff;
  color: #ffc107;
  transition: 0.4s;
  outline: 0;
}
</style>
