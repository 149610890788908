import LoadingSpinner from "@/components/layouts/Loading/loadingSpinner";
import {ref} from "vue";

export default {
    install: (app) => {
        let loading = ref({
            active:false,
            backgroundColor:'#666666',
            opacity:0.3,
            zIndex:999,
            transition:'fade',
            isFullPage:true,
            animationDuration:1000,
            iconSize:100,
            iconColor:'#f04935'
        });
        const showLoader = () =>{
            loading.value.active = true
        }
        const hideLoader = () =>{
            loading.value.active = false
        }
        app.provide('showLoader',showLoader)
        app.provide('hideLoader',hideLoader)
        app.provide('loading',loading)
        app.component('loading-spinner', LoadingSpinner)
    }
}