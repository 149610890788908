import axios from "axios";
import Apis from "@/urls/apis";
import tokenCookie from "@/modules/tokenCookie";
import apiResponse from "@/modules/apiResponse";
import toast from "@/modules/Toast";

const {successToast} = toast();
const {hasValidToken, getToken} = tokenCookie();
const {errorResponse} = apiResponse();

const state = {
    filter: {
        stoppage: {},
        ship: {},
        route: {},
        counter: {},
    },
    trips: [],
    tickets: {}
};
const getters = {
    getAgentStoppageListForFilter: (state) => state.filter.stoppage,
    getAgentShipListForFilter: (state) => state.filter.ship,
    getAgentRouteListForFilter: (state) => state.filter.route,

    getTicketsHistoryAgent: (state) => state.tickets.docs,
    getTotalTicketsHistoyAgent: (state) => state.tickets.totalDocs,
    getTripsForTicketingByAgent: (state) => state.trips.docs,
    getTotalTripsForTicketingByAgent: (state) => state.trips.totalDocs,
    getTripForTicketingByAgent: (state) => (id) => state.trips.docs.filter(function (trip) {
        return trip._id === id;
    }),
};
const actions = {
    async getStoppageListForAgent({commit}, payload) {
        if (await hasValidToken()) {
            commit("setStoppageListInFilter", {});
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };

            return new Promise((resolve, reject) => {
                axios.get(Apis.GET_ALL_AGENT_STOPPAGES, {
                    params: payload,
                    headers: header
                })
                    .then(({data, status}) => {
                        if (status === 200) {
                            let stoppageList = {}
                            data.stoppage.forEach(stoppage => {
                                stoppageList[stoppage._id] = stoppage.name;
                            });
                            commit("setStoppageListInFilter", stoppageList)
                            resolve(data.stoppage);
                        }
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async getShipListForAgent({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };

            return new Promise((resolve, reject) => {
                axios.get(Apis.GET_ALL_AGENT_SHIPS, {
                    params: payload,
                    headers: header
                })
                    .then(({data, status}) => {
                        if (status === 200) {
                            let shipList = {}
                            data.ship.forEach(ship => {
                                shipList[ship._id] = ship.name;
                            });
                            commit("setShipListInFilter", shipList)
                            resolve(data.ship);
                        }
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async purchaseTicketByAgent(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.post(Apis.TICKET_PURCHASE_BY_AGENT, payload, {headers: header})
                    .then((response) => {
                        successToast('Ticket Purchased Successfully')
                        resolve(response.data.ticket)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async getActiveTripsForTicketingOfAgent({commit}, payload) {
        if (await hasValidToken()) {
            commit("setTrips", []);
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.get(Apis.GET_ALL_TRIPS_FOR_TICKETING_BY_AGENT, {
                    params: payload,
                    headers: header
                })
                    .then(({data, status}) => {
                        if (status === 200) {
                            commit("setTrips", data.trip);
                            resolve({'success': 'success'});
                        }
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async getAgentLastTicket(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.get(Apis.GET_LAST_TICKET_BY_AGENT, {
                    params: payload,
                    headers: header
                })
                    .then(({data, status}) => {
                        if (status === 200) {
                            resolve(data.ticket);
                        }
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },
};
const mutations = {
    setStoppageListInFilter: (state, stoppageList) => (state.filter.stoppage = stoppageList),
    setShipListInFilter: (state, shipList) => (state.filter.ship = shipList),
    setTicketsHistory: (state, tickets) => (state.tickets = tickets),
    setTrips: (state, trips) => (state.trips = trips),
};

export default {
    state,
    getters,
    actions,
    mutations
};