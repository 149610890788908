<template>
  <div>
    <div class="modal fade show" tabindex="-1" aria-labelledby="exampleModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" v-bind:class="modalSize">
        <div class="modal-content modal-animate-zoom">
          <div class="modal-header bg_corporate">
            <h5 class="modal-title" id="addAdminModalLabel"><slot name="title"></slot></h5>
            <button type="button" class="modal_close_btn" @click.prevent="$emit('close')">&times;</button>
          </div>
          <div class="modal-body">
            <form>
              <slot name="body"></slot>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade show"></div>
  </div>
</template>

<script>
import {ref} from "vue";

export default {
  name: "Modal",
  emits: ['close'],
  props:{
    size: String
  },
  setup(props){
    const modalSize = ref('')
    if(props.size === 'xl'){
      modalSize.value="modal-xl"
    }else if(props.size === 'lg'){
      modalSize.value="modal-lg"
    }else if(props.size === 'sm'){
      modalSize.value="modal-sm"
    }
    return{
      modalSize
    }
  }
}
</script>

<style scoped>
.modal{
  display: block;
}

</style>