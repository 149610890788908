import axios from "axios";
import Apis from "@/urls/apis";
import tokenCookie from "@/modules/tokenCookie";
import apiResponse from "@/modules/apiResponse";

const {hasValidToken, getToken} = tokenCookie();
const {errorResponse} = apiResponse();

const state = {
    reports: {}
};
const getters = {
    getAgentReport: (state) => state.reports,
};
const actions = {
    async getAllAgentReport({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };

            return new Promise((resolve, reject) => {
                axios.get(Apis.GET_ALL_AGENT_REPORT, {
                    params: payload,
                    headers: header
                })
                    .then(({data, status}) => {
                        if (status === 200) {
                            commit("setAllAgentReport", data)
                            resolve(data)
                        }
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async getAgentTripReport(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };

            return new Promise((resolve, reject) => {
                axios.get(Apis.GET_AGENT_TRIPWISE_REPORT, {
                    params: payload,
                    headers: header
                })
                    .then(({data, status}) => {
                        if (status === 200) {
                            resolve(data)
                        }
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },
};
const mutations = {
    setAllAgentReport: (state, reports) => (state.reports = reports),
};

export default {
    state,
    getters,
    actions,
    mutations
};