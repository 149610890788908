<template>
  <div>
    <div class="not_found_page d-flex justify-content-center align-items-center">
      <div class="not_found_text">
        <h1>404</h1>
        <h6>Something is wrong</h6>
        <p>The page you are looking for was moved, removed, renamed or might never existed.</p>
        <router-link to="/">LOGIN</router-link>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "Error"
}
</script>

<style scoped>

</style>