<template>
  <span class="text-danger err_msg">{{ errorMessage }}</span>
</template>

<script>
import {ref, watch} from "vue";

export default {
  name: "Error",
  props:{
    error:String
  },
  setup(props){
    const errorMessage = ref(props.error);
    watch(() => props.error, (message) => {
      errorMessage.value = message;
    });
    return{
      errorMessage
    }
  }
};
</script>

<style scoped>

</style>