<template>
  <header>
    <nav class="navbar navbar-light bg-light shadow-sm">
      <div class="container">
        <a class="navbar-brand logo" href="#">
          <img src="@/assets/images/logo.png" alt="" class="img-responsive">
        </a>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  name: "Nav",
}
</script>

<style scoped>

</style>