<template>
  <div>
    <label :for="inputId" class="col-sm-3 col-form-label font_detail">{{inputLabel}}</label>
    <Multiselect
        placeholder="Select a ship"
        v-model="input"
        :options="options"
        :id="inputId"
        mode="tags"
        :searchable="true"
        :createTag="true"
    />
    <slot name="error"></slot>
  </div>
</template>

<script>
import Multiselect from '@vueform/multiselect'
import {ref, watch} from "vue";

export default {
  name: "Multiselect",

  components: {
    Multiselect,
  },
  props: {
    modelValue: Array,
    dropdown: Object,
    label: String,
    id: String
  },
  setup(props, {emit}) {
    const options = ref(props.dropdown);
    const inputLabel = ref(props.label);
    const inputId = ref(props.id);
    const input = ref(props.modelValue);
    watch(() => input.value, () => {
      emit("update:modelValue", input.value);
    });
    return {
      options, inputLabel, inputId,
      input
    };
  }
}
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>

</style>
