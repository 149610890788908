<template>
  <div class="col-sm-3">
    <label :for="inputId" class="font_detail ">{{ inputLabel }} :</label>
  </div>
  <div class="col-sm-9">
    <input 
      :id="inputId" 
      v-model="input" 
      :type="inputType" 
      :placeholder="inputPlaceholder" 
      :min="inputMin"
      :max="inputMax"
      :maxlength="inputMaxlength"
      :step="inputStep" 
      :onkeypress="inputOnkeypress" 
      autocomplete="off"
      class="form-control font_detail" 
      pattern="^(?:\+?88)?01[3-9]\d{8}$"
      @click.right.prevent 
      @keydown="keydown" 
      @copy.prevent 
      @paste.prevent>
    <slot name="error"></slot>
  </div>
</template>
 
<script>
import {ref, watch} from "vue";

export default {
  name: "InputPhone",
  props: {
    modelValue: [String, Number],
    placeholder: String,
    label: String,
    id: String,
    type: String,
    min: String,
    max: String,
    maxlength: String,
    step: String,
    onkeypress: String,
    isDisableKeyBindingInput: Boolean
  },
  setup(props, {emit}) {
    const inputPlaceholder = ref(props.placeholder);
    const inputLabel = ref(props.label);
    const inputId = ref(props.id);
    const inputType = ref(props.type);
    const input = ref(props.modelValue);
    const inputMin = ref(props.min);
    const inputMax = ref(props.max);
    const inputMaxlength = ref(props.maxlength);
    const inputStep = ref(props.step);
    const inputOnkeypress = ref(props.onkeypress);
    const isDisableKeyBinding = ref(props.isDisableKeyBindingInput);
    watch(() => input.value, () => {
      emit("update:modelValue", input.value);
    });
    watch(() => props.modelValue, (value) => {
      if (inputType.value == 'number'){
        input.value = value || 0;
      }
      else{
        input.value = value;
      }
    });

    return {
      inputPlaceholder, 
      inputLabel, 
      inputId, 
      inputType,
      input, 
      inputMin, 
      inputMax,
      inputMaxlength,
      inputStep, 
      inputOnkeypress, 
      isDisableKeyBinding,
    };
  }
};
</script>

<style scoped>

</style>