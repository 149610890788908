<template>
    <div>
        <Filter>
            <template v-slot:body>
                <div class="col-sm-6 col-md-2">
                    <DatePickerFilter v-bind:id="'datePickerDate'" v-bind:label="'Date'" v-model="pagination.date"/>
                </div>
                <div class="col-sm-6 col-md-2">
                    <DropdownFilter v-bind:id="'status'" v-bind:label="'Status'"
                                    v-bind:defaultOption="'--select status--'"
                                    v-model="pagination.status"
                                    v-bind:defaultValue="''"
                                    v-bind:dropdown="status"/>
                </div>
            </template>
        </Filter>
        <div>
            <Table v-if="$store.getters.getAgentTrips">
                <template v-slot:header>
                    <tr>
                        <td class="font_detail"> SL</td>
                        <td class="font_detail"> TRIP TIME & DATE</td>
                        <td class="font_detail"> TRIP CODE</td>
                        <td class="font_detail"> SHIP</td>
                        <td class="font_detail"> ROUTE</td>
                        <td class="font_detail"> DIRECTION</td>
                        <td class="font_detail"> DECK FARE</td>
                        <td class="font_detail"> STATUS</td>
                    </tr>
                </template>
                <template v-slot:body>
                    <tr v-for="(trip,index) in $store.getters.getAgentTrips" :key="trip._id">
                        <td class="font_detail">{{ index + 1 }}</td>
                        <td class="font_detail bold">{{ dateFormat(trip.tripDate) }}</td>
                        <td class="font_detail">{{ trip.tripCode }}</td>
                        <td class="font_detail bold">{{ trip.ship.name }}</td>
                        <td class="font_detail">{{ trip.route.name }}</td>
                        <td class="font_detail bold">{{ trip.direction }}<br>({{ trip.fromStoppage.name }} - {{
                                trip.toStoppage.name
                            }})
                        </td>
                        <td class="font_detail">{{ trip.deckFare }}</td>
                        <td><span class="badge" :class="'bg-'+dropdownVariables.tripStatus[trip.status].badge">{{
                                dropdownVariables.tripStatus[trip.status].value
                            }}</span>
                        </td>
                    </tr>
                </template>
                <template v-slot:pagination>
                    <pagination v-model="pagination.page" :records="$store.getters.getAgentTotalTrips"
                                :per-page="pagination.limit"
                                @paginate="getAllTrips" :options="options"/>
                </template>
            </Table>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import {reactive} from "@vue/reactivity";
import {inject, onBeforeMount, watch, computed} from "vue";
import store from "@/store";
import dropdownVariables from "@/modules/dropdownVariables";

export default {
    name: "Trips",
    setup() {

        let pagination = reactive({
            page: 1,
            limit: 10,
            from: "",
            to: "",
            ship: "",
            date: moment(new Date()).format('yyyy-MM-DD'),
            status: "",
        });


        const showLoader = inject('showLoader');
        const hideLoader = inject('hideLoader');

        const options = {
            texts: {
                count: ""
            }
        };


        watch(() => pagination.status, () => {
            getAllTrips()
        });
        watch(() => pagination.date, () => {
            getAllTrips()
        });


        const status = computed(() => {
            let allTripStatus = {}
            for (let [key, value] of Object.entries(dropdownVariables.tripStatus)) {
                allTripStatus[key] = value.value
            }
            return allTripStatus
        });

        const dateFormat = (date) => {
            return moment(String(date)).utcOffset(0, false).format('DD-MMM-yyyy hh:mm:ss A');
        };

        const getAllTrips = () => {
            showLoader()
            store.dispatch('getAllTripsForAgent', pagination).then(() => {
                hideLoader()
            }).catch((error) => {
                hideLoader()
                if (error.status === 401) {
                    getAllTrips()
                }
            });
        };

        onBeforeMount(() => {
            getAllTrips();

        });

        return {
            pagination, dropdownVariables, close, dateFormat, status, options,
            getAllTrips
        };
    }
}
</script>

<style scoped>
.bold {
    font-weight: bold;
}
</style>