<template>
  <div class="row m-0">
    <div class="col-md-10 col-xl-9 col-lg-9 order-md-1 order-lg-1 order-xl-1">
      <span class="fw-bold">TICKET INFORMATION</span>
      <div class="card card-body bg-dark border-3 rounded-3 border-secondary">
        <div class="row my-1">
          <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-4">
            <div class="row">
              <div
                  class="col-sm-4 col-md-4 col-lg-3 border-1 border_end text-end"
              >
                <span class="text-info ticket-info">PNR</span>
              </div>
              <div class="col-sm-8 col-md-8 col-lg-9 text-start">
                <span class="text-white ticket-info">{{
                    ticketVerified.pnrCode
                  }}</span>
              </div>
            </div>
            <div class="row mt-1">
              <div
                  class="col-sm-4 col-md-4 col-lg-3 border-1 border_end text-end"
              >
                <span class="text-info ticket-info">Trip Date</span>
              </div>
              <div class="col-sm-8 col-md-8 col-lg-9 text-start">
                <span class="orange-bold-text ticket-info">{{
                    dateFormat2(ticketVerified.tripDateTime)
                  }}</span>
              </div>
            </div>
            <div class="row mt-1">
              <div
                  class="col-sm-4 col-md-4 col-lg-3 border-1 border_end text-end"
              >
                <span class="text-info ticket-info">Boarding Point</span>
              </div>
              <div class="col-sm-8 col-md-8 col-lg-9 text-start">
                <span class="orange-bold-text ticket-info">{{
                    ticketVerified.boardingPlace
                  }}</span>
              </div>
            </div>
            <div class="row mt-1">
              <div
                  class="col-sm-4 col-md-4 col-lg-3 border-1 border_end text-end"
              >
                <span class="text-info ticket-info">Dropping Point</span>
              </div>
              <div class="col-sm-8 col-md-8 col-lg-9 text-start">
                <span class="orange-bold-text ticket-info">{{
                    ticketVerified.droppingPoint
                  }}</span>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-4">
            <div class="row">
              <div
                  class="col-sm-4 col-md-4 col-lg-3 border-1 border_end text-end"
              >
                <span class="text-info ticket-info">Seat Class</span>
              </div>
              <div class="col-sm-8 col-md-8 col-lg-9 text-start">
                <span class="orange-bold-text ticket-info">{{
                    ticketVerified.seatClassName
                  }}</span>
              </div>
            </div>
            <div class="row mt-1">
              <div
                  class="col-sm-4 col-md-4 col-lg-3 border-1 border_end text-end"
              >
                <span class="text-info ticket-info">Seat</span>
              </div>
              <div class="col-sm-8 col-md-8 col-lg-9 text-start">
                <span class="orange-bold-text ticket-info"
                >#{{ ticketVerified.seatNumbers }}</span
                >
              </div>
            </div>
            <div class="row mt-1">
              <div
                  class="col-sm-4 col-md-4 col-lg-3 border-1 border_end text-end"
              >
                <span class="text-info ticket-info">Amount</span>
              </div>
              <div class="col-sm-8 col-md-8 col-lg-9 text-start">
                <span class="text-white ticket-info">{{
                    ticketVerified.totalAmount
                  }}</span>
              </div>
            </div>
            <!-- <div class="row mt-1">
              <div
                class="col-sm-4 col-md-4 col-lg-3 border-1 border_end text-end"
              >
                <span class="text-info ticket-info">Discount</span>
              </div>
              <div class="col-sm-8 col-md-8 col-lg-9 text-start">
                <span class="text-white ticket-info">{{
                  ticketVerified.discount
                }}</span>
              </div>
            </div> -->
            <div class="row mt-1">
              <div
                  class="col-sm-4 col-md-4 col-lg-3 border-1 border_end text-end"
              >
                <span class="text-info ticket-info">Payable</span>
              </div>
              <div class="col-sm-8 col-md-8 col-lg-9 text-start">
                <span class="orange-bold-text ticket-info">{{
                    ticketVerified.payable
                  }}</span>
              </div>
            </div>
            <div class="row mt-1">
              <div
                  class="col-sm-4 col-md-4 col-lg-3 border-1 border_end text-end"
              >
                <span class="text-info ticket-info">Issued By</span>
              </div>
              <div class="col-sm-8 col-md-8 col-lg-9 text-start">
                <span class="text-white ticket-info">{{
                    ticketVerified.issuedBy
                  }}</span>
              </div>
            </div>
            <div class="row mt-1">
              <div
                  class="col-sm-4 col-md-4 col-lg-3 border-1 border_end text-end"
              >
                <span class="text-info ticket-info">Ticket Date</span>
              </div>
              <div class="col-sm-8 col-md-8 col-lg-9 text-start">
                <span class="text-white ticket-info">{{
                    dateFormat(ticketVerified.ticketDateTime)
                  }}</span>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-4">
            <div class="row">
              <div
                  class="col-sm-4 col-md-4 col-lg-3 border-1 border_end text-end"
              >
                <span class="text-info ticket-info">Name</span>
              </div>
              <div class="col-sm-8 col-md-8 col-lg-9 text-start">
                <span class="text-white ticket-info">{{
                    ticketVerified.passengerName
                  }}</span>
              </div>
            </div>
            <div class="row mt-1">
              <div
                  class="col-sm-4 col-md-4 col-lg-3 border-1 border_end text-end"
              >
                <span class="text-info ticket-info">Phone</span>
              </div>
              <div class="col-sm-8 col-md-8 col-lg-9 text-start">
                <span class="text-white ticket-info">{{
                    ticketVerified.passengerPhone
                  }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-2 col-lg-3 col-xl-3 order-md-2 order-lg-2 order-xl-2">
      <span class="fw-bold">ACTIONS</span>
      <div class="card card-body">
        <div class="row">
          <div class="col-md-12 col-lg-6 col-xl-6 mb-5 mb-lg-0">
            <p class="text-center font_detail m-0">GO BACK</p>
            <button class="cancel_button w-100" @click="goBack(ticketVerified.passengerPhone)">
              <i class="fas fa-arrow-left"></i>
            </button>
          </div>
          <div class="col-md-12 col-lg-6 col-xl-6">
            <p class="text-center font_detail m-0">PRINT</p>
            <p style="display: none" id="ticketVerified">
              {{ ticketVerified }}
            </p>
            <p style="display: none" id="type">{{ type }}</p>
            <p style="display: none" id="printCount">{{ printCount }}</p>

            <button
                class="confirm_button w-100"
                onclick="PrintAndroid(document.getElementById('ticketVerified').innerHTML,document.getElementById('type').innerHTML,document.getElementById('printCount').innerHTML)"
                @click="submitPrintCount(ticketVerified.passengerPhone)"
            >
              <i class="fas fa-print"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ref, onBeforeMount, watch,} from "vue";
import moment from "moment";
import store from "@/store";

export default {
  name: "TicketPrintPreview",

  setup(props, { emit }) {
    const printCount = ref(1);
    let reA = /[^a-zA-Z]/g;
    let reN = /[^0-9]/g;

    let ticketVerified = ref({
      seatNumbersForPrintArray: [],
      seatNumbersForPrint: "",
    });
    let type = ref("");

    const dateFormat = (date) => {
      return moment(date)
          .utcOffset(6, false)
          .format("DD-MMM-yyyy hh:mm:ss A");
    };

    const dateFormat2 = (date) => {
      return moment(date)
          .utcOffset(0, false)
          .format("DD-MMM-yyyy hh:mm:ss A");
    };

    watch(()=> store.getters.getTicketDetailsVerified, (value )=>{
      setSeatNumbersForPrint(value)
    }, )

    const submitPrintCount = (phone) => {
      goBack(phone);
    };
    const goBack = (phone) => {
      emit("close", phone);
    };

    const setSeatNumbersForPrint = (ticket) => {
      for (let index = 0; index < ticket.numberOfTicketCopy; index++) {
        ticketVerified.value.seatNumbersForPrintArray = [
          ...ticketVerified.value.seatNumbersForPrintArray,
          ...ticket.seatNumbers.split(","),
        ];
      }
      ticketVerified.value.seatNumbersForPrint =
          ticketVerified.value.seatNumbersForPrintArray.sort(sortAlphaNum).toString();
      delete ticketVerified.value.seatNumbersForPrintArray;

      ticketVerified.value = Object.assign(ticketVerified.value, ticket);
    };
    const sortAlphaNum = (a, b) => {
      let aA = a.replace(reA, "");
      let bA = b.replace(reA, "");
      if (aA === bA) {
        let aN = parseInt(a.replace(reN, ""), 10);
        let bN = parseInt(b.replace(reN, ""), 10);
        return aN === bN ? 0 : aN > bN ? 1 : -1;
      } else {
        return aA > bA ? 1 : -1;
      }
    };

    onBeforeMount(() => {
      setSeatNumbersForPrint(store.getters.getTicketDetailsVerified)
      ticketVerified.value = {...store.getters.getTicketDetailsVerified, ...ticketVerified.value}
      type.value = "ticket";
      printCount.value = 1;
    });
    return {
      ticketVerified,
      type,
      dateFormat,
      dateFormat2,
      submitPrintCount,
      printCount,
      goBack,
    };
  },
};
</script>

<style scoped>
.cancel_button {
  margin: 1px 2px;
  border: 2px solid #ff0000;
  border-radius: 4px;
  background-color: #ff0000;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.cancel_button:hover,
.cancel_button:focus {
  border: 2px solid #ff0000;
  background-color: #fff;
  color: #ff0000;
  transition: 0.4s;
  outline: 0;
}

.confirm_button {
  margin: 1px 2px;
  border: 2px solid #34a853;
  border-radius: 4px;
  background-color: #34a853;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.confirm_button:hover,
.confirm_button:focus {
  border: 2px solid #34a853;
  background-color: #fff;
  color: #34a853;
  transition: 0.4s;
  outline: 0;
}
</style>
