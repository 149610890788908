export default function useValidators() {
  const isEmpty = (fieldName, fieldValue) => {
    return !fieldValue || Object.keys(fieldValue).length === 0
      ? "The " + fieldName + " field is required"
      : "";
  };
  const isValidNumber = (fieldName, fieldValue) => {
    return parseInt(fieldValue) <= 0 ? "Must be a valid number" : "";
  };
  const isValidNumberWithZero = (fieldName, fieldValue) => {
    return parseInt(fieldValue) < 0 ? "Must be a valid number" : "";
  };
  const minLength = (fieldName, fieldValue, min) => {
    return fieldValue.length < min
      ? "The " +
          fieldName +
          " field must be atleast " +
          min +
          " characters long"
      : "";
  };
  const isEmail = (fieldName, fieldValue) => {
    const re = /\w+@\w+\.\w+/;
    return re.test(fieldValue) ? null : "Must be a valid email address";
  };
  const isPhone = (fieldName, fieldValue) => {
    const re = /^\+?[0-9]+$/;
    return re.test(fieldValue) ? null : "Must be a valid phone number";
  };
  const hasRole = (requiredRole, role, roleObject) => {
    return requiredRole && role
      ? Object.keys(roleObject).find(
          (key) => roleObject[key] === requiredRole
        ) +
          " Role required for " +
          Object.keys(roleObject).find((key) => roleObject[key] === role) +
          " Role"
      : "";
  };
  return {
    isEmpty,
    minLength,
    isEmail,
    isValidNumber,
    isValidNumberWithZero,
    isPhone,
    hasRole,
  };
}
