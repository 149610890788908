<template>
  <div v-if="reportActive">
    <FilterTabBetween>
      <template v-slot:body>
        <div class="col-sm-6 col-md-2">
          <DropdownFilter
            :id="'company'"
            :label="'Company'"
            :defaultOption="'--select company--'"
            v-model="counterwiseReport.companyId"
            :defaultValue="''"
            :dropdown="$store.getters.getCompanyList"
          />
        </div>
        <div v-if="counterwiseReport.companyId" class="col-sm-6 col-md-auto">
          <DropdownFilter
            :id="'ship'"
            :label="'Ship Name'"
            :defaultOption="'--select ship--'"
            v-model="counterwiseReport.shipId"
            :defaultValue="''"
            :dropdown="$store.getters.getCompanyInfo.ships"
          />
        </div>
        <div v-if="counterwiseReport.companyId" class="col-sm-6 col-md-auto">
          <DropdownFilter
            :id="'counter'"
            :label="'Counter'"
            :defaultOption="'--select counter--'"
            v-model="counterwiseReport.counterId"
            :defaultValue="''"
            :dropdown="$store.getters.getCompanyInfo.counters"
          />
        </div>
        <div v-if="counterwiseReport.companyId" class="col-sm-6 col-md-3">
          <DateTimeRangePicker v-model="date.range" />
        </div>

        <div v-if="counterwiseReport.companyId" class="col-sm-6 col-md-auto">
          <DropdownFilter
            :id="'status'"
            :label="'Status'"
            v-model="counterwiseReport.status"
            :dropdown="dropdownVariables.counterReportStatus"
          />
        </div>
        <div v-if="counterwiseReport.companyId" class="col-sm-6 col-md-auto">
          <DropdownFilter
            :id="'direction'"
            :label="'Direction'"
            :defaultOption="'--select direction--'"
            v-model="counterwiseReport.direction"
            :defaultValue="''"
            :dropdown="dropdownVariables.direction"
          />
        </div>
        <div v-if="counterwiseReport.companyId" class="col-sm-2 col-md-auto">
          <CheckboxSingle v-model="isDiscount" />
        </div>

        <div v-if="counterwiseReport.companyId" class="col-sm-2 col-md-auto">
          <CheckboxSingleInput v-model="ticketDateTime" />
        </div>
      </template>

      <template v-slot:export>
        <div class="d-flex justify-content-between gap-2">
          <div
            v-if="counterwiseReport.companyId"
            class="col-sm-2 col-md-auto mt-auto"
          >
            <button class="edit_table_data font_detail" @click="filterReport">
              <i class="fas fa-filter"></i><span class="mx-1 bold">Filter</span>
            </button>
          </div>
          
          <button
            class="edit_table_data font_detail"
            @click="
              exportExcel(
                'xlsx',
                'Admin_Counter_Wise_Report.',
                undefined,
                'exportable_data'
              )
            "
          >
            <i class="fas fa-download"></i><span class="mx-1 bold">Export</span>
          </button>
        </div>
      </template>
    </FilterTabBetween>
    <Table
      v-if="counterwiseReport.companyId !== '' && filterActive"
      id="exportable_data"
    >
      <template v-slot:header>
        <tr>
          <td class="font_detail">SL</td>
          <td class="font_detail">Counter Man</td>
          <td class="font_detail">Total Ticket</td>
          <td class="font_detail">{{discountStatus ? 'Discount Amount':'Total Amount'}}</td>
          <td class="font_detail">Details</td>
        </tr>
      </template>
      <template v-slot:body>
        <tr
          v-for="(item, index) in $store.getters.getCounterWiseReport"
          :key="item._id"
        >
          <td class="font_detail">{{ index + 1 }}</td>
          <td class="font_detail">{{ item.countermanName }}</td>
          <td class="font_detail">{{ item.totalTicket }}</td>
          <td class="font_detail">{{ item.totalAmount }} Taka</td>
          <td class="font_detail">
            <button
              class="btn btn-success m-1 btn-sm"
              @click="seatDetails(item.countermanId)"
            >
              Details
            </button>
          </td>
        </tr>
        <tr class="bg-dark text-white" v-if="summary">
          <td class="font_detail"></td>
          <td class="font_detail"></td>
          <td class="font_detail text-white">Total Ticket: {{ ticketTotal }}</td>
          <td class="font_detail text-white">Total Amount: {{ amountTotal }} Taka</td>
          <td class="font_detail"></td>
        </tr>
      </template>
    </Table>
  </div>
  <SeatDetails
    v-if="seatDetailsActive"
    :status="counterwiseReport.status"
    :direction="counterwiseReport.direction"
    :counterManId="counterManIdPass"
    :counterName="counterwiseReport.counterId"
    :shipName="counterwiseReport.shipId"
    :fromDate="date.range.start"
    :toDate="date.range.end"
    :discount="counterwiseReport.discount"
    :ticketDateTime="counterwiseReport.ticketDateTime"
    v-on:backToShipWiseReport="onChildClick"
  />

  <router-view></router-view>
</template>

<script>
import DateTimeRangePicker from "@/components/layouts/Filter/Inputs/DateTimeRangePicker";
import DropdownFilter from "@/components/layouts/Filter/Inputs/Dropdown";
import CheckboxSingle from "@/components/layouts/Inputs/CheckboxSingle.vue";
import CheckboxSingleInput from "@/components/layouts/Inputs/CheckboxSingleInput.vue";
import dropdownVariables from "@/modules/dropdownVariables";
import { reactive } from "@vue/reactivity";
import moment from "moment";
import { watch, onBeforeMount, ref, inject } from "vue";
import store from "@/store";
import SeatDetails from "./CounterSeatDetails.vue";

export default {
  name: "CounterReport",
  components: {
    DateTimeRangePicker,
    DropdownFilter,
    SeatDetails,
    CheckboxSingle,
    CheckboxSingleInput
  },

  setup() {
    let deckDetailsActive = ref(false);
    let seatDetailsActive = ref(false);
    let reportActive = ref(true);
    let counterManIdPass = ref("");
    let filterActive = ref(false);

    let ticketTotal = ref(0);
    let amountTotal = ref(0);
    let seatTicketTotal = ref(0);
    let seatAmountTotal = ref(0);
    let isDiscount = ref(false);
    let discountStatus = ref(false);
    let ticketDateTime = ref(false);
    let summary = ref(false);
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const exportExcel = inject("exportExcel");

    const fromDate = moment(new Date()).format("yyyy-MM-DD" + " 00:00:00");
    const toDate = moment(new Date()).format("yyyy-MM-DD" + " 23:59:59");

    let date = reactive({
      range: {
        start: fromDate,
        end: toDate,
      },
    });

    let counterwiseReport = reactive({
      companyId: "",
      counterId: "",
      shipId: "",
      countermanId: "",
      fromDate: "",
      toDate: "",
      status: "confirmed",
      direction: "",
      discount: "",
      ticketDateTime: "",
      page: 1,
      limit: 10,
    });

    watch(
      () => isDiscount.value,
      () => {
        isDiscount.value
          ? (counterwiseReport.discount = 1)
          : (counterwiseReport.discount = "");
      }
    );

    watch(
      () => ticketDateTime.value,
      () => {
        ticketDateTime.value
          ? (counterwiseReport.ticketDateTime = 1)
          : (counterwiseReport.ticketDateTime = "");
      }
    );

    watch(
      () => counterwiseReport.companyId,
      (value) => {
        counterwiseReport.companyId = value;
      }
    );
    watch(
      () => counterwiseReport.counterId,
      (value) => {
        counterwiseReport.counterId = value;
      }
    );
    watch(
      () => counterwiseReport.shipId,
      (value) => {
        counterwiseReport.shipId = value;
      }
    );
    watch(
      () => counterwiseReport.status,
      (value) => {
        counterwiseReport.status = value;
      }
    );
    watch(
      () => counterwiseReport.direction,
      (value) => {
        counterwiseReport.direction = value;
      }
    );
    watch(
      () => date.range.start,
      (value) => {
        counterwiseReport.fromDate = value
        // counterwiseReport.fromDate = moment(value).format(
        //   "yyyy-MM-DD" + " 00:00:00"
        // );
      }
    );
    watch(
      () => date.range.end,
      (value) => {
        counterwiseReport.toDate = value
        // counterwiseReport.toDate = moment(value).format(
        //   "yyyy-MM-DD" + " 23:59:59"
        // );
      }
    );
    watch(
      () => counterwiseReport.companyId,
      () => {
        if (counterwiseReport.companyId !== "") {
          getCompanyInformation();
        }
      }
    );

    const getCompanyInformation = () => {
      showLoader();
      store
        .dispatch("getCompanyInfo", {
          companyId: counterwiseReport.companyId,
        })
        .then(() => hideLoader())
        .catch(() => hideLoader());
    };

    const getAllCompanyList = () => {
      showLoader();
      store
        .dispatch("getAllCompanyList")
        .then(() => hideLoader())
        .catch(() => hideLoader());
    };
    // const getAllShipReport = () => {
    //   store.dispatch("getAllShipReport", counterwiseReport);
    // };
    const filterReport = () => {
      showLoader();
      filterActive.value = true;
      store
        .dispatch("getfilterCounterWiseReport", counterwiseReport)
        .then((response) => {
          if(isDiscount.value == 1){
            discountStatus.value = true
          } else {
            discountStatus.value = false
          }
          if (response.length === 0) {
            summary.value = false;
          } else {
            summary.value = true;
            ticketTotal.value = 0;
            amountTotal.value = 0;
            sumListData();
          }
          hideLoader();
        })
        .catch(() => hideLoader());
      // getAllShipReport();
    };

    const sumListData = () => {
      store.getters.getCounterWiseReport.forEach((data) => {
        ticketTotal.value += data.totalTicket;
        amountTotal.value += data.totalAmount;
      });
    };

    onBeforeMount(() => {
      getAllCompanyList();
      counterwiseReport.fromDate = fromDate;
      counterwiseReport.toDate = toDate;
    });

    const seatDetails = (counterManId) => {
      counterManIdPass.value = counterManId;
      deckDetailsActive.value = false;
      reportActive.value = false;
      seatDetailsActive.value = true;
    };

    const onChildClick = (value) => {
      reportActive.value = value;
      deckDetailsActive.value = !value;
      seatDetailsActive.value = !value;
    };

    return {
      dropdownVariables,
      date,
      counterwiseReport,
      filterReport,
      deckDetailsActive,
      seatDetailsActive,
      seatDetails,
      reportActive,
      counterManIdPass,
      filterActive,
      onChildClick,
      ticketTotal,
      amountTotal,
      seatTicketTotal,
      seatAmountTotal,
      exportExcel,
      summary,
      isDiscount,
      discountStatus,
      ticketDateTime
    };
  },
};
</script>

<style scoped></style>
