import permission from "@/modules/permission";
const {permittedRoute,permissionGranted} = permission();

export default function hasAccess({to, next}) {
    if (!permissionGranted(to.name)) {
        return next({
            name: permittedRoute()
        });
    }
    return next();
}