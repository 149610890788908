<template>
  <Modal @close="closeModal" v-bind:size="'xl'">
    <template v-slot:title>{{ title }}</template>
    <template v-slot:body>
      <form>
        <div class="auth_form py-3">
          <div class="row my-2">
            <div class="col-md-3">
              <Input
                v-model="companyData.name"
                v-bind:placeholder="'Enter Company Name'"
                v-bind:label="'Name'"
                v-bind:id="'name'"
                v-bind:type="'text'"
              />
              <Error v-if="errors.name" v-bind:error="errors.name" />
            </div>
            <div class="col-md-3">
              <Input
                v-model="companyData.contactPerson"
                v-bind:placeholder="'Enter Contact Person'"
                v-bind:label="'Contact Person'"
                v-bind:id="'contactPerson'"
                v-bind:type="'text'"
              />
            </div>
            <div class="col-md-3">
              <Input
                v-model="companyData.phone"
                v-bind:placeholder="'Enter Complain Number'"
                v-bind:label="'Complain Number'"
                v-bind:id="'phone'"
                v-bind:type="'text'"
              />
            </div>
            <div class="col-md-3">
              <Input
                v-model="companyData.maxSeatPerTicket"
                v-bind:placeholder="'Enter Phone Number'"
                v-bind:label="'Max seat per ticket'"
                v-bind:id="'maxSeatPerTicket'"
                v-bind:type="'number'"
              />
              <Error
                v-if="errors.maxSeatPerTicket"
                v-bind:error="errors.maxSeatPerTicket"
              />
            </div>
          </div>

          <div class="row my-2">
            <div class="col-md-3">
              <Input
                v-model="companyData.ticketBlockHour"
                v-bind:placeholder="'Enter Phone Number'"
                v-bind:label="'User Ticket Block Before (hour)'"
                v-bind:id="'ticketBlockHour'"
                v-bind:type="'number'"
              />
              <Error
                v-if="errors.ticketBlockHour"
                v-bind:error="errors.ticketBlockHour"
              />
            </div>

            <div class="col-md-3">
              <Dropdown
                v-if="status"
                v-model="companyData.status"
                v-bind:id="'status'"
                v-bind:label="'Status'"
                v-bind:dropdown="status"
              />
            </div>
            <div class="col-md-3">
              <Dropdown
                v-if="reservation"
                v-model="companyData.reservation"
                v-bind:id="'reservation'"
                v-bind:label="'Reservation'"
                v-bind:dropdown="reservation"
              />
            </div>
            <div class="col-md-3">
              <Input
                  v-model="companyData.countermanTicketBlockHour"
                  v-bind:placeholder="'Enter block hour'"
                  v-bind:label="'Counterman Ticket Block Before(Hr)'"
                  v-bind:id="'countermanTicketBlockHour'"
                  v-bind:type="'number'"
              />

            </div>
          </div>

          <div class="row my-2">
            <hr />
            <h6 class="mb-3">Comission Calculation</h6>

            <div class="row">
              <div class="col-md-2">Seat Tickets:</div>
              <div class="col-md-5">
                <Dropdown
                  v-if="comissionBase"
                  v-model="companyData.seatCommissionBase"
                  v-bind:id="'seatcommissionbase'"
                  v-bind:label="'Comission Base'"
                  v-bind:dropdown="comissionBase"
                />
                <Error
                  v-if="errors.seatCommissionBase"
                  v-bind:error="errors.seatCommissionBase"
                />
              </div>
              <div class="col-md-5">
                <Input
                  v-model="companyData.seatCommissionRate"
                  v-bind:placeholder="'Enter Comission'"
                  v-bind:label="'Comission Rate'"
                  v-bind:id="'seatcommissionRate'"
                  v-bind:type="'number'"
                  :disabled="seatComissionInputDisability"
                />
                <Error
                  v-if="errors.seatCommissionRate"
                  v-bind:error="errors.seatCommissionRate"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-2">Deck Tickets:</div>
              <div class="col-md-5">
                <Dropdown
                  v-if="comissionBase"
                  v-model="companyData.deckCommissionBase"
                  v-bind:id="'deckcommissionbase'"
                  v-bind:label="'Comission Base'"
                  v-bind:dropdown="comissionBase"
                />
                <Error
                  v-if="errors.deckCommissionBase"
                  v-bind:error="errors.deckCommissionBase"
                />
              </div>
              <div class="col-md-5">
                <Input
                  v-model="companyData.deckCommissionRate"
                  v-bind:placeholder="'Enter Comission'"
                  v-bind:label="'Comission Rate'"
                  v-bind:id="'deckcommissionamount'"
                  v-bind:type="'number'"
                  :disabled="deckComissionInputDisability"
                />
                <Error
                  v-if="errors.deckCommissionRate"
                  v-bind:error="errors.deckCommissionRate"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-2">Goods Tickets:</div>
              <div class="col-md-5">
                <Dropdown
                  v-if="comissionBase"
                  v-model="companyData.goodsCommissionBase"
                  v-bind:id="'goodscommissionbase'"
                  v-bind:label="'Comission Base'"
                  v-bind:dropdown="comissionBase"
                />
                <Error
                  v-if="errors.goodsCommissionBase"
                  v-bind:error="errors.goodsCommissionBase"
                />
              </div>
              <div class="col-md-5">
                <Input
                  v-model="companyData.goodsCommissionRate"
                  v-bind:placeholder="'Enter Comission'"
                  v-bind:label="'Comission Rate'"
                  v-bind:id="'goodscommissionamount'"
                  v-bind:type="'number'"
                  :disabled="goodsComissionInputDisability"
                />
                <Error
                  v-if="errors.goodsCommissionRate"
                  v-bind:error="errors.goodsCommissionRate"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-2">Digital Tickets:</div>
              <div class="col-md-5">
                <Dropdown
                  v-if="comissionBase"
                  v-model="companyData.digitalTicketingCommissionBase"
                  v-bind:id="'digitalTicketingcommissionbase'"
                  v-bind:label="'Comission Base'"
                  v-bind:dropdown="comissionBase"
                />
                <Error
                  v-if="errors.digitalTicketingCommissionBase"
                  v-bind:error="errors.digitalTicketingCommissionBase"
                />
              </div>
              <div class="col-md-5">
                <Input
                  v-model="companyData.digitalTicketingCommissionRate"
                  v-bind:placeholder="'Enter Comission'"
                  v-bind:label="'Comission Rate'"
                  v-bind:id="'digitalTicketingcommissionamount'"
                  v-bind:type="'number'"
                  :disabled="digitalTicketingComissionInputDisability"
                />
                <Error
                  v-if="errors.digitalTicketingCommissionRate"
                  v-bind:error="errors.digitalTicketingCommissionRate"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-2">Payment Gateway:</div>
              <div class="col-md-5">
                <Input
                  v-model="companyData.paymentGatewayRate"
                  v-bind:placeholder="'Enter Charge'"
                  v-bind:label="'Charge'"
                  v-bind:id="'paymentGatewayRate'"
                  v-bind:type="'number'"
                />
                <Error
                  v-if="errors.paymentGatewayRate"
                  v-bind:error="errors.paymentGatewayRate"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="form-group row my-1">
          <label class="col-sm-3 col-form-label"></label>
          <div class="col-sm-9">
            <input
              v-if="!companyId"
              type="submit"
              class="mt-3 py-2 submit_button font_detail float-end"
              @click.prevent="addCompany"
            />
            <input
              v-else
              type="submit"
              class="mt-3 py-2 submit_button font_detail float-end"
              @click.prevent="updateCompany"
            />
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
import { reactive } from "@vue/reactivity";
import dropdownVariables from "@/modules/dropdownVariables";
import useInputValidation from "@/modules/useInputValidations";
import { inject, onBeforeMount, ref, watch, computed } from "vue";
import store from "@/store";

export default {
  name: "CompanyAddUpdateModal",
  props: {
    modalValue: Boolean,
    company: String,
  },
  setup(props, { emit }) {
    let companyData = reactive({
      id: "",
      name: "",
      contactPerson: "",
      phone: "",
      logo: "",
      maxSeatPerTicket: 1,
      ticketBlockHour: 0,
      countermanTicketBlockHour: 0,
      allowDiscount: 0,
      allowTotalPaidEdit: 0,
      allowPandemicFares: 0,
      // reportByIndividualSeat: 0,
      // individualTicketPrint: 0,
      status: 1,
      reservation: 1,
      deckCommissionBase: "PENDING",
      deckCommissionRate: "0",
      goodsCommissionBase: "PENDING",
      goodsCommissionRate: "0",
      seatCommissionBase: "PENDING",
      seatCommissionRate: "0",
      digitalTicketingCommissionBase: "PENDING",
      digitalTicketingCommissionRate: "0",
      paymentGatewayRate: "0",
    });

    let companyId = ref(props.company);
    let { requiredValidation, requiredValidationNumber, errors } =
      useInputValidation();
    const title = ref("Add Company");
    const boolean = dropdownVariables.boolean;
    // const reportByIndividualSeat = dropdownVariables.reportByIndividualSeat;
    // const individualTicketPrint = dropdownVariables.individualTicketPrint;
    const status = dropdownVariables.status;
    const reservation = dropdownVariables.reservation;
    const printerType = dropdownVariables.printerType;
    const comissionBase = dropdownVariables.comissionBase;
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");

    watch(
      () => companyData.name,
      () => {
        requiredValidation("name", companyData.name);
      }
    );
    watch(
      () => companyData.maxSeatPerTicket,
      () => {
        requiredValidation(
          "maxSeatPerTicket",
          companyData.maxSeatPerTicket.toString()
        );
      }
    );
    // watch(
    //   () => companyData.printerType,
    //   () => {
    //     requiredValidation("printerType", companyData.printerType);
    //   }
    // );

    watch(
      () => companyData.seatCommissionRate,
      () => {
        requiredValidationNumber(
          "seatCommissionRate",
          companyData.seatCommissionRate
        );
      }
    );
    watch(
      () => companyData.deckCommissionRate,
      () => {
        requiredValidationNumber(
          "deckCommissionRate",
          companyData.deckCommissionRate
        );
      }
    );
    watch(
      () => companyData.goodsCommissionRate,
      () => {
        requiredValidationNumber(
          "goodsCommissionRate",
          companyData.goodsCommissionRate
        );
      }
    );
    watch(
      () => companyData.digitalTicketingCommissionRate,
      () => {
        requiredValidationNumber(
          "digitalTicketingCommissionRate",
          companyData.digitalTicketingCommissionRate
        );
      }
    );

    watch(
      () => companyData.seatCommissionBase,
      (data) => {
        if (data === "PENDING") {
          companyData.seatCommissionRate = 0;
        }
      }
    );
    watch(
      () => companyData.deckCommissionBase,
      (data) => {
        if (data === "PENDING") {
          companyData.deckCommissionRate = 0;
        }
      }
    );
    watch(
      () => companyData.goodsCommissionBase,
      (data) => {
        if (data === "PENDING") {
          companyData.goodsCommissionRate = 0;
        }
      }
    );
    watch(
      () => companyData.digitalTicketingCommissionBase,
      (data) => {
        if (data === "PENDING") {
          companyData.digitalTicketingCommissionRate = 0;
        }
      }
    );

    const seatComissionInputDisability = computed(() => {
      return companyData.seatCommissionBase === "PENDING" ? true : false;
    });
    const deckComissionInputDisability = computed(() => {
      return companyData.deckCommissionBase === "PENDING" ? true : false;
    });
    const goodsComissionInputDisability = computed(() => {
      return companyData.goodsCommissionBase === "PENDING" ? true : false;
    });
    const digitalTicketingComissionInputDisability = computed(() => {
      return companyData.digitalTicketingCommissionBase === "PENDING"
        ? true
        : false;
    });

    const closeModal = () => {
      emit("update:modelValue", false);
    };
    const validate = () => {
      Object.keys(errors).forEach(function (key) {
        errors[key] = "";
      });
      requiredValidation("name", companyData.name);
      requiredValidation(
        "maxSeatPerTicket",
        companyData.maxSeatPerTicket.toString()
      );
      requiredValidation("seatCommissionBase", companyData.seatCommissionBase);
      requiredValidationNumber(
        "seatCommissionRate",
        companyData.seatCommissionRate
      );
      requiredValidation("deckCommissionBase", companyData.deckCommissionBase);
      requiredValidationNumber(
        "deckCommissionRate",
        companyData.deckCommissionRate
      );
      requiredValidation(
        "goodsCommissionBase",
        companyData.goodsCommissionBase
      );
      requiredValidationNumber(
        "goodsCommissionRate",
        companyData.goodsCommissionRate
      );
      requiredValidation(
        "digitalTicketingCommissionBase",
        companyData.digitalTicketingCommissionBase
      );
      requiredValidationNumber(
        "digitalTicketingCommissionRate",
        companyData.digitalTicketingCommissionRate
      );

      return !(
        Object.values(errors).length <= 0 ||
        !Object.values(errors).every((x) => x === null || x === "")
      );
    };
    const addCompany = () => {
      if (validate()) {
        showLoader();
        store
          .dispatch("addCompany", companyData)
          .then(() => {
            hideLoader();
            emit("getAllCompanies");
            closeModal();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              addCompany();
            }
          });
      }
    };
    const updateCompany = () => {
      if (validate()) {
        showLoader();
        store
          .dispatch("updateCompany", companyData)
          .then(() => {
            hideLoader();
            emit("getAllCompanies");
            closeModal();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              updateCompany();
            }
          });
      }
    };

    onBeforeMount(() => {
      Object.keys(errors).forEach(function (key) {
        delete errors[key];
      });

      if (companyId.value !== "") {
        const companyDetails = store.getters.getCompany(companyId.value);
        title.value = "Update Company";
        companyData.id = companyDetails[0]._id;
        companyData.name = companyDetails[0].name;
        companyData.contactPerson = companyDetails[0].contactPerson;
        companyData.phone = companyDetails[0].phone;
        companyData.logo = companyDetails[0].logo;
        // companyData.printerType = companyDetails[0].printerType;
        companyData.maxSeatPerTicket = companyDetails[0].maxSeatPerTicket;
        companyData.ticketBlockHour = companyDetails[0].ticketBlockHour;
        companyData.countermanTicketBlockHour = companyDetails[0].countermanTicketBlockHour;
        // companyData.reportByIndividualSeat = companyDetails[0]
        //     .reportByIndividualSeat
        //     ? 1
        //     : 0;
        // companyData.individualTicketPrint = companyDetails[0]
        //     .individualTicketPrint
        //     ? 1
        //     : 0;
        companyData.allowDiscount = companyDetails[0].allowDiscount ? 1 : 0;
        companyData.allowTotalPaidEdit = companyDetails[0].allowTotalPaidEdit
          ? 1
          : 0;
        companyData.allowPandemicFares = companyDetails[0].allowPandemicFares
          ? 1
          : 0;
        companyData.status = companyDetails[0].status ? 1 : 0;
        companyData.reservation = companyDetails[0].reservation ? 1 : 0;
        (companyData.deckCommissionBase =
          companyDetails[0]?.deckCommissionBase || "PENDING"),
          (companyData.deckCommissionRate =
            companyDetails[0]?.deckCommissionRate || 0),
          (companyData.goodsCommissionBase =
            companyDetails[0]?.goodsCommissionBase || "PENDING"),
          (companyData.goodsCommissionRate =
            companyDetails[0]?.goodsCommissionRate || 0),
          (companyData.seatCommissionBase =
            companyDetails[0]?.seatCommissionBase || "PENDING");
        companyData.seatCommissionRate =
          companyDetails[0]?.seatCommissionRate || 0;
        companyData.digitalTicketingCommissionBase =
          companyDetails[0]?.digitalTicketingCommissionBase || "PENDING";
        companyData.digitalTicketingCommissionRate =
          companyDetails[0]?.digitalTicketingCommissionRate || 0;
        companyData.paymentGatewayRate =
          companyDetails[0]?.paymentGatewayRate || 0;
      }
    });

    return {
      companyData,
      status,
      reservation,
      title,
      errors,
      companyId,
      boolean,
      printerType,
      addCompany,
      updateCompany,
      closeModal,
      comissionBase,
      seatComissionInputDisability,
      deckComissionInputDisability,
      goodsComissionInputDisability,
      digitalTicketingComissionInputDisability,
    };
  },
};
</script>

<style scoped></style>
