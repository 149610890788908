// import validationMessage from "@/modules/ValidationMessage"
import apiResponse from "@/modules/apiResponse";
import toast from "@/modules/Toast";
import tokenCookie from "@/modules/tokenCookie";
import Apis from "@/urls/apis";
import axios from "axios";

const { successToast } = toast();
const { hasValidToken, getToken } = tokenCookie();
// const {messageExtractor} = validationMessage();
const { errorResponse } = apiResponse();

const state = {
    filter: {
        stoppage: {},
        ship: {},
        route: {},
        counter: {},
    },
    trips: [],
    tickets: {},
    reservedTickets: {},
    reservedTicketsSeatclassName: {},
    reservedTicket: {},
    reservedTicketKeyStatus: {}
};
const getters = {
    getReservedTicketsData: (state) => state.reservedTicket,

    getCountermanStoppageListForFilter: (state) => state.filter.stoppage,
    getCountermanShipListForFilter: (state) => state.filter.ship,
    getCountermanRouteListForFilter: (state) => state.filter.route,

    getTicketsHistoryCounter: (state) => state.tickets.docs,
    getReservedTicketsHistoryCounter: (state) => state.reservedTickets.docs,
    getReservedTicketsSeatclassName: (state) => state.reservedTicketsSeatclassName.seatClassName,
    getTotalTicketsHistoyCounter: (state) => state.tickets.totalDocs,
    getTotalReservedTicketsHistoyCounter: (state) => state.reservedTickets.totalDocs,
    getTotalReservedTicketKeyStatus: (state) => state.reservedTicketKeyStatus.keyStatus,
    getTripsForTicketing: (state) => state.trips,
    getTotalTripsForTicketing: (state) => state.trips.totalDocs,
    getTripForTicketing: (state) => (id) => state.trips.filter(function (trip) {
        return trip._id === id;
    }),
};
const actions = {
    async getStoppageListForCounterman({ commit }, payload) {
        if (await hasValidToken()) {
            commit("setStoppageListInFilter", {});
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };

            return new Promise((resolve, reject) => {
                axios.get(Apis.GET_ALL_COUNTERMAN_STOPPAGES, {
                    params: payload,
                    headers: header
                })
                    .then(({ data, status }) => {
                        if (status === 200) {
                            let stoppageList = {}
                            data.stoppage.forEach(stoppage => {
                                stoppageList[stoppage._id] = stoppage.name;
                            });
                            commit("setStoppageListInFilter", stoppageList)
                            resolve(data.stoppage);
                        }
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async getRouteListForCounterman({ commit }, payload) {
        if (await hasValidToken()) {
            commit("setRouteListInFilter", {});
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };

            return new Promise((resolve, reject) => {
                axios.get(Apis.GET_ALL_COUNTERMAN_ROUTES, {
                    params: payload,
                    headers: header
                })
                    .then(({ data, status }) => {
                        if (status === 200) {
                            let routeList = {}
                            data.route.forEach(route => {
                                routeList[route._id] = route.name;
                            });
                            commit("setRouteListInFilter", routeList)
                            resolve(data.route);
                        }
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async getCounterListForCounterman({ commit }, payload) {
        if (await hasValidToken()) {
            commit("setCounterListInFilter", {});
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };

            return new Promise((resolve, reject) => {
                axios.get(Apis.GET_ALL_COUNTERMAN_COUNTERS, {
                    params: payload,
                    headers: header
                })
                    .then(({ data, status }) => {
                        if (status === 200) {
                            let counterList = {}
                            data.counter.forEach(counter => {
                                counterList[counter._id] = counter.name;
                            });
                            commit("setCounterListInFilter", counterList)
                            resolve(data.counter);
                        }
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async getShipListForCounterman({ commit }, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };

            return new Promise((resolve, reject) => {
                axios.get(Apis.GET_ALL_COUNTERMAN_SHIPS, {
                    params: payload,
                    headers: header
                })
                    .then(({ data, status }) => {
                        if (status === 200) {
                            let shipList = {}
                            data.ship.forEach(ship => {
                                shipList[ship._id] = ship.name;
                            });
                            commit("setShipListInFilter", shipList)
                            resolve(data.ship);
                        }
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async getTicketsHistory({ commit }, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };

            return new Promise((resolve, reject) => {
                axios.get(Apis.TICKETS_HISTORY_COUNTER, {
                    params: payload,
                    headers: header
                })
                    .then(({ data, status }) => {
                        if (status === 200) {
                            commit("setTicketsHistory", data.ticket)
                            resolve(data.ticket)
                        }
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async getReservedTicketsHistory({ commit }, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };

            return new Promise((resolve, reject) => {
                axios.get(Apis.RESERVED_TICKETS_HISTORY_COUNTER, {
                    params: payload,
                    headers: header
                })
                    .then(({ data, status }) => {
                        if (status === 200) {
                            commit("setReservedTicketsHistory", data.ticket)
                            commit("setReservedTicketsSeatClassName", data.seatClassName)
                            resolve(data.ticket)
                        }
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async ticketDelete(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.post(Apis.TICKET_CANCEL, payload, { headers: header })
                    .then((response) => {
                        successToast('Ticket Cancelled Successfully')
                        resolve(response)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async reserveTicketDelete(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.post(Apis.RESERVE_TICKET_CANCEL, payload, { headers: header })
                    .then((response) => {
                        successToast('Reserved Ticket Cancelled Successfully')
                        resolve(response)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async reserveTicketConfirm({ commit }, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.post(Apis.RESERVE_TICKET_CONFIRM, payload, { headers: header })
                    .then((response) => {
                        successToast('Reserved Ticket Confirmed Successfully')
                        commit("setReservedTicketData", response.data.ticket)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async confirmPassengerTicket(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.post(Apis.TICKET_PURCHASE, payload, { headers: header })
                    .then((response) => {
                        successToast('Ticket Purchased Successfully')
                        resolve(response.data.ticket)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async reservePassengerTicket(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.post(Apis.TICKET_RESERVE, payload, { headers: header })
                    .then((response) => {
                        successToast('Ticket Reserve Successfully')
                        resolve(response.data.ticket)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async getActiveTripsForTicketing({ commit }, payload) {
        if (await hasValidToken()) {
            commit("setTrips", []);
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.get(Apis.GET_ALL_TRIPS_FOR_TICKETING, {
                    params: payload,
                    headers: header
                })
                    .then(({ data, status }) => {
                        if (status === 200) {
                            commit("setTrips", data.trip);
                            resolve({ 'success': 'success' });
                        }
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async getReservationTicketKey({ commit }, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.post(Apis.GET_RESERVATION_KEY, payload, { headers: header })
                .then(({ data, status }) => {
                    if (status === 200) {
                        commit("setKeyData", data);
                        resolve({ 'success': 'success' });
                    }
                })
                .catch(error => {
                    reject(errorResponse(error))
                });
            });
        }
    },

    async getCountermanLastTicket(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.get(Apis.GET_LAST_TICKET, {
                    params: payload,
                    headers: header
                })
                    .then(({ data, status }) => {
                        if (status === 200) {
                            resolve(data.ticket);
                        }
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },
    async ticketPrintCount(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.post(Apis.TICKET_PRINT_COUNT, payload, { headers: header })
                    .then((response) => {
                        console.log("response.data.ticket==>", response.data.ticket);
                        resolve(response.data.ticket)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },
    async reportPrintCount(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.post(Apis.REPORT_PRINT_COUNT, payload, { headers: header })
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },
    async reportPrintCountIncrease(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.post(Apis.REPORT_PRINT_COUNT_INCREASE, payload, { headers: header })
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },
    async verifyOnlineTicketList(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.post(Apis.VERIFY_TICKET_LIST, payload, { headers: header })
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },
};
const mutations = {
    setSeatNumbersForPrint: (state, seatNumbers) => (state.reservedTicket.seatNumbersForPrint = seatNumbers),
    setReservedTicketData: (state, ticket) => (state.reservedTicket = ticket),
    setStoppageListInFilter: (state, stoppageList) => (state.filter.stoppage = stoppageList),
    setShipListInFilter: (state, shipList) => (state.filter.ship = shipList),
    setRouteListInFilter: (state, routeList) => (state.filter.route = routeList),
    setCounterListInFilter: (state, counterList) => (state.filter.counter = counterList),
    setTicketsHistory: (state, tickets) => (state.tickets = tickets),
    setReservedTicketsHistory: (state, reservedTickets) => (state.reservedTickets = reservedTickets),
    setReservedTicketsSeatClassName: (state, reservedTicketsSeatclassName) => (state.reservedTicketsSeatclassName = reservedTicketsSeatclassName),
    // setReservedTicketSearch: (state, reservedTickets) => (state.reservedTickets.docs = reservedTickets, console.log("commitData=>",reservedTickets)),
    setTrips: (state, trips) => (state.trips = trips),
    setKeyData: (state, status) => (state.reservedTicketKeyStatus = status),
};

export default {
    state,
    getters,
    actions,
    mutations
};