<template>
  <Modal @close="closeModal">
    <template v-slot:title>{{ title }}</template>
    <template v-slot:body>
      <form>
        <div class="form-group row my-1">
          <ModalInput v-model="counterData.name"
                 v-bind:placeholder="'Enter Name'"
                 v-bind:label="'Name'" v-bind:id="'name'" v-bind:type="'text'">
            <template v-slot:error>
              <ModalError v-if="errors.name" v-bind:error="errors.name"/>
            </template>
          </ModalInput>
        </div>

        <div class="form-group row my-1">
          <ModalDropdown v-if="$store.getters.getStoppageListForFilter" v-model="counterData.preferredStoppage"
                    v-bind:id="'stoppageId'"
                    v-bind:label="'Preferred'"
                    v-bind:dropdown="$store.getters.getStoppageListForFilter"
                    v-bind:defaultOption="'--select preferred stoppage--'"
                    v-bind:defaultValue="''">
            <template v-slot:error>
              <ModalError v-if="errors.preferredStoppage" v-bind:error="errors.preferredStoppage"/>
            </template>
          </ModalDropdown>
        </div>

        <div class="form-group row my-1">
          <ModalDropdown v-if="status" v-model="counterData.status" v-bind:id="'status'" v-bind:label="'Status'"
                    v-bind:dropdown="status"></ModalDropdown>
        </div>

        <div class="form-group row my-1">
          <label class="col-sm-3 col-form-label"></label>
          <div class="col-sm-9">
            <input v-if="!counterId" type="submit" class="mt-3 py-2 submit_button font_detail float-end"
                   @click.prevent="addStoppage"/>
            <input v-else type="submit" class="mt-3 py-2 submit_button font_detail float-end"
                   @click.prevent="updateStoppage"/>
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
import dropdownVariables from "@/modules/dropdownVariables";
import useInputValidation from "@/modules/useInputValidations";
import {inject, onBeforeMount, ref, watch} from "vue";
import {reactive} from "@vue/reactivity";
import store from "@/store";

export default {
  name: "CounterAddUpdateModal",
  props: {
    counter: String,
    company: String,
  },
  setup(props, {emit}) {
    let counterData = reactive({
      id: "",
      companyId: "",
      preferredStoppage: "",
      name: "",
      status: 1
    });
    let {requiredValidation, errors} = useInputValidation();
    const counterId = ref(props.counter);
    const companyId = ref(props.company);
    const status = dropdownVariables.status;
    const title = ref("Add Counter");
    const showLoader = inject('showLoader');
    const hideLoader = inject('hideLoader');

    watch(() => counterData.name, () => {
      requiredValidation("name", counterData.name);
    });

    const validate = () => {
      Object.keys(errors).forEach(function (key) {
        errors[key] = ""
      });
      requiredValidation("name", counterData.name);
      return !(Object.values(errors).length <= 0 || !Object.values(errors).every(x => x === null || x === ''));
    };
    const closeModal = () => {
      emit("update:modelValue", false);
    };
    const addStoppage = () => {
      if (validate()) {
        showLoader()
        store.dispatch("addCounter", counterData).then(() => {
          hideLoader()
          emit('getAllCounters');
          closeModal();
        }).catch((error) => {
          hideLoader()
          if (error.status === 401) {
            addStoppage()
          }
        });

      }
    };
    const updateStoppage = () => {
      if (validate()) {
        showLoader()
        store.dispatch("updateCounter", counterData).then(() => {
          hideLoader()
          emit('getAllCounters');
          closeModal();
        }).catch((error) => {
          hideLoader()
          if (error.status === 401) {
            updateStoppage()
          }
        });

      }
    };

    onBeforeMount(() => {
      Object.keys(errors).forEach(function (key) {
        delete errors[key];
      });
      if (counterId.value !== "") {
        const counterDetails = store.getters.getCounter(counterId.value);
        title.value = "Update Counter";
        counterData.id = counterDetails[0]._id;
        counterData.name = counterDetails[0].name;
          counterData.preferredStoppage = counterDetails[0].preferredStoppage
          counterData.status = counterDetails[0].status ? 1 : 0;
      }
      counterData.companyId = companyId.value;
    });

    return {
      counterId, counterData, errors, status, title,
      closeModal, addStoppage, updateStoppage
    };
  }
};
</script>

<style scoped>

</style>