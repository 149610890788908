<template>
  <li v-for="(plan,index) in seatLists" :key="spId+'plan'+index" class="list-group-item">
    <div class="row my-2">
      <div class="col-md-11 right_border">
        <div class="row">
          <div v-for="(seat,seatId) in plan.split(',')" :key="'seat'+seatId+index" class="col-auto">
            <div v-if="seat === 'AAA'" class="seat-empty my-1 px-1 p-3 rounded text-center   ">
              <span> </span>
            </div>
            <div v-else-if="seat === 'BBB'" class="seat my-1 px-1 p-3 rounded text-center bg-dark ">
              <span> </span>
            </div>
            <div v-else-if="seat === 'RRR'" class=" ms-auto" style="width: 114px">
              <div class="row">
                <div class="seat-empty my-1 px-1 p-3 rounded text-center   ms-auto me-auto">
                  <span> </span>
                </div>
                <div class="seat-empty my-1 px-1 p-3 rounded text-center   ms-auto me-auto">
                  <span> </span>
                </div>
              </div>
            </div>
            <div v-else-if="seat === ''" class="d-none ">
              <span> </span>
            </div>
            <div
                v-else
                :class="getSeatClass(seat)"
                class="seat my-1 px-1 py-1 rounded text-center"
                @click="selectedForBlocked(seat)">
              <span>{{ seat }}</span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="hasPermission(Admin.companyDeployWrite)"
           class="col-md-1 d-flex flex-column flex-xxl-row gap-1 align-items-center justify-content-center">
        <button class="edit_button" @click="editSeatRow(index)"><i class="fas fa-pencil-alt"></i></button>
        <button v-if="seatLists.length === index+1" class="remove_button" @click="removeSeatRow(index)"><i
            class="fas fa-minus"></i></button>
      </div>
    </div>
  </li>
  <EditSeatRowModal v-if="editModal" v-model="editModal" v-bind:row="listIndex" v-bind:seatList="seatLists[listIndex]"
                    v-bind:seatPlan="spId" v-bind:ship="shipId" @getSeatPlan="getSeatPlan"/>
</template>

<script>
import {inject, ref, watch} from "vue";
import store from "@/store";
import {reactive} from "@vue/reactivity";
import permission from "@/modules/permission";
import roles from "@/modules/roles";
import EditSeatRowModal from "@/views/Admin/CompanyDeploy/Children/Ships/SeatPlan/EditSeatRowModal";

const {hasPermission} = permission();
const {Admin} = roles();

export default {
  name: "SeatList",
  props: {
    blocked: Object,
    dtUpSeats: Object,
    dtDownSeats: Object,
    seatList: Object,
    seatPlan: String,
    ship: String,
  },
  components: {
    EditSeatRowModal
  },
  emits: ["getSeatPlan", "selectedForBlocked"],
  setup(props, {emit}) {
    let seat = reactive({
      shipId: "",
      seatClassId: "",
      rowIndex: "",
    })
    const spId = ref(props.seatPlan)
    const blockedSeats = ref(props.blocked)
    const digitalTicketingUpSeats = ref(props.dtUpSeats)
    const digitalTicketingDownSeats = ref(props.dtDownSeats)
    const seatLists = ref(props.seatList)
    const shipId = ref(props.ship)
    let editModal = ref(false)
    let listIndex = ref(-1)
    const showLoader = inject('showLoader');
    const hideLoader = inject('hideLoader');

    watch(() => [...props.blocked], () => {
      blockedSeats.value = props.blocked;
    });
    watch(() => [...props.dtUpSeats], () => {
      digitalTicketingUpSeats.value = props.dtUpSeats;
    });
    watch(() => [...props.dtDownSeats], () => {
      digitalTicketingDownSeats.value = props.dtDownSeats;
    });
    watch(() => [...props.seatList], () => {
      seatLists.value = props.seatList;
    });

    const init = () => {
      seat.shipId = "";
      seat.seatClassId = "";
    };
    const getSeatPlan = () => {
      emit("getSeatPlan");
    }
    const selectedForBlocked = (seat) => {
      emit("selectedForBlocked", seat);
    }
    const removeSeatRow = (rowIndex) => {
      showLoader()
      seat.shipId = shipId.value
      seat.seatClassId = spId.value
      seat.rowIndex = rowIndex
      store.dispatch("deleteSeatRow", seat).then(() => {
        hideLoader()
        getSeatPlan();
        init();
      }).catch((error) => {
        hideLoader()
        if (error.status === 401) {
          removeSeatRow()
        }
      });
    };
    const editSeatRow = (rowIndex) => {
      listIndex.value = rowIndex
      editModal.value = true
    };

    const getSeatClass = (seat) => {
      if (blockedSeats.value.includes(seat)) {
        return 'allBlockSeat'
      } else if (digitalTicketingUpSeats.value.includes(seat) && digitalTicketingDownSeats.value.includes(seat)) {
        return 'upAndDownSeat'
      } else if (digitalTicketingUpSeats.value.includes(seat)) {
        return 'allDtUpSeat'
      } else if (digitalTicketingDownSeats.value.includes(seat)) {
        return 'allDtDownSeat'
      } else {
        return ''
      }
    }

    return {
      spId,
      seatLists,
      shipId,
      Admin,
      blockedSeats,
      editModal,
      listIndex,
      hasPermission,
      removeSeatRow,
      selectedForBlocked,
      editSeatRow,
      getSeatPlan,
      digitalTicketingUpSeats,
      digitalTicketingDownSeats,
      getSeatClass,
    }
  }
};
</script>

<style scoped>

.remove_button {
  border: 2px solid #ff072c;
  border-radius: 20px;
  background-color: #ff072c;
  padding: 5px 10px;
  color: #ffffff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.remove_button:hover,
.remove_button:focus {
  border: 2px solid #ff072c;
  background-color: #fff;
  color: #ff072c;
  transition: 0.4s;
  outline: 0;
}

.edit_button {
  border: 2px solid #FFC107;
  border-radius: 20px;
  background-color: #FFC107;
  padding: 5px 10px;
  color: #ffffff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.edit_button:hover,
.edit_button:focus {
  border: 2px solid #FFC107;
  background-color: #fff;
  color: #FFC107;
  transition: 0.4s;
  outline: 0;
}

.right_border {
  border-right: 1px solid #ccc;
}


.allBlockSeat {
  background-color: #000 !important;
}

.allDtUpSeat {
  background-color: #c96c89 !important;
}

.upAndDownSeat {
  background-image: linear-gradient(to right, #c96c89 , #9997dc);
}

.allDtDownSeat {
  background-color: #9997dc !important;
}

.seat {
  cursor: pointer;
  background-color: #FF6347;
  width: 45px;
  font-size: 14px;
  color: #FFF;
}

.seat-empty {
  cursor: crosshair;
  background-color: #ded2d0;
  width: 45px;
  font-size: 14px;
  color: #FFF;
}
</style>