<template>
  <div>
    <FilterTab v-if="!activeSeatPermission">
      <template v-slot:title><h6>Cabin Man</h6></template>
      <template v-slot:body>
        <div class="col-sm-6 col-md-2">
          <!--          <DropdownFilter v-bind:id="'status'" v-bind:label="'Status'" v-bind:defaultOption="'&#45;&#45;select status&#45;&#45;'"-->
          <!--                          v-bind:defaultValue="''"-->
          <!--                          v-bind:dropdown="dropdownVariables.status"/>-->
        </div>
      </template>
      <template v-slot:button1>
        <button
          v-if="hasPermission(Owner.ownerCompanySetupWrite)"
          class="edit_table_data font_detail"
          @click="addModal"
        >
          <i class="fas fa-plus"></i><span class="mx-1 bold">Add New</span>
        </button>
      </template>
    </FilterTab>

    <SeatPermission
        v-if="activeSeatPermission"
        :cabinMan="cabinManId"
        :ship="shipId"
        @toggle="toggle">
    </SeatPermission>
    <Table v-else-if="$store.getters.getOwnerServicesCabinMans">
      <template v-slot:header>
        <tr>
          <td class="font_detail">SL</td>
          <td class="font_detail">NAME</td>
          <td class="font_detail">SHIPS</td>
          <td class="font_detail">MOBILE</td>
          <td class="font_detail">STATUS</td>
          <td
            v-if="hasPermission(Owner.ownerCompanySetupWrite)"
            class="font_detail"
          >
            ACTION
          </td>
        </tr>
      </template>
      <template v-slot:body>
        <tr
          v-for="(cabinman, index) in $store.getters.getOwnerServicesCabinMans"
          :key="cabinman._id"
        >
          <td class="font_detail">{{ pagination.limit*(pagination.page-1) +index +1 }}</td>
          <td class="font_detail">{{ cabinman.name }}</td>
          <td class="font_detail">
            <span v-for="ship in cabinman.shipId" :key="ship._id"
              ><span class="badge rounded-pill bg-primary m-1 p-2">{{
                ship.name
              }}</span></span
            >
          </td>
          <td class="font_detail">{{ cabinman.phone }}</td>
          <td>
            <span v-if="cabinman.status" class="badge bg-success">{{
              dropdownVariables.status[1]
            }}</span>
            <span v-else class="badge bg-danger">{{
              dropdownVariables.status[0]
            }}</span>
          </td>
          <td v-if="hasPermission(Owner.ownerCompanySetupWrite)">

            <button
              class="edit_table_data font_detail edit_table_button"
              @click="updateModal(cabinman._id)"
            >
              <i class="fas fa-edit"></i><span class="mx-1">Edit</span>
            </button>
            <button
                class="edit_table_data font_detail add_table_button"
                @click="handleSeatPermission(cabinman)"
            >
              <i class="fas fa-plus"></i><span class="mx-1">Permission</span>
            </button>
          </td>
        </tr>
      </template>
      <template v-slot:pagination>
        <pagination
          v-model="pagination.page"
          :records="$store.getters.getOwnerServicesTotalCabinMan"
          :per-page="pagination.limit"
          @paginate="getAllCabinman"
          :options="options"
        />
      </template>
    </Table>
    <div v-if="showModal">
      <CabinmanAddUpdateModal
        v-model="showModal"
        @getAllCabinman="getAllCabinman"
        v-bind:cabinman="cabinManId"
        v-bind:company="pagination.companyId"
      />
    </div>

  </div>
</template>

<script>
import store from "@/store";
import dropdownVariables from "@/modules/dropdownVariables";
import { reactive } from "@vue/reactivity";
import { inject, onBeforeMount, ref } from "vue";
import permission from "@/modules/permission";
import roles from "@/modules/roles";
import CabinmanAddUpdateModal from "./CabinmanAddUpdateModal";
import SeatPermission from "./SeatPermission";

const { hasPermission } = permission();
const { Owner } = roles();

export default {
  name: "Cabinman",
  components: { CabinmanAddUpdateModal, SeatPermission, },
  setup() {
    let pagination = reactive({
      page: 1,
      limit: 10,
      companyId: "",
      shipId: "",
      status: 1,
    });
    const options = {
      texts: {
        count: "",
      },
    };
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const cabinManId = ref("");
    const showModal = ref(false);
    const activeSeatPermission = ref(false);
    const shipId = ref("");

    const getAllCabinman = () => {
      showLoader();
      store
        .dispatch("getOwnerServicesCabinManByPage", pagination)
        .then(() => {
          getAllShips();
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            getAllCabinman();
          }
        });
    };

    const getAllShips = () => {
      store
        .dispatch("getOwnerShipsByDeck", { companyId: pagination.companyId })
        .then(() => {
          hideLoader();
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            getAllShips();
          }
        });
    };
    const addModal = () => {
      cabinManId.value = "";
      showModal.value = true;
    };
    const updateModal = (value) => {
      cabinManId.value = value;
      showModal.value = true;
    };


    const handleSeatPermission = (cabinMan) => {
      cabinManId.value = cabinMan._id;
      shipId.value = cabinMan.shipId[0]._id
      activeSeatPermission.value = true;
    }

    const toggle = () => {
      activeSeatPermission.value = false
    }

    onBeforeMount(() => {
        getAllCabinman();
    });

    return {
      dropdownVariables,
      pagination,
      options,
      showModal,
      cabinManId,
      Owner,
      hasPermission,
      addModal,
      updateModal,
      getAllCabinman,
      handleSeatPermission,
      activeSeatPermission,
      toggle,
      shipId,
    };
  },
};
</script>

<style scoped></style>
