import roles from "@/modules/roles";

const {
    Admin: AdminRoles,
    Owner: OwnerRoles,
    Counterman: CountermanRoles,
    Agent: AgentRoles,
} = roles();

const Default = {
    LOGIN_URL: {
        title: "Login",
        path: "/",
        name: "login",
    },
    LOGOUT_URL: {
        title: "Logout",
        path: "/logout",
        name: "logout",
    },
    ERROR_URL: {
        title: "Error",
        path: "/:pathMatch(.*)*",
        name: "error",
    },
};

const Admin = {
    path: "/admin",
    name: "admin",
    DASHBOARD_URL: {
        title: "Dashboard",
        path: "dashboard",
        name: "admin-dashboard",
        permission: AdminRoles.dashboard,
    },
    REPORT_URL: {
        title: "Report",
        path: "report",
        name: "admin-report",
        permission: AdminRoles.reports,
        children: {
            SHIP_REPORT_URL: {
                title: "Ship Wise",
                path: "ship-report",
                name: "admin-report-ship-report",
            },
            COUNTER_REPORT_URL: {
                title: "Counter Wise",
                path: "counter-report",
                name: "admin-report-counter-report",
            },
            DECKMAN_REPORT_URL: {
                title: "Deckman Wise",
                path: "deckman-report",
                name: "admin-report-deckman-report",
            },
            CABINMAN_REPORT_URL: {
                title: "Cabinman Wise",
                path: "cabinman-report",
                name: "admin-report-cabinman-report",
            },
            AGENT_REPORT_URL: {
                title: "Agent Wise",
                path: "agent-report",
                name: "admin-report-agent-report",
            },
            USER_REPORT_URL: {
                title: "User Wise",
                path: "user-report",
                name: "admin-report-user-report",
            },
            PRINT_COUNT_REPORT_URL: {
                title: "Print Count",
                path: "print-count",
                name: "admin-report-print-count-report",
            },
            RESERVATION_REPORT_URL: {
                title: "Reservation",
                path: "reservation-report",
                name: "admin-report-reservation-report",
            },
        },
    },
    ADMINS_URL: {
        title: "Admins",
        path: "admins",
        name: "admin-admins",
        permission: AdminRoles.adminRead,
        children: {
            LIST_URL: {
                title: "List",
                path: "list",
                name: "admin-admins-list",
            },
            ADD_URL: {
                title: "Add",
                path: "add",
                name: "admin-admins-add",
            },
            UPDATE_URL: {
                title: "Update",
                path: "update",
                name: "admin-admins-update",
            },
        },
    },
    DIGITAL_TICKETING_URL: {
        title: "Digital Ticketing",
        path: "digital-ticketing",
        name: "admin-digital-ticketing",
        permission: AdminRoles.companyDeployRead,
        children: {
            STOPPAGE_URL: {
                "title": "City",
                "path": "city",
                "name": "admin-digital-ticketing-city",
            },
        },
    },
    COMPANY_DEPLOY_URL: {
        title: "Company Deploy",
        path: "company-deploy",
        name: "admin-company-deploy",
        permission: AdminRoles.companyDeployRead,
        children: {
            LIST_URL: {
                title: "List",
                path: "list",
                name: "admin-company-deploy-list",
            },
            ADD_URL: {
                title: "Add",
                path: "add",
                name: "admin-company-deploy-add",
            },
            UPDATE_URL: {
                title: "Update",
                path: "update",
                name: "admin-company-deploy-update",
            },
            MANAGE_URL: {
                title: "Manage",
                path: "manage",
                name: "admin-company-deploy-manage",
                children: {
                    OWNER_URL: {
                        title: "Owner",
                        path: "owner",
                        name: "admin-company-deploy-owner",
                    },
                    STOPPAGE_URL: {
                        title: "Stoppage",
                        path: "stoppage",
                        name: "admin-company-deploy-stoppage",
                    },
                    // 'SEAT_CLASS_URL': {
                    //     "title": "SEAT CLASS",
                    //     "path": "seat-class",
                    //     "name": "admin-company-deploy-seat-class",
                    // },
                    // 'SEAT_PLAN_URL': {
                    //     "title": "SEAT PLAN",
                    //     "path": "seat-plan",
                    //     "name": "admin-company-deploy-seat-plan",
                    // },
                    ROUTE_URL: {
                        title: "Route",
                        path: "route",
                        name: "admin-company-deploy-route",
                    },
                    // 'FARES_URL': {
                    //     "title": "FARES",
                    //     "path": "fares",
                    //     "name": "admin-company-deploy-fares",
                    // },
                    COUNTER_URL: {
                        title: "Counter",
                        path: "counter",
                        name: "admin-company-deploy-counter",
                    },
                    SHIPS_URL: {
                        title: "Ships",
                        path: "ships",
                        name: "admin-company-deploy-ships",
                    },
                    TRIPS_URL: {
                        title: "Trips",
                        path: "trips",
                        name: "admin-company-deploy-trips",
                    },
                    AGENT_URL: {
                        title: "Agent",
                        path: "agent",
                        name: "admin-company-deploy-agent",
                    },
                    COUNTERMAN_URL: {
                        title: "Counterman",
                        path: "counterman",
                        name: "admin-company-deploy-counterman",
                    },
                    SUPERVISOR_URL: {
                        title: "Supervisor",
                        path: "supervisor",
                        name: "admin-company-deploy-supervisor",
                    },
                    DECKMAN_URL: {
                        title: "Deckman",
                        path: "deckman",
                        name: "admin-company-deploy-deckman",
                    },
                    CABINMAN_URL: {
                        title: "Cabinman",
                        path: "cabinman",
                        name: "admin-company-deploy-cabinman",
                    },
                },
            },
        },
    },
    PROFILE_URL: {
        title: "Profile",
        path: "profile",
        name: "admin-profile",
    },
};

const Owner = {
    path: "/owner",
    name: "owner",
    DASHBOARD_URL: {
        title: "Dashboard",
        path: "dashboard",
        name: "owner-dashboard",
        permission: OwnerRoles.ownerDashboard,
    },
    REPORT_URL: {
        title: "Report",
        path: "report",
        name: "owner-report",
        permission: OwnerRoles.ownerReports,
        children: {
            SHIP_REPORT_URL: {
                title: "Ship Wise",
                path: "ship-report",
                name: "admin-report-ship-report",
            },
            COUNTER_REPORT_URL: {
                title: "Counter Wise",
                path: "counter-report",
                name: "admin-report-counter-report",
            },
            DECKMAN_REPORT_URL: {
                title: "Deckman Wise",
                path: "deckman-report",
                name: "owner-report-deckman-report",
            },
            CABINMAN_REPORT_URL: {
                title: "Cabinman Wise",
                path: "cabinman-report",
                name: "owner-report-cabinman-report",
            },
            AGENT_REPORT_URL: {
                title: "Agent Wise",
                path: "agent-report",
                name: "admin-report-agent-report",
            },
            USER_REPORT_URL: {
                title: "User Wise",
                path: "user-report",
                name: "admin-report-user-report",
            },
            PRINT_COUNT_REPORT_URL: {
                title: "Print Count",
                path: "print-count",
                name: "admin-report-print-count-report",
            },
            RESERVATION_REPORT_URL: {
                title: "Reservation",
                path: "reservation-report",
                name: "owner-report-reservation-report",
            },
        },
    },
    OWNERS_URL: {
        title: "Owners",
        path: "owners",
        name: "owner-owners",
        permission: OwnerRoles.ownerRead,
        children: {
            LIST_URL: {
                title: "List",
                path: "list",
                name: "admin-admins-list",
            },
            ADD_URL: {
                title: "Add",
                path: "add",
                name: "admin-admins-add",
            },
            UPDATE_URL: {
                title: "Update",
                path: "update",
                name: "admin-admins-update",
            },
        },
    },
    SERVICES_URL: {
        title: "Services",
        path: "services",
        name: "owner-services",
        permission: OwnerRoles.ownerCompanySetupRead,
        children: {
            STOPPAGE_URL: {
                title: "Stoppage",
                path: "stoppage",
                name: "owner-services-stoppage",
            },
            // 'SEAT_CLASS_URL': {
            //     "title": "SEAT CLASS",
            //     "path": "seat-class",
            //     "name": "owner-services-seat-class",
            // },
            // 'SEAT_PLAN_URL': {
            //     "title": "SEAT PLAN",
            //     "path": "seat-plan",
            //     "name": "owner-services-seat-plan",
            // },
            ROUTE_URL: {
                title: "Route",
                path: "route",
                name: "owner-services-route",
            },
            // 'FARES_URL': {
            //     "title": "FARES",
            //     "path": "fares",
            //     "name": "owner-services-fares",
            // },
            COUNTER_URL: {
                title: "Counter",
                path: "counter",
                name: "owner-services-counter",
            },
            SHIPS_URL: {
                title: "Ships",
                path: "ships",
                name: "owner-services-ships",
            },
            TRIPS_URL: {
                title: "Trips",
                path: "trips",
                name: "owner-services-trips",
            },
            AGENT_URL: {
                title: "Agent",
                path: "agent",
                name: "owner-services-agent",
            },
            COUNTERMAN_URL: {
                title: "Counterman",
                path: "counterman",
                name: "owner-services-counterman",
            },
            SUPERVISOR_URL: {
                title: "Supervisor",
                path: "supervisor",
                name: "owner-services-supervisor",
            },
            DECKMAN_URL: {
                title: "Deckman",
                path: "deckman",
                name: "owner-services-deckman",
            },
            CABINMAN_URL: {
                title: "Cabinman",
                path: "cabinman",
                name: "owner-services-cabinman",
            },
        },
    },
    PROFILE_URL: {
        title: "PROFILE",
        path: "profile",
        name: "owner-profile",
    },
};

const Counterman = {
    path: "/counterman",
    name: "counterman",
    DASHBOARD_URL: {
        title: "Dashboard",
        path: "dashboard",
        name: "counterman-dashboard",
        permission: CountermanRoles.countermanTicketBooking,
    },
    REPORT_URL: {
        title: "Report",
        path: "report",
        name: "counterman-report",
        permission: CountermanRoles.countermanReports,
    },
    SHIPS_URL: {
        title: "Ship",
        path: "ship",
        name: "counterman-ship",
        permission: CountermanRoles.countermanShips,
    },
    TRIPS_URL: {
        title: "Trips",
        path: "trip",
        name: "counterman-strip",
        permission: CountermanRoles.countermanTrips,
    },

    PROFILE_URL: {
        title: "Profile",
        path: "profile",
        name: "counterman-profile",
    },
    VERIFY_URL: {
        title: "Digital Ticketing Verify",
        path: "verify",
        name: "counterman-verify",
    },
    TICKET_URL: {
        title: "DT Tickets",
        path: "ticket",
        name: "counterman-dt-ticket",
    },
};

const Supervisor = {
    path: "/supervisor",
    name: "supervisor",
    DASHBOARD_URL: {
        title: "Dashboard",
        path: "dashboard",
        name: "supervisor-dashboard",
    },
    REPORT_URL: {
        title: "Report",
        path: "report",
        name: "supervisor-report",
    },
    VERIFY_URL: {
        title: "Verify",
        path: "verify",
        name: "supervisor-verify",
    },
};

const Agent = {
    path: "/agent",
    name: "agent",
    DASHBOARD_URL: {
        title: "Dashboard",
        path: "dashboard",
        name: "agent-dashboard",
        permission: AgentRoles.agentTicketBooking,
    },
    REPORT_URL: {
        title: "Report",
        path: "report",
        name: "agent-report",
        permission: AgentRoles.agentReports,
    },
    SHIPS_URL: {
        title: "Ship",
        path: "ship",
        name: "agent-ship",
        permission: AgentRoles.agentShips,
    },
    TRIPS_URL: {
        title: "Trips",
        path: "trip",
        name: "agent-strip",
        permission: AgentRoles.agentTrips,
    },

    PROFILE_URL: {
        title: "Profile",
        path: "profile",
        name: "counterman-profile",
    },
};
export default {
    Default,
    Admin,
    Owner,
    Counterman,
    Supervisor,
    Agent,
};
