<template>
  <div>
    <FilterTab>
      <template v-slot:title><h6>Deck Man</h6></template>
      <template v-slot:body>
        <div class="col-sm-6 col-md-2">
          <FilterInput
            v-model="pagination.nameOrPhone"
            :placeholder="'Name/Phone'"
            :label="'Name/Phone'"
            :id="'nameOrphone'"
            :type="'text'"
          />
        </div>
      </template>
      <template v-slot:button2>
        <button
          v-if="hasPermission(Owner.ownerCompanySetupWrite)"
          class="edit_table_data font_detail"
          @click="addModal"
        >
          <i class="fas fa-plus"></i><span class="mx-1 bold">Add New</span>
        </button>
      </template>
      <template v-slot:button1>
        <button class="edit_table_data font_detail" @click="getAllDeckman">
          <i class="fas fa-search"></i><span class="mx-1 bold">Search</span>
        </button>
      </template>
    </FilterTab>
    <Table v-if="$store.getters.getDeckMansOwner">
      <template v-slot:header>
        <tr>
          <td class="font_detail">SL</td>
          <td class="font_detail">NAME</td>
          <td class="font_detail">SHIPS</td>
          <td class="font_detail">MOBILE</td>
          <td class="font_detail">STATUS</td>
          <td
            v-if="hasPermission(Owner.ownerCompanySetupWrite)"
            class="font_detail"
          >
            ACTION
          </td>
        </tr>
      </template>
      <template v-slot:body>
        <tr
          v-for="(deckman, index) in $store.getters.getDeckMansOwner"
          :key="deckman._id"
        >
          <td class="font_detail">{{ pagination.limit*(pagination.page-1) +index +1 }}</td>
          <td class="font_detail">{{ deckman.name }}</td>
          <td class="font_detail">
            <span v-for="ship in deckman.shipId" :key="ship._id"
              ><span class="badge rounded-pill bg-primary m-1 p-2">{{
                ship.name
              }}</span></span
            >
          </td>
          <td class="font_detail">{{ deckman.phone }}</td>
          <td>
            <span v-if="deckman.status" class="badge bg-success">{{
              dropdownVariables.status[1]
            }}</span>
            <span v-else class="badge bg-danger">{{
              dropdownVariables.status[0]
            }}</span>
          </td>
          <td v-if="hasPermission(Owner.ownerCompanySetupWrite)">
            <button
              class="edit_table_data font_detail edit_table_button"
              @click="updateModal(deckman._id)"
            >
              <i class="fas fa-edit"></i><span class="mx-1">Edit</span>
            </button>
          </td>
        </tr>
      </template>
      <template v-slot:pagination>
        <pagination
          v-model="pagination.page"
          :records="$store.getters.getTotalDeckManOwner"
          :per-page="pagination.limit"
          @paginate="getAllDeckman"
          :options="options"
        />
      </template>
    </Table>
    <div v-if="showModal">
      <DeckmanAddUpdateModal
        v-model="showModal"
        @getAllDeckman="getAllDeckman"
        v-bind:deckman="deckManId"
        v-bind:company="pagination.companyId"
      />
    </div>
  </div>
</template>

<script>
import store from "@/store";
import dropdownVariables from "@/modules/dropdownVariables";
import { reactive } from "@vue/reactivity";
import { inject, onBeforeMount, ref } from "vue";
import permission from "@/modules/permission";
import roles from "@/modules/roles";
import DeckmanAddUpdateModal from "./DeckmanAddUpdateModal.vue";
import FilterInput from "@/components/layouts/Filter/Inputs/Input.vue";

const { hasPermission } = permission();
const { Owner } = roles();

export default {
  name: "Deckman",
  components: { DeckmanAddUpdateModal, FilterInput },
  setup() {
    let pagination = reactive({
      page: 1,
      limit: 10,
      companyId: "",
      nameOrPhone: "",
      shipId: "",
      status: 1,
    });
    const options = {
      texts: {
        count: "",
      },
    };
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const deckManId = ref("");
    const showModal = ref(false);

    const getAllDeckman = () => {
      showLoader();
      store
        .dispatch("getDackmanByPageOwner", pagination)
        .then(() => {
          getAllShips();
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            getAllDeckman();
          }
        });
    };

    const getAllShips = () => {
      store
        .dispatch("getOwnerShipsByDeck", {})
        .then(() => {
          hideLoader();
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            getAllShips();
          }
        });
    };
    const addModal = () => {
      deckManId.value = "";
      showModal.value = true;
    };
    const updateModal = (value) => {
      deckManId.value = value;
      showModal.value = true;
    };

    onBeforeMount(() => {
      pagination.companyId = store.getters.userCompnay;
      getAllDeckman();
    });

    return {
      dropdownVariables,
      pagination,
      options,
      showModal,
      deckManId,
      Owner,
      hasPermission,
      addModal,
      updateModal,
      getAllDeckman,
    };
  },
};
</script>

<style scoped></style>
