const API_URL = process.env.VUE_APP_API;

const LOGIN = API_URL + "/api/auth/login";
const REFRESH = API_URL + "/api/auth/refresh-token";
const LOGOUT = API_URL + "/logout";

const GET_ALL_USERS = API_URL + "/api/admin";
const ADD_USER = API_URL + "/api/admin";
const UPDATE_USER = API_URL + "/api/admin";
const DELETE_USER = API_URL + "/api/admin";

const GET_ALL_COMPANIES = API_URL + "/api/company";
const ADD_COMPANY = API_URL + "/api/company";
const UPDATE_COMPANY = API_URL + "/api/company";

const GET_ALL_OWNERS = API_URL + "/api/owner";
const ADD_OWNER = API_URL + "/api/owner";
const UPDATE_OWNER = API_URL + "/api/owner";

const GET_ALL_STOPPAGES = API_URL + "/api/stoppage";
const ADD_STOPPAGE = API_URL + "/api/stoppage";
const UPDATE_STOPPAGE = API_URL + "/api/stoppage";

const GET_ALL_ROUTES = API_URL + "/api/route";
const ADD_ROUTE = API_URL + "/api/route";
const UPDATE_ROUTE = API_URL + "/api/route";

const GET_ALL_BOARDING_PLACES = API_URL + "/api/boarding-place";
const ADD_BOARDING_PLACE = API_URL + "/api/boarding-place";
const UPDATE_BOARDING_PLACE = API_URL + "/api/boarding-place";

const GET_ALL_DROPPING_PLACES = API_URL + "/api/dropping-point";
const ADD_DROPPING_PLACE = API_URL + "/api/dropping-point";
const UPDATE_DROPPING_PLACE = API_URL + "/api/dropping-point";

const GET_COMPANY_INFO = API_URL + "/api/company/info";
const GET_ALL_LOCAL_ROUTES = API_URL + "/api/route/local-route";
const ADD_LOCAL_ROUTE = API_URL + "/api/route/local-route";
const UPDATE_LOCAL_ROUTE = API_URL + "/api/route/local-route";
const DELETE_LOCAL_ROUTE =API_URL + "/api/route/local-route";

const GET_ALL_COUNTERS = API_URL + "/api/counter";
const ADD_COUNTER = API_URL + "/api/counter";
const UPDATE_COUNTER = API_URL + "/api/counter";

const GET_ALL_COUNTERMANS = API_URL + "/api/counterman";
const ADD_COUNTERMAN = API_URL + "/api/counterman";
const UPDATE_COUNTERMAN = API_URL + "/api/counterman";

const GET_ALL_SUPERVISORS = API_URL + "/api/supervisor";
const ADD_SUPERVISOR = API_URL + "/api/supervisor";
const UPDATE_SUPERVISOR = API_URL + "/api/supervisor";

const GET_ALL_SUPERVISORS_OWNER = API_URL + "/api/owner-panel/supervisor";
const ADD_SUPERVISOR_OWNER = API_URL + "/api/owner-panel/supervisor";
const UPDATE_SUPERVISOR_OWNER = API_URL + "/api/owner-panel/supervisor";
const VERIFY_SUPERVISOR_TICKET = API_URL + "/api/supervisor/ticket/verification";

const GET_ALL_DECKMANS = API_URL + "/api/deckman";
const ADD_DECKMAN = API_URL + "/api/deckman";
const UPDATE_DECKMAN = API_URL + "/api/deckman";
const LOGOUT_DECKMAN = API_URL + "/api/deckman/logout-from-admin";

const GET_ALL_CABINMANS = API_URL + "/api/cabinman";
const ADD_CABINMAN = API_URL + "/api/cabinman";
const UPDATE_CABINMAN = API_URL + "/api/cabinman";
const LOGOUT_CABINMAN = API_URL + "/api/cabinman/logout-from-admin";
const SEAT_PERMISSION_CABINMAN = API_URL + "/api/cabinman/seatPermissionAdd";
const GET_CABIN_SEATLIST = API_URL + "/api/cabinman/cabinSeatList";
const GET_PERMITTED_CABIN_SEATLIST = API_URL + "/api/cabinman/permittedSeatList";

const GET_ALL_SHIPS = API_URL + "/api/ship";
const GET_ALL_SHIPS_BY_ROUTE = API_URL + "/api/ship/by-route";
const GET_ALL_SHIPS_BY_DECK = API_URL + "/api/ship/by-deck";
const ADD_SHIP = API_URL + "/api/ship";
const UPDATE_SHIP = API_URL + "/api/ship";
const COPY_SHIP = API_URL + "/api/ship/copy-ship";
const GET_SHIP_INFO = API_URL + "/api/ship/info";

const ADD_FLOOR = API_URL + "/api/floor/add-floor";
const UPDATE_FLOOR = API_URL + "/api/floor/update-floor";
const DELETE_FLOOR = API_URL + "/api/floor/delete-floor";
// const GET_ALL_SEAT_CLASSES = API_URL + "/api/seat-class"
const ADD_SEAT_CLASS = API_URL + "/api/seat-plan/add-class";
const UPDATE_SEAT_CLASS = API_URL + "/api/seat-plan/update-class";
const DELETE_SEAT_CLASS = API_URL + "/api/seat-plan/delete-class";

const ADD_SEAT_ROW = API_URL + "/api/seat-plan/add-seat";
const ADD_CUSTOM_SEAT_ROW = API_URL + "/api/seat-plan/add-custom-seat";
const EDIT_CUSTOM_SEAT_ROW = API_URL + "/api/seat-plan/edit-seat-row";
const DELETE_SEAT_ROW = API_URL + "/api/seat-plan/delete-seat";
const SEAT_BLOCK = API_URL + "/api/seat-plan/seat-blocking";
const ADD_DT_UP_SEAT = API_URL + "/api/seat-plan/dt-up-seat";
const ADD_DT_DOWN_SEAT = API_URL + "/api/seat-plan/dt-down-seat";

const COMPANY_LIST = API_URL + "/api/company";

const PRINT_COUNT = API_URL + "/api/report/print-count-report";
const SHIP_WISE_REPORT = API_URL + "/api/report/ship-wise-report";
const COUNTER_WISE_REPORT = API_URL + "/api/report/counter-wise-report";
const RESERVATION_WISE_REPORT = API_URL + "/api/report/reservation-report";
const SHIP_WISE_DECK_REPORT_DETAILS =
  API_URL + "/api/report/ship-wise-report-details";
const SHIP_WISE_GOOD_REPORT_DETAILS =
  API_URL + "/api/report/ship-wise-report-details";
const COUNTER_WISE_REPORT_DETAILS =
  API_URL + "/api/report/counter-wise-report-details";
const RESERVATION_WISE_REPORT_DETAILS =
  API_URL + "/api/report/reservation-report-details";
const DELETE_BOARDING_PLACE_FOR_ROUTE =
  API_URL + "/api/boarding-place";
const DELETE_DROPPING_POINT_FOR_ROUTE =
  API_URL + "/api/dropping-point";
const CANCEL_COUNTERMAN_TICKET =
  API_URL + "/api/report/cancel-counterman-ticket";
const CANCEL_USER_TICKET =
    API_URL + "/api/report/cancel-user-ticket";
// Cabinman report for admin

const CABINMAN_WISE_REPORT = API_URL + '/api/report/cabinman-wise-report';
const CABINMAN_WISE_REPORT_DETAILS = API_URL + '/api/report/cabinman-wise-report-details';

const GET_ALL_TRIPS = API_URL + "/api/trip";
const ADD_TRIP = API_URL + "/api/trip";
const UPDATE_TRIP = API_URL + "/api/trip";
const TRIP_COMPLETED = API_URL + "/api/trip/complete";
const GET_ALL_DASHBOARD_TICKETS = API_URL + "/api/dashboard";
const USER_WISE_REPORT = API_URL + "/api/report/user-wise-report";

const GET_DIGITAL_TICKETING_STOPPAGES = API_URL + "/api/city"
const ADD_DIGITAL_TICKETING_STOPPAGE = API_URL + "/api/city"
const UPDATE_DIGITAL_TICKETING_STOPPAGE = API_URL + "/api/city"
const GET_DIGITAL_TICKETING_ALL_STOPPAGE = API_URL + "/api/city/all"

//Counterman APIs
const GET_ALL_COUNTERMAN_STOPPAGES =
  API_URL + "/api/counterman/dashboard/stoppage";
const GET_ALL_COUNTERMAN_SHIPS = API_URL + "/api/counterman/dashboard/ship";
const GET_ALL_COUNTERMAN_ROUTES = API_URL + "/api/route";
const GET_ALL_COUNTERMAN_COUNTERS = API_URL + "/api/counter";
const COUNTER_ALL_SHIPS = API_URL + "/api/counterman/ship";
const VERIFY_TICKET_COUNTERMAN =
  API_URL + "/api/counterman/ticket/digital-ticket-verification";
const COUNTER_ALL_TRIPS = API_URL + "/api/counterman/trip";
const TICKETS_HISTORY_COUNTER = API_URL + "/api/counterman/ticket/history";
const RESERVED_TICKETS_HISTORY_COUNTER = API_URL + "/api/counterman/ticket/reserved-tickets";
const TICKET_CANCEL = API_URL + "/api/counterman/ticket/cancel";
const RESERVE_TICKET_CANCEL = API_URL + "/api/counterman/ticket/cancel-reservation";
const RESERVE_TICKET_CONFIRM = API_URL + "/api/counterman/ticket/confirm-reservation";
const TICKET_PURCHASE = API_URL + "/api/counterman/ticket/purchase";
const TICKET_RESERVE = API_URL + "/api/counterman/ticket/reservation";
const GET_RESERVATION_KEY = API_URL + "/api/counterman/ticket/reservation-key";
const GET_ALL_TRIPS_FOR_TICKETING = API_URL + "/api/counterman/dashboard";
const GET_ALL_COUNTERMAN_REPORT = API_URL + "/api/counterman/report";
const GET_COUNTERMAN_TRIPWISE_REPORT =
  API_URL + "/api/counterman/report/trip-wise";
const GET_ALL_COUNTERMAN_USER_REPORT = API_URL + "/api/counterman/report/user-wise-report";
const GET_LAST_TICKET = API_URL + "/api/counterman/ticket/print-last-one";
const TICKET_PRINT_COUNT =
  API_URL + "/api/counterman/ticket/ticket-print-count";
const REPORT_PRINT_COUNT =
  API_URL + "/api/counterman/report/report-print-count";
const REPORT_PRINT_COUNT_INCREASE =
  API_URL + "/api/counterman/report/report-print-count-increase";
const VERIFY_TICKET_LIST =
  API_URL + "/api/counterman/ticket/digital-ticket-list";
const GET_ALL_COUNTERMAN_SHIP =   API_URL + "/api/counterman/ship/all";

// Agent

const GET_ALL_AGENT_STOPPAGES = API_URL + "/api/agent/dashboard/stoppage";
const GET_ALL_AGENT_SHIPS = API_URL + "/api/agent/dashboard/ship";
const GET_ALL_TRIPS_FOR_TICKETING_BY_AGENT = API_URL + "/api/agent/dashboard";
const GET_LAST_TICKET_BY_AGENT = API_URL + "/api/agent/ticket/print-last-one";
const AGENT_ALL_SHIPS = API_URL + "/api/agent/ship";
const AGENT_ALL_TRIPS = API_URL + "/api/agent/trip";
const GET_AGENT_TRIPWISE_REPORT = API_URL + "/api/agent/report/trip-wise";
const GET_ALL_AGENT_REPORT = API_URL + "/api/agent/report";
const TICKET_PURCHASE_BY_AGENT = API_URL + "/api/agent/ticket/purchase";
const AGENT_LIST = API_URL + "/api/agent";
const AGENT_ADD = API_URL + "/api/agent";
const AGENT_WISE_REPORT = API_URL + "/api/report/agent-wise-report";
const DECKMAN_WISE_REPORT = API_URL + "/api/report/deckman-wise-report";
const DECKMAN_WISE_REPORT_OWNER =
  API_URL + "/api/owner-panel/report/deckman-wise-report";
const AGENT_WISE_SEAT_REPORT_DETAILS =
  API_URL + "/api/report/agent-wise-report-details";

const DECKMAN_WISE_REPORT_DETAILS =
  API_URL + "/api/report/deckman-wise-report-details";
const CANCEL_DECKMAN_WISE_REPORT_DETAILS =
  API_URL + "/api/report/cancel-deck-ticket";
const CANCEL_CABINMAN_REPORT_DETAILS =
  API_URL + "/api/report/cancel-cabin-ticket";
const DECKMAN_WISE_REPORT_DETAILS_OWNER =
  API_URL + "/api/owner-panel/report/deckman-wise-report-details";

// Cabinman report for owner

const CABINMAN_WISE_REPORT_OWNER = API_URL + '/api/owner-panel/report/cabinman-wise-report';
const CABINMAN_WISE_REPORT_DETAILS_OWNER = API_URL + '/api/owner-panel/report/cabinman-wise-report-details';

//supervisor
const GET_ALL_SUPERVISOR_STOPPAGES =
  API_URL + "/api/supervisor/trip/stoppage-list";
const ADD_SUPERVISOR_TRIP = API_URL + "/api/supervisor/trip";
const UPDATE_SUPERVISOR_TRIP = API_URL + "/api/supervisor/trip";
const GET_ALL_SUPERVISOR_SHIPS = API_URL + "/api/supervisor/trip/ship-list";
const GET_ALL_SUPERVISOR_TRIPS = API_URL + "/api/supervisor/trip";
const GET_ALL_SUPERVISOR_TRIPS_REPORT_LIST = API_URL + "/api/supervisor/report/trip-list";
const GET_ALL_SUPERVISOR_ROUTES = API_URL + "/api/supervisor/trip/route-list";
const GET_ALL_SUPERVISOR_SHIPS_BY_ROUTE =
  API_URL + "/api/supervisor/trip/ship-by-route";
const GET_ALL_SUPERVISOR_REPORT =
  API_URL + "/api/supervisor/report/summary-report";
const GET_SUPERVISOR_REPORT_FOR_PRINT =
  API_URL + "/api/supervisor/report/report-print";
const SUPERVISOR_REPORT_PRINT_COUNT =
  API_URL + "/api/supervisor/report/report-print-count";
const SUPERVISOR_REPORT_PRINT_COUNT_INCREASE =
  API_URL + "/api/supervisor/report/report-print-count-increase";
// Owner

const GET_OWNER_SERVICES_ALL_CABINMANS = API_URL + "/api/owner-panel/cabinman";
const ADD_OWNER_SERVICES_CABINMAN = API_URL + "/api/owner-panel/cabinman";
const UPDATE_OWNER_SERVICES_CABINMAN = API_URL + "/api/owner-panel/cabinman";
const LOGOUT_OWNER_SERVICES_CABINMAN = API_URL + "/api/owner-panel/cabinman/logout-from-admin";
const SEAT_OWNER_SERVICES_PERMISSION_CABINMAN = API_URL + "/api/owner-panel/cabinman/seatPermissionAdd";
const GET_OWNER_SERVICES_CABIN_SEATLIST = API_URL + "/api/owner-panel/cabinman/cabinSeatList";
const GET_OWNER_SERVICES_PERMITTED_CABIN_SEATLIST = API_URL + "/api/owner-panel/cabinman/permittedSeatList";

const GET_ALL_OWNERS_PANEL = API_URL + "/api/owner-panel/owner";
const ADD_OWNER_PANEL = API_URL + "/api/owner-panel/owner";
const UPDATE_OWNER_PANEL = API_URL + "/api/owner-panel/owner";
const GET_ALL_DASHBOARD_TICKETS_OWNER = API_URL + "/api/owner-panel/dashboard";
const SHIP_WISE_REPORT_OWNER =
  API_URL + "/api/owner-panel/report/ship-wise-report";
const GET_ALL_SHIPS_OWNER = API_URL + "/api/owner-panel/ship";
const CANCEL_USER_TICKET_OWNER = API_URL + "/api/owner-panel/report/cancel-user-ticket";
const GET_ALL_OWNER_SHIPS_BY_DECK = API_URL + "/api/owner-panel/ship/by-deck";
const GET_ALL_COUNTER_OWNER = API_URL + "/api/owner-panel/counter";
const SHIP_WISE_DECK_REPORT_DETAILS_OWNER =
  API_URL + "/api/owner-panel/report/ship-wise-report-details";
const SHIP_WISE_GOOD_REPORT_DETAILS_OWNER =
  API_URL + "/api/owner-panel/report/ship-wise-report-details";
const SHIP_WISE_SEAT_REPORT_DETAILS_OWNER =
  API_URL + "/api/owner-panel/report/ship-wise-report-details";
const COUNTER_WISE_REPORT_OWNER =
  API_URL + "/api/owner-panel/report/counter-wise-report";
const COUNTER_WISE_DETAILS_REPORT_OWNER =
  API_URL + "/api/owner-panel/report/counter-wise-report-details";
const RESERVATION_WISE_REPORT_OWNER = API_URL + "/api/owner-panel/report/reservation-report";
const RESERVATION_WISE_REPORT_DETAILS_OWNER =
  API_URL + "/api/owner-panel/report/reservation-report-details";
const AGENT_WISE_REPORT_OWNER =
  API_URL + "/api/owner-panel/report/agent-wise-report";
const AGENT_WISE_REPORT_DETAILS_OWNER =
  API_URL + "/api/owner-panel/report/agent-wise-report-details";
const GET_OWNER_SERVICES_STOPAGE = API_URL + "/api/owner-panel/stoppage";
const GET_OWNER_SERVICES_ROUTE = API_URL + "/api/owner-panel/route";
const GET_OWNER_SERVICES_BOARDING_PLACE =
  API_URL + "/api/owner-panel/boarding-place";
const GET_OWNER_SERVICES_DROPPING_PLACE =
  API_URL + "/api/owner-panel/dropping-point";
const GET_OWNER_SERVICES_TRIPS = API_URL + "/api/owner-panel/trip";
const GET_ALL_OWNER_SERVICES_SHIPS_BY_ROUTE =
  API_URL + "/api/owner-panel/ship/by-route";
const GET_ALL_OWNER_SERVICES_SHIP_INFO = API_URL + "/api/owner-panel/ship/info";
const AGENT_LIST_OWNER = API_URL + "/api/owner-panel/agent";
const AGENT_ADD_OWNER = API_URL + "/api/owner-panel/agent";
const GET_ALL_DECKMANS_OWNER = API_URL + "/api/owner-panel/deckman";
const ADD_DECKMAN_OWNER = API_URL + "/api/owner-panel/deckman";
const UPDATE_DECKMAN_OWNER = API_URL + "/api/owner-panel/deckman";
const GET_ALL_COUNTERS_OWNER = API_URL + "/api/owner-panel/counter";
const GET_ALL_STOPPAGES_OWNER = API_URL + "/api/owner-panel/stoppage";
const ADD_COUNTER_OWNER = API_URL + "/api/owner-panel/counter";
const UPDATE_COUNTER_OWNER = API_URL + "/api/owner-panel/counter";
const GET_ALL_COUNTERMANS_OWNER = API_URL + "/api/owner-panel/counterman";
const ADD_COUNTERMAN_OWNER = API_URL + "/api/owner-panel/counterman";
const UPDATE_COUNTERMAN_OWNER = API_URL + "/api/owner-panel/counterman";
const GET_ALL_ROUTES_OWNER = API_URL + "/api/owner-panel/route";
const ADD_SHIP_OWNER = API_URL + "/api/owner-panel/ship";
const UPDATE_SHIP_OWNER = API_URL + "/api/owner-panel/ship";
const ADD_SEAT_CLASS_OWNER = API_URL + "/api/owner-panel/seat-plan/add-class";
const ADD_SEAT_ROW_OWNER = API_URL + "/api/owner-panel/seat-plan/add-seat";
const ADD_CUSTOM_SEAT_ROW_BY_OWNER =
  API_URL + "/api/owner-panel/seat-plan/add-custom-seat";
const EDIT_CUSTOM_SEAT_ROW_BY_OWNER =
  API_URL + "/api/owner-panel/seat-plan/edit-seat-row";
const SEAT_BLOCK_OWNER = API_URL + "/api/owner-panel/seat-plan/seat-blocking";
const DELETE_SEAT_CLASS_OWNER =
  API_URL + "/api/owner-panel/seat-plan/delete-class";
const UPDATE_SEAT_CLASS_OWNER =
  API_URL + "/api/owner-panel/seat-plan/update-class";
const DELETE_SEAT_ROW_OWNER =
  API_URL + "/api/owner-panel/seat-plan/delete-seat";
const AGENT_RECHARGE_BALANCE =
  API_URL + "/api/owner-panel/agent/balance-recharge";
const PRINT_COUNT_OWNER =
  API_URL + "/api/owner-panel/report/print-count-report";
const USER_WISE_REPORT_OWNER =
  API_URL + "/api/owner-panel/report/user-wise-report";
const OWNER_ADD_FLOORPLAN =
  API_URL + "/api/owner-panel/floor/add-floor";
const OWNER_UPDATE_FLOORPLAN =
  API_URL + "/api/owner-panel/floor/update-floor";
const OWNER_DELETE_FLOORPLAN =
  API_URL + "/api/owner-panel/floor/delete-floor";

const OWNER_DELETE_BOARDING_PLACE_FOR_ROUTE =
  API_URL + "/api/owner-panel/boarding-place";
const OWNER_DELETE_DROPPING_POINT_FOR_ROUTE =
  API_URL + "/api/owner-panel/dropping-point";

  const OWNER_GET_ALL_LOCAL_ROUTES = API_URL + "/api/owner-panel/route/local-route";
  const OWNER_ADD_LOCAL_ROUTE = API_URL + "/api/owner-panel/route/local-route";
  const OWNER_UPDATE_LOCAL_ROUTE = API_URL + "/api/owner-panel/route/local-route";
  const OWNER_DELETE_LOCAL_ROUTE =API_URL + "/api/owner-panel/route/local-route";

export default {
  LOGIN,
  LOGOUT,
  REFRESH,
  GET_ALL_USERS,
  ADD_USER,
  UPDATE_USER,
  DELETE_USER,
  GET_ALL_COMPANIES,
  ADD_COMPANY,
  UPDATE_COMPANY,
  GET_ALL_OWNERS,
  ADD_OWNER,
  UPDATE_OWNER,
  GET_ALL_STOPPAGES,
  ADD_STOPPAGE,
  UPDATE_STOPPAGE,
  GET_ALL_BOARDING_PLACES,
  ADD_BOARDING_PLACE,
  UPDATE_BOARDING_PLACE,
  GET_ALL_DROPPING_PLACES,
  ADD_DROPPING_PLACE,
  UPDATE_DROPPING_PLACE,
  GET_ALL_LOCAL_ROUTES,
  ADD_LOCAL_ROUTE,
  UPDATE_LOCAL_ROUTE,
  DELETE_LOCAL_ROUTE,
  GET_ALL_ROUTES,
  ADD_ROUTE,
  UPDATE_ROUTE,
  GET_COMPANY_INFO,
  GET_ALL_COUNTERS,
  ADD_COUNTER,
  UPDATE_COUNTER,
  GET_ALL_COUNTERMANS,
  ADD_COUNTERMAN,
  UPDATE_COUNTERMAN,
  GET_ALL_SUPERVISORS,
  ADD_SUPERVISOR,
  UPDATE_SUPERVISOR,
  GET_ALL_SUPERVISORS_OWNER,
  ADD_SUPERVISOR_OWNER,
  UPDATE_SUPERVISOR_OWNER,
  VERIFY_SUPERVISOR_TICKET,
  GET_ALL_SHIPS,
  GET_ALL_COUNTERMAN_SHIP,
  GET_ALL_SHIPS_BY_ROUTE,
  GET_ALL_SHIPS_BY_DECK,
  ADD_SHIP,
  UPDATE_SHIP,
  COPY_SHIP,
  USER_WISE_REPORT,
  // GET_ALL_SEAT_CLASSES,
  ADD_FLOOR,
  UPDATE_FLOOR,
  DELETE_FLOOR,
  ADD_SEAT_CLASS,
  UPDATE_SEAT_CLASS,
  DELETE_SEAT_CLASS,
  ADD_SEAT_ROW,
  ADD_CUSTOM_SEAT_ROW,
  EDIT_CUSTOM_SEAT_ROW,
  DELETE_SEAT_ROW,
  GET_ALL_TRIPS,
  ADD_TRIP,
  UPDATE_TRIP,
  GET_ALL_DECKMANS,
  ADD_DECKMAN,
  UPDATE_DECKMAN,
  LOGOUT_DECKMAN,
  GET_ALL_CABINMANS,
  ADD_CABINMAN,
  UPDATE_CABINMAN,
  LOGOUT_CABINMAN,
  SEAT_PERMISSION_CABINMAN,
  GET_CABIN_SEATLIST,
  GET_PERMITTED_CABIN_SEATLIST,
  TRIP_COMPLETED,
  GET_SHIP_INFO,
  COMPANY_LIST,
  SHIP_WISE_REPORT,
  COUNTER_WISE_REPORT,
  RESERVATION_WISE_REPORT,
  SHIP_WISE_DECK_REPORT_DETAILS,
  SHIP_WISE_GOOD_REPORT_DETAILS,
  GET_ALL_TRIPS_FOR_TICKETING,
  GET_RESERVATION_KEY,
  GET_ALL_DASHBOARD_TICKETS,
  COUNTER_WISE_REPORT_DETAILS,
  RESERVATION_WISE_REPORT_DETAILS,
  GET_DIGITAL_TICKETING_STOPPAGES,
  ADD_DIGITAL_TICKETING_STOPPAGE,
  UPDATE_DIGITAL_TICKETING_STOPPAGE,
  GET_DIGITAL_TICKETING_ALL_STOPPAGE,
  TICKET_PURCHASE,
  TICKET_RESERVE,
  TICKET_PRINT_COUNT,
  PRINT_COUNT,
  REPORT_PRINT_COUNT,
  REPORT_PRINT_COUNT_INCREASE,
  VERIFY_TICKET_LIST,
  COUNTER_ALL_SHIPS,
  COUNTER_ALL_TRIPS,
  TICKETS_HISTORY_COUNTER,
  RESERVED_TICKETS_HISTORY_COUNTER,
  TICKET_CANCEL,
  RESERVE_TICKET_CANCEL,
  RESERVE_TICKET_CONFIRM,
  GET_ALL_COUNTERMAN_REPORT,
  GET_ALL_COUNTERMAN_USER_REPORT,
  GET_COUNTERMAN_TRIPWISE_REPORT,
  GET_LAST_TICKET,
  AGENT_LIST,
  AGENT_ADD,
  SEAT_BLOCK,
  ADD_DT_UP_SEAT,
  ADD_DT_DOWN_SEAT,
  AGENT_WISE_REPORT,
  DECKMAN_WISE_REPORT,
  DECKMAN_WISE_REPORT_OWNER,
  AGENT_WISE_SEAT_REPORT_DETAILS,
  DECKMAN_WISE_REPORT_DETAILS,
  CANCEL_DECKMAN_WISE_REPORT_DETAILS,
  CANCEL_CABINMAN_REPORT_DETAILS,
  DECKMAN_WISE_REPORT_DETAILS_OWNER,
  GET_ALL_DASHBOARD_TICKETS_OWNER,
  SHIP_WISE_REPORT_OWNER,
  GET_ALL_SHIPS_OWNER,
  CANCEL_USER_TICKET_OWNER,
  SHIP_WISE_DECK_REPORT_DETAILS_OWNER,
  SHIP_WISE_GOOD_REPORT_DETAILS_OWNER,
  SHIP_WISE_SEAT_REPORT_DETAILS_OWNER,
  COUNTER_WISE_REPORT_OWNER,
  GET_ALL_OWNER_SHIPS_BY_DECK,
  GET_ALL_COUNTER_OWNER,
  COUNTER_WISE_DETAILS_REPORT_OWNER,
  RESERVATION_WISE_REPORT_OWNER,
  RESERVATION_WISE_REPORT_DETAILS_OWNER,
  AGENT_WISE_REPORT_OWNER,
  AGENT_WISE_REPORT_DETAILS_OWNER,
  GET_ALL_TRIPS_FOR_TICKETING_BY_AGENT,
  GET_LAST_TICKET_BY_AGENT,
  GET_AGENT_TRIPWISE_REPORT,
  GET_ALL_AGENT_REPORT,
  TICKET_PURCHASE_BY_AGENT,
  AGENT_ALL_SHIPS,
  AGENT_ALL_TRIPS,
  GET_ALL_OWNERS_PANEL,
  ADD_OWNER_PANEL,
  UPDATE_OWNER_PANEL,
  OWNER_ADD_FLOORPLAN,
  OWNER_UPDATE_FLOORPLAN,
  OWNER_DELETE_FLOORPLAN,
  DELETE_BOARDING_PLACE_FOR_ROUTE,
  DELETE_DROPPING_POINT_FOR_ROUTE,
  CANCEL_COUNTERMAN_TICKET,
  CANCEL_USER_TICKET,
  // DELETE_OWNER_PANEL,
  OWNER_DELETE_BOARDING_PLACE_FOR_ROUTE,
  OWNER_DELETE_DROPPING_POINT_FOR_ROUTE,
  OWNER_GET_ALL_LOCAL_ROUTES,
  OWNER_ADD_LOCAL_ROUTE,
  OWNER_UPDATE_LOCAL_ROUTE,
  OWNER_DELETE_LOCAL_ROUTE,
  AGENT_LIST_OWNER,
  AGENT_ADD_OWNER,
  USER_WISE_REPORT_OWNER,
  GET_ALL_DECKMANS_OWNER,
  ADD_DECKMAN_OWNER,
  UPDATE_DECKMAN_OWNER,
  GET_ALL_COUNTERS_OWNER,
  GET_ALL_STOPPAGES_OWNER,
  ADD_COUNTER_OWNER,
  UPDATE_COUNTER_OWNER,
  GET_ALL_COUNTERMANS_OWNER,
  ADD_COUNTERMAN_OWNER,
  UPDATE_COUNTERMAN_OWNER,
  GET_ALL_ROUTES_OWNER,
  ADD_SHIP_OWNER,
  UPDATE_SHIP_OWNER,
  ADD_SEAT_CLASS_OWNER,
  ADD_SEAT_ROW_OWNER,
  ADD_CUSTOM_SEAT_ROW_BY_OWNER,
  EDIT_CUSTOM_SEAT_ROW_BY_OWNER,
  SEAT_BLOCK_OWNER,
  DELETE_SEAT_CLASS_OWNER,
  UPDATE_SEAT_CLASS_OWNER,

  GET_OWNER_SERVICES_ALL_CABINMANS,
  ADD_OWNER_SERVICES_CABINMAN,
  UPDATE_OWNER_SERVICES_CABINMAN,
  LOGOUT_OWNER_SERVICES_CABINMAN,
  SEAT_OWNER_SERVICES_PERMISSION_CABINMAN,
  GET_OWNER_SERVICES_CABIN_SEATLIST,
  GET_OWNER_SERVICES_PERMITTED_CABIN_SEATLIST,

  GET_OWNER_SERVICES_STOPAGE,
  GET_OWNER_SERVICES_ROUTE,
  GET_OWNER_SERVICES_BOARDING_PLACE,
  GET_OWNER_SERVICES_DROPPING_PLACE,
  GET_OWNER_SERVICES_TRIPS,
  GET_ALL_OWNER_SERVICES_SHIPS_BY_ROUTE,
  GET_ALL_OWNER_SERVICES_SHIP_INFO,
  GET_ALL_COUNTERMAN_STOPPAGES,
  GET_ALL_COUNTERMAN_ROUTES,
  GET_ALL_COUNTERMAN_COUNTERS,
  GET_ALL_COUNTERMAN_SHIPS,
  VERIFY_TICKET_COUNTERMAN,
  GET_ALL_AGENT_STOPPAGES,
  GET_ALL_SUPERVISOR_STOPPAGES,
  GET_ALL_SUPERVISOR_SHIPS,
  GET_ALL_SUPERVISOR_TRIPS,
  GET_ALL_SUPERVISOR_TRIPS_REPORT_LIST,
  GET_ALL_SUPERVISOR_ROUTES,
  GET_ALL_SUPERVISOR_SHIPS_BY_ROUTE,
  ADD_SUPERVISOR_TRIP,
  UPDATE_SUPERVISOR_TRIP,
  GET_ALL_SUPERVISOR_REPORT,
  GET_SUPERVISOR_REPORT_FOR_PRINT,
  SUPERVISOR_REPORT_PRINT_COUNT,
  SUPERVISOR_REPORT_PRINT_COUNT_INCREASE,
  GET_ALL_AGENT_SHIPS,
  DELETE_SEAT_ROW_OWNER,
  AGENT_RECHARGE_BALANCE,
  PRINT_COUNT_OWNER,


  // Cabinman report
  CABINMAN_WISE_REPORT,
  CABINMAN_WISE_REPORT_DETAILS,
  CABINMAN_WISE_REPORT_OWNER,
  CABINMAN_WISE_REPORT_DETAILS_OWNER
};
