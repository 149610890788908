<template>
  <div class="form-check text-white">
    <input
      class="form-check-input"
      type="checkbox"
      v-model="checked"
      id="defaultCheck1"
    />
    <label class="form-check-label" for="defaultCheck1"> Discount </label>
  </div>
</template>

<script>
import { ref, watch } from "vue";

export default {
  name: "CheckboxSingle",
  props: {
    modelValue: Boolean,
  },
  setup(props, { emit }) {
    let checked = ref(props.modelValue);
    watch(
      () => checked.value,
      (value) => {
        emit("update:modelValue", value);
      }
    );

    return { checked };
  },
};
</script>

<style scoped>
.form-check {
  margin-top: 19px;
}
.form-check-input {
  width: 1.6em;
  height: 1.6em;
  margin-right: 3px;
}
label {
  vertical-align: sub;
}
</style>