import axios from "axios";
import Apis from "@/urls/apis";
import toast from "@/modules/Toast";
import tokenCookie from "@/modules/tokenCookie";
import apiResponse from "@/modules/apiResponse";

const {hasValidToken, getToken} = tokenCookie();
const {errorResponse} = apiResponse();
const {infoToast} = toast()

const state = {
    filter: {
        ship: {},
    },
    reports: {},

};
const getters = {
    getCountermanReport: (state) => state.reports,
    getCountermanShipListInFilter: (state) => state.filter.ship,
};
const actions = {
    async getAllShipList({ commit }, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };

            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_ALL_COUNTERMAN_SHIP, {
                        params: payload,
                        headers: header,
                    })
                    .then(({ data, status }) => {
                        if (status === 200) {
                            let shipList = {};
                            data.ship.forEach((ship) => {
                                shipList[ship._id] = ship.name;
                            });
                            commit("setShipListInFilter", shipList);
                            resolve(data.ship);
                        }
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    async getAllUserWiseReportCounterman(_, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };

            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_ALL_COUNTERMAN_USER_REPORT, {
                        params: payload,
                        headers: header,
                    })
                    .then(({ data, status }) => {
                        if (data.ticket.length === 0) {
                            infoToast("No Data Available")
                        }
                        if (status === 200) {
                            resolve(data.ticket);
                        }
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    async getAllCountermanReport({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };

            return new Promise((resolve, reject) => {
                axios.get(Apis.GET_ALL_COUNTERMAN_REPORT, {
                    params: payload,
                    headers: header
                })
                    .then(({data, status}) => {
                        if (status === 200) {
                            commit("setAllCounterManReport", data)
                            resolve(data)
                        }
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async getCountermanTripReport(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };

            return new Promise((resolve, reject) => {
                axios.get(Apis.GET_COUNTERMAN_TRIPWISE_REPORT, {
                    params: payload,
                    headers: header
                })
                    .then(({data, status}) => {
                        if (status === 200) {
                            resolve(data)
                        }
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },
};
const mutations = {
    setAllCounterManReport: (state, reports) => (state.reports = reports),
    setShipListInFilter: (state, shipList) => (state.filter.ship = shipList),
};

export default {
    state,
    getters,
    actions,
    mutations
};