<template>
    <div>
        <FilterTab>
            <template v-slot:title><h6>Local Route</h6></template>
            <template v-slot:body>
                <div class="col-sm-6 col-md-2">
                    <!--          <DropdownFilter v-bind:id="'status'" v-bind:label="'Status'" v-bind:defaultOption="'&#45;&#45;select status&#45;&#45;'"-->
                    <!--                          v-bind:defaultValue="''"-->
                    <!--                          v-bind:dropdown="dropdownVariables.status"/>-->
                </div>
            </template>
            <template v-slot:button2>
                <button
                        v-if="hasPermission(Admin.companyDeployWrite)"
                        class="edit_table_data font_detail"
                        @click="goBack"
                >
                    <i class="fas fa-arrow-left"></i
                    ><span class="mx-1 bold">Go Back</span>
                </button>
            </template>
            <template v-slot:button1>
                <button
                        v-if="hasPermission(Admin.companyDeployWrite)"
                        class="edit_table_data font_detail"
                        @click="addModal"
                >
                    <i class="fas fa-plus"></i><span class="mx-1 bold">Add New</span>
                </button>
                <button v-else class="edit_table_data font_detail" @click="goBack">
                    <i class="fas fa-arrow-left"></i
                    ><span class="mx-1 bold">Go Back</span>
                </button>
            </template>
        </FilterTab>
        <Table>
            <template v-slot:header>
                <tr>
                    <td class="font_detail">SL</td>
                    <td class="font_detail">Boarding Place</td>
                    <td class="font_detail">Dropping Place</td>
                    <td class="font_detail">Up Fare</td>
                    <td class="font_detail">Down Fare</td>
                    <td
                            v-if="hasPermission(Admin.companyDeployWrite)"
                            class="font_detail"
                    >
                        ACTION
                    </td>
                </tr>
            </template>
            <template v-slot:body v-if="$store.getters.getLocalRoutes">
                <tr
                        v-for="(localRoutes, index) in $store.getters.getLocalRoutes"
                        :key="index"
                >
                    <td class="font_detail">{{ index + 1 }}</td>
                    <td class="font_detail">{{localRoutes.boardingPlace}}</td>
                    <td class="font_detail">{{localRoutes.droppingPoint}}</td>
                    <td class="font_detail">{{localRoutes.upFare}}</td>
                    <td class="font_detail">{{localRoutes.downFare}}</td>
                    <td v-if="hasPermission(Admin.companyDeployWrite)">
                        <button class="edit_table_data font_detail edit_table_button" @click="updateModal(localRoutes)">
                            <i class="fas fa-edit"></i><span class="mx-1">Edit</span>
                        </button>
                        <button class="header_delete_button font_detail" @click="deleteLocalRoute(index, localRoutes)">
                            <i class="fas fa-trash"></i><span class="mx-1">Delete</span>
                        </button>
                    </td>
                </tr>
            </template>
        </Table>
        <div v-if="showModal">
            <LocalRouteAddUpdateModal
                v-model="showModal"
                @getAllLocalRoutes="getAllLocalRoutes"
                :localRouteObject=$store.getters.getPlaces
                :boardingPlaceforLocalRoutes = "localRoutesBoardingPlace"
                :droppingPointforLocalRoutes = "localRoutesDroppingPoint"
                :localRoutesUpFare = "localRoutesUpFare"
                :localRoutesDownFare = "localRoutesDownFare"
                :localRoutesID = "localID"
                :routeId="localRoutesID"
                :rId="rId"
            />
        </div>
        <div v-if="showModalDelete">
            <LocalRouteDeleteModal
                v-model="showModalDelete"
                @getAllLocalRoutes="getAllLocalRoutes"
                :routeIdValue="rId"
                :localRoutesIdValue = "localRoutesID"
            />
        </div>
    </div>
</template>

<script>
import store from "@/store";
import dropdownVariables from "@/modules/dropdownVariables";
import {reactive} from "@vue/reactivity";
import {inject, onBeforeMount, ref} from "vue";
import LocalRouteAddUpdateModal from "./LocalRouteAddUpdateModal";
import LocalRouteDeleteModal from "./LocalRouteDeleteModal";
import permission from "@/modules/permission";
import roles from "@/modules/roles";

const {hasPermission} = permission();
const {Admin} = roles();

export default {
    name: "LocalRoute",
    components: {LocalRouteAddUpdateModal,LocalRouteDeleteModal},
    props: {
        localRouteData: Object,
    },
    setup(props, {emit}) {
        let pagination = reactive({
            page: 1,
            limit: 10,
            routeId: "",
        });
        const options = {
            texts: {
                count: "",
            },
        };
        const rId = ref(props.localRouteData.routeId);
        const localRoutesBoardingPlace = ref("");
        const localRoutesDroppingPoint = ref("");
        const localRoutesUpFare = ref("");
        const localRoutesDownFare = ref("");
        const localRoutesID = ref("");
        const localID = ref("");
        const showModalDelete = ref(false);
        const showModal = ref(false);
        const showLoader = inject("showLoader");
        const hideLoader = inject("hideLoader");

        const getAllLocalRoutes = () => {
            showLoader();
            store
                .dispatch("getLocalRoutesByPage", pagination)
                .then(() => {
                    hideLoader();
                })
                .catch((error) => {
                    hideLoader();
                    if (error.status === 401) {
                        getAllLocalRoutes();
                    }
                });
        };
        const goBack = () => {
            emit("toggle");
        };
        const addModal = () => {
            localRoutesBoardingPlace.value = "";
            localRoutesDroppingPoint.value = "";
            localRoutesUpFare.value = "";
            localRoutesDownFare.value = "";
            localRoutesID.value = "";
            showModal.value = true;
        };
        const updateModal = (value) => {
            localRoutesBoardingPlace.value = value.boardingPlace;
            localRoutesDroppingPoint.value = value.droppingPoint;
            localRoutesUpFare.value = value.upFare;
            localRoutesDownFare.value = value.downFare;
            localRoutesID.value = value.routeId;
            localID.value = value._id;
            showModal.value = true;
        };
        const deleteLocalRoute = (index, value) => {
            showModalDelete.value = true;
            localRoutesID.value = value._id;
        };

        onBeforeMount(() => {
            if (rId.value) {
                pagination.routeId = rId.value;
                getAllLocalRoutes();
            }
        });

        return {
            dropdownVariables,
            pagination,
            showModal,
            rId,
            options,
            Admin,
            hasPermission,
            getAllLocalRoutes,
            addModal,
            updateModal,
            goBack,
            deleteLocalRoute,
            showModalDelete,
            localRoutesBoardingPlace,
            localRoutesDroppingPoint,
            localRoutesUpFare,
            localRoutesDownFare,
            localRoutesID,
            localID
        };
    },
};
</script>

<style scoped>
.header_delete_button {
  margin: 0 2px;
  border: 2px solid #ff072c;
  border-radius: 4px;
  background-color: #ff072c;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.header_delete_button:hover,
.header_delete_button:focus {
  border: 2px solid #ff072c;
  background-color: #fff;
  color: #ff072c;
  transition: 0.4s;
  outline: 0;
}
.bold {
    font-weight: bold;
}
</style>