<template>
  <CountermanNav/>
  <router-view></router-view>
</template>

<script>
import CountermanNav from "../../components/layouts/Nav/CountermanNav";
export default {
  name: "Counterman",
  components: {CountermanNav}
}
</script>

<style scoped>

</style>