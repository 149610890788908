import XLSX from "xlsx";

export default {
    install: (app) => {
        const exportExcel = (type, fileName, dl, tableId) => {
            if (document.getElementById(tableId) !== null) {
              var elt = document.getElementById(tableId);
              var wb = XLSX.utils.table_to_book(elt, { sheet: "sheet Js" });
              dl
                ? XLSX.write(wb, { bookType: type, bookSST: true, type: "base64" })
                : XLSX.writeFile(
                    wb,
                    (fileName || "Report.") + (type || "xlsx")
                  );
            }
          };  
        app.provide('exportExcel', exportExcel);     
    }
}