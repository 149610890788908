<template>
  <Modal @close="closeModal" v-bind:size="'xl'">
    <template v-slot:title>{{ title }}</template>
    <template v-slot:body>
      <form>
        <div class="auth_form py-3">
          <div class="row my-2">
            <div class="col-md-6 col-lg-4 col-xl-3 mt-3">
              <Input
                v-model="deckmanData.name"
                v-bind:placeholder="'Enter Deckman Name'"
                v-bind:label="'Name'"
                v-bind:id="'name'"
                v-bind:type="'text'"
              />
              <Error v-if="errors.name" v-bind:error="errors.name" />
            </div>
            <div class="col-md-6 col-lg-4 col-xl-3 mt-3">
              <Input
                v-model="deckmanData.phone"
                v-bind:placeholder="'Enter Phone Number'"
                v-bind:label="'Phone'"
                v-bind:id="'phone'"
                v-bind:type="'text'"
              />
              <Error v-if="errors.phone" v-bind:error="errors.phone" />
            </div>
            <div class="col-md-6 col-lg-4 col-xl-3 mt-3">
              <Input
                v-model="deckmanData.password"
                v-bind:placeholder="'Enter Password'"
                autocomplete="off"
                v-bind:label="'Password'"
                v-bind:id="'password'"
                v-bind:type="fieldTypes.password"
              />
              <Error v-if="errors.password" v-bind:error="errors.password" />
            </div>
            <div class="col-md-6 col-lg-4 col-xl-3 mt-3">
              <Input
                v-model="deckmanData.email"
                v-bind:placeholder="'Email Address'"
                autocomplete="off"
                v-bind:label="'Email'"
                v-bind:id="'email'"
                v-bind:type="fieldTypes.email"
              />
            </div>

            <div class="col-md-6 col-lg-4 col-xl-3 mt-3">
              <Input
                v-model="deckmanData.reportPrintLimit"
                v-bind:placeholder="'Print Limit'"
                v-bind:label="'Report Print Limit'"
                v-bind:id="'reportPrintLimit'"
                v-bind:type="'number'"
              />
              <Error
                v-if="errors.reportPrintLimit"
                v-bind:error="errors.reportPrintLimit"
              />
            </div>
            <div class="col-md-6 col-lg-4 col-xl-3 mt-3">
              <Input
                v-model="deckmanData.nid"
                v-bind:placeholder="'NID Number'"
                v-bind:label="'NID'"
                v-bind:id="'nid'"
                v-bind:type="'text'"
              />
            </div>
            <div class="col-md-6 col-lg-4 col-xl-3 mt-3">
              <Dropdown
                v-if="status"
                v-model="deckmanData.status"
                v-bind:id="'status'"
                v-bind:label="'Status'"
                v-bind:dropdown="status"
              />
            </div>
            <div class="col-md-6 col-lg-4 col-xl-3 mt-3">
              <!--              <MultiSelectDropdown v-model="deckmanData.shipId" v-bind:label="'Ships'" v-bind:id="'ships'"/>-->
              <label class="label_font">Ships</label>
              <Multiselect
                class="w-100 mt-1 mb-4"
                placeholder="Select ships"
                v-model="deckmanData.shipId"
                :options="$store.getters.getShipListForFilter"
                id="multiselectId"
                mode="single"
                :searchable="true"
                :createTag="true"
              />
              <Error v-if="errors.ship" v-bind:error="errors.ship" />
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <label for="address" class="form-label all_permission_label"
                >Address</label
              >
              <div>
                <textarea
                  class="form-control font_detail"
                  id="address"
                  v-model="deckmanData.address"
                ></textarea>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group row my-1">
          <label class="col-sm-3 col-form-label"></label>
          <div class="col-sm-9">
            <input
              v-if="!deckmanIdForUpdate"
              type="submit"
              class="mt-3 py-2 submit_button font_detail float-end"
              @click.prevent="addDeckMan"
            />
            <input
              v-else
              type="submit"
              class="mt-3 py-2 submit_button font_detail float-end"
              @click.prevent="updateDeckMan"
            />
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
import { reactive } from "@vue/reactivity";
import dropdownVariables from "@/modules/dropdownVariables";
import useInputValidation from "@/modules/useInputValidations";
import { inject, onBeforeMount, ref, watch } from "vue";
import store from "@/store";
import Multiselect from "@vueform/multiselect";

export default {
  name: "DeckmanAddUpdateModal",
  props: {
    modalValue: Boolean,
    deckman: String,
    company: String,
  },
  components: {
    Multiselect,
  },
  setup(props, { emit }) {
    let deckmanData = reactive({
      id: "",
      name: "",
      designation: "",
      phone: "",
      password: "",
      email: "",
      nid: "",
      address: "",
      avatar: "",
      transactionType: "",
      balance: "",
      commission: "",
      userType: "deckman",
      companyId: "",
      counterId: "",
      shipId: [],
      commissionType: "",
      roles: "",
      status: "1",
      api_token: "",
      device_token: "",
      reset_token: "",
      reportPrintLimit: 1,
    });
    let deckmanIdForUpdate = ref(props.deckman);
    let companyId = ref(props.company);
    let {
      requiredValidation,
      passwordValidation,
      phoneValidation,
      validNumberValidation,
      errors,
    } = useInputValidation();
    const status = dropdownVariables.status;
    const title = ref("Add Deckman");
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");

    let fieldTypes = reactive({
      email: "text",
      password: "text",
    });

    watch(
      () => deckmanData.name,
      () => {
        requiredValidation("name", deckmanData.name);
      }
    );
    watch(
      () => deckmanData.phone,
      () => {
        phoneValidation("phone", deckmanData.phone);
      }
    );
    watch(
      () => deckmanData.password,
      () => {
        passwordValidation("password", deckmanData.password);
      }
    );
    watch(
      () => deckmanData.shipId,
      () => {
        requiredValidation("ship", deckmanData.shipId);
      }
    );
    watch(
      () => deckmanData.reportPrintLimit,
      () => {
        validNumberValidation("reportPrintLimit", deckmanData.reportPrintLimit);
      }
    );
    watch(
      () => deckmanData.password,
      () => {
        fieldTypes.password = "password";
      }
    );
    watch(
      () => deckmanData.email,
      () => {
        fieldTypes.email = "email";
      }
    );

    const closeModal = () => {
      emit("update:modelValue", false);
    };

    const validate = () => {
      Object.keys(errors).forEach(function (key) {
        errors[key] = "";
      });
      requiredValidation("name", deckmanData.name);
      phoneValidation("phone", deckmanData.phone);
      requiredValidation("ship", deckmanData.shipId);
      validNumberValidation("reportPrintLimit", deckmanData.reportPrintLimit);
      if (deckmanIdForUpdate.value === "") {
        passwordValidation("password", deckmanData.password);
      } else {
        if (deckmanData.password !== "") {
          passwordValidation("password", deckmanData.password);
        }
      }
      return !(
        Object.values(errors).length <= 0 ||
        !Object.values(errors).every((x) => x === null || x === "")
      );
    };

    const addDeckMan = () => {
      if (validate()) {
        showLoader();
        store
          .dispatch("addDeckMan", deckmanData)
          .then(() => {
            hideLoader();
            emit("getAllDeckman");
            closeModal();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              addDeckMan();
            }
          });
      }
    };
    const updateDeckMan = () => {
      if (validate()) {
        showLoader();
        store
          .dispatch("updateDeckMan", deckmanData)
          .then(() => {
            emit("getAllDeckman");
            closeModal();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              updateDeckMan();
            }
          });
      }
    };

    onBeforeMount(() => {
      Object.keys(errors).forEach(function (key) {
        delete errors[key];
      });
      deckmanData.companyId = companyId.value;
      if (deckmanIdForUpdate.value !== "") {
        const deckMan = store.getters.getDeckMan(deckmanIdForUpdate.value);
        title.value = "Update Deckman";
        deckmanData.id = deckMan[0]._id;
        deckmanData.companyId = deckMan[0].companyId;
        deckmanData.name = deckMan[0].name;
        deckmanData.phone = deckMan[0].phone;
        deckmanData.email = deckMan[0].email;
        deckmanData.nid = deckMan[0].nid;
        deckmanData.address = deckMan[0].address;
        deckmanData.status = deckMan[0].status ? 1 : 0;
        deckMan[0].shipId.forEach((item) => {
          deckmanData.shipId.push(item._id);
        });
        deckmanData.reportPrintLimit = parseInt(deckMan[0].reportPrintLimit);
      }
    });

    return {
      status,
      deckmanData,
      errors,
      title,
      deckmanIdForUpdate,
      updateDeckMan,
      addDeckMan,
      closeModal,
      fieldTypes,
    };
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>

<style scoped></style>
