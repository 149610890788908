<template>
  <div>
    <FilterTab>
      <template v-slot:title><h6>Agent</h6></template>
      <template v-slot:body>
        <div class="col-sm-6 col-md-2">
          <!--          <DropdownFilter v-bind:id="'status'" v-bind:label="'Status'" v-bind:defaultOption="'&#45;&#45;select status&#45;&#45;'"-->
          <!--                          v-bind:defaultValue="''"-->
          <!--                          v-bind:dropdown="dropdownVariables.status"/>-->
        </div>
      </template>
      <template v-if="hasPermission(Admin.companyDeployWrite)" v-slot:button1>
          <button class="edit_table_data font_detail " @click="addModal"><i
                  class="fas fa-plus"></i><span class="mx-1 bold">Add New</span>
          </button>
      </template>
    </FilterTab>
    <Table v-if="$store.getters.getAllAgentList">
      <template v-slot:header>
        <tr>
          <td class="font_detail"> SL</td>
          <td class="font_detail"> NAME</td>
          <td class="font_detail"> MOBILE</td>
          <td class="font_detail"> EMAIL</td>
          <td class="font_detail"> USER TYPE</td>
          <td class="font_detail"> STATUS</td>
          <td v-if="hasPermission(Admin.companyDeployWrite)" class="font_detail"> ACTION</td>
        </tr>
      </template>
      <template v-slot:body>
        <tr v-for="(agent,index) in $store.getters.getAllAgentList" :key="agent._id">
          <td class="font_detail">{{ pagination.limit*(pagination.page-1) +index +1 }}</td>
          <td class="font_detail">{{ agent.name }}</td>
          <td class="font_detail">{{ agent.phone }}</td>
          <td class="font_detail">{{ agent.email }}</td>
          <td class="font_detail">{{ dropdownVariables.userType[agent.userType] }}</td>
          <td><span v-if="agent.status" class="badge bg-success">{{ dropdownVariables.status[1] }}</span>
            <span v-else class="badge bg-danger">{{ dropdownVariables.status[0] }}</span>
          </td>
          <td v-if="hasPermission(Admin.companyDeployWrite)">
            <button class="edit_table_data font_detail edit_table_button" @click="updateModal(agent._id)"><i
                class="fas fa-edit"></i><span class="mx-1">Edit</span></button>
          </td>
        </tr>
      </template>
      <template v-slot:pagination>
        <pagination v-model="pagination.page" :records="$store.getters.getTotalAllAgentList" :per-page="pagination.limit"
                    @paginate="getAgentList" :options="options"/>
      </template>
    </Table>
    <div v-if="showModal">
      <AgentAddUpdateModal v-model="showModal"  @getAllAgents="getAgentList" :agentId="agentId" :companyId="pagination.companyId "/>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import dropdownVariables from "@/modules/dropdownVariables";
import {reactive} from "@vue/reactivity";
import {inject, onBeforeMount, ref} from "vue";
import {useRoute} from "vue-router";
import AgentAddUpdateModal from "./AgentAddUpdateModal.vue";
import permission from "@/modules/permission";
import roles from "@/modules/roles";

const {hasPermission} = permission();
const {Admin} = roles();

export default {
  name: "Counterman",
  components: {AgentAddUpdateModal},

  setup() {
    let pagination = reactive({
      page: 1,
      limit: 10,
      companyId: ''
    });
    const options = {
      texts: {
        count: ""
      }
    };
    const route = useRoute();
    const showLoader = inject('showLoader');
    const hideLoader = inject('hideLoader');
    const agentId = ref("");
    const showModal = ref(false);

      const getAgentList = () => {
          showLoader()
          store.dispatch('getAgentList', pagination).then(() => {
              hideLoader()
          }).catch((error) => {
              if (error.status === 401) {
                  getAgentList()
              }       

          });
      };
      // const goBack = () => {
      //   emit("toggle")
      // };
      const addModal = () => {
          agentId.value = "";
          showModal.value = true;
      };
      const updateModal = (value) => {
          agentId.value = value;
          showModal.value = true;
      };

      onBeforeMount(() => {
          pagination.companyId = route.query.id
          getAgentList();
      });

      return {
          dropdownVariables, pagination, options, showModal, agentId, Admin,
          hasPermission,
          addModal, getAgentList, updateModal
      };
  }
};
</script>

<style scoped>
.bold{
  font-weight: bold;
}

</style>