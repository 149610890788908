<template>
  <div :class="isAdminOwnerDashboardPermission ? '' : 'large_device_view'">
    <section class="select_area py-3">
      <!-- Searching menu -->
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-8 col-md-9 col-lg-9">
            <div class="row font_detail">
              <slot name="body"> </slot>
            </div>
          </div>
          <div class="col-sm-4 col-md-3 col-lg-3">
            <div class="row button_section justify-content-end">
              <!--                            <div class="col-sm-4 col-md-6 col-lg-4 order-md-2 order-sm-3 order-lg-3 p-1 d-flex justify-content-end"><slot name="button1"></slot></div>-->
              <!--                            <div class="col-sm-4 col-md-6 col-lg-4 order-md-1 order-sm-2 order-lg-2 p-1 d-flex justify-content-end"><slot name="button2"></slot></div>-->
              <!--                            <div class="col-sm-4 col-md-6 col-lg-4 order-md-3 order-sm-1 order-lg-1 p-1 d-flex justify-content-end"><slot name="button3"></slot></div>-->
              <div class="col-auto order-md-2 order-sm-3 order-lg-3 p-1">
                <slot name="button1"></slot>
              </div>
              <div class="col-auto order-md-1 order-sm-2 order-lg-2 p-1">
                <slot name="button2"></slot>
              </div>
              <div class="col-auto order-md-3 order-sm-1 order-lg-1 p-1">
                <slot name="button3"></slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  name: "Filter",

   props: {
    isAdminOwnerDashboard: Boolean,
  },

  setup(props) {
    const isAdminOwnerDashboardPermission = ref(props.isAdminOwnerDashboard);

    return {
      isAdminOwnerDashboardPermission,
    }
  }
};
</script>

<style scoped>
</style>