<template>
  <div v-if="hasPermission(Admin.dashboard)">
    <Filter :isAdminOwnerDashboard = "true">
      <template v-slot:body>
        <div class="col-12 col-sm-9 col-xl-4">
          <DateTimeRangePicker v-model="date.range" />
        </div>
        <div class="col-12 col-sm-3 col-xl-2 mt-2 mt-md-auto">
          <button class="edit_table_data font_detail" @click="getDeckAndSeat">
            <i class="fas fa-filter"></i><span class="mx-1 bold">Filter</span>
          </button>
        </div>
      </template>
    </Filter>
    <DeckAndSeat />
  </div>
</template>

<script>
import moment from "moment";
import store from "@/store";

import permission from "@/modules/permission";
import roles from "@/modules/roles";
import { inject, onBeforeMount } from "vue";

import { reactive } from "@vue/reactivity";
import DateTimeRangePicker from "@/components/layouts/Filter/Inputs/DateTimeRangePicker";
import DeckAndSeat from "@/views/Admin/Dashboard/DeckAndSeat.vue";

const { hasPermission } = permission();
const { Admin } = roles();
export default {
  name: "AdminDashboard",
  components: { DateTimeRangePicker, DeckAndSeat },

  setup() {
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    let fromDate = moment(new Date()).format("yyyy-MM-DD" + " 00:00:00");
    let toDate = moment(new Date()).format("yyyy-MM-DD" + " 23:59:59");
    let date = reactive({
      range: {
        start: fromDate,
        end: toDate,
      },
    });

    const getDeckAndSeat = () => {
      showLoader();
      const payload = {
        toDate: date.range.end,
        fromDate: date.range.start,
      };
      store
        .dispatch("getDashboardTickets", payload)
        .then(() => {
          hideLoader();
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            getDeckAndSeat();
          }
        });
    };

    onBeforeMount(() => {
      getDeckAndSeat();
    });

    return {
      date,
      Admin,
      hasPermission,
      getDeckAndSeat,
    };
  },
};
</script>

<style scoped>
</style>