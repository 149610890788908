<template>
  <div v-if="reportActive">
    <FilterTabBetween>
      <template v-slot:body>
        <div class="col-sm-6 col-md-auto">
          <DropdownFilter
            :id="'ship'"
            :label="'Ship Name'"
            :defaultOption="'--select ship--'"
            v-model="reservationwiseReport.shipId"
            :defaultValue="''"
            :dropdown="$store.getters.getCompanyInfoOwner.ships"
          />
        </div>
        <div class="col-sm-6 col-md-4">
          <DateRangePicker v-model="date.range" />
        </div>
        <div class="col-sm-6 col-md-2">
          <DropdownFilter
            :id="'status'"
            :label="'Status'"
            v-model="reservationTicketStatus"
            :dropdown="dropdownVariables.reservationReportStatus"
          />
        </div>

        <!-- <div
          v-if="reservationwiseReport.companyId"
          class="col-sm-2 col-md-auto"
        >
          <CheckboxSingle v-model="isDiscount" />
        </div> -->
        <div class="col-sm-2 col-md-auto mt-auto">
          <button class="edit_table_data font_detail" @click="filterReport">
            <i class="fas fa-filter"></i><span class="mx-1 bold">Filter</span>
          </button>
        </div>
      </template>

      <template v-slot:export>
        <button
          class="edit_table_data font_detail"
          @click="
            exportExcel(
              'xlsx',
              'Owner_Counter_Wise_Report.',
              undefined,
              'exportable_data'
            )
          "
        >
          <i class="fas fa-download"></i><span class="mx-1 bold">Export</span>
        </button>
      </template>
    </FilterTabBetween>
    <Table v-if="filterActive" id="exportable_data">
      <template v-slot:header>
        <tr>
          <td class="font_detail">SL</td>
          <td class="font_detail">Counter Man</td>
          <td v-if="reservationwiseReport.status == 'pending' || reservationwiseReport.status == ''" class="font_detail">Reserved</td>
          <td v-if="reservationwiseReport.status == 'canceled' || reservationwiseReport.status == ''" class="font_detail">Cancelled</td>
          <td v-if="reservationwiseReport.status == 'confirmed' || reservationwiseReport.status == ''" class="font_detail">Confirmed</td>
          <td class="font_detail">Total Ticket</td>
          <td class="font_detail">Total Seat</td>
          <td class="font_detail">Total Amount</td>
          <td class="font_detail">Details</td>
        </tr>
      </template>
        
      <template v-slot:body>
        <tr
          v-for="(item, index) in $store.getters.getReservationWiseReport"
          :key="item._id"
        >
          <td class="font_detail">{{ index + 1 }}</td>
          <td class="font_detail">{{ item.countermanName || "N/A" }}</td>
          <td v-if="reservationwiseReport.status == 'pending' || reservationwiseReport.status == ''"  class="font_detail">{{ item.pending }}</td>
          <td v-if="reservationwiseReport.status == 'canceled' || reservationwiseReport.status == ''" class="font_detail">{{ item.cancelled }}</td>
          <td v-if="reservationwiseReport.status == 'confirmed' || reservationwiseReport.status == ''" class="font_detail">{{ item.confirmed }}</td>
          <td class="font_detail">{{ item.totalTicket }}</td>
          <td class="font_detail">{{ item.totalSeat }}</td>
          <td class="font_detail">{{ item.totalAmount }} Taka</td>
          <td class="font_detail">
            <button
              class="btn btn-success m-1 btn-sm"
              @click="ReservationDetails(item.countermanId, item.date)"
            >
              Details
            </button>
          </td>
        </tr>
        <tr class="bg-dark text-white" v-if="summary">
          <td class="font_detail text-white"></td>
          <td class="font_detail text-white"></td>
         <td v-if="reservationwiseReport.status == 'pending' || reservationwiseReport.status == ''"  class="font_detail text-white"></td>
          <td v-if="reservationwiseReport.status == 'canceled' || reservationwiseReport.status == ''" class="font_detail text-white"></td>
          <td v-if="reservationwiseReport.status == 'confirmed' || reservationwiseReport.status == ''" class="font_detail text-white"></td>
          <td class="font_detail text-white">Total Ticket: {{ ticketTotal }}</td>
          <td class="font_detail text-white">Total Seat: {{ seatTotal }}</td>
          <td class="font_detail text-white">
            Total Amount: {{ amountTotal }} Taka
          </td>
          <td class="font_detail text-white"></td>
        </tr>
      </template>
    </Table>
  </div>
  <ReservationDetails
    v-if="ReservationDetailsActive"
    :counterManId="counterManIdPass"
    :shipName="reservationwiseReport.shipId"
    :status="reservationwiseReport.status"
    :fromDate="date.range.start"
    :toDate="date.range.end"
    v-on:backToShipWiseReport="onChildClick"
  />

  <router-view></router-view>
</template>

<script>
import DateRangePicker from "@/components/layouts/Filter/Inputs/DateRangePicker";
import DropdownFilter from "@/components/layouts/Filter/Inputs/Dropdown";
import dropdownVariables from "@/modules/dropdownVariables";
import { reactive } from "@vue/reactivity";
import moment from "moment";
import { watch, onBeforeMount, ref, inject } from "vue";
import store from "@/store";
import ReservationDetails from "./ReservationDetails.vue";

export default {
  name: "CounterReport",
  components: {
    DropdownFilter,
    DateRangePicker,
    ReservationDetails,
  },

  setup() {
    let deckDetailsActive = ref(false);
    let ReservationDetailsActive = ref(false);
    let reportActive = ref(true);
    let counterManIdPass = ref("");
    let sentDate = ref("");
    let filterActive = ref(false);

    let ticketTotal = ref(0);
    let seatTotal = ref(0);
    let amountTotal = ref(0);
    let seatTicketTotal = ref(0);
    let seatAmountTotal = ref(0);
    let isDiscount = ref(false);
    let summary = ref(false);
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const exportExcel = inject("exportExcel");

    const fromDate = moment(new Date()).format("yyyy-MM-DD");
    const toDate = moment(new Date()).format("yyyy-MM-DD");
    const reservationTicketStatus = ref('confirmed');
    
    let date = reactive({
      range: {
        start: fromDate,
        end: toDate,
      },
    });
    let reservationwiseReport = reactive({
      companyId: "",
      fromDate: "",
      toDate: "",
      status: "",
      shipId: "",
      // page: 1,
      // limit: 10,
    });

    watch(
      () => reservationwiseReport.companyId,
      (value) => {
        reservationwiseReport.companyId = value;
      }
    );
    watch(
      () => reservationwiseReport.shipId,
      (value) => {
        reservationwiseReport.shipId = value;
      }
    );

    watch(
      () => date.range.start,
      (value) => {
        reservationwiseReport.fromDate = moment(value).format(
          "yyyy-MM-DD"
        );
      }
    );
    watch(
      () => date.range.end,
      (value) => {
        reservationwiseReport.toDate = moment(value).format(
          "yyyy-MM-DD"
        );
      }
    );
    watch(
      () => reservationwiseReport.status,
      (value) => {
        reservationwiseReport.status = value;
      }
    );
    watch(
      () => reservationwiseReport.companyId,
      () => {
        if (reservationwiseReport.companyId !== "") {
          getAllCounter();
        }
      }
    );

    const getAllCounter = () => {
      showLoader();
      store
        .dispatch("getCounterList", {
          companyId: reservationwiseReport.companyId,
        })
        .then(() => hideLoader())
        .catch(() => hideLoader());
    };

    const getAllCompanyList = () => {
      showLoader();
      store
        .dispatch("getAllCompanyList")
        .then(() => hideLoader())
        .catch(() => hideLoader());
    };
    const getCompanyInformation = () => {
      showLoader();
      store
        .dispatch("getCompanyInfoOwner", {
          companyId: reservationwiseReport.companyId,
        })
        .then(() => hideLoader())
        .catch(() => hideLoader());
    };
    // const getAllShipReport = () => {
    //   store.dispatch("getAllShipReport", counterwiseReport);
    // };
    const filterReport = () => {
      reservationwiseReport.status = reservationTicketStatus.value;
      showLoader();
      filterActive.value = true;
      store
        .dispatch("getfilterReservationWiseReportOwner", reservationwiseReport)
        .then((response) => {
          if (response.length === 0) {
            summary.value = false;
          } else {
            summary.value = true;
            ticketTotal.value = 0;
            seatTotal.value = 0;
            amountTotal.value = 0;
            sumListData();
          }
          hideLoader();
        })
        .catch(() => hideLoader());
      // getAllShipReport();
    };

    const sumListData = () => {
      store.getters.getReservationWiseReportOwner.forEach((data) => {
        ticketTotal.value += data.totalTicket;
        seatTotal.value += data.totalSeat;
        amountTotal.value += data.totalAmount;
      });
    };

    onBeforeMount(() => {
      reservationwiseReport.companyId = store.getters.userCompany;
      getAllCompanyList();
      reservationwiseReport.fromDate = fromDate;
      reservationwiseReport.toDate = toDate;
      getCompanyInformation();
    });

    const ReservationDetails = (counterManId, date) => {
      counterManIdPass.value = counterManId;
      sentDate.value = date;
      deckDetailsActive.value = false;
      reportActive.value = false;
      ReservationDetailsActive.value = true;
    };

    const onChildClick = (value) => {
      reportActive.value = value;
      deckDetailsActive.value = !value;
      ReservationDetailsActive.value = !value;
    };

    return {
      dropdownVariables,
      reservationwiseReport,
      filterReport,
      deckDetailsActive,
      ReservationDetailsActive,
      ReservationDetails,
      reportActive,
      counterManIdPass,
      sentDate,
      filterActive,
      onChildClick,
      ticketTotal,
      seatTotal,
      amountTotal,
      seatTicketTotal,
      seatAmountTotal,
      exportExcel,
      summary,
      isDiscount,
      date,
      reservationTicketStatus
    };
  },
};
</script>

<style scoped></style>
