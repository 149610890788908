<template>
  <div>
    <FilterTab>
      <template v-slot:title><h6>Dropping Places</h6></template>
      <template v-slot:body>
        <div class="col-sm-6 col-md-2">
          <!--          <DropdownFilter v-bind:id="'status'" v-bind:label="'Status'" v-bind:defaultOption="'&#45;&#45;select status&#45;&#45;'"-->
          <!--                          v-bind:defaultValue="''"-->
          <!--                          v-bind:dropdown="dropdownVariables.status"/>-->
        </div>
      </template>
      <template v-slot:button2>
        <button
          v-if="hasPermission(Owner.ownerCompanySetupWrite)"
          class="edit_table_data font_detail"
          @click="goBack"
        >
          <i class="fas fa-arrow-left"></i
          ><span class="mx-1 bold">Go Back</span>
        </button>
      </template>
      <template v-slot:button1>
        <button
          v-if="hasPermission(Owner.ownerCompanySetupWrite)"
          class="edit_table_data font_detail"
          @click="addModal"
        >
          <i class="fas fa-plus"></i><span class="mx-1 bold">Add New</span>
        </button>
        <button v-else class="edit_table_data font_detail" @click="goBack">
          <i class="fas fa-arrow-left"></i
          ><span class="mx-1 bold">Go Back</span>
        </button>
      </template>
    </FilterTab>
    <Table>
      <template v-slot:header>
        <tr>
          <td class="font_detail">SL</td>
          <td class="font_detail">NAME</td>
          <td
            v-if="hasPermission(Owner.ownerCompanySetupWrite)"
            class="font_detail"
          >
            ACTION
          </td>
        </tr>
      </template>
      <template
        v-slot:body
        v-if="$store.getters.getOwnerServicesDroppingPlaces"
      >
        <tr
          v-for="(droppingPlace, index) in $store.getters
            .getOwnerServicesDroppingPlaces"
          :key="index"
        >
          <td class="font_detail">{{ pagination.limit*(pagination.page-1) +index +1 }}</td>
          <td class="font_detail">{{ droppingPlace }}</td>

          <td v-if="hasPermission(Owner.ownerCompanySetupWrite)">
            <button
              class="edit_table_data font_detail edit_table_button"
              @click="updateModal(droppingPlace, index+1)"
            >
              <i class="fas fa-edit"></i><span class="mx-1">Edit</span>
            </button>
            <button
                class="header_delete_button font_detail"
                @click="deleteDroppingPoint(index, droppingPlace)"
            >
              <i class="fas fa-trash"></i><span class="mx-1">Delete</span>
            </button>
          </td>
        </tr>
      </template>
    </Table>
    <div v-if="showModal">
      <DroppingPlaceAddUpdateModal
        v-model="showModal"
        @getAllDroppingPlaces="getAllDroppingPlaces"
        :route="rId"
        :droppingPlace="droppingPlaceId"
        :dpPosition="droppingPlacePosition"
      />
    </div>
    <div v-if="showModalDelete">
      <DroppingPlaceDeleteModal
          v-model="showModalDelete"
          @getAllDroppingPlaces="getAllDroppingPlaces"
          :routeId="rId"
          :droppingPlaceName="droppingPlace"
      />
    </div>
  </div>
</template>

<script>
import store from "@/store";
import dropdownVariables from "@/modules/dropdownVariables";
import { reactive } from "@vue/reactivity";
import { inject, onBeforeMount, ref } from "vue";
import DroppingPlaceAddUpdateModal from "./DroppingPlaceAddUpdateModal";
import DroppingPlaceDeleteModal from "./DroppingPlaceDeleteModal";

import permission from "@/modules/permission";
import roles from "@/modules/roles";

const { hasPermission } = permission();
const { Owner } = roles();

export default {
  name: "DroppingPlace",
  components: { DroppingPlaceAddUpdateModal, DroppingPlaceDeleteModal},
  props: {
    routeId: String,
  },
  setup(props, { emit }) {
    let pagination = reactive({
      page: 1,
      limit: 10,
      routeId: "",
    });
    const options = {
      texts: {
        count: "",
      },
    };
    const rId = ref(props.routeId);
    const droppingPlaceId = ref("");
    const droppingPlace = ref("");
    const droppingPlacePosition = ref("");
    const showModalDelete = ref(false);
    const showModal = ref(false);
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");

    const getAllDroppingPlaces = () => {
      showLoader();
      store
        .dispatch("getOwnerServicesDroppingPlacesByPage", pagination)
        .then(() => {
          hideLoader();
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            getAllDroppingPlaces();
          }
        });
    };
    const goBack = () => {
      emit("toggle");
    };
    const addModal = () => {
      droppingPlaceId.value = "";
      showModal.value = true;
    };
    const updateModal = (value, index) => {
      droppingPlaceId.value = value;
      droppingPlacePosition.value = index;
      showModal.value = true;
    };

    const deleteDroppingPoint = (index, dp) => {
      showModalDelete.value = true;
      droppingPlace.value = dp;
    };

    onBeforeMount(() => {
      if (rId.value) {
        pagination.routeId = rId.value;
        getAllDroppingPlaces();
      }
    });

    return {
      dropdownVariables,
      pagination,
      showModal,
      droppingPlaceId,
      droppingPlacePosition,
      rId,
      options,
      Owner,
      hasPermission,
      getAllDroppingPlaces,
      addModal,
      updateModal,
      goBack,
      deleteDroppingPoint,
      showModalDelete,
      droppingPlace,
    };
  },
};
</script>

<style scoped>
.header_delete_button {
  margin: 0 2px;
  border: 2px solid #ff072c;
  border-radius: 4px;
  background-color: #ff072c;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.header_delete_button:hover,
.header_delete_button:focus {
  border: 2px solid #ff072c;
  background-color: #fff;
  color: #ff072c;
  transition: 0.4s;
  outline: 0;
}
.bold {
  font-weight: bold;
}
</style>