// import tokenCookie from "@/modules/tokenCookie";
import web from "@/urls/web";

// const {hasValidToken} = tokenCookie();

export default function counterman ({ next, store }){
    if(store.getters.userType !== 'Counterman'){
        return next({
            name: web.Default.LOGIN_URL.name
        })
    }
    return next()
}