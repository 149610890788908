<template>
  <li class="list-group-item">
    <div class="row my-2">
      <span v-if="error" class="text-danger err_msg">* {{ error }}</span>
      <div class="col-md-3">
        <div class="card p-1 h-100">
          <div class="row">
            <div class="col-auto">
              <span
                v-for="seat in ownPermittedSeat"
                :key="seat + spId"
                class="badge badge-pill seat_block m-1 p-2"
                >{{ seat }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-1">
        <button type="button" class="btn btn-success" @click="blockSeats()">
          <i class="fas fa-plus"></i> Permission
        </button>
      </div>
      <div class="col-md-7"></div>
      <div class="col-md-1">
        <input
          type="checkbox"
          class="input_field form-check-input me-2"
          :id="seatClassId"
          @click="selectAllSeat"
          v-model="checked"
        />
        <label :for="seatClassId" class="label_font"> Select All</label>
      </div>
    </div>
  </li>
 
</template>

<script>
import { reactive } from "@vue/reactivity";
import { inject, ref, watch, computed } from "vue";
import store from "@/store";

export default {
  name: "SeatPermissionAdd",
  props: {
    blockSeat: Object,
    ownPermitted: Object,
    allreadyPermittedSeats: Object,
    seatPlanId: String,
    ship: String,
    company: String,
    cabinMan: String,
    seatClass: String,
    seatList: [Object, Array],
  },
  emits: ["getSeatPlan", "selectedForBlocked", "getSeatPlanOfOthers", "getBlockedSeatList"],

  setup(props, { emit }) {
    const seatClassId = ref(props.seatClass);
    let block = reactive({
      cabinManId: props.cabinMan,
      shipId: props.ship,
      permittedSeat: {
        seatClassId: seatClassId,
        seats: "",
      },
    });
    let spId = ref(props.seatPlanId);
    let ownPermittedSeat = ref(props.ownPermitted);
    const blockedSeat = computed(()=> props.blockSeat)
    const allreadyPermittedSeatsByOther = computed(()=> props.allreadyPermittedSeats)
    let companyId = ref(props.company);
    let shipId = ref(props.ship);
    let errorId = ref("");
    let error = ref("");
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    let checked = ref(false);
    const allSeat = ref([]);

    watch(
      () => [...props.ownPermitted],
      // () => [allSeat.value],
      () => {
        ownPermittedSeat.value = props.ownPermitted;
      }
    );

    watch(() => [...props.allreadyPermittedSeats], () => {
         allreadyPermittedSeatsByOther.value = props.allreadyPermittedSeats;
      });
    watch(() => [...props.blockSeat], () => {
         blockedSeat.value = props.blockSeat;
      });

    const clear = () => {
      block.seatClassId = "";
      block.permittedSeat.seats = "";
    };

    const getSeatPlan = () => {
      emit("getSeatPlan");
      emit("getBlockedSeatList");
      emit("getSeatPlanOfOthers");
    };
    const blockSeats = () => {
      showLoader();
      block.permittedSeat.seats = ownPermittedSeat.value.toString();
      store
        .dispatch("addSeatPermission", block)
        .then(() => {
          hideLoader();
          clear();
          getSeatPlan();
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            blockSeats();
          }
        });
    };
    const selectAllSeat = () => {
      if (!checked.value) {
        let seats = [];
        props.seatList.seatList.forEach((element) => {
          seats.push(...element.split(","));
        });
        seats = seats.filter(item => !allreadyPermittedSeatsByOther.value.join(',').split(',').includes(item));
        seats = seats.filter(item => !blockedSeat.value.join(',').split(',').includes(item));
        allSeat.value = seats.filter((seat) => {
          const emptySeat =
            seat === "AAA" || seat === "RRR" || seat === "BBB" || seat === "";
          return !emptySeat;
        });
          checked.value = !checked.value;
      } else {
        allSeat.value = [];
        checked.value = !checked.value;
      }
      ownPermittedSeat.value = allSeat.value;
      emit('getAllSelectedSeats', allSeat.value);
    };

    return {
      companyId,
      shipId,
      spId,
      errorId,
      error,
      ownPermittedSeat,
      blockSeats,
      blockedSeat,
      seatClassId,
      selectAllSeat,
      allSeat,
      checked,
      allreadyPermittedSeatsByOther
    };
  },
};
</script>

<style scoped>
.add_button {
  border: 2px solid #17a2b8;
  border-radius: 20px;
  background-color: #17a2b8;
  padding: 5px 10px;
  color: #ffffff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.add_button:hover,
.add_button:focus {
  border: 2px solid #17a2b8;
  background-color: #fff;
  color: #17a2b8;
  transition: 0.4s;
  outline: 0;
}

.block_button {
  border: 2px solid #ff0000;
  border-radius: 20px;
  background-color: #ff0000;
  padding: 5px 10px;
  color: #ffffff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.block_button:hover,
.block_button:focus {
  border: 2px solid #ff0000;
  background-color: #fff;
  color: #ff0000;
  transition: 0.4s;
  outline: 0;
}

.seat_block{
   background-color: #157347 !important;
}

.form-check-input {
  width: 20px !important;
  height: 20px !important;
}
</style>
