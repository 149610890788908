<template>
  <div>
    <div class="card mx-2 mx-xxl-5 my-2">
      <div
          class="card-header"
          :id="'heading' + floorPlan._id"
          data-toggle="collapse"
          :data-target="'#collapse' + floorPlan._id"
          aria-expanded="true"
          :aria-controls="'collapse' + floorPlan._id"
      >
        <div class="row my-1">
          <div class="col col-sm-4 col-lg-6 bold">
            {{ floorPlan.name }}
          </div>

          <div class="col col-sm-8 col-lg-6">
            <button
                v-if="collapse"
                class="header_show_button font_detail float-end"
                @click="collapse = false"
            >
              <i class="fas fa-chevron-down"></i><span class="mx-1">Show</span>
            </button>
            <button
                v-else
                class="header_show_button font_detail float-end"
                @click="collapse = true"
            >
              <i class="fas fa-chevron-up"></i><span class="mx-1">Hide</span>
            </button>
            <button
                v-if="hasPermission(Admin.companyDeployWrite)"
                class="header_edit_button font_detail float-end"
                @click="updateFloor()"
            >
              <i class="fas fa-pencil-alt"></i><span class="mx-1">Edit</span>
            </button>
            <button
                v-if="hasPermission(Admin.companyDeployWrite)"
                class="header_delete_button font_detail float-end"
                @click="deleteFloor()"
            >
              <i class="fas fa-trash"></i><span class="mx-1">Delete</span>
            </button>
            <div class="text-center float-end me-4">
                Status:
                <span class="bold" :class="floorPlan.status ? 'text-success' : 'text-danger' ">{{
                    floorPlan.status ? "Active" : "Inactive"
                  }}</span>
              </div>
          </div>
        </div>
      </div>
      <div v-if="floorPlan" 
        :id="'collapse' + floorPlan._id"
        :aria-labelledby="'heading' + floorPlan._id"
        data-parent="#accordion"
        class="collapse list-group list-group-flush"
        :class="!collapse ? 'show' : ''"
      >
        <SeatPlan v-bind:ship="shipId" v-bind:company="companyId" :inactiveFilter="filter" :floor="floorPlan._id" @close="close" @toggle="toggle"/>
      </div>

<!--      <ul-->
<!--          v-if="seatPlan"-->
<!--          :id="'collapse' + seatPlan._id"-->
<!--          :aria-labelledby="'heading' + seatPlan._id"-->
<!--          data-parent="#accordion"-->
<!--          class="collapse list-group list-group-flush"-->
<!--          :class="!collapse ? 'show' : ''"-->
<!--      >-->
<!--        <AddSeatRow-->
<!--            v-bind:blocked="blockedSeats"-->
<!--            v-if="hasPermission(Admin.companyDeployWrite)"-->
<!--            v-bind:company="companyId"-->
<!--            v-bind:seatPlanId="seatPlan._id"-->
<!--            v-bind:ship="shipId"-->
<!--            @getSeatPlan="getSeatPlan"-->
<!--        />-->
<!--        <SeatList-->
<!--            v-bind:blocked="blockedSeats"-->
<!--            v-bind:seatList="seatPlan.seatList"-->
<!--            v-bind:seatPlan="seatPlan._id"-->
<!--            v-bind:ship="shipId"-->
<!--            @getSeatPlan="getSeatPlan"-->
<!--            @selectedForBlocked="selectedForBlocked"-->
<!--        />-->
<!--      </ul>-->
    </div>
    <div v-if="updateModal">
      <FloorPlanUpdateModal
          v-model="updateModal"
          v-bind:company="companyId"
          v-bind:ship="shipId"
          v-bind:floor="flId"
      />
    </div>
    <div v-if="deleteModal">
      <FloorPlanDeleteModal
          v-model="deleteModal"
          v-bind:company="companyId"
          v-bind:ship="shipId"
          v-bind:floor="flId"
      />
    </div>
  </div>
</template>

<script>
import dropdownVariables from "@/modules/dropdownVariables";
import { onBeforeMount, ref, watch } from "vue";
import store from "@/store";
import FloorPlanUpdateModal from "./FloorPlanUpdateModal";
import FloorPlanDeleteModal from "./FloorPlanDeleteModal";
import SeatPlan from "./SeatPlan";
// import AddSeatRow from "./AddSeatRow";
// import SeatList from "./SeatList";
import permission from "@/modules/permission";
import roles from "@/modules/roles";

const { hasPermission } = permission();
const { Admin } = roles();

export default {
  name: "FloorPlanCard",
  props: {
    floorId: String,
    ship: String,
    company: String,
  },
  components: {
    FloorPlanUpdateModal,
    FloorPlanDeleteModal,
    SeatPlan,
    // AddSeatRow,
    // SeatList,
  },
  setup(props) {
    let flId = ref(props.floorId);
    let companyId = ref(props.company);
    let shipId = ref(props.ship);
    let updateModal = ref(false);
    let deleteModal = ref(false);
    let collapse = ref(true);
    let floorPlan = ref({});
    const filter = ref(true)

    const status = dropdownVariables.status;


    watch(
      () => updateModal.value,
      () => {
        if (updateModal.value === false) {
          getFloorPlan();
        }
      }
    );
    const getFloorPlan = () => {
      floorPlan.value = store.getters.getFloorPlan(flId.value, shipId.value);
    };
    const updateFloor = () => {
      updateModal.value = true;
    };
    const deleteFloor = () => {
      deleteModal.value = true;
    };

    const close = () => {

    };
    const toggle = () => {

    };


    onBeforeMount(() => {
      getFloorPlan();
    });

    return {
      status,
      floorPlan,
      flId,
      shipId,
      updateModal,
      companyId,
      deleteModal,
      collapse,
      Admin,
      hasPermission,
      updateFloor,
      deleteFloor,
      close,
      toggle,
      filter,

    };
  },
};
</script>

<style scoped>
.header_edit_button {
  margin: 0 2px;
  border: 2px solid #ffc107;
  border-radius: 4px;
  background-color: #ffc107;
  padding: 2px 6px;
  color: #000;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.header_edit_button:hover,
.header_edit_button:focus {
  border: 2px solid #ffc107;
  background-color: #fff;
  color: #ffc107;
  transition: 0.4s;
  outline: 0;
}

.header_delete_button {
  margin: 0 2px;
  border: 2px solid #ff072c;
  border-radius: 4px;
  background-color: #ff072c;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.header_delete_button:hover,
.header_delete_button:focus {
  border: 2px solid #ff072c;
  background-color: #fff;
  color: #ff072c;
  transition: 0.4s;
  outline: 0;
}

.header_show_button {
  margin: 0 2px;
  border: 2px solid #15aabf;
  border-radius: 4px;
  background-color: #15aabf;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.header_show_button:hover,
.header_show_button:focus {
  border: 2px solid #15aabf;
  background-color: #fff;
  color: #15aabf;
  transition: 0.4s;
  outline: 0;
}

.bold {
  font-weight: bold;
}
</style>