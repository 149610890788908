<template>
  <Modal @close="closeModal">
    <template v-slot:title>{{ title }}</template>
    <template v-slot:body>
      <form>
        <div class="form-group row my-1">
          <ModalInput
            v-model="shipData.name"
            v-bind:placeholder="'Enter Name'"
            v-bind:label="'Name'"
            v-bind:id="'name'"
            v-bind:type="'text'"
          >
            <template v-slot:error>
              <ModalError v-if="errors.name" v-bind:error="errors.name" />
            </template>
          </ModalInput>
        </div>

        <div class="form-group row my-1">
          <ModalInput
            v-model="shipData.bookingNumber"
            v-bind:placeholder="'Enter Booking Number'"
            v-bind:label="'Booking Number'"
            v-bind:id="'bookingNumber'"
            v-bind:type="'phoneNumber'"
          >
            <template v-slot:error>
              <ModalError
                v-if="errors.bookingNumber"
                v-bind:error="errors.bookingNumber"
              />
            </template>
          </ModalInput>
        </div>

        <div class="form-group row my-1">
          <ModalDropdown
            v-if="$store.getters.getRouteListForFilterOwner"
            v-model="shipData.routeId"
            v-bind:id="'routeId'"
            v-bind:label="'Route'"
            v-bind:dropdown="$store.getters.getRouteListForFilterOwner"
            v-bind:defaultOption="'--select route--'"
            v-bind:defaultValue="''"
          >
            <template v-slot:error>
              <ModalError v-if="errors.routeId" v-bind:error="errors.routeId" />
            </template>
          </ModalDropdown>
        </div>
        <div class="form-group row my-1">
          <ModalCheckSwitch
            v-model="shipData.deckAvailability"
            v-bind:label="'Deck Available'"
            v-bind:id="'deck-available'"
          >
            <template v-slot:error>
              <Error
                v-if="errors.deckAvailability"
                v-bind:error="errors.deckAvailability"
              />
            </template>
          </ModalCheckSwitch>
        </div>

        <div v-if="shipData.deckAvailability" class="form-group row my-1">
          <ModalInput
            v-model="shipData.deckFare"
            v-bind:placeholder="'Enter Deck Fare'"
            v-bind:label="'Deck Fare'"
            v-bind:id="'deck-fare'"
            v-bind:type="'number'"
          >
            <template v-slot:error>
              <ModalError
                v-if="errors.deckFare"
                v-bind:error="errors.deckFare"
              />
            </template>
          </ModalInput>
        </div>

        <div class="form-group row my-1">
          <ModalCheckSwitch
            v-model="shipData.isMultiFloor"
            v-bind:label="'Floor Available'"
            v-bind:id="'floor-available'"
          >
            <template v-slot:error>
              <Error
                v-if="errors.isMultiFloor"
                v-bind:error="errors.isMultiFloor"
              />
            </template>
          </ModalCheckSwitch>
        </div>

        <div class="form-group row my-1">
          <ModalDropdown
            v-if="status"
            v-model="shipData.status"
            v-bind:id="'status'"
            v-bind:label="'Status'"
            v-bind:dropdown="status"
          ></ModalDropdown>
        </div>

        <div class="form-group row my-1">
          <label class="col-sm-3 col-form-label"></label>
          <div class="col-sm-9">
            <input
              v-if="!shipId"
              type="submit"
              class="mt-3 py-2 submit_button font_detail float-end"
              @click.prevent="addShip"
            />
            <input
              v-else
              type="submit"
              class="mt-3 py-2 submit_button font_detail float-end"
              @click.prevent="updateShip"
            />
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
import dropdownVariables from "@/modules/dropdownVariables";
import useInputValidation from "@/modules/useInputValidations";
import { inject, onBeforeMount, ref, watch } from "vue";
import { reactive } from "@vue/reactivity";
import store from "@/store";

export default {
  name: "ShipsAddUpdateModal",
  props: {
    ship: String,
    company: String,
  },
  setup(props, { emit }) {
    let shipData = reactive({
      id: "",
      companyId: "",
      routeId: "",
      name: "",
      bookingNumber: "",
      seatCapacity: 0,
      deckAvailability: false,
      deckFare: 0,
      isMultiFloor: false,
      status: 1,
    });
    let {
      requiredValidation,
      validNumberValidation,
      requiredValidationNumber,
      errors,
    } = useInputValidation();
    const shipId = ref(props.ship);
    const companyId = ref(props.company);
    const status = dropdownVariables.status;
    const title = ref("Add Ship");
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");

    watch(
      () => shipData.deckFare,
      () => {
        if (shipData.deckAvailability) {
          validNumberValidation("deckFare", shipData.deckFare);
        }
      }
    );
    watch(
      () => shipData.name,
      () => {
        requiredValidation("name", shipData.name);
      }
    );
    watch(
      () => shipData.bookingNumber,
      () => {
        requiredValidationNumber("bookingNumber", shipData.bookingNumber);
      }
    );
    watch(
      () => shipData.routeId,
      () => {
        requiredValidation("routeId", shipData.routeId);
      }
    );

    const validate = () => {
      Object.keys(errors).forEach(function (key) {
        errors[key] = "";
      });
      requiredValidation("name", shipData.name);
      requiredValidationNumber("bookingNumber", shipData.bookingNumber);
      requiredValidation("routeId", shipData.routeId);
      if (shipData.deckAvailability) {
        validNumberValidation("deckFare", shipData.deckFare);
      }
      return !(
        Object.values(errors).length <= 0 ||
        !Object.values(errors).every((x) => x === null || x === "")
      );
    };
    const closeModal = () => {
      emit("update:modelValue", false);
    };
    const addShip = () => {
      if (validate()) {
        showLoader();
        store
          .dispatch("addShipOwner", shipData)
          .then(() => {
            hideLoader();
            emit("getAllShips");
            closeModal();
          })
          .catch((error) => {
            if (error.status === 401) {
              addShip();
            }
          });
      }
    };
    const updateShip = () => {
      if (validate()) {
        showLoader();
        store
          .dispatch("updateShipOwner", shipData)
          .then(() => {
            hideLoader();
            emit("getAllShips");
            closeModal();
          })
          .catch((error) => {
            if (error.status === 401) {
              updateShip();
            }
          });
      }
    };

    onBeforeMount(() => {
      Object.keys(errors).forEach(function (key) {
        delete errors[key];
      });
      if (shipId.value !== "") {
        const counterDetails = store.getters.getShipOwner(shipId.value);
        title.value = "Update Ship";
        shipData.id = counterDetails[0]._id;
        shipData.routeId = counterDetails[0].routeId[0];
        shipData.name = counterDetails[0].name;
        shipData.bookingNumber = counterDetails[0].bookingNumber;
        shipData.deckAvailability = counterDetails[0].deckAvailability;
        shipData.deckFare = counterDetails[0].deckFare;
        shipData.isMultiFloor = counterDetails[0].isMultiFloor;
        shipData.status = counterDetails[0].status ? 1 : 0;
      }
      shipData.companyId = companyId.value;
    });

    return {
      shipId,
      shipData,
      errors,
      status,
      title,
      closeModal,
      addShip,
      updateShip,
    };
  },
};
</script>

<style scoped></style>
