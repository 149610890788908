<template>
  <Modal @close="closeModal" v-bind:size="'xl'">
    <template v-slot:title>{{ title }}</template>
    <template v-slot:body>
      <form>
        <div class="auth_form py-3">
          <div class="row my-2">
            <div class="col-md-3">
              <Dropdown
                v-bind:id="'counter'"
                v-bind:label="'Counter'"
                v-bind:defaultOption="'--select counter--'"
                v-model="countermanData.counterId"
                v-bind:defaultValue="''"
                :dropdown="$store.getters.getCounterListForFilterOwner"
              />
              <Error v-if="errors.counterId" v-bind:error="errors.counterId" />
            </div>
            <div class="col-md-3">
              <Input
                v-model="countermanData.name"
                v-bind:placeholder="'Enter Counterman Name'"
                v-bind:label="'Name'"
                v-bind:id="'name'"
                v-bind:type="'text'"
              />
              <Error v-if="errors.name" v-bind:error="errors.name" />
            </div>
            <div class="col-md-3">
              <Input
                v-model="countermanData.phone"
                v-bind:placeholder="'Enter Phone Number'"
                v-bind:label="'Phone'"
                v-bind:id="'phone'"
                v-bind:type="'text'"
              />
              <Error v-if="errors.phone" v-bind:error="errors.phone" />
            </div>
            <div class="col-md-3">
              <Input
                v-model="countermanData.password"
                autocomplete="off"
                v-bind:placeholder="'Enter Password'"
                v-bind:label="'Password'"
                v-bind:id="'password'"
                v-bind:type="fieldTypes.password"
              />
              <Error v-if="errors.password" v-bind:error="errors.password" />
            </div>
          </div>
          <div class="row my-2">
            <div class="col-md-3">
              <label for="address" class="form-label all_permission_label"
                >Address</label
              >
              <div>
                <textarea
                  class="form-control font_detail"
                  id="address"
                  v-model="countermanData.address"
                ></textarea>
              </div>
            </div>
            <div class="col-md-3">
              <Input
                v-model="countermanData.defaultBoardingPlace"
                v-bind:placeholder="'Enter Default Boarding Place'"
                v-bind:label="'Default Boarding Place'"
                v-bind:id="'boardingPlace'"
                v-bind:type="'text'"
              />
              <!-- <Error
                v-if="errors.boardingPlace"
                v-bind:error="errors.boardingPlace"
              /> -->
            </div>
            <div class="col-md-3">
              <Dropdown
                v-if="printerType"
                v-model="countermanData.printerType"
                v-bind:id="'printerType'"
                v-bind:label="'Printer Type'"
                v-bind:dropdown="printerType"
                v-bind:default-option="'Select a Printer Type'"
                v-bind:default-value="''"
              />
              <Error
                v-if="errors.printerType"
                v-bind:error="errors.printerType"
              />
            </div>
            <div class="col-md-3">
              <Dropdown
                v-if="status"
                v-model="countermanData.printingType"
                v-bind:id="'printingType'"
                v-bind:label="'Ticket Printing'"
                v-bind:dropdown="printingType"
              />
            </div>
            <div class="col-md-3">
              <Input
                  v-model="countermanData.reportPrintLimit"
                  v-bind:placeholder="'Print Limit'"
                  v-bind:label="'Report Print Limit'"
                  v-bind:id="'reportPrintLimit'"
                  v-bind:type="'number'"
              />
              <Error
                  v-if="errors.reportPrintLimit"
                  v-bind:error="errors.reportPrintLimit"
              />
            </div>
            <div class="col-md-3">
              <Input
                v-model="countermanData.nid"
                v-bind:placeholder="'NID Number'"
                v-bind:label="'NID'"
                v-bind:id="'nid'"
                v-bind:type="'text'"
              />
            </div>
            <div class="col-md-3">
              <Dropdown
                  v-if="status"
                  v-model="countermanData.status"
                  v-bind:id="'status'"
                  v-bind:label="'Status'"
                  v-bind:dropdown="status"
              />
            </div>
            <div class="col-md-9">
              <label class="label_font">Ships</label>
              <Multiselect
                class="w-100 mt-1 mb-4"
                placeholder="Select ships"
                v-model="countermanData.shipId"
                :options="$store.getters.getShipListForFilterOwnerServices"
                id="multiselectId"
                mode="tags"
                :searchable="true"
                :createTag="true"
              />
              <Error v-if="errors.ship" v-bind:error="errors.ship" />
            </div>
          </div>
        </div>

        <hr class="section_devider" />

        <div class="role_saection">
          <div class="row">
            <div class="d-flex justify-content-between align-items-center">
              <h5><b>Roles</b></h5>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input all_permission_label"
                  type="checkbox"
                  id="select-all"
                  name="select-all"
                  @click="giveAllPermission"
                  v-model="allSelected"
                />
                <label
                  class="form-check-label all_permission_label"
                  for="select-all"
                  >Give All Permission</label
                >
              </div>
            </div>
          </div>
          <span v-if="errors.roles" class="text-danger err_msg">{{
            errors.roles
          }}</span>
          <span
            v-else-if="errors[Counterman.countermanFareDiscount]"
            class="text-danger err_msg"
            >{{ errors[Counterman.countermanFareDiscount] }}</span
          >
          <span
            v-else-if="errors[Counterman.countermanTicketCancel]"
            class="text-danger err_msg"
            >{{ errors[Counterman.countermanTicketCancel] }}</span
          >
          <span
            v-else-if="errors[Counterman.countermanFares]"
            class="text-danger err_msg"
            >{{ errors[Counterman.countermanFares] }}</span
          >
          <span
            v-else-if="errors[Counterman.countermanCancelOthersTicket]"
            class="text-danger err_msg"
            >{{ errors[Counterman.countermanCancelOthersTicket] }}</span
          >
          <div class="assigned_role">
            <div class="row">
              <div class="col-6 col-sm-4 col-md-3">
                <div class="role_select_area">
                  <h5>Ticket Booking</h5>
                  <Checkbox
                    v-model="checked"
                    v-bind:label="'Yes'"
                    v-bind:role="Counterman.countermanTicketBooking"
                    :key="allSelected"
                    @toggleAllPermission="toggleAllPermission"
                  />
                </div>
              </div>
              <div class="col-6 col-sm-4 col-md-3">
                <div class="role_select_area">
                  <h5>Fare Discount</h5>
                  <Checkbox
                    v-model="checked"
                    v-bind:label="'Yes'"
                    v-bind:role="Counterman.countermanFareDiscount"
                    :key="allSelected"
                    @toggleAllPermission="toggleAllPermission"
                  />
                </div>
              </div>
              <div class="col-6 col-sm-4 col-md-3">
                <div class="role_select_area">
                  <h5>Report</h5>
                  <Checkbox
                    v-model="checked"
                    v-bind:label="'Yes'"
                    v-bind:role="Counterman.countermanReports"
                    :key="allSelected"
                    @toggleAllPermission="toggleAllPermission"
                  />
                </div>
              </div>
              <div class="col-6 col-sm-4 col-md-3">
                <div class="role_select_area">
                  <h5>Trip</h5>
                  <Checkbox
                    v-model="checked"
                    v-bind:label="'Yes'"
                    v-bind:role="Counterman.countermanTrips"
                    :key="allSelected"
                    @toggleAllPermission="toggleAllPermission"
                  />
                </div>
              </div>
              <div class="col-6 col-sm-4 col-md-3">
                <div class="role_select_area">
                  <h5>Ship</h5>
                  <Checkbox
                    v-model="checked"
                    v-bind:label="'Yes'"
                    v-bind:role="Counterman.countermanShips"
                    :key="allSelected"
                    @toggleAllPermission="toggleAllPermission"
                  />
                </div>
              </div>
              <div class="col-6 col-sm-4 col-md-3">
                <div class="role_select_area">
                  <h5>Fare</h5>
                  <Checkbox
                    v-model="checked"
                    v-bind:label="'Yes'"
                    v-bind:role="Counterman.countermanFares"
                    :key="allSelected"
                    @toggleAllPermission="toggleAllPermission"
                  />
                </div>
              </div>
              <div class="col-6 col-sm-4 col-md-3">
                <div class="role_select_area">
                  <h5>Cancel Ticket</h5>
                  <Checkbox
                    v-model="checked"
                    v-bind:label="'Yes'"
                    v-bind:role="Counterman.countermanTicketCancel"
                    :key="allSelected"
                    @toggleAllPermission="toggleAllPermission"
                  />
                </div>
              </div>
              <div class="col-6 col-sm-4 col-md-3">
                <div class="role_select_area">
                  <h5>Cancel Other Ticket</h5>
                  <Checkbox
                    v-model="checked"
                    v-bind:label="'Yes'"
                    v-bind:role="Counterman.countermanCancelOthersTicket"
                    :key="allSelected"
                    @toggleAllPermission="toggleAllPermission"
                  />
                </div>
              </div>
              <div class="col-6 col-sm-4 col-md-3" v-if="reservationStatus">
                <div class="role_select_area">
                  <h5>Reservation</h5>
                  <Checkbox
                    v-model="checked"
                    v-bind:label="'Yes'"
                    v-bind:role="Counterman.counterReservation"
                    :key="allSelected"
                    @toggleAllPermission="toggleAllPermission"
                    @click="reservationChecked"
                  />
                </div>
              </div>
              <div
                class="col-6 col-sm-4 col-md-3"
                v-if="reservationStatus && activeReservation"
              >
                <div class="role_select_area">
                  <h5>Cancel Reservation</h5>
                  <Checkbox
                    v-model="checked"
                    v-bind:label="'Yes'"
                    v-bind:role="Counterman.counterCancelReservation"
                    :key="allSelected"
                    @toggleAllPermission="toggleAllPermission"
                  />
                </div>
              </div>
              <div
                class="col-6 col-sm-4 col-md-3"
                v-if="reservationStatus && activeReservation"
              >
                <div class="role_select_area">
                  <h5>Cancel Other Reservation</h5>
                  <Checkbox
                    v-model="checked"
                    v-bind:label="'Yes'"
                    v-bind:role="Counterman.counterCancelOtherReservation"
                    :key="allSelected"
                    @toggleAllPermission="toggleAllPermission"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row my-1">
          <label class="col-sm-3 col-form-label"></label>
          <div class="col-sm-9">
            <input
              v-if="!countermanIdForUpdate"
              type="submit"
              class="mt-3 py-2 submit_button font_detail float-end"
              @click.prevent="addCounterMan"
            />
            <input
              v-else
              type="submit"
              class="mt-3 py-2 submit_button font_detail float-end"
              @click.prevent="updateCounterMan"
            />
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
import { reactive } from "@vue/reactivity";
import dropdownVariables from "@/modules/dropdownVariables";
import useInputValidation from "@/modules/useInputValidations";
import { inject, onBeforeMount, ref, watch } from "vue";
import roles from "@/modules/roles";
import store from "@/store";
import Multiselect from "@vueform/multiselect";

export default {
  name: "CountermanAddUpdateModalSeperate",
  components: {
    Multiselect,
  },
  props: {
    modalValue: Boolean,
    company: String,
    counterMan: String,
  },
  setup(props, { emit }) {
    let countermanData = reactive({
      id: "",
      name: "",
      designation: "",
      phone: "",
      password: "",
      email: "",
      nid: "",
      address: "",
      avatar: "",
      transactionType: "",
      balance: "",
      commission: "",
      userType: "counterman",
      defaultBoardingPlace: "",
      printingType: "Individual",
      companyId: "",
      counterId: "",
      commissionType: "",
      roles: "",
      status: "1",
      api_token: "",
      device_token: "",
      reset_token: "",
      printerType: "",
      shipId: [],
      reportPrintLimit: 1,
    });
    let countermanIdForUpdate = ref(props.counterMan);
    let companyId = ref(props.company);
    let checked = ref([]);
    let allSelected = ref(false);
    let {
      requiredValidation,
      passwordValidation,
      phoneValidation,
      requiredRoleValidation,
      validNumberValidation,
      errors,
    } = useInputValidation();
    let printerType = dropdownVariables.countermanPrinterType;
    const { Counterman } = roles();
    let reservationStatus = ref(false);
    const status = dropdownVariables.status;
    const printingType = dropdownVariables.printingType;
    const title = ref("Add Counterman");
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const activeReservation = ref(false);

    let fieldTypes = reactive({
      email: "text",
      password: "text",
    });

    watch(
      () => countermanData.name,
      () => {
        requiredValidation("name", countermanData.name);
      }
    );
    // watch(
    //   () => countermanData.defaultBoardingPlace,
    //   () => {
    //     requiredValidation(
    //       "boardingPlace",
    //       countermanData.defaultBoardingPlace
    //     );
    //   }
    // );
    watch(
      () => countermanData.counterId,
      () => {
        requiredValidation("counterId", countermanData.counterId);
      }
    );
    watch(
      () => countermanData.phone,
      () => {
        phoneValidation("phone", countermanData.phone);
      }
    );
    watch(
      () => countermanData.roles,
      () => {
        requiredValidation("roles", countermanData.roles);
      }
    );
    watch(
      () => countermanData.printerType,
      () => {
        requiredValidation("printerType", countermanData.printerType);
      }
    );
    watch(
      () => countermanData.password,
      () => {
        passwordValidation("password", countermanData.password);
      }
    );

    watch(
      () => countermanData.password,
      () => {
        fieldTypes.password = "password";
      }
    );
    watch(
      () => countermanData.email,
      () => {
        fieldTypes.email = "email";
      }
    );

    watch(
        () => countermanData.reportPrintLimit,
        () => {
          validNumberValidation("reportPrintLimit", countermanData.reportPrintLimit);
        }
    );

    const giveAllPermission = () => {
      checked.value = [];
      if (!allSelected.value) {
        checked.value = Object.values(Counterman);
        reservationChecked();
      }
    };
    const closeModal = () => {
      emit("update:modelValue", false);
    };
    const toggleAllPermission = () => {
      allSelected.value =
        checked.value.length === Object.values(Counterman).length;
    };
    const validate = () => {
      Object.keys(errors).forEach(function (key) {
        errors[key] = "";
      });
      requiredValidation("name", countermanData.name);
      // requiredValidation("boardingPlace", countermanData.defaultBoardingPlace);
      requiredValidation("printerType", countermanData.printerType);
      phoneValidation("phone", countermanData.phone);
      requiredValidation("roles", countermanData.roles);
      requiredValidation("counterId", countermanData.counterId);
      validNumberValidation("reportPrintLimit", countermanData.reportPrintLimit);

      requiredRoleValidation(
        Counterman.countermanFareDiscount.toString(),
        checked.value,
        Counterman.countermanTicketBooking,
        Counterman.countermanFareDiscount,
        Counterman
      );
      requiredRoleValidation(
        Counterman.countermanTicketCancel.toString(),
        checked.value,
        Counterman.countermanTicketBooking,
        Counterman.countermanTicketCancel,
        Counterman
      );
      requiredRoleValidation(
        Counterman.countermanFares.toString(),
        checked.value,
        Counterman.countermanTicketBooking,
        Counterman.countermanFares,
        Counterman
      );
      requiredRoleValidation(
        Counterman.countermanCancelOthersTicket.toString(),
        checked.value,
        Counterman.countermanTicketBooking,
        Counterman.countermanCancelOthersTicket,
        Counterman
      );
      if (countermanIdForUpdate.value === "") {
        passwordValidation("password", countermanData.password);
      } else {
        if (countermanData.password !== "") {
          passwordValidation("password", countermanData.password);
        }
      }
      return !(
        Object.values(errors).length <= 0 ||
        !Object.values(errors).every((x) => x === null || x === "")
      );
    };
    const reservationChecked = () => {
      activeReservation.value =
        checked.value.indexOf(108) === -1 ? false : true;
    };

    const handleReservationRole = () => {
      if (!reservationStatus.value) {
        const reservationIndex = checked.value.indexOf(108);

        if (reservationIndex != -1) {
          checked.value.splice(reservationIndex, 1);
        }
      }
    };
    const handleReservationStatus = () => {
      if (!activeReservation.value) {
        const index = checked.value.indexOf(109);
        if (index != -1) {
          checked.value.splice(index, 1);
        }
        const index2 = checked.value.indexOf(110);
        if (index2 != -1) {
          checked.value.splice(index2, 1);
        }
      }
    };
    const addCounterMan = () => {
      handleReservationStatus();
      handleReservationRole();
      countermanData.roles = checked.value.sort().join(",");
      if (validate()) {
        showLoader();
        store
          .dispatch("addCounterManOwner", countermanData)
          .then(() => {
            hideLoader();
            emit("getAllCounterMans");
            closeModal();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              addCounterMan();
            }
          });
      }
    };
    const updateCounterMan = () => {
      handleReservationStatus();
      handleReservationRole();
      countermanData.roles = checked.value.sort().join(",");
      if (validate()) {
        showLoader();
        store
          .dispatch("updateCounterManOwner", countermanData)
          .then(() => {
            emit("getAllCounterMans");
            closeModal();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              updateCounterMan();
            }
          });
      }
    };

    onBeforeMount(() => {
      reservationStatus.value = store.getters.getReservationStatus;
      // reservationStatus.value = store.getters.getCompany(
      //   store.getters.userCompany
      // )[0].reservation;
      Object.keys(errors).forEach(function (key) {
        delete errors[key];
      });
      countermanData.companyId = companyId.value;
      if (countermanIdForUpdate.value !== "") {
        const countermanDetails = store.getters.getCounterManOwner(
          countermanIdForUpdate.value
        );
        title.value = "Update Counterman";
        countermanData.id = countermanDetails[0]._id;
        countermanData.companyId = countermanDetails[0].companyId;
        countermanData.name = countermanDetails[0].name;
        countermanData.defaultBoardingPlace =
          countermanDetails[0].defaultBoardingPlace;
        countermanData.phone = countermanDetails[0].phone;
        countermanData.email = countermanDetails[0].email;
        countermanData.counterId = countermanDetails[0].counterId._id;
        countermanData.printerType = countermanDetails[0].printerType;
        countermanData.status = countermanDetails[0].status ? 1 : 0;
        countermanData.printingType = countermanDetails[0].printingType;
        countermanData.reportPrintLimit = parseInt(countermanDetails[0].reportPrintLimit);
        countermanDetails[0].roles[0]
          .split(",")
          .forEach((element) => checked.value.push(parseInt(element)));
        activeReservation.value =
          countermanDetails[0].roles[0].indexOf(108) === -1 ? false : true;
        Object.keys(Counterman).length === checked.value.length
          ? (allSelected.value = true)
          : (allSelected.value = false);

        countermanDetails[0]?.shipId?.forEach((item) => {
          countermanData.shipId.push(item._id);
        });
      }
    });

    return {
      status,
      countermanData,
      errors,
      Counterman,
      title,
      checked,
      allSelected,
      countermanIdForUpdate,
      printingType,
      fieldTypes,
      giveAllPermission,
      toggleAllPermission,
      updateCounterMan,
      addCounterMan,
      closeModal,
      printerType,
      reservationChecked,
      activeReservation,
      reservationStatus,
    };
  },
};
</script>

<style scoped></style>
