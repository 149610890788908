import web from "@/urls/web";

export default function guest({ next, store }) {
  if (store.getters.isLoggedIn && store.getters.userType) {
    let type;
    if (store.getters.userType === "Admin") {
      type = web.Admin.DASHBOARD_URL.name;
    } else if (store.getters.userType === "Owner") {
      type = web.Owner.DASHBOARD_URL.name;
    } else if (store.getters.userType === "Counterman") {
      type = web.Counterman.DASHBOARD_URL.name;
    } else if (store.getters.userType === "Agent") {
      type = web.Agent.DASHBOARD_URL.name;
    } else if (store.getters.userType === "Supervisor") {
      type = web.Supervisor.DASHBOARD_URL.name;
    }
    return next({
      name: type,
    });
  }

  return next();
}
