import dropdownVariables from "@/modules/dropdownVariables";

export default function fareModality() {
    const getNetFare = (selectedSeats, upFare, downFare, direction) => {
        if (dropdownVariables.direction.UP === dropdownVariables.direction[direction]) {
            return selectedSeats.length * parseInt(upFare);
        }else{
            return selectedSeats.length * parseInt(downFare);
        }
    };
    return {
        getNetFare
    };
}