<template>
  <ul style="margin: 0; padding: 20px 0; width: auto">
    <li
        v-for="(seatRow, index) in seat.seatList"
        :key="seat._id + 'plan' + index"
        class="list-group-item py-0 border-0 bg-transparent w-100"
    >
      <div class="row my-2 px-3">
        <div
            v-for="(seat, seatId) in seatRow.split(',')"
            :key="'seat' + seatId + index"
            :class="seat === '' ? 'col-12' : 'col-auto'"
        >
          <div
              v-if="seat !== '' && blockedSeats.includes(seat)"
              class="seat my-1 px-1 py-1 rounded text-center bg-secondary"
              style="color: white; border: 1px solid #6c757d"
          >
            <span>{{ seat }}</span>
          </div>
          <div
              v-else-if="seat !== '' && alreadyBooked.includes(seat)"
              :style="bookedByFemale.includes(seat) ? 'background-color: #FF67B6 !important; border: 1px solid #FF67B6 !important;' : 'background-color: #FE0000; border: 1px solid #FE0000' "
              class="seat my-1 px-1 py-1 rounded text-center"
              style="color: white;"
          >
            <span>{{ seat }}</span>
          </div>
          <div
              v-else-if="seat !== '' && alreadyReserved.includes(seat)"
              class="seat my-1 px-1 py-1 rounded text-center"
              style="background-color: #5c1916; color: white; border: 1px solid #5c1916;"
          >
            <span>{{ seat }}</span>
          </div>
          <div
              v-else-if="seat !== '' && onlineSeats.includes(seat)"
              class="seat my-1 px-1 py-1 rounded text-center"
              style="background-color: #2B2E7E; color: white; border: 1px solid #2B2E7E;"
          >
            <span>{{ seat }}</span>
          </div>
          <div
              v-else-if="isDtAvailable && tripDirection === 'UP' && seat !== '' && (dtUpSeat.length === 0 || dtUpSeat.includes(seat))"
              class="seat my-1 px-1 py-1 rounded text-center"
              style="background-color: #ffffff; color: white; border: 1px solid #000;"
          >
            <div :id="seat" class="custom-lock" title="Not available">
              <i class='fa fa-lock'></i>
            </div>
            <span>{{ seat }}</span>
          </div>
          <div
              v-else-if="isDtAvailable && tripDirection === 'DOWN' && seat !== '' &&(dtDownSeat.length === 0 || dtDownSeat.includes(seat))"
              class="seat my-1 px-1 py-1 rounded text-center"
              style="background-color: #ffffff; color: white; border: 1px solid #000;"
          >
            <div :id="seat" class="custom-lock" title="Not available">
              <i class='fa fa-lock'></i>
            </div>
            <span>{{ seat }}</span>
          </div>
          <div
              v-else-if="seat !== '' && selectedSeats.includes(seat)"
              class="seat my-1 px-1 py-1 rounded text-center"
              style="background-color: darkgreen; color: white; cursor: pointer"
              @click="seatSelected(seat)"
          >
            <span>{{ seat }}</span>
          </div>
          <div
              v-else-if="seat === 'AAA'"
              class="my-1 px-1 py-1 rounded text-center seat-empty"
          >
            <span> </span>
          </div>
          <div
              v-else-if="seat === 'BBB'"
              class="seat my-1 px-1 py-1 rounded text-center bg-dark"
          >
            <span> &nbsp; </span>
          </div>
          <div
              v-else-if="seat === 'RRR'"
              class="ms-auto" style="width: 106px"
          >
            <div class="row">
              <div class="ms-auto me-auto my-1 px-1 py-1 rounded text-center seat-empty">
                <span> &nbsp; </span>
              </div>
              <div class="ms-auto me-auto my-1 px-1 py-1 rounded text-center seat-empty">
                <span> &nbsp; </span>
              </div>
            </div>
          </div>
          <div v-else-if="seat === ''" class="w-100">
            <div class="my-1 p-3 rounded text-center seat-empty-road"></div>
          </div>
          <div
              v-else
              class="seat my-1 px-1 py-1 rounded text-center"
              style="cursor: pointer"
              @click="seatSelected(seat)"
          >
            <span>{{ seat }}</span>
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import {onBeforeMount, ref, watch} from "vue";
import toast from "@/modules/Toast";

export default {
  name: "SeatList",
  props: {
    modelValue: Array,
    seatPlan: Object,
    direction: String,
    maxSeatPerTicket: [Number, String],
  },
  setup(props, {emit}) {
    const seat = ref(props.seatPlan);
    const tripDirection = ref(props.direction);
    const selectedSeats = ref(props.modelValue);
    const alreadyBooked = ref([]);
    const bookedByFemale = ref([]);
    const bookedByMale = ref([]);
    const alreadyReserved = ref([]);
    const blockedSeats = ref([]);
    const onlineSeats = ref([]);
    const dtUpSeat = ref([]);
    const dtDownSeat = ref([]);
    const isDtAvailable = ref(props.seatPlan.dtAvailable);
    const maxTicketPerReq = ref(props.maxSeatPerTicket);
    const {warningToast} = toast();
    watch(
        () => selectedSeats.value,
        () => {
          emit("update:modelValue", selectedSeats.value);
        }
    );
    watch(
        () => props.modelValue,
        (value) => {
          selectedSeats.value = value;
        }
    );
    watch(
        () => props.seatPlan,
        (value) => {
          isDtAvailable.value = value.dtAvailable;
          seat.value = value;
          if (seat.value.bookedList !== undefined) {
            alreadyBooked.value = seat.value.bookedList.split(",");
          } else {
            alreadyBooked.value = ""
          }
          if (seat.value.bookedByMaleList !== undefined) {
            bookedByMale.value = seat.value.bookedByMaleList.split(",");
          } else {
            bookedByMale.value = ""
          }
          if (seat.value.bookedByFemaleList !== undefined) {
            bookedByFemale.value = seat.value.bookedByFemaleList.split(",");
          } else {
            bookedByFemale.value = ""
          }
          if (seat.value.reservedSeats !== undefined) {
            alreadyReserved.value = seat.value.reservedSeats.split(",");
          } else {
            alreadyReserved.value = ""
          }
          if (seat.value.onlineSeats !== undefined) {
            onlineSeats.value = seat.value.onlineSeats.split(",");
          } else {
            onlineSeats.value = ""
          }
          if (seat.value.blockedSeats !== undefined) {
            blockedSeats.value = seat.value.blockedSeats.split(",");
          } else {
            blockedSeats.value = ""
          }
          if (seat.value.dtUpSeat.length > 0) {
            dtUpSeat.value = seat.value.dtUpSeat.split(",");
          } else {
            dtUpSeat.value = ""
          }
          if (seat.value.dtDownSeat.length > 0) {
            dtDownSeat.value = seat.value.dtDownSeat.split(",");
          } else {
            dtDownSeat.value = ""
          }
        }
    );

    const seatSelected = (seat) => {
      if (selectedSeats.value.includes(seat)) {
        selectedSeats.value = selectedSeats.value.filter(
            (alreadySelected) => alreadySelected !== seat
        );
      } else if (selectedSeats.value.length < maxTicketPerReq.value) {
        selectedSeats.value.push(seat);
      } else {
        warningToast(
            `You Cannot select More than ${maxTicketPerReq.value} Seats`
        );
      }
    };
    onBeforeMount(() => {
      if (seat.value.bookedList !== undefined) {
        alreadyBooked.value = seat.value.bookedList.split(",");
      }
      if (seat.value.bookedByFemaleList !== undefined) {
        bookedByFemale.value = seat.value.bookedByFemaleList.split(",");
      }
      if (seat.value.bookedByMaleList !== undefined) {
        bookedByMale.value = seat.value.bookedByMaleList.split(",");
      }
      if (seat.value.reservedSeats !== undefined) {
        alreadyReserved.value = seat.value.reservedSeats.split(",");
      }
      if (seat.value.onlineSeats !== undefined) {
        onlineSeats.value = seat.value.onlineSeats.split(",");
      }
      if (seat.value.blockedSeats !== undefined) {
        blockedSeats.value = seat.value.blockedSeats.split(",");
      }
      if (seat.value.dtUpSeat.length > 0) {
        dtUpSeat.value = seat.value.dtUpSeat.split(",");
      }
      if (seat.value.dtDownSeat.length > 0) {
        dtDownSeat.value = seat.value.dtDownSeat.split(",");
      }
    });
    return {
      seat,
      seatSelected,
      alreadyBooked,
      bookedByFemale,
      bookedByMale,
      alreadyReserved,
      onlineSeats,
      selectedSeats,
      blockedSeats,
      tripDirection,
      dtUpSeat,
      dtDownSeat,
      isDtAvailable,
    };
  },
};
</script>

<style scoped>
.custom-lock {
  position: absolute;
  left: 0;
  transform: translate(0%, 5%);
  width: 43px;
  z-index: 100;
  top: 0px;
  cursor: not-allowed;
}

.fa-lock {
  color: #b2b2b2;
  font-size: 20px;
  padding: 3px;
  border-radius: 3px;
}

.remove_button {
  border: 2px solid #ffc107;
  border-radius: 20px;
  background-color: #ffc107;
  padding: 5px 10px;
  color: #ffffff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.remove_button:hover,
.remove_button:focus {
  border: 2px solid #ffc107;
  background-color: #fff;
  color: #ffc107;
  transition: 0.4s;
  outline: 0;
}

.right_border {
  border-right: 1px solid #ccc;
}

.seat {
  position: relative;
  display: inline-block;
  border-radius: 3px;

  background-color: #fff;
  border: 1px solid #222;
  width: 45px;
  font-size: 14px;
  color: #000;
}

.seat-empty {
  cursor: default;
  background-color: #ded2d0;
  width: 45px;
  font-size: 14px;
  color: #fff;
  opacity: 0;
}

.seat-empty-road {
  cursor: default;
  background-color: #ded2d0;
  width: 100%;
  color: #fff;
  opacity: 0;
}
</style>
