<template>
  <Modal @close="closeModal">
    <template v-slot:title>{{ title }}</template>
    <template v-slot:body>
      <form>
        <div class="form-group row my-1">
          <Input
            v-model="seatPlanData.name"
            v-bind:placeholder="'Enter Name'"
            v-bind:label="'Name'"
            v-bind:id="'name'"
            v-bind:type="'text'"
          >
            <template v-slot:error>
              <Error v-if="errors.name" v-bind:error="errors.name" />
            </template>
          </Input>
        </div>
        <div class="form-group row my-1">
          <Input
            v-model="seatPlanData.upFare"
            v-bind:placeholder="'Enter Up Fare'"
            v-bind:label="'Up Fare'"
            v-bind:id="'upFare'"
            v-bind:type="'number'"
          >
            <template v-slot:error>
              <Error v-if="errors.upFare" v-bind:error="errors.upFare" />
            </template>
          </Input>
        </div>
        <div class="form-group row my-1">
          <Input
            v-model="seatPlanData.downFare"
            v-bind:placeholder="'Enter Down Fare'"
            v-bind:label="'Down Fare'"
            v-bind:id="'downFare'"
            v-bind:type="'number'"
          >
            <template v-slot:error>
              <Error v-if="errors.downFare" v-bind:error="errors.downFare" />
            </template>
          </Input>
        </div>
        <div class="form-group row my-1">
          <Input
            v-model="seatPlanData.numberOfTicketCopy"
            v-bind:placeholder="'Enter no of Ticket Copy'"
            v-bind:label="'No of T.Copy'"
            v-bind:id="'numberOfTicketCopy'"
            v-bind:type="'number'"
          >
            <template v-slot:error>
              <Error
                v-if="errors.numberOfTicketCopy"
                v-bind:error="errors.numberOfTicketCopy"
              />
            </template>
          </Input>
        </div>

        <div class="form-group row my-1">
          <ModalDropdown
            v-model="seatPlanData.status"
            v-bind:id="'status'"
            v-bind:label="'Status'"
            v-bind:dropdown="status"
          ></ModalDropdown>
        </div>

        <div class="form-group row my-1">
          <div class="form-group col-5 my-auto">
            <CheckboxInput
                v-model="seatPlanData.passengerCopy"
                :label="'Passenger Copy'"
            />
          </div>
          <div class="form-group col-4 my-auto">
            <CheckboxInput
                v-model="seatPlanData.officeCopy"
                :label="'Office Copy'"
            />
          </div>
          <div class="form-group col-3 my-auto">
            <CheckboxInput
                v-model="seatPlanData.isCabin"
                :label="'Cabin'"
            />
          </div>
          <div class="form-group col-md-12 my-auto">
            <input
                type="submit"
                class="mt-3 py-2 submit_button font_detail float-end"
                @click.prevent="updateSeatClass"
            />
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
import Input from "@/components/layouts/Modals/Inputs/Input";
import dropdownVariables from "@/modules/dropdownVariables";
import Error from "@/components/layouts/Modals/Inputs/Error";
import Modal from "@/components/layouts/Modals/Modal";
import useInputValidation from "@/modules/useInputValidations";
import { inject, onBeforeMount, ref, watch } from "vue";
import { reactive } from "@vue/reactivity";
import store from "@/store";
import CheckboxInput from "@/components/layouts/Inputs/CheckboxInput.vue";

// import Dropdown from "@/components/layouts/Modals/Inputs/Dropdown";

export default {
  name: "SeatClassUpdateModal",
  components: { Modal, Input, Error, CheckboxInput },
  props: {
    ship: String,
    company: String,
    seatClass: String,
    floor: String,
  },
  setup(props, { emit }) {
    let seatPlanData = reactive({
      id: "",
      companyId: "",
      shipId: "",
      name: "",
      upFare: 0,
      downFare: 0,
      status: 1,
      passengerCopy: false,
      officeCopy: false,
      floorId: "",
      isCabin: false,
    });
    let { requiredValidation, validNumberValidation, errors } =
      useInputValidation();
    const shipId = ref(props.ship);
    const companyId = ref(props.company);
    const seatPlanId = ref(props.seatClass);
    const status = dropdownVariables.status;
    const title = ref("Update Seat Class");
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");

    watch(
      () => seatPlanData.name,
      () => {
        requiredValidation("name", seatPlanData.name);
      }
    );
    watch(
      () => seatPlanData.upFare,
      () => {
        validNumberValidation("upFare", seatPlanData.upFare);
      }
    );
    watch(
      () => seatPlanData.downFare,
      () => {
        validNumberValidation("downFare", seatPlanData.downFare);
      }
    );
    watch(
      () => seatPlanData.numberOfTicketCopy,
      () => {
        validNumberValidation(
          "numberOfTicketCopy",
          seatPlanData.numberOfTicketCopy
        );
      }
    );

    const validate = () => {
      Object.keys(errors).forEach(function (key) {
        errors[key] = "";
      });
      requiredValidation("name", seatPlanData.name);
      validNumberValidation("upFare", seatPlanData.upFare);
      validNumberValidation("downFare", seatPlanData.downFare);
      validNumberValidation(
        "numberOfTicketCopy",
        seatPlanData.numberOfTicketCopy
      );
      return !(
        Object.values(errors).length <= 0 ||
        !Object.values(errors).every((x) => x === null || x === "")
      );
    };
    const closeModal = () => {
      emit("update:modelValue", false);
    };
    const updateSeatClass = () => {
      if (validate()) {
        showLoader();
        store
          .dispatch("updateSeatClassOwner", seatPlanData)
          .then(() => {
            hideLoader();
            closeModal();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              updateSeatClass();
            }
          });
      }
    };

    onBeforeMount(() => {
      Object.keys(errors).forEach(function (key) {
        delete errors[key];
      });
      const seatPlanDetails = store.getters.getSeatPlanOwner(
        seatPlanId.value,
        shipId.value
      );
      seatPlanData.id = seatPlanDetails._id;
      seatPlanData.name = seatPlanDetails.name;
      seatPlanData.upFare = parseInt(seatPlanDetails.upFare);
      seatPlanData.downFare = parseInt(seatPlanDetails.downFare);
      seatPlanData.numberOfTicketCopy = parseInt(
        seatPlanDetails.numberOfTicketCopy
      );
      seatPlanData.status = seatPlanDetails.status ? 1 : 0;
      seatPlanData.passengerCopy = seatPlanDetails.passengerCopy;
      seatPlanData.officeCopy = seatPlanDetails.officeCopy;
      seatPlanData.companyId = companyId.value;
      seatPlanData.shipId = shipId.value;
      seatPlanData.floorId = props.floor;
      seatPlanData.isCabin = seatPlanDetails.isCabin;
    });

    return {
      seatPlanId,
      seatPlanData,
      errors,
      status,
      title,
      closeModal,
      updateSeatClass,
    };
  },
};
</script>

<style scoped></style>
