<template>
  <div>
    <FilterTab>
      <template v-slot:body>
        <div class="col-sm-6 col-md-2"></div>
      </template>
      <template v-slot:button2>
        <button class="edit_table_data font_detail" @click="goBack">
          <i class="fas fa-arrow-left"></i
          ><span class="mx-1 bold">Go Back</span>
        </button>
      </template>
      <template v-slot:button1>
        <button
          class="edit_table_data font_detail"
          @click="exportTable('xlsx')"
        >
          <i class="fas fa-download"></i><span class="mx-1 bold">Export</span>
        </button>
      </template>
    </FilterTab>
  </div>
  <div
    class="card h-100 mt-4"
    v-for="(itemValue, itemKey) in classWiseReport"
    :key="itemKey"
  >
    <div class="card-header text-center bg-white">
      <h5 class="mb-0 text-uppercase">{{ itemKey }}</h5>
    </div>
    <div class="card-body mt-0 py-0">
      <table-simple :id="itemKey">
        <template v-slot:header>
          <tr>
            <td class="font_detail">SL</td>
            <td class="font_detail">Ticket Date Time</td>
            <td class="font_detail">Ship Name</td>
            <td class="font_detail">Passenger Name</td>
            <td class="font_detail">Passenger Phone</td>
            <td class="font_detail">Boarding Place</td>
            <td class="font_detail">Dropping Place</td>
            <td class="font_detail">Floor Name</td>
            <td class="font_detail">Seat Numbers</td>
            <td class="font_detail">Total Seat</td>
            <td class="font_detail">Receivable Amount</td>
            <td class="font_detail" v-if="cabinWiseSeatReport.discount">Discount</td>
            <td class="font_detail">Issued By</td>
            <td class="font_detail" v-if="cancelledBy === 0">Cancelled By</td>
            <td class="font_detail" v-if="cancelledBy === 0">Cancellation Cause</td>
          </tr>
        </template>
        <template v-slot:body>
          <tr v-for="(item, index) in itemValue.totalReport" :key="index">
            <td class="font_detail">{{ index + 1 }}</td>
            <td class="font_detail">{{ dateFormat(item.ticketDateTime) }}</td>
            <td class="font_detail">{{ item.shipName }}</td>
            <td class="font_detail">{{ item.passengerName || 'N/A' }}</td>
            <td class="font_detail">{{ item.passengerPhone || 'N/A' }}</td>
            <td class="font_detail">{{ item.boardingPlace }}</td>
            <td class="font_detail">{{ item.droppingPoint }}</td>
            <td class="font_detail">{{ item.floorName || 'N/A' }}</td>
            <td class="font_detail">{{ item.seatNumbers.replaceAll(",", ", ") }}</td>
            <td class="font_detail">{{ item.totalSeat }}</td>
            <td class="font_detail">{{ item.payable }}</td>
            <td class="font_detail" v-if="cabinWiseSeatReport.discount">{{ item.discount }}</td>
            <td class="font_detail">{{ item.issuedBy }}</td>
            <td class="font_detail" v-if="cancelledBy === 0">{{ item.cancelledBy }}</td>
            <td class="font_detail" v-if="cancelledBy === 0">{{ item.cancellationCause }}</td>
          </tr>
          <tr class="bg-dark text-white" v-if="summary">
            <td class="font_detail"></td>
            <td class="font_detail"></td>
            <td class="font_detail"></td>
            <td class="font_detail"></td>
            <td class="font_detail"></td>
            <td class="font_detail"></td>
            <td class="font_detail"></td>
            <td class="font_detail"></td>
            <td class="font_detail"></td>
            <td class="font_detail text-white">Total Seat: {{ itemValue.totalTicket }}</td>
            <td class="font_detail text-white">Total Amount: {{ itemValue.totalAmount }} Taka</td>
            <td class="font_detail text-white" v-if="cabinWiseSeatReport.discount">Total Discount: {{ itemValue.totalDiscount }} Taka</td>
            <td class="font_detail"></td>
            <td class="font_detail text-white" v-if="cancelledBy === 0"></td>
            <td class="font_detail text-white" v-if="cancelledBy === 0"></td>
          </tr>
        </template>
      </table-simple>
    </div>
  </div>
</template>


<script>
import { reactive } from "@vue/reactivity";
import { ref, onBeforeMount, inject } from "vue";
import store from "@/store";
import moment from "moment";

export default {
  name: "CounterSeatDetails",
  components: {},
  props: {
    cabinManId: String,
    tripCode: String,
    fromDate: String,
    toDate: String,
    status: Number,
    direction: String,
    discount: [Number, String],
  },
  emits: ["backToShipWiseReport"],
  setup(props, { emit }) {
    let cabinManId = ref(props.cabinManId);
    let fromDate = ref(props.fromDate);
    let toDate = ref(props.toDate);

    let totalAmountSeat = ref(0);
    let totalSeat = ref(0);
    let totalDiscount = ref(0);

    let summary = ref(false);
    let cancelledBy = ref(props.status);
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const exportExcel = inject("exportExcel");

    let cabinWiseSeatReport = reactive({
      ticketType: "SEAT",
      countermanId: cabinManId.value,
      fromDate: fromDate.value,
      toDate: toDate.value,
      direction: props.direction,
      status: props.status,
      discount: props.discount,
      page: 1,
      limit: 10,
      tripCode: props.tripCode,
    });

    let classWiseReport = reactive({});

    const dateFormat = (date) => {
      return moment(String(date))
        .utcOffset(6, false)
        .format("DD-MMM-yyyy hh:mm A");
    };

    const getSeatDetails = () => {
      showLoader();
      store
        .dispatch("getCabinmanWiseReportDetailsForOwner", cabinWiseSeatReport)
        .then((response) => {
          if (response.length !== 0) {
            classifyByClassName(response);
            
          } else {
            summary.value = false;
          }
          hideLoader();
        })
        .catch(() => hideLoader());
    };

    const classifyByClassName = (reports) => {
      reports.forEach((report) => {
        report.seatClassName = report.seatClassName.replace(/[\s]+/g, ' ').trim().toUpperCase()
        if (report.seatClassName in classWiseReport) {
          classWiseReport[report.seatClassName].totalReport.push(report);
        } else {
          classWiseReport[report.seatClassName] = {
            totalReport: [],
            totalTicket: 0,
            totalAmount: 0,
            totalDiscount: 0,
          };
          classWiseReport[report.seatClassName].totalReport.push(report);
        }
      });
      seatDetailsdata();
      summary.value = true;
    };

    const seatDetailsdata = () => {
      for (const key in classWiseReport) {
        classWiseReport[key].totalReport.forEach((data) => {
          classWiseReport[key].totalAmount += data.payable;
          classWiseReport[key].totalTicket += data.totalSeat;
          classWiseReport[key].totalDiscount += data.discount;
        });
      }
    };

    const goBack = () => {
      emit("backToShipWiseReport", true);
    };

    const exportTable = (type) => {
      for (const key in classWiseReport) {
        exportExcel(
          type,
          key + "_Admin_CounterWise_Details_Report.",
          undefined,
          key
        );
      }
    };

    onBeforeMount(() => {
      getSeatDetails();
    });

    return {
      cabinWiseSeatReport,
      totalAmountSeat,
      goBack,
      exportTable,
      totalSeat,
      summary,
      cancelledBy,
      totalDiscount,
      dateFormat,
      classWiseReport,
    };
  },
};
</script>

<style scoped>
h3,
h5 {
  font-size: 36px;
  color: #f04935;
}
h5 {
  font-size: 18px;
}
</style>