<template>
  <Modal @close="closeModal">
    <template v-slot:title>{{ title }}</template>
    <template v-slot:body>
      <form>
        <div class="form-group row my-1">
          <ModalDropdown
            v-if="routes"
            v-model="tripData.route"
            v-bind:id="'route'"
            v-bind:label="'Routes'"
            v-bind:defaultOption="'---select a route---'"
            v-bind:defaultValue="''"
            v-bind:dropdown="routes"
          >
            <template v-slot:error>
              <ModalError v-if="errors.route" v-bind:error="errors.route" />
            </template>
          </ModalDropdown>
        </div>

        <div v-if="showShipDropdown" class="form-group row my-1">
          <ModalDropdown
            v-if="ships"
            v-model="tripData.ship"
            v-bind:id="'ship'"
            v-bind:label="'Ship'"
            v-bind:defaultOption="'---select a ship---'"
            v-bind:defaultValue="''"
            v-bind:dropdown="ships"
          >
            <template v-slot:error>
              <ModalError v-if="errors.ship" v-bind:error="errors.ship" />
            </template>
          </ModalDropdown>
        </div>

        <div class="form-group row my-1">
          <ModalDropdown
            v-if="direction"
            v-model="tripData.direction"
            v-bind:id="'direction'"
            v-bind:label="'Direction'"
            v-bind:defaultOption="'---select a direction---'"
            v-bind:defaultValue="''"
            v-bind:dropdown="direction"
          >
            <template v-slot:error>
              <ModalError
                v-if="errors.direction"
                v-bind:error="errors.direction"
              />
            </template>
          </ModalDropdown>
        </div>

        <div v-if="tripId" class="form-group row my-1">
          <DatePicker
            v-model="tripData.tripDate"
            v-bind:id="'tripDate'"
            v-bind:label="'Trips Date'"
          >
            <template v-slot:error>
              <ModalError
                v-if="errors.tripDate"
                v-bind:error="errors.tripDate"
              />
            </template>
          </DatePicker>
        </div>

        <div v-else>
          <div class="form-group row my-1">
            <DatePicker
              v-model="tripData.fromDate"
              v-bind:id="'fromDate'"
              v-bind:label="'From'"
            >
              <template v-slot:error>
                <ModalError
                  v-if="errors.fromDate"
                  v-bind:error="errors.fromDate"
                />
              </template>
            </DatePicker>
          </div>

          <div class="form-group row my-1">
            <DatePicker
              v-model="tripData.toDate"
              v-bind:id="'toDate'"
              v-bind:label="'To'"
            >
              <template v-slot:error>
                <ModalError v-if="errors.toDate" v-bind:error="errors.toDate" />
              </template>
            </DatePicker>
          </div>
        </div>

        <div class="form-group row my-1">
          <TimePicker
            v-model="tripData.tripTime"
            v-bind:id="'tripTime'"
            v-bind:label="'Time'"
          >
            <template v-slot:error>
              <ModalError
                v-if="errors.tripTime"
                v-bind:error="errors.tripTime"
              />
            </template>
          </TimePicker>
        </div>

        <div class="form-group row my-1">
          <ModalCheckSwitch
            v-model="tripData.deckAvailability"
            v-bind:label="'Deck Available'"
            v-bind:id="'deck-available'"
            :disability="!shipDeckAvailability"
          >
            <template v-slot:error>
              <Error
                v-if="errors.deckAvailability"
                v-bind:error="errors.deckAvailability"
              />
            </template>
          </ModalCheckSwitch>
        </div>

        <div v-if="tripData.deckAvailability" class="form-group row my-1">
          <ModalInput
            v-model="tripData.deckFare"
            v-bind:placeholder="'Enter Deck Fare'"
            v-bind:label="'Deck Fare'"
            v-bind:id="'deck-fare'"
            v-bind:type="'number'"
          >
            <template v-slot:error>
              <ModalError
                v-if="errors.deckFare"
                v-bind:error="errors.deckFare"
              />
            </template>
          </ModalInput>
        </div>
        <div class="form-group row my-1">
          <ModalDropdown
            v-if="status"
            v-model="tripData.status"
            v-bind:id="'status'"
            v-bind:label="'Status'"
            v-bind:dropdown="status"
          ></ModalDropdown>
        </div>

        <div class="form-group row my-1">
          <label class="col-sm-3 col-form-label"></label>
          <div class="col-sm-9">
            <input
              v-if="!tripId"
              type="submit"
              class="mt-3 py-2 submit_button font_detail float-end"
              @click.prevent="addTrip"
            />
            <input
              v-else
              type="submit"
              class="mt-3 py-2 submit_button font_detail float-end"
              @click.prevent="updateTrip"
            />
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
import dropdownVariables from "@/modules/dropdownVariables";
import useInputValidation from "@/modules/useInputValidations";
import { computed, inject, onBeforeMount, ref, watch } from "vue";
import { reactive } from "@vue/reactivity";
import store from "@/store";
import moment from "moment";

export default {
  name: "TripsAddUpdateModal",
  props: {
    trip: String,
    company: String,
    routeList: Object,
  },
  setup(props, { emit }) {
    let { requiredValidation, errors } = useInputValidation();
    // let range = reactive({
    //   fromDate: moment(new Date()).format('yyyy-MM-DD'),
    //   toDate: moment(new Date()).format('yyyy-MM-DD'),
    //   tripDate: moment(new Date()).format('yyyy-MM-DD'),
    //   tripTime: moment(new Date()).format('HH:mm:00'),
    // })
    let tripData = reactive({
      id: "",
      companyId: "",
      ship: "",
      route: "",
      direction: "",
      fromDate: "",
      toDate: "",
      deckAvailability: false,
      deckFare: 0,
      tripDate: "",
      tripTime: "",
      status: "ACTIVE",
    });
    const tripId = ref(props.trip);
    const companyId = ref(props.company);
    const showShipDropdown = ref(false);
    const routes = ref(props.routeList);
    const ships = ref({});
    const updateModal = ref(false);
    const direction = dropdownVariables.direction;
    const title = ref("Add Trips");
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    let shipDeckAvailability = ref(true);

    const status = computed(() => {
      let allTripStatus = {};
      for (let [key, value] of Object.entries(dropdownVariables.tripStatus)) {
        allTripStatus[key] = value.value;
      }
      return allTripStatus;
    });

    const allShipsByRoutes = ref([]);

    watch(
      () => tripData.route,
      () => {
        if(!updateModal.value){
          tripData.ship = "";
        }
        requiredValidation("route", tripData.route);
        showLoader();
        ships.value = {};
        store
          .dispatch("getShipsByRouteForSupervisor", { routeId: tripData.route })
          .then((response) => {
            allShipsByRoutes.value = response;
            response.forEach((ship) => {
              ships.value[ship._id] = ship.name;
            });
            showShipDropdown.value = true;
            hideLoader();
          })
          .catch(() => {
            hideLoader();
          });
      }
    );
    watch(
      () => tripData.ship,
      () => {
        if (tripData.route) {
          requiredValidation("ship", tripData.ship);
        }
        if (tripData.ship) {
          showLoader();
          store
            .dispatch("getShipsByRouteForSupervisor", {
              routeId: tripData.route,
            })
            .then((response) => {
              const selectedShip = response.filter((item) => {
                return item._id === tripData.ship;
              })[0];
              shipDeckAvailability.value = selectedShip.deckAvailability;
              if (tripId.value) {
                if (!selectedShip.deckAvailability) {
                  tripData.deckAvailability = false;
                  tripData.deckFare = 0;
                }
              } else {
                tripData.deckAvailability = selectedShip.deckAvailability;
                tripData.deckFare = selectedShip.deckFare;
              }
              hideLoader();
            })
            .catch(() => {
              hideLoader();
            });
        }
      }
    );
    watch(
      () => tripData.tripTime,
      () => {
        requiredValidation("tripTime", tripData.tripTime);
      }
    );
    watch(
      () => tripData.direction,
      () => {
        requiredValidation("direction", tripData.direction);
      }
    );
    watch(
      () => tripData.fromDate,
      () => {
        tripData.toDate = tripData.fromDate;
      }
    );
    watch(
      () => tripData.toDate,
      () => {
        if (tripData.toDate < tripData.fromDate) {
          tripData.fromDate = tripData.toDate;
        }
      }
    );

    const validate = () => {
      Object.keys(errors).forEach(function(key) {
        errors[key] = "";
      });
      requiredValidation("route", tripData.route);
      requiredValidation("tripTime", tripData.tripTime);
      requiredValidation("direction", tripData.direction);
      if (tripId.value !== "") {
        requiredValidation("tripDate", tripData.tripDate);
      } else {
        requiredValidation("fromDate", tripData.fromDate);
        requiredValidation("toDate", tripData.toDate);
      }
      if (tripData.route) {
        requiredValidation("ship", tripData.ship);
      }
      return !(
        Object.values(errors).length <= 0 ||
        !Object.values(errors).every((x) => x === null || x === "")
      );
    };
    const closeModal = () => {
      emit("update:modelValue", false);
    };
    const addTrip = () => {
      if (validate()) {
        showLoader();
        store
          .dispatch("addSupervisorTrip", tripData)
          .then(() => {
            hideLoader();
            emit("getAllTrips");
            emit("close");
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              addTrip();
            }
          });
      }
    };
    const updateTrip = () => {
      if (validate()) {
        showLoader();
        store
          .dispatch("updateSupervisorTrip", tripData)
          .then(() => {
            hideLoader();
            emit("getAllTrips");
            emit("close");
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              updateTrip();
            }
          });
      }
    };

    onBeforeMount(() => {
      Object.keys(errors).forEach(function(key) {
        delete errors[key];
      });
      // Object.entries(props.routeList).forEach(el => {
      //   routes[el[0]] = el[1];
      // });
      if (tripId.value !== "") {
        updateModal.value = true
        const tripDetails = store.getters.getSupervisorTrip(tripId.value);
        tripData.route = tripDetails[0].route._id;
        title.value = "Update Trip";
        tripData.id = tripDetails[0]._id;
        tripData.ship = tripDetails[0].ship._id;
        tripData.direction = tripDetails[0].direction;
        tripData.deckAvailability = tripDetails[0].deckAvailability;
        tripData.deckFare = tripDetails[0].deckFare;
        tripData.tripDate = moment(tripDetails[0].tripDate)
          .utcOffset(0, false)
          .format("yyyy-MM-DD");
        tripData.tripTime = moment(tripDetails[0].tripDate)
          .utcOffset(0, false)
          .format("HH:mm:00");
        tripData.status = tripDetails[0].status;
      } else {
        tripData.fromDate = moment(new Date()).format("yyyy-MM-DD");
        tripData.toDate = moment(new Date()).format("yyyy-MM-DD");
        tripData.tripTime = moment(new Date()).format("HH:mm:00");
      }
      tripData.companyId = companyId.value;
    });

    return {
      tripId,
      errors,
      status,
      title,
      routes,
      showShipDropdown,
      ships,
      direction,
      tripData,
      addTrip,
      updateTrip,
      closeModal,
      shipDeckAvailability,
    };
  },
};
</script>

<style scoped></style>
