<template>
  <Modal @close="closeModal">
    <template v-slot:title>{{ title }}</template>
    <template v-slot:body>
      <form>
        <div class="form-group row my-1">
          <ModalInput
            v-model="shipData.name"
            :placeholder="'Enter Name'"
            :label="'Name'"
            :id="'name'"
            :type="'text'"
          >
            <template v-slot:error>
              <ModalError v-if="errors.name" v-bind:error="errors.name" />
            </template>
          </ModalInput>
        </div>

        <div class="form-group row my-1">
          <ModalInput
            v-model="shipData.bookingNumber"
            :placeholder="'Enter Booking Number'"
            :label="'Booking Number'"
            :id="'bookingNumber'"
            :type="'phoneNumber'"
            :isDisabled="modalType === 'copy' ? true : false"
          >
            <template v-slot:error>
              <ModalError
                v-if="errors.bookingNumber"
                :error="errors.bookingNumber"
              />
            </template>
          </ModalInput>
        </div>

        <div class="form-group row my-1">
          <ModalDropdown
            v-if="$store.getters.getRouteListForFilter"
            v-model="shipData.routeId"
            :id="'routeId'"
            :label="'Route'"
            :dropdown="$store.getters.getRouteListForFilter"
            :defaultOption="'--select route--'"
            :defaultValue="''"
            :isDisabled="modalType === 'copy' ? true : false"
          >
            <template v-slot:error>
              <ModalError v-if="errors.routeId" :error="errors.routeId" />
            </template>
          </ModalDropdown>
        </div>
        <div class="form-group row my-1">
          <ModalCheckSwitch
            v-model="shipData.deckAvailability"
            :label="'Deck Available'"
            :id="'deck-available'"
            :disability="modalType === 'copy' ? true : false"
          >
            <template v-slot:error>
              <Error
                v-if="errors.deckAvailability"
                :error="errors.deckAvailability"
              />
            </template>
          </ModalCheckSwitch>
        </div>

        <div v-if="shipData.deckAvailability" class="form-group row my-1">
          <ModalInput
            v-model="shipData.deckFare"
            :placeholder="'Enter Deck Fare'"
            :label="'Deck Fare'"
            :id="'deck-fare'"
            :type="'number'"
            :isDisabled="modalType === 'copy' ? true : false"
          >
            <template v-slot:error>
              <ModalError
                v-if="errors.deckFare"
                :error="errors.deckFare"
              />
            </template>
          </ModalInput>
        </div>

        <div class="form-group row my-1">
          <ModalCheckSwitch
              v-model="shipData.isMultiFloor"
              :label="'Floor Available'"
              :id="'floor-available'"
              :disability="modalType === 'copy' ? true : false"
          >
            <template v-slot:error>
              <Error
                  v-if="errors.isMultiFloor"
                  :error="errors.isMultiFloor"
              />
            </template>
          </ModalCheckSwitch>
        </div>

        <div class="form-group row my-1">
          <ModalDropdown
            v-if="status"
            v-model="shipData.status"
            :id="'status'"
            :label="'Status'"
            :dropdown="status"
            :isDisabled="modalType === 'copy' ? true : false"
          ></ModalDropdown>
        </div>

        <div class="form-group row my-1">
          <label class="col-sm-3 col-form-label"></label>
          <div class="col-sm-9">
            <input
              v-if="!shipId"
              type="submit"
              class="mt-3 py-2 submit_button font_detail loat-end"
              @click.prevent="addShip"
            />
            <input
              v-else-if="modalType === 'update'"
              type="submit"
              class="mt-3 py-2 submit_button font_detail float-end"
              @click.prevent="updateShip"
            />
            <input
              v-else-if="modalType === 'copy'"
              type="submit"
              class="mt-3 py-2 submit_button font_detail float-end"
              @click.prevent="copyShip"
            />
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
import dropdownVariables from "@/modules/dropdownVariables";
import useInputValidation from "@/modules/useInputValidations";
import { inject, onBeforeMount, ref, watch } from "vue";
import { reactive } from "@vue/reactivity";
import store from "@/store";

export default {
  name: "ShipsAddUpdateModal",
  props: {
    ship: String,
    modalType: String,
    company: String,
  },
  setup(props, { emit }) {
    let shipData = reactive({
      id: "",
      companyId: "",
      routeId: "",
      name: "",
      bookingNumber: "",
      seatCapacity: 0,
      deckAvailability: false,
      deckFare: 0,
      isMultiFloor: false,
      status: 1,
    });
    let {
      requiredValidation,
      validNumberValidation,
      requiredValidationNumber,
      errors,
    } = useInputValidation();

    let copyShipData = reactive({
      companyId: "",
      shipId:"",
      name: ""
    })
    const shipId = ref(props.ship);
    const actionType = ref(props.modalType);
    const companyId = ref(props.company);
    const status = dropdownVariables.status;
    const title = ref("Add Ship");
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");

    watch(
      () => shipData.deckFare,
      () => {
        if (shipData.deckAvailability) {
          validNumberValidation("deckFare", shipData.deckFare);
        }
      }
    );
    watch(
      () => shipData.name,
      () => {
        if(actionType.value === 'copy'){
          copyShipData.name = shipData.name
        }
        requiredValidation("name", shipData.name);
      }
    );
    watch(
      () => shipData.bookingNumber,
      () => {
        requiredValidationNumber("bookingNumber", shipData.bookingNumber);
      }
    );
    watch(
      () => shipData.routeId,
      () => {
        requiredValidation("routeId", shipData.routeId);
      }
    );

    const validate = () => {
      Object.keys(errors).forEach(function (key) {
        errors[key] = "";
      });
      requiredValidation("name", shipData.name);
      requiredValidationNumber("bookingNumber", shipData.bookingNumber);
      requiredValidation("routeId", shipData.routeId);
      if (shipData.deckAvailability) {
        validNumberValidation("deckFare", shipData.deckFare);
      }
      return !(
        Object.values(errors).length <= 0 ||
        !Object.values(errors).every((x) => x === null || x === "")
      );
    };

    const closeModal = () => {
      emit("update:modelValue", false);
    };

    const addShip = () => {
      if (validate()) {
        showLoader();
        store
          .dispatch("addShip", shipData)
          .then(() => {
            hideLoader();
            emit("getAllShips");
            closeModal();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              addShip();
            }
          });
      }
    };

    const updateShip = () => {
      if (validate()) {
        showLoader();
        store
          .dispatch("updateShip", shipData)
          .then(() => {
            hideLoader();
            emit("getAllShips");
            closeModal();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              updateShip();
            }
          });
      }
    };

    const copyShip = () => {
      if (validate()) {
        showLoader();
        store
          .dispatch("copyShip", copyShipData)
          .then(() => {
            hideLoader();
            emit("getAllShips");
            closeModal();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              copyShip();
            }
          });
      }
    };

    onBeforeMount(() => {
      Object.keys(errors).forEach(function (key) {
        delete errors[key];
      });
      if (shipId.value !== "") {
        const shipDetails = store.getters.getShip(shipId.value);
        if(actionType.value === 'update'){
          title.value = "Update Ship";
        } else {
          title.value = "Copy Ship";
          copyShipData.companyId = companyId.value;
          copyShipData.shipId = shipId.value;
          copyShipData.name = shipDetails[0].name;
        }
        shipData.id = shipDetails[0]._id;
        shipData.routeId = shipDetails[0].routeId[0];
        shipData.name = shipDetails[0].name;
        shipData.bookingNumber = shipDetails[0].bookingNumber;
        shipData.deckAvailability = shipDetails[0].deckAvailability;
        shipData.deckFare = shipDetails[0].deckFare;
        shipData.seatCapacity = shipDetails[0].seatCapacity
        shipData.isMultiFloor = shipDetails[0].isMultiFloor;
        shipData.status = shipDetails[0].status ? 1 : 0;
      }
      shipData.companyId = companyId.value;
    });

    return {
      shipId,
      shipData,
      copyShipData,
      errors,
      status,
      title,
      closeModal,
      addShip,
      updateShip,
      actionType,
      copyShip
    };
  },
};
</script>

<style scoped>
</style>