<template>
  <label :for="inputId" class="label_font">{{inputLabel}}</label>
  <select v-model="input" :id="inputId"
          class="form-select form-select-sm py-2 px-3 w-100 mt-1 mb-4"
          aria-label="example">
    <option v-if="inputDefaultOption" :value="inputDefaultValue">{{inputDefaultOption}}
    </option>
    <option v-for="(type,index) in inputDropdown" :key="index" :value="index">
      {{ type }}
    </option>
  </select>
</template>

<script>
import {ref, watch} from "vue";

export default {
  name: "Dropdown",
  props:{
    modelValue:[String,Number],
    dropdown:Object,
    defaultOption:String,
    defaultValue:String,
    label:String,
    id:String,
  },
  setup(props,{emit}) {
    const inputDropdown = ref(props.dropdown)
    const inputDefaultOption = ref(props.defaultOption)
    const inputDefaultValue = ref(props.defaultValue)
    const inputLabel = ref(props.label)
    const inputId = ref(props.id)
    const input = ref(props.modelValue)
    watch(()=>input.value,()=>{
      emit("update:modelValue", input.value)
    })
    watch(() => props.dropdown, (value) => {
      inputDropdown.value = value
    });
    return{
      inputDropdown,inputDefaultOption,inputDefaultValue,inputLabel,inputId,
      input
    }
  }
};
</script>

<style scoped>

</style>