<template>
  <Modal @close="closeModal" v-bind:size="'md'">
    <template v-slot:title>Are you sure want to logout?</template>
    <template v-slot:body>
      <div>
        <button @click.prevent="logoutDeckman" class="btn btn-primary">
          Confirm
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
import { inject, reactive } from "vue";
import store from "@/store";

export default {
  name: "DeckmanLogoutModal",
  props: {
    modalValue: Boolean,
    deckman: String,
  },
  components: {},
  setup(props, { emit }) {
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const payload = reactive({
      id: props.deckman,
    });
    const closeModal = () => {
      emit("update:modelValue", false);
    };
    const logoutDeckman = () => {
      showLoader();
      store
        .dispatch("logoutDeckMan", payload)
        .then(() => {
          hideLoader();
          emit("update:modelValue", false);
        })
        .catch(() => {
          hideLoader();
          emit("update:modelValue", false);
        });
    };
    return {
      closeModal,
      logoutDeckman,
    };
  },
};
</script>

<style scoped></style>
