<template>
    <Modal @close="closeModal">
        <template v-slot:title>{{ title }}</template>
        <template v-slot:body>
            <form>
                <div class="form-group row my-1">
                    <ModalInput v-model="rechargeData.rechargeAmount"
                                v-bind:placeholder="'Enter Recharge Amount'"
                                v-bind:label="'Recharge Amount'" v-bind:id="'name'" v-bind:type="'number'">
                        <template v-slot:error>
                            <ModalError v-if="errors.rechargeAmount" v-bind:error="errors.rechargeAmount"/>
                        </template>
                    </ModalInput>
                </div>

                <div class="form-group row my-1">
                    <label class="col-sm-3 col-form-label"></label>
                    <div class="col-sm-9">
                        <input type="submit" class="mt-3 py-2 submit_button font_detail float-end"
                               @click.prevent="recharge"/>
                    </div>
                </div>
            </form>
        </template>
    </Modal>
</template>

<script>
import useInputValidation from "@/modules/useInputValidations";
import {inject, onBeforeMount, ref, watch} from "vue";
import {reactive} from "@vue/reactivity";
import store from "@/store";

export default {
    name: "RechargeModal",
    props: {
        modelValue: Boolean,
        companyId: String,
        agentId: String,
    },
    setup(props, {emit}) {
        let rechargeData = reactive({
            agentId: props.agentId,
            rechargeAmount: 0,
        });
        let {validNumberValidation, errors} = useInputValidation();
        const title = ref("Recharge Agent Balance");
        const showLoader = inject('showLoader');
        const hideLoader = inject('hideLoader');

        watch(() => rechargeData.rechargeAmount, () => {
            validNumberValidation("rechargeAmount", rechargeData.rechargeAmount);
        });

        const validate = () => {
            Object.keys(errors).forEach(function (key) {
                errors[key] = ""
            });
            validNumberValidation("rechargeAmount", rechargeData.rechargeAmount);
            return !(Object.values(errors).length <= 0 || !Object.values(errors).every(x => x === null || x === ''));
        };
        const closeModal = () => {
            emit("update:modelValue", false);
        };
        const recharge = () => {
            if (validate()) {
                showLoader()
                store.dispatch("rechargeBalance", rechargeData).then(() => {
                    hideLoader()
                    emit('getAllAgents');
                    closeModal();
                }).catch((error) => {
                    hideLoader()
                    if (error.status === 401) {
                        recharge()
                    }
                });

            }
        };

        onBeforeMount(() => {
            Object.keys(errors).forEach(function (key) {
                delete errors[key];
            });
        });

        return {
            rechargeData, errors, status, title,
            closeModal, recharge
        };
    }
}
</script>

<style scoped>

</style>