<template>
    <div class="card card-body py-4" style="background-color: whitesmoke">
        <div class="row">
            <div v-if="purchaseStatus" class="row m-0">
                <div v-if="$store.getters.userType !== 'Agent'">
                    <TicketPrintPreview
                            v-bind:ticket="purchasedTicket"
                            v-bind:ticketType="'ticket'"
                            @close="close"
                    />
                </div>
                <div v-else>
                    <TicketPrintPreviewForAgent
                            v-bind:ticket="purchasedTicket"
                            v-bind:ticketType="'ticket'"
                            @close="close"
                    />
                </div>
            </div>
            <div v-else class="row m-0">
                <div
                        class="col-md-6 col-lg-3 col-xl-3 order-md-1 order-lg-1 order-xl-1"
                >
                    <span class="fw-bold">SUMMARY</span>
                    <div
                            class="card card-body bg-dark border-3 rounded-3 border-secondary"
                    >
                        <div class="row my-1">
                            <div class="col-md-4">
                <span class="text-white fw-bold border-2 border-white"
                >SEATS</span
                >
                            </div>
                            <div class="col-md-8">
                <span class="orange-bold-text fw-bold"
                >#{{ seatInformation.seats.toString() }}</span
                >
                            </div>
                        </div>
                        <div class="row my-1">
                            <div class="col-md-4">
                                <span class="text-white fw-bold">CLASS</span>
                            </div>
                            <div class="col-md-8">
                <span class="orange-bold-text fw-bold">{{
                        seatInformation.seatClassName
                    }}</span>
                            </div>
                        </div>
                        <div class="card bg-transparent border-1 border-info my-1 py-1">
                            <div class="row my-1">
                                <div class="col-md-4">
                                    <span class="text-white fw-bold">AMOUNT</span>
                                </div>
                                <div class="col-md-8">
                  <span class="text-white fw-bold"
                  >{{ seatInformation.totalAmount }} Taka</span
                  >
                                </div>
                            </div>
                            <div class="row my-1">
                                <div class="col-md-4">
                                    <span class="text-white fw-bold">DISCOUNT</span>
                                </div>
                                <div class="col-md-8">
                  <span class="text-white fw-bold"
                  >{{ seatInformation.discount }} Taka</span
                  >
                                </div>
                            </div>
                            <div class="border-top border-1 border-white mx-3"></div>
                            <div class="row my-1">
                                <div class="col-md-4">
                                    <span class="text-white fw-bold">PAYABLE</span>
                                </div>
                                <div class="col-md-8">
                  <span class="orange-bold-text fw-bold"
                  >{{ seatInformation.payable }} Taka</span
                  >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                        class="col-md-12 col-lg-6 col-xl-6 order-md-3 order-lg-2 order-xl-2"
                >
                    <span class="fw-bold">BOOKING INFORMATION</span>
                    <div class="card card-body">
                        <div class="mx-6">
                            <form class="my-2">
                                <div class="row">
                                    <div class="col-md-6 col-lg-12 col-xl-6">
                                        <div class="form-group row">
                                            <TicketInput
                                                    v-model="passengerDetails.passengerName"
                                                    v-bind:placeholder="'Passenger Name'"
                                                    v-bind:label="'Name'"
                                                    v-bind:id="'name'"
                                                    v-bind:type="'text'"
                                            />
                                        </div>
                                        <div class="form-group row my-1">
                                            <TicketInput
                                                    v-model="passengerDetails.passengerPhone"
                                                    v-bind:placeholder="'Passenger Mobile'"
                                                    v-bind:label="'Mobile'"
                                                    v-bind:id="'mobile'"
                                                    v-bind:type="'text'"
                                            >
                                                <template v-slot:error>
                                                    <TicketError
                                                            v-if="errors.passengerPhone"
                                                            v-bind:error="errors.passengerPhone"
                                                    />
                                                </template>
                                            </TicketInput>
                                        </div>
                                        <div class="form-group row my-1">
                                            <TicketDropdown
                                                    v-model="passengerDetails.passengerGender"
                                                    v-bind:id="'passengerGender'"
                                                    v-bind:label="'Gender'"
                                                    v-bind:dropdown="dropdownVariables.gender"
                                                    v-bind:defaultOption="'--select gender --'"
                                                    v-bind:defaultValue="''"
                                            >
                                            </TicketDropdown>
                                        </div>
                                        <div class="form-group row my-1">
                                            <TicketInput
                                                    v-model="passengerDetails.passengerNID"
                                                    v-bind:placeholder="'Passenger NID'"
                                                    v-bind:label="'NID'"
                                                    v-bind:id="'nid'"
                                                    v-bind:type="'text'"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-lg-12 col-xl-6">
                                        <div class="form-group row my-1">
                                            <div class="col-sm-3">
                                                <label for="address" class="col-form-label font_detail"
                                                >Address</label
                                                >
                                            </div>
                                            <div class="col-sm-9">
                        <textarea
                                class="form-control font_detail"
                                id="address"
                                v-model="passengerDetails.passengerAddress"
                        ></textarea>
                                            </div>
                                        </div>
                                        <div class="form-group row my-1">
                                            <TicketDropdown
                                                    v-model="passengerDetails.boardingPlace"
                                                    v-bind:id="'boardingPlace'"
                                                    v-bind:label="'Boarding place'"
                                                    v-bind:dropdown="boardingPlaceDropDown"
                                            >
                                                <template v-slot:error>
                                                    <TicketError
                                                            v-if="errors.boardingPlace"
                                                            v-bind:error="errors.boardingPlace"
                                                    />
                                                </template>
                                            </TicketDropdown>
                                        </div>
                                        <div class="form-group row my-1">
                                            <TicketDropdown
                                                    v-model="passengerDetails.droppingPoint"
                                                    v-bind:id="'droppingPoint'"
                                                    v-bind:label="'Dropping place'"
                                                    v-bind:dropdown="droppingPointDropDown"
                                            >
                                                <template v-slot:error>
                                                    <TicketError
                                                            v-if="errors.droppingPoint"
                                                            v-bind:error="errors.droppingPoint"
                                                    />
                                                </template>
                                            </TicketDropdown>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div
                        class="col-md-6 col-lg-3 col-xl-3 order-md-2 order-lg-3 order-xl-3"
                >
                    <span class="fw-bold">ACTIONS</span>
                    <div class="card card-body">
                        <div class="row">
                            <div class="col-md-12 col-lg-6 col-xl-6">
                                <p class="text-center font_detail m-0">RESET</p>
                                <button class="reset_button w-100" @click="reset">
                                    <i class="fas fa-trash-alt"></i>
                                </button>
                            </div>
                            <div class="col-md-12 col-lg-6 col-xl-6">
                                <p class="text-center font_detail m-0">CANCEL</p>
                                <button class="cancel_button w-100" @click="close">
                                    <i class="fas fa-times"></i>
                                </button>
                            </div>
                            <div class="col-md-12 col-lg-12 col-xl-12">
                                <p class="text-center font_detail m-0">CONFIRM</p>
                                <button
                                        class="confirm_button w-100"
                                        @click.prevent="purchaseTicket"
                                >
                                    <i class="fas fa-check"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import dropdownVariables from "@/modules/dropdownVariables";
import useInputValidation from "@/modules/useInputValidations";
import {onBeforeMount, ref, computed, watch, inject} from "vue";
import {reactive} from "@vue/reactivity";
import store from "@/store";
import moment from "moment";
import toast from "@/modules/Toast";

const {errorToast} = toast();

export default {
    name: "TicketPurchase",
    props: {
        seatInfo: Object,
    },
    setup(props, {emit}) {
        let {requiredValidation, validPhoneValidation, errors} =
            useInputValidation();
        let passengerDetails = reactive({
            ticketType: "SEAT",
            issuedBy: store.getters.userName,
            countermanId: store.getters.userId,
            passengerName: "",
            passengerPhone: "",
            passengerGender: "",
            passengerNID: "",
            passengerAddress: "",
            boardingPlace: "",
            droppingPoint: "",
            companyId: "",
            tripId: "",
            direction: "",
            shipId: "",
            shipName: "",
            seatClassId: "",
            seatClassName: "",
            seatNumbers: "",
            totalAmount: "0",
            discount: "0",
            payable: "0",
            paid: 0,
            dues: 0,
            refund: 0,
            ticketDateTime: "",
        });
        const seatInformation = ref(props.seatInfo);
        const title = ref("Print Booking");
        const purchaseStatus = ref(false);
        const purchasedTicket = ref({});
        const status = dropdownVariables.status;
        const showLoader = inject("showLoader");
        const hideLoader = inject("hideLoader");

        watch(
            () => passengerDetails.boardingPlace,
            () => {
                requiredValidation("boardingPlace", passengerDetails.boardingPlace);
            }
        );
        watch(
            () => passengerDetails.droppingPoint,
            () => {
                requiredValidation("droppingPoint", passengerDetails.droppingPoint);
            }
        );
        watch(
            () => passengerDetails.passengerPhone,
            () => {
                validPhoneValidation("passengerPhone", passengerDetails.passengerPhone);
            }
        );

        const boardingPlaceDropDown = computed(() => {
            let boardingPlace = {};
            let boarding = store.getters.getTripForTicketingByAgent(
                seatInformation.value.tripId
            )[0].route.boardingPlace;
            boarding.forEach((item) => {
                boardingPlace[item] = item;
            });
            return boardingPlace;
        });

        const reset = () => {
            passengerDetails.passengerName = "";
            passengerDetails.passengerPhone = "";
            passengerDetails.passengerGender = "";
            passengerDetails.passengerNID = "";
            passengerDetails.passengerAddress = "";
            passengerDetails.boardingPlace = "";
            passengerDetails.droppingPoint = "";
        };

        const droppingPointDropDown = computed(() => {
            let droppingPoint = {};
            let boarding = store.getters.getTripForTicketingByAgent(
                seatInformation.value.tripId
            )[0].route.droppingPoint;
            boarding.forEach((item) => {
                droppingPoint[item] = item;
            });
            return droppingPoint;
        });
        const validate = () => {
            Object.keys(errors).forEach(function (key) {
                errors[key] = "";
            });
            requiredValidation("boardingPlace", passengerDetails.boardingPlace);
            requiredValidation("droppingPoint", passengerDetails.droppingPoint);
            validPhoneValidation("passengerPhone", passengerDetails.passengerPhone);
            return !(
                Object.values(errors).length <= 0 ||
                !Object.values(errors).every((x) => x === null || x === "")
            );
        };
        const close = () => {
            emit("refreshPage");
            emit("update:modelValue", false);
        };
        const purchaseTicket = () => {
            if (passengerDetails.payable > store.getters.agentBalance) {
                errorToast("Out of Balance!!!");
            } else {
                passengerDetails.ticketDateTime = moment(new Date())
                    .utcOffset(0, true)
                    .format("yyyy-MM-DD hh:mm:ss");
                if (validate()) {
                    showLoader();
                    store
                        .dispatch("purchaseTicketByAgent", passengerDetails)
                        .then((ticket) => {
                            hideLoader();
                            purchaseStatus.value = true;
                            purchasedTicket.value = ticket;
                            store.commit("updateBalance", ticket.payable);
                        })
                        .catch((error) => {
                            purchaseStatus.value = false;
                            hideLoader();
                            if (error.status === 401) {
                                purchaseTicket();
                            }
                        });
                }
            }
        };

        onBeforeMount(() => {
            passengerDetails.companyId = store.getters.userCompany;
            passengerDetails.tripId = seatInformation.value.tripId;
            passengerDetails.direction = store.getters.getTripForTicketingByAgent(
                seatInformation.value.tripId
            )[0].direction;
            passengerDetails.shipId = store.getters.getTripForTicketingByAgent(
                seatInformation.value.tripId
            )[0].ship._id;
            passengerDetails.shipName = store.getters.getTripForTicketingByAgent(
                seatInformation.value.tripId
            )[0].ship.name;
            passengerDetails.seatClassId = seatInformation.value.seatClassId;
            passengerDetails.seatClassName = seatInformation.value.seatClassName;
            passengerDetails.seatNumbers = seatInformation.value.seats.toString();
            passengerDetails.totalAmount = seatInformation.value.totalAmount;
            passengerDetails.discount = seatInformation.value.discount;
            passengerDetails.payable = seatInformation.value.payable;
            passengerDetails.paid = seatInformation.value.payable;
            passengerDetails.boardingPlace = store.getters.getTripForTicketingByAgent(
                seatInformation.value.tripId
            )[0].route.boardingPlace[0];
            passengerDetails.droppingPoint = store.getters.getTripForTicketingByAgent(
                seatInformation.value.tripId
            )[0].route.droppingPoint[0];
        });

        return {
            seatInformation,
            purchaseStatus,
            purchasedTicket,
            status,
            title,
            passengerDetails,
            boardingPlaceDropDown,
            droppingPointDropDown,
            dropdownVariables,
            errors,
            purchaseTicket,
            reset,
            close,
        };
    },
};
</script>

<style scoped>
.ticket-info {
    font-size: 12px !important;
}

.cancel_button {
    margin: 1px 2px;
    border: 2px solid #ff0000;
    border-radius: 4px;
    background-color: #ff0000;
    padding: 2px 6px;
    color: #fff;
    position: relative;
    font-size: 14px;
    font-family: Helvetica Neue;
}

.cancel_button:hover,
.cancel_button:focus {
    border: 2px solid #ff0000;
    background-color: #fff;
    color: #ff0000;
    transition: 0.4s;
    outline: 0;
}

.confirm_button {
    margin: 1px 2px;
    border: 2px solid #34a853;
    border-radius: 4px;
    background-color: #34a853;
    padding: 2px 6px;
    color: #fff;
    position: relative;
    font-size: 14px;
    font-family: Helvetica Neue;
}

.confirm_button:hover,
.confirm_button:focus {
    border: 2px solid #34a853;
    background-color: #fff;
    color: #34a853;
    transition: 0.4s;
    outline: 0;
}

.reset_button {
    margin: 1px 2px;
    border: 2px solid #ffc107;
    border-radius: 4px;
    background-color: #ffc107;
    padding: 2px 6px;
    color: #ffff;
    position: relative;
    font-size: 14px;
    font-family: Helvetica Neue;
}

.reset_button:hover,
.reset_button:focus {
    border: 2px solid #ffc107;
    background-color: #fff;
    color: #ffc107;
    transition: 0.4s;
    outline: 0;
}
</style>