<template>
  <div v-if="reportActive">
    <FilterTabBetween>
      <template v-slot:body>
        <div v-if="shipWiseReport.companyId" class="col-sm-6 col-md-2">
          <DropdownFilter
            :id="'ship'"
            :label="'Ship'"
            :defaultOption="'--select ship--'"
            v-model="shipWiseReport.shipId"
            :defaultValue="''"
            :dropdown="$store.getters.getShipListForFilterOwner"
          />
        </div>
        <div v-if="shipWiseReport.companyId" class="col-sm-6 col-md-3">
          <DateTimeRangePicker v-model="date.range" />
        </div>

        <div v-if="shipWiseReport.companyId" class="col-sm-6 col-md-auto">
          <DropdownFilter
            :id="'direction'"
            :label="'Direction'"
            :defaultOption="'--select direction--'"
            v-model="shipWiseReport.direction"
            :defaultValue="''"
            :dropdown="dropdownVariables.direction"
          />
        </div>

        <div v-if="shipWiseReport.companyId" class="col-sm-6 col-md-2 col-xxl-2">
          <DropdownFilter
            :id="'status'"
            :label="'Status'"
            v-model="shipWiseReport.status"
            :dropdown="dropdownVariables.reportStatus"
          />
        </div>
        <div v-if="shipWiseReport.companyId" class="col-sm-6 col-md-auto">
          <CheckboxSingle v-model="isDiscount" />
        </div>
        <div class="col-sm-2 col-md-auto mt-auto">
          <button class="edit_table_data font_detail" @click="filterReport">
            <i class="fas fa-filter"></i><span class="mx-1 bold">Filter</span>
          </button>
        </div>
      </template>
      <!-- <template v-slot:filter>
        <button class="edit_table_data font_detail" @click="filterReport">
          <i class="fas fa-filter"></i><span class="mx-1 bold">Filter</span>
        </button>
      </template> -->
      <template v-slot:export>
        <button
          class="edit_table_data font_detail"
          @click="
            exportExcel(
              'xlsx',
              'Owner_Ship_Wise_Report.',
              undefined,
              'exportable_data'
            )
          "
        >
          <i class="fas fa-download"></i><span class="mx-1 bold">Export</span>
        </button>
      </template>
    </FilterTabBetween>
    <Table
      v-if="shipWiseReport.companyId !== '' && filterActive"
      id="exportable_data"
      :key="tripCountColumn"
    >
      <template v-slot:header>
        <tr>
          <td class="font_detail">SL</td>
          <td class="font_detail">Ship Name</td>
          <td class="font_detail" v-if="tripCountColumn">
            Total Trips
          </td>
          <td class="font_detail">Deck Ticket</td>
          <td class="font_detail">Deck Amount</td>

          <td class="font_detail">Goods Ticket</td>
          <td class="font_detail">Goods Amount</td>
          <td class="font_detail">Seat Ticket</td>
          <td class="font_detail">{{discountStatus ? 'Discount Amount':'Seat Amount'}}</td>
          <td class="font_detail">Details</td>
        </tr>
      </template>
      <template v-slot:body>
        <tr
          v-for="(item, index) in $store.getters.getShipWiseReportOwner"
          :key="item._id"
        >
          <td class="font_detail">{{ index + 1 }}</td>
          <td class="font_detail">{{ item.shipName }}</td>
          <td class="font_detail" v-if="tripCountColumn">
            {{ item.tripCount }}
          </td>
          <td class="font_detail">{{ item.deckTicket }}</td>
          <td class="font_detail">{{ item.deckAmount }} Taka</td>

          <td class="font_detail">{{ item.goodsTicket }}</td>
          <td class="font_detail">{{ item.goodsAmount }} Taka</td>
          <td class="font_detail">{{ item.seatTicket }}</td>
          <td class="font_detail">{{ item.seatAmount }} Taka</td>
          <td class="font_detail">
            <button
              class="btn btn-primary m-1"
              @click="deckDetails(item.shipId, item.shipName)"
            >
              Deck
            </button>
            <button
              class="btn btn-secondary text-light m-1"
              @click="goodDetails(item.shipId, item.shipName)"
            >
              Goods
            </button>
            <button
              class="btn btn-success m-1"
              @click="seatDetails(item.shipId, item.shipName)"
            >
              Seat
            </button>
          </td>
        </tr>
        <tr class="bg-dark" v-if="summary">
          <td class="font_detail"></td>
          <td class="font_detail"></td>
          <td class="font_detail" v-if="tripCountColumn"></td>
          <td class="font_detail text-white">
            Total Ticket: {{ deckTicketTotal }}
          </td>
          <td class="font_detail text-white">
            Total Amount: {{ deckAmountTotal }} Taka
          </td>

          <td class="font_detail text-white">
            Total Ticket: {{ goodTicketTotal }}
          </td>
          <td class="font_detail text-white">
            Total Amount: {{ goodAmountTotal }} Taka
          </td>
          <td class="font_detail text-white">
            Total Seat: {{ seatTicketTotal }}
          </td>
          <td class="font_detail text-white">
            Total Amount: {{ seatAmountTotal }} Taka
          </td>
          <td class="font_detail"></td>
        </tr>
      </template>
      <!-- <template v-slot:pagination>
              <Pagination v-model="pagination.page" :records="$store.getters.getTotalShipWiseReport" :per-page="pagination.limit"
                          @paginate="getAllCompanyList" :options="options"/>
           </template> -->
    </Table>
  </div>
  <DeckDetails
    v-if="deckDetailsActive"
    :shipId="shipIdPass"
    :shipName="shipNamePass"
    :fromDate="date.range.start"
    :toDate="date.range.end"
    :direction="shipWiseReport.direction"
    :discount="shipWiseReport.discount"
    :status="shipWiseReport.status"
    v-on:backToShipWiseReport="onChildClick"
  />
  <GoodDetails
    v-if="goodDetailsActive"
    :shipId="shipIdPass"
    :shipName="shipNamePass"
    :fromDate="date.range.start"
    :toDate="date.range.end"
    :direction="shipWiseReport.direction"
    :discount="shipWiseReport.discount"
    :status="shipWiseReport.status"
    v-on:backToShipWiseReport="onChildClick"
  />
  <SeatDetails
    v-if="seatDetailsActive"
    :shipId="shipIdPass"
    :shipName="shipNamePass"
    :fromDate="date.range.start"
    :toDate="date.range.end"
    :direction="shipWiseReport.direction"
    :discount="shipWiseReport.discount"
    :status="shipWiseReport.status"
    v-on:backToShipWiseReport="onChildClick"
  />
  <router-view></router-view>
</template>

<script>
import DropdownFilter from "@/components/layouts/Filter/Inputs/Dropdown";
import CheckboxSingle from "@/components/layouts/Inputs/CheckboxSingle.vue";
import dropdownVariables from "@/modules/dropdownVariables";
import { reactive } from "@vue/reactivity";
import moment from "moment";
import { watch, onBeforeMount, ref, inject } from "vue";
import store from "@/store";
import DeckDetails from "./DeckDetails.vue";
import GoodDetails from "./GoodDetails.vue";
import SeatDetails from "./SeatDetails.vue";

export default {
  name: "ShipReport",
  components: {
    DropdownFilter,
    DeckDetails,
    SeatDetails,
    CheckboxSingle,
    GoodDetails,
  },
  emits: ["backToShipWiseReport"],

  setup() {
    let deckDetailsActive = ref(false);
    let goodDetailsActive = ref(false);
    let seatDetailsActive = ref(false);
    let reportActive = ref(true);
    let shipIdPass = ref("");
    let shipNamePass = ref("");
    let filterActive = ref(false);

    let deckTicketTotal = ref(0);
    let deckAmountTotal = ref(0);

    let goodTicketTotal = ref(0);
    let goodAmountTotal = ref(0);
    let seatTicketTotal = ref(0);
    let seatAmountTotal = ref(0);
    let isDiscount = ref(false);
    let discountStatus = ref(false);
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const exportExcel = inject("exportExcel");
  const tripCountColumn = ref(false);
    let summary = ref(false);

    const fromDate = moment(new Date()).format("yyyy-MM-DD" + " 00:00:00");
    const toDate = moment(new Date()).format("yyyy-MM-DD" + " 23:59:59");

    let date = reactive({
      range: {
        start: fromDate,
        end: toDate,
      },
    });

    let shipWiseReport = reactive({
      companyId: "",
      shipId: "",
      fromDate: "",
      toDate: "",
      direction: "",
      discount: "",
      status: 1,
      page: 1,
      limit: 10,
    });

    const options = {
      texts: {
        count: "",
      },
    };

    watch(
      () => isDiscount.value,
      () => {
        isDiscount.value
          ? (shipWiseReport.discount = 1)
          : (shipWiseReport.discount = "");
      }
    );

    watch(
      () => shipWiseReport.companyId,
      (value) => {
        shipWiseReport.companyId = value;
      }
    );
    watch(
      () => shipWiseReport.shipId,
      (value) => {
        shipWiseReport.shipId = value;
      }
    );
    watch(
      () => shipWiseReport.direction,
      (value) => {
        shipWiseReport.direction = value;
      }
    );
    watch(
      () => shipWiseReport.status,
      (value) => {
        shipWiseReport.status = value;
      }
    );
    watch(
      () => date.range.start,
      (value) => {
        shipWiseReport.fromDate = value
        // shipWiseReport.fromDate = moment(value).format(
        //   "yyyy-MM-DD" + " 00:00:00"
        // );
      }
    );
    watch(
      () => date.range.end,
      (value) => {
        shipWiseReport.toDate = value
        // shipWiseReport.toDate = moment(value).format(
        //   "yyyy-MM-DD" + " 23:59:59"
        // );
      }
    );
    watch(
      () => shipWiseReport.companyId,
      () => {
        if (shipWiseReport.companyId !== "") {
          getAllShips();
        }
      }
    );

    const getAllShips = () => {
      showLoader();
      store
        .dispatch("getShipListOwner")
        .then(() => hideLoader())
        .catch(() => hideLoader());
    };

    const getAllShipReport = () => {
      showLoader();

      store
        .dispatch("getAllShipReportOwner", shipWiseReport)
        .then((response) => {
          if(isDiscount.value == 1){
            discountStatus.value = true
          } else {
            discountStatus.value = false
          }
          if (response.length === 0) {
            summary.value = false;
          } else {
            summary.value = true;
            deckTicketTotal.value = 0;
            deckAmountTotal.value = 0;

            goodTicketTotal.value = 0;
            goodAmountTotal.value = 0;
            seatTicketTotal.value = 0;
            seatAmountTotal.value = 0;
            sumListData();
          }
          hideLoader();
        })
        .catch(() => hideLoader());
    };
    const filterReport = () => {
      filterActive.value = true;

      if(shipWiseReport.status == 1){
        tripCountColumn.value = true
      } else{
        tripCountColumn.value = false
      }
      getAllShipReport();
    };

    const sumListData = () => {
      store.getters.getShipWiseReportOwner.forEach((data) => {
        deckTicketTotal.value += data.deckTicket;
        deckAmountTotal.value += data.deckAmount;

        goodTicketTotal.value += data.goodsTicket;
        goodAmountTotal.value += data.goodsAmount;
        seatTicketTotal.value += data.seatTicket;
        seatAmountTotal.value += data.seatAmount;
      });
    };

    onBeforeMount(() => {
      shipWiseReport.companyId = store.getters.userCompany;
      shipWiseReport.fromDate = fromDate;
      shipWiseReport.toDate = toDate;
      // getAllShips()
    });

    const deckDetails = (shipId, shipName) => {
      shipNamePass.value = shipName;
      shipIdPass.value = shipId;
      deckDetailsActive.value = true;
      goodDetailsActive.value = false;
      seatDetailsActive.value = false;
      reportActive.value = false;
    };

    const goodDetails = (shipId, shipName) => {
      shipNamePass.value = shipName;
      shipIdPass.value = shipId;
      deckDetailsActive.value = false;
      goodDetailsActive.value = true;
      seatDetailsActive.value = false;
      reportActive.value = false;
    };

    const seatDetails = (shipId, shipName) => {
      shipNamePass.value = shipName;
      shipIdPass.value = shipId;
      deckDetailsActive.value = false;
      goodDetailsActive.value = false;
      seatDetailsActive.value = true;
      reportActive.value = false;
    };

    const onChildClick = (value) => {
      reportActive.value = value;
      deckDetailsActive.value = !value;
      goodDetailsActive.value = !value;
      seatDetailsActive.value = !value;
    };

    return {
      dropdownVariables,
      date,
      shipWiseReport,
      filterReport,
      deckDetails,
      goodDetails,
      seatDetails,
      deckDetailsActive,
      goodDetailsActive,
      seatDetailsActive,
      reportActive,
      shipIdPass,
      filterActive,
      onChildClick,
      shipNamePass,
      deckTicketTotal,
      deckAmountTotal,
      goodTicketTotal,
      goodAmountTotal,
      seatTicketTotal,
      seatAmountTotal,
      exportExcel,
      summary,
      options,
      isDiscount,
      discountStatus,
      tripCountColumn
    };
  },
};
</script>

<style scoped></style>
