<template>
  <Modal @close="closeModal">
    <template v-slot:title>Remove Floor</template>
    <template v-slot:body>
      Are you sure you want to remove this seat floor?
      <div class="form-group row my-1">
        <label class="col-sm-3 col-form-label"></label>
        <div class="col-sm-9">
          <input
            type="submit"
            class="mt-3 py-2 submit_button float-end font_detail"
            value="Remove"
            @click.prevent="removeFloor"
          />
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/layouts/Modals/Modal";
import { inject, ref } from "vue";
import store from "@/store";
import { reactive } from "@vue/reactivity";
import { onBeforeMount } from "vue";

export default {
  name: "SeatClassDeleteModal",
  components: { Modal },
  props: {
    ship: String,
    company: String,
    floor: String,
  },
  setup(props, { emit }) {
    let floorPayload = reactive({
      id: "",
      companyId: "",
      shipId: "",
    });
    let floorId = ref(props.floor);
    let companyId = ref(props.company);
    let shipId = ref(props.ship);
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");

    const closeModal = () => {
      emit("update:modelValue", false);
    };
    const removeFloor = () => {
      showLoader();
      store
        .dispatch("deleteFloorOwner", floorPayload)
        .then(() => {
          hideLoader();
          closeModal();
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            removeFloor();
          }
        });
    };

    onBeforeMount(() => {
      floorPayload.id = floorId.value;
      floorPayload.companyId = companyId.value;
      floorPayload.shipId = shipId.value;
    });

    return {
      floorPayload,
      removeFloor,
      closeModal,
    };
  },
};
</script>

<style scoped></style>
