import tokenCookie from "@/modules/tokenCookie";
import apiResponse from "@/modules/apiResponse";
const {hasValidToken, getToken} = tokenCookie();
const {errorResponse} = apiResponse();
import axios from "axios";
import Apis from "@/urls/apis";

const state = {
    trips: {},
};
const getters = {
    getCountermanTrips: (state) => state.trips.docs,
    getCountermanTotalTrips: (state) => state.trips.totalDocs,
};
const actions = {
    async getAllTripsForCounterman({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };

            return new Promise((resolve, reject) => {
                axios.get(Apis.COUNTER_ALL_TRIPS, {
                    params: payload,
                    headers: header
                })
                    .then(({data, status}) => {
                        if (status === 200) {
                            commit("setAllTripsForCounterman", data.trip)
                            resolve(data.trip)
                        }
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },
};
const mutations = {
    setAllTripsForCounterman: (state, allTrips) => (state.trips = allTrips),
};

export default {
    state,
    getters,
    actions,
    mutations
};