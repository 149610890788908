<template>
  <!--  <div class="card">-->
  <div>
    <div class="nav-style text-white fw-bold text-center py-2">{{companyName}}</div>
    <div v-if="companyDeployTabs">
      <ul
        class="nav nav-tabs justify-content-center nav-style"
        id="myTab"
        role="tablist"
      >
        <li
          v-for="(tab, index) in companyDeployTabs"
          :key="index"
          class="nav-item"
          role="presentation"
        >
          <button
            class="nav-link"
            :id="tab.name + '-tab'"
            data-bs-toggle="tab"
            v-bind:class="
              tab.name === activeTab
                ? 'active active-button'
                : 'inactive-button'
            "
            type="button"
            @click="setActive(tab.name)"
            role="tab"
            :aria-controls="tab.path"
            aria-selected="true"
          >
            {{ tab.title }}
          </button>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div
          v-for="(tab, index) in companyDeployTabs"
          :key="index"
          class="tab-pane fade"
          v-bind:class="tab.name === activeTab ? 'show active' : ''"
          :id="tab.path"
          role="tabpanel"
          :aria-labelledby="tab.name + '-tab'"
        ></div>
        <Owner v-if="activeTab === companyDeployTabs.OWNER_URL.name" />
        <Stoppage v-if="activeTab === companyDeployTabs.STOPPAGE_URL.name" />
        <Route v-if="activeTab === companyDeployTabs.ROUTE_URL.name" />
        <Counter v-if="activeTab === companyDeployTabs.COUNTER_URL.name" />
        <Ship v-if="activeTab === companyDeployTabs.SHIPS_URL.name" />
        <Trips v-if="activeTab === companyDeployTabs.TRIPS_URL.name" />
        <Agent v-if="activeTab === companyDeployTabs.AGENT_URL.name" />
        <CountermanSeperate
          v-if="activeTab === companyDeployTabs.COUNTERMAN_URL.name"
        />
        <Supervisor
          v-if="activeTab === companyDeployTabs.SUPERVISOR_URL.name"
        />
        <Deckman v-if="activeTab === companyDeployTabs.DECKMAN_URL.name" />
        <Cabinman v-if="activeTab === companyDeployTabs.CABINMAN_URL.name" />
      </div>
    </div>
  </div>
</template>

<script>
import web from "@/urls/web";
import Stoppage from "./Children/Stoppage/Stoppage";
import Owner from "./Children/Owner/Owner";
import Route from "./Children/Routes/Routes";
import { onBeforeMount, onBeforeUnmount, ref } from "vue";
import {useRoute} from 'vue-router'
import store from "@/store";
import Counter from "./Children/Counter/Counter";
import Ship from "./Children/Ships/Ships";
import Trips from "./Children/Trips/Trips";
import Agent from "@/views/Admin/CompanyDeploy/Children/Agent/Agent";
import CountermanSeperate from "./Children/counterman/CountermanSeperate.vue";
import Supervisor from "./Children/Supervisor/Supervisor.vue";
import Deckman from "@/views/Admin/CompanyDeploy/Children/Deckman/Deckman";
import Cabinman from "@/views/Admin/CompanyDeploy/Children/Cabinman/Cabinman";

export default {
  name: "CompanyManage",
  components: {
    Route,
    Owner,
    Stoppage,
    Counter,
    Ship,
    Trips,
    Agent,
    CountermanSeperate,
    Supervisor,
    Deckman,
    Cabinman,
  },
  setup() {
    const route = useRoute();
    const companyName = ref("");
    let activeTab = ref("");
    const companyDeployTabs =
      web.Admin.COMPANY_DEPLOY_URL.children.MANAGE_URL.children;

    const setActive = (index) => {
      activeTab.value = index;
      store.commit("setActiveTab", activeTab.value);
    };

    onBeforeMount(() => {
      companyName.value = store.getters.getCompanyName
      activeTab.value = store.getters.getActiveTab
        ? store.getters.getActiveTab
        : companyDeployTabs.OWNER_URL.name;
    });
    onBeforeUnmount(() => {
      if (store.getters.isLoggedIn) {
        store.commit("setActiveTab", "");
      }
    });

    return {
      companyDeployTabs,
      activeTab,
      setActive,
      companyName,
      route
    };
  },
};
</script>

<style scoped>
/*.card {*/
/*  padding: 20px 30px;*/
/*}*/

.nav-style {
  background: #3b3b3b;
  border-top: 1px;
}

.active-button {
  background: #242424 !important;
  border: 1px solid #242424 !important;
  color: #fff !important;
}

.inactive-button {
  color: #fff !important;
}
</style>