<template>
  <div class="coming-soon">
    <h2>Coming Soon</h2>
    <h6>This page is under development</h6>
  </div>
</template>

<script>
export default {
  name: "Profile"
}
</script>

<style scoped>
.coming-soon {
  padding:50px 100px;
  background-color: #FF6347;
}

</style>