<template>
  <div>
    <FilterTab>
      <template v-slot:title><h6>Counter Man</h6></template>
      <template v-slot:body>
        <div class="col-sm-6 col-md-2">
          <DropdownFilter
            v-bind:id="'counter'"
            v-bind:label="'Counter'"
            v-bind:defaultOption="'--select counter--'"
            v-model="pagination.counterId"
            v-bind:defaultValue="''"
            :dropdown="$store.getters.getCounterListForFilterOwner"
          />
        </div>
        <div class="col-sm-6 col-md-2">
          <FilterInput
            v-model="pagination.nameOrPhone"
            :placeholder="'Name/Phone'"
            :label="'Name/Phone'"
            :id="'nameOrphone'"
            :type="'text'"
          />
        </div>
      </template>
      <template v-slot:button2>
        <button
          v-if="hasPermission(Owner.ownerCompanySetupWrite)"
          class="edit_table_data font_detail"
          @click="addModal"
        >
          <i class="fas fa-plus"></i><span class="mx-1 bold">Add New</span>
        </button>
      </template>
      <template v-slot:button1>
        <button class="edit_table_data font_detail" @click="getAllCounterMans">
          <i class="fas fa-search"></i><span class="mx-1 bold">Search</span>
        </button>
      </template>
    </FilterTab>
    <Table v-if="$store.getters.getCounterMansOwner">
      <template v-slot:header>
        <tr>
          <td class="font_detail">SL</td>
          <td class="font_detail">NAME</td>
          <td class="font_detail">SHIPS</td>

          <td class="font_detail">COUNTER NAME</td>
          <td class="font_detail">MOBILE</td>
          <td class="font_detail">USER TYPE</td>
          <td class="font_detail">STATUS</td>
          <td
            v-if="hasPermission(Owner.ownerCompanySetupWrite)"
            class="font_detail"
          >
            ACTION
          </td>
        </tr>
      </template>
      <template v-slot:body>
        <tr
          v-for="(counterMan, index) in $store.getters.getCounterMansOwner"
          :key="counterMan._id"
        >
          <td class="font_detail">{{ pagination.limit*(pagination.page-1) +index +1 }}</td>
          <td class="font_detail">{{ counterMan.name }}</td>
          <td class="font_detail">
            <span v-for="ship in counterMan.shipId" :key="ship._id"
              ><span class="badge rounded-pill bg-primary m-1 p-2">{{
                ship.name
              }}</span></span
            >
          </td>

          <td class="font_detail">{{ counterMan.counterId?.name }}</td>
          <td class="font_detail">{{ counterMan.phone }}</td>
          <td class="font_detail">
            {{ dropdownVariables.userType[counterMan.userType] }}
          </td>
          <td>
            <span v-if="counterMan.status" class="badge bg-success">{{
              dropdownVariables.status[1]
            }}</span>
            <span v-else class="badge bg-danger">{{
              dropdownVariables.status[0]
            }}</span>
          </td>
          <td v-if="hasPermission(Owner.ownerCompanySetupWrite)">
            <button
              class="edit_table_data font_detail edit_table_button"
              @click="updateModal(counterMan._id)"
            >
              <i class="fas fa-edit"></i><span class="mx-1">Edit</span>
            </button>
          </td>
        </tr>
      </template>
      <template v-slot:pagination>
        <pagination
          v-model="pagination.page"
          :records="$store.getters.getTotalCounterManOwner"
          :per-page="pagination.limit"
          @paginate="getAllCounterMans"
          :options="options"
        />
      </template>
    </Table>
    <div v-if="showModal">
      <CountermanAddUpdateModal
        v-model="showModal"
        @getAllCounterMans="getAllCounterMans"
        v-bind:company="companyId"
        v-bind:counterMan="counterManId"
      />
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { onBeforeMount, reactive, ref, inject, watch } from "vue";
import permission from "@/modules/permission";
import roles from "@/modules/roles";
import dropdownVariables from "@/modules/dropdownVariables";
import CountermanAddUpdateModal from "./CountermanAddUpdateModalSeperate.vue";
import FilterInput from "@/components/layouts/Filter/Inputs/Input.vue";

const { hasPermission } = permission();
const { Owner } = roles();
export default {
  name: "CountermanSeperate",
  components: {
    CountermanAddUpdateModal, 
    FilterInput
  },
  setup() {
    const companyId = ref("");
    const counterManId = ref("");
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const showModal = ref(false);

    const pagination = reactive({
      companyId,
      counterId: "",
      nameOrPhone: "",
      page: 1,
      limit: 10,
    });
    const options = {
      texts: {
        count: "",
      },
    };

    const getAllCounterMans = () => {
      showLoader();
      store
        .dispatch("getCounterMansByPageOwner", pagination)
        .then(() => {
          getAllShips();
        })
        .catch((error) => {
          if (error.status === 401) {
            // getAllCounterMans();
          }
        });
    };
    const getAllShips = () => {
      store
        .dispatch("getOwnerServicesShipListInFilter", {
          companyId: store.getters.userCompany,
        })
        .then(() => {
          hideLoader();
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            getAllShips();
          }
        });
    };
    const getCounterList = () => {
      store.dispatch("getCounterListOwner", {
        companyId: store.getters.userCompany,
      });
    };

    const updateModal = (value) => {
      counterManId.value = value;
      showModal.value = true;
    };
    const addModal = () => {
      counterManId.value = "";
      showModal.value = true;
    };

    watch(
      () => pagination.counterId,
      () => {
        getAllCounterMans();
      }
    );

    onBeforeMount(() => {
      companyId.value = store.getters.userCompany;
      getAllCounterMans();
      getCounterList();
    });
    return {
      companyId,
      counterManId,
      pagination,
      getAllCounterMans,
      updateModal,
      hasPermission,
      Owner,
      options,
      dropdownVariables,
      showModal,
      addModal,
      getCounterList,
    };
  },
};
</script>

<style scoped>
.bold {
  font-weight: bold;
}
</style>
