<template>
  <div>
    <FilterTab>
      <template v-slot:body>
        <div class="col-sm-6 col-md-2">
          <DropdownFilter
            :id="'company'"
            :label="'Company'"
            :defaultOption="'--select company--'"
            v-model="printCountReport.companyId"
            :defaultValue="''"
            :dropdown="$store.getters.getCompanyList"
          />
        </div>
        <div class="col-sm-6 col-md-2" v-if="printCountReport.companyId">
          <DropdownFilter
            :id="'reporttype'"
            :label="'Report Type'"
            :defaultOption="'--select report type--'"
            v-model="reportingType"
            :defaultValue="''"
            :dropdown="dropdownVariables.reportType"
          />
        </div>
        <div
          v-if="showDatePicker === 'dateTimeRange'"
          class="col-sm-6 col-md-4"
        >
          <DateTimeRangePicker v-model="date.range" />
        </div>
        <div
          v-if="showDatePicker === 'dateRange'"
          class="col-sm-6 col-md-2 text-white"
        >
          <DatePickerFilter
            v-model="printCountReport.summaryDate"
            :id="'countdate'"
            :label="'Report Count Date'"
          >
          </DatePickerFilter>
        </div>
      </template>
      <template v-slot:button2>
        <button class="edit_table_data font_detail" @click="filterCountReport">
          <i class="fas fa-filter"></i><span class="mx-1 bold">Filter</span>
        </button>
      </template>
      <template v-slot:button1>
        <button
          class="edit_table_data font_detail"
          @click="exportExcel('xlsx')"
        >
          <i class="fas fa-download"></i><span class="mx-1 bold">Export</span>
        </button>
      </template>
    </FilterTab>

    <TicketPrintCountDetails
      v-if="printCountReport.reportType === 'ticket' && loading"
    />
    <TripWisePrintCountReportDetails
      v-if="printCountReport.reportType === 'tripWiseReport' && loading"
    />
    <DailySummaryReportDetails
      v-if="printCountReport.reportType === 'dailySummaryReport' && loading"
    />
    <SupervisorTripWiseReport
      v-if="printCountReport.reportType === 'supervisorTripWiseReport' && loading"
    />
  </div>

  <router-view></router-view>
</template>

<script>
import DateTimeRangePicker from "@/components/layouts/Filter/Inputs/DateTimeRangePicker";
import DropdownFilter from "@/components/layouts/Filter/Inputs/Dropdown";
import TicketPrintCountDetails from "./TicketPrintCountDetails.vue";
import TripWisePrintCountReportDetails from "./TripWisePrintCountReportDetails.vue";
import SupervisorTripWiseReport from "./SupervisorTripWiseReport.vue";
import DailySummaryReportDetails from "./DailySummaryReportDetails.vue";
import dropdownVariables from "@/modules/dropdownVariables";
import { reactive } from "@vue/reactivity";
import moment from "moment";
import { computed, onBeforeMount, watch, ref, inject } from "vue";
import store from "@/store";
import XLSX from "xlsx";

export default {
  name: "PrintCountReport",
  components: {
    DateTimeRangePicker,
    DropdownFilter,
    TicketPrintCountDetails,
    TripWisePrintCountReportDetails,
    DailySummaryReportDetails,
    SupervisorTripWiseReport
  },

  setup() {
    const fromDate = moment(new Date()).format("yyyy-MM-DD" + " 00:00:00");
    const toDate = moment(new Date()).format("yyyy-MM-DD" + " 23:59:59");
    const today = moment(new Date()).format("yyyy-MM-DD");
    const reportingType = ref("");
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const loading = ref(false);

    let date = reactive({
      range: {
        start: fromDate,
        end: toDate,
      },
    });

    let printCountReport = reactive({
      companyId: "",
      reportType: "",
      fromDate: "",
      toDate: "",
      summaryDate: "",
      page: 1,
      limit: 10,
    });

    const showDatePicker = computed(() => {
      if (
        (reportingType.value === "ticket" ||
          reportingType.value === "tripWiseReport" ||
          reportingType.value === "supervisorTripWiseReport") &&
          printCountReport.companyId
      ) {
        return "dateTimeRange";
      } else if (
        reportingType.value === "dailySummaryReport" &&
        printCountReport.companyId
      ) {
        return "dateRange";
      }
      return "nothing";
    });

    watch(
      () => date.range.start,
      (date) => {
        printCountReport.fromDate = date;
      }
    );
    watch(
      () => date.range.end,
      (date) => {
        printCountReport.toDate = date;
      }
    );

    const filterCountReport = () => {
      showLoader();
      loading.value = false;
      printCountReport.reportType = reportingType.value;
      if (reportingType.value) {
        store
          .dispatch("getPrintCountList", printCountReport)
          .then(() => {
            loading.value = true;
            hideLoader();
          })
          .catch(() => {
            hideLoader();
          });
      } else {
        hideLoader();
      }
    };

    onBeforeMount(() => {
      printCountReport.fromDate = fromDate;
      printCountReport.toDate = toDate;
      printCountReport.summaryDate = today;
    });

    const exportExcel = (type, fn, dl) => {
      if (document.getElementById("exportable_data") !== null) {
        var elt = document.getElementById("exportable_data");
        var wb = XLSX.utils.table_to_book(elt, { sheet: "sheet Js" });
        return dl
          ? XLSX.write(wb, { bookType: type, bookSST: true, type: "base64" })
          : XLSX.writeFile(
              wb,
              fn || "Owner_CounterWise_Report." + (type || "xlsx")
            );
      }
    };

    return {
      dropdownVariables,
      date,
      printCountReport,
      filterCountReport,
      exportExcel,
      showDatePicker,
      reportingType,
      loading,
    };
  },
};
</script>

<style scoped>
</style>