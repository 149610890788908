export default {
  boolean: {
    1: "Yes",
    0: "No",
  },
  direction: {
    UP: "Up",
    DOWN: "Down",
  },
  userType: {
    admin: "Admin",
    owner: "Owner",
    counterman: "Counterman",
    agent: "Agent",
    supervisor: "Supervisor",
  },
  tripStatus: {
    ACTIVE: { value: "Active", badge: "success" },
    CANCELLED: { value: "Cancelled", badge: "danger" },
    COMPLETE: { value: "Completed", badge: "primary" },
  },
  status: {
    1: "Active",
    0: "Inactive",
  },
  reservationReportStatus:{
    pending: "RESERVED",
    confirmed: "CONFIRMED",
    canceled: "CANCELLED"
  },

  counterReportStatus:{
    canceled : "CANCELLED",
    reserved: "RESERVED",
    confirmed: "CONFIRMED"
  },
  reservation: {
    1: "Active",
    0: "Inactive",
  },
  reportStatus: {
    1: "Confirmed",
    0: "Cancelled",
  },
  printingType: {
    Individual: "Individual",
    Group: "Group",
  },
  reportByIndividualSeat: {
    1: "Yes",
    0: "No",
  },
  gender: {
    Male: "Male",
    Female: "Female",
  },
  fareType: {
    required: "Standard",
    coach_based: "Coach Based",
    hand_input: "Instant Fare",
  },
  printerType: {
    laser_printer: "Laser Printer",
    desktop_pos: "Desktop POS",
    android_pos: "Android POS",
    bluetooth_pos: "Bluetooth POS",
  },
  countermanPrinterType: {
    T2mini: "T2mini",
    Bluetooth: "Bluetooth",
    USB: "USB",
  },
  adminTicketCancelReason: {
    "প্রিন্টার সমস্যা": "প্রিন্টার সমস্যা",
    "আসন পরিবর্তন": "আসন পরিবর্তন",
    "ক্লাস পরিবর্তন": "ক্লাস পরিবর্তন",
    "টিকেট ফেরত": "টিকেট ফেরত",
    "Mistakenly sold by counterman": "Mistakenly sold by counterman",
    অন্যান্য: "অন্যান্য",
  },
  userTicketCancelReason: {
    "Trip Cancel": "Trip Cancel",
    "Operator owner request": "Operator owner request",
    "Others( write options for admin and owner)": "Others( write options for admin and owner)"
  },
  ticketCancelReason: {
    "প্রিন্টার সমস্যা": "প্রিন্টার সমস্যা",
    "আসন পরিবর্তন": "আসন পরিবর্তন",
    "ক্লাস পরিবর্তন": "ক্লাস পরিবর্তন",
    "টিকেট ফেরত": "টিকেট ফেরত",
    অন্যান্য: "অন্যান্য",
  },
  discount: {
    0: "No Discount",
    1: "With Discount",
  },
  reportType: {
    ticket: "Ticket",
    tripWiseReport: "Trip Wise Report",
    dailySummaryReport: "Daily Summary Report",
    supervisorTripWiseReport: "Supervisor Trip Report",
  },
  comissionBase: {
    PENDING: "Pending",
    NO_OF_TICKETS_PERCENTAGE: "No of tickets percentage",
    NO_OF_TICKETS_AMOUNT: "No of tickets amount",
    GMV_PERCENTAGE: "GMV percentage",
    DAILY_FIXED_AMOUNT: "Daily fixed amount",
  },
  
  platformOptions: {
    "Brothers Navigation": "Brothers Navigation",
    "Counterman": "Counterman",
    "Jatri": "Jatri"
  },
};

// 'ACTIVE': {value:"Active",badge:"success"},
// 'CANCELLED': {value:"Cancelled",badge:"success"},
// 'COMPLETE': {value:"Completed",badge:"success"},
