<template>
  <div>
    <FilterTab>
      <template v-slot:title><h6>Stoppage</h6></template>
      <template v-slot:body>
        <div class="col-sm-6 col-md-2">
        </div>
      </template>
      <template v-if="hasPermission(Admin.companyDeployWrite)" v-slot:button1>
        <button class="edit_table_data font_detail" @click="addModal"><i
            class="fas fa-plus"></i><span class="mx-1 bold">Add New</span>
        </button>
      </template>
    </FilterTab>
    <div v-if="addUpdate">
      <!--      <StoppageAddUpdate v-bind:stoppageId="stoppageId" @close="close" @getAllStoppages="getAllStoppages"/>-->
      <StoppageAddUpdateModal v-bind:stoppage="stoppageId" v-bind:company="pagination.companyId" @close="close"
                              @getAllStoppages="getAllStoppages"/>
    </div>
    <div>
      <Table v-if="$store.getters.getStoppages">
        <template v-slot:header>
          <tr>
            <td class="font_detail"> SL</td>
            <td class="font_detail"> NAME</td>
            <td class="font_detail">CITY</td>
            <td class="font_detail"> STATUS</td>
            <td v-if="hasPermission(Admin.companyDeployWrite)" class="font_detail"> ACTION</td>
          </tr>
        </template>
        <template v-slot:body>
          <tr v-for="(stoppage,index) in $store.getters.getStoppages" :key="stoppage._id">
            <td class="font_detail">{{ pagination.limit*(pagination.page-1) +index +1 }}</td>
            <td class="font_detail">{{ stoppage.name }}</td>
            <td class="font_detail">{{ $store.getters.getDigitalTicketingAllStoppages[stoppage.cityId] }}</td>
            <td><span v-if="stoppage.status" class="badge bg-success">{{
                dropdownVariables.status[1]
              }}</span>
              <span v-else class="badge bg-danger">{{ dropdownVariables.status[0] }}</span>
            </td>
            <td v-if="hasPermission(Admin.companyDeployWrite)">
              <button class="edit_table_data font_detail edit_table_button"
                      @click="updateModal(stoppage._id)"><i
                  class="fas fa-edit"></i><span class="mx-1">Edit</span></button>
            </td>
          </tr>
        </template>
        <template v-slot:pagination>
          <pagination v-model="pagination.page" :records="$store.getters.getTotalStoppages"
                      :per-page="pagination.limit"
                      @paginate="getAllStoppages" :options="options"/>
        </template>
      </Table>
    </div>
  </div>
</template>

<script>
import {reactive} from "@vue/reactivity";
import {inject, onBeforeMount, ref} from "vue";
import store from "@/store";
import dropdownVariables from "@/modules/dropdownVariables";
import {useRoute} from "vue-router";
import StoppageAddUpdateModal from "./StoppageAddUpdateModal";
import permission from "@/modules/permission";
import roles from "@/modules/roles";

const {hasPermission} = permission();
const {Admin} = roles();

export default {
  name: "Stoppage",
  components: {StoppageAddUpdateModal},
  setup() {
    let pagination = reactive({
      page: 1,
      limit: 10,
      companyId: ""
    });
    const addUpdate = ref(false);
    const stoppageId = ref("");
    const showLoader = inject('showLoader');
    const hideLoader = inject('hideLoader');
    const options = {
      texts: {
        count: ""
      }
    };
    const route = useRoute();

    const getAllStoppages = () => {
      showLoader();
      store.dispatch('getStoppagesByPage', pagination).then(() => {
        hideLoader();
      }).catch((error) => {
        hideLoader();
        if (error.status === 401) {
          getAllStoppages();
        }
      });
    };
    const close = () => {
      addUpdate.value = false;
    };
    const addModal = () => {
      stoppageId.value = '';
      addUpdate.value = true;
    };
    const updateModal = (id) => {
      stoppageId.value = id;
      addUpdate.value = true;
    };

    const getDigitalTicketingAllStoppages = () => {
      store
          .dispatch("getDigitalTicketingAllStoppages")
    }

    onBeforeMount(() => {
      getDigitalTicketingAllStoppages();
      if (route.query.id) {
        pagination.companyId = route.query.id;
        getAllStoppages();
      }
    });

    return {
      options, pagination, dropdownVariables, addUpdate, stoppageId, Admin,
      hasPermission,
      getAllStoppages, addModal, updateModal, close
    };
  }
};
</script>

<style scoped>
.bold {
  font-weight: bold;
}

</style>