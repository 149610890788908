<template>
  <SupervisorNav />
  <router-view></router-view>
</template>

<script>
import SupervisorNav from "@/components/layouts/Nav/SupervisorNav.vue";
export default {
  name: "Supervisor",
  components: { SupervisorNav },
};
</script>

<style scoped>
</style>