import apiResponse from "@/modules/apiResponse";
import tokenCookie from "@/modules/tokenCookie";
import Apis from "@/urls/apis";
import axios from "axios";
const {hasValidToken, getToken} = tokenCookie();
const {successResponse, errorResponse} = apiResponse();

const state = {
    filter: {
        stoppage: {},
    },
    stoppages: {},
    stoppage: {},

    allStoppage: [],


};
const getters = {



    getDigitalTicketingAllStoppages: (state) => state.filter.stoppage,
    getDigitalTicketingStoppages: (state) => state.stoppages.docs,
    getDigitalTicketingTotalStoppages: (state) => state.stoppages.totalDocs,
    getDigitalTicketingStoppage: (state) => state.stoppage,
    getDigitalTicketingStoppageById: (state) => (id) =>
        state.stoppages.docs.filter(function (stoppage) {
            return stoppage._id === id;
        }),

};
const actions = {



    async getDigitalTicketingAllStoppages({commit}, payload) {
        if (await hasValidToken()) {
            commit("setAllStoppagesInFilter", {});
            commit("setAllDtStoppages", []);
            const header = {
                Authorization: "Bearer " + getToken(),
            };

            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_DIGITAL_TICKETING_ALL_STOPPAGE, {
                        params: payload,
                        headers: header,
                    })
                    .then(({data, status}) => {
                        if (status === 200) {
                            let stoppageList = {};
                            data.city.forEach((stoppage) => {
                                stoppageList[stoppage._id] = stoppage.name;
                            });
                            commit("setAllStoppagesInFilter", stoppageList);
                            commit("setAllDtStoppages", data.city);
                            resolve(data.city);
                        }
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },

    async getDigitalTicketingStoppagesByPage({commit}, payload) {
        if (await hasValidToken()) {
            commit("setStoppages", []);
            const header = {
                Authorization: "Bearer " + getToken(),
            };

            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_DIGITAL_TICKETING_STOPPAGES, {
                        params: payload,
                        headers: header,
                    })
                    .then(({data, status}) => {
                        if (status === 200) {
                            commit("setStoppages", data.city);
                            resolve({success: "success"});
                        }
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },

    async getDigitalTicketingStoppage({commit}, payload) {
        if (await hasValidToken()) {
            commit("setStoppages", []);
            const header = {
                Authorization: "Bearer " + getToken(),
            };

            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_DIGITAL_TICKETING_STOPPAGE, {
                        params: payload,
                        headers: header,
                    })
                    .then(({data, status}) => {
                        if (status === 200) {
                            commit("setStoppage", data.dtStoppage);
                            resolve({success: "success"});
                        }
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },

    async addDigitalTicketingStoppage(_, {stoppageData, pagination}) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };

            return new Promise((resolve, reject) => {
                axios
                    .post(Apis.ADD_DIGITAL_TICKETING_STOPPAGE, stoppageData, {
                        headers: header,
                        params: pagination,
                    })
                    .then((success) => {
                        // commit("setStoppages", success.data.dtStoppages);
                        resolve(successResponse(success));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },

    async updateDigitalTicketingStoppage(_, {stoppageData, pagination}) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };

            return new Promise((resolve, reject) => {
                axios
                    .put(Apis.UPDATE_DIGITAL_TICKETING_STOPPAGE, stoppageData, {
                        headers: header,
                        params: pagination,
                    })
                    .then((success) => {
                        // commit("setStoppageById", success.data.dtStoppages);
                        resolve(successResponse(success));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },


};
const mutations = {

    setAllDtStoppages: (state, allStoppage) => (state.allStoppage = allStoppage),
    setAllStoppagesInFilter: (state, stoppageList) =>
        (state.filter.stoppage = stoppageList),
    setStoppages: (state, stoppages) => (state.stoppages = stoppages),
    setStoppage: (state, stoppage) => (state.stoppage = stoppage),
    setStoppageById: (state, stoppage) =>
        state.stoppages.docs.filter((element, index) => {
            if (element._id === stoppage._id) {
                state.stoppages.docs.splice(index, 1, stoppage);
            }
        }),

};

export default {
    state,
    getters,
    actions,
    mutations,
};
