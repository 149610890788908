import { reactive } from "@vue/reactivity";
import useValidators from "./validators";

const errors = reactive({});
const {
  isEmpty,
  minLength,
  isEmail,
  isValidNumber,
  isValidNumberWithZero,
  isPhone,
  hasRole,
} = useValidators();
export default function useInputValidation() {
  const requiredValidation = (fieldName, fieldValue) => {
    errors[fieldName] =
      !fieldValue || Object.keys(fieldValue).length === 0
        ? isEmpty(fieldName, fieldValue)
        : "";
  };

  const requiredValidationNumber = (fieldName, fieldValue) => {
    errors[fieldName] =
      fieldValue !== ""
        ? isValidNumberWithZero(fieldName, fieldValue)
        : isEmpty(fieldName, fieldValue);
  };
  const validNumberValidation = (fieldName, fieldValue) => {
    errors[fieldName] =
      fieldValue !== ""
        ? isValidNumber(fieldName, fieldValue)
        : isEmpty(fieldName, fieldValue);
  };
  const passwordValidation = (fieldName, fieldValue) => {
    errors[fieldName] = !fieldValue
      ? isEmpty(fieldName, fieldValue)
      : minLength(fieldName, fieldValue, 8);
  };
  const emailValidation = (fieldName, fieldValue) => {
    errors[fieldName] = !fieldValue
      ? isEmpty(fieldName, fieldValue)
      : isEmail(fieldName, fieldValue);
  };
  const phoneValidation = (fieldName, fieldValue) => {
    errors[fieldName] = !fieldValue
      ? isEmpty(fieldName, fieldValue)
      : isPhone(fieldName, fieldValue);
  };
  const validPhoneValidation = (fieldName, fieldValue) => {
    errors[fieldName] = fieldValue ? isPhone(fieldName, fieldValue) : "";
  };
  const requiredRoleValidation = (
    fieldName,
    chosenRoles,
    requiredRole,
    role,
    roleObject
  ) => {
    errors[fieldName] =
      chosenRoles.includes(role) && !chosenRoles.includes(requiredRole)
        ? hasRole(requiredRole, role, roleObject)
        : "";
  };
  return {
    errors,
    requiredValidation,
    passwordValidation,
    emailValidation,
    requiredValidationNumber,
    validNumberValidation,
    phoneValidation,
    validPhoneValidation,
    requiredRoleValidation,
  };
}
