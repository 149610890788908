<template>
  <label :for="inputId" class="col-sm-3 col-form-label font_detail ">{{ inputLabel }} :</label>
  <div v-if="inputType == 'number'" class="col-sm-9">
    <input 
      :id="inputId" 
      v-model="input" 
      :type="inputType" 
      :placeholder="inputPlaceholder" 
      min="1" 
      step="1"
      :max="inputMax"
      :maxlength="inputMaxlength"
      :pattern="inputPattern"
      onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.keyCode == 8 || event.keyCode == 46'
      oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"
      autocomplete="off"
      @click.right.prevent 
      @keydown="keydown" 
      @copy.prevent 
      @paste.prevent
      :disabled="inputDisabled"
      class="form-control font_detail">
    <slot name="error"></slot>
  </div>
  <div v-else-if="inputType == 'phoneNumber'" class="col-sm-9">
    <input 
      :id="inputId" 
      v-model="input" 
      type="text" 
      :placeholder="inputPlaceholder" 
      min="1" 
      step="1"
      maxlength="14"
      pattern="\d{14}"
      onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.keyCode == 8 || event.keyCode == 46'
      autocomplete="off"
      @click.right.prevent 
      @keydown="keydown" 
      @copy.prevent 
      @paste.prevent
      :disabled="inputDisabled"
      class="form-control font_detail">
    <slot name="error"></slot>
  </div>
  <div v-else-if="inputType == '4digitNumber'" class="col-sm-9">
    <input 
      :id="inputId" 
      v-model="input" 
      type="text" 
      :placeholder="inputPlaceholder" 
      min="1" 
      step="1"
      maxlength="4"
      pattern="\d{4}"
      onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.keyCode == 8 || event.keyCode == 46'
      oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"
      autocomplete="off"
      @click.right.prevent 
      @keydown="keydown" 
      @copy.prevent 
      @paste.prevent
      :disabled="inputDisabled"
      class="form-control font_detail">
    <slot name="error"></slot>
  </div>
  <div v-else class="col-sm-9">
    <input 
      :id="inputId" 
      v-model="input" 
      :type="inputType" 
      :placeholder="inputPlaceholder"
      min="1" 
      step="1"
      autocomplete="on"
      :disabled="inputDisabled"
      class="form-control font_detail">
    <slot name="error"></slot>
  </div>
</template>

<script>
import {ref, watch} from "vue";

export default {
  name: "Input",
  props: {
    modelValue: [String, Number],
    placeholder: String,
    label: String,
    id: String,
    type: String,
    max: String,
    maxlength: String,
    pattern: String,
    isDisabled: Boolean
  },
  setup(props, {emit}) {
    const inputPlaceholder = ref(props.placeholder);
    const inputLabel = ref(props.label);
    const inputId = ref(props.id);
    const inputType = ref(props.type);
    const input = ref(props.modelValue);
    const inputMax = ref(props.max);
    const inputMaxlength = ref(props.maxlength);
    const inputPattern = ref(props.pattern);
    const inputDisabled = ref(props.isDisabled);
    watch(() => input.value, () => {
      emit("update:modelValue", input.value);
    });
    watch(() => props.modelValue, (value) => {
      input.value = value;
    });
    return {
      inputPlaceholder, inputLabel, inputId, inputType, inputMax, inputMaxlength, inputPattern, input, inputDisabled
    };
  }
};
</script>

<style scoped>

</style>