import apiResponse from "@/modules/apiResponse";
import toast from "@/modules/Toast";
import tokenCookie from "@/modules/tokenCookie";
import validationMessage from "@/modules/ValidationMessage";
import Apis from "@/urls/apis";
import axios from "axios";

const { successToast, errorToast } = toast();
const { hasValidToken, getToken } = tokenCookie();
const { errorResponse, successResponse } = apiResponse();
const { messageExtractor } = validationMessage();

const state = {
  filter: {
    agentsListOwner: {},
    stoppageOwner: {},
    routeOwner: {},
    route: {},
    ship: {},
  },
  deckMansOwner: [],
  countersOwner: [],
  counterMansOwner: [],
  supervisorsOwner: {},

  shipsOwner: [],

  ownerServicesStopage: [],
  ownerServicesRoute: [],
  ownerServicesBoardingPlaces: [],
  ownerServicesDroppingPlaces: [],
  localRoutes: [],
  places: [],
  ownerServicesTrips: [],

  cabinMans: [],
  cabinSeatList: [],
  permittedCabinSeatList: [],
  permittedCabinSeatListByOtherCabinMan:[],
  blockedSeatList:[]
};
const getters = {
  getShipListForFilterOwnerServices: (state) => state.filter.ship,
  getShipsOwner: (state) => state.shipsOwner.docs,
  getTotalShipOwner: (state) => state.shipsOwner.totalDocs,
  getShipOwner: (state) => (id) =>
    state.shipsOwner.docs.filter(function (ship) {
      return ship._id === id;
    }),
  getRouteListForFilterOwner: (state) => state.filter.routeOwner,

  getSeatPlanOwner: (state) => (id, shipId) => {
    return state.shipsOwner.docs
      .find((ship) => ship._id === shipId)
      .seatPlan.find((plan) => plan._id === id);
  },

  getCounterMansOwner: (state) => state.counterMansOwner.docs,
  getTotalCounterManOwner: (state) => state.counterMansOwner.totalDocs,
  getCounterManOwner: (state) => (id) =>
    state.counterMansOwner.docs.filter(function (counterMan) {
      return counterMan._id === id;
    }),

  getSupervisorsOwner: (state) => state.supervisorsOwner.docs,
  getTotalSupervisorOwner: (state) => state.supervisorsOwner.totalDocs,
  getSupervisorOwner: (state) => (id) =>
    state.supervisorsOwner.docs.filter(function (supervisor) {
      return supervisor._id === id;
    }),

  getStoppagesOwner: (state) => state.filter.stoppageOwner,

  getCountersOwner: (state) => state.countersOwner.docs,
  getTotalCounterOwner: (state) => state.countersOwner.totalDocs,
  getCounterOwner: (state) => (id) =>
    state.countersOwner.docs.filter(function (counter) {
      return counter._id === id;
    }),

  getAllAgentListOwner: (state) => state.filter.agentsListOwner.docs,
  getTotalAllAgentListOwner: (state) => state.filter.agentsListOwner.totalDocs,
  getAgentOwner: (state) => (id) =>
    state.filter.agentsListOwner.docs.filter(function (agent) {
      return agent._id === id;
    }),

  getDeckMansOwner: (state) => state.deckMansOwner.docs,
  getTotalDeckManOwner: (state) => state.deckMansOwner.totalDocs,
  getDeckManOwner: (state) => (id) =>
    state.deckMansOwner.docs.filter(function (deckMan) {
      return deckMan._id === id;
    }),

  getOwnerServicesStopageList: (state) => state.ownerServicesStopage.docs,
  getTotalStopageRecords: (state) => state.ownerServicesStopage.totalDocs,
  getOwnerServicesStopage: (state) => (id) =>
    state.ownerServicesStopage.docs.filter(function (stopage) {
      return stopage._id === id;
    }),

  getOwnerServicesRouteList: (state) => state.ownerServicesRoute.docs,
  getTotalRouteRecords: (state) => state.ownerServicesRoute.totalDocs,
  getOwnerServicesRoute: (state) => (id) =>
    state.ownerServicesRoute.docs.filter(function (route) {
      return route._id === id;
    }),
  getServicesRouteListForFilter: (state) => state.filter.route,

  getOwnerServicesBoardingPlaces: (state) => state.ownerServicesBoardingPlaces,
  getOwnerServicesDroppingPlaces: (state) => state.ownerServicesDroppingPlaces,
  getOwnerServiceLocalRoutes: (state) => state.localRoutes,
  getOwnerServicePlaces: (state) => state.places,
  getOwnerServicesTrips: (state) => state.ownerServicesTrips.docs,
  getTotalOwnerServicesTrips: (state) => state.ownerServicesTrips.totalDocs,
  getOwnerServicesTrip: (state) => (id) =>
    state.ownerServicesTrips.docs.filter(function (trip) {
      return trip._id === id;
    }),
  getOwnerSertvicesFloorPlan: (state) => (id, shipId) => {
    return state.shipsOwner.docs
      .find((ship) => ship._id === shipId)
      .floors.find((plan) => plan._id === id);
  },
  getOwnerServicesShipListForFilter: (state) => state.filter.ship,

  getOwnerServicesCabinMans: (state) => state.cabinMans.docs,
  getOwnerServicesTotalCabinMan: (state) => state.cabinMans.totalDocs,
  getOwnerServicesCabinMan: (state) => (id) =>
    state.cabinMans.docs.filter(function (cabinMan) {
      return cabinMan._id === id;
    }),

  getOwnerServicesCabinSeatList: (state) => state.cabinSeatList,
  getOwnerServicesCabinSeatListBySeatClassId: (state) => (id) =>
    state.cabinSeatList.filter(function (cabinSeatClass) {
      return cabinSeatClass.seatClassId === id;
  }),

  getOwnerServicesCabinOwnPermittedSeatList: (state) => state.permittedCabinSeatList,
  getOwnerServicesCabinOwnPermittedSeatListBySeatClassId: (state) => (id) =>
    state.permittedCabinSeatList.filter(function (cabinSeatClass) {
      return cabinSeatClass.seatClassId === id;
  }),

  getOwnerServicesPermittedCabinSeatList: (state) => state.permittedCabinSeatListByOtherCabinMan,
  getOwnerServicesPermittedCabinSeatListBySeatClassId: (state) => (id) =>
  state.permittedCabinSeatListByOtherCabinMan.filter(function (cabinSeatClass) {
    return cabinSeatClass.seatClassId === id;
  }),
  getOwnerServicesBlockedSeatList: (state) => state.blockedSeatList,
  getOwnerServicesBlockedSeatListBySeatClassId: (state) => (id) =>
    state.blockedSeatList.filter(function (cabinSeatClass) {
      return cabinSeatClass.seatClassId === id;
    }),
};
const actions = {

  async deleteOwnerServicesBoardingPlaceForRoute(_, { bPlace }) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .delete(Apis.OWNER_DELETE_BOARDING_PLACE_FOR_ROUTE, {
            data: bPlace,
            headers: header,
          })
          .then((response) => {
            resolve(successResponse(response));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
  async deleteOwnerServicesDroppingPointForRoute(_, { dPlace }) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .delete(Apis.OWNER_DELETE_DROPPING_POINT_FOR_ROUTE, {
            data: dPlace,
            headers: header,
          })
          .then((response) => {
            resolve(successResponse(response));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getOwnerServicesCabinManByPage({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setCabinMans", []);
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_OWNER_SERVICES_ALL_CABINMANS, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              commit("setCabinMans", data.cabinMan);
              resolve({ success: "success" });
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async addOwnerServicesCabinMan(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.ADD_OWNER_SERVICES_CABINMAN, payload, { headers: header })
          .then((success) => {
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async updateOwnerServicesCabinMan(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .put(Apis.UPDATE_OWNER_SERVICES_CABINMAN, payload, { headers: header })
          .then((success) => {
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getOwnerServicesCabinSeatList({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.GET_OWNER_SERVICES_CABIN_SEATLIST, payload, { headers: header })
          .then((res) => {
            commit('setCabinSeatList', res.data)
            resolve(res.data);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getOwnerServicesPermittedCabinSeatList({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.GET_OWNER_SERVICES_PERMITTED_CABIN_SEATLIST, payload, { headers: header })
          .then((res) => {
            if (res.data !== null) {
              commit('setPermittedCabinSeatList', res.data.permittedSeats)
              commit('setPermittedCabinSeatListByOther', res.data.permittedSeatListByOthers)
              commit('setBlockedSeatList', res.data.blockedSeatList)
              resolve(res.data.permittedSeats);
            }
            resolve(res.data)
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async addOwnerServicesSeatPermission(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.SEAT_OWNER_SERVICES_PERMISSION_CABINMAN, payload, { headers: header })
          .then((success) => {
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async addFloorOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.OWNER_ADD_FLOORPLAN, payload, { headers: header })
          .then(({ data, status }) => {
            if (status === 200) {
              messageExtractor(data, status);
              commit("updateShipOwner", data.ship);
              resolve(data.message);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async updateFloorOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .put(Apis.OWNER_UPDATE_FLOORPLAN, payload, { headers: header })
          .then(({ data, status }) => {
            if (status === 200) {
              messageExtractor(data, status);
              commit("updateShipOwner", data.ship);
              resolve(data.message);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async deleteFloorOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .delete(Apis.OWNER_DELETE_FLOORPLAN, { data: payload, headers: header })
          .then(({ data, status }) => {
            if (status === 200) {
              messageExtractor(data, status);
              // commit("removeSeatClass", payload);
              commit("updateShipOwner", data.ship);
              resolve(data.message);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getAgentListOwner({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setDeckMans", []);
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.AGENT_LIST_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              commit("setAgentListOwner", data.agent);
              resolve({ success: "success" });
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async addAgentOwner(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };
      return new Promise((resolve, reject) => {
        axios
          .post(Apis.AGENT_ADD_OWNER, payload, { headers: header })
          .then((response) => {
            successToast("Agent Added Successfully");
            resolve(response);
          })
          .catch((error) => {
            errorToast("Agent Add Failed");
            reject(errorResponse(error));
          });
      });
    }
  },

  async updateAgentOwner(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };
      return new Promise((resolve, reject) => {
        axios
          .put(Apis.AGENT_ADD_OWNER, payload, { headers: header })
          .then((response) => {
            successToast("Agent updated Successfully");
            resolve(response);
          })
          .catch((error) => {
            errorToast("Agent Update Failed");
            reject(errorResponse(error));
          });
      });
    }
  },

  async getDackmanByPageOwner({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setDeckMans", []);
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_DECKMANS_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              commit("setDeckMansOwner", data.deckMan);
              resolve({ success: "success" });
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async addDeckManOwner(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.ADD_DECKMAN_OWNER, payload, { headers: header })
          .then((response) => {
            successToast("Deckman Added Successfully");
            resolve(response);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async updateDeckManOwner(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .put(Apis.UPDATE_DECKMAN_OWNER, payload, { headers: header })
          .then((response) => {
            successToast("Deckman Updated Successfully");
            resolve(response);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getCountersByPageOwner({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setCounters", []);
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_COUNTERS_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              commit("setCountersOwner", data.counter);
              resolve({ success: "success" });
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getStoppageListOwner({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setStoppageListInFilter", {});
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_STOPPAGES_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              let stoppageList = {};
              data.stoppage.forEach((stoppage) => {
                stoppageList[stoppage._id] = stoppage.name;
              });
              commit("setStoppageListInFilterOwner", stoppageList);
              resolve(data.stoppage);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async addCounterOwner(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.ADD_COUNTER_OWNER, payload, { headers: header })
          .then((success) => {
            successToast("Counter Added Successfully");
            resolve(success);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async updateCounterOwner(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .put(Apis.UPDATE_COUNTER_OWNER, payload, { headers: header })
          .then((success) => {
            successToast("Counter Updated Successfully");
            resolve(success);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getCounterMansByPageOwner({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setCounterMans", []);
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_COUNTERMANS_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              commit("setCounterMansOwner", data.counterMan);
              resolve({ success: "success" });
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async addCounterManOwner(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.ADD_COUNTERMAN_OWNER, payload, { headers: header })
          .then((success) => {
            successToast("Counterman Added Successfully");
            resolve(success);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async updateCounterManOwner(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .put(Apis.UPDATE_COUNTERMAN_OWNER, payload, { headers: header })
          .then((success) => {
            successToast("Counterman Updated Successfully");
            resolve(success);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  //owner supervisor

  async getSupervisorsByPageOwner({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setSupervisorsOwner", []);
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_SUPERVISORS_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              commit("setSupervisorsOwner", data.supervisor);
              resolve({ success: "success" });
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async addSupervisorOwner(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.ADD_SUPERVISOR_OWNER, payload, { headers: header })
          .then((success) => {
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async updateSupervisorOwner(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .put(Apis.UPDATE_SUPERVISOR_OWNER, payload, { headers: header })
          .then((success) => {
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getShipsByPageOwner({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setShips", []);
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_SHIPS_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              commit("setShipsOwner", data.ship);
              resolve({ success: "success" });
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getRouteListOwner({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setRouteListInFilter", {});
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_ROUTES_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              let routeList = {};
              data.route.forEach((route) => {
                if (route.status) {
                  routeList[route._id] = route.name;
                }
              });
              commit("setRouteListInFilterOwner", routeList);
              resolve(data.route);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async addShipOwner(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };
      return new Promise((resolve, reject) => {
        axios
          .post(Apis.ADD_SHIP_OWNER, payload, { headers: header })
          .then((response) => {
            successToast("Ship Added Successfully");
            resolve(response);
          })
          .catch((error) => {
            errorToast("Agent Add Failed");
            reject(errorResponse(error));
          });
      });
    }
  },

  async updateShipOwner(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };
      return new Promise((resolve, reject) => {
        axios
          .put(Apis.UPDATE_SHIP_OWNER, payload, { headers: header })
          .then((response) => {
            successToast("Ship updated Successfully");
            resolve(response);
          })
          .catch((error) => {
            errorToast("Agent Update Failed");
            reject(errorResponse(error));
          });
      });
    }
  },

  async addSeatClassOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.ADD_SEAT_CLASS_OWNER, payload, { headers: header })
          .then(({ data, status }) => {
            if (status === 200) {
              messageExtractor(data, status);
              commit("updateShipOwner", data.ship);
              resolve(data.message);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async addSeatRowOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.ADD_SEAT_ROW_OWNER, payload, { headers: header })
          .then(({ data, status }) => {
            if (status === 200) {
              messageExtractor(data, status);
              commit("updateShipOwner", data.ship);
              resolve(data.message);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async addCustomSeatRowByOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.ADD_CUSTOM_SEAT_ROW_BY_OWNER, payload, { headers: header })
          .then(({ data, status }) => {
            if (status === 200) {
              messageExtractor(data, status);
              commit("updateShipOwner", data.ship);
              resolve(data.message);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async editCustomSeatRowByOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.EDIT_CUSTOM_SEAT_ROW_BY_OWNER, payload, {
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              messageExtractor(data, status);
              commit("updateShipOwner", data.ship);
              resolve(data.message);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async blockSeatsOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.SEAT_BLOCK_OWNER, payload, { headers: header })
          .then(({ data, status }) => {
            if (status === 200) {
              messageExtractor(data, status);
              commit("updateShipOwner", data.ship);
              resolve(data.message);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async deleteSeatClassOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .delete(Apis.DELETE_SEAT_CLASS_OWNER, {
            data: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              messageExtractor(data, status);
              // commit("removeSeatClass", payload);
              commit("updateShipOwner", data.ship);
              resolve(data.message);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async updateSeatClassOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .put(Apis.UPDATE_SEAT_CLASS_OWNER, payload, { headers: header })
          .then(({ data, status }) => {
            if (status === 200) {
              messageExtractor(data, status);
              commit("updateShipOwner", data.ship);
              resolve(data.message);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getOwnerServicesStopageByPage({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setOwnersServicesStopage", []);
      const header = {
        Authorization: "Bearer " + getToken(),
      };
      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_OWNER_SERVICES_STOPAGE, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              commit("setOwnersServicesStopage", data.stoppage);
              resolve({ success: "success" });
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async addOwnerServicesStopage(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };
      return new Promise((resolve, reject) => {
        axios
          .post(Apis.GET_OWNER_SERVICES_STOPAGE, payload, { headers: header })
          .then((success) => {
            successToast("Owner Added Succesfully");
            resolve(success);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async updateOwnerServicesStopage(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };
      return new Promise((resolve, reject) => {
        axios
          .put(Apis.GET_OWNER_SERVICES_STOPAGE, payload, { headers: header })
          .then((success) => {
            successToast("Owner Updated Succesfully");
            resolve(success);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getOwnerServicesRouteByPage({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setOwnersServicesRoute", []);
      const header = {
        Authorization: "Bearer " + getToken(),
      };
      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_OWNER_SERVICES_ROUTE, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              commit("setOwnersServicesRoute", data.route);
              resolve({ success: "success" });
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getOwnerServicesStoppagesInFilter({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setOwnersServicesRouteInFilter", {});
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_OWNER_SERVICES_STOPAGE, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              let stoppageList = {};
              data.stoppage.forEach((stoppage) => {
                stoppageList[stoppage._id] = stoppage.name;
              });
              commit("setOwnersServicesRouteInFilter", stoppageList);
              resolve(data.stoppage);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async addOwnerServicesRoute(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };
      return new Promise((resolve, reject) => {
        axios
          .post(Apis.GET_OWNER_SERVICES_ROUTE, payload, { headers: header })
          .then((success) => {
            successToast("Added Route Succesfully");
            resolve(success);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async updateOwnerServicesRoute(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };
      return new Promise((resolve, reject) => {
        axios
          .put(Apis.GET_OWNER_SERVICES_ROUTE, payload, { headers: header })
          .then((success) => {
            successToast("Route Updated  Succesfully");
            resolve(success);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getOwnerServicesBoardingPlacesByPage({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setOwnerServicesBoardingPlaces", []);
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_OWNER_SERVICES_BOARDING_PLACE, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              commit("setOwnerServicesBoardingPlaces", data.boardingPlace);
              resolve({ success: "success" });
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async addOwnerServicesBoardingPlace(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.GET_OWNER_SERVICES_BOARDING_PLACE, payload, {
            headers: header,
          })
          .then((success) => {
            successToast("Boarding Place Added Succesfully");
            resolve(success);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async updateOwnerServicesBoardingPlace(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .put(Apis.GET_OWNER_SERVICES_BOARDING_PLACE, payload, {
            headers: header,
          })
          .then((success) => {
            successToast("Boarding Place Updated Succesfully");
            resolve(success);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getOwnerServicesDroppingPlacesByPage({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setOwnerServicesDroppingPlaces", []);
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_OWNER_SERVICES_DROPPING_PLACE, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              commit("setOwnerServicesDroppingPlaces", data.droppingPoint);
              resolve({ success: "success" });
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async addOwnerServicesDroppingPlace(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.GET_OWNER_SERVICES_DROPPING_PLACE, payload, {
            headers: header,
          })
          .then((success) => {
            successToast("Dropping Place Added Succesfully");
            resolve(success);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async updateOwnerServicesDroppingPlace(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .put(Apis.GET_OWNER_SERVICES_DROPPING_PLACE, payload, {
            headers: header,
          })
          .then((success) => {
            successToast("Dropping Place Updated Succesfully");
            resolve(success);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getOwnerServicesLocalRoutesByPage({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setOwnerServicesLocalRoutes", []);
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.OWNER_GET_ALL_LOCAL_ROUTES, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              commit("setOwnerServicesLocalRoutes", data.localRoutes);
              commit("setOwnerServicesPlaces", data.places);
              resolve({ success: "success" });
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async addOwnerServicesLocalRoute(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.OWNER_ADD_LOCAL_ROUTE, payload, { headers: header })
          .then((success) => {
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
  
  async updateOwnerServicesLocalRoute(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .put(Apis.OWNER_UPDATE_LOCAL_ROUTE, payload, { headers: header })
          .then((success) => {
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async deleteOwnerServicesLocalRouteforRoute(_, { localRoute }) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .delete(Apis.OWNER_DELETE_LOCAL_ROUTE, { params: localRoute, headers: header })
          .then((response) => {
            resolve(successResponse(response));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getOwnerServicesTripsByPage({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setOwnerServicesTrips", []);
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_OWNER_SERVICES_TRIPS, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              commit("setOwnerServicesTrips", data.trip);
              resolve({ success: "success" });
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async addOwnerServicesTrip(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.GET_OWNER_SERVICES_TRIPS, payload, { headers: header })
          .then((response) => {
            // successToast("Added Route Succesfully");
            // resolve(success);
            resolve(successResponse(response));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async updateOwnerServicesTrip(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .put(Apis.GET_OWNER_SERVICES_TRIPS, payload, { headers: header })
          .then((success) => {
            successToast("Trip Updated Succesfully");
            resolve(success);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
  async getOwnerServicesShipsByRoute(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_OWNER_SERVICES_SHIPS_BY_ROUTE, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              resolve(data.ship);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
  async getOwnerServicesShipsInfo(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_OWNER_SERVICES_SHIP_INFO, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              resolve(data.ship);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
  async rechargeBalance(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.AGENT_RECHARGE_BALANCE, payload, {
            headers: header,
          })
          .then((success) => {
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
  async getOwnerServicesShipListInFilter({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_SHIPS_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              let shipList = {};
              data.ship.forEach((ship) => {
                shipList[ship._id] = ship.name;
              });
              commit("setOwnerServicesShipListInFilter", shipList);
              resolve(data.ship);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
  async getOwnerShipsByDeck({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_OWNER_SHIPS_BY_DECK, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              let shipList = {};
              data.ship.forEach((ship) => {
                shipList[ship._id] = ship.name;
              });
              commit("setOwnerServicesShipListInFilter", shipList);
              resolve(data.ship);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async deleteSeatRowOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .delete(Apis.DELETE_SEAT_ROW_OWNER, {
            data: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              messageExtractor(data, status);
              commit("updateShipOwner", data.ship);
              resolve(data.message);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
};
const mutations = {

  setRouteListInFilterOwner: (state, routeList) =>
    (state.filter.routeOwner = routeList),
  setShipsOwner: (state, ships) => (state.shipsOwner = ships),
  setAgentListOwner: (state, agents) => (state.filter.agentsListOwner = agents),
  setDeckMansOwner: (state, deckMans) => (state.deckMansOwner = deckMans),
  setCountersOwner: (state, counters) => (state.countersOwner = counters),
  setStoppageListInFilterOwner: (state, stoppageList) =>
    (state.filter.stoppageOwner = stoppageList),
  setCounterMansOwner: (state, counterMans) =>
    (state.counterMansOwner = counterMans),
  setSupervisorsOwner: (state, supervisorsOwner) =>
    (state.supervisorsOwner = supervisorsOwner),

  updateShipOwner: (state, updatedShip) => {
    const index = state.shipsOwner.docs.findIndex(
      (ship) => ship._id === updatedShip._id
    );
    if (index !== -1) {
      state.shipsOwner.docs.splice(index, 1, updatedShip);
    }
  },

  setOwnersServicesStopage: (state, stopage) =>
    (state.ownerServicesStopage = stopage),
  setOwnersServicesRoute: (state, route) => (state.ownerServicesRoute = route),
  setOwnersServicesRouteInFilter: (state, routeList) =>
    (state.filter.route = routeList),

  setOwnerServicesBoardingPlaces: (state, boardingPlaces) =>
    (state.ownerServicesBoardingPlaces = boardingPlaces),
  setOwnerServicesDroppingPlaces: (state, droppingPlaces) =>
    (state.ownerServicesDroppingPlaces = droppingPlaces),
  setOwnerServicesLocalRoutes: (state, localRoutes) => (state.localRoutes = localRoutes),
  setOwnerServicesPlaces: (state, places) => (state.places = places),
  setOwnerServicesTrips: (state, trips) => (state.ownerServicesTrips = trips),
  setOwnerServicesShipListInFilter: (state, shipList) =>
    (state.filter.ship = shipList),

  setCabinMans: (state, cabinMans) => (state.cabinMans = cabinMans),
  setCabinSeatList: (state, seatList) => (state.cabinSeatList = seatList),
  setPermittedCabinSeatList: (state, seatList) => (state.permittedCabinSeatList = seatList),
  setPermittedCabinSeatListByOther: (state, seatList) => (state.permittedCabinSeatListByOtherCabinMan = seatList),
  setBlockedSeatList: (state, blockedSeatList) => (state.blockedSeatList = blockedSeatList),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
