<template>
  <div>
    <FilterTab>
      <template v-slot:title><h6>Trips</h6></template>
      <template v-slot:body>
        <div class="col-sm-4 col-lg-2">
          <DropdownFilter
            v-bind:id="'from'"
            v-bind:label="'From Stoppage'"
            v-bind:defaultOption="'--select stoppage--'"
            v-model="pagination.from"
            v-bind:defaultValue="''"
            v-bind:dropdown="$store.getters.getStoppageListForFilter"
          />
        </div>
        <div class="col-sm-4 col-lg-2">
          <DropdownFilter
            v-bind:id="'to'"
            v-bind:label="'To Stoppage'"
            v-bind:defaultOption="'--select stoppage--'"
            v-model="pagination.to"
            v-bind:defaultValue="''"
            v-bind:dropdown="$store.getters.getStoppageListForFilter"
          />
        </div>
        <div class="col-sm-4 col-lg-2">
          <DropdownFilter
            v-bind:id="'ship'"
            v-bind:label="'Ship'"
            v-bind:defaultOption="'--select ship--'"
            v-model="pagination.ship"
            v-bind:defaultValue="''"
            v-bind:dropdown="$store.getters.getShipListForFilter"
          />
        </div>
        <div class="col-sm-8 col-lg-4">
          <DateRangePicker v-model="date.range" />
        </div>
        <div class="col-sm-4 col-lg-2">
          <DropdownFilter
            v-bind:id="'status'"
            v-bind:label="'Status'"
            v-bind:defaultOption="'--select status--'"
            v-model="pagination.status"
            v-bind:defaultValue="''"
            v-bind:dropdown="status"
          />
        </div>
      </template>
      <template v-if="hasPermission(Admin.companyDeployWrite)" v-slot:button1>
        <button class="edit_table_data font_detail" @click="addModal">
          <i class="fas fa-plus"></i><span class="mx-1 bold">Add New</span>
        </button>
      </template>
    </FilterTab>
    <div v-if="addUpdate">
      <TripsAddUpdateModal
        v-bind:trip="tripId"
        v-bind:routeList="$store.getters.getRouteListForFilter"
        v-bind:company="pagination.companyId"
        @close="close"
        @getAllTrips="getAllTrips"
      />
    </div>
    <div>
      <Table v-if="$store.getters.getTrips">
        <template v-slot:header>
          <tr>
            <td class="font_detail">SL</td>
            <td class="font_detail">TRIP TIME & DATE</td>
            <td class="font_detail">TRIP CODE</td>
            <td class="font_detail">SHIP</td>
            <td class="font_detail">ROUTE</td>
            <td class="font_detail">DIRECTION</td>
            <td class="font_detail">DECK FARE</td>
            <td   class="font_detail">DT AVAILABLE</td>
            <td class="font_detail">STATUS</td>
            <td
              v-if="hasPermission(Admin.companyDeployWrite)"
              class="font_detail"
            >
              ACTION
            </td>
          </tr>
        </template>
        <template v-slot:body>
          <tr v-for="(trip, index) in $store.getters.getTrips" :key="trip._id">
            <td class="font_detail">{{ pagination.limit*(pagination.page-1) +index +1 }}</td>
            <td class="font_detail bold">{{ dateFormat(trip.tripDate) }}</td>
            <td class="font_detail">{{ trip.tripCode }}</td>
            <td class="font_detail bold">{{ trip.ship.name }}</td>
            <td class="font_detail">{{ trip.route.name }}</td>
            <td class="font_detail bold">
              {{ trip.direction }}<br />({{ trip.fromStoppage.name }} -
              {{ trip.toStoppage.name }})
            </td>
            <td class="font_detail">{{ trip.deckFare }}</td>
            <td  >
              <span
                class="badge"
                :class="trip.isDTAvailable === undefined || trip.isDTAvailable == true ? 'bg-success' : 'bg-danger'"
                >{{ trip.isDTAvailable === undefined || trip.isDTAvailable == true ? 'Yes' : 'No' }}</span
              >
            </td>
            <td>
              <span
                class="badge"
                :class="'bg-' + dropdownVariables.tripStatus[trip.status].badge"
                >{{ dropdownVariables.tripStatus[trip.status].value }}</span
              >
            </td>
            
            <td v-if="hasPermission(Admin.companyDeployWrite)">
              <button
                class="edit_table_data font_detail edit_table_button"
                @click="updateModal(trip._id)"
              >
                <i class="fas fa-edit"></i><span class="mx-1">Edit</span>
              </button>
            </td>
          </tr>
        </template>
        <template v-slot:pagination>
          <pagination
            v-model="pagination.page"
            :per-page="pagination.limit"
            :records="$store.getters.getTotalTrips"
            @paginate="getAllTrips"
            :options="options"
          />
        </template>
      </Table>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { reactive } from "@vue/reactivity";
import { inject, onBeforeMount, ref, watch, computed } from "vue";
import store from "@/store";
import dropdownVariables from "@/modules/dropdownVariables";
import { useRoute } from "vue-router";
import TripsAddUpdateModal from "@/views/Admin/CompanyDeploy/Children/Trips/TripsAddUpdateModal";
import permission from "@/modules/permission";
import roles from "@/modules/roles";
import DateRangePicker from "@/components/layouts/Filter/Inputs/DateRangePicker";

const { hasPermission } = permission();
const { Admin } = roles();

export default {
  name: "Trips",
  components: { TripsAddUpdateModal, DateRangePicker },
  setup() {
    const addUpdate = ref(false);
    const tripId = ref("");
    const options = {
      texts: {
        count: "",
      },
    };
    const route = useRoute();
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    // const routeList = reactive({});
    // let stoppageList = ref({});
    // const shipList = reactive({});

    const fromDate = moment(new Date()).format("yyyy-MM-DD");
    const toDate = moment(new Date()).format("yyyy-MM-DD");

    let date = reactive({
      range: {
        start: fromDate,
        end: toDate,
      },
    });
  
    let pagination = reactive({
      page: 1,
      limit: 10,
      companyId: "",
      from: "",
      to: "",
      ship: "",
      fromDate: "",
      toDate: "",
      status: "",
    });

    watch(
      () => pagination.status,
      () => {
        resetPagination();
        getAllTrips();
      }
    );
    watch(
      () => pagination.from,
      () => {
        resetPagination();
        getAllTrips();
      }
    );
    watch(
      () => pagination.to,
      () => {
        resetPagination();
        getAllTrips();
      }
    );
    watch(
      () => date.range,
      () => {
        resetPagination();
        getAllTrips();
      }
    );
    watch(
      () => pagination.ship,
      () => {
        resetPagination();
        getAllTrips();
      }
    );
    watch(
      () => date.range.start,
      (value) => {
        pagination.fromDate = moment(value).format(
          "yyyy-MM-DD"
        );
      }
    );
    watch(
      () => date.range.end,
      (value) => {
        pagination.toDate = moment(value).format(
          "yyyy-MM-DD"
        );
      }
    );

    const resetPagination =()=>{
      pagination.page = 1;
    };

    const status = computed(() => {
      let allTripStatus = {};
      for (let [key, value] of Object.entries(dropdownVariables.tripStatus)) {
        allTripStatus[key] = value.value;
      }
      return allTripStatus;
    });

    const close = () => {
      addUpdate.value = false;
    };
    const addModal = () => {
      tripId.value = "";
      addUpdate.value = true;
    };
    const updateModal = (id) => {
      tripId.value = id;
      addUpdate.value = true;
    };
    const dateFormat = (date) => {
      return moment(String(date))
        .utcOffset(0, false)
        .format("DD-MMM-yyyy hh:mm:ss A");
    };
    const getAllTrips = () => {
      showLoader();
      store
        .dispatch("getTripsByPage", pagination)
        .then(() => {
          getAllStoppages();
          getAllShips();
          getAllRoutes();
          hideLoader();
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            getAllTrips();
          }
        });
    };
    const getAllStoppages = () => {
      store
        .dispatch("getStoppageList", { companyId: pagination.companyId })
        .then(() => {})
        .catch((error) => {
          if (error.status === 401) {
            getAllStoppages();
          }
        });
    };
    const getAllShips = () => {
      store
        .dispatch("getShipList", { companyId: pagination.companyId })
        .then(() => {})
        .catch((error) => {
          if (error.status === 401) {
            getAllShips();
          }
        });
    };
    const getAllRoutes = () => {
      store
        .dispatch("getRouteList", { companyId: pagination.companyId })
        .then(() => {})
        .catch((error) => {
          if (error.status === 401) {
            getAllRoutes();
          }
        });
    };

    onBeforeMount(() => {
      if (route.query.id) {
        pagination.companyId = route.query.id;
        pagination.fromDate = fromDate;
        pagination.toDate = toDate;
        getAllTrips();
      }
    });

    return {
      options,
      pagination,
      dropdownVariables,
      addUpdate,
      tripId,
      Admin,
      hasPermission,
      getAllTrips,
      addModal,
      updateModal,
      close,
      dateFormat,
      status,
      date
    };
  },
};
</script>

<style scoped>
.bold {
  font-weight: bold;
}
</style>