<template>
  <div>
    <FilterTab>
      <template v-slot:title><h6>Supervisor</h6></template>
      <template v-slot:body>
        <div class="col-sm-6 col-md-2">
          <FilterInput
            v-model="pagination.nameOrPhone"
            :placeholder="'Name/Phone'"
            :label="'Name/Phone'"
            :id="'nameOrphone'"
            :type="'text'"
          />
        </div>
      </template>
      <template v-slot:button2>
        <button
          v-if="hasPermission(Owner.ownerCompanySetupWrite)"
          class="edit_table_data font_detail"
          @click="addModal"
        >
          <i class="fas fa-plus"></i><span class="mx-1 bold">Add New</span>
        </button>
      </template>
      <template v-slot:button1>
        <button class="edit_table_data font_detail" @click="getAllSupervisor">
          <i class="fas fa-search"></i><span class="mx-1 bold">Search</span>
        </button>
      </template>
    </FilterTab>
    <Table v-if="$store.getters.getSupervisorsOwner">
      <template v-slot:header>
        <tr>
          <td class="font_detail">SL</td>
          <td class="font_detail">NAME</td>
          <td class="font_detail">SHIPS</td>

          <td class="font_detail">MOBILE</td>
          <td class="font_detail">USER TYPE</td>
          <td class="font_detail">STATUS</td>
          <td
            v-if="hasPermission(Owner.ownerCompanySetupWrite)"
            class="font_detail"
          >
            ACTION
          </td>
        </tr>
      </template>
      <template v-slot:body>
        <tr
          v-for="(supervisor, index) in $store.getters.getSupervisorsOwner"
          :key="supervisor._id"
        >
          <td class="font_detail">{{ pagination.limit*(pagination.page-1) +index +1 }}</td>
          <td class="font_detail">{{ supervisor.name }}</td>
          <td class="font_detail">
            <span v-for="ship in supervisor.shipId" :key="ship._id"
              ><span class="badge rounded-pill bg-primary m-1 p-2">{{
                ship.name
              }}</span></span
            >
          </td>
          <td class="font_detail">{{ supervisor.phone }}</td>
          <td class="font_detail">
            {{ dropdownVariables.userType[supervisor.userType] }}
          </td>
          <td>
            <span v-if="supervisor.status" class="badge bg-success">{{
              dropdownVariables.status[1]
            }}</span>
            <span v-else class="badge bg-danger">{{
              dropdownVariables.status[0]
            }}</span>
          </td>
          <td v-if="hasPermission(Owner.ownerCompanySetupWrite)">
            <button
              class="edit_table_data font_detail edit_table_button"
              @click="updateModal(supervisor._id)"
            >
              <i class="fas fa-edit"></i><span class="mx-1">Edit</span>
            </button>
          </td>
        </tr>
      </template>
      <template v-slot:pagination>
        <pagination v-model="pagination.page" :records="$store.getters.getTotalSupervisorOwner" :per-page="pagination.limit"
                    @paginate="getAllSupervisor" :options="options"/>
      </template>
    </Table>
    <div v-if="showModal">
      <SupervisorAddUpdateModal
        v-model="showModal"
        @getAllSupervisors="getAllSupervisor"
        :company="companyId"
        :supervisor="supervisorId"
      />
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { useRoute } from "vue-router";
import { onBeforeMount, reactive, ref, inject } from "vue";
import permission from "@/modules/permission";
import roles from "@/modules/roles";
import dropdownVariables from "@/modules/dropdownVariables";
import SupervisorAddUpdateModal from "./SupervisorAddUpdateModal.vue";
import FilterInput from "@/components/layouts/Filter/Inputs/Input.vue";

const { hasPermission } = permission();
const { Owner } = roles();
export default {
  name: "Supervisor",
  components: {
    SupervisorAddUpdateModal,
    FilterInput
  },
  setup() {
    const companyId = ref("");
    const supervisorId = ref("");
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const showModal = ref(false);

    const pagination = reactive({
      companyId,
      counterId: "",
      nameOrPhone: "",
      page: 1,
      limit: 10,
    });
    const options = {
      texts: {
        count: "",
      },
    };

    const getAllSupervisor = () => {
      showLoader();
      store
        .dispatch("getSupervisorsByPageOwner", pagination)
        .then(() => {
          getAllShips();
        })
        .catch((error) => {
          if (error.status === 401) {
            // getAllSupervisor();
          }
        });
    };
    const getAllShips = () => {
      store
        .dispatch("getShipListOwner", { companyId: pagination.companyId })
        .then(() => {
          hideLoader();
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            getAllShips();
          }
        });
    };

    const updateModal = (value) => {
      supervisorId.value = value;
      showModal.value = true;
    };
    const addModal = () => {
      supervisorId.value = "";
      showModal.value = true;
    };

    onBeforeMount(() => {
      companyId.value = useRoute().query.id;
      getAllSupervisor();
    });
    return {
      companyId,
      supervisorId,
      pagination,
      getAllSupervisor,
      updateModal,
      hasPermission,
      Owner,
      options,
      dropdownVariables,
      showModal,
      addModal,
    };
  },
};
</script>

<style scoped>
.bold {
  font-weight: bold;
}
</style>