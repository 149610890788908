import tokenCookie from "@/modules/tokenCookie";
import apiResponse from "@/modules/apiResponse";

const {hasValidToken, getToken} = tokenCookie();
const {errorResponse} = apiResponse();
import axios from "axios";
import Apis from "@/urls/apis";

const state = {
    ships: {},
};
const getters = {
    getAllShipsAgent: (state) => state.ships.docs,
    getTotalShipsAgent: (state) => state.ships.totalDocs,
};
const actions = {
    async getAllShipsAgent({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };

            return new Promise((resolve, reject) => {
                axios.get(Apis.AGENT_ALL_SHIPS, {
                    params: payload,
                    headers: header
                })
                    .then(({data, status}) => {
                        if (status === 200) {
                            commit("setAllShipsAgent", data.ship)
                            resolve(data.ship)
                        }
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },
};
const mutations = {
    setAllShipsAgent: (state, allShips) => (state.ships = allShips),
};

export default {
    state,
    getters,
    actions,
    mutations
};