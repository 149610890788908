<template>
  <div class="large_device_view">
    <section class="select_area py-1">
      <!-- Searching menu -->
      <div class="text-white text-center bold">
        <slot name="title"></slot>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12 col-md-9 col-xl-8">
            <div class="row font_detail">
              <slot name="body"></slot>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-xl-4">
            <div class="row row-cols-auto gap-2 button_section justify-content-end justify-content-lg-end">
                <slot name="searchTicket"></slot>
                <slot name="button1"></slot>
                <slot name="button2"></slot>
                <slot name="button3"></slot>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "FilterTab",
};
</script>

<style scoped>
.bold {
  font-weight: bold;
}

.button_section {
  padding-top: 13px;
  margin: 2px 0;
}
</style>
