<template>
  <Modal @close="closeModal">
    <template v-slot:title>{{ title }}</template>
    <template v-slot:body>
      <form class="my-2">
        <div class="form-group my-1 mt-3">
          <div class="row form-group my-1">
            <div class="col-sm-3">
              <p class="font_detail">Due(s)</p>
            </div>
            <div class="col-sm-9">
              <div
                class="form-control font_detail available_other available span_font"
              >
                <span class="orange-bold-text">
                  {{ ticketAmountDues }}
                </span>
              </div>
            </div>
          </div>
          <div class="row form-group my-1">
            <div class="col-sm-3">
              <p class="font_detail">DISCOUNT</p>
            </div>
            <div class="col-sm-9">
              <input
                v-model="ticketConfirmData.discount"
                type="Number"  
                min="0" 
                step="1" 
                onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.keyCode == 8 || event.keyCode == 46'
                onkeydown="if(event.key==='.'){event.preventDefault();}"
                onkeyup="if(event.key==='.'){event.preventDefault();}"
                oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"
                class="input_field form-control rounded px-3 w-100 text-left"
                autocomplete="off" @click.right.prevent @keydown="keydown" @copy.prevent @paste.prevent
              />
            </div>
          </div>
          <div class="row form-group my-1">
            <div class="col-sm-3">
              <p class="font_detail">Paid</p>
            </div>
            <div class="col-sm-9">
              <div
                class="form-control font_detail available_other available span_font"
              >
                <span class="orange-bold-text">
                  {{ paidAmount }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row my-1">
          <label class="col-sm-3 col-form-label"></label>
          <div class="col-sm-9">
            <button
              class="mt-3 py-2 mr-1 submit_button font_detail float-end"
              @click.prevent="reservedTicketConfirm"
            >
              Confirm
            </button>
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
import { inject, ref, onBeforeMount, reactive, watch } from "vue";
import store from "@/store";
// import moment from "moment";

export default {
  components: {},
  name: "ReserveTicketConfirm",
  props: {
    ticketIdStore: {
      type: String,
    },
    countermanTicketId: {
      type: String,
    },
    ticketTotalAmount: {
      type: Number,
    },
    ticketPayable: {
      type: Number,
    },
    ticketAmountPaid: {
      type: Number,
    },
    ticketAmountDues: {
      type: Number,
    },
    pagination: {
      type: Object,
    },
    // ticket: {
    //   type: Object,
    // },
  },
  setup(props, { emit }) {
    const ticketConfirmData = reactive({
      ticketId: "",
      countermanId: "",
      totalAmount: props.ticketTotalAmount,
      dues: 0,
      discount: 0,
      paid: 0,
      payable: 0,
      ticketType: "SEAT",
      ticketDateTime:""
    });
    let paidAmount = ref(props.ticketAmountDues);
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const title = ref("Reserved Ticket Confirm");
    let checked = ref([]);
    let allSelected = ref(false);
    let reA = /[^a-zA-Z]/g;
    let reN = /[^0-9]/g;

    let lastTicketClone = {
      seatNumbersForPrintArray: [],
      seatNumbersForPrint: "",
    };

    const setSeatNumbersForPrint = () => {
      for (
        let index = 0;
        index < store.getters.getReservedTicketsData.numberOfTicketCopy;
        index++
      ) {
        lastTicketClone.seatNumbersForPrintArray = [
          ...lastTicketClone.seatNumbersForPrintArray,
          ...store.getters.getReservedTicketsData.seatNumbers.split(","),
        ];
      }

      lastTicketClone.seatNumbersForPrint =
        lastTicketClone.seatNumbersForPrintArray.sort(sortAlphaNum).toString();
      delete lastTicketClone.seatNumbersForPrintArray;

      store.commit("setSeatNumbersForPrint", lastTicketClone.seatNumbersForPrint)
    };

    const sortAlphaNum = (a, b) => {
      let aA = a.replace(reA, "");
      let bA = b.replace(reA, "");
      if (aA === bA) {
        let aN = parseInt(a.replace(reN, ""), 10);
        let bN = parseInt(b.replace(reN, ""), 10);
        return aN === bN ? 0 : aN > bN ? 1 : -1;
      } else {
        return aA > bA ? 1 : -1;
      }
    };

    const getTicketsHistory = () => {
      showLoader();
      store
        .dispatch("getReservedTicketsHistory", props.pagination)
        .then(() => {
          hideLoader();
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            getTicketsHistory();
          }
        });
    };

    const closeModal = () => {
      emit("update:modelValue", false);
    };

    watch(
      () => ticketConfirmData.discount,
      () => {
        if(ticketConfirmData.discount % 1 !== 0){
          ticketConfirmData.discount = Math.round(ticketConfirmData.discount)
        }
        if (ticketConfirmData.discount > props.ticketAmountDues) {
          ticketConfirmData.discount = 0;
        }
        paidAmount.value =
          parseInt(props.ticketAmountDues) - ticketConfirmData.discount;
      }
    );

    const reservedTicketConfirm = () => {
      showLoader();

      ticketConfirmData.ticketId = props.ticketIdStore;
      ticketConfirmData.countermanId = props.countermanTicketId;
      ticketConfirmData.payable =
        props.ticketPayable - ticketConfirmData.discount;
      ticketConfirmData.paid = ticketConfirmData.payable;
      ticketConfirmData.dues = 0;
      ticketConfirmData.ticketDateTime = new Date()
      store
        .dispatch("reserveTicketConfirm", ticketConfirmData)
        .then(() => {
          getTicketsHistory();
          emit("ticketPrintPreview", ticketConfirmData);
          setSeatNumbersForPrint();
          // emit("getAllTripsForTicketing", props.ticket);
          hideLoader();
          closeModal();
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            getTicketsHistory();
          }
        });
    };

    onBeforeMount(() => {});

    return {
      title,
      reservedTicketConfirm,
      closeModal,
      checked,
      allSelected,
      ticketConfirmData,
      paidAmount,
      lastTicketClone,
      setSeatNumbersForPrint,
    };
  },
};
</script>

<style scoped>
.custom-size-input {
  /* Double-sized Checkboxes */
  -ms-transform: scale(1.5); /* IE */
  -moz-transform: scale(1.5); /* FF */
  -webkit-transform: scale(1.5); /* Safari and Chrome */
  -o-transform: scale(1.5); /* Opera */
  padding: 1px;
  margin-right: 10px;
}
.custom-label {
  font-size: 16px;
  line-height: 0;
}
</style>
