import apiResponse from "@/modules/apiResponse";
import tokenCookie from "@/modules/tokenCookie";
import Apis from "@/urls/apis";
import axios from "axios";


const {hasValidToken, getToken} = tokenCookie();
const {successResponse, errorResponse} = apiResponse();

const state = {
    panelOwners: []
};
const getters = {
    getPanelOwners: (state) => state.panelOwners.docs,
    getPanelTotalRecords: (state) => state.panelOwners.totalDocs,
    getPanelOwner: (state) => (id) => state.panelOwners.docs.filter(function (user) {
        return user._id === id;
    })
};
const actions = {
    async getPanelOwnersByPage({commit}, payload) {
        if (await hasValidToken()) {
            commit("setOwners", []);
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.get(Apis.GET_ALL_OWNERS_PANEL, {
                    params: payload,
                    headers: header
                })
                    .then(({data, status}) => {
                        if (status === 200) {
                            commit("setOwners", data.owner);
                            resolve({'success': 'success'});
                        }
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async addPanelOwner(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.post(Apis.ADD_OWNER_PANEL, payload, {headers: header})
                    .then((success) => {
                        resolve(successResponse(success))
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async updatePanelOwner(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.put(Apis.UPDATE_OWNER_PANEL, payload, {headers: header})
                    .then((success) => {
                        resolve(successResponse(success))
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async deleteOwner({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.delete(Apis.DELETE_OWNER_PANEL, {data: payload, headers: header})
                    .then((success) => {
                        if (success.status === 200) {
                            commit('deleteOwner', payload);
                            resolve(successResponse(success))
                        }
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    }


};
const mutations = {
    setOwners: (state, owners) => (state.panelOwners = owners),
    deleteOwner: (state, payload) => (state.panelOwners.docs = state.panelOwners.docs.filter((owner) => owner._id !== payload.id))
};

export default {
    state,
    getters,
    actions,
    mutations
};