import web from "@/urls/web";

export default function auth({ next, store }) {
  if (!store.getters.isLoggedIn) {
    return next({
      name: web.Default.LOGIN_URL.name,
    });
  }

  return next();
}
