<template>
  <Table
    id="exportable_data"
    v-if="$store.getters.getPrintTicketCountList.length"
  >
    <template v-slot:header>
      <tr>
        <td class="font_detail">SL</td>
        <td class="font_detail">Counterman / Cabinman Name</td>
        <td class="font_detail">Trip Date & time</td>
        <td class="font_detail">Direction</td>
        <td class="font_detail">Ship Name</td>
        <td class="font_detail">Seat Class</td>
        <td class="font_detail">Seat Numbers</td>
        <td class="font_detail">Print Count</td>
      </tr>
    </template>
    <template v-slot:body>
      <tr v-for="(item, index) in $store.getters.getPrintTicketCountList" :key="index">
        <td class="font_detail" :class="item.userType === 'cabinman' || item.isConfirmedByCabinMan ? 'cabinmanTicket' : ''">{{ index + 1 }}</td>
        <td class="font_detail" :class="item.userType === 'cabinman' || item.isConfirmedByCabinMan ? 'cabinmanTicket' : ''">{{ item.issuedBy }}</td>
        <td class="font_detail" :class="item.userType === 'cabinman' || item.isConfirmedByCabinMan ? 'cabinmanTicket' : ''">{{ dateFormat(item.tripDateTime) }}</td>
        <td class="font_detail" :class="item.userType === 'cabinman' || item.isConfirmedByCabinMan ? 'cabinmanTicket' : ''">{{ item.direction }}</td>
        <td class="font_detail" :class="item.userType === 'cabinman' || item.isConfirmedByCabinMan ? 'cabinmanTicket' : ''">{{ item.shipName }}</td>
        <td class="font_detail" :class="item.userType === 'cabinman' || item.isConfirmedByCabinMan ? 'cabinmanTicket' : ''">{{ item.seatClassName }}</td>
        <td class="font_detail" :class="item.userType === 'cabinman' || item.isConfirmedByCabinMan ? 'cabinmanTicket' : ''">{{ item.seatNumbers.replaceAll(",", ", ") }}</td>
        <td class="font_detail" :class="item.userType === 'cabinman' || item.isConfirmedByCabinMan ? 'cabinmanTicket' : ''">{{ item.printCount }}</td>
      </tr>
      <!-- <tr class="bg-dark text-white">
        <td class="font_detail"></td>
        <td class="font_detail"></td>
        <td class="font_detail"></td>
        <td class="font_detail"></td>
        <td class="font_detail"></td>
        <td class="font_detail"></td>
        <td class="font_detail"></td>
        <td class="font_detail"></td>
      </tr> -->
    </template>
    <!-- <template v-slot:pagination>
      <Pagination
        v-model="shipWiseSeatReport.page"
        :records="$store.getters.getShipWiseSeatDetailsOwner.length"
        :per-page="shipWiseSeatReport.limit"
        @paginate="getSeatDetails"
        :options="options"
      />
    </template> -->
  </Table>
</template>

<script>
import moment from "moment";
export default {
  name: "TicketPrintCountDetails",
  setup() {
    const dateFormat = (date) => {
      return moment(String(date))
        .utcOffset(0, false)
        .format("DD-MMM-yyyy hh:mm:ss A");
    };
    return {
      dateFormat,
    };
  },
};
</script>

<style scoped>
  .cabinmanTicket{
    background-color: #bdcbff;
  }
</style>