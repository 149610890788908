<template>
  <div v-if="reportActive">
    <FilterTabBetween>
      <template v-slot:body>
        <div class="col-sm-6 col-md-2">
          <DropdownFilter
            :id="'company'"
            :label="'Company'"
            :defaultOption="'--select company--'"
            v-model="deckmanWiseReport.companyId"
            :defaultValue="''"
            :dropdown="$store.getters.getCompanyList"
          />
        </div>
        <div v-if="deckmanWiseReport.companyId" class="col-sm-6 col-md-auto">
          <DropdownFilter
              :id="'ship'"
              :label="'Ship Name'"
              :defaultOption="'--select ship--'"
              v-model="deckmanWiseReport.shipId"
              :defaultValue="''"
              :dropdown="$store.getters.getCompanyInfo.ships"
          />
        </div>

        <div v-if="deckmanWiseReport.companyId" class="col-sm-6 col-md-4">
          <DateTimeRangePicker v-model="date.range" />
        </div>
        <div
          v-if="deckmanWiseReport.companyId"
          class="col-sm-6 col-md-2 col-lg-1 col-xxl-2"
        >
          <FilterInput
            :id="'tripcode'"
            :label="'Trip Code'"
            v-model="deckmanWiseReport.tripCode"
            :placeholder="'tripcode'"
          />
        </div>

        <div v-if="deckmanWiseReport.companyId" class="col-sm-6 col-md-auto">
          <DropdownFilter
            :id="'direction'"
            :label="'Direction'"
            :defaultOption="'--select direction--'"
            v-model="deckmanWiseReport.direction"
            :defaultValue="''"
            :dropdown="dropdownVariables.direction"
          />
        </div>
        <div v-if="deckmanWiseReport.companyId" class="col-sm-2 col-md-auto">
          <DropdownFilter
            :id="'status'"
            :label="'Status'"
            v-model="deckmanWiseReport.status"
            :dropdown="dropdownVariables.reportStatus"
          />
        </div>

      </template>

      <template v-slot:export>
        <div class="d-flex justify-content-between gap-2">
          <div
              v-if="deckmanWiseReport.companyId"
              class="col-sm-2 col-md-auto mt-auto"
          >
            <button class="edit_table_data font_detail" @click="filterReport">
              <i class="fas fa-filter"></i><span class="mx-1 bold">Filter</span>
            </button>
          </div>

          <button
              class="edit_table_data font_detail"
              @click="
            exportExcel(
              'xlsx',
              'Admin_DeckmanWise_Report.',
              undefined,
              'exportable_data'
            )
          "
          >
            <i class="fas fa-download"></i><span class="mx-1 bold">Export</span>
          </button>
        </div>
      </template>

    </FilterTabBetween>
    <Table
      v-if="deckmanWiseReport.companyId !== '' && filterActive"
      id="exportable_data"
    >
      <template v-slot:header>
        <tr>
          <td class="font_detail">SL</td>
          <td class="font_detail">Deckman Name</td>
          <td class="font_detail">Deck Ticket</td>
          <td class="font_detail">Deck Amount</td>
          <td class="font_detail">Goods Ticket</td>
          <td class="font_detail">Goods Amount</td>
          <td class="font_detail">Details</td>
        </tr>
      </template>
      <template v-slot:body>
        <tr
          v-for="(item, index) in $store.getters.getDeckmanWiseReport"
          :key="item._id"
        >
          <td class="font_detail">{{ index + 1 }}</td>
          <td class="font_detail">{{ item.deckmanName[0] }}</td>
          <td class="font_detail">{{ item.deckTicket }}</td>
          <td class="font_detail">{{ item.deckAmount }} Taka</td>
          <td class="font_detail">{{ item.goodsTicket }}</td>
          <td class="font_detail">{{ item.goodsAmount }} Taka</td>
          <td class="font_detail">
            <button
              class="btn btn-success m-1 btn-sm"
              @click="seatDetails(item.countermanId)"
            >
              Details
            </button>
          </td>
        </tr>
        <tr class="bg-dark text-white" v-if="summary">
          <td class="font_detail"></td>
          <td class="font_detail"></td>
          <td class="font_detail text-white">
            Total Ticket: {{ deckTicketTotal }}
          </td>
          <td class="font_detail text-white">
            Total Amount: {{ deckAmountTotal }} Taka
          </td>
          <td class="font_detail text-white">
            Total Ticket: {{ goodsTicketTotal }}
          </td>
          <td class="font_detail text-white">
            Total Amount: {{ goodsAmountTotal }} Taka
          </td>
          <td class="font_detail"></td>
        </tr>
      </template>
    </Table>
  </div>
  <DeckmanReportDetails
    v-if="seatDetailsActive"
    :deckmanId="counterManIdPass"
    :fromDate="date.range.start"
    :toDate="date.range.end"
    :status="deckmanWiseReport.status"
    :tripCode="deckmanWiseReport.tripCode"
    :direction="deckmanWiseReport.direction"
    v-on:backToDeckmanWiseReport="onChildClick"
  />

  <router-view></router-view>
</template>

<script>
import DateTimeRangePicker from "@/components/layouts/Filter/Inputs/DateTimeRangePicker";
import DropdownFilter from "@/components/layouts/Filter/Inputs/Dropdown";
import FilterInput from "@/components/layouts/Filter/Inputs/Input.vue";
import dropdownVariables from "@/modules/dropdownVariables";
import { reactive } from "@vue/reactivity";
import moment from "moment";
import { watch, onBeforeMount, ref, inject } from "vue";
import store from "@/store";
import DeckmanReportDetails from "./DeckmanReportDetails.vue";
export default {
  name: "AgentReport",
  components: {
    DateTimeRangePicker,
    DropdownFilter,
    DeckmanReportDetails,
    FilterInput,
  },
  setup() {
    let deckDetailsActive = ref(false);
    let seatDetailsActive = ref(false);
    let reportActive = ref(true);
    let counterManIdPass = ref("");
    let filterActive = ref(false);
    let deckTicketTotal = ref(0);
    let deckAmountTotal = ref(0);
    let goodsTicketTotal = ref(0);
    let goodsAmountTotal = ref(0);
    let isDiscount = ref(false);
    let summary = ref(false);
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const exportExcel = inject("exportExcel");
    const fromDate = moment(new Date()).format("yyyy-MM-DD" + " 00:00:00");
    const toDate = moment(new Date()).format("yyyy-MM-DD" + " 23:59:59");
    let date = reactive({
      range: {
        start: fromDate,
        end: toDate,
      },
    });
    let deckmanWiseReport = reactive({
      companyId: "",
      shipId: "",
      fromDate: "",
      toDate: "",
      status: 1,
      tripCode: "",
      direction: "",
    });
    watch(
      () => deckmanWiseReport.companyId,
      (value) => {
        deckmanWiseReport.companyId = value;
      }
    );
    watch(
      () => deckmanWiseReport.status,
      (value) => {
        deckmanWiseReport.status = value;
      }
    );
    watch(
      () => date.range.start,
      (value) => {
        deckmanWiseReport.fromDate = moment(value).format(
          "yyyy-MM-DD" + " 00:00:00"
        );
      }
    );
    watch(
      () => date.range.end,
      (value) => {
        deckmanWiseReport.toDate = moment(value).format(
          "yyyy-MM-DD" + " 23:59:59"
        );
      }
    );
    watch(
      () => deckmanWiseReport.direction,
      (value) => {
        deckmanWiseReport.direction = value;
      }
    );
    watch(
        () => deckmanWiseReport.companyId,
        () => {
          if (deckmanWiseReport.companyId !== "") {
            getCompanyInformation();
          }
        }
    );
    watch(
        () => deckmanWiseReport.shipId,
        (value) => {
          deckmanWiseReport.shipId = value;
        }
    );
    const getCompanyInformation = () => {
      showLoader();
      store
          .dispatch("getCompanyInfo", {
            companyId: deckmanWiseReport.companyId,
          })
          .then(() => hideLoader())
          .catch(() => hideLoader());
    };
    const getAllCompanyList = () => {
      showLoader();
      store
        .dispatch("getAllCompanyList")
        .then(() => hideLoader())
        .catch(() => hideLoader());
    };
    const getAllAgentList = () => {
      showLoader();
      store
        .dispatch("getDeckmanWiseReport", deckmanWiseReport)
        .then((response) => {
          if (response.length === 0) {
            summary.value = false;
          } else {
            summary.value = true;
            deckTicketTotal.value = 0;
            deckAmountTotal.value = 0;
            goodsTicketTotal.value = 0;
            goodsAmountTotal.value = 0;
            sumListData();
          }
          hideLoader();
        })
        .catch(() => hideLoader());
    };
    const filterReport = () => {
      filterActive.value = true;
      getAllAgentList();
    };
    const sumListData = () => {
      store.getters.getDeckmanWiseReport.forEach((data) => {
        deckTicketTotal.value += data.deckTicket;
        deckAmountTotal.value += data.deckAmount;
        goodsTicketTotal.value += data.goodsTicket;
        goodsAmountTotal.value += data.goodsAmount;
      });
    };
    onBeforeMount(() => {
      getAllCompanyList();
      deckmanWiseReport.fromDate = fromDate;
      deckmanWiseReport.toDate = toDate;
    });
    const seatDetails = (counterManId) => {
      counterManIdPass.value = counterManId;
      deckDetailsActive.value = false;
      reportActive.value = false;
      seatDetailsActive.value = true;
    };
    const onChildClick = (value) => {
      reportActive.value = value;
      deckDetailsActive.value = !value;
      seatDetailsActive.value = !value;
      filterReport();
    };
    return {
      dropdownVariables,
      date,
      deckmanWiseReport,
      filterReport,
      deckDetailsActive,
      seatDetailsActive,
      seatDetails,
      reportActive,
      counterManIdPass,
      filterActive,
      onChildClick,
      deckTicketTotal,
      deckAmountTotal,
      goodsTicketTotal,
      goodsAmountTotal,
      exportExcel,
      summary,
      isDiscount,
    };
  },
};
</script>

<style scoped></style>
