<template>
  <div>
    <FilterTab>
      <template v-slot:body>
        <div class="col-sm-6 col-md-2">
          <DropdownFilter
              v-bind:id="'ticketType'"
              v-model="ticketTypeForFilter"
              v-bind:defaultOption="'--select all--'"
              v-bind:defaultValue="''"
              v-bind:dropdown="ticketTypesForFilter"
              v-bind:label="'Ticket Type'"
          />
        </div>
        <div class="col-sm-6 col-md-2">
          <DropdownFilter
              v-bind:id="'deckType'"
              v-model="deckTypeForFilter"
              v-bind:defaultOption="'--select all--'"
              v-bind:defaultValue="''"
              v-bind:dropdown="deckTypesForFilter"
              v-bind:label="'Deck Type'"
          />
        </div>
        <div class="col-sm-6 col-md-2">
          <DropdownFilter
              v-bind:id="'boardingPlace'"
              v-model="boardingPlaceForFilter"
              v-bind:defaultOption="'--select all--'"
              v-bind:defaultValue="''"
              v-bind:dropdown="boardingPlacesForFilter"
              v-bind:label="'Boarding Place'"
          />
        </div>
        <div class="col-sm-6 col-md-2">
          <DropdownFilter
              v-bind:id="'droppingPoint'"
              v-model="droppingPointForFilter"
              v-bind:defaultOption="'--select all--'"
              v-bind:defaultValue="''"
              v-bind:dropdown="droppingPointsForFilter"
              v-bind:label="'Dropping Point'"
          />
        </div>
        <div class="col-sm-6 col-md-2">
          <DropdownFilter
              v-bind:id="'fareAmount'"
              v-model="fareAmountForFilter"
              v-bind:defaultOption="'--select all--'"
              v-bind:defaultValue="''"
              v-bind:dropdown="fareAmountsForFilter"
              v-bind:label="'Fare Amount'"
          />
        </div>
      </template>
      <template v-slot:button2>
        <button class="edit_table_data font_detail" @click="goBack">
          <i class="fas fa-arrow-left"></i
          ><span class="mx-1 bold">Go Back</span>
        </button>
      </template>
      <template v-slot:button1>
        <button
            class="edit_table_data font_detail"
            @click="exportExcel('xlsx')"
        >
          <i class="fas fa-download"></i><span class="mx-1 bold">Export</span>
        </button>
      </template>
    </FilterTab>
  </div>
  {{ticketTypeForFilter}}

  <Table
      v-if="
      deckmanWiseReport.deckmanId !== '' &&
      $store.getters.getDeckmanWiseReportDetails.length > 0
    "
      id="exportable_data"
  >
    <template v-slot:header>
      <tr>
        <td class="font_detail">SL</td>
        <td class="font_detail">Ticket Date Time</td>
        <td class="font_detail">Ticket Serial</td>
        <td class="font_detail">Trip Code</td>

        <td class="font_detail">Ticket Type</td>
        <td class="font_detail">Goods Type</td>
        <td class="font_detail">Boarding Place</td>
        <td class="font_detail">Dropping Point</td>
        <td class="font_detail">Fare</td>
        <td class="font_detail">Deck Type</td>
        <td class="font_detail">Fare Update Cause</td>
        <td
            v-if="hasPermission(Admin.cancelDeckTicket) && statusProp == 1"
            class="font_detail"
        >
          Actions
        </td>
      </tr>
    </template>
    <template v-slot:body>
      <tr v-for="(item, index) in filteredDeckDetails" :key="item._id">
        <td class="font_detail">{{ index + 1 }}</td>
        <td class="font_detail">{{ dateFormat(item.ticketDateTime) }}</td>
        <td class="font_detail">{{ item.serial }}</td>
        <td class="font_detail">{{ item.tripCode }}</td>
        <td class="font_detail">{{ item.ticketType }}</td>
        <td class="font_detail">{{ item.goodsType || "N/A" }}</td>
        <td class="font_detail">{{ item.boardingPlace || "N/A" }}</td>
        <td class="font_detail">{{ item.droppingPoint || "N/A" }}</td>
        <td class="font_detail">{{ item.payable }} Taka</td>
        <td class="font_detail">{{ item.deckType || "N/A" }}</td>
        <td class="font_detail">
          {{ item.fareUpdateCause || "N/A" }}
        </td>
        <td
            v-if="hasPermission(Admin.cancelDeckTicket) && statusProp == 1"
            class="font_detail"
        >
          <button
              class="delete_table_data font_detail delete_table_button"
              @click="cancelTicket(item._id)"
          >
            <i class="fas fa-trash"></i><span class="mx-1">Cancel</span>
          </button>
        </td>
      </tr>
      <tr v-if="summary" class="bg-dark text-white">
        <td class="font_detail"></td>
        <td class="font_detail"></td>
        <td class="font_detail"></td>
        <td class="font_detail"></td>
        <td class="font_detail"></td>
        <td class="font_detail"></td>
        <td class="font_detail"></td>
        <td class="font_detail"></td>
        <td class="font_detail text-white">
          Total Fare:
          {{ totalPayable }} Taka
        </td>
        <td class="font_detail"></td>
        <td class="font_detail"></td>
        <td
            v-if="hasPermission(Admin.cancelDeckTicket) && statusProp == 1"
            class="font_detail"
        ></td>
      </tr>
    </template>
    <!-- <template v-slot:pagination>
      <pagination
        v-model="deckmanWiseReport.page"
        :records="$store.getters.getTotalDeckmanWiseReportDetails"
        :per-page="deckmanWiseReport.limit"
        @paginate="getDeckmanDetails"
        :options="options"
      />
    </template> -->
  </Table>
  <CancelTicketModal
      v-if="ticketModal"
      v-model="ticketModal"
      :tecketId="ticketId"
      @getAllTicketReport="getAllTicketReport"
  />
</template>

<script>
import {reactive} from "@vue/reactivity";
import {inject, onBeforeMount, ref, watch} from "vue";
import store from "@/store";
import moment from "moment";
import XLSX from "xlsx";
import roles from "@/modules/roles";
import permission from "@/modules/permission";
import CancelTicketModal from "./CancelTicketModal.vue";

const {Admin} = roles();
const {hasPermission} = permission();
export default {
  name: "DeckmanDetails",
  components: {CancelTicketModal},
  props: {
    deckmanId: String,
    fromDate: String,
    toDate: String,
    status: Number,
    tripCode: [Number, String],
    direction: String,
  },
  emits: ["backToDeckmanWiseReport"],
  setup(props, {emit}) {
    let deckmanId = ref(props.deckmanId);
    let fromDate = ref(props.fromDate);
    let toDate = ref(props.toDate);
    let direction = ref(props.direction);
    let tripCode = ref(props.tripCode);
    let statusProp = ref(props.status);
    let totalPayable = ref(0);
    let ticketId = ref("");
    let summary = ref(false);
    let ticketModal = ref(false);
    let boardingPlaceForFilter = ref("");
    let droppingPointForFilter = ref("");
    let fareAmountForFilter = ref("");
    let fareAmountsForFilter = ref({});
    let ticketTypeForFilter = ref("");
    let deckTypeForFilter = ref("");
    let boardingPlacesForFilter = ref({});
    let droppingPointsForFilter = ref({});
    let ticketTypesForFilter = ref({});
    let deckTypesForFilter = ref({});
    let filteredDeckDetails = ref([]);
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    let deckmanWiseReport = reactive({
      deckmanId: deckmanId.value,
      fromDate: fromDate.value,
      toDate: toDate.value,
      direction: direction.value,
      status: statusProp.value,
      tripCode: tripCode.value,
      page: 1,
      limit: 10,
    });
    const options = {
      texts: {
        count: "",
      },
    };


    watch(
        [
          boardingPlaceForFilter,
          droppingPointForFilter,
          ticketTypeForFilter,
          deckTypeForFilter,
          fareAmountForFilter,
        ],
        ([bPlace, dPoint, ticketType, deckType, fare]) => {
          const fareAmount = parseInt(fare);
          let reportData = store.getters.getDeckmanWiseReportDetails
          filteredDeckDetails.value = reportData.filter(
              (item) => {
                let result = true
                bPlace && (result &&= item.boardingPlace === bPlace)
                dPoint && (result &&= item.droppingPoint === dPoint)
                ticketType && (result &&= item.ticketType === ticketType)
                deckType && (result &&= item.deckType === deckType)
                fareAmount && (result &&= item.payable === fareAmount)
                return result
              }
          );
          calculateTotal();
        }
    );
    const dateFormat = (date) => {
      return moment(String(date))
          .utcOffset(0, false)
          .format("DD-MMM-yyyy hh:mm A");
    };
    const cancelTicket = (id) => {
      ticketModal.value = true;
      ticketId.value = id;
    };

    const getAllTicketReport = () => {
      console.log(ticketTypeForFilter.value)

      boardingPlaceForFilter.value = ""
      droppingPointForFilter.value = ""
      ticketTypeForFilter.value = ""
      deckTypeForFilter.value = ""
      fareAmountForFilter.value = ""
      getDeckmanDetails();
      console.log(ticketTypeForFilter.value)
    }

    const getDeckmanDetails = () => {
      showLoader();
      store
          .dispatch("getDeckmanWiseReportDetails", deckmanWiseReport)
          .then((response) => {
            if (response.length === 0) {
              summary.value = false;
            } else {
              summary.value = true;
              seatDetailsdata();
            }
            hideLoader();
          })
          .catch(() => hideLoader());
    };

    const seatDetailsdata = () => {
      filteredDeckDetails.value = store.getters.getDeckmanWiseReportDetails;
      initFilterDropdown();
      calculateTotal();
    };
    const initFilterDropdown = () => {
      filteredDeckDetails.value.forEach((data) => {
        if (data.boardingPlace) {
          boardingPlacesForFilter.value[data.boardingPlace] =
              data.boardingPlace;
        }
        if (data.droppingPoint) {
          droppingPointsForFilter.value[data.droppingPoint] =
              data.droppingPoint;
        }
        ticketTypesForFilter.value[data.ticketType] = data.ticketType;
        deckTypesForFilter.value[data.deckType] = data.deckType;
        fareAmountsForFilter.value[data.payable] = data.payable;
      });
    };
    const calculateTotal = () => {
      totalPayable.value = 0;
      filteredDeckDetails.value.forEach((item) => {
        totalPayable.value += item.payable;
      });
    };
    const goBack = () => {
      emit("backToDeckmanWiseReport", true);
    };
    const exportExcel = (type, fn, dl) => {
      if (document.getElementById("exportable_data") !== null) {
        var elt = document.getElementById("exportable_data");
        var wb = XLSX.utils.table_to_book(elt, {sheet: "sheet Js"});
        return dl
            ? XLSX.write(wb, {bookType: type, bookSST: true, type: "base64"})
            : XLSX.writeFile(
                wb,
                fn || "Admin_DeckmanWise_Details_Report." + (type || "xlsx")
            );
      }
    };
    onBeforeMount(() => {
      getDeckmanDetails();
    });
    return {
      deckmanWiseReport,
      getDeckmanDetails,
      goBack,
      summary,
      dateFormat,
      totalPayable,
      exportExcel,
      options,
      Admin,
      hasPermission,
      cancelTicket,
      ticketId,
      ticketModal,
      statusProp,
      boardingPlaceForFilter,
      boardingPlacesForFilter,
      droppingPointForFilter,
      fareAmountForFilter,
      fareAmountsForFilter,
      droppingPointsForFilter,
      ticketTypeForFilter,
      deckTypeForFilter,
      ticketTypesForFilter,
      deckTypesForFilter,
      filteredDeckDetails,
      getAllTicketReport,
    };
  },
};
</script>
