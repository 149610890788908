<template>
    <Modal @close="closeModal" v-bind:size="'xl'">
        <template v-slot:title>{{ title }}</template>
        <template v-slot:body>
            <form>
                <div class="auth_form py-3">
                    <div class="row my-2">
                        <div class="col-md-3">
                            <Input v-model="agentData.name"
                                   v-bind:placeholder="'Enter Agent Name'"
                                   v-bind:label="'Name'" v-bind:id="'name'" v-bind:type="'text'"/>
                            <Error v-if="errors.name" v-bind:error="errors.name"/>
                        </div>
                        <div class="col-md-3">
                            <Input v-model="agentData.phone"
                                   v-bind:placeholder="'Enter Phone Number'"
                                   v-bind:label="'Phone'" v-bind:id="'phone'" v-bind:type="'text'"/>
                            <Error v-if="errors.phone" v-bind:error="errors.phone"/>
                        </div>
                        <div class="col-md-3">
                            <Input v-model="agentData.password"
                                   v-bind:placeholder="'Enter Password'"
                                   autocomplete="off"
                                   v-bind:label="'Password'" v-bind:id="'password'" v-bind:type="fieldTypes.password"/>
                            <Error v-if="errors.password" v-bind:error="errors.password"/>
                        </div>
                        <div class="col-md-3">
                            <Input v-model="agentData.email"
                                   v-bind:placeholder="'Email Address'"
                                   autocomplete="off"
                                   v-bind:label="'Email'" v-bind:id="'email'" v-bind:type="fieldTypes.email"/>
                        </div>
                    </div>
                    <div class="row my-2">
                        <div class="col-md-6">
                            <label for="address" class="form-label all_permission_label">Address</label>
                            <div>
                                <textarea class="form-control font_detail" id="address"
                                          v-model="agentData.address"></textarea>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <Input v-model="agentData.nid"
                                   v-bind:placeholder="'NID Number'"
                                   v-bind:label="'NID'" v-bind:id="'nid'" v-bind:type="'text'"/>
                        </div>
                        <div class="col-md-3">
                            <Dropdown v-if="status" v-model="agentData.status" v-bind:id="'status'"
                                      v-bind:label="'Status'"
                                      v-bind:dropdown="status"/>
                        </div>
                    </div>
                </div>

                <hr class="section_devider">

                <div class="role_saection">
                    <div class="row">
                        <div class="d-flex justify-content-between align-items-center">
                            <h5><b>Roles</b></h5>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input all_permission_label" type="checkbox" id="select-all"
                                       name="select-all" @click="giveAllPermission" v-model="allSelected">
                                <label class="form-check-label all_permission_label" for="select-all">Give All
                                    Permission</label>
                            </div>
                        </div>
                    </div>
                    <span v-if="errors.roles" class="text-danger err_msg">{{ errors.roles }}</span>
                    <span v-else-if="errors[Agent.agentFareDiscount]"
                          class="text-danger err_msg">{{ errors[Agent.agentFareDiscount] }}</span>
                    <span v-else-if="errors[Agent.agentFares]"
                          class="text-danger err_msg">{{ errors[Agent.agentFares] }}</span>
                    <div class="assigned_role">
                        <div class="row">
                            <div class="col-6 col-sm-4 col-md-3">
                                <div class="role_select_area">
                                    <h5>Ticket Booking</h5>
                                    <Checkbox v-model="checked" v-bind:label="'Yes'"
                                              v-bind:role="Agent.agentTicketBooking"
                                              :key="allSelected" @toggleAllPermission="toggleAllPermission"/>
                                </div>
                            </div>
                            <div class="col-6 col-sm-4 col-md-3">
                                <div class="role_select_area">
                                    <h5>Fare Discount</h5>
                                    <Checkbox v-model="checked" v-bind:label="'Yes'"
                                              v-bind:role="Agent.agentFareDiscount"
                                              :key="allSelected"
                                              @toggleAllPermission="toggleAllPermission"/>
                                </div>
                            </div>
                            <div class="col-6 col-sm-4 col-md-3">
                                <div class="role_select_area">
                                    <h5>Report</h5>
                                    <Checkbox v-model="checked" v-bind:label="'Yes'" v-bind:role="Agent.agentReports"
                                              :key="allSelected"
                                              @toggleAllPermission="toggleAllPermission"/>
                                </div>
                            </div>
                            <div class="col-6 col-sm-4 col-md-3">
                                <div class="role_select_area">
                                    <h5>Trip</h5>
                                    <Checkbox v-model="checked" v-bind:label="'Yes'" v-bind:role="Agent.agentTrips"
                                              :key="allSelected"
                                              @toggleAllPermission="toggleAllPermission"/>
                                </div>
                            </div>
                            <div class="col-6 col-sm-4 col-md-3">
                                <div class="role_select_area">
                                    <h5>Ship</h5>
                                    <Checkbox v-model="checked" v-bind:label="'Yes'" v-bind:role="Agent.agentShips"
                                              :key="allSelected"
                                              @toggleAllPermission="toggleAllPermission"/>
                                </div>
                            </div>
                            <div class="col-6 col-sm-4 col-md-3">
                                <div class="role_select_area">
                                    <h5>Fare</h5>
                                    <Checkbox v-model="checked" v-bind:label="'Yes'" v-bind:role="Agent.agentFares"
                                              :key="allSelected"
                                              @toggleAllPermission="toggleAllPermission"/>
                                </div>
                            </div>
                            <!-- <div class="col-6 col-sm-4 col-md-3">
                                <div class="role_select_area">
                                    <h5>Ticket Cancel</h5>
                                    <Checkbox v-model="checked" v-bind:label="'Yes'"
                                              v-bind:role="Agent.agentTicketCancel"
                                              :key="allSelected"
                                              @toggleAllPermission="toggleAllPermission"/>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="form-group row my-1">
                    <label class="col-sm-3 col-form-label"></label>
                    <div class="col-sm-9">
                        <input v-if="!agentForUpdate" type="submit"
                               class="mt-3 py-2 submit_button font_detail float-end"
                               @click.prevent="addAgentOwner"/>
                        <input v-else type="submit" class="mt-3 py-2 submit_button font_detail float-end"
                               @click.prevent="updateAgentOwner"/>
                    </div>
                </div>
            </form>
        </template>
    </Modal>
</template>

<script>
import {reactive} from "@vue/reactivity";
import dropdownVariables from "@/modules/dropdownVariables";
import useInputValidation from "@/modules/useInputValidations";
import {inject, onBeforeMount, ref, watch} from "vue";
import roles from "@/modules/roles";
import store from "@/store";

export default {
    name: "CountermanAddUpdateModal",
    props: {
        modelValue: Boolean,
        companyId: String,
        agentId: String,
    },
    setup(props, {emit}) {
        let agentData = reactive({
            id: "",
            name: "",
            designation: "",
            phone: "",
            email: "",
            password: "",
            nid: "",
            address: "",
            avatar: "",
            transactionType: "",
            balance: 0,
            commission: "",
            commissionType: "",
            userType: "agent",
            printerType: "",
            companyId: "",
            counterId: "",
            roles: "",
            status: "1",
            api_token: "",
            device_token: "",
            reset_token: ""
        });
        let agentForUpdate = ref(props.agentId);
        let companyId = ref(props.companyId);
        let checked = ref([]);
        let allSelected = ref(false);
        let {requiredValidation, passwordValidation, phoneValidation,requiredRoleValidation, errors} = useInputValidation();
        const {Agent} = roles();
        const status = dropdownVariables.status;
        const title = ref("Add Agent");
        const showLoader = inject('showLoader');
        const hideLoader = inject('hideLoader');

        let fieldTypes = reactive({
            email: 'text',
            password: 'text',
        })

        watch(() => agentData.name, () => {
            requiredValidation("name", agentData.name);
        });
        watch(() => agentData.phone, () => {
            phoneValidation("phone", agentData.phone);
        });
        watch(() => agentData.roles, () => {
            requiredValidation("roles", agentData.roles);
        });
        watch(() => agentData.password, () => {
            passwordValidation("password", agentData.password);
        });

        watch(() => agentData.password, () => {
            fieldTypes.password = 'password'
        });
        watch(() => agentData.email, () => {
            fieldTypes.email = 'email'
        });

        const giveAllPermission = () => {
            checked.value = [];
            if (!allSelected.value) {
                checked.value = Object.values(Agent);
            }
        };

        const closeModal = () => {
            emit("update:modelValue", false);
        };
        const toggleAllPermission = () => {
            allSelected.value = checked.value.length === Object.values(Agent).length;
        };
        const validate = () => {
            Object.keys(errors).forEach(function (key) {
                errors[key] = ""
            });
            requiredValidation("name", agentData.name);
            phoneValidation("phone", agentData.phone);
            requiredValidation("roles", agentData.roles);
            requiredRoleValidation(Agent.agentFareDiscount.toString(), checked.value, Agent.agentTicketBooking, Agent.agentFareDiscount, Agent)
            requiredRoleValidation(Agent.agentFares.toString(), checked.value, Agent.agentTicketBooking, Agent.agentFares, Agent)
            if (agentForUpdate.value === "") {
                passwordValidation("password", agentData.password);
            } else {
                if (agentData.password !== "") {
                    passwordValidation("password", agentData.password);
                }
            }
            return !(Object.values(errors).length <= 0 || !Object.values(errors).every(x => x === null || x === ''));
        };
        const addAgentOwner = () => {
            agentData.roles = checked.value.sort().join(',');
            if (validate()) {
                showLoader()
                store.dispatch("addAgentOwner", agentData).then(() => {
                    hideLoader()
                    emit("getAllAgents");
                    closeModal();
                }).catch((error) => {
                    hideLoader()
                    if (error.status === 401) {
                        addAgentOwner()
                    }
                });
            }
        };
        const updateAgentOwner = () => {
            agentData.roles = checked.value.sort().join(',');
            if (validate()) {
                showLoader()
                store.dispatch("updateAgentOwner", agentData).then(() => {
                    emit("getAllAgents");
                    closeModal();
                }).catch((error) => {
                    hideLoader()
                    if (error.status === 401) {
                        updateAgentOwner()
                    }
                });
            }
        };

        onBeforeMount(() => {
            Object.keys(errors).forEach(function (key) {
                delete errors[key];
            });
            agentData.companyId = companyId.value;
            if (agentForUpdate.value !== "") {
                const agentDetails = store.getters.getAgentOwner(agentForUpdate.value)
                title.value = "Update Agent"
                agentData.id = agentDetails[0]._id;
                agentData.companyId = agentDetails[0].companyId;
                agentData.name = agentDetails[0].name;
                agentData.phone = agentDetails[0].phone;
                agentData.email = agentDetails[0].email;
                agentData.nid = agentDetails[0].nid;
                agentData.address = agentDetails[0].address;
                agentData.status = agentDetails[0].status ? 1 : 0;
                agentDetails[0].roles[0].split(',').forEach(element => checked.value.push(parseInt(element)));
                Object.keys(Agent).length === checked.value.length ? allSelected.value = true : allSelected.value = false;
            }
        });

        return {
            status, agentData, errors, Agent, title, checked, allSelected, agentForUpdate, fieldTypes,
            giveAllPermission, toggleAllPermission, updateAgentOwner, addAgentOwner, closeModal,
        };
    }
};
</script>

<style scoped>

</style>