import tokenCookie from "@/modules/tokenCookie";
import validationMessage from "@/modules/ValidationMessage";
import router from "@/router";
import web from "@/urls/web";


const {removeToken} = tokenCookie();
const {messageExtractor} = validationMessage();

export default function apiResponse() {
    const successResponse = (success) => {
        if (success.status === 200) {
            messageExtractor(success.data,success.status)
            return success.data.message;
        }
    }
    const errorResponse = (error) =>{
        if (error.response.status === 400) {
            messageExtractor(error.response.data,error.response.status)
            return error.response;
        }
        if (error.response.status === 401) {
            removeToken()
            messageExtractor(error.response.data,error.response.status)
            return error.response;
        }
        if (error.response.status === 402) {
            messageExtractor(error.response.data,error.response.status)
            return error.response;
        }
        if(error.response.status === 403){
            messageExtractor(error.response.data,error.response.status)
            router.push(web.Default.LOGIN_URL.path).catch(error => (console.log(error)))
            return error.response
        }
        if(error.response.status === 504){
            messageExtractor(error.response.data,error.response.status)
            return error.response
        }
    }
    return {
        successResponse,errorResponse
    };

}