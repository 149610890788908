import apiResponse from "@/modules/apiResponse";
import tokenCookie from "@/modules/tokenCookie";
import Apis from "@/urls/apis";
import axios from "axios";


const {getToken} = tokenCookie();
const {errorResponse} = apiResponse();

const state = {
    dashboardTicketsOwner:[],
    dashboardDeckDetailsOwner: [],
    dashboardTicketsDetailsOwner: []
};
const getters = {
    getDashboardTicketsOwner: (state) => state.dashboardTicketsOwner,  
    getDashboardDeckDetailsOwner: (state) => state.dashboardDeckDetailsOwner,
    getDashboardTicketsDetailsOwner: (state) => state.dashboardTicketsDetailsOwner
};
const actions = {

    async getDashboardTicketsOwner({commit}, payload) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.get(Apis.GET_ALL_DASHBOARD_TICKETS_OWNER, {
                    params: payload,
                    headers: header
                })
                    .then(({data, status}) => {
                        if (status === 200) {
                            commit("setDashboardTicketsOwner", data.summary);
                            commit("setDashboardDeckDetailsOwner", data.deckDetails);
                            commit("setDashboardTicketsDetailsOwner", data.seatDetails);
                            resolve({'success': 'success'});
                        }
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
    },

   


};
const mutations = {
    setDashboardTicketsOwner: (state, tickets) => (state.dashboardTicketsOwner = tickets),
    setDashboardDeckDetailsOwner: (state, deckDetails) => (state.dashboardDeckDetailsOwner = deckDetails),
    setDashboardTicketsDetailsOwner: (state, seatDetails) => (state.dashboardTicketsDetailsOwner = seatDetails),
};

export default {
    state,
    getters,
    actions,
    mutations
};