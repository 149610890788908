<template>
  <!--  <div class="card">-->
  <div>
    <div v-if="companyDeployTabs">
      <ul
        class="nav nav-tabs justify-content-center nav-style"
        id="myTab"
        role="tablist"
      >
        <li
          v-for="(tab, index) in companyDeployTabs"
          :key="index"
          class="nav-item"
          role="presentation"
        >
          <button
            class="nav-link"
            :id="tab.name + '-tab'"
            data-bs-toggle="tab"
            v-bind:class="
              tab.name === activeTab
                ? 'active active-button'
                : 'inactive-button'
            "
            type="button"
            @click="setActive(tab.name)"
            role="tab"
            :aria-controls="tab.path"
            aria-selected="true"
          >
            {{ tab.title }}
          </button>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div
          v-for="(tab, index) in companyDeployTabs"
          :key="index"
          class="tab-pane fade"
          v-bind:class="tab.name === activeTab ? 'show active' : ''"
          :id="tab.path"
          role="tabpanel"
          :aria-labelledby="tab.name + '-tab'"
        ></div>
        <Stoppage v-if="activeTab === companyDeployTabs.STOPPAGE_URL.name" />
        <Route v-if="activeTab === companyDeployTabs.ROUTE_URL.name" />
        <Counter v-if="activeTab === companyDeployTabs.COUNTER_URL.name" />
        <Ship v-if="activeTab === companyDeployTabs.SHIPS_URL.name" />
        <Trips v-if="activeTab === companyDeployTabs.TRIPS_URL.name"/>
        <Agent v-if="activeTab === companyDeployTabs.AGENT_URL.name" />
        <CountermanSeperate
          v-if="activeTab === companyDeployTabs.COUNTERMAN_URL.name"
        />
        <Supervisor
          v-if="activeTab === companyDeployTabs.SUPERVISOR_URL.name"
        />
        <Deckman v-if="activeTab === companyDeployTabs.DECKMAN_URL.name" />
        <CabinMan v-if="activeTab === companyDeployTabs.CABINMAN_URL.name" />
      </div>
    </div>
  </div>
</template>

<script>
import web from "@/urls/web";
import Stoppage from "./Children/Stoppage/Stoppage.vue";
import Route from "./Children/Routes/Routes";
import { onBeforeMount, onBeforeUnmount, ref, inject } from "vue";
import store from "@/store";
import Counter from "./Children/Counter/Counter";
import Ship from "./Children/Ships/Ships";
import Trips from "./Children/Trips/Trips";
import CountermanSeperate from "./Children/counterman/CountermanSeperate.vue";
import Supervisor from "./Children/Supervisor/Supervisor.vue";
import Deckman from "./Children/Deckman/Deckman.vue";
import Agent from "./Children/Agent/Agent.vue";
import CabinMan from "./Children/Cabinman/Cabinman";

export default {
  name: "CompanyManage",
  components: {
    Route,
    Stoppage,
    Counter,
    Ship,
    Trips,
    Agent,
    Deckman,
    CountermanSeperate,
    Supervisor,
    CabinMan,
  },
  setup() {
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    let activeTab = ref("");
    const companyDeployTabs = web.Owner.SERVICES_URL.children;

    const setActive = (index) => {
      activeTab.value = index;
      store.commit("setActiveTab", activeTab.value);
    };
    
    const getCompanyInformation = () => {
      showLoader();
      store
        .dispatch("getCompanyInfoOwner", {
          companyId: store.getters.userCompany,
        })
        .then((data) => {
          store.commit("setOwnerCompanyTag", data.company.tag)
          hideLoader()
        })
        .catch(() => hideLoader());
    };

    onBeforeMount(() => {
      getCompanyInformation();
      activeTab.value = store.getters.getActiveTab
        ? store.getters.getActiveTab
        : companyDeployTabs.STOPPAGE_URL.name;
    });
    onBeforeUnmount(() => {
      if (store.getters.isLoggedIn) {
        store.commit("setActiveTab", "");
      }
    });

    return {
      companyDeployTabs,
      activeTab,
      setActive,
    };
  },
};
</script>

<style scoped>
/*.card {*/
/*  padding: 20px 30px;*/
/*}*/

.nav-style {
  background: #3b3b3b;
  border-top: 1px;
}

.active-button {
  background: #242424 !important;
  border: 1px solid #242424 !important;
  color: #fff !important;
}

.inactive-button {
  color: #fff !important;
}
</style>