<template>
  <div >
    <FilterTab>
      <template v-slot:body>

        <div class="col-sm-6 col-md-2">
          <DropdownFilter
              v-bind:id="'boardingPlace'"
              v-bind:label="'Boarding Place'"
              v-bind:defaultOption="'--select boarding place--'"
              v-model="boardingPlaceForFilter"
              v-bind:defaultValue="''"
              v-bind:dropdown="boardingPlacesForFilter"
          />
        </div>
        <div class="col-sm-6 col-md-2">
          <DropdownFilter
              v-bind:id="'droppingPoint'"
              v-bind:label="'Dropping Point'"
              v-bind:defaultOption="'--select dropping point--'"
              v-model="droppingPointForFilter"
              v-bind:defaultValue="''"
              v-bind:dropdown="droppingPointsForFilter"
          />
        </div>
        <div class="col-sm-6 col-md-2">
          <DropdownFilter
              v-bind:id="'deckType'"
              v-bind:label="'Deck Type Filter'"
              v-bind:defaultOption="'--select deck type--'"
              v-model="deckTypeForFilter"
              v-bind:defaultValue="''"
              v-bind:dropdown="deckTypesForFilter"
          />
        </div>

        <div class="col-sm-6 col-md-2">
          <DropdownFilter
              v-bind:id="'amount'"
              v-bind:label="'Fare Amount'"
              v-bind:defaultOption="'--select Amount--'"
              v-model="fareAmountForFilter"
              v-bind:defaultValue="''"
              v-bind:dropdown="fareAmountsForFilter"
          />
        </div>

      </template>
      <template v-slot:button2>
        <button class="edit_table_data font_detail" @click="goBack">
          <i class="fas fa-arrow-left"></i
          ><span class="mx-1 bold">Go Back</span>
        </button>
      </template>
      <template v-slot:button1>
        <button
            class="edit_table_data font_detail"
            @click="exportExcel('xlsx')"
        >
          <i class="fas fa-download"></i><span class="mx-1 bold">Export</span>
        </button>
      </template>
    </FilterTab>
  </div>
  <Table id="exportable_data">
    <template v-slot:header>
      <tr>
        <td class="font_detail">SL</td>

        <td class="font_detail">Ticket Date Time</td>
        <td class="font_detail">Ticket Serial</td>
        <td class="font_detail">Ship Name</td>
        <td class="font_detail">Boarding Place</td>
        <td class="font_detail">Dropping Point</td>
        <td class="font_detail">Amount</td>
        <td class="font_detail">Issued By</td>
        <td class="font_detail">Deck Type</td>
        <td class="font_detail">Fare Update Cause</td>
      </tr>
    </template>
    <template v-slot:body>
      <tr
          v-for="(item, index) in filteredDeckDetails"
          :key="index"
      >
        <td class="font_detail">{{ index + 1 }}</td>

        <td class="font_detail">{{ dateFormat(item.ticketDateTime) }}</td>
        <td class="font_detail">{{ item.serial }}</td>

        <td class="font_detail">{{ item.shipName }}</td>
        <td class="font_detail">
          {{ item.boardingPlace || 'N/A'}}
        </td>
        <td class="font_detail">
          {{ item.droppingPoint || 'N/A' }}
        </td>
        <td class="font_detail">{{ item.totalAmount }}</td>
        <td class="font_detail">{{ item.issuedBy }}</td>
        <td class="font_detail">{{ item.deckType || 'N/A' }}</td>
        <td class="font_detail">{{ item.fareUpdateCause || 'N/A' }}</td>
      </tr>
      <tr class="bg-dark text-white">
        <td class="font_detail"></td>
        <td class="font_detail"></td>
        <td class="font_detail"></td>
        <td class="font_detail"></td>
        <td class="font_detail"></td>
        <td class="font_detail"></td>
        <td class="font_detail text-white">
          Total Amount:
          {{ totalAmount }} Taka
        </td>
        <td class="font_detail"></td>
        <td class="font_detail"></td>
        <td class="font_detail"></td>
      </tr>
    </template>
    <!-- <template v-slot:pagination>
      <pagination
        v-model="shipWiseDeckReport.page"
        :records="$store.getters.getTotalShipWiseDeckDetails"
        :per-page="shipWiseDeckReport.limit"
        @paginate="getDeckDetails"
        :options="options"
      />
    </template> -->
  </Table>
</template>


<script>
import {reactive} from "@vue/reactivity";
import {ref, onBeforeMount, inject, watch} from "vue";
import store from "@/store";
import XLSX from "xlsx";
import moment from "moment";

export default {
  name: "DeckDetails",
  components: {},
  props: {
    shipId: String,
    shipName: String,
    status: Number,
    discount: [Number, String],
    fromDate: String,
    toDate: String,
    direction: String,
  },
  emits: ["backToShipWiseReport"],
  setup(props, {emit}) {
    let shipId = ref(props.shipId);
    let shipNameProps = ref(props.shipName);
    let totalAmount = ref(0);
    let totalTicket = ref(0);
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader")
    const boardingPlaceForFilter = ref("")
    const droppingPointForFilter = ref("")
    const deckTypeForFilter = ref("")
    const fareAmountForFilter = ref("")
    const boardingPlacesForFilter = reactive({})
    const droppingPointsForFilter = reactive({})
    const deckTypesForFilter = reactive({})
    const fareAmountsForFilter = reactive({})
    let filteredDeckDetails = ref([])
    let shipWiseDeckReport = reactive({
      ticketType: "DECK",
      shipId: shipId.value,
      fromDate: props.fromDate,
      toDate: props.toDate,
      direction: props.direction,
      discount: props.discount,
      status: props.status,
      page: 1,
      limit: 10,
    });

    const options = {
      texts: {
        count: "",
      },
    };
    watch(
      [boardingPlaceForFilter, droppingPointForFilter, deckTypeForFilter, fareAmountForFilter], 
      ([bPlace, dPoint, deckType, amount]) => {
      const fAmount = parseInt(amount);
      let reportData = store.getters.getShipWiseDeckDetails
        filteredDeckDetails.value = reportData.filter(
          (item) => {
            let result = true
            bPlace && (result &&= item.boardingPlace === bPlace)
            dPoint && (result &&=  item.droppingPoint === dPoint)
            deckType && (result &&= item.deckType === deckType)
            fAmount && (result &&= item.payable === fAmount)
            return result
          }
        );
        calculateTotal();
    })

    const dateFormat = (date) => {
      return moment(String(date))
          .utcOffset(0, false)
          .format("DD-MMM-yyyy hh:mm A");
    };

    const getDeckDetails = () => {
      showLoader();
      store
          .dispatch("getDeckDetails", shipWiseDeckReport)
          .then(() => {
            hideLoader();
            deckDetailsdata();
          })
          .catch(() => hideLoader());
    };

    const calculateTotal = () => {
      totalAmount.value = 0
      totalTicket.value = 0
      filteredDeckDetails.value.forEach((item) => {
        totalAmount.value += item.totalAmount;
        totalTicket.value += item.totalTicket;
      })
    }

    const deckDetailsdata = () => {
      filteredDeckDetails.value = store.getters.getShipWiseDeckDetails
      store.getters.getShipWiseDeckDetails.forEach((data) => {
        if (data.boardingPlace){
          boardingPlacesForFilter[data.boardingPlace] = data.boardingPlace
        }
        if (data.droppingPoint){
          droppingPointsForFilter[data.droppingPoint] = data.droppingPoint
        }
        if (data.deckType){
          deckTypesForFilter[data.deckType] = data.deckType
        }
        fareAmountsForFilter[data.totalAmount] = data.totalAmount
      });
      calculateTotal()
    };

    const goBack = () => {
      emit("backToShipWiseReport", true);
    };

    const exportExcel = (type, fn, dl) => {
      if (document.getElementById("exportable_data") !== null) {
        var elt = document.getElementById("exportable_data");
        var wb = XLSX.utils.table_to_book(elt, {sheet: "sheet Js"});
        return dl
            ? XLSX.write(wb, {bookType: type, bookSST: true, type: "base64"})
            : XLSX.writeFile(
                wb,
                fn || "Admin_ShipWise_Deck_Report." + (type || "xlsx")
            );
      }
    };

    onBeforeMount(() => {
      getDeckDetails();
    });

    return {
      shipWiseDeckReport,
      totalAmount,
      totalTicket,
      goBack,
      exportExcel,
      shipNameProps,
      getDeckDetails,
      options,
      dateFormat,
      boardingPlaceForFilter,
      boardingPlacesForFilter,
      droppingPointForFilter,
      deckTypeForFilter,
      droppingPointsForFilter,
      deckTypesForFilter,
      filteredDeckDetails,
      fareAmountForFilter,
      fareAmountsForFilter,
    };
  },
};
</script>
