<template>
  <div v-if="reportActive">
    <Filter>
      <template v-slot:body>
        <div class="col-sm-6 col-md-4">
          <DateRangePicker v-model="date.range" />
        </div>
        <div class="col-sm-6 col-md-2">
          <DropdownFilter
              v-bind:id="'direction'"
              v-bind:label="'Direction'"
              v-bind:defaultOption="'--select direction--'"
              v-model="pagination.direction"
              v-bind:defaultValue="''"
              v-bind:dropdown="dropdownVariables.direction"
          />
        </div>
        <div class="col-md-2 col-sm-6 mt-auto">
          <button
              class="edit_table_data font_detail"
              @click="getAllTrips"
          >
            <i class="fas fa-filter"></i><span class="mx-1 bold">Filter</span>
          </button>
        </div>
      </template>
    </Filter>
        <div>
          <Table v-if="$store.getters.getSupervisorTripsReportList">
            <template v-slot:header>
              <tr>
                <td class="font_detail">SL</td>
                <td class="font_detail">TRIP TIME & DATE</td>
                <td class="font_detail">TRIP CODE</td>
                <td class="font_detail">SHIP</td>
                <td class="font_detail">ROUTE</td>
                <td class="font_detail">DIRECTION</td>
                <td class="font_detail">ACTION</td>
              </tr>
            </template>
            <template v-slot:body>
              <tr
                  v-for="(trip, index) in $store.getters.getSupervisorTripsReportList"
                  :key="trip._id"
              >
                <td class="font_detail">{{ pagination.limit*(pagination.page-1) + index + 1 }}</td>

                <td class="font_detail bold">{{ dateFormat(trip.tripDate)}}</td>
                <td class="font_detail">{{ trip.tripCode }}</td>
                <td class="font_detail bold">{{ trip.ship.name }}</td>
                <td class="font_detail">{{ trip.route.name }}</td>
                <td class="font_detail bold">
                  {{ trip.direction }}<br />({{ trip.fromStoppage.name }} -
                  {{ trip.toStoppage.name }})
                </td>
                <td>
                  <button
                      class="btn btn-success m-1 btn-sm"
                      @click="tripDetails(trip._id, trip.ship.name, trip.tripDate, trip.route.name, trip.tripCode, trip.direction )"
                  >
                    Details
                  </button>
                </td>
              </tr>
            </template>
            <template v-slot:pagination>
              <pagination
                  v-model="pagination.page"
                  :records="$store.getters.getSupervisorTotalTripsReportList"
                  :per-page="pagination.limit"
                  @paginate="getAllTrips"
                  :options="options"
              />
            </template>
          </Table>
        </div>
  </div>
  <ReportDetails
      v-if="tripDetailsActive"
      :tripId="tripIdPass"
      :selectedShipName="selectedShipName"
      :routeName="routeName"
      :tripDate="tripDate"
      :tripCode="tripCode"
      :tripDirection="tripDirection"
      v-on:backToReportList="onChildClick"
  />
</template>

<script>
import moment from "moment";
import { reactive } from "@vue/reactivity";
import { inject, onBeforeMount, ref, watch } from "vue";
import store from "@/store";
import dropdownVariables from "@/modules/dropdownVariables";
import permission from "@/modules/permission";
import roles from "@/modules/roles";
import DateRangePicker from "@/components/layouts/Filter/Inputs/DateRangePicker";
import ReportDetails from "@/views/Supervisor/Report/ReportDetails";

const { hasPermission } = permission();
const { Admin } = roles();

export default {
  name: "Trips",
  components: {ReportDetails, DateRangePicker },
  setup() {
    let pagination = reactive({
      page: 1,
      limit: 10,
      fromDate: moment(new Date()).format("yyyy-MM-DD"),
      toDate: moment(new Date()).format("yyyy-MM-DD"),
      direction: ""
    });

    let tripDetailsActive = ref(false);
    let reportActive = ref(true);
    const tripIdPass = ref("");
    const tripCode = ref("");
    const tripDirection = ref("");
    const selectedShipName = ref("");
    const tripDate = ref("");
    const routeName = ref("");

    const fromDate = moment(new Date()).format("yyyy-MM-DD");
    const toDate = moment(new Date()).format("yyyy-MM-DD");

    let date = reactive({
      range: {
        start: fromDate,
        end: toDate,
      },
    });

    watch(
        () => date.range.start,
        (value) => {
          pagination.fromDate = moment(value).format("yyyy-MM-DD");
          resetPagination();
        }
    );
    watch(
        () => date.range.end,
        (value) => {
          pagination.toDate = moment(value).format("yyyy-MM-DD");
          resetPagination();
        }
    );
    watch(
        () => pagination.direction,
        (value) => {
          pagination.direction = value;
          resetPagination();
        }
    );

    const resetPagination =()=>{
      pagination.page = 1;
    };


    const options = {
      texts: {
        count: "",
      },
    };
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");

    const dateFormat = (date) => {
      return moment(String(date))
          .utcOffset(0, false)
          .format("DD-MMM-yyyy hh:mm:ss A");
    };
    const getAllTrips = () => {
      showLoader();
      store
          .dispatch("getSupervisorTripsReportListByPage", pagination)
          .then(() => {
            hideLoader();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              getAllTrips();
            }
          });
    };

    onBeforeMount(() => {
      getAllTrips();
    });

    const tripDetails = (tripId, selectedShip, tDate, route, code, direction) => {
      tripIdPass.value = tripId;
      selectedShipName.value = selectedShip;
      tripDate.value = tDate;
      routeName.value = route;
      tripCode.value = code;
      tripDirection.value = direction;
      tripDetailsActive.value = true;
      reportActive.value = false;
    };

    const onChildClick = (value) => {
      reportActive.value = value;
      tripDetailsActive.value = false;
      reportActive.value = true;
    };

    return {
      options,
      pagination,
      dropdownVariables,
      tripIdPass,
      Admin,
      hasPermission,
      getAllTrips,
      dateFormat,
      date,
      tripDetailsActive,
      tripDetails,
      reportActive,
      onChildClick,
      selectedShipName,
      tripDate,
      routeName,
      tripCode,
      tripDirection
    };
  },
};
</script>

<style scoped>
.bold {
  font-weight: bold;
}
</style>