<template>
    <ul style="margin:0;padding: 0">
        <li v-for="(seatRow,index) in seats.seatList" :key="seats._id+'plan'+index"
            class="list-group-item py-0 border-0 bg-transparent">
            <div class="row my-2">
                <div class="col-md-12">
                    <div class="row">
                        <div v-for="(seat,seatId) in seatRow.split(',')" :key="'seat'+seatId+index" class="col-auto"
                             style="width:50px">
                            <div v-if="blockedSeats.includes(seat)"
                                 class="seat my-1 px-1 py-1 rounded text-center bg-secondary"
                                 style="color:white;border:1px solid #6c757d">
                                <span>{{ seat }}</span>
                            </div>
                            <div v-else-if="alreadyBooked.includes(seat)"
                                 class="seat my-1 px-1 py-1 rounded text-center"
                                 style="background-color:red; color:white;border:1px solid red">
                                <span>{{ seat }}</span>
                            </div>
                            <div v-else-if="selectedSeats.includes(seat)"
                                 class="seat my-1 px-1 py-1 rounded text-center"
                                 style="background-color:darkgreen; color:white;cursor:pointer"
                                 @click="seatSelected(seat)">
                                <span>{{ seat }}</span>
                            </div>
                            <div v-else class="seat my-1 px-1 py-1 rounded text-center" style="cursor:pointer"
                                 @click="seatSelected(seat)">
                                <span>{{ seat }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    </ul>
</template>

<script>
import {ref, watch} from "vue";
// import store from "@/store";
// import {reactive} from "@vue/reactivity";

export default {
    name: "SeatList",
    props: {
        modelValue: Array,
        seatPlan: Object
    },
    setup(props, {emit}) {
        const seats = ref(props.seatPlan);
        const selectedSeats = ref(props.modelValue);
        const alreadyBooked = ref(seats.value.bookedList.split(','));
        const blockedSeats = ref(seats.value.blockedSeats.split(','));

        watch(() => selectedSeats.value, () => {
            emit("update:modelValue", selectedSeats.value)
        });
        watch(() => props.modelValue, (value) => {
            selectedSeats.value = value;
        });
        watch(() => props.seatPlan, (value) => {
            seats.value = value;
            alreadyBooked.value = seats.value.bookedList.split(',');
            blockedSeats.value = seats.value.blockedSeats.split(',');
        });

        const seatSelected = (seat) => {
            if (selectedSeats.value.includes(seat)) {
                selectedSeats.value = selectedSeats.value.filter((alreadySelected) => alreadySelected !== seat)
            } else {
                selectedSeats.value.push(seat)
            }
        };
        return {
            seats, seatSelected, alreadyBooked, selectedSeats, blockedSeats
        };
    }
};
</script>

<style scoped>
.remove_button {
    border: 2px solid #FFC107;
    border-radius: 20px;
    background-color: #FFC107;
    padding: 5px 10px;
    color: #ffffff;
    position: relative;
    font-size: 14px;
    font-family: Helvetica Neue;
}

.remove_button:hover,
.remove_button:focus {
    border: 2px solid #FFC107;
    background-color: #fff;
    color: #FFC107;
    transition: 0.4s;
    outline: 0;
}

.right_border {
    border-right: 1px solid #ccc;
}

.seat {
    background-color: #fff;
    border: 1px solid #222;
    width: 45px;
    font-size: 14px;
    color: #000;
}

</style>