<template>
  <Modal @close="closeModal">
    <template v-slot:title>{{ title }}</template>
    <template v-slot:body>
      <form>
        <div class="form-group row my-1">
          <Input
            v-model="floorPayload.name"
            v-bind:placeholder="'Enter Name'"
            v-bind:label="'Name'"
            v-bind:id="'name'"
            v-bind:type="'text'"
          >
            <template v-slot:error>
              <Error v-if="errors.name" v-bind:error="errors.name" />
            </template>
          </Input>
        </div>

        <div class="form-group row my-1">
          <ModalDropdown
            v-model="floorPayload.status"
            v-bind:id="'status'"
            v-bind:label="'Status'"
            v-bind:dropdown="status"
          ></ModalDropdown>
        </div>

        <div class="form-group row my-1 ">
          <div class="col-12">
            <input
              type="submit"
              value="Submit"
              class="mt-3 py-2 submit_button font_detail float-end"
              @click.prevent="updateFloor"
            />
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
import Input from "@/components/layouts/Modals/Inputs/Input";
import dropdownVariables from "@/modules/dropdownVariables";
import Error from "@/components/layouts/Modals/Inputs/Error";
import Modal from "@/components/layouts/Modals/Modal";
import useInputValidation from "@/modules/useInputValidations";
import { inject, onBeforeMount, ref, watch } from "vue";
import { reactive } from "@vue/reactivity";
import store from "@/store";

// import Dropdown from "@/components/layouts/Modals/Inputs/Dropdown";

export default {
  name: "SeatClassUpdateModal",
  components: { Modal, Input, Error, },
  props: {
    ship: String,
    company: String,
    floor: String,
  },
  setup(props, { emit }) {
    let floorPayload = reactive({
      id: "",
      shipId: "",
      name: "",
      status: 1
    });
    let { requiredValidation, errors } =
        useInputValidation();
    const shipId = ref(props.ship);
    const floorId = ref(props.floor);
    const status = dropdownVariables.status;
    const title = ref("Update  Floor");
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");

    watch(
        () => floorPayload.name,
        () => {
          requiredValidation("name", floorPayload.name);
        }
    );


    const validate = () => {
      Object.keys(errors).forEach(function (key) {
        errors[key] = "";
      });
      requiredValidation("name", floorPayload.name);

      return !(
          Object.values(errors).length <= 0 ||
          !Object.values(errors).every((x) => x === null || x === "")
      );
    };
    const closeModal = () => {
      emit("update:modelValue", false);
    };
    const updateFloor = () => {
      if (validate()) {
        showLoader();
        store
            .dispatch("updateFloor", floorPayload)
            .then(() => {
              hideLoader();
              closeModal();
            })
            .catch((error) => {
              hideLoader();
              if (error.status === 401) {
                updateFloor();
              }
            });
      }
    };

    onBeforeMount(() => {
      Object.keys(errors).forEach(function (key) {
        delete errors[key];
      });
      const floorDetails = store.getters.getFloorPlan(
          floorId.value,
          shipId.value
      );
      floorPayload.id = floorDetails._id;
      floorPayload.name = floorDetails.name;
      floorPayload.status = floorDetails.status ? 1 : 0;
      floorPayload.shipId = shipId.value;
    });

    return {
      floorId,
      floorPayload,
      errors,
      status,
      title,
      closeModal,
      updateFloor,
    };
  },
};
</script>

<style scoped>
</style>