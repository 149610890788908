<template>
<Modal @close="closeModal">
  <template v-slot:title>Remove Admin</template>
  <template v-slot:body>
    Are you sure you want to remove admin {{admin}} ({{adminId}})?
    <div class="form-group row my-1">
      <label class="col-sm-3 col-form-label"></label>
      <div class="col-sm-9">
        <input type="submit" class="mt-3 py-2 submit_button float-end font_detail" value="Remove" @click.prevent="removeAdmin"/>
      </div>
    </div>
  </template>
</Modal>
</template>

<script>
import {inject, ref} from "vue";
import store from "@/store";

export default {
  name: "AdminDelete",
  props:['name','id'],
  emits: ['close'],
  setup(props,{emit}){
    const showLoader = inject('showLoader');
    const hideLoader = inject('hideLoader');
    let admin = ref(props.name)
    let adminId = ref(props.id)

    const closeModal = () => {
      emit('close')
    };
    const removeAdmin = () =>{
      showLoader()
      store.dispatch('deleteAdmin',{"id": adminId.value}).then(() => {
        hideLoader()
        closeModal()
      }).catch((error)=>{
        hideLoader()
        if(error.status===401){
          removeAdmin()
        }
      });
    }

    return{
      admin, adminId,
      removeAdmin,closeModal
    }
  }
}
</script>

<style scoped>

</style>