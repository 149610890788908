<template>
    <div class="row mt-5 w-100" v-if="$store.getters.getDashboardDeckDetailsOwner.length" >
      <h4 class="highlight-title">Ship Wise <span class="text-orange text-bold">Deck & Goods</span></h4>

      <div class="col-md-6 mt-3" v-for="(shipWiseDeck,index) in $store.getters.getDashboardDeckDetailsOwner" :key="index">
        <div class="card shadow h-100 rounded-3">
          <div class="card-header rounded-pill mt-2 mx-2 text-center">
            <h5 class="mb-0 text-uppercase">{{ shipWiseDeck.shipInfo.shipName[0] }}</h5>
          </div>
          
          <div class="card-body">
            <Table id="exportable_data">
              <template v-slot:header>
                <tr>
                  <td class="font_detail" colspan="2"><b>Deck</b></td>
                  <td class="font_detail" colspan="2"><b>Goods</b></td>
                </tr>
                <tr>
                  <td class="bg-dark text-white">Tickets</td>
                  <td class="bg-dark text-white">Amount</td>
                  <td class="bg-dark text-white">Tickets</td>
                  <td class="bg-dark text-white">Amount</td>
                </tr>
              </template>

              <template v-slot:body>
                <tr>
                  <td class="font_detail">{{ shipWiseDeck.deckTicket }}</td>
                  <td class="font_detail">{{ shipWiseDeck.deckAmount }}</td>
                  <td class="font_detail">{{ shipWiseDeck.goodsTicket }}</td>
                  <td class="font_detail">{{ shipWiseDeck.goodsAmount }}</td>
                </tr>
              </template>
            </Table>
          </div>
     
        </div>
      </div>
    </div>
</template>