<template>
  <loading :active="loadingActive.active"
           :backgroundColor="loadingActive.backgroundColor"
           :opacity=loadingActive.opacity
           :zIndex=loadingActive.zIndex
           :transition="loadingActive.transition"
           :is-full-page="loadingActive.isFullPage" >
    <atom-spinner
        :animation-duration=loadingActive.animationDuration
        :size=loadingActive.iconSize
        :color="loadingActive.iconColor"
    />
  </loading>

</template>

<script>
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import {AtomSpinner} from 'epic-spinners'
import {inject, ref} from "vue";

export default {
  name: "loadingSpinner",
  components: {
    Loading,
    AtomSpinner
  },
  setup(){
    let loadingActive = ref(inject('loading'))
    return{
      loadingActive
    }
  }
};
</script>

<style scoped>

</style>