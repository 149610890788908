<template>
  <Modal @close="closeModal" v-bind:size="'xl'">
    <template v-slot:title>{{ title }}</template>
    <template v-slot:body>
      <form>
        <div class="auth_form py-3">
          <div class="row my-2">
            <div class="col-md-4">
              <Input
                v-model="supervisorData.name"
                v-bind:placeholder="'Enter supervisor Name'"
                v-bind:label="'Name'"
                v-bind:id="'name'"
                v-bind:type="'text'"
              />
              <Error v-if="errors.name" v-bind:error="errors.name" />
            </div>
            <div class="col-md-4">
              <Input
                v-model="supervisorData.phone"
                v-bind:placeholder="'Enter Phone Number'"
                v-bind:label="'Phone'"
                v-bind:id="'phone'"
                v-bind:type="'text'"
              />
              <Error v-if="errors.phone" v-bind:error="errors.phone" />
            </div>
            <div class="col-md-4">
              <Input
                v-model="supervisorData.password"
                autocomplete="off"
                v-bind:placeholder="'Enter Password'"
                v-bind:label="'Password'"
                v-bind:id="'password'"
                v-bind:type="fieldTypes.password"
              />
              <Error v-if="errors.password" v-bind:error="errors.password" />
            </div>
          </div>
          <div class="row my-2">
            <div class="col-md-4">
              <Input
                v-model="supervisorData.nid"
                v-bind:placeholder="'NID Number'"
                v-bind:label="'NID'"
                v-bind:id="'nid'"
                v-bind:type="'text'"
              />
            </div>
            <div class="col-md-4">
              <Dropdown
                v-if="status"
                v-model="supervisorData.status"
                v-bind:id="'status'"
                v-bind:label="'Status'"
                v-bind:dropdown="status"
              />
            </div>
            <div class="col-md-4">
              <label for="address" class="form-label all_permission_label"
                >Address</label
              >
              <div>
                <textarea
                  class="form-control font_detail"
                  id="address"
                  v-model="supervisorData.address"
                ></textarea>
              </div>
            </div>

            <div class="col-md-4">
              <Dropdown
                v-if="printerType"
                v-model="supervisorData.printerType"
                v-bind:id="'printerType'"
                v-bind:label="'Printer Type'"
                v-bind:dropdown="printerType"
                v-bind:default-option="'Select a Printer Type'"
                v-bind:default-value="''"
              />
              <Error
                v-if="errors.printerType"
                v-bind:error="errors.printerType"
              />
            </div>
            <div class="col-md-8">
              <label class="label_font">Ships</label>
              <Multiselect
                class="w-100 mt-1 mb-4"
                placeholder="Select ships"
                v-model="supervisorData.shipId"
                :options="$store.getters.getShipListForFilter"
                id="multiselectId"
                mode="tags"
                :searchable="true"
                :createTag="true"
              />
              <Error v-if="errors.ship" v-bind:error="errors.ship" />
            </div>
          </div>
        </div>

        <hr class="section_devider" />

        <div class="role_saection">
          <div class="row">
            <div class="d-flex justify-content-between align-items-center">
              <h5><b>Roles</b></h5>
              <div class="form-check form-check-inline">
                <input
                    class="form-check-input all_permission_label"
                    type="checkbox"
                    id="select-all"
                    name="select-all"
                    @click="giveAllPermission"
                    v-model="allSelected"
                />
                <label
                    class="form-check-label all_permission_label"
                    for="select-all"
                >Give All Permission</label
                >
              </div>
            </div>
          </div>
          <span v-if="errors.roles" class="text-danger err_msg">{{
              errors.roles
            }}</span>

          <span v-else-if="errors[Supervisor.supervisorTicketVerify]" class="text-danger err_msg">
            {{ errors[Supervisor.supervisorTicketVerify] }}
          </span>
          <div class="assigned_role">
            <div class="row">
              <div class="col-6 col-sm-4 col-md-3">
                <div class="role_select_area">
                  <h5>Ticket Verify</h5>
                  <Checkbox
                      v-model="checked"
                      :label="'Yes'"
                      :role="Supervisor.supervisorTicketVerify"
                      :key="allSelected"
                      @toggleAllPermission="toggleAllPermission"
                  />
                </div>
              </div>
              <div class="col-6 col-sm-4 col-md-3">
                <div class="role_select_area">
                  <h5>Report</h5>
                  <Checkbox
                      v-model="checked"
                      :label="'Yes'"
                      :role="Supervisor.supervisorReports"
                      :key="allSelected"
                      @toggleAllPermission="toggleAllPermission"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group row my-1">
          <label class="col-sm-3 col-form-label"></label>
          <div class="col-sm-9">
            <input
              v-if="!supervisorIdForUpdate"
              type="submit"
              class="mt-3 py-2 submit_button font_detail float-end"
              @click.prevent="addsupervisor"
            />
            <input
              v-else
              type="submit"
              class="mt-3 py-2 submit_button font_detail float-end"
              @click.prevent="updateSupervisor"
            />
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
import { reactive } from "@vue/reactivity";
import dropdownVariables from "@/modules/dropdownVariables";
import useInputValidation from "@/modules/useInputValidations";
import { inject, onBeforeMount, ref, watch } from "vue";
import roles from "@/modules/roles";
const { Supervisor } = roles();


import store from "@/store";
import Multiselect from "@vueform/multiselect";

export default {
  name: "supervisorAddUpdateModalSeperate",
  components: {
    Multiselect,
  },
  props: {
    modalValue: Boolean,
    company: String,
    supervisor: String,
  },
  setup(props, { emit }) {
    let supervisorData = reactive({
      id: "",
      name: "",
      designation: "",
      phone: "",
      password: "",
      email: "",
      nid: "",
      address: "",
      avatar: "",
      transactionType: "",
      balance: "",
      commission: "",
      userType: "supervisor",
      companyId: "",
      counterId: "",
      commissionType: "",
      roles: "",
      status: "1",
      api_token: "",
      device_token: "",
      printingType: "Individual",
      printerType: "",
      reset_token: "",
      shipId: [],
    });
    let supervisorIdForUpdate = ref(props.supervisor);
    let printerType = dropdownVariables.countermanPrinterType;
    let checked = ref([]);
    let allSelected = ref(false);
    let companyId = ref(props.company);
    let { requiredValidation, passwordValidation, phoneValidation,  errors } =
      useInputValidation();
    const status = dropdownVariables.status;
    const title = ref("Add supervisor");
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");

    let fieldTypes = reactive({
      email: "text",
      password: "text",
    });

    watch(
      () => supervisorData.name,
      () => {
        requiredValidation("name", supervisorData.name);
      }
    );

    watch(
      () => supervisorData.phone,
      () => {
        phoneValidation("phone", supervisorData.phone);
      }
    );
    // watch(
    //     () => supervisorData.roles,
    //     () => {
    //       requiredValidation("roles", supervisorData.roles);
    //     }
    // );
    watch(
      () => supervisorData.printerType,
      () => {
        requiredValidation("printerType", supervisorData.printerType);
      }
    );

    watch(
      () => supervisorData.password,
      () => {
        passwordValidation("password", supervisorData.password);
      }
    );

    watch(
      () => supervisorData.password,
      () => {
        fieldTypes.password = "password";
      }
    );
    watch(
      () => supervisorData.email,
      () => {
        fieldTypes.email = "email";
      }
    );

    const closeModal = () => {
      emit("update:modelValue", false);
    };
    const giveAllPermission = () => {
      checked.value = [];
      if (!allSelected.value) {
        checked.value = Object.values(Supervisor);
      }
    };
    const toggleAllPermission = () => {
      allSelected.value =
          checked.value.length === Object.values(Supervisor).length;
    };

    const validate = () => {
      Object.keys(errors).forEach(function (key) {
        errors[key] = "";
      });
      requiredValidation("name", supervisorData.name);
      phoneValidation("phone", supervisorData.phone);
      requiredValidation("printerType", supervisorData.printerType);
      // requiredValidation("roles", Supervisor.roles);


      if (supervisorIdForUpdate.value === "") {
        passwordValidation("password", supervisorData.password);
      } else {
        if (supervisorData.password !== "") {
          passwordValidation("password", supervisorData.password);
        }
      }
      return !(
        Object.values(errors).length <= 0 ||
        !Object.values(errors).every((x) => x === null || x === "")
      );
    };
    const addsupervisor = () => {
      supervisorData.roles = checked.value.sort().join(",");

      if (validate()) {
        showLoader();
        store
          .dispatch("addSupervisor", supervisorData)
          .then(() => {
            hideLoader();
            emit("getAllSupervisors");
            closeModal();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              addsupervisor();
            }
          });
      }
    };
    const updateSupervisor = () => {
      supervisorData.roles = checked.value.sort().join(",");
      if (validate()) {
        showLoader();
        store
          .dispatch("updateSupervisor", supervisorData)
          .then(() => {
            hideLoader();
            emit("getAllSupervisors");
            closeModal();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              updateSupervisor();
            }
          });
      }
    };

    onBeforeMount(() => {
      Object.keys(errors).forEach(function (key) {
        delete errors[key];
      });
      supervisorData.companyId = companyId.value;
      if (supervisorIdForUpdate.value !== "") {
        const supervisorDetails = store.getters.getSupervisor(
          supervisorIdForUpdate.value
        );
        title.value = "Update supervisor";
        supervisorData.id = supervisorDetails[0]._id;
        supervisorData.companyId = supervisorDetails[0].companyId;
        supervisorData.name = supervisorDetails[0].name;
        supervisorData.phone = supervisorDetails[0].phone;
        supervisorData.nid = supervisorDetails[0].nid;
        supervisorData.address = supervisorDetails[0].address;
        supervisorData.email = supervisorDetails[0].email;
        supervisorData.printerType = supervisorDetails[0].printerType;

        supervisorData.status = supervisorDetails[0].status ? 1 : 0;
        if(supervisorDetails[0].roles[0]){
          supervisorDetails[0].roles[0]
          .split(",")
          .forEach((element) => checked.value.push(parseInt(element)));
        }
        Object.keys(Supervisor).length === checked.value.length
            ? (allSelected.value = true)
            : (allSelected.value = false);
        supervisorDetails[0]?.shipId?.forEach((item) => {
          supervisorData.shipId.push(item._id);
        });
      }
    });

    return {
      status,
      supervisorData,
      errors,
      title,
      supervisorIdForUpdate,
      fieldTypes,
      updateSupervisor,
      addsupervisor,
      closeModal,
      printerType,
      Supervisor,
      giveAllPermission,
      toggleAllPermission,
      checked,
      allSelected,
    };
  },
};
</script>

<style scoped>
</style>
