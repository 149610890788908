<template>
  <label :for="inputId" class="text-white">{{ inputLabel }} :</label>
  <v-date-picker class="inline-block h-full" mode="date" v-model="date" :masks="masks"
                 :model-config="modelConfig"
                 is-dark
                 color="teal"
                >
    <template v-slot="{ inputValue, togglePopover }">
      <input
          class="select_item form-select-sm font_detail w-100"
          :value="inputValue"
          @click="togglePopover()"
          readonly
      />
    </template>
  </v-date-picker>
</template>

<script>
import {ref, watch} from "vue";

export default {
  name: "DatePicker",
  props: {
    modelValue: String,
    label: String,
    id: String
  },
  setup(props, {emit}) {
    let date = ref(props.modelValue);
    const inputLabel = ref(props.label);
    const inputId = ref(props.id);
    const modelConfig = ref({
      type: 'string',
      mask: 'YYYY-MM-DD',
    });
    const masks = ref({
      input: 'DD-MMM-YYYY',
    })
    watch(() => date.value, () => {
      emit("update:modelValue", date.value);
    });
    watch(() => props.modelValue, (value) => {
      date.value = value;
    });
    return {
      inputLabel, inputId, date, modelConfig, masks
    };
  }
}
</script>

<style scoped>

</style>