<template>
  <div>
    <div class="card mx-5 my-2">
      <div
        class="card-header"
        :id="'heading' + seatList.seatClassId"
        data-toggle="collapse"
        :data-target="'#collapse' + seatList.seatClassId"
        aria-expanded="true"
        :aria-controls="'collapse' + seatList.seatClassId"
      >
        <div class="row my-1">
          <div class="col-md-1 bold">
            {{ seatList.seatClassName }}
          </div>

          <div class="col-md-11">
            <button
              v-if="collapse"
              class="header_show_button font_detail float-end"
              @click="collapse = false"
            >
              <i class="fas fa-chevron-down"></i><span class="mx-1">Show</span>
            </button>
            <button
              v-else
              class="header_show_button font_detail float-end"
              @click="collapse = true"
            >
              <i class="fas fa-chevron-up"></i><span class="mx-1">Hide</span>
            </button>

          </div>
        </div>
      </div>
      <ul
        v-if="seatList"
        :id="'collapse' + seatList.seatClassId"
        :aria-labelledby="'heading' + seatList.seatClassId"
        data-parent="#accordion"
        class="collapse list-group list-group-flush"
        :class="!collapse ? 'show' : ''"
      >
        <SeatPermissionAdd
            v-bind:blockSeat="blockedSeats"
            v-bind:ownPermitted="ownPermittedSeats"
            v-bind:allreadyPermittedSeats="allreadyPermittedSeats"
            v-if="hasPermission(Admin.companyDeployWrite)"
            v-bind:company="companyId"
            v-bind:seatPlanId="seatList.seatClassId"
            v-bind:ship="shipId"
            :cabinMan="cabinManId"
            :seatClass="seatList.seatClassId"
            @getSeatPlan="getSeatPlan"
            :seatList="seatList"
            @getAllSelectedSeats="getAllSelectedSeats"
          />
        <SeatList
          v-bind:blockSeat="blockedSeats"
          v-bind:ownPermitted="ownPermittedSeats"
          v-bind:allreadyPermittedSeats="allreadyPermittedSeats"
          v-bind:seatList="seatList.seatList"
          v-bind:seatPlan="seatList.seatClassId"
          v-bind:ship="shipId"
          :cabinman="cabinmanId"
          @getSeatPlan="getSeatPlan"
          @selectedForBlocked="selectedForBlocked"
        />
      </ul>
    </div>
  </div>
</template>

<script>
import dropdownVariables from "@/modules/dropdownVariables";
import { onBeforeMount, ref, } from "vue";
import store from "@/store";
import SeatList from "@/views/Admin/CompanyDeploy/Children/Cabinman/SeatList";
import SeatPermissionAdd from "./SeatPermissionAdd";
import permission from "@/modules/permission";
import roles from "@/modules/roles";
import {reactive} from "@vue/reactivity";

const { hasPermission } = permission();
const { Admin } = roles();

export default {
  name: "SeatClassCard",
  props: {
    cabinSeatList: Object,
    seatClassId: String,
    ship: String,
    cabinMan: String,
    company: String,
  },
  components: {
    SeatList,
    SeatPermissionAdd,
  },
  setup(props) {
    const seatList = ref(props.cabinSeatList);
    let spId = ref(props.seatClassId);
    let companyId = ref(props.company);
    let shipId = ref(props.ship);
    const cabinManId = ref(props.cabinMan)
    let collapse = ref(true);
    let seatPlan = ref({});
    const status = dropdownVariables.status;
    let blockedSeats = ref([]);
    let ownPermittedSeats = ref([]);
    let allreadyPermittedSeats = ref([]);
    const seatListPayload = reactive({
      cabinManId: cabinManId.value,
      shipId: shipId.value,
    })

    const getSeatPlan = () => {
      store
          .dispatch("getPermittedCabinSeatList", seatListPayload)
          .then(() => {
            const permittedSeats = store.getters.getPermittedCabinSeatListBySeatClassId(seatList.value.seatClassId)[0]
            const permittedSeatsByOthers = store.getters.getPermittedCabinSeatListByOtherBySeatClassId(seatList.value.seatClassId)[0]
            const blockedSeatList = store.getters.getBlockedSeatListBySeatClassId(seatList.value.seatClassId)[0]
            if (permittedSeats && permittedSeats.seats){
              ownPermittedSeats.value = permittedSeats.seats.split(",")
            }
            if (permittedSeatsByOthers && permittedSeatsByOthers.seats){
              allreadyPermittedSeats.value = permittedSeatsByOthers.seats.split(",")
            }
            if (blockedSeatList && blockedSeatList.seats){
              blockedSeats.value = blockedSeatList.seats.split(",")
            }
          })
          .catch(( ) => {

          });
    };

    const selectedForBlocked = (seat) => {
      if (ownPermittedSeats.value.includes(seat)) {
        ownPermittedSeats.value = ownPermittedSeats.value.filter(
          (alreadySelected) => alreadySelected !== seat
        );
      } else {
        ownPermittedSeats.value.push(seat);
      }
    };

    const getAllSelectedSeats = (seats)=>{
      ownPermittedSeats.value = seats
    }

    onBeforeMount(() => {
      getSeatPlan();
    });

    return {
      status,
      seatPlan,
      spId,
      shipId,
      companyId,
      collapse,
      Admin,
      blockedSeats,
      ownPermittedSeats,
      allreadyPermittedSeats,
      hasPermission,
      getSeatPlan,
      selectedForBlocked,
      seatList,
      cabinManId,
      getAllSelectedSeats
    };
  },
};
</script>

<style scoped>


.header_show_button {
  margin: 0 2px;
  border: 2px solid #15aabf;
  border-radius: 4px;
  background-color: #15aabf;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.header_show_button:hover,
.header_show_button:focus {
  border: 2px solid #15aabf;
  background-color: #fff;
  color: #15aabf;
  transition: 0.4s;
  outline: 0;
}

.bold {
  font-weight: bold;
}
</style>
