<template>
  <div v-if="!reportPrint">
    <Filter>
      <template v-slot:body>
        <div class="col-sm-6 col-lg-4">
          <DateTimeRangePicker v-model="date.range" />
        </div>
        <div class="col-sm-2 col-lg-auto">
          <CheckboxSingleInput v-model="ticketDateTime" />
        </div>
        <div class="col-md-2 col-sm-6 mt-auto">
          <button
            class="edit_table_data font_detail"
            @click="getCounterManReport"
          >
            <i class="fas fa-filter"></i><span class="mx-1 bold">Filter</span>
          </button>
        </div>
      </template>

      <template v-slot:button2>
        <button class="edit_table_data font_detail" @click="printUserReport">
          <i class="fas fa-print"></i><span class="mx-1 bold">Print</span>
        </button>
      </template>
      <template v-slot:button1>
        <button
          class="edit_table_data font_detail"
          @click="exportExcel('xlsx')"
        >
          <i class="fas fa-download"></i><span class="mx-1 bold">Export</span>
        </button>
      </template>
    </Filter>
    <Table id="exportable_data">
      <template v-slot:header>
        <tr>
          <td class="font_detail">SL</td>
          <td class="font_detail">Seat Class</td>
          <td class="font_detail">Seat Ticket</td>
          <!-- <td class="font_detail">Seat Amount</td> -->
        </tr>
      </template>
      <template v-slot:body>
        <tr
          v-for="(report, index) in $store.getters.getCountermanReport.ticket"
          :key="report.seatClassId"
        >
          <td class="font_detail">{{ index + 1 }}</td>
          <td class="font_detail">{{ report.seatClass }}</td>
          <td class="font_detail">{{ report.seatTicket }}</td>
          <!-- <td class="font_detail">{{ report.seatAmount }}</td> -->
        </tr>
        <tr class="bg-dark text-white">
          <td class="font_detail"></td>
          <td class="font_detail"></td>
          <!-- <td class="font_detail"> Deck Total Print: {{deckTicketTotal}}</td> -->
          <td class="font_detail text-white">Total Ticket: {{ seatTicketTotal }}</td>
          <!-- <td class="font_detail">Total Amount: {{ seatAmountTotal }} Taka</td> -->
        </tr>
      </template>
      <!-- <template v-slot:pagination>
              <Pagination v-model="pagination.page" :records="$store.getters.getTotalShipWiseReport" :per-page="pagination.limit"
                          @paginate="getAllCompanyList" :options="options"/>
           </template> -->
    </Table>
  </div>
  <div class="card card-body" v-else>
    <UserReportPrintPreview
      :report="ticketDateTime ? dayWiseReport : normalReportData "
      :ticketType="ticketDateTime ? 'userDayWiseReport': 'userReport'"
      :printCount="printCount"
      :printCountStatus="printCountStatus"
      :dayWiseReport ="ticketDateTime"
      @close="close"
    />
  </div>
</template>

<script>
import moment from "moment";
import { reactive } from "@vue/reactivity";
import { onBeforeMount, ref, watch, inject } from "vue";
import CheckboxSingleInput from "@/components/layouts/Inputs/CheckboxSingleInput.vue";
import store from "@/store";
import XLSX from "xlsx";

export default {
  name: "Report",
  components: {
    CheckboxSingleInput
  },
  setup() {
    let pagination = reactive({
      page: 1,
      limit: 10,
      shipId: "",
      fromDate: "",
      toDate: "",
      ticketDateTime: "",
    });
    const options = {
      texts: {
        count: "",
      },
    };
    const fromDate = moment(new Date()).format("yyyy-MM-DD" + " 00:00:00");
    const toDate = moment(new Date()).format("yyyy-MM-DD" + " 23:59:59");
    let seatTicketTotal = ref(0);
    let seatAmountTotal = ref(0);
    let printCount = ref(0);
    let printCountStatus = ref(false);
    let reportPrint = ref(false);
    let normalReportData = ref();
    let ticketDateTime = ref(false);
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");

    const dayWiseReport = reactive({
      printerType: '',
      date: moment(new Date()).format("yyyy-MM-DD"),
      companyName: '',
      countermanName:'',
      totalTicket: seatTicketTotal,
      totalAmount: seatAmountTotal,
      ticket: {}
    });

    // const dailySummaryReport = reactive({
    //   reportType: "dailySummaryReport",
    //   dailySummaryDate: moment(new Date()).format("yyyy-MM-DD"),
    // })

    let date = reactive({
      range: {
        start: fromDate,
        end: toDate,
      },
    });

    watch(
      () => date.range.start,
      (value) => {
        pagination.fromDate = moment(value).format("yyyy-MM-DD" + " 00:00:00");
      }
    );
    watch(
      () => date.range.end,
      (value) => {
        pagination.toDate = moment(value).format("yyyy-MM-DD" + " 23:59:59");
      }
    );

    watch(
      () => ticketDateTime.value,
      () => {
        ticketDateTime.value
        ? (pagination.ticketDateTime = 1)
        : (pagination.ticketDateTime = "");
      }
    );

    const printUserReport = () => {
      showLoader();
      store
        .dispatch("reportPrintCount", {
          reportType: ticketDateTime.value ? 'userDayWiseReport' : 'dailySummaryReport',
          dailySummaryDate: moment(new Date()).format("yyyy-MM-DD"),
        })
        .then((data) => {
          printCount.value = data.printCount + 1;
          printCountStatus.value = data.status;
          hideLoader();
          reportPrint.value = true;
        })
        .catch(() => {
          hideLoader();
        });
    };
    const close = () => {
      reportPrint.value = false;
    };
    const sumListData = () => {
      store.getters.getCountermanReport.ticket.forEach((data) => {
        seatTicketTotal.value += data.seatTicket;
        seatAmountTotal.value += data.seatAmount;
      });
    };
    const exportExcel = (type, fn, dl) => {
      let elt = document.getElementById("exportable_data");
      let wb = XLSX.utils.table_to_book(elt, { sheet: "sheet Js" });
      return dl
        ? XLSX.write(wb, { bookType: type, bookSST: true, type: "base64" })
        : XLSX.writeFile(wb, fn || "User_Report." + (type || "xlsx"));
    };

    const initDayWiseReportData = ()=>{
      dayWiseReport.printerType = store.getters.getCountermanReport.reportInfo.printerType
      dayWiseReport.companyName = store.getters.getCountermanReport.reportInfo.companyName
      dayWiseReport.countermanName = store.getters.getCountermanReport.reportInfo.countermanName
      dayWiseReport.ticket = store.getters.getCountermanReport.ticket
    }

    const getCounterManReport = () => {
      store.dispatch("getAllCountermanReport", pagination).then(() => {
        seatTicketTotal.value = 0;
        seatAmountTotal.value = 0;
        normalReportData.value = store.getters.getCountermanReport
        sumListData();
        initDayWiseReportData();
      });
    };
    onBeforeMount(() => {
      pagination.fromDate = fromDate;
      pagination.toDate = toDate;
      getCounterManReport();
    });
    return {
      options,
      pagination,
      date,
      ticketDateTime,
      seatTicketTotal,
      seatAmountTotal,
      reportPrint,
      exportExcel,
      getCounterManReport,
      printUserReport,
      close,
      printCount,
      printCountStatus,
      dayWiseReport,
      normalReportData
    };
  },
};
</script>

<style scoped>
</style>