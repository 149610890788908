<template>
  <div>
    <FilterTab>
      <!-- joyonto: new Work -->
      <template v-slot:body>
        <div class="col-sm-6 col-md-2">
          <DropdownFilter
              v-bind:id="'boardingPlace'"
              v-bind:label="'Boarding Place'"
              v-bind:defaultOption="'--select boarding place--'"
              v-model="boardingPlaceForFilter"
              v-bind:defaultValue="''"
              v-bind:dropdown="boardingPlacesForFilter"
          />
        </div>
        <div class="col-sm-6 col-md-2">
          <DropdownFilter
              v-bind:id="'droppingPlace'"
              v-bind:label="'Dropping Place'"
              v-bind:defaultOption="'--select dropping place--'"
              v-model="droppingPointForFilter"
              v-bind:defaultValue="''"
              v-bind:dropdown="droppingPointsForFilter"
          />
        </div>
        <div class="col-sm-6 col-md-2">
          <DropdownFilter
              v-bind:id="'floorName'"
              v-bind:label="'Floor Name'"
              v-bind:defaultOption="'--select Floor Name--'"
              v-model="floorNameForFilter"
              v-bind:defaultValue="''"
              v-bind:dropdown="floorNamesForFilter"
          />
        </div>
      </template>
      <template v-slot:button2>
        <button class="edit_table_data font_detail" @click="goBack">
          <i class="fas fa-arrow-left"></i
          ><span class="mx-1 bold">Go Back</span>
        </button>
      </template>
      <template v-slot:button1>
        <button
          class="edit_table_data font_detail"
          @click="exportTable('xlsx')"
        >
          <i class="fas fa-download"></i><span class="mx-1 bold">Export</span>
        </button>
      </template>
    </FilterTab>
  </div>
  <div id="exportable_data">
    <div class="card h-100 mt-4"
      v-for="(itemValue, itemKey) in classWiseReport"
      :key="itemKey"
    >
      <table>
        <tr colspan="100">
          <th><h5 class="mb-0 py-2 text-uppercase fw-bold text-center text-orange">{{ itemKey }}</h5></th>
        </tr>
      </table>
      <div class="card-body mt-0 py-0" >
        <Table :pagination="false">
          <template v-slot:header>
            <tr>
              <td class="font_detail">SL</td>
              <td class="font_detail">Ticket Date Time</td>
              <td class="font_detail">Ship Name</td>
              <td class="font_detail">Passenger Name</td>
              <td class="font_detail">Passenger Phone</td>
              <td class="font_detail">Boarding Place</td>
              <td class="font_detail">Dropping Place</td>
              <td class="font_detail">Floor Name</td>
              <td class="font_detail">Seat Numbers</td>
              <td class="font_detail">Total Ticket</td>
              <td class="font_detail">Receivable Amount</td>
              <td class="font_detail" v-if="shipWiseSeatReport.discount">Discount</td>
              <td class="font_detail">Issued By</td>
              <td class="font_detail">Status</td>
              <td class="font_detail" v-if="shipWiseSeatReport.status == 0">Cancelled By</td>
              <td class="font_detail" v-if="shipWiseSeatReport.status == 0">Cancellation Cause</td>
              <td class="font_detail" v-if="shipWiseSeatReport.status == 0">Cancelled At</td>
            </tr>
          </template>
          <template v-slot:body>
            <tr v-for="(item, index) in itemValue.totalReport" :key="index">
              <td class="font_detail" :class="item.userType === 'cabinman' || item.isConfirmedByCabinMan ? 'cabinmanTicket' : ''">{{ index + 1 }}</td>
              <td class="font_detail" :class="item.userType === 'cabinman' || item.isConfirmedByCabinMan ? 'cabinmanTicket' : ''">{{ dateFormat(item.ticketDateTime) }}</td>
              <td class="font_detail" :class="item.userType === 'cabinman' || item.isConfirmedByCabinMan ? 'cabinmanTicket' : ''">{{ shipNameProps }}</td>
              <td class="font_detail" :class="item.userType === 'cabinman' || item.isConfirmedByCabinMan ? 'cabinmanTicket' : ''">{{ item.passengerName || 'N/A' }}</td>
              <td class="font_detail" :class="item.userType === 'cabinman' || item.isConfirmedByCabinMan ? 'cabinmanTicket' : ''">{{ item.passengerPhone || 'N/A' }}</td>
              <td class="font_detail" :class="item.userType === 'cabinman' || item.isConfirmedByCabinMan ? 'cabinmanTicket' : ''">{{ item.boardingPlace }}</td>
              <td class="font_detail" :class="item.userType === 'cabinman' || item.isConfirmedByCabinMan ? 'cabinmanTicket' : ''">{{ item.droppingPoint }}</td>
              <td class="font_detail" :class="item.userType === 'cabinman' || item.isConfirmedByCabinMan ? 'cabinmanTicket' : ''">{{ item.floorName || 'N/A' }}</td>
              <td class="font_detail" :class="item.userType === 'cabinman' || item.isConfirmedByCabinMan ? 'cabinmanTicket' : ''">{{ item.seatNumbers.replaceAll(",", ", ") }}</td>
              <td class="font_detail" :class="item.userType === 'cabinman' || item.isConfirmedByCabinMan ? 'cabinmanTicket' : ''">{{ item.totalSeat }}</td>
              <td class="font_detail" :class="item.userType === 'cabinman' || item.isConfirmedByCabinMan ? 'cabinmanTicket' : ''">{{ item.payable }}</td>
              <td class="font_detail" :class="item.userType === 'cabinman' || item.isConfirmedByCabinMan ? 'cabinmanTicket' : ''" v-if="shipWiseSeatReport.discount">{{ item.discount }}</td>
              <td class="font_detail" :class="item.userType === 'cabinman' || item.isConfirmedByCabinMan ? 'cabinmanTicket' : ''">{{ item.issuedBy }}</td>
              <td class="font_detail" :class="item.userType === 'cabinman' || item.isConfirmedByCabinMan ? 'cabinmanTicket' : ''">{{ item.status ? "Confirmed" : "Cancelled" }}</td>
              <td class="font_detail" :class="item.userType === 'cabinman' || item.isConfirmedByCabinMan ? 'cabinmanTicket' : ''" v-if="shipWiseSeatReport.status == 0">{{ item.cancelledBy }}</td>
              <td class="font_detail" :class="item.userType === 'cabinman' || item.isConfirmedByCabinMan ? 'cabinmanTicket' : ''" v-if="shipWiseSeatReport.status == 0">{{ item.cancellationCause }}</td>
              <td class="font_detail" :class="item.userType === 'cabinman' || item.isConfirmedByCabinMan ? 'cabinmanTicket' : ''" v-if="shipWiseSeatReport.status == 0">{{item.cancelledAt ? dateFormat(item.cancelledAt) : 'N/A' }}</td>
            </tr>
            <tr class="bg-dark text-white">
              <td class="font_detail"></td>
              <td class="font_detail"></td>
              <td class="font_detail"></td>
              <td class="font_detail"></td>
              <td class="font_detail"></td>
              <td class="font_detail"></td>
              <td class="font_detail"></td>
              <td class="font_detail"></td>
              <td class="font_detail"></td>
              <td class="font_detail text-white">Total Ticket: {{ itemValue.totalSeat }}</td>
              <td class="font_detail text-white">Total Amount: {{itemValue.payable}} Taka</td>
              <td class="font_detail text-white" v-if="shipWiseSeatReport.discount">Total Discount: {{ itemValue.totalDiscount }} Taka</td>
              <td class="font_detail"></td>
              <td class="font_detail"></td>
              <td class="font_detail" v-if="shipWiseSeatReport.status == 0"></td>
              <td class="font_detail" v-if="shipWiseSeatReport.status == 0"></td>
              <td class="font_detail" v-if="shipWiseSeatReport.status == 0"></td>
            </tr>
          </template>
        </Table>
      </div>
    </div>
  </div>
  <!-- <div class="position-relative">
    <div class="position-absolute end-0 mt-3 me-5">
      <pagination
        v-if="$store.getters.getTotalShipWiseSeatDetails"
        v-model="shipWiseSeatReport.page"
        :records="$store.getters.getTotalShipWiseSeatDetails"
        :per-page="shipWiseSeatReport.limit"
        @paginate="getSeatDetails"
        :options="options"
      />
    </div>
  </div> -->
</template>


<script>
import { reactive } from "@vue/reactivity";
import { ref, onBeforeMount, inject, watch, computed } from "vue";
import store from "@/store";
import moment from "moment";
import XLSX from "xlsx";

export default {
  name: "SeatDetails",
  props: {
    shipId: String,
    shipName: String,
    status: Number,
    discount: [Number, String],
    fromDate: String,
    toDate: String,
    direction: String,
  },
  emits: ["backToShipWiseReport"],
  setup(props, {emit}) {
    let shipId = ref(props.shipId);
    let shipNameProps = ref(props.shipName);
    // let totalAmount = ref(0);
    // let totalTicket = ref(0);
    let cancelledBy = ref(props.status);
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader")
    const boardingPlaceForFilter = ref("")
    const droppingPointForFilter = ref("")
    const floorNameForFilter = ref("")
    const fareAmountForFilter = ref("")
    const boardingPlacesForFilter = reactive({})
    const droppingPointsForFilter = reactive({})
    const floorNamesForFilter = reactive({})
    const fareAmountsForFilter = reactive({})
    let filteredSeatDetails = ref([])
    let summary = ref(false);
    let shipWiseSeatReport = reactive({
      ticketType: "SEAT",
      shipId: shipId.value,
      fromDate: props.fromDate,
      toDate: props.toDate,
      direction: props.direction,
      discount: props.discount,
      status: props.status,
      page: 1,
      limit: 10,
    });

    let reportData = ref([]);

    const options = {
      texts: {
        count: "",
      },
    };

    watch(
      [boardingPlaceForFilter, droppingPointForFilter, floorNameForFilter], ([bPlace, dPoint, floorName]) => {
      let reportData = store.getters.getShipWiseSeatDetails
      filteredSeatDetails.value = reportData.filter(
          (item) => {
            let result = true
            bPlace && (result &&= item.boardingPlace === bPlace)
            dPoint && (result &&=  item.droppingPoint === dPoint)
            floorName && (result &&= item.floorName === floorName)
            return result
          }
        );
    })

    const dateFormat = (date) => {
      return moment(String(date))
          .utcOffset(6, false)
          .format("DD-MMM-yyyy hh:mm A");
    };

    const getDeckDetails = () => {
      showLoader();
      store
          .dispatch("getSeatDetails", shipWiseSeatReport)
          .then(() => {
            hideLoader();
            deckDetailsdata();
          })
          .catch(() => hideLoader());
    };

    const deckDetailsdata = () => {
      filteredSeatDetails.value = store.getters.getShipWiseSeatDetails
      filteredSeatDetails.value.forEach((data) => {
        if (data.boardingPlace){
          boardingPlacesForFilter[data.boardingPlace] = data.boardingPlace
        }
        if (data.droppingPoint){
          droppingPointsForFilter[data.droppingPoint] = data.droppingPoint
        }
        if (data.floorName){
          floorNamesForFilter[data.floorName] = data.floorName
        }
      });
    };

    const classWiseReport = computed(() => {
      const data = {}
      const payable = []
      const totalSeat = []
      const totalDiscount = []
      filteredSeatDetails.value.forEach((report) => {
        report.seatClassName = report.seatClassName.replace(/[\s]+/g, ' ').trim().toUpperCase()
        if (report.seatClassName in data) {
          data[report.seatClassName].totalReport.push(report);
          payable[report.seatClassName] =  payable[report.seatClassName] == undefined ? report.payable : payable[report.seatClassName] + report.payable;
          totalSeat[report.seatClassName] =  totalSeat[report.seatClassName] == undefined ? report.totalSeat : totalSeat[report.seatClassName] + report.totalSeat;
          totalDiscount[report.seatClassName] =  totalDiscount[report.seatClassName] == undefined ? report.discount : totalDiscount[report.seatClassName] + report.discount;
          data[report.seatClassName].payable = payable[report.seatClassName];
          data[report.seatClassName].totalSeat = totalSeat[report.seatClassName];
          data[report.seatClassName].totalDiscount = totalDiscount[report.seatClassName];
        } 
        else {
          data[report.seatClassName] = {
            totalReport: [],
          };
          data[report.seatClassName].totalReport.push(report);
          payable[report.seatClassName] = 
            payable[report.seatClassName] == undefined ? report.payable : payable[report.seatClassName] + report.payable;
          totalSeat[report.seatClassName] = 
            totalSeat[report.seatClassName] == undefined ? report.totalSeat : totalSeat[report.seatClassName] + report.totalSeat;
          totalDiscount[report.seatClassName] = 
            totalDiscount[report.seatClassName] == undefined ? report.discount : totalDiscount[report.seatClassName] + report.discount;
          data[report.seatClassName].payable = payable[report.seatClassName];
          data[report.seatClassName].totalSeat = totalSeat[report.seatClassName];
          data[report.seatClassName].totalDiscount = totalDiscount[report.seatClassName];
        }
      });
    summary = true;
    return data;
    });

    const goBack = () => {
      emit("backToShipWiseReport", true);
    };

    const exportTable = (type, fn, dl) => {
      if (document.getElementById("exportable_data") !== null) {
        var elt = document.getElementById("exportable_data");
        var wb = XLSX.utils.table_to_book(elt, {sheet: "sheet Js"});
        return dl
            ? XLSX.write(wb, {bookType: type, bookSST: true, type: "base64"})
            : XLSX.writeFile(
                wb,
                fn || "Admin_ShipWise_Seat_Report." + (type || "xlsx")
            );
      }
    };

    onBeforeMount(() => {
      getDeckDetails();
    });

    return {
      shipWiseSeatReport,
      goBack,
      cancelledBy,
      exportTable,
      shipNameProps,
      getDeckDetails,
      options,
      dateFormat,
      boardingPlaceForFilter,
      boardingPlacesForFilter,
      droppingPointForFilter,
      droppingPointsForFilter,
      floorNameForFilter,
      floorNamesForFilter,
      filteredSeatDetails,
      fareAmountForFilter,
      fareAmountsForFilter,
      summary,
      classWiseReport,
      reportData
    };
  },
};
</script>
<style scoped>
  h3,
  h5 {
    font-size: 36px;
    color: #f04935;
  }
  h5 {
    font-size: 18px;
  }
  .cabinmanTicket{
    background-color: #bdcbff;
  }
</style>