<template>
  <div>
    <div class="card mx-5 my-2">
      <div
        class="card-header"
        :id="'heading' + seatPlan._id"
        data-toggle="collapse"
        :data-target="'#collapse' + seatPlan._id"
        aria-expanded="true"
        :aria-controls="'collapse' + seatPlan._id"
      >
        <div class="row align-items-center my-1">
          <div class="col-md-1 bold">
            {{ seatPlan.name }}
          </div>
          <div class="col-md-9">
            <div class="row">
              <div class="col text-center">
                Up: <span class="bold">{{ seatPlan.upFare }}</span>
              </div>
              <div class="col text-center">
                Down: <span class="bold">{{ seatPlan.downFare }}</span>
              </div>
              <div class="col text-center">
                No of Ticket Copy:
                <span class="bold">{{ seatPlan.numberOfTicketCopy }}</span>
              </div>
              <div class="col text-center">
                Passenger Copy:
                <span class="bold">{{
                  seatPlan.passengerCopy ? "Yes" : "No"
                }}</span>
              </div>
              <div class="col text-center">
                Office Copy:
                <span class="bold">{{
                  seatPlan.officeCopy ? "Yes" : "No"
                }}</span>
              </div>
              <div class="col text-center">
                Cabin:
                <span class="bold">{{
                    seatPlan.isCabin ? "Yes" : "No"
                  }}</span>
              </div>
              <div class="col text-center">
                Status:
                <span class="bold" :class="seatPlan.status ? 'text-success' : 'text-danger' ">{{
                  seatPlan.status ? "Active" : "Inactive"
                }}</span>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <button
              v-if="collapse"
              class="header_show_button font_detail float-end"
              @click="collapse = false"
            >
              <i class="fas fa-chevron-down"></i><span class="mx-1">Show</span>
            </button>
            <button
              v-else
              class="header_show_button font_detail float-end"
              @click="collapse = true"
            >
              <i class="fas fa-chevron-up"></i><span class="mx-1">Hide</span>
            </button>
            <button
              v-if="hasPermission(Owner.ownerCompanySetupWrite)"
              class="header_edit_button font_detail float-end"
              @click="updateSeatClass()"
            >
              <i class="fas fa-pencil-alt"></i><span class="mx-1">Edit</span>
            </button>
            <button
              v-if="hasPermission(Owner.ownerCompanySetupWrite)"
              class="header_delete_button font_detail float-end"
              @click="deleteSeatClass()"
            >
              <i class="fas fa-trash"></i><span class="mx-1">Delete</span>
            </button>
          </div>
        </div>
      </div>
      <ul
        v-if="seatPlan"
        :id="'collapse' + seatPlan._id"
        :aria-labelledby="'heading' + seatPlan._id"
        data-parent="#accordion"
        class="collapse list-group list-group-flush"
        :class="!collapse ? 'show' : ''"
      >
        <AddSeatRow
          v-bind:blocked="blockedSeats"
          v-if="hasPermission(Owner.ownerCompanySetupWrite)"
          :company="companyId"
          :seatPlanId="seatPlan._id"
          :ship="shipId"
          @getSeatPlan="getSeatPlan"
        />
        <SeatList
          :blocked="blockedSeats"
          :seatList="seatPlan.seatList"
          :seatPlan="seatPlan._id"
          :ship="shipId"
          @getSeatPlan="getSeatPlan"
          @selectedForBlocked="selectedForBlocked"
        />
      </ul>
    </div>
    <div v-if="updateModal">
      <SeatPlanUpdateModal
        v-model="updateModal"
        :company="companyId"
        :ship="shipId"
        :seatClass="spId"
        :floor="floorId"
      />
    </div>
    <div v-if="deleteModal">
      <SeatPlanDeleteModal
        v-model="deleteModal"
        :company="companyId"
        :ship="shipId"
        :seatClass="spId"
      />
    </div>
  </div>
</template>

<script>
import dropdownVariables from "@/modules/dropdownVariables";
import { onBeforeMount, ref, watch } from "vue";
import store from "@/store";
import SeatPlanUpdateModal from "./SeatPlanUpdateModal";
import SeatPlanDeleteModal from "./SeatPlanDeleteModal";
import AddSeatRow from "./AddSeatRow";
import SeatList from "./SeatList";
import permission from "@/modules/permission";
import roles from "@/modules/roles";

const { hasPermission } = permission();
const { Owner } = roles();

export default {
  name: "SeatClassCard",
  props: {
    seatPlanId: String,
    ship: String,
    company: String,
    floor: String,
  },
  components: {
    SeatPlanUpdateModal,
    SeatPlanDeleteModal,
    AddSeatRow,
    SeatList,
  },
  setup(props) {
    let spId = ref(props.seatPlanId);
    let companyId = ref(props.company);
    let shipId = ref(props.ship);
    let floorId = ref(props.floor);
    let updateModal = ref(false);
    let deleteModal = ref(false);
    let collapse = ref(true);
    let seatPlan = ref({});
    const status = dropdownVariables.status;
    let blockedSeats = ref([]);

    watch(
      () => updateModal.value,
      () => {
        if (updateModal.value === false) {
          getSeatPlan();
        }
      }
    );
    watch(
      () => seatPlan.value,
      () => {
        getSeatPlan();
      }
    );
    const getSeatPlan = () => {
      seatPlan.value = store.getters.getSeatPlanOwner(spId.value, shipId.value);
      if (seatPlan.value.blockedSeat) {
        blockedSeats.value = seatPlan.value.blockedSeat.split(",");
      } else {
        blockedSeats.value = []
      }
    };
    const updateSeatClass = () => {
      updateModal.value = true;
    };
    const deleteSeatClass = () => {
      deleteModal.value = true;
    };
    const selectedForBlocked = (seat) => {
      if (blockedSeats.value.includes(seat)) {
        blockedSeats.value = blockedSeats.value.filter(
          (alreadySelected) => alreadySelected !== seat
        );
      } else {
        blockedSeats.value.push(seat);
      }
    };

    onBeforeMount(() => {
      getSeatPlan();
    });

    return {
      status,
      seatPlan,
      spId,
      shipId,
      updateModal,
      companyId,
      deleteModal,
      collapse,
      Owner,
      blockedSeats,
      hasPermission,
      updateSeatClass,
      deleteSeatClass,
      getSeatPlan,
      selectedForBlocked,
      floorId,
    };
  },
};
</script>

<style scoped>
.header_edit_button {
  margin: 0 2px;
  border: 2px solid #ffc107;
  border-radius: 4px;
  background-color: #ffc107;
  padding: 2px 6px;
  color: #000;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.header_edit_button:hover,
.header_edit_button:focus {
  border: 2px solid #ffc107;
  background-color: #fff;
  color: #ffc107;
  transition: 0.4s;
  outline: 0;
}

.header_delete_button {
  margin: 0 2px;
  border: 2px solid #ff072c;
  border-radius: 4px;
  background-color: #ff072c;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.header_delete_button:hover,
.header_delete_button:focus {
  border: 2px solid #ff072c;
  background-color: #fff;
  color: #ff072c;
  transition: 0.4s;
  outline: 0;
}

.header_show_button {
  margin: 0 2px;
  border: 2px solid #15aabf;
  border-radius: 4px;
  background-color: #15aabf;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.header_show_button:hover,
.header_show_button:focus {
  border: 2px solid #15aabf;
  background-color: #fff;
  color: #15aabf;
  transition: 0.4s;
  outline: 0;
}

.bold {
  font-weight: bold;
}
</style>
