import apiResponse from "@/modules/apiResponse";
import tokenCookie from "@/modules/tokenCookie";
import validationMessage from "@/modules/ValidationMessage";
import Apis from "@/urls/apis";
import axios from "axios";
import jwt_decode from "jwt-decode";
import SecureLS from "secure-ls";
import dropdownVariables from "../../modules/dropdownVariables";

const { setCookies, removeCookies } = tokenCookie();
const { messageExtractor } = validationMessage();
const { errorResponse } = apiResponse();
const ls = new SecureLS({ isCompression: false });

const state = {
  authentication: {
    isLoggedIn: false,
    // authToken: null,
    userType: null,
  },
  user: {},
  tracker: {
    activeTab: "",
  },
};
const getters = {
  isLoggedIn: (state) => state.authentication.isLoggedIn,
  // authToken: (state) => state.authentication.authToken,
  userType: (state) => state.authentication.userType,
  userName: (state) => state.user.name,
  userId: (state) => state.user._id,
  userCompany: (state) => state.user.companyId,
  userRoles: (state) => state.user.roles,
  agentBalance: (state) => state.user.balance,
  getActiveTab: (state) => state.tracker.activeTab,
  getUser: (state) => state.user,
  getReservationStatus: (state) => state.user.companyReservation,
  getDefaultBoardingPlace: (state) => state.user.defaultBoardingPlace
};
const actions = {
  async login({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(Apis.LOGIN, payload)
        .then(({ data, status }) => {
          if (status === 200) {
            messageExtractor(data, status);
            setCookies(data);
            commit("setState", data);
            resolve(data.message);
          }
        })
        .catch((error) => {
          reject(errorResponse(error));
        });
    });
  },

  async refreshToken({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(Apis.REFRESH, payload)
        .then(({ data, status }) => {
          if (status === 200) {
            commit("setState", data);
            setCookies(data);
            resolve(data.message);
          }
        })
        .catch((error) => {
          errorResponse(error);
          reject(false);
        });
    });
  },

  async logout() {
    ls.remove("intercity"); // TODO have to check a better solution
    removeCookies();
    this.reset();
  },

  // async logout({commit, rootGetters}) {
  //     let authToken = rootGetters.authToken
  //     const header = {
  //         headers: {
  //             'Authorization': 'Bearer ' + authToken,
  //         }
  //     }
  //     return new Promise((resolve, reject) => {
  //         axios
  //             .post(
  //                 Apis.LOGOUT, null,
  //                 header
  //             )
  //             .then(({data, status}) => {
  //                 if (status === 200) {
  //                     commit("setIsLoggedIn",false);
  //                     resolve({'success': 'Successfully Logout'});
  //                 } else {
  //                     resolve({'error': status});
  //                 }
  //             })
  //             .catch(error => {
  //                 reject({'error': error});
  //             });
  //     });
  // },
};
const mutations = {
  setActiveTab: (state, tab) => (state.tracker.activeTab = tab),
  setState: (state, data) => {
    state.user = jwt_decode(data.token);
    state.authentication.isLoggedIn = true;
    // state.authentication.authToken = data.token
    state.authentication.userType =
      dropdownVariables.userType[state.user.userType];
  },
  updateBalance: (state, newBalance) => (state.user.balance -= newBalance),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
