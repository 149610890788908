<template>
  <cancellationCause
    v-if="modelValue"
    v-model="modelValue"
    :ticketIdStore="ticketIdStore"
    :ticketTotalSeats="ticketSeats"
    :pagination="pagination"
    @getAllTripsForTicketing="getAllTripsForTicketing"
  />
  <div>
    <FilterTab>
      <template v-slot:title><h6>Ticket Cancellation</h6></template>
      <template v-slot:body> </template>
      <template v-slot:searchTicket>
        <div
          class="d-flex p-0 justify-content-start border border-2 border-secondary rounded-2"
        >
          <input
            type="text"
            placeholder="Search Ticket"
            v-model="searchingValue"
            class="p-1 rounded-2 border-0"
          />
          <button
            @click="searchingTicket"
            class="border-0 bg-secondary text-white px-2"
          >
            <i class="fa fa-search"></i>
          </button>
        </div>
      </template>
      <template v-slot:button1>
        <button class="edit_table_data font_detail" @click="goBack">
          <i class="fas fa-arrow-left"></i
          ><span class="mx-1 bold">Go Back</span>
        </button>
      </template>
    </FilterTab>
    <div v-if="seatClasses" class="table-responsive">
      <Table>
        <template v-slot:header>
          <tr>
            <td class="font_detail">SL</td>
            <td class="font_detail">PASSENGER NAME</td>
            <td class="font_detail">PHONE</td>
            <td class="font_detail">SEAT NUMBERS</td>
            <td class="font_detail">TOTAL SEATS</td>
            <td class="font_detail">FLOOR NAME</td>
            <td class="font_detail">SEAT CLASS</td>
            <td
              v-if="hasPermission(Counterman.countermanTicketCancel)"
              class="font_detail"
            >
              CANCEL
            </td>
          </tr>
        </template>
        <template v-slot:body>
          <tr v-for="(ticket, index) in computedSeat" :key="ticket._id">
            <td class="font_detail">{{ pagination.limit*(pagination.page-1) +index +1 }}</td>
            <td class="font_detail">{{ ticket.passengerName || "N/A" }}</td>
            <td class="font_detail">{{ ticket.passengerPhone || "N/A" }}</td>
            <td class="font_detail">
              {{ ticket.seatNumbers.replaceAll(",", ", ") }}
            </td>
            <td class="font_detail">{{ ticket.totalSeat }}</td>
            <td class="font_detail">{{ ticket.floorName || "N/A" }}</td>
            <td class="font_detail">{{ ticket.seatClassName }}</td>
            <td
              v-if="hasPermission(Counterman.countermanTicketCancel)"
              class="font_detail"
            >
              <button
                class="edit_table_data font_detail view_table_button"
                @click="cancelTicket(ticket._id, ticket.seatNumbers)"
              >
                <i></i><span class="mx-1">CANCEL</span>
              </button>
            </td>
          </tr>
        </template>
        <template v-slot:pagination>
          <pagination
            v-model="pagination.page"
            :records="$store.getters.getTotalTicketsHistoyCounter"
            :per-page="pagination.limit"
            @paginate="getTicketsHistory"
            :options="options"
          />
        </template>
      </Table>
    </div>
  </div>
</template>

<script>
import { inject,onBeforeUpdate, onBeforeMount, ref, watch } from "vue";
import store from "@/store";
import { computed, reactive } from "@vue/reactivity";
import permission from "@/modules/permission";
import roles from "@/modules/roles";
import cancellationCause from "./CancellationCause.vue";

const { hasPermission } = permission();
const { Counterman } = roles();

export default {
  name: "TicketsDetails",
  components: {
    cancellationCause,
  },
  props: {
    tripIdPass: {
      type: String,
    },
    paginationData: {
      type: Object,
    },
    seatNumbers :{
      type: String,
    },
  },
  emits: ["close"],
  setup(props, { emit }) {
    let searchingValue = ref("");
    // let seatClasses = ref([]);
    let computedSeat = ref([]);
    let modelValue = ref(false);
    let ticketIdStore = ref("");
    let tripId = ref(props.tripIdPass);
    let ticketSeats = ref([]);
    let pagination = reactive({
      page: 1,
      limit: 25,
      tripId: tripId.value,
      seatNumbers: props.seatNumbers,
    });

    const options = {
      texts: {
        count: "",
      },
    };

    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");

    const goBack = () => {
      emit("close");
    };

    const getTicketsHistory = () => {
      showLoader();
      store
        .dispatch("getTicketsHistory", pagination)
        .then(() => {
          hideLoader();
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            getTicketsHistory();
          }
        });
    };

    watch(
      () => searchingValue.value,
      (value) => {
        pagination.seatNumbers = value;
        resetPagination();
        getTicketsHistory();
      }
    );

    const resetPagination =()=>{
      pagination.page = 1;
    };

    const getAllTripsForTicketing = () => {
      showLoader();
      store
        .dispatch("getActiveTripsForTicketing", props.paginationData)
        .then(() => {
          hideLoader();
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            getAllTripsForTicketing();
          }
        });
    };

    const cancelTicket = (ticketId, seats) => {
      ticketSeats.value = seats.split(",");

      modelValue.value = true;
      ticketIdStore.value = ticketId;
    };

    const seatClasses = computed(() => {
      return store.getters.getTicketsHistoryCounter;
    });
    // const computedSeat = computed(() => {
    //   return store.getters.getTicketsHistoryCounter;
    // });

    onBeforeUpdate(()=>{
      computedSeat.value = store.getters.getTicketsHistoryCounter;
    })

    onBeforeMount(() => {
      getTicketsHistory();
    });

    return {
      options,
      pagination,
      Counterman,
      modelValue,
      ticketIdStore,
      hasPermission,
      cancelTicket,
      getTicketsHistory,
      goBack,
      ticketSeats,
      getAllTripsForTicketing,
      searchingValue,
      seatClasses,
      computedSeat,
      resetPagination
    };
  },
};
</script>

<style scoped></style>
