<template>
  <label :for="inputId" class="text-white">{{ inputLabel }}</label>
  <select
    v-model="input"
    :id="inputId"
    class="form-select font_detail form-select-sm w-100"
    :class="style"
    aria-label=".form-select-sm example"
  >
    <option v-if="inputDefaultOption" :value="inputDefaultValue">
      {{ inputDefaultOption }}
    </option>
    <option v-for="(type, index) in inputDropdown" :key="index" :value="index">
      {{ type }}
    </option>
  </select>
</template>

<script>
import { ref, watch } from "vue";
export default {
  name: "Dropdown",
  props: {
    modelValue: [String, Number],
    dropdown: Object,
    defaultOption: String,
    defaultValue: String,
    style: String,
    label: String,
    id: String,
  },
  setup(props, { emit }) {
    const inputDropdown = ref(props.dropdown);
    const inputDefaultOption = ref(props.defaultOption);
    const inputDefaultValue = ref(props.defaultValue);
    const inputLabel = ref(props.label);
    const inputId = ref(props.id);
    const input = ref(props.modelValue);
    watch(
      () => input.value,
      () => {
        emit("update:modelValue", input.value);
      }
    );
    watch(
      () => props.modelValue,
      () => {
        input.value = props.modelValue;
      }
    );
    watch(
      () => props.dropdown,
      () => {
        inputDropdown.value = props.dropdown;
      }
    );
    return {
      inputDropdown,
      inputDefaultOption,
      inputDefaultValue,
      inputLabel,
      inputId,
      input,
    };
  },
};
</script>

<style scoped>
</style>