<template>
    <div class="row mt-5 w-100">
        <div class="col-md-4">
            <div class="card text-center shadow">
            <h2 class="card-header background text-light">Deck</h2>
            <div class="card-body backgroundBody">
                <div class="d-flex justify-content-around align-items-stretch">
                <figure class="figure w-50">
                    <h3>
                    {{
                        $store.getters.getDashboardTicketsOwner[0]?.deckTicket || 0
                    }}
                    </h3>
                    <figcaption class="figure-caption">
                    <h5>Tickets</h5>
                    </figcaption>
                </figure>
                <div class="dashedBorder"></div>
                <figure class="figure w-50">
                    <h3>
                    &#2547;{{
                        $store.getters.getDashboardTicketsOwner[0]?.deckAmount || 0
                    }}
                    </h3>
                    <figcaption class="figure-caption">
                    <h5>Amount</h5>
                    </figcaption>
                </figure>
                </div>
            </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="card text-center shadow">
            <h2 class="card-header background text-light">Goods</h2>
            <div class="card-body backgroundBody">
                <div class="d-flex justify-content-around align-items-stretch">
                <figure class="figure w-50">
                    <h3>
                    {{
                        $store.getters.getDashboardTicketsOwner[0]?.goodsTicket || 0
                    }}
                    </h3>
                    <figcaption class="figure-caption">
                    <h5>Tickets</h5>
                    </figcaption>
                </figure>
                <div class="dashedBorder"></div>
                <figure class="figure w-50">
                    <h3>
                    &#2547;{{
                        $store.getters.getDashboardTicketsOwner[0]?.goodsAmount || 0
                    }}
                    </h3>
                    <figcaption class="figure-caption">
                    <h5>Amount</h5>
                    </figcaption>
                </figure>
                </div>
            </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="card text-center shadow">
            <h2 class="card-header background text-light">Seat</h2>
            <div class="card-body backgroundBody">
                <div class="d-flex justify-content-around align-items-stretch">
                <figure class="figure w-50">
                    <h3>
                    {{
                        $store.getters.getDashboardTicketsOwner[0]?.seatTicket || 0
                    }}
                    </h3>
                    <figcaption class="figure-caption">
                    <h5>Tickets</h5>
                    </figcaption>
                </figure>
                <div class="dashedBorder"></div>
                <figure class="figure w-50">
                    <h3>
                    &#2547;{{
                        $store.getters.getDashboardTicketsOwner[0]?.seatAmount || 0
                    }}
                    </h3>
                    <figcaption class="figure-caption">
                    <h5>Amount</h5>
                    </figcaption>
                </figure>
                </div>
            </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.dashedBorder {
  border-right: 3px dashed #cf200d21;
}
.background {
  background-color: #f04935;
}
.backgroundBody {
  background-color: #eee;
}
h3,
h5 {
  font-size: 36px;
  color: #f04935;
}
h5 {
  font-size: 18px;
}
</style>