<template>
  <div>
    <div
      class="card pb-3 rounded-0"
      style="background-color: #dcdcdc;"
    >
      <div
          class="card-body m-auto p-5"
          style="min-width: 400px"
      >
        <form>
          <div class="mb-3">
            <label for="inputPassword2"><h5>Trip Code</h5></label>
            <input type="text"
                   class="form-control"
                   id="inputPassword2"
                   placeholder="Enter Trip code"
                   v-model="verifyData.tripCode"
            >
          </div>
          <div class="mb-3">
            <label for="exampleFormControlTextarea1" class="form-label"><h5>Verification Code</h5></label>
            <textarea class="form-control"
                      id="exampleFormControlTextarea1"
                      rows="4"
                      placeholder="Scan QR"
                      v-on:keyup.enter="submit"
                      v-model="verifyData.verificationCode"
            ></textarea>
          </div>

          <button type="submit"
                  class="btn btn-success btn-lg w-50"
                  @click.prevent="submit"
          >Verify</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from "@vue/reactivity";
import { inject,   } from "vue";
import store from "@/store";
import toast from "@/modules/Toast";

export default {

  setup() {

    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    let verifyData = reactive({
      tripCode: "",
      verificationCode: "",
    });
    const { errorToast, successToast } = toast();
    const clear = () => {
      verifyData.verificationCode = "";
    };
    const submit = () => {
      if (verifyData.tripCode && verifyData.verificationCode){
        verifyData.verificationCode = verifyData.verificationCode.trim().split('\n')[0]
        showLoader();
        store
            .dispatch("verifySupervisorTicket", verifyData)
            .then((data) => {
              successToast(data.message)
              clear()
              hideLoader();
            })
            .catch((error) => {
              clear()
              hideLoader();
              if (error.status === 401) {
                submit();
              } else if (error.status === 400) {
                const { data } = error;
                if (!Object.prototype.hasOwnProperty.call(data, "message")) {
                  errorToast("Invalid Code!");
                }
              }
            });
      }
    };


    return {
      verifyData,
      submit,



    };
  },
};
</script>

<style   scoped>
.btn{
  padding-top: 4px;
  padding-bottom: 4px;
}
</style>