<template>
    <div>
        <FilterTab>
            <template v-slot:title><h6>Owner</h6></template>
            <template v-slot:body>
                <div class="col-sm-6 col-md-2">
                    <!--          <DropdownFilter v-bind:id="'status'" v-bind:label="'Status'" v-bind:defaultOption="'&#45;&#45;select status&#45;&#45;'"-->
                    <!--                          v-bind:defaultValue="''"-->
                    <!--                          v-bind:dropdown="dropdownVariables.status"/>-->
                </div>
            </template>
            <template v-if="hasPermission(Admin.companyDeployWrite)" v-slot:button1>
                <button class="edit_table_data font_detail" @click="addModal">
                    <i class="fas fa-plus"></i><span class="mx-1 bold">Add New</span>
                </button>
            </template>
        </FilterTab>
        <Table v-if="$store.getters.getOwners">
            <template v-slot:header>
                <tr>
                    <td class="font_detail">SL</td>
                    <td class="font_detail">NAME</td>
                    <td class="font_detail">MOBILE</td>
                    <td class="font_detail">EMAIL</td>
                    <td class="font_detail">STATUS</td>
                    <td
                            v-if="hasPermission(Admin.companyDeployWrite)"
                            class="font_detail"
                    >
                        ACTION
                    </td>
                </tr>
            </template>
            <template v-slot:body>
                <tr v-for="(owner, index) in $store.getters.getOwners" :key="owner._id">
                    <td class="font_detail">{{ pagination.limit*(pagination.page-1) +index +1 }}</td>
                    <td class="font_detail">{{ owner.name }}</td>
                    <td class="font_detail">{{ owner.phone }}</td>
                    <td class="font_detail">{{ owner.email }}</td>
                    <td>
            <span v-if="owner.status" class="badge bg-success">{{
                    dropdownVariables.status[1]
                }}</span>
                        <span v-else class="badge bg-danger">{{
                                dropdownVariables.status[0]
                            }}</span>
                    </td>
                    <td v-if="hasPermission(Admin.companyDeployWrite)">
                        <button
                                class="edit_table_data font_detail edit_table_button"
                                @click="updateModal(owner._id)"
                        >
                            <i class="fas fa-edit"></i><span class="mx-1">Edit</span>
                        </button>
                    </td>
                </tr>
            </template>
            <template v-slot:pagination>
                <pagination
                        v-model="pagination.page"
                        :records="$store.getters.getTotalOwners"
                        :per-page="pagination.limit"
                        @paginate="getAllOwners"
                        :options="options"
                />
            </template>
        </Table>
        <div v-if="showModal">
            <AddUpdateModal
                    v-model="showModal"
                    @getAllOwners="getAllOwners"
                    v-bind:ownerId="ownerId"
            />
        </div>
    </div>
</template>

<script>
import {reactive} from "@vue/reactivity";
import store from "@/store";
import {inject, onBeforeMount, onMounted, ref} from "vue";
import dropdownVariables from "@/modules/dropdownVariables";
import AddUpdateModal from "./OwnerAddUpdateModal";
import {useRoute} from "vue-router";
import permission from "@/modules/permission";
import roles from "@/modules/roles";

const {hasPermission} = permission();
const {Admin} = roles();
// import DropdownFilter from "@/components/layouts/Filter/Inputs/Dropdown";

export default {
    name: "Owner",
    components: {AddUpdateModal},
    setup() {
        let pagination = reactive({
            page: 1,
            limit: 10,
            companyId: "",
        });
        const options = {
            texts: {
                count: "",
            },
        };
        const showModal = ref(false);
        const ownerId = ref("");
        const route = useRoute();
        const showLoader = inject("showLoader");
        const hideLoader = inject("hideLoader");

        const getAllOwners = () => {
            showLoader();
            store
                .dispatch("getOwnersByPage", pagination)
                .then(() => {
                    hideLoader();
                })
                .catch((error) => {
                    hideLoader();
                    if (error.status === 401) {
                        getAllOwners();
                    }
                });
        };
        const addModal = () => {
            ownerId.value = "";
            showModal.value = true;
        };
        const updateModal = (value) => {
            ownerId.value = value;
            showModal.value = true;
        };

        onMounted(getAllOwners);
        onBeforeMount(() => {
            if (route.query.id) {
                pagination.companyId = route.query.id;
            }
        });

        return {
            options,
            showModal,
            dropdownVariables,
            pagination,
            ownerId,
            Admin,
            hasPermission,
            getAllOwners,
            addModal,
            updateModal,
        };
    },
};
</script>

<style scoped>
.bold {
    font-weight: bold;
}
</style>