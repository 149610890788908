<template>
  <label :for="inputId" class="col-sm-3 col-form-label font_detail">{{ inputLabel }}</label>
  <div class="col-sm-9">
    <select class="form-select form-control font_detail w-100"
            v-model="input" :id="inputId" :disabled="inputDisabled">
      <option v-if="inputDefaultOption" :value="inputDefaultValue">{{ inputDefaultOption }}</option>
      <option v-for="(type,index) in inputDropdown" :key="index" :value="index">
        {{ type }}
      </option>
    </select>
    <slot name="error"></slot>
  </div>
</template>

<script>
import {ref, watch} from "vue";

export default {
  name: "Dropdown",
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    modelValue: [String, Number],
    dropdown: Object,
    defaultOption: String,
    defaultValue: String,
    label: String,
    id: String,
    isDisabled: Boolean
  },
  setup(props, {emit}) {
    const inputDropdown = ref(props.dropdown);
    const inputDefaultOption = ref(props.defaultOption);
    const inputDefaultValue = ref(props.defaultValue);
    const inputLabel = ref(props.label);
    const inputId = ref(props.id);
    const input = ref(props.modelValue);
    const inputDisabled = ref(props.isDisabled);
    watch(() => input.value, () => {
      emit("update:modelValue", input.value);
    });
    watch(() => props.dropdown, (value) => {
      inputDropdown.value = value
    });
    watch(() => props.modelValue, (value) => {
      input.value = value
    });
    return {
      inputDropdown, inputDefaultOption, inputDefaultValue, inputLabel, inputId,
      input, props, inputDisabled
    };
  }
};
</script>

<style scoped>

</style>