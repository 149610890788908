<template>
  <header id="topHeader">
    <nav class="navbar navbar-expand-lg navbar-light navbar_bg">
      <div class="container-fluid">
        <router-link
            v-if="hasPermission(Counterman.countermanTicketBooking)"
            class="navbar-brand brand_name menu_font_detail home-logout"
            v-bind:to="{ name: web.Counterman.DASHBOARD_URL.name }"
        >{{ web.Counterman.DASHBOARD_URL.title }}
        </router-link>
        <button
            aria-controls="navbarTogglerDemo03"
            aria-expanded="false"
            aria-label="Toggle navigation"
            class="navbar-toggler mobile_nav_toggle"
            data-bs-target="#navbarTogglerDemo03"
            data-bs-toggle="collapse"
            type="button"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div id="navbarTogglerDemo03" class="collapse navbar-collapse">
          <ul
              class="
              navbar-nav
              dashboard_nav
              me-auto
              mb-2 mb-lg-0
              menu_font_detail
            "
          >
            <li class="nav-item">
              <router-link
                  v-if="hasPermission(Counterman.countermanReports)"
                  class="nav-link menu_font_detail"
                  v-bind:to="{ name: web.Counterman.REPORT_URL.name }"
              >{{ web.Counterman.REPORT_URL.title }}
              </router-link
              >
            </li>
            <li class="nav-item">
              <router-link
                  v-if="hasPermission(Counterman.countermanTrips)"
                  class="nav-link menu_font_detail"
                  v-bind:to="{ name: web.Counterman.TRIPS_URL.name }"
              >{{ web.Counterman.TRIPS_URL.title }}
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                  v-if="hasPermission(Counterman.countermanShips)"
                  class="nav-link menu_font_detail"
                  v-bind:to="{ name: web.Counterman.SHIPS_URL.name }"
              >{{ web.Counterman.SHIPS_URL.title }}
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                  class="nav-link menu_font_detail"
                  v-bind:to="{ name: web.Counterman.VERIFY_URL.name }"
              >
                {{ web.Counterman.VERIFY_URL.title }}
              </router-link>
            </li>
            <li v-if="hasPermission(Counterman.countermanUserReports)"
                class="nav-item">
              <router-link
                  class="nav-link menu_font_detail"
                  v-bind:to="{ name: web.Counterman.TICKET_URL.name }"
              >
                {{ web.Counterman.TICKET_URL.title }}
              </router-link>
            </li>
          </ul>
          <ul class="navbar-nav dashboard_nav d-flex menu_font_detail">
            <li class="nav-item dropdown">
              <a
                  id="navbarScrollingDropdown"
                  aria-expanded="false"
                  class="nav-link dropdown-toggle menu_font_detail"
                  data-bs-toggle="dropdown"
                  href="#"
                  role="button"
              >
                <span
                    v-bind:class="
                    currentPath ===
                    web.Counterman.path + '/' + web.Counterman.PROFILE_URL.path
                      ? 'nav-active'
                      : ''
                  "
                >{{ $store.getters.userName }}</span
                >
              </a>
              <ul
                  aria-labelledby="navbarScrollingDropdown"
                  class="dropdown-menu intercity_login_dropdown"
              >
                <!-- <li>
                  <router-link v-bind:to="{name:web.Counterman.PROFILE_URL.name}" class="dropdown-item text-dark menu_font_detail">
                    {{ web.Counterman.PROFILE_URL.title }}
                  </router-link>

                </li> -->
                <!-- <li>
                  <hr class="dropdown-divider">
                </li> -->
                <li>
                  <p id="logoutMessage" style="display: none">
                    {{ logoutMessage }}
                  </p>
                  <button
                      class="dropdown-item text-dark menu_font_detail"
                      onclick="logoutMessageAndroid(document.getElementById('logoutMessage').innerHTML)"
                      @click="logout"
                  >
                    {{ web.Default.LOGOUT_URL.title }}
                  </button>
                </li>
                <li></li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import {useRoute, useRouter} from "vue-router";
import {computed} from "vue";
import store from "@/store";
import web from "@/urls/web";
import permission from "@/modules/permission";
import roles from "@/modules/roles";

const {hasPermission} = permission();
const {Counterman} = roles();

export default {
  name: "CountermanNav",
  setup() {
    const route = useRoute();
    const router = useRouter();
    const currentPath = computed(() => route.path);
    const logoutMessage = "logout sucessfully";

    const logout = () => {
      store
          .dispatch("logout")
          .then(() => {
            router
                .push(web.Default.LOGIN_URL.path)
                .catch((error) => console.log(error));
          })
          .catch(() => {
          });
    };

    return {
      currentPath,
      web,
      Counterman,
      hasPermission,
      logout,
      logoutMessage,
    };
  },
};
</script>

<style scoped>
nav .active,
nav .exact-active,
.nav-active {
  color: #ff6347 !important;
  outline: none;
}

.dashboard_nav li a:hover {
  color: #ff6347 !important;
}
</style>
