<template>
  <div>
    <FilterTab>
      <template v-slot:body>
        <div class="col-sm-6 col-md-2">
          <DropdownFilter
              v-bind:id="'ticketType'"
              v-bind:label="'Ticket Type'"
              v-bind:defaultOption="'--select all--'"
              v-model="ticketTypeForFilter"
              v-bind:defaultValue="''"
              v-bind:dropdown="ticketTypesForFilter"
          />
        </div>
        <div class="col-sm-6 col-md-2">
          <DropdownFilter
            v-bind:id="'deckType'"
            v-bind:label="'Deck Type'"
            v-bind:defaultOption="'--select all--'"
            v-model="deckTypeForFilter"
            v-bind:defaultValue="''"
            v-bind:dropdown="deckTypesForFilter"
          />
        </div>
        <div class="col-sm-6 col-md-2">
          <DropdownFilter
              v-bind:id="'boardingPlace'"
              v-bind:label="'Boarding Place'"
              v-bind:defaultOption="'--select all--'"
              v-model="boardingPlaceForFilter"
              v-bind:defaultValue="''"
              v-bind:dropdown="boardingPlacesForFilter"
          />
        </div>
        <div class="col-sm-6 col-md-2">
          <DropdownFilter
              v-bind:id="'droppingPoint'"
              v-bind:label="'Dropping Point'"
              v-bind:defaultOption="'--select all--'"
              v-model="droppingPointForFilter"
              v-bind:defaultValue="''"
              v-bind:dropdown="droppingPointsForFilter"
          />
        </div>
        <div class="col-sm-6 col-md-2">
          <DropdownFilter
              v-bind:id="'fareAmount'"
              v-bind:label="'Fare Amount'"
              v-bind:defaultOption="'--select all--'"
              v-model="fareAmountForFilter"
              v-bind:defaultValue="''"
              v-bind:dropdown="fareAmountsForFilter"

          />
        </div>
      </template>
      <template v-slot:button2>
        <button class="edit_table_data font_detail" @click="goBack">
          <i class="fas fa-arrow-left"></i
          ><span class="mx-1 bold">Go Back</span>
        </button>
      </template>
      <template v-slot:button1>
        <button
          class="edit_table_data font_detail"
          @click="exportExcel('xlsx')"
        >
          <i class="fas fa-download"></i><span class="mx-1 bold">Export</span>
        </button>
      </template>
    </FilterTab>
  </div>
  <Table v-if="deckmanWiseReport.deckmanId !== ''" id="exportable_data">
    <template v-slot:header>
      <tr>
        <td class="font_detail">Ticket Date Time</td>
        <td class="font_detail">Ticket Serial</td>
        <td class="font_detail">Trip Code</td>
        <td class="font_detail">Ticket Type</td>
        <td class="font_detail">Goods Type</td>
        <td class="font_detail">Boarding Place</td>
        <td class="font_detail">Dropping Point</td>
        <td class="font_detail">Fare</td>
        <td class="font_detail">Deck Type</td>
        <td class="font_detail">Fare Update Cause</td>
      </tr>
    </template>
    <template v-slot:body>
      <tr
        v-for="item in filteredDeckDetails"
        :key="item._id"
      >
        <td class="font_detail">{{ dateFormat(item.ticketDateTime) }}</td>

        <td class="font_detail">{{ item.serial }}</td>
        <td class="font_detail">{{ item.tripCode }}</td>
        <td class="font_detail">{{ item.ticketType }}</td>
        <td class="font_detail">{{ item.goodsType || "N/A" }}</td>
        <td class="font_detail">{{ item.boardingPlace || "N/A" }}</td>

        <td class="font_detail">{{ item.droppingPoint  || "N/A" }}</td>
        <td class="font_detail">{{ item.payable }} Taka</td>
        <td class="font_detail">{{ item.deckType || 'N/A' }}</td>
        <td class="font_detail">{{ item.fareUpdateCause || 'N/A' }}</td>
      </tr>
      <tr class="bg-dark text-white" v-if="summary">
        <td class="font_detail"></td>
        <td class="font_detail"></td>
        <td class="font_detail"></td>
        <td class="font_detail"></td>
        <td class="font_detail"></td>
        <td class="font_detail"></td>
        <td class="font_detail"></td>
        <td class="font_detail text-white">
          Total Fare:
          {{ totalPayable }} Taka
        </td>
        <td class="font_detail"></td>
        <td class="font_detail"></td>
      </tr>
    </template>
    <!-- <template v-slot:pagination>
      <pagination
        v-if="$store.getters.getTotalDeckmanWiseReportDetailsOwner"
        v-model="deckmanWiseReport.page"
        :records="$store.getters.getTotalDeckmanWiseReportDetailsOwner"
        :per-page="deckmanWiseReport.limit"
        @paginate="getDeckmanDetails"
        :options="options"
      />
    </template> -->
  </Table>
</template>

<script>
import { reactive } from "@vue/reactivity";
import { ref, onBeforeMount, inject, watch } from "vue";
import store from "@/store";
import moment from "moment";
import XLSX from "xlsx";


export default {
  name: "DeckmanDetailsOwner",
  components: { },
  props: {
    deckmanId: String,
    fromDate: String,
    toDate: String,
    status: Number,
    tripCode: [Number, String],
    direction: String,
  },
  emits: ["backToDeckmanWiseReport"],
  setup(props, { emit }) {
    let deckmanId = ref(props.deckmanId);
    let fromDate = ref(props.fromDate);
    let toDate = ref(props.toDate);
    let direction = ref(props.direction);
    let tripCode = ref(props.tripCode);
    let status = ref(props.status);
    let totalPayable = ref(0);

    const boardingPlaceForFilter = ref("");
    const droppingPointForFilter = ref("");
    const ticketTypeForFilter = ref("");
    const deckTypeForFilter = ref("");
    const fareAmountForFilter = ref("");

    const boardingPlacesForFilter = ref({});
    const droppingPointsForFilter = ref({});
    const fareAmountsForFilter = ref({});
    const ticketTypesForFilter = ref({});
    let deckTypesForFilter = ref({});
    let filteredDeckDetails = ref([]);

    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");

    let summary = ref(false);

    let deckmanWiseReport = reactive({
      deckmanId: deckmanId.value,
      fromDate: fromDate.value,
      toDate: toDate.value,
      direction: direction.value,
      status: status.value,
      tripCode: tripCode.value,
      page: 1,
      limit: 10,
    });

    const options = {
      texts: {
        count: "",
      },
    };
    
    watch(
      [boardingPlaceForFilter, droppingPointForFilter, ticketTypeForFilter, deckTypeForFilter, fareAmountForFilter],
      ([bPlace, dPoint, ticketType, deckType, fare]) => {
        const fareAmount = parseInt(fare);
        let reportData = store.getters.getDeckmanWiseReportDetailsOwner
        filteredDeckDetails.value = reportData.filter(
          (item) => {
            let result = true
            bPlace && (result &&= item.boardingPlace === bPlace)
            dPoint && (result &&=  item.droppingPoint === dPoint)
            ticketType && (result &&=  item.ticketType === ticketType)
            deckType && (result &&= item.deckType === deckType)
            fareAmount && (result &&= item.payable === fareAmount)
            return result
          }
        );
        calculateTotal();
      }
    );

    const dateFormat = (date) => {
      return moment(String(date))
        .utcOffset(0, false)
        .format("DD-MMM-yyyy hh:mm A");
    };

    const getDeckmanDetails = () => {
      showLoader();
      store
        .dispatch("getDeckmanWiseReportDetailsOwner", deckmanWiseReport)
        .then((response) => {
          if (response.length === 0) {
            summary.value = false;
          } else {
            summary.value = true;
            totalPayable.value = 0;
            seatDetailsdata();
          }

          hideLoader();
        })
        .catch(() => hideLoader());
    };

    const seatDetailsdata = () => {
      filteredDeckDetails.value = store.getters.getDeckmanWiseReportDetailsOwner
     initFilterDropdown()
      calculateTotal()

    };

    const initFilterDropdown = ()=>{
      filteredDeckDetails.value.forEach((data) => {
        if (data.boardingPlace){
          boardingPlacesForFilter.value[data.boardingPlace] = data.boardingPlace
        }
        if (data.droppingPoint){
          droppingPointsForFilter.value[data.droppingPoint] = data.droppingPoint
        }
        ticketTypesForFilter.value[data.ticketType] = data.ticketType
        deckTypesForFilter.value[data.deckType] = data.deckType;
        fareAmountsForFilter.value[data.payable] = data.payable

      });
    }

    const calculateTotal = () => {
      totalPayable.value = 0
      filteredDeckDetails.value.forEach((item) => {
        totalPayable.value += item.payable;
      })
    }

    const goBack = () => {
      emit("backToDeckmanWiseReport", true);
    };

    const exportExcel = (type, fn, dl) => {
      if (document.getElementById("exportable_data") !== null) {
        var elt = document.getElementById("exportable_data");
        var wb = XLSX.utils.table_to_book(elt, { sheet: "sheet Js" });
        return dl
          ? XLSX.write(wb, { bookType: type, bookSST: true, type: "base64" })
          : XLSX.writeFile(
              wb,
              fn || "Owner_DeckmanWise_Details_Report." + (type || "xlsx")
            );
      }
    };

    onBeforeMount(() => {
      getDeckmanDetails();
    });

    return {
      deckmanWiseReport,
      goBack,
      options,
      summary,
      dateFormat,
      totalPayable,
      exportExcel,
      getDeckmanDetails,
      boardingPlaceForFilter,
      boardingPlacesForFilter,
      droppingPointForFilter,
      fareAmountForFilter,
      droppingPointsForFilter,
      fareAmountsForFilter,
      ticketTypeForFilter,
      deckTypeForFilter,
      ticketTypesForFilter,
      deckTypesForFilter,
      filteredDeckDetails,
    };
  },
};
</script>
