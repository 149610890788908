<template>
  <div>
    <FilterTab>
      <template v-slot:body>
        <div class="col-sm-6 col-md-2"></div>
      </template>
      <template v-slot:button1>
        <div>
          <p style="display: none" id="supervisorReport">{{ supervisorReportPrintData }}</p>
          <p style="display: none" id="type">{{ type }}</p>
          <p style="display: none" id="printCount">{{ printCount }}</p>
          <p style="display: none" id="once">{{ once }}</p>
          <!-- <button 
            class="edit_table_data font_detail"
            @click="printSupervisorReport(tripID)"
            >
            <i class="fas fa-print"></i>
            <span class="mx-1 bold">Print</span>
          </button> -->
        </div>
      </template>
      <template v-slot:button2>
        <button class="edit_table_data font_detail" @click="goBack">
          <i class="fas fa-arrow-left"></i
            ><span class="mx-1 bold">Go Back</span>
          </button>
        </template>
      </FilterTab>
    </div>
    <TripReportPrintPreviewForSupervisor
      v-if="printPreview"
        :printData="supervisorReportPrintData"
        :ticketType="type"
        :printCount="printCount"
        :reportOnce="once"
        :printCountStatus="printCountStatus"
        :tripId="tripId"
        :tripDateAndTime="tripDateTime"
        :selectedShip="selectedShip"
        :routesName="routesName"
        @close="closePrintPreview"
      />
  <div>
    <div class="card-header text-center bg-white mt-4">
      <h5 class="mb-0 text-uppercase report-details-title">{{ selectedShip }}</h5>
    </div>
    <Table>
      <template v-slot:header>
        <tr>
          <td class="font_detail">Deck Ticket</td>
          <td class="font_detail">Deck Amount</td>
          <td class="font_detail">Goods Ticket</td>
          <td class="font_detail">Goods Amount</td>
        </tr>
      </template>
      <template v-slot:body>
        <tr>
          <td class="font_detail">
            {{ $store.getters.getSupervisorReport.deckTicket }}
          </td>
          <td class="font_detail">
            {{ $store.getters.getSupervisorReport.deckAmount }}
          </td>
          <td class="font_detail">
            {{ $store.getters.getSupervisorReport.goodsTicket }}
          </td>
          <td class="font_detail">
            {{ $store.getters.getSupervisorReport.goodsAmount }}
          </td>
        </tr>
      </template>
    </Table>
    <div v-if="seatTicketsActive">
      <div
        class="card h-100 mt-4"
        v-for="(seatTicket, itemKey) in $store.getters.getSupervisorReport.seatTickets"
        :key="itemKey"
    >
      <div class="card-header text-center bg-white">
        <h5 class="mb-0 text-uppercase">{{ seatTicket.seatClassName }}</h5>
      </div>
      <div class="card-body mt-0 py-0">
        <table-simple :id="itemKey">
          <template v-slot:header>
            <tr>
              <td class="font_detail">SL</td>
              <td class="font_detail">Ticket Date Time</td>
              <td class="font_detail">Passenger Name</td>
              <td class="font_detail">Passenger Phone</td>
              <td class="font_detail">Boarding Place</td>
              <td class="font_detail">Dropping Place</td>
              <td class="font_detail">Seat Number</td>
              <td class="font_detail">Total Ticket</td>
              <td class="font_detail">Issued By</td>
              <td class="font_detail">Discount</td>
              <td class="font_detail">Amount Received</td>
            </tr>
          </template>
          <template v-slot:body>
            <tr v-for="(item, index) in seatTicket.tickets" :key="index">
              <td class="font_detail" :class="item.userType === 'cabinman' || item.isConfirmedByCabinMan ? 'cabinmanTicket' : ''">{{ index + 1 }}</td>
              <td class="font_detail" :class="item.userType === 'cabinman' || item.isConfirmedByCabinMan ? 'cabinmanTicket' : ''">{{ dateFormat(item.ticketDateTime) }}</td>
              <td class="font_detail" :class="item.userType === 'cabinman' || item.isConfirmedByCabinMan ? 'cabinmanTicket' : ''">{{ item.passengerName || 'N/A' }}</td>
              <td class="font_detail" :class="item.userType === 'cabinman' || item.isConfirmedByCabinMan ? 'cabinmanTicket' : ''">{{ item.passengerPhone || 'N/A' }}</td>
              <td class="font_detail" :class="item.userType === 'cabinman' || item.isConfirmedByCabinMan ? 'cabinmanTicket' : ''">{{ item.boardingPlace }}</td>
              <td class="font_detail" :class="item.userType === 'cabinman' || item.isConfirmedByCabinMan ? 'cabinmanTicket' : ''">{{ item.droppingPoint }}</td>
              <td class="font_detail" :class="item.userType === 'cabinman' || item.isConfirmedByCabinMan ? 'cabinmanTicket' : ''">{{ item.seatNumbers }}</td>
              <td class="font_detail" :class="item.userType === 'cabinman' || item.isConfirmedByCabinMan ? 'cabinmanTicket' : ''">{{ item.totalTicket }}</td>
              <td class="font_detail" :class="item.userType === 'cabinman' || item.isConfirmedByCabinMan ? 'cabinmanTicket' : ''">{{ item.issuedBy}}</td>
              <td class="font_detail" :class="item.userType === 'cabinman' || item.isConfirmedByCabinMan ? 'cabinmanTicket' : ''">{{ item.discount }}</td>
              <td class="font_detail" :class="item.userType === 'cabinman' || item.isConfirmedByCabinMan ? 'cabinmanTicket' : ''">{{ item.payable }}</td>
            </tr>
            <tr class="bg-dark text-white">
              <td class="font_detail"></td>
              <td class="font_detail"></td>
              <td class="font_detail"></td>
              <td class="font_detail"></td>
              <td class="font_detail"></td>
              <td class="font_detail"></td>
              <td class="font_detail"></td>
              <td class="font_detail text-white">
                Total Ticket: {{ seatTicket.totalTickets }}
              </td>
              <td class="font_detail"></td>
              <td class="font_detail text-white">
                Total Discount: {{ seatTicket.totalDiscount }} Taka
              </td>
              <td class="font_detail text-white">
                Total Received: {{ seatTicket.totalPayable }} Taka
              </td>
            </tr>
          </template>
        </table-simple>
      </div>
    </div>
    </div>

    <div v-if="$store.getters.getSupervisorReport.dtTickets.length">
      <div class="card-header text-center bg_dt_corporate mt-5">
        <h5 class="mb-0 text-uppercase">DT Tickets</h5>
      </div>
      <div
        class="card h-100 mt-4"
        v-for="(seatTicket, itemKey) in $store.getters.getSupervisorReport.dtTickets"
        :key="itemKey"
    >
      <div class="card-header text-center bg-white">
        <h5 class="mb-0 text-uppercase">{{ seatTicket.seatClassName }}</h5>
      </div>
      <div class="card-body mt-0 py-0">
        <table-simple :id="itemKey">
          <template v-slot:dtHeader>
            <tr>
              <td class="font_detail">SL</td>
              <td class="font_detail">Ticket Date Time</td>
              <td class="font_detail">Passenger Name</td>
              <td class="font_detail">Passenger Phone</td>
              <td class="font_detail">Boarding Place</td>
              <td class="font_detail">Dropping Place</td>
              <td class="font_detail">Seat Number</td>
              <td class="font_detail">Total Ticket</td>
              <td class="font_detail">Issued By</td>
              <td class="font_detail">Discount</td>
              <td class="font_detail">Amount Received</td>
            </tr>
          </template>
          <template v-slot:body>
            <tr v-for="(item, index) in seatTicket.tickets" :key="index">
              <td class="font_detail">{{ index + 1 }}</td>
              <td class="font_detail">{{ dateFormat(item.ticketDateTime) }}</td>
              <td class="font_detail">{{ item.passengerName }}</td>
              <td class="font_detail">{{ item.passengerPhone }}</td>
              <td class="font_detail">{{ item.boardingPlace }}</td>
              <td class="font_detail">{{ item.droppingPoint }}</td>
              <td class="font_detail">{{ item.seatNumbers }}</td>
              <td class="font_detail">{{ item.totalTicket }}</td>
              <td class="font_detail">{{ item.issuedBy}}</td>
              <td class="font_detail">{{ item.discount }}</td>
              <td class="font_detail">{{ item.payable }}</td>
            </tr>
            <tr class="bg-dark text-white">
              <td class="font_detail"></td>
              <td class="font_detail"></td>
              <td class="font_detail"></td>
              <td class="font_detail"></td>
              <td class="font_detail"></td>
              <td class="font_detail"></td>
              <td class="font_detail"></td>
              <td class="font_detail text-white">
                Total Ticket: {{ seatTicket.totalTickets }}
              </td>
              <td class="font_detail"></td>
              <td class="font_detail text-white">
                Total Discount: {{ seatTicket.totalDiscount }}
              </td>
              <td class="font_detail text-white">
                Total Received: {{ seatTicket.totalPayable }} Taka
              </td>
            </tr>
          </template>
        </table-simple>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { onBeforeMount, ref, inject, onMounted } from "vue";
import store from "@/store";
import toast from "@/modules/Toast";
import dropdownVariables from "@/modules/dropdownVariables";
import TripReportPrintPreviewForSupervisor from "@/components/layouts/Print/TripReportPrintPreviewForSupervisor.vue";

const { errorToast } = toast();
export default {
    name: "ReportDetails",
    props: {
        tripId: String,
        selectedShipName: String,
        routeName: String,
        tripDate: String,
        tripCode: String,
        tripDirection: String,
    },
    emits: ["backToReportList"],
    setup(props, { emit }) {
        let tripID = ref(props.tripId);
        let selectedShip = ref(props.selectedShipName);
        let routesName = ref(props.routeName);
        let tripDateTime = ref(props.tripDate);
        const type = ref("supervisorTripWiseReport");
        let printCount = ref(0);
        let printCountStatus = ref(false);
        let printPreview = ref(false);
        let once = ref(1);
        let supervisorReportPrintData = ref({});
        const showLoader = inject("showLoader");
        const hideLoader = inject("hideLoader");
        const seatTicketsActive = ref(false);
        const tripInformation = ref({
          tripinfo:{
            shipName : props.selectedShipName,
            routeName: props.routeName,
            tripDateTime: props.tripDate,
            direction: props.tripDirection,
            tripCode: props.tripCode
          }
        })
        const dateFormat = (date) => {
            return moment(String(date))
                .utcOffset(6, false)
                .format("DD-MMM-yyyy hh:mm A");
        };
        const getSupervisorReport = () => {
            showLoader();
            store.dispatch("getAllSupervisorReport", { tripId: tripID.value }).then(() => {
                hideLoader();
                seatTicketsActive.value = true;
            });
        };
        const closePrintPreview = () => {
            printPreview.value = false;
            goBack();
        };
        const goBack = () => {
            emit("backToReportList", true);
        };
        const printSupervisorReport = (trip_Id) => {
            showLoader();
            once.value += 1;
            store
                .dispatch("getAllSupervisorReportForPrint", { tripId: trip_Id })
                .then((data) => {
                supervisorReportPrintData.value = { ...data};
                hideLoader();
                submitPrintCount();
            })
                .catch(() => {
                hideLoader();
                errorToast("Something wrong.Please try again later.");
            });
        };
        const submitPrintCount = () => {
            showLoader();
            store
                .dispatch("supervisorReportPrintCount", {
                reportType: "supervisorTripWiseReport",
                tripId: tripID.value,
            })
                .then((data) => {
                hideLoader();
                printCount.value = data.printCount + 1;
                printCountStatus.value = data.status;
                printPreview.value = true;
                // submitPrintCountIncrease();
            })
                .catch(() => {
                hideLoader();
                errorToast("Something wrong.Please try again later.");
            });
        };

        onMounted(()=>{
          printSupervisorReport(tripID.value)
        });

        onBeforeMount(() => {
            getSupervisorReport();
        });
        return {
            getSupervisorReport,
            dropdownVariables,
            dateFormat,
            seatTicketsActive,
            goBack,
            selectedShip,
            tripID,
            printSupervisorReport,
            submitPrintCount,
            supervisorReportPrintData,
            type,
            once,
            printCount,
            printCountStatus,
            printPreview,
            closePrintPreview,
            tripDateTime,
            routesName,
            tripInformation
        };
    },
    components: { TripReportPrintPreviewForSupervisor }
};
</script>

<style scoped>
.report-details-title{
  color: #f04935;
  font-size: 1.5rem;
  font-weight: 700;
}
.cabinmanTicket{
    background-color: #bdcbff;
  }
</style>
