import DatePicker from "@/components/layouts/DateTime/DatePicker";
import TimePicker from "@/components/layouts/DateTime/TimePicker";
import Filter from "@/components/layouts/Filter/Filter";
import FilterTab from "@/components/layouts/Filter/FilterTab";
import FilterTabBetween from "@/components/layouts/Filter/FilterTabBetween";
import DatePickerFilter from "@/components/layouts/Filter/Inputs/DatePicker";
import DateTimeRangePicker from "@/components/layouts/Filter/Inputs/DateTimeRangePicker";
import DropdownFilter from "@/components/layouts/Filter/Inputs/Dropdown";
import Checkbox from "@/components/layouts/Inputs/Checkbox";
import Dropdown from "@/components/layouts/Inputs/Dropdown";
import Error from "@/components/layouts/Inputs/Error";
import Input from "@/components/layouts/Inputs/Input";
import TextArea from "@/components/layouts/Inputs/TextArea";
import ModalCheckSwitch from "@/components/layouts/Modals/Inputs/CheckSwitch";
import ModalDropdown from "@/components/layouts/Modals/Inputs/Dropdown";
import ModalError from "@/components/layouts/Modals/Inputs/Error";
import ModalInput from "@/components/layouts/Modals/Inputs/Input";
import ModalMultiselect from "@/components/layouts/Modals/Inputs/MultiSelectDropdown";
import Modal from "@/components/layouts/Modals/Modal";
import TicketPrintPreview from "@/components/layouts/Print/TicketPrintPreview";
import ReservationTicketPrintPreview from "@/components/layouts/Print/ReservationTicketPrintPreview";
import TicketPrintPreviewForAgent from "@/components/layouts/Print/TicketPrintPreviewForAgent";
import TripReportPrintPreview from "@/components/layouts/Print/TripReportPrintPreview";
import TripReportPrintPreviewForAgent from "@/components/layouts/Print/TripReportPrintPreviewForAgent";
import UserReportPrintPreview from "@/components/layouts/Print/UserReportPrintPreview";
import UserReportPrintPreviewForAgent from "@/components/layouts/Print/UserReportPrintPreviewForAgent";
import Table from "@/components/layouts/Tables/Table";
import TableSimple from "@/components/layouts/Tables/TableSimple";
import TicketDropdown from "@/components/layouts/TicketInputs/Dropdown";
import TicketError from "@/components/layouts/TicketInputs/Error";
import TicketInput from "@/components/layouts/TicketInputs/Input";
import TicketInputPhone from "@/components/layouts/TicketInputs/InputPhone";

export default {
  install: (app) => {
    app.component("Table", Table);
    app.component("TableSimple", TableSimple);
    app.component("Modal", Modal);
    app.component("ModalInput", ModalInput);
    app.component("TextArea", TextArea);
    app.component("ModalError", ModalError);
    app.component("ModalDropdown", ModalDropdown);
    app.component("ModalCheckSwitch", ModalCheckSwitch);
    app.component("TicketInput", TicketInput);
    app.component("TicketInputPhone", TicketInputPhone);
    app.component("TicketError", TicketError);
    app.component("TicketDropdown", TicketDropdown);
    app.component("Checkbox", Checkbox);
    app.component("Input", Input);
    app.component("Error", Error);
    app.component("Dropdown", Dropdown);
    app.component("FilterTab", FilterTab);
    app.component("FilterTabBetween", FilterTabBetween);
    app.component("Filter", Filter);
    app.component("ModalMultiselect", ModalMultiselect);
    app.component("DropdownFilter", DropdownFilter);
    app.component("DatePickerFilter", DatePickerFilter);
    app.component("DatePicker", DatePicker);
    app.component("TimePicker", TimePicker);
    app.component("DateTimeRangePicker", DateTimeRangePicker);
    app.component("TicketPrintPreview", TicketPrintPreview);
    app.component("ReservationTicketPrintPreview", ReservationTicketPrintPreview);
    app.component("TripReportPrintPreview", TripReportPrintPreview);
    app.component("TicketPrintPreviewForAgent", TicketPrintPreviewForAgent);
    app.component("UserReportPrintPreview", UserReportPrintPreview);
    app.component("TripReportPrintPreviewForAgent", TripReportPrintPreviewForAgent);
    app.component("UserReportPrintPreviewForAgent", UserReportPrintPreviewForAgent);
  },
};
