<template>
   <li class="list-group-item">
      <div class="row my-2">
         <span v-if="error" class="text-danger err_msg">* {{ error }}</span>
         <div class="col-md-1">
            <Input v-model="seatDesign.prefix"
                  :placeholder="'Prefix'" :id="'prefix'+spId" :type="'text'"/>
            <!--      <Error v-if="errors.prefix" :error="errors.prefix"/>-->
         </div>
         <div class="col-md-1">
            <Input v-model="seatDesign.from"
                  :placeholder="'From'" :id="'from'+spId" :type="'text'"/>
            <div v-if="errorId === spId">
               <Error v-if="errors.from" :error="errors.from"/>
            </div>
         </div>
         <div class="col-md-1">
            <Input v-model="seatDesign.to"
                  :placeholder="'To'" :id="'to'+spId" :type="'text'"/>
            <div v-if="errorId === spId">
               <Error v-if="errors.to" :error="errors.to"/>
            </div>
         </div>
         <div class="col-md-1">
            <Input v-model="seatDesign.suffix"
                  :placeholder="'Suffix'" :id="'suffix'+spId" :type="'text'"/>
            <!--      <Error v-if="errors.suffix" :error="errors.suffix"/>-->
         </div>
         <div class="col-md-1">
            <button :id="'add'+spId" class="add_button mt-1" @click="addSeatPlan()"><i class="fas fa-plus"></i></button>
         </div>
         <div class="col-md-2">
            <Input v-model="seatDesign.customSeats"
                  :placeholder="'Enter Seats(Ex: 101,102,106)'" :id="'custom'+spId" :type="'text'"/>
            <div v-if="errorId === spId">
               <Error v-if="errors.seats" :error="errors.seats"/>
            </div>
         </div>
         <div class="col-md-1">
            <button :id="'addCustom'+spId" class="add_button mt-1" @click="addCustomSeatPlan()"><i
               class="fas fa-plus"></i></button>
         </div>
         <div class="col-md-3">
            <div class="card p-1 h-100">
               <div class="row">
                  <div class="col-auto">
               <span v-for="seat in blockedSeats" :key="seat+spId" class="badge badge-pill allBlockSeat m-1 p-2">{{
                     seat
                  }}</span>
                  </div>
               </div>
            </div>
         </div>
         <div class="col-md-1">
            <button :id="'block'+spId" class="block_button mt-1" @click="blockSeats()"><i class="fas fa-ban"></i>
            </button>
         </div>
      </div>
   </li>
</template>

<script>
import {reactive} from "@vue/reactivity";
import Input from "@/components/layouts/Inputs/Input";
import Error from "@/components/layouts/Inputs/Error";
import useInputValidation from "@/modules/useInputValidations";
import {inject, ref, watch} from "vue";
import store from "@/store";

export default {
   name: "AddSeatRow",
   props: {
      blocked: Object,
      seatPlanId: String,
      ship: String,
      company: String
   },
   emits: ["getSeatPlan"],
   components: {Input, Error},
   setup(props, {emit}) {
      let seatDesign = reactive({
         prefix: "",
         from: "",
         to: "",
         suffix: "",
         customSeats: "",
      });
      let row = reactive({
         shipId: "",
         seatClassId: "",
         seatRow: ""
      });
      let block = reactive({
         shipId: "",
         seatClassId: "",
         seatNumbers: "",
      });
      let spId = ref(props.seatPlanId);
      let blockedSeats = ref(props.blocked);
      let companyId = ref(props.company);
      let shipId = ref(props.ship);
      let errorId = ref("");
      let error = ref("");
      let {requiredValidation, errors} = useInputValidation();
      const showLoader = inject('showLoader');
      const hideLoader = inject('hideLoader');

      watch(() => [...props.blocked], () => {
         blockedSeats.value = props.blocked;
      });

      const clear = () => {
         row.shipId = "";
         row.seatClassId = "";
         row.seatRow = "";
         seatDesign.prefix = "";
         seatDesign.from = "";
         seatDesign.to = "";
         seatDesign.suffix = "";
         seatDesign.customSeats = "";
         block.seatClassId = "";
         block.shipId = "";
         block.seatNumbers = "";
      };
      const validate = () => {
         error.value = "";
         Object.keys(errors).forEach(function (key) {
            errors[key] = ""
         });
         requiredValidation("from", seatDesign.from);
         requiredValidation("to", seatDesign.to);
         if (Object.values(errors).length <= 0 || !Object.values(errors).every(x => x === null || x === '')) {
            return false;
         } else {
            if (parseInt(seatDesign.from) && parseInt(seatDesign.to) && parseInt(seatDesign.from) <= parseInt(seatDesign.to)) {
               return true;
            } else if (typeof seatDesign.from === typeof seatDesign.to && seatDesign.from.length === 1 && seatDesign.to.length === 1) {
               if (/[a-z]/.test(seatDesign.from) && /[a-z]/.test(seatDesign.to) && seatDesign.from.charCodeAt(0) < seatDesign.to.charCodeAt(0)) {
                  return true;
               } else if (/[A-Z]/.test(seatDesign.from) && /[A-Z]/.test(seatDesign.to) && seatDesign.from.charCodeAt(0) < seatDesign.to.charCodeAt(0)) {
                  return true;
               } else {
                  error.value = "Supported format for 'From' and 'To' fields: A-Z/a-z/1-'<'any number'>'";
                  return false;
               }
            } else {
               error.value = "Supported format: A-Z/a-z/1-'<'any number'>'";
               return false;
            }
         }
      };
      const validateCustomSeatPlan = () => {
        error.value = "";
        Object.keys(errors).forEach(function (key) {
          errors[key] = ""
        });
        // requiredValidation("seats", seatDesign.customSeats.trim());

        if (seatDesign.customSeats.trim() === ''){
          return true
        }else{
          if (/\s/g.test(seatDesign.customSeats.trim())) {
            error.value = "Space not allowed";
            return false
          } else if (!/^([A-Za-z0-9-]+,?)+$/.test(seatDesign.customSeats.trim())) {
            error.value = "Invalid seats";
            return false
          } else {
            return true
          }
        }
      };
      const seats = () => {
         let seatList = [];
         if (parseInt(seatDesign.from) && parseInt(seatDesign.to)) {
            for (let i = parseInt(seatDesign.from); i <= parseInt(seatDesign.to); i++) {
               seatList.push(seatDesign.prefix + i + seatDesign.suffix);
            }
         } else {
            for (let i = seatDesign.from.charCodeAt(0); i <= seatDesign.to.charCodeAt(0); i++) {
               seatList.push(seatDesign.prefix + String.fromCharCode(i) + seatDesign.suffix);
            }
         }
         return seatList.join();
      };
      const getSeatPlan = () => {
         emit("getSeatPlan");
      };
      const blockSeats = () => {
         showLoader()
         block.shipId = shipId.value;
         block.seatClassId = spId.value;
         block.seatNumbers = blockedSeats.value.toString();
         store.dispatch("blockSeatsOwner", block).then(() => {
            hideLoader()
            clear();
            getSeatPlan();
         }).catch((error) => {
            hideLoader()
            if (error.status === 401) {
               blockSeats()
            }
         });

      };
      const addSeatPlan = () => {
         if (validate()) {
            showLoader()
            row.shipId = shipId.value;
            row.seatClassId = spId.value;
            row.seatRow = seats();
            store.dispatch("addSeatRowOwner", row).then(() => {
               hideLoader()
               clear();
               getSeatPlan();
            }).catch((error) => {
               hideLoader()
               if (error.status === 401) {
                  addSeatPlan()
               }
            });
         } else {
            errorId.value = spId.value;
         }
      };
      const addCustomSeatPlan = () => {
         if (validateCustomSeatPlan()) {
            showLoader()
            row.shipId = shipId.value;
            row.seatClassId = spId.value;
            row.seatRow = seatDesign.customSeats.trim();
            store.dispatch("addCustomSeatRowByOwner", row).then(() => {
               hideLoader()
               clear();
               getSeatPlan();
            }).catch((error) => {
               hideLoader()
               if (error.status === 401) {
                  addCustomSeatPlan()
               }
            });
         } else {
            errorId.value = spId.value;
         }
      };

      return {
         seatDesign, companyId, shipId, spId, errors, errorId, error, blockedSeats,
         addSeatPlan, blockSeats,addCustomSeatPlan
      };
   }
};
</script>

<style scoped>
.add_button {
   border: 2px solid #17A2B8;
   border-radius: 20px;
   background-color: #17A2B8;
   padding: 5px 10px;
   color: #ffffff;
   position: relative;
   font-size: 14px;
   font-family: Helvetica Neue;
}

.add_button:hover,
.add_button:focus {
   border: 2px solid #17A2B8;
   background-color: #fff;
   color: #17A2B8;
   transition: 0.4s;
   outline: 0;
}

.allBlockSeat{
   background-color: #000 !important;
}
.block_button {
   border: 2px solid #ff0000;
   border-radius: 20px;
   background-color: #ff0000;
   padding: 5px 10px;
   color: #ffffff;
   position: relative;
   font-size: 14px;
   font-family: Helvetica Neue;
}

.block_button:hover,
.block_button:focus {
   border: 2px solid #ff0000;
   background-color: #fff;
   color: #ff0000;
   transition: 0.4s;
   outline: 0;
}
</style>