<template>
   <Modal @close="closeModal">
      <template v-slot:title>{{ title }}</template>
      <template v-slot:body>
         <form>
            <div class="form-group row my-1">
               <ModalInput
                  v-model="seat.seatRow"
                  :placeholder="'Enter Seats'"
                  :label="'Seats'"
                  :id="'seats'"
                  :type="'text'"
               >
                  <template v-slot:error>
                     <ModalError v-if="errors.seat" v-bind:error="errors.seat" />
                     <span v-if="error" class="text-danger err_msg">* {{ error }}</span>
                  </template>
               </ModalInput>
            </div>

            <div class="form-group row my-1">
               <label class="col-sm-3 col-form-label"></label>
               <div class="col-sm-9">
                  <input
                     type="submit"
                     class="mt-3 py-2 submit_button font_detail float-end"
                     @click.prevent="updateSeatRow"
                  />
               </div>
            </div>
         </form>
      </template>
   </Modal>
</template>

<script>
import useInputValidation from "@/modules/useInputValidations";
import { inject, onBeforeMount, ref, watch } from "vue";
import { reactive } from "@vue/reactivity";
import store from "@/store";

export default {
   name: "EditSeatRowModal",
   props: {
      seatList: String,
      seatPlan: String,
      ship: String,
      row: Number,
   },
   setup(props, { emit }) {
      let { requiredValidation, errors } = useInputValidation();
      let seat = reactive({
         shipId: props.ship,
         seatClassId: props.seatPlan,
         rowIndex: props.row,
         seatRow: props.seatList,
      });
      const title = ref("Edit Seats");
      const showLoader = inject("showLoader");
      const hideLoader = inject("hideLoader");
      let error = ref("");

      watch(
         () => seat.seatRow,
         () => {
            requiredValidation("seat", seat.seatRow);
         }
      );
      // watch(
      //     () => props.seatList,
      //     () => {
      //        seat.seatRow = props.seatList
      //     }
      // );

      const closeModal = () => {
         emit("update:modelValue", false);
      };

      const validateCustomSeatPlan = () => {
         error.value = "";
         Object.keys(errors).forEach(function (key) {
            errors[key] = ""
         });
         //requiredValidation("seats", seat.seatRow.trim());
         if (seat.seatRow.trim() === ''){
            return true
         }else{
            if (/\s/g.test(seat.seatRow.trim())) {
               error.value = "Space not allowed";
               return false
            } else if (!/^([A-Za-z0-9-]+,?)+$/.test(seat.seatRow.trim())) {
               error.value = "Invalid seats";
               return false
            } else {
               return true
            }
         }

      };

      const getSeatPlan = () => {
         emit("getSeatPlan");
      }
      const updateSeatRow = () => {
         if (validateCustomSeatPlan()) {
            seat.seatRow = seat.seatRow.trim()
            showLoader();
            store
               .dispatch("editCustomSeatRow", seat)
               .then(() => {
                  hideLoader();
                  getSeatPlan()
                  closeModal();
               })
               .catch((error) => {
                  hideLoader();
                  if (error.status === 401) {
                     updateSeatRow();
                  }
               });
         }
      };

      onBeforeMount(() => {
         Object.keys(errors).forEach(function (key) {
            delete errors[key];
         });
      });

      return {
         seat,
         errors,
         error,
         status,
         title,
         closeModal,
         updateSeatRow,
      };
   },
}
</script>

<style scoped>

</style>