<template>
  <div v-if="hasPermission(Admin.adminRead)">
    <!--    <Filter>-->
    <!--      <template v-slot:body>-->
    <!--        <div class="col-md-2">-->
    <!--        </div>-->
    <!--      </template>-->
    <!--    </Filter>-->
    <Table v-if="$store.getters.getAdmins">
      <template v-slot:header>
        <tr>
            <td class="font_detail">SL</td>
            <td class="font_detail">NAME</td>
            <td class="font_detail">DESIGNATION</td>
            <td class="font_detail">MOBILE</td>
            <td class="font_detail">EMAIL</td>
            <td class="font_detail">USER TYPE</td>
            <td class="font_detail">STATUS</td>
            <td v-if="hasPermission(Admin.adminWrite)" class="font_detail">
                ACTION
            </td>
        </tr>
      </template>
      <template v-slot:body>
          <tr v-for="(user, index) in $store.getters.getAdmins" :key="user._id">
              <td class="font_detail">{{ index + 1 }}</td>
              <td class="font_detail">{{ user.name }}</td>
              <td class="font_detail">{{ user.designation }}</td>
              <td class="font_detail">{{ user.phone }}</td>
              <td class="font_detail">{{ user.email }}</td>
              <td class="font_detail">
                  {{ dropdownVariables.userType[user.userType] }}
              </td>
              <td>
            <span v-if="user.status" class="badge bg-success">{{
                    dropdownVariables.status[1]
                }}</span>
                  <span v-else class="badge bg-danger">{{
                          dropdownVariables.status[0]
                      }}</span>
              </td>
              <td v-if="hasPermission(Admin.adminWrite)">
                  <!--            <router-link type="button" v-bind:to="{name:updateUrl,query:{id:user._id}}" @click="$emit('open')"-->
                  <!--                         class="edit_table_data edit_table_button font_detail"><i-->
                  <!--                class="fas fa-pencil-alt"></i></router-link>-->
                  <button
                          class="edit_table_data edit_table_button font_detail"
                          @click="updateModal(user._id)"
                  >
                      <i class="fas fa-pencil-alt"></i><span class="mx-1">Edit</span>
                  </button>
                  <!-- <button
                          class="edit_table_data delete_table_button font_detail"
                          @click="openDeleteModal(user.name, user._id)"
                  >
                      <i class="fas fa-trash"></i><span class="mx-1">Delete</span>
                  </button> -->
              </td>
        </tr>
      </template>
        <template v-slot:pagination>
            <pagination
                    v-model="pagination.page"
                    :records="$store.getters.getTotalRecords"
                    :per-page="pagination.limit"
                    @paginate="getAllAdmins"
                    :options="options"
            />
        </template>
    </Table>
      <div v-if="showDeleteModal">
          <AdminDelete
                  @close="closeDeleteModal"
                  v-bind:id="adminId"
                  v-bind:name="admin"
          />
      </div>
      <div v-if="showModal">
          <AdminAddUpdateModal
                  v-model="showModal"
                  v-bind:adminId="adminId"
                  @getAllAdmins="getAllAdmins"
          />
      </div>
  </div>
</template>

<script>
import {inject, onMounted, ref, watch} from "vue";
import {reactive} from "@vue/reactivity";
import store from "@/store";
import dropdownVariables from "@/modules/dropdownVariables";
import AdminDelete from "./AdminDelete";
import AdminAddUpdateModal from "./AdminAddUpdateModal";
import permission from "@/modules/permission";
import roles from "@/modules/roles";
// import DropdownFilter from "@/components/layouts/Filter/Inputs/Dropdown";
// import Filter from "@/components/layouts/Filter/Filter";

const {hasPermission} = permission();
const {Admin} = roles();

export default {
    name: "AdminList",
    components: {AdminDelete, AdminAddUpdateModal},
    props: {
        modelValue: Boolean,
    },
    setup(props, {emit}) {
        let pagination = reactive({
            page: 1,
            limit: 10,
        });
        const options = {
            texts: {
                count: "",
            },
        };
        const showLoader = inject("showLoader");
        const hideLoader = inject("hideLoader");
        const showDeleteModal = ref(false);
        const showModal = ref(false);
        const admin = ref("");
        const adminId = ref("");

        watch(
            () => props.modelValue,
            (value) => {
                if (value) {
                    addModal();
                    emit("update:modelValue", false);
                }
            }
        );

        const openDeleteModal = (name, id) => {
            admin.value = name;
            adminId.value = id;
            showDeleteModal.value = true;
        };
        const closeDeleteModal = () => {
            showDeleteModal.value = false;
        };
        const addModal = () => {
      adminId.value = "";
      showModal.value = true;
    };
    const updateModal = (value) => {
      adminId.value = value;
      showModal.value = true;
    };
    const getAllAdmins = () => {
        showLoader();
        store
            .dispatch("getAdminsByPage", pagination)
            .then(() => {
                hideLoader();
            })
            .catch((error) => {
                hideLoader();
                if (error.status === 401) {
                    getAllAdmins();
                }
            });
    };

    onMounted(getAllAdmins);

    return {
        pagination,
        options,
        admin,
        adminId,
        dropdownVariables,
        showModal,
        showDeleteModal,
        Admin,
        openDeleteModal,
        closeDeleteModal,
        getAllAdmins,
        addModal,
        updateModal,
        hasPermission,
    };
    },
};
</script>

<style scoped>
</style>