<template>
  <OwnerNav/>
  <router-view></router-view>
</template>

<script>
import OwnerNav from "../../components/layouts/Nav/OwnerNav";
export default {
  name: "Owner",
  components: {OwnerNav}
}
</script>

<style scoped>

</style>