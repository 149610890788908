import apiResponse from "@/modules/apiResponse";
import tokenCookie from "@/modules/tokenCookie";
import Apis from "@/urls/apis";
import axios from "axios";
const { hasValidToken, getToken } = tokenCookie();
const { errorResponse } = apiResponse();

const state = {

};
const getters = {

};
const actions = {
  async verifySupervisorTicket(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.VERIFY_SUPERVISOR_TICKET, payload, { headers: header })
          .then(({ data, status }) => {
            if (status === 200) {
              resolve(data);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
};
const mutations = {

};

export default {
  state,
  getters,
  actions,
  mutations,
};
