<template>
  <div class="row mt-5 w-100" v-if="$store.getters.getDashboardTicketsDetailsOwner.length" >
    <h4 class="highlight-title"><span class="text-orange text-bold">Ship & Seat</span> Class Wise</h4>

    <div
      class="col-md-6 mt-3"
      v-for="(ship, key, index) in shipWiseDetails.detail"
      :key="index"
    >
      <div class="card shadow h-100 rounded-3">
        <div class="card-header rounded-pill mt-2 mx-2 text-center">
          <h5 class="mb-0 text-uppercase">{{ key }}</h5>
        </div>
        <div class="card-body">
          <Table id="exportable_data">
            <template v-slot:header>
              <tr>
                <td class="font_detail">SL</td>
                <td class="font_detail" style="text-align: left">
                  Class Name
                </td>
                <td class="font_detail">Total Tickets</td>
                <td class="font_detail">Amount</td>
              </tr>
            </template>
            <template v-slot:body>
              <tr v-for="(item, i) in ship.slice(0, -1)" :key="i">
                <td class="font_detail">{{ i + 1 }}</td>
                <td class="font_detail" style="text-align: left">
                  {{ item.className }}
                </td>
                <td class="font_detail">{{ item.totalTicket }}</td>
                <td class="font_detail">&#2547;{{ item.totalAmount }}</td>
              </tr>
              <tr
                class="bg-dark"
                v-for="(totalItem, j) in ship.slice(ship.length - 1)"
                :key="j"
              >
                <td class="font_detail text-white"></td>
                <td class="font_detail text-white"></td>
                <td class="font_detail text-white">
                  Total Ticket: {{ totalItem.totalTicket }}
                </td>
                <td class="font_detail text-white">
                  Total Amount: &#2547;{{ totalItem.totalAmount }}
                </td>
              </tr>
            </template>
          </Table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { onBeforeMount, reactive } from "@vue/runtime-core";
import { watch } from "vue";

export default {
  setup() {
    let shipWiseDetails = reactive({ detail: {} });

    watch(
      () => store.getters.getDashboardTicketsDetailsOwner,
      () => {
        dashboardDetails();
      }
    );

    const dashboardDetails = () => {
      let details = store.getters.getDashboardTicketsDetailsOwner;
      shipWiseDetails.detail = {};
      details.forEach((element) => {
        let shipName = element?.othersInfo.shipName;

        if (shipName in shipWiseDetails.detail) {
          shipWiseDetails.detail[shipName].push({
            classId: element._id,
            totalTicket: element.totalTicket,
            totalAmount: element.amount,
            className: element?.othersInfo.seatClassName,
          });
        } else {
          shipWiseDetails.detail[shipName] = [
            {
              classId: element._id,
              totalTicket: element.totalTicket,
              totalAmount: element.amount,
              className: element?.othersInfo.seatClassName,
            },
          ];
        }
      });
      calculateSummary();
    };

    const calculateSummary = () => {
      for (const value of Object.values(shipWiseDetails.detail)) {
        let totalTicket = 0;
        let totalAmount = 0;
        value.forEach((item) => {
          totalTicket += item.totalTicket;
          totalAmount += item.totalAmount;
        });
        value.push({ totalTicket, totalAmount });
      }
    };

    onBeforeMount(() => {
      dashboardDetails();
    });

    return {
      shipWiseDetails,
    };
  }
}
</script >