<template>
  <AdminNav/>
  <router-view></router-view>
</template>

<script>
import AdminNav from "../../components/layouts/Nav/AdminNav";
export default {
  name: "Admin",
  components: {AdminNav}
}
</script>

<style scoped>

</style>