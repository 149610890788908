<template>
  <FilterTab>
    <template v-slot:title><h6>Cabin Man</h6></template>
    <template v-slot:body>
<!--      <div class="col-sm-6 col-md-2">-->
<!--        <DropdownFilter-->
<!--            v-bind:id="'ship'"-->
<!--            v-bind:label="'Ship'"-->
<!--            v-bind:defaultOption="'&#45;&#45;select ship&#45;&#45;'"-->
<!--            v-model="seatListPayload.shipId"-->
<!--            v-bind:defaultValue="''"-->
<!--            v-bind:dropdown="$store.getters.getShipListForFilter"-->
<!--        />-->
<!--      </div>-->
    </template>
    <template v-slot:button1>
      <button

          class="edit_table_data font_detail"
          @click="goBack"
      >
        <i class="fas fa-arrow-left"></i><span class="mx-1 bold">Go Back</span>
      </button>
    </template>
  </FilterTab>
 <div
     v-if="seatListPayload.shipId && $store.getters.getOwnerServicesCabinSeatList.length > 0"
     class="container-fluid"
 >
   <div
       v-for="cabinSeatList in $store.getters.getOwnerServicesCabinSeatList"
       :key="cabinSeatList.seatClassId"
   >
     <SeatClassCard
         :ship="shipId"
         :cabinMan="cabinManId"
         :cabinSeatList="cabinSeatList"
     />
   </div>
 </div>
</template>

<script>
import {onBeforeMount, inject, ref, } from "vue";
import {reactive} from "@vue/reactivity";
import store from "@/store";
import SeatClassCard from "./SeatClassCard";

export default {
  name: "SeatPermission",
  components: { SeatClassCard, },
  props: {
    cabinMan: String,
    ship: String,
  },
  setup(props, {emit}) {
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const cabinManId = ref(props.cabinMan)
    const shipId = ref(props.ship)
    const seatListPayload = reactive({
      cabinManId: cabinManId.value,
      shipId: shipId.value,
    })

    const goBack = () => {
      emit('toggle')
    }

    const getCabinSeatList = () => {
      if (seatListPayload.shipId){
        showLoader()
        store
            .dispatch("getOwnerServicesCabinSeatList", {shipId: seatListPayload.shipId})
            .then(() => {
              hideLoader()
            })
            .catch((error) => {
              hideLoader()
              if (error.status === 401) {
                getCabinSeatList();
              }
            });
      }
    }


    onBeforeMount(() => {
      getCabinSeatList()
    });

    return {
      goBack,
      seatListPayload,
      shipId,
      cabinManId,
    };
  },
};
</script>

<style scoped></style>
