<template>
  <div class="large_device_view">
    <section class="select_area py-1">
      <!-- Searching menu -->
      <div class="text-white text-center bold">
        <slot name="title"></slot>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12 col-md-10 col-xxl-10">
            <div class="row font_detail">
              <slot name="body"></slot>
            </div>
          </div>
          <div class="col-sm-12 col-md-2 col-xxl-2">
            <div class="row button_section justify-content-between">
              <div class="col-auto p-1">
                <slot name="filter"></slot>
              </div>
              <div class="col-auto p-1">
                <slot name="export"></slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "FilterTabBetween",
};
</script>

<style scoped>
.bold {
  font-weight: bold;
}

.button_section {
  padding-top: 13px;
  margin: 2px 0;
}
</style>