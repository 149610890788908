<template>
    <div v-if="hasPermission(Owner.ownerRead)">
        <Table v-if="$store.getters.getPanelOwners">
            <template v-slot:header>
                <tr>
                    <td class="font_detail">SL</td>
                    <td class="font_detail">NAME</td>
                    <td class="font_detail">DESIGNATION</td>
                    <td class="font_detail">MOBILE</td>
                    <td class="font_detail">EMAIL</td>
                    <td class="font_detail">USER TYPE</td>
                    <td class="font_detail">STATUS</td>
                    <td v-if="hasPermission(Owner.ownerWrite)" class="font_detail">
                        ACTION
                    </td>
                </tr>
            </template>
            <template v-slot:body>
                <tr
                        v-for="(user, index) in $store.getters.getPanelOwners"
                        :key="user._id"
                >
                    <td class="font_detail">{{ index + 1 }}</td>
                    <td class="font_detail">{{ user.name }}</td>
                    <td class="font_detail">{{ user.designation }}</td>
                    <td class="font_detail">{{ user.phone }}</td>
                    <td class="font_detail">{{ user.email }}</td>
                    <td class="font_detail">
                        {{ dropdownVariables.userType[user.userType] }}
                    </td>
                    <td>
            <span v-if="user.status" class="badge bg-success">{{
                    dropdownVariables.status[1]
                }}</span>
                        <span v-else class="badge bg-danger">{{
                                dropdownVariables.status[0]
                            }}</span>
                    </td>
                    <td v-if="hasPermission(Owner.ownerWrite)">
                        <!--            <router-link type="button" v-bind:to="{name:updateUrl,query:{id:user._id}}" @click="$emit('open')"-->
                        <!--                         class="edit_table_data edit_table_button font_detail"><i-->
                        <!--                class="fas fa-pencil-alt"></i></router-link>-->
                        <button
                                class="edit_table_data edit_table_button font_detail"
                                @click="updateModal(user._id)"
                        >
                            <i class="fas fa-pencil-alt"></i><span class="mx-1">Edit</span>
                        </button>
                    </td>
                </tr>
            </template>
            <!-- <template v-slot:pagination>
              <pagination
                v-model="pagination.page"
                :records="$store.getters.getTotalRecords"
                :per-page="pagination.limit"
                @paginate="getAllAdmins"
                :options="options"
              />
            </template> -->
        </Table>
        <div v-if="showModal">
            <OwnerAddUpdateModal
                    v-model="showModal"
                    v-bind:ownerId="ownerId"
                    @getAllOwners="getAllOwners"
            />
        </div>
        <button
                v-if="hasPermission(Owner.ownerWrite)"
                @click="addModal"
                class="floating_btn"
        >
            <span><i class="fa fa-plus my-float"></i></span>
        </button>
    </div>
</template>

<script>
import {inject, onBeforeMount, ref} from "vue";
import {reactive} from "@vue/reactivity";
import store from "@/store";
import permission from "@/modules/permission";
import roles from "@/modules/roles";
import dropdownVariables from "@/modules/dropdownVariables";
import OwnerAddUpdateModal from "./OwnerAddUpdateModal.vue";

const {hasPermission} = permission();
const {Owner} = roles();
export default {
    name: "OwnersList",
    components: {OwnerAddUpdateModal},
    setup() {
        let pagination = reactive({
            page: 1,
            limit: 10,
        });
        const ownerId = ref("");
        const showModal = ref(false);
        const showLoader = inject("showLoader");
        const hideLoader = inject("hideLoader");
        const getAllOwners = () => {
            showLoader();
            store
                .dispatch("getPanelOwnersByPage", pagination)
                .then(() => {
                    hideLoader();
                })
                .catch((error) => {
                    hideLoader();
                    if (error.status === 401) {
                        getAllOwners();
                    }
                });
        };
        onBeforeMount(() => {
            getAllOwners();
        });
        const updateModal = (value) => {
            ownerId.value = value;
            showModal.value = true;
        };
        const addModal = () => {
            ownerId.value = "";
            showModal.value = true;
        };

        return {
            getAllOwners,
            hasPermission,
            Owner,
            updateModal,
            dropdownVariables,
            pagination,
            showModal,
            ownerId,
            addModal,
        };
    },
};
</script>

<style>
</style>