<template>
  <div>
    <div v-if="printPreview" >
      <TicketPreviewVerify @close="closePrintPreview" />
    </div>
    <div v-if="!printPreview">
      <div class="card text-center pb-3 rounded-0" style="background-color: #dcdcdc">
        <div class="card-body">
          <div>
            <div class="card-text"><h2>Verify By Phone</h2></div>
            <div class="row">
              <div class="col-6 col-lg-4 mx-auto">
                <form @submit.prevent="getOnlineTicket" class="input-group mb-3" action="#">
                  <input 
                    type="text"
                    class="form-control"
                    placeholder="Enter Passenger phone"
                    aria-label="Enter phone"
                    aria-describedby="button-addon2"
                    pattern="^01[3-9]\d{8}$"
                    onkeypress ="return event.charCode >= 48 && event.charCode <= 57"
                    v-model="verifyData.passengerPhone"
                    @keyup.enter="getOnlineTicket"
                    required
                  >
                  <input 
                    class="verifyButton"
                    type="submit"
                    value="Submit"
                    id="button-addon2"
                  />
                </form>
              </div>
            </div>
          </div>
          <!-- oninput="this.value = !!this.value && Math.abs(this.value) >= -1 ? Math.abs(this.value) : null" -->
        </div>
      </div>
      <div v-if="onlineTicketList.length>0" class="container-lg p-0 my-1">
        <table class="table table-hover table-bordered table-striped text-center vehicle_table">
          <thead class="table-dark text-white">
            <tr>
              <th class="font_detail text-center" scope="col">TRIP DATE TIME</th>
              <th class="font_detail text-center" scope="col">SHIP NAME</th>
              <th class="font_detail text-center" scope="col">ROUTE</th>
              <th class="font_detail text-center" scope="col">DIRECTION</th>
              <th class="font_detail text-center" scope="col">SEAT CLASS</th>
              <th class="font_detail text-center" scope="col">SEATS</th>
              <th class="font_detail text-center" scope="col">ACTION</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(ticket, index) in onlineTicketList" :key="index">
              <td class="font_detail text-center fw-bold"><span v-html="dateFormat2(ticket.tripDateTime).split(/ (.*)/s).splice(0,2).join('<br>')"></span></td>
              <td class="font_detail text-center fw-bold">{{ticket.shipName}}</td>
              <td class="font_detail text-center">{{ticket.routeName}}</td>
              <td class="font_detail text-center fw-bold">{{ticket.direction}} <br> ({{ticket.stoppageStr}})</td>
              <td class="font_detail text-center">{{ticket.seatClassName}}</td>
              <td class="font_detail text-center">#{{ ticket.seatNumbers }}</td>
              <td>
                <button 
                  class="btn btn-success w-100 float-end font_detail"
                  type="button"
                  id="button-addon2"
                  @click="submit(ticket._id)"
                >
                  Verify
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from "@vue/reactivity";
import { inject, ref,   } from "vue";
import store from "@/store";
import TicketPreviewVerify from "./TicketPreviewVerify.vue";
import moment from "moment";
import toast from "@/modules/Toast";

export default {
  components: {
    TicketPreviewVerify,
  },
  setup() {
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const onlineTicketList = ref([])
    let verifyData = reactive({
      passengerPhone: "",
    });
    const printPreview = ref(false);
    const { errorToast } = toast();
    const closePrintPreview = (phone) => {
      // focusInput();
      printPreview.value = false;
      verifyData.passengerPhone = phone;
      getOnlineTicket()
    };
    const dateFormat = (date) => {
      return moment(date)
          .utcOffset(6, false)
          .format("DD-MMM-yyyy hh:mm:ss A");
    };

    const dateFormat2 = (date) => {
      return moment(date)
          .utcOffset(0, false)
          .format("DD-MMM-yyyy hh:mm:ss A");
    };
    const submit = (id) => {
        showLoader();
        store
          .dispatch("verifyCountermanTicket", {ticketId: id})
          .then(( ) => {
            printPreview.value = true;
            hideLoader();
          })
          .catch((error) => {
            hideLoader();
            closePrintPreview();
            if (error.status === 401) {
              submit();
            } else if (error.status === 400) {
              const { data } = error;
              if (!Object.prototype.hasOwnProperty.call(data, "message")) {
                errorToast("Invalid Code!");
              }
            }
          });
    };

    const getOnlineTicket = () => {
      showLoader();
      store
        .dispatch("verifyOnlineTicketList", verifyData)
        .then((data) => {
          onlineTicketList.value = data.ticket
          hideLoader();
        })
        .catch(() => {
          onlineTicketList.value = []
          hideLoader();
          console.log("Something wrong.Please try again.");
        });
    }
    //
    // const focusInput = () => {
    //   let input = document.querySelector("[autofocus]");
    //   input.focus();
    // };
    //
    // onUpdated(() => {
    //   focusInput();
    // });
    //   onMounted(() => {
    //     focusInput();
    //   });

    return {
      verifyData,
      submit,
      printPreview,
      closePrintPreview,
      getOnlineTicket,
      onlineTicketList,
      dateFormat,
      dateFormat2
    };
  },
};
</script>

<style lang="css" scoped>
.verifyButton,
.verifyButton:focus,
.verifyButton:hover{
  background: #f04935;
  color: #fff;
  border: 0;
  border-radius: 0 5px 5px 0;
  font-weight: 500;
  padding: 0 15px;
}

/* @media only screen and (min-width: 992px) {
  .tableText{
    font-size: 15px;
  }
}

@media only screen and (max-width: 991px) {
  .tableText{
    font-size: 14px;
  }
} */

</style>
