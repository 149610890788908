<template>
  <Modal @close="closeModal">
    <template v-slot:title>{{ title }}</template>
    <template v-slot:body>
      <form>

        <div class="form-group row my-1">
          <ModalDropdown
            v-model="setBoardingPoint"
            :id="'boardingPoint'"
            :label="'Boarding Point'"
            :dropdown="boardingPoints"
            :defaultOption="'--select boarding point--'"
            :defaultValue="''">
            <template v-slot:error>
              <ModalError v-if="errors.boardingPoint" v-bind:error="errors.boardingPoint"/>
            </template>
          </ModalDropdown>
        </div>

        <div class="form-group row my-1">
          <ModalDropdown
            v-model="setDroppingPoint"
            :id="'droppingPoint'"
            :label="'Dropping Point'"
            :dropdown="droppingPoints"
            :defaultOption="'--select dropping point--'"
            :defaultValue="''">
            <template v-slot:error>
              <ModalError v-if="errors.droppingPoint" v-bind:error="errors.droppingPoint"/>
            </template>
          </ModalDropdown>
        </div>

        <div class="form-group row my-1">
          <ModalInput
            v-model="localRoute.upFare"
            :placeholder="'Up Fare'"
            :label="'Up Fare'"
            :id="'upFare'"
            :type="'4digitNumber'"
          >
            <template v-slot:error>
              <ModalError v-if="errors.upFare" v-bind:error="errors.upFare" />
            </template>
          </ModalInput>
        </div>

        <div class="form-group row my-1">
          <ModalInput
            v-model="localRoute.downFare"
            :placeholder="'Down Fare'"
            :label="'Down Fare'"
            :id="'downFare'"
            :type="'4digitNumber'"
          >
            <template v-slot:error>
              <ModalError v-if="errors.downFare" v-bind:error="errors.downFare" />
            </template>
          </ModalInput>
        </div>

        <div class="form-group row my-1">
          <label class="col-sm-3 col-form-label"></label>
          <div class="col-sm-9">
            <input
              v-if="!boardingPointId && !droppingPointId && !upFareId && !downFareId"
              type="submit"
              value="Submit"
              class="mt-3 py-2 submit_button font_detail float-end"
              @click.prevent="addLocalRoute"
            />
            <input
              v-else
              type="submit"
              value="Submit"
              class="mt-3 py-2 submit_button font_detail float-end"
              @click.prevent="updateLocalRoute"
            />
          </div>
        </div>
      </form>
    
    </template>
  </Modal>
</template>

<script>
import useInputValidation from "@/modules/useInputValidations";
import { inject, onMounted, onBeforeMount, ref, watch } from "vue";
import { reactive } from "@vue/reactivity";
import store from "../../../../../../store";
import { useRoute } from "vue-router";

export default {
  name: "LocalRouteAddUpdateModal",
  props: {
    localRouteObject : Object,
    boardingPlaceforLocalRoutes : String,
    droppingPointforLocalRoutes: String,
    localRoutesUpFare : [String, Number],
    localRoutesDownFare : [String, Number],
    localRoutesID : String,
    routeId : String,
    rId: String
  },
  setup(props, { emit }) {
    let { requiredValidation, errors } = useInputValidation();
    const localRoute = reactive({
      id:"",
      routeId: "",
      companyId: "",
      boardingPlace: "",
      droppingPoint: "",
      upFare: 0,
      downFare: 0,
    });
    const boardingPointId = ref(props.boardingPlaceforLocalRoutes);
    const droppingPointId = ref(props.droppingPointforLocalRoutes);
    const upFareId = ref(props.localRoutesUpFare);
    const downFareId = ref(props.localRoutesDownFare);
    const route = useRoute();
    let setBoardingPoint = ref("");
    let boardingPoints = ref({});
    let setDroppingPoint = ref("");
    let droppingPoints = ref({});
    const title = ref("Add Local Route");
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");

    watch(
      () => setBoardingPoint.value,
      (value) => {
        localRoute.boardingPlace= boardingPoints.value[value];
        requiredValidation("name", localRoute.boardingPlace);
      }, {immediate: true}
    );

    watch(
      () => setDroppingPoint.value,
      (value) => {
        localRoute.droppingPoint = droppingPoints.value[value];
        requiredValidation("name", localRoute.droppingPoint);
      }, {immediate: true}
    );

    const validate = () => {
      Object.keys(errors).forEach(function (key) {
        errors[key] = "";
      });
      requiredValidation("boardingPoint", localRoute.boardingPlace);
      requiredValidation("droppingPoint", localRoute.droppingPoint);
      return !(
        Object.values(errors).length <= 0 ||
        !Object.values(errors).every((x) => x === null || x === "")
      );
    };
    const closeModal = () => {
      emit("update:modelValue", false);
    };
    const addLocalRoute = () => {
      if (validate()) {
        showLoader();
        store
          .dispatch("addLocalRoute", localRoute)
          .then(() => {
            hideLoader();
            emit("getAllLocalRoutes");
            closeModal();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              addLocalRoute();
            }
          });
      }
    };
    const updateLocalRoute = () => {
      if (validate()) {
        showLoader();
        store
          .dispatch("updateLocalRoute", localRoute)
          .then(() => {
            hideLoader();
            emit("getAllLocalRoutes");
            closeModal();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              updateLocalRoute();
            }
          });
      }
    };
    const initStoppages = () =>{
      boardingPoints.value = {}
      props.localRouteObject.forEach((boardingPoint, index)=>{
        boardingPoints.value[index] = boardingPoint;
      });

      droppingPoints.value = {}
      props.localRouteObject.forEach((droppingPoint, index)=>{
        droppingPoints.value[index] = droppingPoint;
      });
    }

    const findStoppages = (stoppageObject, selectedValue) => {
      return stoppageObject.findIndex(singleStoppage => singleStoppage === selectedValue);
    }

    onMounted(()=>{
      initStoppages();
    })

    onBeforeMount(() => {
      initStoppages();
      Object.keys(errors).forEach(function (key) {
        delete errors[key];
      });
      if (route.query.id) {
        localRoute.companyId = route.query.id;
        localRoute.id = props.localRoutesID
        localRoute.routeId = props.rId;
      }
      if (boardingPointId.value!== "" && droppingPointId.value !== "" && upFareId.value !== "" && downFareId.value !== "") {
        title.value = "Update Local Route";
        setBoardingPoint.value = findStoppages(props.localRouteObject, boardingPointId.value);
        setDroppingPoint.value = findStoppages(props.localRouteObject, droppingPointId.value);
        localRoute.upFare = upFareId.value;
        localRoute.downFare = downFareId.value;
      }
    });

    return {
      boardingPointId,
      droppingPointId,
      upFareId,
      downFareId,
      setBoardingPoint,
      boardingPoints,
      setDroppingPoint,
      droppingPoints,
      localRoute,
      errors,
      title,
      addLocalRoute,
      updateLocalRoute,
      closeModal,
    };
  },
};
</script>

<style scoped>
</style>