import apiResponse from "@/modules/apiResponse";
import tokenCookie from "@/modules/tokenCookie";
import Apis from "@/urls/apis";
import axios from "axios";

const {hasValidToken, getToken} = tokenCookie();
const {errorResponse} = apiResponse();

const state = {
    reports: {},
    printReports: {}
};
const getters = {
    getSupervisorReport: (state) => state.reports,
    getSupervisorReportForPrint: (state) => state.printReports,
};
const actions = {
    async getAllSupervisorReport({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };

            return new Promise((resolve, reject) => {
                axios.get(Apis.GET_ALL_SUPERVISOR_REPORT, {
                    params: payload,
                    headers: header
                })
                    .then(({data, status}) => {
                        if (status === 200) {
                            commit("setAllSupervisorReport", data)
                            resolve(data.ship)
                        }
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },
    async getAllSupervisorReportForPrint({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };

            return new Promise((resolve, reject) => {
                axios.get(Apis.GET_SUPERVISOR_REPORT_FOR_PRINT, {
                    params: payload,
                    headers: header
                })
                    .then(({data, status}) => {
                        if (status === 200) {
                            commit("setSupervisorReportForPrint", data)
                            resolve(data.report)
                        }
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },
    async supervisorReportPrintCount(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.post(Apis.SUPERVISOR_REPORT_PRINT_COUNT, payload, { headers: header })
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },
    async supervisorReportPrintCountIncrease(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.post(Apis.SUPERVISOR_REPORT_PRINT_COUNT_INCREASE, payload, { headers: header })
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },
    
};
const mutations = {
    setAllSupervisorReport: (state, reports) => (state.reports = reports),
    setSupervisorReportForPrint: (state, reports) => (state.printReports = reports),
};

export default {
    state,
    getters,
    actions,
    mutations
};