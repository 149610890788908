import apiResponse from "@/modules/apiResponse";
import tokenCookie from "@/modules/tokenCookie";
import Apis from "@/urls/apis";
import axios from "axios";
const { successResponse, errorResponse } = apiResponse();

// const { successToast } = toast();
const { hasValidToken, getToken } = tokenCookie();

const state = {
  filter: {
    stoppage: {},
    ship: {},
    route: {},
    counter: {},
  },
  trips: [],
  tripsReportList: [],
  tickets: {},
};
const getters = {
  getSupervisorTrips: (state) => state.trips.docs,
  getSupervisorTotalTrips: (state) => state.trips.totalDocs,
  getSupervisorTrip: (state) => (id) =>
    state.trips.docs.filter(function(trip) {
      return trip._id === id;
    }),
  getSupervisorStoppageListForFilter: (state) => state.filter.stoppage,
  getSupervisorShipListForFilter: (state) => state.filter.ship,
  getSupervisorRouteListForFilter: (state) => state.filter.route,

  getSupervisorTripsReportList: (state) => state.tripsReportList.docs,
  getSupervisorTotalTripsReportList: (state) => state.tripsReportList.totalDocs,
};
const actions = {
  async addSupervisorTrip(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.ADD_SUPERVISOR_TRIP, payload, { headers: header })
          .then((success) => {
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
  async updateSupervisorTrip(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .put(Apis.UPDATE_SUPERVISOR_TRIP, payload, { headers: header })
          .then((success) => {
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
  async getSupervisorTripsByPage({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setTrips", []);
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_SUPERVISOR_TRIPS, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              commit("setTrips", data.trip);
              resolve({ success: "success" });
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getStoppageListForSupervisor({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setStoppageListInFilter", {});
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_SUPERVISOR_STOPPAGES, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              let stoppageList = {};
              data.stoppage.forEach((stoppage) => {
                stoppageList[stoppage._id] = stoppage.name;
              });
              commit("setStoppageListInFilter", stoppageList);
              resolve(data.stoppage);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getShipListForSupervisor({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_SUPERVISOR_SHIPS, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              let shipList = {};
              data.ship.forEach((ship) => {
                shipList[ship._id] = ship.name;
              });
              commit("setShipListInFilter", shipList);
              resolve(data.ship);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
  async getRouteListForSupervisor({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setRouteListInFilter", {});
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_SUPERVISOR_ROUTES, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              let routeList = {};
              data.route.forEach((route) => {
                routeList[route._id] = route.name;
              });
              commit("setRouteListInFilter", routeList);
              resolve(data.route);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getShipsByRouteForSupervisor(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_SUPERVISOR_SHIPS_BY_ROUTE, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              resolve(data.ship);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getSupervisorTripsReportListByPage({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setTripsReportList", []);
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
            .get(Apis.GET_ALL_SUPERVISOR_TRIPS_REPORT_LIST, {
              params: payload,
              headers: header,
            })
            .then(({ data, status }) => {
              if (status === 200) {
                commit("setTripsReportList", data.trips);
                resolve({ success: "success" });
              }
            })
            .catch((error) => {
              reject(errorResponse(error));
            });
      });
    }
  },
};
const mutations = {
  setTrips: (state, trips) => (state.trips = trips),
  setTripsReportList: (state, tripsReportList) => (state.tripsReportList = tripsReportList),

  setStoppageListInFilter: (state, stoppageList) =>
    (state.filter.stoppage = stoppageList),
  setShipListInFilter: (state, shipList) => (state.filter.ship = shipList),
  setRouteListInFilter: (state, routeList) => (state.filter.route = routeList),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
